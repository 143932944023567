import {AUTH_LOGOUT, AUTH_SUCCESS, AUTH_ERROR, AUTH_REQUEST} from "../actions/actionTypes";

const initialState = {
    isAutoLogin: false,
    authRequest: false,
    authError: null,
    token: null
}

export default function authReducer(state = initialState, action) {
    switch (action.type) {
        case AUTH_REQUEST:
            return {
                ...state, isAutoLogin: true, authRequest: true, authError: null, token: null
            }
        case AUTH_ERROR:
            return {
                ...state, isAutoLogin: true, authRequest: false, authError: action.authError, token: null
            }
        case AUTH_SUCCESS:
            return {
                ...state, isAutoLogin: true, authRequest: false, authError: null, token: action.token
            }
        case AUTH_LOGOUT:
            return {
                ...state, isAutoLogin: true, authRequest: false, authError: null, token: null
            }
        default:
            return state;
    }
}
