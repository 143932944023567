import React from 'react';
import {connect} from 'react-redux';
import {authLogout} from "../../store/actions/authAction";
import {pageUnmount} from "../../store/actions/pageAction"
import {apiUnmount} from "../../store/actions/apiAction";

class Logout extends React.Component {

    componentDidMount() {
        this.props.pageUnmount();
        this.props.apiUnmount();
        this.props.authLogout(() => {
            // console.log('Auth logout.');
            // this.props.history.push('/home');
            this.props.history.push('/login');
        });
    }

    render() {
        return null;
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageUnmount: () => dispatch(pageUnmount()),
        apiUnmount: () => dispatch(apiUnmount()),
        authLogout: (callback) => dispatch(authLogout(callback))
    }
}

export default connect(null, mapDispatchToProps)(Logout);
