import React from 'react';
import './Navigate.scss';
import {NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import {profileOpen} from "../../store/actions/profileAction";
import baseURL from "../../library/baseURL";
import Loading from "../loading/Loading";

class Navigate extends React.Component {

    profileOpen(profileOpenForm) {
        this.props.profileOpen(profileOpenForm);
    }

    linkOpen(link) {
        window.open(link, '_blank');
    }


    render() {
        const { profileData } = this.props;

        return (
            <nav className="_after _before">
                <div className="container">
                    <ul>
                        <li className="link logotype">
                            <NavLink to={"/cabinet/home"}>
                                <div className={"logotype_small_blue"}/>
                            </NavLink>
                                <p className={"signature"}>Онлайн магистратура <br/>ПНИПУ</p>
                                {/*<p className={"signature"}>Образовательный портал <br/> магистратура онлайн <br/> демо-версия</p>*/}
                        </li>

                        {profileData ? (
                            <React.Fragment>
                                <li className="link name">
                                    <a onClick={this.profileOpen.bind(this, 'formProfileData')}>
                                        {/*<div className={"account account_circle_white_100"}>*/}
                                        {/*    /!*<svg id="account_circle-black-18dp" xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">*!/*/}
                                        {/*    /!*    <path id="Path_1" data-name="Path 1" d="M0,0H100V100H0Z" fill="none"/>*!/*/}
                                        {/*    /!*    <path id="Path_2" data-name="Path 2" d="M50,2A48,48,0,1,0,98,50,48.018,48.018,0,0,0,50,2Zm0,14.4A14.4,14.4,0,1,1,35.6,30.8,14.381,14.381,0,0,1,50,16.4Zm0,68.16A34.563,34.563,0,0,1,21.2,69.1C21.344,59.552,40.4,54.32,50,54.32c9.552,0,28.656,5.232,28.8,14.784A34.563,34.563,0,0,1,50,84.56Z" fill="#fff"/>*!/*/}
                                        {/*    /!*</svg>*!/*/}
                                        {/*    <div className={"setting setting_blue_32"}/>*/}
                                        {/*</div>*/}

                                        {profileData.profile_avatar ? (
                                                <div className={"account account_circle_white_100"}>
                                                    <div className="Box__avatar Avatar__image"
                                                         style={{
                                                             height: 100,
                                                             width: 100,
                                                             borderRadius: '50%'
                                                         }}>
                                                        <img className={'Avatar__image'}
                                                             src={baseURL() + 'api/images/avatar/' + profileData.profile_avatar}
                                                             width="100"
                                                             height="100"/>
                                                    </div>
                                                    <div className={"setting setting_blue_32"}/>
                                                </div>
                                        ) : (
                                            <div className={"account account_circle_white_100"}>
                                                {/*<svg id="account_circle-black-18dp" xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">*/}
                                                {/*    <path id="Path_1" data-name="Path 1" d="M0,0H100V100H0Z" fill="none"/>*/}
                                                {/*    <path id="Path_2" data-name="Path 2" d="M50,2A48,48,0,1,0,98,50,48.018,48.018,0,0,0,50,2Zm0,14.4A14.4,14.4,0,1,1,35.6,30.8,14.381,14.381,0,0,1,50,16.4Zm0,68.16A34.563,34.563,0,0,1,21.2,69.1C21.344,59.552,40.4,54.32,50,54.32c9.552,0,28.656,5.232,28.8,14.784A34.563,34.563,0,0,1,50,84.56Z" fill="#fff"/>*/}
                                                {/*</svg>*/}
                                                <div className={"setting setting_blue_32"}/>
                                            </div>
                                        )}
                                        <br/>
                                        <div className={"fio__name"}>{profileData.profile_fio.split(' ')[1]}</div>
                                    </a>
                                </li>

                                {profileData.profile_type === 'administrator' ? (
                                    <React.Fragment>
                                        <li className="link">
                                            <NavLink to={"/cabinet/students"}>Студенты</NavLink>
                                        </li>
                                        <li className="link">
                                            <NavLink to={"/cabinet/employees"}>Преподаватели</NavLink>
                                        </li>
                                        <li className="link">
                                            <NavLink to={"/cabinet/training/plans"}>Учебные планы</NavLink>
                                        </li>
                                        <li className="link">
                                            <NavLink to={"/cabinet/training/schedules"}>Учебные графики</NavLink>
                                        </li>
                                        <li className="link">
                                            <NavLink to={"/cabinet/feedbacks"}>Сообщения</NavLink>
                                        </li>
                                        <li className="link"/>
                                        <li className="link">
                                            <div className="a" onClick={this.linkOpen.bind(this, "https://online.at.pstu.ru/")}>ПНИПУ курсы</div>
                                        </li>
                                        <li className="link">
                                            <div className="a" onClick={this.linkOpen.bind(this, "http://lib.pstu.ru/")}>Библиотека</div>
                                        </li>
                                    </React.Fragment>
                                ) : null}

                                {profileData.profile_type === 'teacher' ? (
                                    <React.Fragment>
                                        <li className="link">
                                            <NavLink to={"/cabinet/teacher/training/courses"}>Учебные курсы</NavLink>
                                        </li>
                                        <li className="link">
                                            <NavLink to={"/cabinet/teacher/curator"}>Курирование</NavLink>
                                        </li>
                                        <li className="link">
                                            <NavLink to={"/cabinet/teacher/resit"}>Ведомости</NavLink>
                                        </li>
                                        <li className="link">
                                            <NavLink to={"/cabinet/teacher/messages"}>Сообщения</NavLink>
                                        </li>
                                        <li className="link">
                                            <NavLink to={"/cabinet/teacher/feedback"}>Обратная связь</NavLink>
                                        </li>
                                        <li className="link"/>
                                        <li className="link">
                                            <div className="a" onClick={this.linkOpen.bind(this, "https://online.at.pstu.ru/")}>ПНИПУ курсы</div>
                                        </li>
                                        <li className="link">
                                            <div className="a" onClick={this.linkOpen.bind(this, "http://lib.pstu.ru/")}>Библиотека</div>
                                        </li>
                                    </React.Fragment>
                                ) : null}

                                {profileData.profile_type === 'student' ? (
                                    <React.Fragment>
                                        <li className="link">
                                            <NavLink to={"/cabinet/student/training/schedule"}>Расписание</NavLink>
                                        </li>
                                        <li className="link">
                                            <NavLink to={"/cabinet/student/training/courses"}>Учебные курсы</NavLink>
                                        </li>
                                        <li className="link">
                                            <NavLink to={"/cabinet/student/gradebook"}>Зачетная книжка</NavLink>
                                        </li>
                                        <li className="link">
                                            <NavLink to={"/cabinet/student/messages"}>Сообщения</NavLink>
                                        </li>
                                        <li className="link">
                                            <NavLink to={"/cabinet/student/feedback"}>Обратная связь</NavLink>
                                        </li>
                                        <li className="link"/>
                                        <li className="link">
                                            <div className="a" onClick={this.linkOpen.bind(this, "https://online.at.pstu.ru/")}>ПНИПУ курсы</div>
                                        </li>
                                        <li className="link">
                                            <div className="a" onClick={this.linkOpen.bind(this, "http://lib.pstu.ru/")}>Библиотека</div>
                                        </li>
                                    </React.Fragment>
                                ) : null}

                            </React.Fragment>
                        ) : null}

                        <li className="link exit">
                            <NavLink to={"/cabinet/logout"}>Выйти</NavLink>
                        </li>
                    </ul>
                </div>
            </nav>
        );
    }
}

function mapStateToProps(state) {
    return {
        profileData: state.profile.profileData
    }
}

function mapDispatchToProps(dispatch) {
    return {
        profileOpen: (profileOpenForm) => dispatch(profileOpen(profileOpenForm))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (Navigate);
