import {ALERT_CLOSE, ALERT_OPEN} from "./actionTypes";

export function alertOpen(alertForm, alertData) {

    return {
        type: ALERT_OPEN,
        alertForm: alertForm,
        alertData: alertData
    }
}

export function alertClose() {

    return {
        type: ALERT_CLOSE
    }
}
