import React from "react";
import {connect} from "react-redux";
import {modalClose} from "../../../../store/actions/modalAction";
import {notificationMessage} from "../../../../store/actions/notificationAction";
import {apiRequest} from "../../../../store/actions/apiAction";
import {pageUpdate} from "../../../../store/actions/pageAction";
import Loading from "../../../loading/Loading";
import {formLoad} from "../../../../store/actions/formAction";
import {alertOpen} from "../../../../store/actions/alertAction";

class ModalStudentAdd extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isMountForm: true,
            isOpenForm: false,
            isLoadForm: false,
            isUpdateForm: false,
            formData: null,
            isHandleSubmit: false,
            objectId: null,
            objectData: null,
            formResponseError: null,
            studentFio: null,
            studentBirthday: null,
            studentEmail: null,
            trainingGroupId: this.props.modalData.objectId,
            dateEnrollment: null
        };
    }

    // componentDidMount() {
    //     this.formUpdate(this.state.isLoadForm);
    // }

    componentWillUnmount() {
        // console.log('From Unmounted');
        this.setState({
            isMountForm: false
        });
    }

    // componentDidUpdate() {
    //     if (this.state.isMountForm) {
    //         const responseData = this.props.responseData;
    //         // console.log(1 + ' componentDidUpdate');
    //         if (this.props.isLoadForm !== this.state.isLoadForm) {
    //             // console.log(2 + ' isLoad');
    //             if (responseData !== this.state.formData) {
    //                 // console.log(3 + ' responseData');
    //
    //                 this.formUpdate();
    //
    //                 this.setState({
    //                     isOpenForm: true,
    //                     isLoadForm: this.props.isLoadForm,
    //                     isUpdateForm: this.props.isUpdateForm,
    //                     formData: responseData
    //                 });
    //             }
    //         }
    //
    //         else if (this.props.isUpdateForm !== this.state.isUpdateForm) {
    //             // console.log(4 + ' isUpdate');
    //             this.setState({
    //                 isUpdateForm: this.props.isUpdateForm
    //             });
    //             this.formUpdate(this.state.isLoadForm);
    //         }
    //     }
    // }
    //
    // formUpdate(isLoadForm) {
    //     this.props.apiRequest('/company/' + this.state.objectId + '/supports_date', null, () => {
    //         this.props.formLoad(isLoadForm);
    //     }, null);
    // }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    // setStateAndInput(name, value) {
    //
    //     let input = document.getElementById(name);
    //     input.value = value;
    //
    //     this.setState({
    //         [name]: value
    //     });
    // }

    handleSubmit(event) {
        event.preventDefault();

        if (this.state.studentEmail && this.state.studentFio && this.state.studentBirthday && this.state.trainingGroupId && this.state.dateEnrollment) {

            let formData = new FormData();

            formData.append('student_email', this.state.studentEmail);
            formData.append('student_fio', this.state.studentFio);
            formData.append('student_birthday', this.state.studentBirthday);
            formData.append('training_group_id', this.state.trainingGroupId);
            formData.append('date_enrollment', this.state.dateEnrollment);

            this.setState({
                isHandleSubmit: true
            });

            this.props.apiRequest('api/cabinet/student/add', formData, () => {
                    // this.setStateAndInput('', null);
                    this.setState({
                        isHandleSubmit: false,
                        formResponseError: null
                    });
                    // this.formUpdate(this.state.isLoadForm);
                    this.props.modalClose();
                    this.props.notificationMessage(this.props.responseMessage, 'green');
                    this.props.pageUpdate();
                }, () => {
                    this.setState({
                        isHandleSubmit: false,
                        formResponseError: this.props.responseError
                    });
                }
            );

        }

        else {
            this.setState({
                formResponseError: 'Пожалуйста, внимательно заполните все поля.'
            });
        }
    }

    modalClose() {
        this.props.modalClose();
    }

    // alertOpen(alertForm, alertData) {
    //     this.props.alertOpen(alertForm, alertData);
    // }

    render() {
        const {
            isMountForm,
            // isOpenForm,
            // formData,
            isHandleSubmit,
            formResponseError } = this.state;

        // let alertData = (objectId, objectData) => {
        //     return {
        //         objectId: objectId,
        //         objectData: objectData
        //     };
        // }

        if (isMountForm) {

            // {!isOpenForm ? (<Loading/>) : (
            //     formData ? () : null)}

            return (
                <div className="Form">
                    <form onSubmit={this.handleSubmit.bind(this)}>
                        <div className="Form__header">
                            <div className="Header__title">Новый студент</div>
                        </div>
                        <div className="Form__content">

                            <div className="Content__hr Content__hr_bottom-32"/>

                            <div className="Content__box">
                                <div className="Form__field">
                                    <div className="Field__label">Фамилия Имя Отчество</div>
                                    <input className="Field__input"
                                           name="studentFio"
                                           type="text"
                                           placeholder="Иванов Иван Иванович"
                                           value={this.state.value}
                                           onChange={this.handleInputChange.bind(this)}/>
                                </div>
                            </div>

                            <div className="Content__box">
                                <div className="Form__field">
                                    <div className="Field__label">Дата рождения</div>
                                    <input className="Field__input"
                                           name="studentBirthday"
                                           type="text"
                                           placeholder="дд.мм.гггг"
                                           value={this.state.value}
                                           onChange={this.handleInputChange.bind(this)}/>
                                </div>
                            </div>

                            <div className="Content__box">
                                <div className="Form__field">
                                    <div className="Field__label">Электронная почта</div>
                                    <input className="Field__input"
                                           name="studentEmail"
                                           type="email"
                                           placeholder="user@email.com"
                                           value={this.state.value}
                                           onChange={this.handleInputChange.bind(this)}/>
                                </div>
                            </div>

                            <div className="Content__hr Content__hr_bottom-32"/>

                            <div className="Content__box">
                                <div className="Form__field">
                                    <div className="Field__label">Дата зачисления</div>
                                    <input className="Field__input"
                                           name="dateEnrollment"
                                           type="text"
                                           placeholder="дд.мм.гггг"
                                           value={this.state.value}
                                           onChange={this.handleInputChange.bind(this)}/>
                                </div>
                            </div>

                            <div className="Content__error">
                                <span className="Error__text">{formResponseError}</span>
                            </div>
                        </div>
                        <div className="Form__footer">
                            <button className={isHandleSubmit ? 'Form__button Form__button_blue Form__button_left Form__button_disabled' : 'Form__button Form__button_blue Form__button_left'}
                                    type="submit"
                                    style={{
                                        position: "relative"
                                    }}
                                    disabled={isHandleSubmit ? 'disabled' : null}>
                                Добавить
                                <div style={{position: "absolute", right: 6, top: 6}}>{isHandleSubmit ? <Loading white={true} /> : null}</div>
                            </button>
                        </div>
                    </form>
                </div>
            );
        }

        else return null;
    }
}

function mapStateToProps(state) {
    return {
        isLoadForm: state.form.isLoadForm,
        isUpdateForm: state.form.isUpdateForm,
        modalData: state.modal.modalData,
        isRequest: state.api.isRequest,
        responseMessage: state.api.responseMessage,
        responseData: state.api.responseData,
        responseError: state.api.responseError
    }
}

function mapDispatchToProps(dispatch) {
    return {
        formLoad: (isLoadForm) => dispatch(formLoad(isLoadForm)),
        pageUpdate: () => dispatch(pageUpdate()),
        notificationMessage: (message, colorMessage) => dispatch(notificationMessage(message, colorMessage)),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        alertOpen: (alertForm, alertData) => dispatch(alertOpen(alertForm, alertData)),
        modalClose: () => dispatch(modalClose())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalStudentAdd);
