import React from "react";
import {connect} from "react-redux";
import {pageLoad, pageUpdate, pageMount} from "../../../../store/actions/pageAction";
import {apiRequest} from "../../../../store/actions/apiAction";
import {modalOpen} from "../../../../store/actions/modalAction";
import "./TrainingSchedules.scss";
import Loading from "../../../loading/Loading";
import {Link} from "react-router-dom";

class TrainingSchedules extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isMount: true,
            isOpen: false,
            isLoad: false,
            isUpdate: false,
            trainingSchedulesData: null
        };
    }

    componentDidMount() {
        this.props.pageMount();
        this.pageUpdate(this.state.isLoad);
        // this.intervalUpdate = setInterval(this.props.pageUpdate, 180000);
    }

    componentWillUnmount() {
        // console.log('Employees Unmounted');
        this.setState({
            isMount: false
        });
    }

    componentDidUpdate() {
        if (this.state.isMount) {
            const responseData = this.props.responseData;
            // console.log(1 + ' componentDidUpdate');
            if (this.props.isLoad !== this.state.isLoad) {
                // console.log(2 + ' isLoad');
                if (responseData !== this.state.trainingSchedulesData) {
                    // console.log(3 + ' responseData');

                    this.setState({
                        isOpen: true,
                        isLoad: this.props.isLoad,
                        isUpdate: this.props.isUpdate,
                        trainingSchedulesData: responseData
                    });
                }
            }

            else if (this.props.isUpdate !== this.state.isUpdate) {
                // console.log(4 + ' isUpdate');
                this.pageUpdate(this.state.isLoad);

                this.setState({
                    isUpdate: this.props.isUpdate
                });
            }
        }
    }

    pageUpdate(isLoad) {
        this.props.apiRequest('api/cabinet/training/schedules', null, () => {
            this.props.pageLoad(isLoad);
        }, null);
    }

    modalOpen(modalForm, modalData) {
        this.props.modalOpen(modalForm, modalData);
    }

    render() {
        const { isMount, isOpen, trainingSchedulesData } = this.state;

        let modalData = (objectId, objectData) => {
            return {
                objectId: objectId,
                objectData: objectData
            };
        }

        if (isMount) {

            if (!isOpen || !trainingSchedulesData) {
                return (<div className="Page__loader"><i/><Loading/><i/></div>);
            }

            else {

                return (
                    <div className="Page__section">

                        <div className="Section__header">
                            <div className="Header__title">
                                <div className="Title__text">Учебные графики</div>
                            </div>
                        </div>

                        <div className="Section__content">

                            <div className="TrainingSchedules">
                                <div className="TrainingSchedules__body">
                                     <ul className="Body__list">
                                        {trainingSchedulesData.map(trainingSchedule => (
                                            <Link to={"/cabinet/training/schedule/" + trainingSchedule.id} key={trainingSchedule.id}>
                                            <li className="List__item">

                                                    <div className="Item__wrapper">
                                                        <div className="Item__left">
                                                            <div className="Item__block">
                                                                <div className="Item__label Item__label_block Item__label_mini">Назначение графика</div>
                                                                {trainingSchedule.destination_schedule}
                                                            </div>
                                                        </div>

                                                        <div className="Item__right">
                                                            {/*<div className="Item__block Item__block_inline Item__block_margin-left Item__block_padding">*/}
                                                            {/*    <div className="Item__label Item__label_inline">Квалификация</div>*/}
                                                            {/*    <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                                            {/*    <div className="Item__Text">{trainingSchedule.training_qualifications}</div>*/}
                                                            {/*</div>*/}

                                                            {/*<div className="Item__block Item__block_inline Item__block_margin-left Item__block_padding">*/}
                                                            {/*    <div className="Item__label Item__label_inline">Форма</div>*/}
                                                            {/*    <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                                            {/*    <div className="Item__Text">{trainingSchedule.form_training}</div>*/}
                                                            {/*</div>*/}

                                                            {/*<div className="Item__block Item__block_inline Item__block_margin-left Item__block_padding">*/}
                                                            {/*    <div className="Item__label Item__label_inline">Срок</div>*/}
                                                            {/*    <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                                            {/*    <div className="Item__Text">{trainingSchedule.period_training}</div>*/}
                                                            {/*</div>*/}

                                                            <div className="Item__block Item__block_inline Item__block_margin-left Item__block_padding">
                                                                <div className="Item__Text">{trainingSchedule.training_qualifications}</div>
                                                                <div className="Item__hr Item__hr_vertical Item__hr_inline Item__hr_margin-32"/>
                                                                <div className="Item__Text">{trainingSchedule.form_training}</div>
                                                                <div className="Item__hr Item__hr_vertical Item__hr_inline Item__hr_margin-32"/>
                                                                <div className="Item__Text">{trainingSchedule.period_training}</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                            </li>
                                            </Link>
                                        ))}
                                    </ul>
                                </div>
                            </div>

                        </div>

                        <div className="Section__button"
                             onClick={this.modalOpen.bind(this, 'modalTrainingScheduleAdd', modalData(null, null))}>
                            <div className="button"/>
                        </div>
                    </div>
                );
            }
        }

        else return null;
    }
}

function mapStateToProps(state) {
    return {
        isLoad: state.page.isLoad,
        isUpdate: state.page.isUpdate,
        responseData: state.api.responseData
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageMount: () => dispatch(pageMount()),
        pageLoad: (isLoad) => dispatch(pageLoad(isLoad)),
        pageUpdate: () => dispatch(pageUpdate()),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        modalOpen: (modalForm, modalData) => dispatch(modalOpen(modalForm, modalData))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (TrainingSchedules);
