import {FORM_LOAD, FORM_UPDATE} from "./actionTypes";

export function formLoad(isLoadForm) {

    isLoadForm = !isLoadForm;

    return {
        type: FORM_LOAD,
        isLoadForm: isLoadForm
    }
}

export function formUpdate() {

    return {
        type: FORM_UPDATE
    }
}
