import React from "react";
import {connect} from "react-redux";
import {
    profileClose,
    profileOpen,
    profileLoad,
    profileObject,
    profileUpdate,
    profileRequest
} from "../../store/actions/profileAction";
import "./ProfileModal.scss";
import "./Profile.scss"
import "./form/ProfileForm.scss";
import Loading from "../loading/Loading";
import ProfileData from "./form/profileData";
import ProfilePassword from "./form/profilePassword";
import ProfileNotifications from "./form/profileNotifications";
import ProfileSettings from "./form/profileSettings";

class Profile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isMountAnimate: false,
            isMountProfile: true,
            isOpenProfile: false,
            isLoadProfile: false,
            isUpdateProfile: false,
            profileData: null,
            formResponseError: null
        };
    }

    componentDidMount() {
        this.profileUpdate(this.state.isLoadProfile);
        this.intervalUpdate = setInterval(this.props.profileUpdate, 60000);
    }

    componentWillUnmount() {
        // console.log('Profile Unmounted');

        clearInterval(this.intervalUpdate);

        this.setState({
            isMountProfile: false
        });
    }

    componentDidUpdate() {
        if (this.state.isMountProfile) {
            const responseData = this.props.responseData;
            // console.log(1 + ' componentDidUpdate');
            if (this.props.isLoadProfile !== this.state.isLoadProfile) {
                // console.log(2 + ' isLoad');
                if (responseData !== this.state.profileData) {
                    // console.log(3 + ' responseData');

                    this.profileUpdate();

                    this.setState({
                        isOpenProfile: true,
                        isLoadProfile: this.props.isLoadProfile,
                        isUpdateForm: this.props.isUpdateProfile,
                        profileData: responseData
                    }, () => {
                        this.props.profileObject(responseData);
                    });
                }
            }

            else if (this.props.isUpdateProfile !== this.state.isUpdateProfile) {
                // console.log(4 + ' isUpdate');
                this.setState({
                    isUpdateProfile: this.props.isUpdateProfile
                });
                this.profileUpdate(this.state.isLoadProfile);
            }
        }
    }

    profileUpdate(isLoadProfile) {
        this.props.profileRequest('api/cabinet/profile', null, () => {
            this.props.profileLoad(isLoadProfile);
        }, null);
    }

    profileClose() {
        this.props.profileClose();
    }

    switchForm(profileOpenForm) {
        switch(profileOpenForm) {
            case 'formProfileData': return (<ProfileData />);
            case 'formProfileNotifications': return (<ProfileNotifications />);
            case 'formProfileSettings': return (<ProfileSettings />);
            case 'formProfilePassword': return (<ProfilePassword />);
            default: return (<div className="Form Form_null" />);
        }
    }

    isMountAnimate() {
        this.setState({
            isMountAnimate: true
        });
    }

    profileOpen(profileOpenForm) {
        this.props.profileOpen(profileOpenForm);
    }

    render() {
        const { isProfileOpen, profileOpenForm } = this.props;
        const { isMountAnimate, isMountProfile, isOpenProfile, profileData } = this.state;

        let switchForm = this.switchForm(profileOpenForm);

        if (!isProfileOpen) { return null; }

        else {

            if (isMountProfile) {

                setTimeout(this.isMountAnimate.bind(this), 10);

                return (
                    <div className="ProfileModal">
                        {/*<i/>*/}
                        <div className="ProfileModal__dialog"
                             style={{
                                 opacity: (isMountAnimate ? 1 : 0),
                                 paddingTop: (isMountAnimate ? 0 : 192)
                             }}>
                            <div className="ProfileModal__content">
                                <div className="Modal__body">
                                    <div className="Body__menu">
                                        <div className="Menu__container">
                                            <ul>
                                                <li className={profileOpenForm === 'formProfileData' ? "Menu__link active" : "Menu__link"}>
                                                    <div className="a"
                                                         onClick={this.profileOpen.bind(this, 'formProfileData')}>Профиль</div>
                                                </li>
                                                <li className={profileOpenForm === 'formProfileNotifications' ? "Menu__link active" : "Menu__link"}>
                                                    <div className="a"
                                                         onClick={this.profileOpen.bind(this, 'formProfileNotifications')}>Уведомления</div>
                                                </li>
                                                <li className={profileOpenForm === 'formProfileSettings' ? "Menu__link active" : "Menu__link"}>
                                                    <div className="a"
                                                         onClick={this.profileOpen.bind(this, 'formProfileSettings')}>Настройки</div>
                                                </li>
                                                <li className={profileOpenForm === 'formProfilePassword' ? "Menu__link active" : "Menu__link"}>
                                                    <div className="a"
                                                         onClick={this.profileOpen.bind(this, 'formProfilePassword')}>Смена пароля</div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="Body__form">
                                        {!isOpenProfile || !profileData ? (<div className="Body__loading"><Loading/></div>) : switchForm }
                                    </div>
                                </div>
                                <div className="Modal__close">
                                    <div className="Button__close icon-close" onClick={this.profileClose.bind(this)} />
                                </div>
                            </div>

                        </div>
                        {/*<i/>*/}
                    </div>
                );
            }

            else return null;
        }
    }
}

function mapStateToProps(state) {
    return {
        isProfileOpen: state.profile.isProfileOpen,
        profileOpenForm: state.profile.profileOpenForm,
        isLoadProfile: state.profile.isLoadProfile,
        isUpdateProfile: state.profile.isUpdateProfile,
        isRequest: state.profile.isRequest,
        responseMessage: state.profile.responseMessage,
        responseData: state.profile.responseData,
        responseError: state.profile.responseError
    }
}

function mapDispatchToProps(dispatch) {
    return {
        profileOpen: (profileOpenForm) => dispatch(profileOpen(profileOpenForm)),
        profileClose: () => dispatch(profileClose()),
        profileLoad: (isLoadProfile) => dispatch(profileLoad(isLoadProfile)),
        profileObject: (profileData) => dispatch(profileObject(profileData)),
        profileUpdate: () => dispatch(profileUpdate()),
        profileRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(profileRequest(requestURL, formData, callbackSuccess, callbackError)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
