import React from "react";
import {connect} from "react-redux";
import {notificationClear, notificationMessage} from "../../store/actions/notificationAction";
import './Notification.scss';

class Notification extends React.Component {

    componentDidMount() {
        this.props.notificationClear();
    }

    render() {
        const { message, colorMessage } = this.props;

        let notificationMessage;

        function classNames(classes) {
            return Object.entries(classes)
                .filter(([key, value]) => value)
                .map(([key, value]) => key)
                .join(' ');
        }

        let classes;

        if (colorMessage === 'green') {
            classes = {
                'Messages__short': true,
                'black': false,
                'green': true,
                'blue': false,
                'red': false
            };
        }

        else if (colorMessage === 'blue') {
            classes = {
                'Messages__short': true,
                'black': false,
                'green': false,
                'blue': true,
                'red': false
            };
        }

        else if (colorMessage === 'red') {
            classes = {
                'Messages__short': true,
                'black': false,
                'green': false,
                'blue': false,
                'red': true
            };
        }

        else {
            classes = {
                'Messages__short': true,
                'black': true,
                'green': false,
                'blue': false,
                'red': false
            };
        }

        const myClassNames = classNames(classes);

        if (message && colorMessage) {
            notificationMessage = (<div className="Messages__block"><span className={myClassNames}>{message}</span></div>);
        }

        else {
            notificationMessage = null;
        }

        return (
            <div className="Notification">
                {notificationMessage}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        message: state.notification.message,
        colorMessage: state.notification.colorMessage
    }
}

function mapDispatchToProps(dispatch) {
    return {
        notificationMessage: (message, colorMessage) => dispatch(notificationMessage(message, colorMessage)),
        notificationClear: () => dispatch(notificationClear())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
