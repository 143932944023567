import React from "react";
import {connect} from "react-redux";
import {pageLoad, pageUpdate, pageMount} from "../../../../store/actions/pageAction";
import {apiRequest} from "../../../../store/actions/apiAction";
import {modalOpen} from "../../../../store/actions/modalAction";
import "./TrainingPlan.scss";
import Loading from "../../../loading/Loading";
import {Link} from "react-router-dom";

class TrainingPlan extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isMount: true,
            isOpen: false,
            isLoad: false,
            isUpdate: false,
            pageError: null,
            trainingPlanData: null
        };
    }

    componentDidMount() {
        this.props.pageMount();
        this.pageUpdate(this.state.isLoad);
        // this.intervalUpdate = setInterval(this.props.pageUpdate, 180000);
    }

    componentWillUnmount() {
        // console.log('Employees Unmounted');
        this.setState({
            isMount: false
        });
    }

    componentDidUpdate() {
        if (this.state.isMount) {
            const responseData = this.props.responseData;
            // console.log(1 + ' componentDidUpdate');
            if (this.props.isLoad !== this.state.isLoad) {
                // console.log(2 + ' isLoad');
                if (responseData !== this.state.trainingPlanData) {
                    // console.log(3 + ' responseData');

                    this.setState({
                        isOpen: true,
                        isLoad: this.props.isLoad,
                        isUpdate: this.props.isUpdate,
                        trainingPlanData: responseData
                    });
                }
            }

            else if (this.props.isUpdate !== this.state.isUpdate) {
                // console.log(4 + ' isUpdate');
                this.pageUpdate(this.state.isLoad);

                this.setState({
                    isUpdate: this.props.isUpdate
                });
            }
        }
    }

    pageUpdate(isLoad) {
        this.props.apiRequest('api/cabinet/training/plan/' + this.props.match.params.trainingPlanId, null, () => {
            this.props.pageLoad(isLoad);
        }, () => {
            this.setState({
                pageError: this.props.responseError
            });
        });
    }

    modalOpen(modalForm, modalData) {
        this.props.modalOpen(modalForm, modalData);
    }

    render() {
        const { isMount, isOpen, pageError, trainingPlanData } = this.state;

        let modalData = (objectId, objectData) => {
            return {
                objectId: objectId,
                objectData: objectData
            };
        }

        if (isMount) {

            if (pageError) {

                if (!isOpen) {

                    return (
                        <div className="Page__loader">
                            <i/>
                            <div className="Body_nodata">
                                Страница не найдена
                                <div style={{textAlign: 'center', marginTop: 48, fontSize: 32}}>
                                    ERROR 404
                                </div>
                            </div>
                            <i/>
                        </div>
                    );
                }

                else {

                    return (
                        <div className="Page__loader">
                            <i/>
                            <div>
                                <div className="Body_nodata">План удалён</div>
                                <div className="Header__path" style={{textAlign: 'center', marginTop: 48}}>
                                    <Link to={"/cabinet/training/plans"}>
                                        <div className="Path__link Path__link_text Path__link_blue">Учебные планы</div>
                                    </Link>
                                </div>
                            </div>
                            <i/>
                        </div>
                    );
                }
            }

            else {

                if (!isOpen || !trainingPlanData) {
                    return (<div className="Page__loader"><i/><Loading/><i/></div>);
                }

                else {

                    return (
                        <div className="Page__section">

                            <div className="Section__header Section__header_margin-bottom-16">
                                <div className="Header__title">
                                    <div className="Title__text">{trainingPlanData.direction_preparation}</div>

                                    <div className="Button__edit"
                                         onClick={this.modalOpen.bind(this, 'modalTrainingPlanEdit', modalData(trainingPlanData.id, trainingPlanData))}>
                                        {/*<svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">*/}
                                        {/*    <g id="Group_1663" data-name="Group 1663" transform="translate(-1765 -274)">*/}
                                        {/*        <circle id="Ellipse_62" data-name="Ellipse 62" cx="21" cy="21" r="21" transform="translate(1765 274)" fill="#2a3e4c" opacity="0"/>*/}
                                        {/*        <g id="edit.0a0e73c359245f3e48551d2cb2a488db" transform="translate(1774 283)">*/}
                                        {/*            <path id="Path_11" data-name="Path 11" d="M2.53,15.144a1.973,1.973,0,0,1,.57-1.216l8.56-8.56,1.395,1.395L5.384,14.432l1.395,1.395,7.671-7.671,1.395,1.395L8.173,17.222l1.395,1.395,7.671-7.671,1.395,1.395-8.56,8.56a1.973,1.973,0,0,1-1.216.57L3.076,22A.986.986,0,0,1,2,20.924ZM7.752,19.59l-2.947.268A1.131,1.131,0,0,0,4.5,19.5c-.25-.219-.366-.219-.366-.219l.276-3.033Z" fill="#373b50" fillRule="evenodd"/>*/}
                                        {/*            <path id="Path_12" data-name="Path 12" d="M13.054,3.972l1.395-1.395a1.972,1.972,0,0,1,2.789,0l4.184,4.184a1.972,1.972,0,0,1,0,2.789l-1.395,1.395Z" fill="#373b50"/>*/}
                                        {/*        </g>*/}
                                        {/*    </g>*/}
                                        {/*</svg>*/}

                                        <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">
                                            <g id="Group_2240" data-name="Group 2240" transform="translate(-471 -775)">
                                                <rect id="Rectangle_2757" data-name="Rectangle 2757" width="42" height="42" transform="translate(471 775)" fill="#f7f7f7" opacity="0"/>
                                                <g id="Group_2239" data-name="Group 2239" transform="translate(50 28)">
                                                    <path id="Path_965" data-name="Path 965" d="M2.657,17.495a2.447,2.447,0,0,1,.707-1.509L13.983,5.367l1.73,1.73L6.2,16.612l1.73,1.73,9.515-9.515,1.73,1.73L9.658,20.073l1.73,1.73L20.9,12.287l1.73,1.73L12.014,24.636a2.447,2.447,0,0,1-1.509.707l-7.171.652a1.223,1.223,0,0,1-1.329-1.329Zm6.478,5.516-3.656.332A1.4,1.4,0,0,0,5.1,22.9c-.31-.272-.454-.272-.454-.272l.342-3.762Z" transform="translate(428 754)" fill="#2a3e4c" fillRule="evenodd"/>
                                                    <path id="Path_966" data-name="Path 966" d="M13.054,4.447l1.731-1.731a2.447,2.447,0,0,1,3.461,0l5.192,5.192a2.447,2.447,0,0,1,0,3.461L21.707,13.1Z" transform="translate(429.852 754)" fill="#2a3e4c"/>
                                                </g>
                                            </g>
                                        </svg>


                                    </div>
                                </div>
                            </div>

                            <div className="Section__content">

                                <div className="TrainingPlan">
                                    <div className="TrainingPlan__body">

                                        <div className="Block__Item">
                                            <div className="Item__wrapper">
                                                <div className="Item__block Item__block_inline Item__block_margin-right Item__block_padding">
                                                    <div className="Item__label Item__label_inline">Срок</div>
                                                    <div className="Item__hr Item__hr_vertical Item__hr_inline"/>
                                                    <div className="Item__Text" style={{fontSize: 24, fontWeight: 900}}>{trainingPlanData.period_training}</div>
                                                </div>

                                                <div className="Item__block Item__block_inline Item__block_margin-right Item__block_padding">
                                                    <div className="Item__label Item__label_inline">Форма</div>
                                                    <div className="Item__hr Item__hr_vertical Item__hr_inline"/>
                                                    <div className="Item__Text" style={{fontSize: 24, fontWeight: 900}}>{trainingPlanData.form_training}</div>
                                                </div>

                                                <div className="Item__block Item__block_inline Item__block_margin-right Item__block_padding">
                                                    <div className="Item__label Item__label_inline">Квалификация</div>
                                                    <div className="Item__hr Item__hr_vertical Item__hr_inline"/>
                                                    <div className="Item__Text" style={{fontSize: 24, fontWeight: 900}}>{trainingPlanData.training_qualifications}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="Body__hr"/>

                                        {trainingPlanData.disciplines_plan ? (
                                            trainingPlanData.disciplines_plan.map((disciplines_plan, index) => (
                                                <React.Fragment key={index}>
                                                    <div className="Body__label">{disciplines_plan.year_program} курс, {disciplines_plan.period_program} семестр</div>
                                                    <ul className="Body__list">
                                                        {disciplines_plan.disciplines.map(discipline => (
                                                            <li className="List__item" key={discipline.id} onClick={this.modalOpen.bind(this, 'modalTrainingPlanDisciplineEdit', modalData(trainingPlanData, discipline))}>
                                                                {discipline.discipline_index ? (<div className="Item__index">{discipline.discipline_index}</div>) : null}
                                                                <div className="Item__wrapper">
                                                                    <div className="Item__left">
                                                                        <div style={{
                                                                                paddingTop: 7
                                                                            }}
                                                                             className="Item__block Item__block_inline">
                                                                            {discipline.discipline_name}
                                                                        </div>
                                                                        {Number(discipline.anchor_of_choice) > 0 ? (
                                                                            <div style={{
                                                                                    paddingTop: 5
                                                                                }}
                                                                                 className="Item__block Item__block_inline Item__block_margin-left">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="12.599" height="26.857" viewBox="0 0 12.599 26.857">
                                                                                    <g id="Group_1635" data-name="Group 1635" transform="translate(-89.692 -1130.28) rotate(30)">
                                                                                        <g id="Group_1633" data-name="Group 1633" transform="translate(809.485 527.485) rotate(90)">
                                                                                            <rect id="Rectangle_1389" data-name="Rectangle 1389" width="13" height="4" rx="2" transform="translate(404 156)" fill="#0061ff"/>
                                                                                            <g id="Group_1341" data-name="Group 1341" transform="translate(-9.314 308.614) rotate(-45)">
                                                                                                <rect id="Rectangle_1390" data-name="Rectangle 1390" width="10" height="4" rx="2" transform="translate(397 184)" fill="#0061ff"/>
                                                                                                <rect id="Rectangle_1391" data-name="Rectangle 1391" width="4" height="10" rx="2" transform="translate(397 184)" fill="#0061ff"/>
                                                                                            </g>
                                                                                        </g>
                                                                                        <g id="Group_1634" data-name="Group 1634" transform="translate(500.485 1357.485) rotate(-90)">
                                                                                            <rect id="Rectangle_1389-2" data-name="Rectangle 1389" width="13" height="4" rx="2" transform="translate(404 156)" fill="#0061ff"/>
                                                                                            <g id="Group_1341-2" data-name="Group 1341" transform="translate(-9.314 308.614) rotate(-45)">
                                                                                                <rect id="Rectangle_1390-2" data-name="Rectangle 1390" width="10" height="4" rx="2" transform="translate(397 184)" fill="#0061ff"/>
                                                                                                <rect id="Rectangle_1391-2" data-name="Rectangle 1391" width="4" height="10" rx="2" transform="translate(397 184)" fill="#0061ff"/>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </svg>
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                    <div className="Item__right">
                                                                        <div className="Item__block Item__block_inline Item__block_margin-left ">
                                                                            {Number(discipline.control_exam) > 0 ? (
                                                                                <React.Fragment>
                                                                                    <div className="Item__hr Item__hr_vertical Item__hr_inline Item__hr_margin-32"/>
                                                                                    <div className="Item__Text">Экзамен</div>
                                                                                </React.Fragment>
                                                                            ) : null}
                                                                            {Number(discipline.control_diff) > 0 ? (
                                                                                <React.Fragment>
                                                                                    <div className="Item__hr Item__hr_vertical Item__hr_inline Item__hr_margin-32"/>
                                                                                    <div className="Item__Text">Диф. зачет</div>
                                                                                </React.Fragment>
                                                                            ) : null}
                                                                            {Number(discipline.control_test) > 0 ? (
                                                                                <React.Fragment>
                                                                                    <div className="Item__hr Item__hr_vertical Item__hr_inline Item__hr_margin-32"/>
                                                                                    <div className="Item__Text">Зачет</div>
                                                                                </React.Fragment>
                                                                            ) : null}
                                                                            {Number(discipline.control_project) > 0 ? (
                                                                                <React.Fragment>
                                                                                    <div className="Item__hr Item__hr_vertical Item__hr_inline Item__hr_margin-32"/>
                                                                                    <div className="Item__Text">Курсовой проект</div>
                                                                                </React.Fragment>
                                                                            ) : null}
                                                                            {Number(discipline.control_coursework) > 0 ? (
                                                                                <React.Fragment>
                                                                                    <div className="Item__hr Item__hr_vertical Item__hr_inline Item__hr_margin-32"/>
                                                                                    <div className="Item__Text">Курсовая работа</div>
                                                                                </React.Fragment>
                                                                            ) : null}
                                                                            <div className="Item__hr Item__hr_vertical Item__hr_inline Item__hr_margin-32"/>
                                                                            <div className="Item__Text">{Number(discipline.lecture_hours) + Number(discipline.laboratory_hours) + Number(discipline.practical_hours) + Number(discipline.ciw_hours) + Number(discipline.iws_hours)}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </React.Fragment>
                                            ))
                                        ) : null}
                                    </div>
                                </div>
                            </div>

                            <div className="Section__button"
                                 onClick={this.modalOpen.bind(this, 'modalTrainingPlanDisciplineAdd', modalData(trainingPlanData.id, trainingPlanData))}>
                                <div className="button"/>
                            </div>
                        </div>
                    );
                }
            }
        }

        else return null;
    }
}

function mapStateToProps(state) {
    return {
        isLoad: state.page.isLoad,
        isUpdate: state.page.isUpdate,
        responseData: state.api.responseData,
        responseError:  state.api.responseError
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageMount: () => dispatch(pageMount()),
        pageLoad: (isLoad) => dispatch(pageLoad(isLoad)),
        pageUpdate: () => dispatch(pageUpdate()),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        modalOpen: (modalForm, modalData) => dispatch(modalOpen(modalForm, modalData))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (TrainingPlan);
