import React from "react";
import {connect} from "react-redux";
import {modalClose} from "../../../../../store/actions/modalAction";
import {notificationMessage} from "../../../../../store/actions/notificationAction";
import {apiRequest} from "../../../../../store/actions/apiAction";
import {pageUpdate} from "../../../../../store/actions/pageAction";
import Loading from "../../../../loading/Loading";
import {formLoad} from "../../../../../store/actions/formAction";
import {alertOpen} from "../../../../../store/actions/alertAction";

class ModalTeacherGradebookResit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isMountForm: true,
            isOpenForm: false,
            isLoadForm: false,
            isUpdateForm: false,
            formData: null,
            isHandleSubmit: false,
            objectId: this.props.modalData.objectId,
            objectData: this.props.modalData.objectData,
            formResponseError: null,

            trainingDisciplineId: 0,
            trainingGroupId: 0,
            trainingProgramId: 0,
            trainingCoursesCurrentData: null,
            trainingGroupData: [],
            trainingProgramData: [],
            trainingDisciplineData: [],


            studentId: 0,

            knowledgeAssessment: 0,
            dataAssessment: null,
            projectTitle: null,
        };
    }

    componentDidMount() {
        // this.formUpdate(this.state.isLoadForm);

        this.trainingCoursesCurrentData();
    }

    componentWillUnmount() {
        // console.log('From Unmounted');
        this.setState({
            isMountForm: false
        });
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }



    controlTestAdd() {
        if ((Number(this.state.knowledgeAssessment) === 0) || (this.state.dataAssessment === '') || (this.state.dataAssessment === null) || (this.state.dataAssessment === "null")) {

            this.props.notificationMessage('Укажите оценку и дату сдачи', 'red');
            return;
        }

        this.setState({
            isHandleSubmit: true
        });

        let formData = new FormData();
        formData.append('knowledge_assessment', this.state.knowledgeAssessment);
        formData.append('data_assessment', this.state.dataAssessment);

        this.props.apiRequest('api/cabinet/gradebook/' + this.state.objectId + '/resit', formData, () => {
                this.setState({
                    isHandleSubmit: false,
                    formResponseError: null
                });
                this.props.notificationMessage(this.props.responseMessage, 'green');
                this.props.pageUpdate();
                this.props.modalClose();
            }, () => {
                this.setState({
                    isHandleSubmit: false,
                    formResponseError: this.props.responseError
                });
            }
        );
    }

    controlDiffAdd() {
        if ((Number(this.state.knowledgeAssessment) === 0) || (this.state.dataAssessment === '') || (this.state.dataAssessment === null) || (this.state.dataAssessment === "null")) {

            this.props.notificationMessage('Укажите оценку и дату сдачи', 'red');
            return;
        }

        this.setState({
            isHandleSubmit: true
        });

        let formData = new FormData();
        formData.append('knowledge_assessment', this.state.knowledgeAssessment);
        formData.append('data_assessment', this.state.dataAssessment);

        this.props.apiRequest('api/cabinet/gradebook/' + this.state.objectId + '/resit', formData, () => {
                this.setState({
                    isHandleSubmit: false,
                    formResponseError: null
                });
                this.props.notificationMessage(this.props.responseMessage, 'green');
                this.props.pageUpdate();
                this.props.modalClose();
            }, () => {
                this.setState({
                    isHandleSubmit: false,
                    formResponseError: this.props.responseError
                });
            }
        );
    }

    controlExamAdd() {
        if ((Number(this.state.knowledgeAssessment) === 0) || (this.state.dataAssessment === '') || (this.state.dataAssessment === null) || (this.state.dataAssessment === "null")) {

            this.props.notificationMessage('Укажите оценку и дату сдачи', 'red');
            return;
        }

        this.setState({
            isHandleSubmit: true
        });

        let formData = new FormData();
        formData.append('knowledge_assessment', this.state.knowledgeAssessment);
        formData.append('data_assessment', this.state.dataAssessment);

        this.props.apiRequest('api/cabinet/gradebook/' + this.state.objectId + '/resit', formData, () => {
                this.setState({
                    isHandleSubmit: false,
                    formResponseError: null
                });
                this.props.notificationMessage(this.props.responseMessage, 'green');
                this.props.pageUpdate();
                this.props.modalClose();
            }, () => {
                this.setState({
                    isHandleSubmit: false,
                    formResponseError: this.props.responseError
                });
            }
        );
    }

    controlProjectAdd() {
        if ((Number(this.state.knowledgeAssessment) === 0) ||
            (this.state.dataAssessment === '') ||
            (this.state.dataAssessment === null) ||
            (this.state.dataAssessment === "null") ||
            (this.state.projectTitle === '') ||
            (this.state.projectTitle === null) ||
            (this.state.projectTitle === "null")) {

            this.props.notificationMessage('Укажите оценку, дату сдачи, и название работы', 'red');
            return;
        }

        this.setState({
            isHandleSubmit: true
        });

        let formData = new FormData();
        formData.append('knowledge_assessment', this.state.knowledgeAssessment);
        formData.append('data_assessment', this.state.dataAssessment);
        formData.append('project_title', this.state.projectTitle);

        this.props.apiRequest('api/cabinet/gradebook/' + this.state.objectId + '/resit', formData, () => {
                this.setState({
                    isHandleSubmit: false,
                    formResponseError: null
                });
                this.props.notificationMessage(this.props.responseMessage, 'green');
                this.props.pageUpdate();
                this.props.modalClose();
            }, () => {
                this.setState({
                    isHandleSubmit: false,
                    formResponseError: this.props.responseError
                });
            }
        );
    }

    controlCourseworkAdd() {
        if ((Number(this.state.knowledgeAssessment) === 0) ||
            (this.state.dataAssessment === '') ||
            (this.state.dataAssessment === null) ||
            (this.state.dataAssessment === "null") ||
            (this.state.projectTitle === '') ||
            (this.state.projectTitle === null) ||
            (this.state.projectTitle === "null")) {

            this.props.notificationMessage('Укажите оценку, дату сдачи, и название работы', 'red');
            return;
        }

        this.setState({
            isHandleSubmit: true
        });

        let formData = new FormData();
        formData.append('knowledge_assessment', this.state.knowledgeAssessment);
        formData.append('data_assessment', this.state.dataAssessment);
        formData.append('project_title', this.state.projectTitle);

        this.props.apiRequest('api/cabinet/gradebook/' + this.state.objectId + '/resit', formData, () => {
                this.setState({
                    isHandleSubmit: false,
                    formResponseError: null
                });
                this.props.notificationMessage(this.props.responseMessage, 'green');
                this.props.pageUpdate();
                this.props.modalClose();
            }, () => {
                this.setState({
                    isHandleSubmit: false,
                    formResponseError: this.props.responseError
                });
            }
        );
    }

    controlDelete(id) {
        this.setState({
            isHandleSubmit: true
        });

        // let formData = new FormData();
        // formData.append('control_type', 'test');
        // formData.append('project_title', null);
        // formData.append('knowledge_assessment', this.state.knowledgeAssessment);
        // formData.append('data_assessment', this.state.dataAssessment);
        // formData.append('employee_id', this.state.employeeId);

        this.props.apiRequest('api/cabinet/student/' + this.state.objectId + '/discipline/' + this.state.objectData.id + '/control/' + id + '/delete', null, () => {
                this.setState({
                    isHandleSubmit: false,
                    alertResponseError: null
                });
                this.props.alertClose();
                this.props.notificationMessage(this.props.responseMessage, 'green');
                this.props.formUpdate();
                this.props.pageUpdate();
                // this.props.modalClose();
            }, () => {
                this.setState({
                    isHandleSubmit: false,
                    alertResponseError: this.props.responseError
                });
            }
        );
    }

    // componentDidUpdate() {
    //     if (this.state.isMountForm) {
    //         const responseData = this.props.responseData;
    //         // console.log(1 + ' componentDidUpdate');
    //         if (this.props.isLoadForm !== this.state.isLoadForm) {
    //             // console.log(2 + ' isLoad');
    //             if (responseData !== this.state.formData) {
    //                 // console.log(3 + ' responseData');
    //
    //                 this.formUpdate();
    //
    //                 this.setState({
    //                     isOpenForm: true,
    //                     isLoadForm: this.props.isLoadForm,
    //                     isUpdateForm: this.props.isUpdateForm,
    //                     formData: responseData
    //                 });
    //             }
    //         }
    //
    //         else if (this.props.isUpdateForm !== this.state.isUpdateForm) {
    //             // console.log(4 + ' isUpdate');
    //             this.setState({
    //                 isUpdateForm: this.props.isUpdateForm
    //             });
    //             this.formUpdate(this.state.isLoadForm);
    //         }
    //     }
    // }
    //
    // formUpdate(isLoadForm) {
    //     this.props.apiRequest('/company/' + this.state.objectId + '/supports_date', null, () => {
    //         this.props.formLoad(isLoadForm);
    //     }, null);
    // }


    handleInputChangeProgram(event) {
        const target = event.target;
        const value = target.value;

        this.setState({
            trainingProgramId: value,
            trainingGroupId: 0,
            trainingDisciplineId: 0
        });
    }

    handleInputChangeGroup(event) {
        const target = event.target;
        const value = target.value;

        let trainingProgramId = 0;

        this.state.trainingGroupData.map((trainingGroup) => {
            if (Number(trainingGroup.id) === Number(value)) {
                trainingProgramId = trainingGroup.training_program_id;
            }
        });

        this.setState({
            trainingGroupId: value,
            trainingProgramId: trainingProgramId
        });
    }

    handleInputChangeDiscipline(event) {
        const target = event.target;
        const value = target.value;

        let trainingProgramId = 0;

        this.state.trainingDisciplineData.map((trainingDiscipline) => {
            if (Number(trainingDiscipline.id) === Number(value)) {
                trainingProgramId = trainingDiscipline.training_program_id;
            }
        });

        this.setState({
            trainingDisciplineId: value,
            trainingProgramId: trainingProgramId
        });
    }

    // setStateAndInput(name, value) {
    //
    //     let input = document.getElementById(name);
    //     input.value = value;
    //
    //     this.setState({
    //         [name]: value
    //     });
    // }

    // handleSubmit(event) {
    //     event.preventDefault();
    //
    //     if (Number(this.state.studentId) > 0) {
    //
    //         let formData = new FormData();
    //
    //         formData.append('student_id', this.state.studentId);
    //
    //         this.setState({
    //             isHandleSubmit: true
    //         });
    //
    //         this.props.apiRequest('api/cabinet/teacher/personal/chat/add', formData, () => {
    //                 // this.setStateAndInput('', null);
    //                 this.setState({
    //                     isHandleSubmit: false,
    //                     formResponseError: null
    //                 });
    //                 // this.formUpdate(this.state.isLoadForm);
    //                 this.props.modalClose();
    //                 this.props.notificationMessage(this.props.responseMessage, 'green');
    //                 this.props.pageUpdate();
    //             }, () => {
    //                 this.setState({
    //                     isHandleSubmit: false,
    //                     formResponseError: this.props.responseError
    //                 });
    //             }
    //         );
    //
    //     }
    //
    //     else {
    //         this.setState({
    //             formResponseError: 'Пожалуйста, выберите кому хотите написать.'
    //         });
    //     }
    // }

    modalClose() {
        this.props.modalClose();
    }

    // alertOpen(alertForm, alertData) {
    //     this.props.alertOpen(alertForm, alertData);
    // }

    trainingCoursesCurrentData() {
        this.props.apiRequest('api/cabinet/students', null, () => {
            this.setState({
                trainingCoursesCurrentData: this.props.responseData
            }, () => {
                // this.trainingGroupData();
                // this.trainingProgramData();
                // this.trainingDisciplineData();
            });
        }, null);
    }

    trainingGroupData() {
        const trainingCoursesCurrentData = this.state.trainingCoursesCurrentData;
        let trainingGroupData = [];

        trainingCoursesCurrentData.map((trainingCoursesCurrent) => {
            let object = {
                id: trainingCoursesCurrent.id,
                training_program_id: trainingCoursesCurrent.training_program_id,
                group_name: trainingCoursesCurrent.group_name,
                initial_year: trainingCoursesCurrent.initial_year,
                direction_preparation: trainingCoursesCurrent.training_program.direction_preparation
            }

            trainingGroupData.push(object);
        });

        trainingGroupData = this.uniq(trainingGroupData);

        this.setState({
            trainingGroupData: trainingGroupData
        });
    }

    trainingProgramData() {
        const trainingCoursesCurrentData = this.state.trainingCoursesCurrentData;
        let trainingProgramData = [];

        trainingCoursesCurrentData.map((trainingCoursesCurrent) => {
            let object = {
                id: trainingCoursesCurrent.training_program.id,
                direction_preparation: trainingCoursesCurrent.training_program.direction_preparation,
                training_qualifications: trainingCoursesCurrent.training_program.training_qualifications,
                form_training: trainingCoursesCurrent.training_program.form_training,
                period_training: trainingCoursesCurrent.training_program.period_training
            }

            trainingProgramData.push(object);
        });

        trainingProgramData = this.uniq(trainingProgramData);

        this.setState({
            trainingProgramData: trainingProgramData
        });
    }

    trainingDisciplineData() {
        const trainingCoursesCurrentData = this.state.trainingCoursesCurrentData;
        let trainingDisciplineData = [];

        trainingCoursesCurrentData.map((trainingCoursesCurrent) => {
            trainingCoursesCurrent.training_program.disciplines_plan.map((discipline) => {
                discipline.direction_preparation = trainingCoursesCurrent.training_program.direction_preparation
                trainingDisciplineData.push(discipline);
            });
        });

        trainingDisciplineData = this.uniq(trainingDisciplineData);

        this.setState({
            trainingDisciplineData: trainingDisciplineData
        });
    }

    uniq(a) {
        let seen = {};
        return a.filter(function(item) {
            return seen.hasOwnProperty(item.id) ? false : (seen[item.id] = true);
        });
    }

    render() {
        const {
            isMountForm,
            // isOpenForm,
            // formData,
            isHandleSubmit,
            formResponseError,

            trainingDisciplineId,
            trainingGroupId,
            trainingProgramId,
            trainingCoursesCurrentData,
            trainingGroupData,
            trainingProgramData,
            trainingDisciplineData,

            studentId,
            objectData,

            isTrue,

            employeeId,
            employeesData,

            knowledgeAssessment
        } = this.state;

        // let alertData = (objectId, objectData) => {
        //     return {
        //         objectId: objectId,
        //         objectData: objectData
        //     };
        // }

        let groupName = (groupName, initialYear) => {
            let course;

            let date = new Date();
            course = (date.getFullYear() - Number(initialYear) + 1);

            if (groupName.search('\$') > 0) {
                return groupName.replace(/\$/i, course);
            }

            else {
                return groupName;
            }
        }

        if (isMountForm) {

            // {!isOpenForm ? (<Loading/>) : (
            //     formData ? () : null)}

            return (
                <div className="Form" style={{width: 340}}>
                    <div>
                        <div className="Form__header">
                            <div className="Header__title" style={{fontSize: 24, fontWeight: 900}}>{objectData.discipline_name}</div>
                        </div>
                        <div className="Form__content">

                            <div className="Content__hr Content__hr_bottom-32"/>







                            {objectData.control_type === 'exam' ? (
                                <React.Fragment>

                                    <div className="Box" style={{marginBottom: 24}}>
                                        <div className="Box__title" style={{
                                            fontFamily: "Roboto, sans-serif",
                                            fontWeight: 700,
                                            fontSize: 20,
                                            color: "#2A3E4C"
                                        }}>Экзамен</div>
                                    </div>



                                    <div className="Content__box">
                                        <div className="Form__field">
                                            <div className="Field__label">Оценка</div>

                                            <input className="Field__input"
                                                   name="knowledgeAssessment"
                                                   type="number"
                                                   style={{
                                                       width: 304
                                                   }}
                                                   max={5}
                                                   min={0}
                                                   step={1}
                                                   defaultValue={0}
                                                   value={this.state.value}
                                                   onChange={this.handleInputChange.bind(this)}/>
                                        </div>
                                    </div>

                                    <div className="Content__box">
                                        <div className="Form__field">
                                            <div className="Field__label">Дата сдачи</div>
                                            <input className="Field__input"
                                                   name="dataAssessment"
                                                   type="text"
                                                   style={{
                                                       width: 304
                                                   }}
                                                   placeholder="дд.мм.гггг"
                                                   value={this.state.value}
                                                   onChange={this.handleInputChange.bind(this)}/>
                                        </div>
                                    </div>

                                </React.Fragment>
                            ) : null}



                            {objectData.control_type === 'diff' ? (
                                <React.Fragment>

                                    <div className="Box" style={{marginBottom: 24}}>
                                        <div className="Box__title" style={{
                                            fontFamily: "Roboto, sans-serif",
                                            fontWeight: 700,
                                            fontSize: 20,
                                            color: "#2A3E4C"
                                        }}>Диф. зачет</div>
                                    </div>



                                    <div className="Content__box">
                                        <div className="Form__field">
                                            <div className="Field__label">Оценка</div>

                                            <input className="Field__input"
                                                   name="knowledgeAssessment"
                                                   type="number"
                                                   style={{
                                                       width: 304
                                                   }}
                                                   max={5}
                                                   min={0}
                                                   step={1}
                                                   defaultValue={0}
                                                   value={this.state.value}
                                                   onChange={this.handleInputChange.bind(this)}/>
                                        </div>
                                    </div>

                                    <div className="Content__box">
                                        <div className="Form__field">
                                            <div className="Field__label">Дата сдачи</div>
                                            <input className="Field__input"
                                                   name="dataAssessment"
                                                   type="text"
                                                   style={{
                                                       width: 304
                                                   }}
                                                   placeholder="дд.мм.гггг"
                                                   value={this.state.value}
                                                   onChange={this.handleInputChange.bind(this)}/>
                                        </div>
                                    </div>

                                </React.Fragment>
                            ) : null}


                            {objectData.control_type === 'test' ? (
                                <React.Fragment>

                                    <div className="Box" style={{marginBottom: 24}}>
                                        <div className="Box__title" style={{
                                            fontFamily: "Roboto, sans-serif",
                                            fontWeight: 700,
                                            fontSize: 20,
                                            color: "#2A3E4C"
                                        }}>Зачет</div>
                                    </div>



                                    <div className="Content__box">
                                        <div className="Form__field">
                                            <div className="Field__label">Оценка</div>
                                            <select className="Field__select"
                                                    id="knowledgeAssessment"
                                                    name="knowledgeAssessment"
                                                    value={knowledgeAssessment}
                                                    style={{
                                                        width: 340
                                                    }}
                                                    onChange={this.handleInputChange.bind(this)}>
                                                <option value="0">Ведомость</option>
                                                <option  className="option__hr" disabled />
                                                <option value="1">Не явка</option>
                                                <option  className="option__hr" disabled />
                                                <option value="2">Не зачет</option>
                                                <option  className="option__hr" disabled />
                                                <option value="5">Зачет</option>
                                                <option  className="option__hr" disabled />
                                            </select>
                                        </div>
                                    </div>

                                    <div className="Content__box">
                                        <div className="Form__field">
                                            <div className="Field__label">Дата сдачи</div>
                                            <input className="Field__input"
                                                   name="dataAssessment"
                                                   type="text"
                                                   style={{
                                                       width: 304
                                                   }}
                                                   placeholder="дд.мм.гггг"
                                                   value={this.state.value}
                                                   onChange={this.handleInputChange.bind(this)}/>
                                        </div>
                                    </div>

                                </React.Fragment>
                            ) : null}

                            {objectData.control_type === 'project' ? (
                                <React.Fragment>
                                    <div className="Content__hr Content__hr_bottom-32" style={{marginTop: 32}}/>

                                    <div className="Box" style={{marginBottom: 24}}>
                                        <div className="Box__title" style={{
                                            fontFamily: "Roboto, sans-serif",
                                            fontWeight: 700,
                                            fontSize: 20,
                                            color: "#2A3E4C"
                                        }}>Курсовой проект</div>
                                    </div>

                                    <div className="Content__box">
                                        <div className="Form__field">
                                            <div className="Field__label">Название проекта</div>
                                            <input className="Field__input"
                                                   name="projectTitle"
                                                   type="text"
                                                   style={{
                                                       width: 304
                                                   }}
                                                   value={this.state.value}
                                                   onChange={this.handleInputChange.bind(this)}/>
                                        </div>
                                    </div>



                                    <div className="Content__box">
                                        <div className="Form__field">
                                            <div className="Field__label">Оценка</div>

                                            <input className="Field__input"
                                                   name="knowledgeAssessment"
                                                   type="number"
                                                   style={{
                                                       width: 304
                                                   }}
                                                   max={5}
                                                   min={0}
                                                   step={1}
                                                   defaultValue={0}
                                                   value={this.state.value}
                                                   onChange={this.handleInputChange.bind(this)}/>
                                        </div>
                                    </div>

                                    <div className="Content__box">
                                        <div className="Form__field">
                                            <div className="Field__label">Дата сдачи</div>
                                            <input className="Field__input"
                                                   name="dataAssessment"
                                                   type="text"
                                                   style={{
                                                       width: 304
                                                   }}
                                                   placeholder="дд.мм.гггг"
                                                   value={this.state.value}
                                                   onChange={this.handleInputChange.bind(this)}/>
                                        </div>
                                    </div>
                                </React.Fragment>
                            ) : null}

                            {objectData.control_type === 'coursework' ? (
                                <React.Fragment>
                                    <div className="Content__hr Content__hr_bottom-32" style={{marginTop: 32}}/>

                                    <div className="Box" style={{marginBottom: 24}}>
                                        <div className="Box__title" style={{
                                            fontFamily: "Roboto, sans-serif",
                                            fontWeight: 700,
                                            fontSize: 20,
                                            color: "#2A3E4C"
                                        }}>Курсовая работа</div>
                                    </div>

                                    <div className="Content__box">
                                        <div className="Form__field">
                                            <div className="Field__label">Название проекта</div>
                                            <input className="Field__input"
                                                   name="projectTitle"
                                                   type="text"
                                                   style={{
                                                       width: 304
                                                   }}
                                                   value={this.state.value}
                                                   onChange={this.handleInputChange.bind(this)}/>
                                        </div>
                                    </div>



                                    <div className="Content__box">
                                        <div className="Form__field">
                                            <div className="Field__label">Оценка</div>

                                            <input className="Field__input"
                                                   name="knowledgeAssessment"
                                                   type="number"
                                                   style={{
                                                       width: 304
                                                   }}
                                                   max={5}
                                                   min={0}
                                                   step={1}
                                                   defaultValue={0}
                                                   value={this.state.value}
                                                   onChange={this.handleInputChange.bind(this)}/>
                                        </div>
                                    </div>

                                    <div className="Content__box">
                                        <div className="Form__field">
                                            <div className="Field__label">Дата сдачи</div>
                                            <input className="Field__input"
                                                   name="dataAssessment"
                                                   type="text"
                                                   style={{
                                                       width: 304
                                                   }}
                                                   placeholder="дд.мм.гггг"
                                                   value={this.state.value}
                                                   onChange={this.handleInputChange.bind(this)}/>
                                        </div>
                                    </div>
                                </React.Fragment>
                            ) : null}























                            <div className="Content__error">
                                <span className="Error__text">{formResponseError}</span>
                            </div>
                        </div>
                        <div className="Form__footer">

                            {objectData.control_type === 'exam' ? (
                                <button className={isHandleSubmit ? 'Form__button Form__button_blue Form__button_left Form__button_disabled' : 'Form__button Form__button_blue Form__button_left'}
                                        type="submit"
                                        disabled={isHandleSubmit ? 'disabled' : null}
                                        onClick={this.controlExamAdd.bind(this)}
                                        style={{position: "relative"}}>
                                    Поставить
                                    <div style={{position: "absolute", right: 6, top: 6}}>{isHandleSubmit ? <Loading white={true} /> : null}</div>
                                </button>
                            ) : null}

                            {objectData.control_type === 'diff' ? (
                                <button className={isHandleSubmit ? 'Form__button Form__button_blue Form__button_left Form__button_disabled' : 'Form__button Form__button_blue Form__button_left'}
                                        type="submit"
                                        disabled={isHandleSubmit ? 'disabled' : null}
                                        onClick={this.controlDiffAdd.bind(this)}
                                        style={{position: "relative"}}>
                                    Поставить
                                    <div style={{position: "absolute", right: 6, top: 6}}>{isHandleSubmit ? <Loading white={true} /> : null}</div>
                                </button>
                            ) : null}

                            {objectData.control_type === 'test' ? (
                                <button className={isHandleSubmit ? 'Form__button Form__button_blue Form__button_left Form__button_disabled' : 'Form__button Form__button_blue Form__button_left'}
                                        type="submit"
                                        disabled={isHandleSubmit ? 'disabled' : null}
                                        onClick={this.controlTestAdd.bind(this)}
                                        style={{position: "relative"}}>
                                    Поставить
                                    <div style={{position: "absolute", right: 6, top: 6}}>{isHandleSubmit ? <Loading white={true} /> : null}</div>
                                </button>
                            ) : null}

                            {objectData.control_type === 'project' ? (
                                <button className={isHandleSubmit ? 'Form__button Form__button_blue Form__button_left Form__button_disabled' : 'Form__button Form__button_blue Form__button_left'}
                                        type="submit"
                                        disabled={isHandleSubmit ? 'disabled' : null}
                                        onClick={this.controlProjectAdd.bind(this)}
                                        style={{position: "relative"}}>
                                    Поставить
                                    <div style={{position: "absolute", right: 6, top: 6}}>{isHandleSubmit ? <Loading white={true} /> : null}</div>
                                </button>
                            ) : null}

                            {objectData.control_type === 'coursework' ? (
                                <button className={isHandleSubmit ? 'Form__button Form__button_blue Form__button_left Form__button_disabled' : 'Form__button Form__button_blue Form__button_left'}
                                        type="submit"
                                        disabled={isHandleSubmit ? 'disabled' : null}
                                        onClick={this.controlCourseworkAdd.bind(this)}
                                        style={{position: "relative"}}>
                                    Поставить
                                    <div style={{position: "absolute", right: 6, top: 6}}>{isHandleSubmit ? <Loading white={true} /> : null}</div>
                                </button>
                            ) : null}

                        </div>
                    </div>
                </div>
            );
        }

        else return null;
    }
}

function mapStateToProps(state) {
    return {
        isLoadForm: state.form.isLoadForm,
        isUpdateForm: state.form.isUpdateForm,
        modalData: state.modal.modalData,
        isRequest: state.api.isRequest,
        responseMessage: state.api.responseMessage,
        responseData: state.api.responseData,
        responseError: state.api.responseError
    }
}

function mapDispatchToProps(dispatch) {
    return {
        formLoad: (isLoadForm) => dispatch(formLoad(isLoadForm)),
        pageUpdate: () => dispatch(pageUpdate()),
        notificationMessage: (message, colorMessage) => dispatch(notificationMessage(message, colorMessage)),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        alertOpen: (alertForm, alertData) => dispatch(alertOpen(alertForm, alertData)),
        modalClose: () => dispatch(modalClose())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalTeacherGradebookResit);
