import {MODAL_CLOSE, MODAL_OPEN} from "./actionTypes";

export function modalOpen(modalForm, modalData) {

    document.body.classList.add('overflow_hidden');

    return {
        type: MODAL_OPEN,
        modalForm: modalForm,
        modalData: modalData
    }
}

export function modalClose() {

    document.body.classList.remove('overflow_hidden');

    return {
        type: MODAL_CLOSE
    }
}
