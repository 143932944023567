import React from "react";
import {connect} from "react-redux";
import {pageLoad, pageUpdate, pageMount} from "../../../../store/actions/pageAction";
import {apiRequest} from "../../../../store/actions/apiAction";
import {modalOpen} from "../../../../store/actions/modalAction";
import "./TeacherResit.scss";
import Loading from "../../../loading/Loading";
import baseURL from "../../../../library/baseURL";
import {alertOpen} from "../../../../store/actions/alertAction";

class TeacherResit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isMount: true,
            isOpen: false,
            isLoad: false,
            isUpdate: false,
            studentsData: null,
            unrollArray: []
        };
    }

    componentDidMount() {
        this.props.pageMount();
        this.pageUpdate(this.state.isLoad);
        this.intervalUpdate = setInterval(this.props.pageUpdate, 180000);
    }

    componentWillUnmount() {
        // console.log('Employees Unmounted');

        clearInterval(this.intervalUpdate);

        this.setState({
            isMount: false
        });
    }

    componentDidUpdate() {
        if (this.state.isMount) {
            const responseData = this.props.responseData;
            // console.log(1 + ' componentDidUpdate');
            if (this.props.isLoad !== this.state.isLoad) {
                // console.log(2 + ' isLoad');
                if (responseData !== this.state.studentsData) {
                    // console.log(3 + ' responseData');

                    this.setState({
                        isOpen: true,
                        isLoad: this.props.isLoad,
                        isUpdate: this.props.isUpdate,
                        studentsData: responseData
                    });
                }
            }

            else if (this.props.isUpdate !== this.state.isUpdate) {
                // console.log(4 + ' isUpdate');
                this.pageUpdate(this.state.isLoad);

                this.setState({
                    isUpdate: this.props.isUpdate
                });
            }
        }
    }

    pageUpdate(isLoad) {
        this.props.apiRequest('api/cabinet/teacher/resit', null, () => {
            this.props.pageLoad(isLoad);
        }, null);
    }

    modalOpen(modalForm, modalData) {
        this.props.modalOpen(modalForm, modalData);
    }

    alertOpen(alertForm, alertData) {
        this.props.alertOpen(alertForm, alertData);
    }


    render() {
        const { isMount, isOpen, studentsData, unrollArray } = this.state;

        let modalData = (objectId, objectData) => {
            return {
                objectId: objectId,
                objectData: objectData
            };
        }




        let groupName = (groupName, initialYear) => {
            let course;

            let date = new Date();
            course = (date.getFullYear() - Number(initialYear) + 1);

            if (groupName) {
                return groupName.replace(/\$/i, course);
            }
        }

        if (isMount) {

            if (!isOpen || !studentsData) {
                return (<div className="Page__loader"><i/><Loading/><i/></div>);
            }

            else {


                return (
                    <div className="Page__section">
                        {studentsData.length > 0 ? (
                            <div className="Section__header">
                                <div className="Header__title">
                                    <div className="Title__text">Ведомости</div>
                                </div>
                            </div>
                        ) : null}

                        <div className="Section__content">

                            <div className="Curator">
                                <div className="Curator__body">
                                    {studentsData.length > 0 ? (
                                            <React.Fragment>
                                                <ul className="Body__list" style={{marginBottom: 12}}>
                                                    {studentsData.map((resit, index) => (
                                                        <React.Fragment key={index}>
                                                            <li className='List__item'
                                                                onClick={this.modalOpen.bind(this, 'modalTeacherGradebookResit', modalData(resit.id, resit))}
                                                                style={{
                                                                    padding: "12px 24px 7px 24px"
                                                                }}>
                                                                <React.Fragment>
                                                                    <div className="Table__td" style={{display: "inline-block", verticalAlign: "top", paddingTop: 4}}>{resit.student_fio}</div>
                                                                    <div className="Item__hr Item__hr_vertical Item__hr_inline"/>
                                                                    <div className="Table__td" style={{display: "inline-block", verticalAlign: "top", paddingTop: 4}}>{resit.discipline_name}</div>
                                                                </React.Fragment>
                                                            </li>
                                                        </React.Fragment>
                                                    ))}
                                                </ul>
                                            </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <div className="Page__loader">
                                                <i/>
                                                <div className="Body_nodata" style={{marginTop: 96 + 48, opacity: .15}}>
                                                    Пока нет ведомостей
                                                    {/*<div style={{textAlign: 'center', marginTop: 48, fontSize: 32}}>*/}
                                                    {/*    ERROR 404*/}
                                                    {/*</div>*/}
                                                </div>
                                                <i/>
                                            </div>
                                        </React.Fragment>
                                    )}
                                </div>
                            </div>

                        </div>

                        {/*<div className="Section__button"*/}
                        {/*     onClick={this.modalOpen.bind(this, 'modalTrainingGroupAdd', modalData(null, null))}>*/}
                        {/*    <div className="button"/>*/}
                        {/*</div>*/}
                    </div>
                );
            }
        }

        else return null;
    }
}

function mapStateToProps(state) {
    return {
        isLoad: state.page.isLoad,
        isUpdate: state.page.isUpdate,
        responseData: state.api.responseData
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageMount: () => dispatch(pageMount()),
        pageLoad: (isLoad) => dispatch(pageLoad(isLoad)),
        pageUpdate: () => dispatch(pageUpdate()),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        alertOpen: (alertForm, alertData) => dispatch(alertOpen(alertForm, alertData)),
        modalOpen: (modalForm, modalData) => dispatch(modalOpen(modalForm, modalData))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (TeacherResit);
