import React from "react";
import {connect} from "react-redux";
import {modalClose} from "../../../../../store/actions/modalAction";
import {notificationMessage} from "../../../../../store/actions/notificationAction";
import {apiRequest} from "../../../../../store/actions/apiAction";
import {pageUpdate} from "../../../../../store/actions/pageAction";
import Loading from "../../../../loading/Loading";
import {formLoad} from "../../../../../store/actions/formAction";
import {alertOpen} from "../../../../../store/actions/alertAction";

class ModalStudentGradeBook extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isMountForm: true,
            isOpenForm: false,
            isLoadForm: false,
            isUpdateForm: false,
            formData: null,
            isHandleSubmit: false,
            objectId: this.props.modalData.objectId,
            objectData:  this.props.modalData.objectData,
            formResponseError: null,

            groupName: null,
            initialYear: null,
            trainingProgramId: 0,
            trainingScheduleId: 0,
            employeeId: 0,
            employeesData: null,
            trainingProgramsData: null,
            trainingSchedulesData: null,

            isTrue: false,


            knowledgeAssessment: 0,
            dataAssessment: null,
            projectTitle: null,
        };
    }

    componentDidMount() {
        // this.formUpdate(this.state.isLoadForm);
        this.employeesData();
        this.trainingProgramsData();
        this.trainingSchedulesData();
    }

    componentWillUnmount() {
        // console.log('From Unmounted');
        this.setState({
            isMountForm: false
        });
    }

    // componentDidUpdate() {
    //     if (this.state.isMountForm) {
    //         const responseData = this.props.responseData;
    //         // console.log(1 + ' componentDidUpdate');
    //         if (this.props.isLoadForm !== this.state.isLoadForm) {
    //             // console.log(2 + ' isLoad');
    //             if (responseData !== this.state.formData) {
    //                 // console.log(3 + ' responseData');
    //
    //                 this.formUpdate();
    //
    //                 this.setState({
    //                     isOpenForm: true,
    //                     isLoadForm: this.props.isLoadForm,
    //                     isUpdateForm: this.props.isUpdateForm,
    //                     formData: responseData
    //                 });
    //             }
    //         }
    //
    //         else if (this.props.isUpdateForm !== this.state.isUpdateForm) {
    //             // console.log(4 + ' isUpdate');
    //             this.setState({
    //                 isUpdateForm: this.props.isUpdateForm
    //             });
    //             this.formUpdate(this.state.isLoadForm);
    //         }
    //     }
    // }
    //
    // formUpdate(isLoadForm) {
    //     this.props.apiRequest('/company/' + this.state.objectId + '/supports_date', null, () => {
    //         this.props.formLoad(isLoadForm);
    //     }, null);
    // }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    // setStateAndInput(name, value) {
    //
    //     let input = document.getElementById(name);
    //     input.value = value;
    //
    //     this.setState({
    //         [name]: value
    //     });
    // }

    handleSubmit(event) {
        event.preventDefault();

        // if (this.state.groupName && this.state.initialYear) {
        //
        //     let formData = new FormData();
        //
        //     formData.append('group_name', this.state.groupName);
        //     formData.append('initial_year', this.state.initialYear);
        //     formData.append('training_program_id', this.state.trainingProgramId);
        //     formData.append('training_schedule_id', this.state.trainingScheduleId);
        //     formData.append('employee_id', this.state.employeeId);
        //
        //     this.setState({
        //         isHandleSubmit: true
        //     });
        //
        //     this.props.apiRequest('api/cabinet/training/group/add', formData, () => {
        //             // this.setStateAndInput('', null);
        //             this.setState({
        //                 isHandleSubmit: false,
        //                 formResponseError: null
        //             });
        //             // this.formUpdate(this.state.isLoadForm);
        //             this.props.modalClose();
        //             this.props.notificationMessage(this.props.responseMessage, 'green');
        //             this.props.pageUpdate();
        //         }, () => {
        //             this.setState({
        //                 isHandleSubmit: false,
        //                 formResponseError: this.props.responseError
        //             });
        //         }
        //     );
        //
        // }
        //
        // else {
        //     this.setState({
        //         formResponseError: 'Внимательно заполните все обязательные поля ( * ).'
        //     });
        // }

        this.props.modalClose();
        this.props.pageUpdate();
    }

    modalClose() {
        this.props.modalClose();
    }

    // alertOpen(alertForm, alertData) {
    //     this.props.alertOpen(alertForm, alertData);
    // }

    employeesData() {
        this.props.apiRequest('api/cabinet/employees', null, () => {
            this.setState({
                employeesData: this.props.responseData
            });
        }, null);
    }

    trainingProgramsData() {
        this.props.apiRequest('api/cabinet/training/plans', null, () => {
            this.setState({
                trainingProgramsData: this.props.responseData
            });
        }, null);
    }

    trainingSchedulesData() {
        this.props.apiRequest('api/cabinet/training/schedules', null, () => {
            this.setState({
                trainingSchedulesData: this.props.responseData
            });
        }, null);
    }

    disciplineChoiceAdd() {
        this.props.apiRequest('api/cabinet/profile/gradebook/discipline/' + this.state.objectData.id + '/choice/add', null, () => {
                this.setState({
                    isHandleSubmit: false,
                    alertResponseError: null
                });
                // this.props.alertClose();
                this.props.notificationMessage(this.props.responseMessage, 'green');
                // this.props.formUpdate();
                this.props.pageUpdate();
                this.props.modalClose();
            }, () => {
                this.setState({
                    isHandleSubmit: false,
                    alertResponseError: this.props.responseError
                });
            }
        );
    }

    render() {
        const {
            isMountForm,
            // isOpenForm,
            // formData,
            isHandleSubmit,
            formResponseError,

            trainingProgramId,
            trainingScheduleId,
            employeeId,
            employeesData,
            trainingProgramsData,
            trainingSchedulesData,

            objectData,
            objectId,

            isTrue,


            knowledgeAssessment

        } = this.state;

        // let alertData = (objectId, objectData) => {
        //     return {
        //         objectId: objectId,
        //         objectData: objectData
        //     };
        // }

        let controlTest = false;
        let controlTestTeacher = false;

        let controlDiff = false;
        let controlDiffTeacher = false;

        let controlExam = false;
        let controlExamTeacher = false;

        let controlProject = false;
        let controlProjectTeacher = false;


        let controlCoursework = false;
        let controlCourseworkTeacher = false;


        let disciplineChoice = false;


        if (isMountForm) {

            // {!isOpenForm ? (<Loading/>) : (
            //     formData ? () : null)}

            return (
                <div className="Form" style={{width: 340}}>
                    <div onSubmit={this.handleSubmit.bind(this)}>
                        <div className="Form__header">
                            <div className="Header__title" style={{fontSize: 24, fontWeight: 900}}>{objectData.discipline_name}</div>

                            {isTrue ? (
                                <div className="Item__index" style={{
                                    marginTop: 12,
                                    display: "inline-block",
                                    backgroundColor: "#43a047",
                                    // backgroundColor: "#e53935",
                                    textTransform: "uppercase",
                                    // isPassed Не сдан
                                    fontFamily: "Roboto, sans-serif",
                                    fontSize: 15,
                                    fontWeight: 700,
                                    backgroundСolor: "#2A3E4C",
                                    color: "white",
                                    padding: "4px 6px 3px",
                                    borderRadius: 4
                                }}>Сдан </div>
                            ) : null}
                        </div>











                        <div className="Form__content" style={{
                            // marginBottom: 42
                        }}>

                            <div className="Content__hr Content__hr_bottom-32"/>



                            <div className="Box" style={{marginBottom: 24}}>
                                <div className="Box__title" style={{
                                    fontFamily: "Roboto, sans-serif",
                                    fontWeight: 700,
                                    fontSize: 20,
                                    color: "#2A3E4C"
                                }}>Обучение</div>
                            </div>


                            <div className="Box" style={{marginBottom: 24}}>
                                <div className="Box__label" style={{
                                    fontFamily: "Roboto, sans-serif",
                                    fontWeight: 700,
                                    fontSize: 16,
                                    color: "#949ea5",
                                    marginBottom: 16
                                }}>Часы</div>
                                <div className="Box__text" style={{
                                    fontFamily: "Roboto, sans-serif",
                                    fontWeight: 400,
                                    fontSize: 16,
                                    color: "#2A3E4C"
                                }}>{Number(objectData.lecture_hours) + Number(objectData.laboratory_hours) + Number(objectData.practical_hours) + Number(objectData.ciw_hours) + Number(objectData.iws_hours)}</div>
                            </div>

                            <div className="Box" style={{marginBottom: 24}}>
                                <div className="Box__label" style={{
                                    fontFamily: "Roboto, sans-serif",
                                    fontWeight: 700,
                                    fontSize: 16,
                                    color: "#949ea5",
                                    marginBottom: 16
                                }}>Зач. ед.</div>
                                <div className="Box__text" style={{
                                    fontFamily: "Roboto, sans-serif",
                                    fontWeight: 400,
                                    fontSize: 16,
                                    color: "#2A3E4C"
                                }}>{objectData.credit_unit}</div>
                            </div>

                            <div className="Content__hr Content__hr_bottom-32"/>



                            {Number(objectData.control_exam) > 0 ? (

                                objectData.academic_performance.map(academic_performance => (
                                    academic_performance.control_type === 'exam' ? (controlExam = true) : null
                                )),

                                    controlExam ? (

                                        objectData.academic_performance.map((academic_performance, index) => (
                                            academic_performance.control_type === 'exam' ? (


                                                <React.Fragment key={index}>

                                                    <div className="Box" style={{marginBottom: 24}}>
                                                        <div className="Box__title" style={{
                                                            fontFamily: "Roboto, sans-serif",
                                                            fontWeight: 700,
                                                            fontSize: 20,
                                                            color: "#2A3E4C"
                                                        }}>Экзамен</div>
                                                    </div>

                                                    <div className="Box" style={{marginBottom: 24}}>
                                                        <div className="Box__label" style={{
                                                            fontFamily: "Roboto, sans-serif",
                                                            fontWeight: 700,
                                                            fontSize: 16,
                                                            color: "#949ea5",
                                                            marginBottom: 16
                                                        }}>Оценка</div>
                                                        <div className="Box__text" style={{
                                                            fontFamily: "Roboto, sans-serif",
                                                            fontWeight: 400,
                                                            fontSize: 16,
                                                            color: "#2A3E4C"
                                                        }}>

                                                            {Number(academic_performance.knowledge_assessment) === 0 ? 'Ведомость' : null}
                                                            {Number(academic_performance.knowledge_assessment) === 1 ? 'Не явка' : null}
                                                            {Number(academic_performance.knowledge_assessment) > 1 ? academic_performance.knowledge_assessment : null}

                                                        </div>
                                                    </div>

                                                    <div className="Box" style={{marginBottom: 24}}>
                                                        <div className="Box__label" style={{
                                                            fontFamily: "Roboto, sans-serif",
                                                            fontWeight: 700,
                                                            fontSize: 16,
                                                            color: "#949ea5",
                                                            marginBottom: 16
                                                        }}>Дата сдачи</div>
                                                        <div className="Box__text" style={{
                                                            fontFamily: "Roboto, sans-serif",
                                                            fontWeight: 400,
                                                            fontSize: 16,
                                                            color: "#2A3E4C"
                                                        }}>
                                                            {!academic_performance.data_assessment ? '-' : (
                                                                academic_performance.data_assessment === "null" ? '-' : (
                                                                    academic_performance.data_assessment === "" ? '-' : academic_performance.data_assessment
                                                                )
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="Box Box_end">
                                                        <div className="Box__label" style={{
                                                            fontFamily: "Roboto, sans-serif",
                                                            fontWeight: 700,
                                                            fontSize: 16,
                                                            color: "#949ea5",
                                                            marginBottom: 16
                                                        }}>Преподаватель</div>
                                                        <div className="Box__text" style={{
                                                            fontFamily: "Roboto, sans-serif",
                                                            fontWeight: 400,
                                                            fontSize: 16,
                                                            color: "#2A3E4C"
                                                        }}>

                                                            {!employeesData ? null : (
                                                                employeesData.map((employees) => (
                                                                    employees.array.map((employee, index) => (
                                                                        Number(academic_performance.employee_id) === Number(employee.id) ? controlExamTeacher = true : null,
                                                                            Number(academic_performance.employee_id) === Number(employee.id) ? employee.employee_fio : null
                                                                    ))
                                                                )))}

                                                            {controlExamTeacher ? null : '-'}

                                                        </div>
                                                    </div>

                                                </React.Fragment>


                                            ) : null
                                        ))


                                    ) : (

                                        <React.Fragment>

                                            <div className="Box" style={{marginBottom: 24}}>
                                                <div className="Box__title" style={{
                                                    fontFamily: "Roboto, sans-serif",
                                                    fontWeight: 700,
                                                    fontSize: 20,
                                                    color: "#2A3E4C"
                                                }}>Экзамен</div>
                                            </div>

                                            <div className="Box" style={{marginBottom: 24}}>
                                                <div className="Box__label" style={{
                                                    fontFamily: "Roboto, sans-serif",
                                                    fontWeight: 700,
                                                    fontSize: 16,
                                                    color: "#949ea5",
                                                    marginBottom: 16
                                                }}>Оценка</div>
                                                <div className="Box__text" style={{
                                                    fontFamily: "Roboto, sans-serif",
                                                    fontWeight: 400,
                                                    fontSize: 16,
                                                    color: "#2A3E4C"
                                                }}>-</div>
                                            </div>

                                            <div className="Box" style={{marginBottom: 24}}>
                                                <div className="Box__label" style={{
                                                    fontFamily: "Roboto, sans-serif",
                                                    fontWeight: 700,
                                                    fontSize: 16,
                                                    color: "#949ea5",
                                                    marginBottom: 16
                                                }}>Дата сдачи</div>
                                                <div className="Box__text" style={{
                                                    fontFamily: "Roboto, sans-serif",
                                                    fontWeight: 400,
                                                    fontSize: 16,
                                                    color: "#2A3E4C"
                                                }}>-</div>
                                            </div>

                                            <div className="Box Box_end">
                                                <div className="Box__label" style={{
                                                    fontFamily: "Roboto, sans-serif",
                                                    fontWeight: 700,
                                                    fontSize: 16,
                                                    color: "#949ea5",
                                                    marginBottom: 16
                                                }}>Преподаватель</div>
                                                <div className="Box__text" style={{
                                                    fontFamily: "Roboto, sans-serif",
                                                    fontWeight: 400,
                                                    fontSize: 16,
                                                    color: "#2A3E4C"
                                                }}>-</div>
                                            </div>
                                        </React.Fragment>

                                    )

                            ) : null}














                            {Number(objectData.control_diff) > 0 ? (

                                objectData.academic_performance.map(academic_performance => (
                                    academic_performance.control_type === 'diff' ? (controlDiff = true) : null
                                )),

                                    controlDiff ? (

                                        objectData.academic_performance.map((academic_performance, index) => (
                                            academic_performance.control_type === 'diff' ? (


                                                <React.Fragment key={index}>

                                                    <div className="Box" style={{marginBottom: 24}}>
                                                        <div className="Box__title" style={{
                                                            fontFamily: "Roboto, sans-serif",
                                                            fontWeight: 700,
                                                            fontSize: 20,
                                                            color: "#2A3E4C"
                                                        }}>Диф. зачет</div>
                                                    </div>

                                                    <div className="Box" style={{marginBottom: 24}}>
                                                        <div className="Box__label" style={{
                                                            fontFamily: "Roboto, sans-serif",
                                                            fontWeight: 700,
                                                            fontSize: 16,
                                                            color: "#949ea5",
                                                            marginBottom: 16
                                                        }}>Оценка</div>
                                                        <div className="Box__text" style={{
                                                            fontFamily: "Roboto, sans-serif",
                                                            fontWeight: 400,
                                                            fontSize: 16,
                                                            color: "#2A3E4C"
                                                        }}>

                                                            {Number(academic_performance.knowledge_assessment) === 0 ? 'Ведомость' : null}
                                                            {Number(academic_performance.knowledge_assessment) === 1 ? 'Не явка' : null}
                                                            {Number(academic_performance.knowledge_assessment) > 1 ? academic_performance.knowledge_assessment : null}

                                                        </div>
                                                    </div>

                                                    <div className="Box" style={{marginBottom: 24}}>
                                                        <div className="Box__label" style={{
                                                            fontFamily: "Roboto, sans-serif",
                                                            fontWeight: 700,
                                                            fontSize: 16,
                                                            color: "#949ea5",
                                                            marginBottom: 16
                                                        }}>Дата сдачи</div>
                                                        <div className="Box__text" style={{
                                                            fontFamily: "Roboto, sans-serif",
                                                            fontWeight: 400,
                                                            fontSize: 16,
                                                            color: "#2A3E4C"
                                                        }}>
                                                            {!academic_performance.data_assessment ? '-' : (
                                                                academic_performance.data_assessment === "null" ? '-' : (
                                                                    academic_performance.data_assessment === "" ? '-' : academic_performance.data_assessment
                                                                )
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="Box Box_end">
                                                        <div className="Box__label" style={{
                                                            fontFamily: "Roboto, sans-serif",
                                                            fontWeight: 700,
                                                            fontSize: 16,
                                                            color: "#949ea5",
                                                            marginBottom: 16
                                                        }}>Преподаватель</div>
                                                        <div className="Box__text" style={{
                                                            fontFamily: "Roboto, sans-serif",
                                                            fontWeight: 400,
                                                            fontSize: 16,
                                                            color: "#2A3E4C"
                                                        }}>

                                                            {!employeesData ? null : (
                                                                employeesData.map((employees) => (
                                                                    employees.array.map((employee, index) => (
                                                                        Number(academic_performance.employee_id) === Number(employee.id) ? controlDiffTeacher = true : null,
                                                                            Number(academic_performance.employee_id) === Number(employee.id) ? employee.employee_fio : null
                                                                    ))
                                                                )))}

                                                            {controlDiffTeacher ? null : '-'}

                                                        </div>
                                                    </div>

                                                </React.Fragment>


                                            ) : null
                                        ))


                                    ) : (

                                        <React.Fragment>

                                            <div className="Box" style={{marginBottom: 24}}>
                                                <div className="Box__title" style={{
                                                    fontFamily: "Roboto, sans-serif",
                                                    fontWeight: 700,
                                                    fontSize: 20,
                                                    color: "#2A3E4C"
                                                }}>Диф. зачет</div>
                                            </div>

                                            <div className="Box" style={{marginBottom: 24}}>
                                                <div className="Box__label" style={{
                                                    fontFamily: "Roboto, sans-serif",
                                                    fontWeight: 700,
                                                    fontSize: 16,
                                                    color: "#949ea5",
                                                    marginBottom: 16
                                                }}>Оценка</div>
                                                <div className="Box__text" style={{
                                                    fontFamily: "Roboto, sans-serif",
                                                    fontWeight: 400,
                                                    fontSize: 16,
                                                    color: "#2A3E4C"
                                                }}>-</div>
                                            </div>

                                            <div className="Box" style={{marginBottom: 24}}>
                                                <div className="Box__label" style={{
                                                    fontFamily: "Roboto, sans-serif",
                                                    fontWeight: 700,
                                                    fontSize: 16,
                                                    color: "#949ea5",
                                                    marginBottom: 16
                                                }}>Дата сдачи</div>
                                                <div className="Box__text" style={{
                                                    fontFamily: "Roboto, sans-serif",
                                                    fontWeight: 400,
                                                    fontSize: 16,
                                                    color: "#2A3E4C"
                                                }}>-</div>
                                            </div>

                                            <div className="Box Box_end">
                                                <div className="Box__label" style={{
                                                    fontFamily: "Roboto, sans-serif",
                                                    fontWeight: 700,
                                                    fontSize: 16,
                                                    color: "#949ea5",
                                                    marginBottom: 16
                                                }}>Преподаватель</div>
                                                <div className="Box__text" style={{
                                                    fontFamily: "Roboto, sans-serif",
                                                    fontWeight: 400,
                                                    fontSize: 16,
                                                    color: "#2A3E4C"
                                                }}>-</div>
                                            </div>
                                        </React.Fragment>

                                    )

                            ) : null}















                            {Number(objectData.control_test) > 0 ? (

                                objectData.academic_performance.map(academic_performance => (
                                    academic_performance.control_type === 'test' ? (controlTest = true) : null
                                )),

                                    controlTest ? (

                                        objectData.academic_performance.map((academic_performance, index) => (
                                            academic_performance.control_type === 'test' ? (


                                                <React.Fragment key={index}>

                                                    <div className="Box" style={{marginBottom: 24}}>
                                                        <div className="Box__title" style={{
                                                            fontFamily: "Roboto, sans-serif",
                                                            fontWeight: 700,
                                                            fontSize: 20,
                                                            color: "#2A3E4C"
                                                        }}>Зачет</div>
                                                    </div>

                                                    <div className="Box" style={{marginBottom: 24}}>
                                                        <div className="Box__label" style={{
                                                            fontFamily: "Roboto, sans-serif",
                                                            fontWeight: 700,
                                                            fontSize: 16,
                                                            color: "#949ea5",
                                                            marginBottom: 16
                                                        }}>Оценка</div>
                                                        <div className="Box__text" style={{
                                                            fontFamily: "Roboto, sans-serif",
                                                            fontWeight: 400,
                                                            fontSize: 16,
                                                            color: "#2A3E4C"
                                                        }}>

                                                            {Number(academic_performance.knowledge_assessment) === 0 ? 'Ведомость' : null}
                                                            {Number(academic_performance.knowledge_assessment) === 1 ? 'Не явка' : null}
                                                            {Number(academic_performance.knowledge_assessment) === 2 ? 'Не зачет' : null}
                                                            {Number(academic_performance.knowledge_assessment) === 5 ? 'Зачет' : null}

                                                        </div>
                                                    </div>

                                                    <div className="Box" style={{marginBottom: 24}}>
                                                        <div className="Box__label" style={{
                                                            fontFamily: "Roboto, sans-serif",
                                                            fontWeight: 700,
                                                            fontSize: 16,
                                                            color: "#949ea5",
                                                            marginBottom: 16
                                                        }}>Дата сдачи</div>
                                                        <div className="Box__text" style={{
                                                            fontFamily: "Roboto, sans-serif",
                                                            fontWeight: 400,
                                                            fontSize: 16,
                                                            color: "#2A3E4C"
                                                        }}>
                                                            {!academic_performance.data_assessment ? '-' : (
                                                                academic_performance.data_assessment === "null" ? '-' : (
                                                                    academic_performance.data_assessment === "" ? '-' : academic_performance.data_assessment
                                                                )
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="Box Box_end">
                                                        <div className="Box__label" style={{
                                                            fontFamily: "Roboto, sans-serif",
                                                            fontWeight: 700,
                                                            fontSize: 16,
                                                            color: "#949ea5",
                                                            marginBottom: 16
                                                        }}>Преподаватель</div>
                                                        <div className="Box__text" style={{
                                                            fontFamily: "Roboto, sans-serif",
                                                            fontWeight: 400,
                                                            fontSize: 16,
                                                            color: "#2A3E4C"
                                                        }}>

                                                            {!employeesData ? null : (
                                                                employeesData.map((employees) => (
                                                                    employees.array.map((employee, index) => (
                                                                        Number(academic_performance.employee_id) === Number(employee.id) ? controlTestTeacher = true : null,
                                                                            Number(academic_performance.employee_id) === Number(employee.id) ? employee.employee_fio : null
                                                                    ))
                                                                )))}

                                                            {controlTestTeacher ? null : '-'}

                                                        </div>
                                                    </div>

                                                </React.Fragment>


                                            ) : null
                                        ))


                                    ) : (

                                        <React.Fragment>

                                            <div className="Box" style={{marginBottom: 24}}>
                                                <div className="Box__title" style={{
                                                    fontFamily: "Roboto, sans-serif",
                                                    fontWeight: 700,
                                                    fontSize: 20,
                                                    color: "#2A3E4C"
                                                }}>Зачет</div>
                                            </div>

                                            <div className="Box" style={{marginBottom: 24}}>
                                                <div className="Box__label" style={{
                                                    fontFamily: "Roboto, sans-serif",
                                                    fontWeight: 700,
                                                    fontSize: 16,
                                                    color: "#949ea5",
                                                    marginBottom: 16
                                                }}>Оценка</div>
                                                <div className="Box__text" style={{
                                                    fontFamily: "Roboto, sans-serif",
                                                    fontWeight: 400,
                                                    fontSize: 16,
                                                    color: "#2A3E4C"
                                                }}>-</div>
                                            </div>

                                            <div className="Box" style={{marginBottom: 24}}>
                                                <div className="Box__label" style={{
                                                    fontFamily: "Roboto, sans-serif",
                                                    fontWeight: 700,
                                                    fontSize: 16,
                                                    color: "#949ea5",
                                                    marginBottom: 16
                                                }}>Дата сдачи</div>
                                                <div className="Box__text" style={{
                                                    fontFamily: "Roboto, sans-serif",
                                                    fontWeight: 400,
                                                    fontSize: 16,
                                                    color: "#2A3E4C"
                                                }}>-</div>
                                            </div>

                                            <div className="Box Box_end">
                                                <div className="Box__label" style={{
                                                    fontFamily: "Roboto, sans-serif",
                                                    fontWeight: 700,
                                                    fontSize: 16,
                                                    color: "#949ea5",
                                                    marginBottom: 16
                                                }}>Преподаватель</div>
                                                <div className="Box__text" style={{
                                                    fontFamily: "Roboto, sans-serif",
                                                    fontWeight: 400,
                                                    fontSize: 16,
                                                    color: "#2A3E4C"
                                                }}>-</div>
                                            </div>
                                        </React.Fragment>

                                    )

                            ) : null}





































                            {Number(objectData.control_project) > 0 ? (

                                objectData.academic_performance.map(academic_performance => (
                                    academic_performance.control_type === 'project' ? (controlProject = true) : null
                                )),

                                    controlProject ? (

                                        objectData.academic_performance.map((academic_performance, index) => (
                                            academic_performance.control_type === 'project' ? (


                                                <React.Fragment key={index}>
                                                    <div className="Content__hr Content__hr_bottom-32" style={{marginTop: 32}}/>

                                                    <div className="Box" style={{marginBottom: 24}}>
                                                        <div className="Box__title" style={{
                                                            fontFamily: "Roboto, sans-serif",
                                                            fontWeight: 700,
                                                            fontSize: 20,
                                                            color: "#2A3E4C"
                                                        }}>Курсовой проект</div>
                                                    </div>

                                                    <div className="Box" style={{marginBottom: 24}}>
                                                        <div className="Box__label" style={{
                                                            fontFamily: "Roboto, sans-serif",
                                                            fontWeight: 700,
                                                            fontSize: 16,
                                                            color: "#949ea5",
                                                            marginBottom: 16
                                                        }}>Название проекта</div>
                                                        <div className="Box__text" style={{
                                                            fontFamily: "Roboto, sans-serif",
                                                            fontWeight: 400,
                                                            fontSize: 16,
                                                            color: "#2A3E4C"
                                                        }}>
                                                            {!academic_performance.project_title ? '-' : (
                                                                academic_performance.project_title === "null" ? '-' : (
                                                                    academic_performance.project_title === "" ? '-' : academic_performance.project_title
                                                                )
                                                            )}

                                                        </div>
                                                    </div>

                                                    <div className="Box" style={{marginBottom: 24}}>
                                                        <div className="Box__label" style={{
                                                            fontFamily: "Roboto, sans-serif",
                                                            fontWeight: 700,
                                                            fontSize: 16,
                                                            color: "#949ea5",
                                                            marginBottom: 16
                                                        }}>Оценка</div>
                                                        <div className="Box__text" style={{
                                                            fontFamily: "Roboto, sans-serif",
                                                            fontWeight: 400,
                                                            fontSize: 16,
                                                            color: "#2A3E4C"
                                                        }}>

                                                            {Number(academic_performance.knowledge_assessment) === 0 ? 'Ведомость' : null}
                                                            {Number(academic_performance.knowledge_assessment) === 1 ? 'Не явка' : null}
                                                            {Number(academic_performance.knowledge_assessment) > 1 ? academic_performance.knowledge_assessment : null}

                                                        </div>
                                                    </div>

                                                    <div className="Box" style={{marginBottom: 24}}>
                                                        <div className="Box__label" style={{
                                                            fontFamily: "Roboto, sans-serif",
                                                            fontWeight: 700,
                                                            fontSize: 16,
                                                            color: "#949ea5",
                                                            marginBottom: 16
                                                        }}>Дата сдачи</div>
                                                        <div className="Box__text" style={{
                                                            fontFamily: "Roboto, sans-serif",
                                                            fontWeight: 400,
                                                            fontSize: 16,
                                                            color: "#2A3E4C"
                                                        }}>
                                                            {!academic_performance.data_assessment ? '-' : (
                                                                academic_performance.data_assessment === "null" ? '-' : (
                                                                    academic_performance.data_assessment === "" ? '-' : academic_performance.data_assessment
                                                                )
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="Box Box_end">
                                                        <div className="Box__label" style={{
                                                            fontFamily: "Roboto, sans-serif",
                                                            fontWeight: 700,
                                                            fontSize: 16,
                                                            color: "#949ea5",
                                                            marginBottom: 16
                                                        }}>Преподаватель</div>
                                                        <div className="Box__text" style={{
                                                            fontFamily: "Roboto, sans-serif",
                                                            fontWeight: 400,
                                                            fontSize: 16,
                                                            color: "#2A3E4C"
                                                        }}>

                                                            {!employeesData ? null : (
                                                                employeesData.map((employees) => (
                                                                    employees.array.map((employee, index) => (
                                                                        Number(academic_performance.employee_id) === Number(employee.id) ? controlProjectTeacher = true : null,
                                                                            Number(academic_performance.employee_id) === Number(employee.id) ? employee.employee_fio : null
                                                                    ))
                                                                )))}

                                                            {controlProjectTeacher ? null : '-'}

                                                        </div>
                                                    </div>

                                                </React.Fragment>


                                            ) : null
                                        ))


                                    ) : (

                                        <React.Fragment>
                                            <div className="Content__hr Content__hr_bottom-32" style={{marginTop: 32}}/>

                                            <div className="Box" style={{marginBottom: 24}}>
                                                <div className="Box__title" style={{
                                                    fontFamily: "Roboto, sans-serif",
                                                    fontWeight: 700,
                                                    fontSize: 20,
                                                    color: "#2A3E4C"
                                                }}>Курсовой проект</div>
                                            </div>

                                            <div className="Box" style={{marginBottom: 24}}>
                                                <div className="Box__label" style={{
                                                    fontFamily: "Roboto, sans-serif",
                                                    fontWeight: 700,
                                                    fontSize: 16,
                                                    color: "#949ea5",
                                                    marginBottom: 16
                                                }}>Название проекта</div>
                                                <div className="Box__text" style={{
                                                    fontFamily: "Roboto, sans-serif",
                                                    fontWeight: 400,
                                                    fontSize: 16,
                                                    color: "#2A3E4C"
                                                }}>-</div>
                                            </div>

                                            <div className="Box" style={{marginBottom: 24}}>
                                                <div className="Box__label" style={{
                                                    fontFamily: "Roboto, sans-serif",
                                                    fontWeight: 700,
                                                    fontSize: 16,
                                                    color: "#949ea5",
                                                    marginBottom: 16
                                                }}>Оценка</div>
                                                <div className="Box__text" style={{
                                                    fontFamily: "Roboto, sans-serif",
                                                    fontWeight: 400,
                                                    fontSize: 16,
                                                    color: "#2A3E4C"
                                                }}>-</div>
                                            </div>

                                            <div className="Box" style={{marginBottom: 24}}>
                                                <div className="Box__label" style={{
                                                    fontFamily: "Roboto, sans-serif",
                                                    fontWeight: 700,
                                                    fontSize: 16,
                                                    color: "#949ea5",
                                                    marginBottom: 16
                                                }}>Дата сдачи</div>
                                                <div className="Box__text" style={{
                                                    fontFamily: "Roboto, sans-serif",
                                                    fontWeight: 400,
                                                    fontSize: 16,
                                                    color: "#2A3E4C"
                                                }}>-</div>
                                            </div>

                                            <div className="Box Box_end">
                                                <div className="Box__label" style={{
                                                    fontFamily: "Roboto, sans-serif",
                                                    fontWeight: 700,
                                                    fontSize: 16,
                                                    color: "#949ea5",
                                                    marginBottom: 16
                                                }}>Преподаватель</div>
                                                <div className="Box__text" style={{
                                                    fontFamily: "Roboto, sans-serif",
                                                    fontWeight: 400,
                                                    fontSize: 16,
                                                    color: "#2A3E4C"
                                                }}>-</div>
                                            </div>
                                        </React.Fragment>

                                    )

                            ) : null}












                            {Number(objectData.control_coursework) > 0 ? (

                                objectData.academic_performance.map(academic_performance => (
                                    academic_performance.control_type === 'coursework' ? (controlCoursework = true) : null
                                )),

                                    controlCoursework ? (

                                        objectData.academic_performance.map((academic_performance, index) => (
                                            academic_performance.control_type === 'coursework' ? (


                                                <React.Fragment key={index}>
                                                    <div className="Content__hr Content__hr_bottom-32" style={{marginTop: 32}}/>

                                                    <div className="Box" style={{marginBottom: 24}}>
                                                        <div className="Box__title" style={{
                                                            fontFamily: "Roboto, sans-serif",
                                                            fontWeight: 700,
                                                            fontSize: 20,
                                                            color: "#2A3E4C"
                                                        }}>Курсовая работа</div>
                                                    </div>

                                                    <div className="Box" style={{marginBottom: 24}}>
                                                        <div className="Box__label" style={{
                                                            fontFamily: "Roboto, sans-serif",
                                                            fontWeight: 700,
                                                            fontSize: 16,
                                                            color: "#949ea5",
                                                            marginBottom: 16
                                                        }}>Название проекта</div>
                                                        <div className="Box__text" style={{
                                                            fontFamily: "Roboto, sans-serif",
                                                            fontWeight: 400,
                                                            fontSize: 16,
                                                            color: "#2A3E4C"
                                                        }}>
                                                            {!academic_performance.project_title ? '-' : (
                                                                academic_performance.project_title === "null" ? '-' : (
                                                                    academic_performance.project_title === "" ? '-' : academic_performance.project_title
                                                                )
                                                            )}

                                                        </div>
                                                    </div>

                                                    <div className="Box" style={{marginBottom: 24}}>
                                                        <div className="Box__label" style={{
                                                            fontFamily: "Roboto, sans-serif",
                                                            fontWeight: 700,
                                                            fontSize: 16,
                                                            color: "#949ea5",
                                                            marginBottom: 16
                                                        }}>Оценка</div>
                                                        <div className="Box__text" style={{
                                                            fontFamily: "Roboto, sans-serif",
                                                            fontWeight: 400,
                                                            fontSize: 16,
                                                            color: "#2A3E4C"
                                                        }}>

                                                            {Number(academic_performance.knowledge_assessment) === 0 ? 'Ведомость' : null}
                                                            {Number(academic_performance.knowledge_assessment) === 1 ? 'Не явка' : null}
                                                            {Number(academic_performance.knowledge_assessment) > 1 ? academic_performance.knowledge_assessment : null}

                                                        </div>
                                                    </div>

                                                    <div className="Box" style={{marginBottom: 24}}>
                                                        <div className="Box__label" style={{
                                                            fontFamily: "Roboto, sans-serif",
                                                            fontWeight: 700,
                                                            fontSize: 16,
                                                            color: "#949ea5",
                                                            marginBottom: 16
                                                        }}>Дата сдачи</div>
                                                        <div className="Box__text" style={{
                                                            fontFamily: "Roboto, sans-serif",
                                                            fontWeight: 400,
                                                            fontSize: 16,
                                                            color: "#2A3E4C"
                                                        }}>
                                                            {!academic_performance.data_assessment ? '-' : (
                                                                academic_performance.data_assessment === "null" ? '-' : (
                                                                    academic_performance.data_assessment === "" ? '-' : academic_performance.data_assessment
                                                                )
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="Box Box_end">
                                                        <div className="Box__label" style={{
                                                            fontFamily: "Roboto, sans-serif",
                                                            fontWeight: 700,
                                                            fontSize: 16,
                                                            color: "#949ea5",
                                                            marginBottom: 16
                                                        }}>Преподаватель</div>
                                                        <div className="Box__text" style={{
                                                            fontFamily: "Roboto, sans-serif",
                                                            fontWeight: 400,
                                                            fontSize: 16,
                                                            color: "#2A3E4C"
                                                        }}>

                                                            {!employeesData ? null : (
                                                                employeesData.map((employees) => (
                                                                    employees.array.map((employee, index) => (
                                                                        Number(academic_performance.employee_id) === Number(employee.id) ? controlCourseworkTeacher = true : null,
                                                                            Number(academic_performance.employee_id) === Number(employee.id) ? employee.employee_fio : null
                                                                    ))
                                                                )))}

                                                            {controlCourseworkTeacher ? null : '-'}

                                                        </div>
                                                    </div>
                                                </React.Fragment>


                                            ) : null
                                        ))


                                    ) : (

                                        <React.Fragment>
                                            <div className="Content__hr Content__hr_bottom-32" style={{marginTop: 32}}/>

                                            <div className="Box" style={{marginBottom: 24}}>
                                                <div className="Box__title" style={{
                                                    fontFamily: "Roboto, sans-serif",
                                                    fontWeight: 700,
                                                    fontSize: 20,
                                                    color: "#2A3E4C"
                                                }}>Курсовая работа</div>
                                            </div>

                                            <div className="Box" style={{marginBottom: 24}}>
                                                <div className="Box__label" style={{
                                                    fontFamily: "Roboto, sans-serif",
                                                    fontWeight: 700,
                                                    fontSize: 16,
                                                    color: "#949ea5",
                                                    marginBottom: 16
                                                }}>Название проекта</div>
                                                <div className="Box__text" style={{
                                                    fontFamily: "Roboto, sans-serif",
                                                    fontWeight: 400,
                                                    fontSize: 16,
                                                    color: "#2A3E4C"
                                                }}>-</div>
                                            </div>

                                            <div className="Box" style={{marginBottom: 24}}>
                                                <div className="Box__label" style={{
                                                    fontFamily: "Roboto, sans-serif",
                                                    fontWeight: 700,
                                                    fontSize: 16,
                                                    color: "#949ea5",
                                                    marginBottom: 16
                                                }}>Оценка</div>
                                                <div className="Box__text" style={{
                                                    fontFamily: "Roboto, sans-serif",
                                                    fontWeight: 400,
                                                    fontSize: 16,
                                                    color: "#2A3E4C"
                                                }}>-</div>
                                            </div>

                                            <div className="Box" style={{marginBottom: 24}}>
                                                <div className="Box__label" style={{
                                                    fontFamily: "Roboto, sans-serif",
                                                    fontWeight: 700,
                                                    fontSize: 16,
                                                    color: "#949ea5",
                                                    marginBottom: 16
                                                }}>Дата сдачи</div>
                                                <div className="Box__text" style={{
                                                    fontFamily: "Roboto, sans-serif",
                                                    fontWeight: 400,
                                                    fontSize: 16,
                                                    color: "#2A3E4C"
                                                }}>-</div>
                                            </div>

                                            <div className="Box Box_end">
                                                <div className="Box__label" style={{
                                                    fontFamily: "Roboto, sans-serif",
                                                    fontWeight: 700,
                                                    fontSize: 16,
                                                    color: "#949ea5",
                                                    marginBottom: 16
                                                }}>Преподаватель</div>
                                                <div className="Box__text" style={{
                                                    fontFamily: "Roboto, sans-serif",
                                                    fontWeight: 400,
                                                    fontSize: 16,
                                                    color: "#2A3E4C"
                                                }}>-</div>
                                            </div>

                                        </React.Fragment>

                                    )

                            ) : null}

















                        </div>

































                        {Number(objectData.anchor_of_choice) > 0 ? (

                            objectData.discipline_choice ? null : (

                                objectId.map(discipline => (

                                    (Number(discipline.id) === Number(objectData.anchor_of_choice)) ? (
                                        discipline.discipline_choice ? null : disciplineChoice = true
                                        ) : null

                                )),

                                    disciplineChoice ? (
                                <React.Fragment>

                                    <div className="Form__link">
                                        <div className="Link__delete" style={{color: "#0061ff"}} onClick={this.disciplineChoiceAdd.bind(this)}>Выбрать предмет</div>
                                    </div>
                                </React.Fragment>
                                    ) : null
                            )
                        ) : null}
                    </div>
                </div>
            );
        }

        else return null;
    }
}

function mapStateToProps(state) {
    return {
        isLoadForm: state.form.isLoadForm,
        isUpdateForm: state.form.isUpdateForm,
        modalData: state.modal.modalData,
        isRequest: state.api.isRequest,
        responseMessage: state.api.responseMessage,
        responseData: state.api.responseData,
        responseError: state.api.responseError
    }
}

function mapDispatchToProps(dispatch) {
    return {
        formLoad: (isLoadForm) => dispatch(formLoad(isLoadForm)),
        pageUpdate: () => dispatch(pageUpdate()),
        notificationMessage: (message, colorMessage) => dispatch(notificationMessage(message, colorMessage)),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        alertOpen: (alertForm, alertData) => dispatch(alertOpen(alertForm, alertData)),
        modalClose: () => dispatch(modalClose())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalStudentGradeBook);
