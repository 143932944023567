import React from "react";
import {connect} from "react-redux";
import {modalClose} from "../../../../../store/actions/modalAction";
import {notificationMessage} from "../../../../../store/actions/notificationAction";
import {apiRequest} from "../../../../../store/actions/apiAction";
import {pageUpdate} from "../../../../../store/actions/pageAction";
import Loading from "../../../../loading/Loading";
import {formLoad} from "../../../../../store/actions/formAction";
import {alertOpen} from "../../../../../store/actions/alertAction";

class ModalTrainingPlanDisciplineEdit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isMountForm: true,
            isOpenForm: false,
            isLoadForm: false,
            isUpdateForm: false,
            formData: null,
            isHandleSubmit: false,
            objectId: this.props.modalData.objectId.id,
            objectData: this.props.modalData.objectData,
            formResponseError: null,


            yearProgram: this.props.modalData.objectData.year_program,
            periodProgram: this.props.modalData.objectData.period_program,

            disciplineName: this.props.modalData.objectData.discipline_name,
            disciplineType: this.props.modalData.objectData.discipline_type,
            disciplineView: this.props.modalData.objectData.discipline_view,
            disciplineIndex: (this.props.modalData.objectData.discipline_index ? this.props.modalData.objectData.discipline_index : ''),

            venueDiscipline: (this.props.modalData.objectData.venue_discipline ? this.props.modalData.objectData.venue_discipline : ''),

            anchorOfChoice: this.props.modalData.objectData.anchor_of_choice,
            anchorsData: this.anchorsData(this.props.modalData.objectId, this.props.modalData.objectData.id),

            controlExam: this.props.modalData.objectData.control_exam,
            controlDiff: this.props.modalData.objectData.control_diff,
            controlTest: this.props.modalData.objectData.control_test,
            controlProject: this.props.modalData.objectData.control_project,
            controlCoursework: this.props.modalData.objectData.control_coursework,

            lectureHours: this.props.modalData.objectData.lecture_hours,
            laboratoryHours: this.props.modalData.objectData.laboratory_hours,
            practicalHours: this.props.modalData.objectData.practical_hours,
            ciwHours: this.props.modalData.objectData.ciw_hours,
            iwsHours: this.props.modalData.objectData.iws_hours,

            creditUnit: this.props.modalData.objectData.credit_unit
        };
    }

    // componentDidMount() {
    //     this.formUpdate(this.state.isLoadForm);
    // }

    componentWillUnmount() {
        // console.log('From Unmounted');
        this.setState({
            isMountForm: false
        });
    }

    // componentDidUpdate() {
    //     if (this.state.isMountForm) {
    //         const responseData = this.props.responseData;
    //         // console.log(1 + ' componentDidUpdate');
    //         if (this.props.isLoadForm !== this.state.isLoadForm) {
    //             // console.log(2 + ' isLoad');
    //             if (responseData !== this.state.formData) {
    //                 // console.log(3 + ' responseData');
    //
    //                 this.formUpdate();
    //
    //                 this.setState({
    //                     isOpenForm: true,
    //                     isLoadForm: this.props.isLoadForm,
    //                     isUpdateForm: this.props.isUpdateForm,
    //                     formData: responseData
    //                 });
    //             }
    //         }
    //
    //         else if (this.props.isUpdateForm !== this.state.isUpdateForm) {
    //             // console.log(4 + ' isUpdate');
    //             this.setState({
    //                 isUpdateForm: this.props.isUpdateForm
    //             });
    //             this.formUpdate(this.state.isLoadForm);
    //         }
    //     }
    // }
    //
    // formUpdate(isLoadForm) {
    //     this.props.apiRequest('/company/' + this.state.objectId + '/supports_date', null, () => {
    //         this.props.formLoad(isLoadForm);
    //     }, null);
    // }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    // setStateAndInput(name, value) {
    //
    //     let input = document.getElementById(name);
    //     input.value = value;
    //
    //     this.setState({
    //         [name]: value
    //     });
    // }

    handleSubmit(event) {
        event.preventDefault();

        if (this.state.yearProgram && this.state.periodProgram && this.state.disciplineName && this.state.disciplineType && this.state.disciplineView) {

            let formData = new FormData();

            formData.append('year_program', this.state.yearProgram);
            formData.append('period_program', this.state.periodProgram);

            formData.append('discipline_name', this.state.disciplineName);
            formData.append('discipline_type', this.state.disciplineType);
            formData.append('discipline_view', this.state.disciplineView);
            formData.append('discipline_index', this.state.disciplineIndex);

            formData.append('venue_discipline', this.state.venueDiscipline);

            formData.append('anchor_of_choice', this.state.anchorOfChoice);

            formData.append('control_exam', this.state.controlExam);
            formData.append('control_diff', this.state.controlDiff);
            formData.append('control_test', this.state.controlTest);
            formData.append('control_project', this.state.controlProject);
            formData.append('control_coursework', this.state.controlCoursework);

            formData.append('lecture_hours', this.state.lectureHours);
            formData.append('laboratory_hours', this.state.laboratoryHours);
            formData.append('practical_hours', this.state.practicalHours);
            formData.append('ciw_hours', this.state.ciwHours);
            formData.append('iws_hours', this.state.iwsHours);

            formData.append('credit_unit', this.state.creditUnit);

            this.setState({
                isHandleSubmit: true
            });

            this.props.apiRequest('api/cabinet/training/plan/' + this.state.objectId + '/discipline/' + this.state.objectData.id + '/edit', formData, () => {
                    // this.setStateAndInput('', null);
                    this.setState({
                        isHandleSubmit: false,
                        formResponseError: null
                    });
                    // this.formUpdate(this.state.isLoadForm);
                    this.props.modalClose();
                    this.props.notificationMessage(this.props.responseMessage, 'green');
                    this.props.pageUpdate();
                }, () => {
                    this.setState({
                        isHandleSubmit: false,
                        formResponseError: this.props.responseError
                    });
                }
            );

        }

        else {
            this.setState({
                formResponseError: 'Внимательно заполните все обязательные поля ( * ).'
            });
        }
    }

    modalClose() {
        this.props.modalClose();
    }

    // alertOpen(alertForm, alertData) {
    //     this.props.alertOpen(alertForm, alertData);
    // }

    anchorsData(data, id) {

        let array = [];

        if (data.disciplines_plan) {
            data.disciplines_plan.map(disciplines_plan => {
                if (disciplines_plan.disciplines) {
                    disciplines_plan.disciplines.map(discipline => {
                        if (Number(discipline.id) !== Number(id)) {
                            array.push(discipline);
                        }
                    });
                }
            });
        }

        return array;
    }

    render() {
        const {
            isMountForm,
            // isOpenForm,
            // formData,
            isHandleSubmit,
            formResponseError,
            disciplineType,
            disciplineView,
            anchorOfChoice,
            anchorsData,
            controlExam,
            controlDiff,
            controlTest,
            controlProject,
            controlCoursework




        } = this.state;

        // let alertData = (objectId, objectData) => {
        //     return {
        //         objectId: objectId,
        //         objectData: objectData
        //     };
        // }

        if (isMountForm) {

            // {!isOpenForm ? (<Loading/>) : (
            //     formData ? () : null)}

            return (
                <div className="Form">
                    <form onSubmit={this.handleSubmit.bind(this)}>
                        <div className="Form__header">
                            <div className="Header__title">Дисциплина</div>
                        </div>
                        <div className="Form__content">

                            <div className="Content__hr Content__hr_bottom-32"/>

                            <div className="Content__box">
                                <div className="Form__field">
                                    <div className="Field__label">Год программы *</div>
                                    <input className="Field__input"
                                           name="yearProgram"
                                           type="number"
                                           step={1}
                                           min={1}
                                           max={44}
                                           defaultValue={this.props.modalData.objectData.year_program}
                                           value={this.state.value}
                                           onChange={this.handleInputChange.bind(this)}/>
                                </div>
                            </div>

                            <div className="Content__box">
                                <div className="Form__field">
                                    <div className="Field__label">Период программы *</div>
                                    <input className="Field__input"
                                           name="periodProgram"
                                           type="number"
                                           step={1}
                                           min={1}
                                           max={44}
                                           defaultValue={this.props.modalData.objectData.period_program}
                                           value={this.state.value}
                                           onChange={this.handleInputChange.bind(this)}/>
                                </div>
                            </div>

                            <div className="Content__hr Content__hr_bottom-32"/>

                            <div className="Content__box">
                                <div className="Form__field">
                                    <div className="Field__label">Название дисциплины *</div>
                                    <input className="Field__input"
                                           name="disciplineName"
                                           type="text"
                                           placeholder="Философские проблемы науки и техники"
                                           defaultValue={this.props.modalData.objectData.discipline_name}
                                           value={this.state.value}
                                           onChange={this.handleInputChange.bind(this)}/>
                                </div>
                            </div>

                            {/*<div className="Content__box">*/}
                            {/*    <div className="Form__field">*/}
                            {/*        <div className="Field__label">Тип дисциплины *</div>*/}
                            {/*        <input className="Field__input"*/}
                            {/*               name="disciplineType"*/}
                            {/*               type="text"*/}
                            {/*               placeholder="Базовая"*/}
                            {/*               defaultValue={this.props.modalData.objectData.discipline_type}*/}
                            {/*               value={this.state.value}*/}
                            {/*               onChange={this.handleInputChange.bind(this)}/>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            <div className="Content__box">
                                <div className="Form__field">
                                    <div className="Field__label">Тип дисциплины *</div>
                                    <select className="Field__select"
                                            id="disciplineType"
                                            name="disciplineType"
                                            value={disciplineType}
                                            onChange={this.handleInputChange.bind(this)}>
                                        <option value="base">Базовый</option>
                                        <option value="variative">Вариативный</option>
                                    </select>
                                </div>
                            </div>

                            {/*<div className="Content__box">*/}
                            {/*    <div className="Form__field">*/}
                            {/*        <div className="Field__label">Вид дисциплины *</div>*/}
                            {/*        <input className="Field__input"*/}
                            {/*               name="disciplineView"*/}
                            {/*               type="text"*/}
                            {/*               placeholder="Дисциплина"*/}
                            {/*               defaultValue={this.props.modalData.objectData.discipline_view}*/}
                            {/*               value={this.state.value}*/}
                            {/*               onChange={this.handleInputChange.bind(this)}/>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            <div className="Content__box">
                                <div className="Form__field">
                                    <div className="Field__label">Вид дисциплины *</div>
                                    <select className="Field__select"
                                            id="disciplineView"
                                            name="disciplineView"
                                            value={disciplineView}
                                            onChange={this.handleInputChange.bind(this)}>
                                        <option value="discipline">Дисциплина</option>
                                        <option value="practice">Практика</option>
                                        <option value="attestation">Аттестация</option>
                                    </select>
                                </div>
                            </div>

                            <div className="Content__box">
                                <div className="Form__field">
                                    <div className="Field__label">Индекс дисциплины</div>
                                    <input className="Field__input"
                                           name="disciplineIndex"
                                           type="text"
                                           placeholder="Б1.Б.01"
                                           defaultValue={this.props.modalData.objectData.discipline_index}
                                           value={this.state.value}
                                           onChange={this.handleInputChange.bind(this)}/>
                                </div>
                            </div>

                            <div className="Content__box">
                                <div className="Form__field">
                                    <div className="Field__label">Место проведения дисциплины (кафедра)</div>
                                    <input className="Field__input"
                                           name="venueDiscipline"
                                           type="text"
                                           placeholder="ПНИПУ"
                                           defaultValue={this.props.modalData.objectData.venue_discipline}
                                           value={this.state.value}
                                           onChange={this.handleInputChange.bind(this)}/>
                                </div>
                            </div>

                            <div className="Content__hr Content__hr_bottom-32"/>

                            {/*<div className="Content__box">*/}
                            {/*    <div className="Form__field">*/}
                            {/*        <div className="Field__label">Выбор между дисциплиной</div>*/}
                            {/*        <input className="Field__input"*/}
                            {/*               name="anchorOfChoice"*/}
                            {/*               type="number"*/}
                            {/*               defaultValue={this.props.modalData.objectData.anchor_of_choice}*/}
                            {/*               value={this.state.value}*/}
                            {/*               onChange={this.handleInputChange.bind(this)}/>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            <div className="Content__box">
                                <div className="Form__field">
                                    <div className="Field__label">Выбор между дисциплиной</div>
                                    <select className="Field__select"
                                            id="anchorOfChoice"
                                            name="anchorOfChoice"
                                            value={anchorOfChoice}
                                            onChange={this.handleInputChange.bind(this)}>
                                        <option value="0">Без выбора</option>
                                        {anchorsData ? (
                                            anchorsData.map((discipline, index) => (
                                                <option value={discipline.id} key={index}>{discipline.discipline_index ? discipline.discipline_index + ' / ' : null}{discipline.discipline_name}</option>
                                            ))
                                        ) : null}
                                    </select>
                                </div>
                            </div>

                            <div className="Content__hr Content__hr_bottom-32"/>

                            {/*<div className="Content__box">*/}
                            {/*    <div className="Form__field">*/}
                            {/*        <div className="Field__label">Вид контроля, экзамен</div>*/}
                            {/*        <input className="Field__input"*/}
                            {/*               name="controlExam"*/}
                            {/*               type="number"*/}
                            {/*               defaultValue={this.props.modalData.objectData.control_exam}*/}
                            {/*               value={this.state.value}*/}
                            {/*               onChange={this.handleInputChange.bind(this)}/>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            <div className="Content__box">
                                <div className="Form__field">
                                    <div className="Field__label">Вид контроля, экзамен</div>
                                    <select className="Field__select"
                                            id="controlExam"
                                            name="controlExam"
                                            value={controlExam}
                                            onChange={this.handleInputChange.bind(this)}>
                                        <option value="0">Нет</option>
                                        <option value="1">Да</option>
                                    </select>
                                </div>
                            </div>

                            {/*<div className="Content__box">*/}
                            {/*    <div className="Form__field">*/}
                            {/*        <div className="Field__label">Вид контроля, диф. зачет</div>*/}
                            {/*        <input className="Field__input"*/}
                            {/*               name="controlDiff"*/}
                            {/*               type="number"*/}
                            {/*               defaultValue={this.props.modalData.objectData.control_diff}*/}
                            {/*               value={this.state.value}*/}
                            {/*               onChange={this.handleInputChange.bind(this)}/>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            <div className="Content__box">
                                <div className="Form__field">
                                    <div className="Field__label">Вид контроля, диф. зачет</div>
                                    <select className="Field__select"
                                            id="controlDiff"
                                            name="controlDiff"
                                            value={controlDiff}
                                            onChange={this.handleInputChange.bind(this)}>
                                        <option value="0">Нет</option>
                                        <option value="1">Да</option>
                                    </select>
                                </div>
                            </div>

                            {/*<div className="Content__box">*/}
                            {/*    <div className="Form__field">*/}
                            {/*        <div className="Field__label">Вид контроля, зачет</div>*/}
                            {/*        <input className="Field__input"*/}
                            {/*               name="controlTest"*/}
                            {/*               type="number"*/}
                            {/*               defaultValue={this.props.modalData.objectData.control_test}*/}
                            {/*               value={this.state.value}*/}
                            {/*               onChange={this.handleInputChange.bind(this)}/>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            <div className="Content__box">
                                <div className="Form__field">
                                    <div className="Field__label">Вид контроля, зачет</div>
                                    <select className="Field__select"
                                            id="controlTest"
                                            name="controlTest"
                                            value={controlTest}
                                            onChange={this.handleInputChange.bind(this)}>
                                        <option value="0">Нет</option>
                                        <option value="1">Да</option>
                                    </select>
                                </div>
                            </div>

                            {/*<div className="Content__box">*/}
                            {/*    <div className="Form__field">*/}
                            {/*        <div className="Field__label">Вид контроля, курсовой проект</div>*/}
                            {/*        <input className="Field__input"*/}
                            {/*               name="controlProject"*/}
                            {/*               type="number"*/}
                            {/*               defaultValue={this.props.modalData.objectData.control_project}*/}
                            {/*               value={this.state.value}*/}
                            {/*               onChange={this.handleInputChange.bind(this)}/>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            <div className="Content__box">
                                <div className="Form__field">
                                    <div className="Field__label">Вид контроля, курсовой проект</div>
                                    <select className="Field__select"
                                            id="controlProject"
                                            name="controlProject"
                                            value={controlProject}
                                            onChange={this.handleInputChange.bind(this)}>
                                        <option value="0">Нет</option>
                                        <option value="1">Да</option>
                                    </select>
                                </div>
                            </div>

                            {/*<div className="Content__box">*/}
                            {/*    <div className="Form__field">*/}
                            {/*        <div className="Field__label">Вид контроля, курсовая работа</div>*/}
                            {/*        <input className="Field__input"*/}
                            {/*               name="controlCoursework"*/}
                            {/*               type="number"*/}
                            {/*               defaultValue={this.props.modalData.objectData.control_coursework}*/}
                            {/*               value={this.state.value}*/}
                            {/*               onChange={this.handleInputChange.bind(this)}/>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            <div className="Content__box">
                                <div className="Form__field">
                                <div className="Field__label">Вид контроля, курсовая работа</div>
                                <select className="Field__select"
                                        id="controlCoursework"
                                        name="controlCoursework"
                                        value={controlCoursework}
                                        onChange={this.handleInputChange.bind(this)}>
                                    <option value="0">Нет</option>
                                    <option value="1">Да</option>
                                </select>
                                </div>
                            </div>

                            <div className="Content__hr Content__hr_bottom-32"/>

                            <div className="Content__box">
                                <div className="Form__field">
                                    <div className="Field__label">Часы лекций</div>
                                    <input className="Field__input"
                                           name="lectureHours"
                                           type="number"
                                           step={1}
                                           min={0}
                                           max={1000}
                                           defaultValue={this.props.modalData.objectData.lecture_hours}
                                           value={this.state.value}
                                           onChange={this.handleInputChange.bind(this)}/>
                                </div>
                            </div>

                            <div className="Content__box">
                                <div className="Form__field">
                                    <div className="Field__label">Лабораторные часы</div>
                                    <input className="Field__input"
                                           name="laboratoryHours"
                                           type="number"
                                           step={1}
                                           min={0}
                                           max={1000}
                                           defaultValue={this.props.modalData.objectData.laboratory_hours}
                                           value={this.state.value}
                                           onChange={this.handleInputChange.bind(this)}/>
                                </div>
                            </div>

                            <div className="Content__box">
                                <div className="Form__field">
                                    <div className="Field__label">Практические часы</div>
                                    <input className="Field__input"
                                           name="practicalHours"
                                           type="number"
                                           step={1}
                                           min={0}
                                           max={1000}
                                           defaultValue={this.props.modalData.objectData.practical_hours}
                                           value={this.state.value}
                                           onChange={this.handleInputChange.bind(this)}/>
                                </div>
                            </div>

                            <div className="Content__box">
                                <div className="Form__field">
                                    <div className="Field__label">Часы контроля самостоятельной работы</div>
                                    <input className="Field__input"
                                           name="ciwHours"
                                           type="number"
                                           step={1}
                                           min={0}
                                           max={1000}
                                           defaultValue={this.props.modalData.objectData.ciw_hours}
                                           value={this.state.value}
                                           onChange={this.handleInputChange.bind(this)}/>
                                </div>
                            </div>

                            <div className="Content__box">
                                <div className="Form__field">
                                    <div className="Field__label">Часы самостоятельной работы студента</div>
                                    <input className="Field__input"
                                           name="iwsHours"
                                           type="number"
                                           step={1}
                                           min={0}
                                           max={1000}
                                           defaultValue={this.props.modalData.objectData.iws_hours}
                                           value={this.state.value}
                                           onChange={this.handleInputChange.bind(this)}/>
                                </div>
                            </div>

                            <div className="Content__hr Content__hr_bottom-32"/>

                            <div className="Content__box">
                                <div className="Form__field">
                                    <div className="Field__label">Зачетные единицы</div>
                                    <input className="Field__input"
                                           name="creditUnit"
                                           type="number"
                                           step={1}
                                           min={0}
                                           max={100}
                                           defaultValue={this.props.modalData.objectData.credit_unit}
                                           value={this.state.value}
                                           onChange={this.handleInputChange.bind(this)}/>
                                </div>
                            </div>



                            <div className="Content__error">
                                <span className="Error__text">{formResponseError}</span>
                            </div>
                        </div>
                        <div className="Form__footer">
                            <button className={isHandleSubmit ? 'Form__button Form__button_blue Form__button_left Form__button_disabled' : 'Form__button Form__button_blue Form__button_left'}
                                    type="submit"
                                    style={{
                                        position: "relative"
                                    }}
                                    disabled={isHandleSubmit ? 'disabled' : null}>
                                Сохранить
                                <div style={{position: "absolute", right: 6, top: 6}}>{isHandleSubmit ? <Loading white={true} /> : null}</div>
                            </button>
                        </div>
                    </form>
                </div>
            );
        }

        else return null;
    }
}

function mapStateToProps(state) {
    return {
        isLoadForm: state.form.isLoadForm,
        isUpdateForm: state.form.isUpdateForm,
        modalData: state.modal.modalData,
        isRequest: state.api.isRequest,
        responseMessage: state.api.responseMessage,
        responseData: state.api.responseData,
        responseError: state.api.responseError
    }
}

function mapDispatchToProps(dispatch) {
    return {
        formLoad: (isLoadForm) => dispatch(formLoad(isLoadForm)),
        pageUpdate: () => dispatch(pageUpdate()),
        notificationMessage: (message, colorMessage) => dispatch(notificationMessage(message, colorMessage)),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        alertOpen: (alertForm, alertData) => dispatch(alertOpen(alertForm, alertData)),
        modalClose: () => dispatch(modalClose())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalTrainingPlanDisciplineEdit);
