import React from "react";
import {connect} from "react-redux";
import {modalClose} from "../../../../store/actions/modalAction";
import {notificationMessage} from "../../../../store/actions/notificationAction";
import {apiRequest} from "../../../../store/actions/apiAction";
import {pageUpdate} from "../../../../store/actions/pageAction";
import Loading from "../../../loading/Loading";
import {formLoad, formUpdate} from "../../../../store/actions/formAction";
import {alertOpen} from "../../../../store/actions/alertAction";
import baseURL from "../../../../library/baseURL";

class ModalEmployee extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isMountForm: true,
            isOpenForm: false,
            isLoadForm: false,
            isUpdateForm: false,
            formData: null,
            isHandleSubmit: false,
            objectId: this.props.modalData.objectId,
            objectData: this.props.modalData.objectData,
            formResponseError: null,
            formMenu: 'data',
            employeeFio: null,
            employeeTelephone: null,
            employeeEmail: null,
            employeePosition: null
        };
    }

    componentDidMount() {
        this.formUpdate(this.state.isLoadForm);
    }

    componentWillUnmount() {
        // console.log('From Unmounted');
        this.setState({
            isMountForm: false
        });
    }

    componentDidUpdate() {
        if (this.state.isMountForm) {
            const responseData = this.props.responseData;
            // console.log(1 + ' componentDidUpdate');
            if (this.props.isLoadForm !== this.state.isLoadForm) {
                // console.log(2 + ' isLoad');
                if (responseData !== this.state.formData) {
                    // console.log(3 + ' responseData');

                    this.formUpdate();

                    this.setState({
                        isOpenForm: true,
                        isLoadForm: this.props.isLoadForm,
                        isUpdateForm: this.props.isUpdateForm,
                        formData: responseData,
                    }, () => {

                        this.setState({
                            employeeFio: this.state.formData.employee_fio,
                            employeeTelephone: (this.state.formData.employee_telephone ? this.state.formData.employee_telephone : ''),
                            employeeEmail: this.state.formData.employee_email,
                            employeePosition: (this.state.formData.employee_position ? this.state.formData.employee_position : '')
                        });
                    });
                }
            }

            else if (this.props.isUpdateForm !== this.state.isUpdateForm) {
                // console.log(4 + ' isUpdate');
                this.setState({
                    isUpdateForm: this.props.isUpdateForm
                });
                this.formUpdate(this.state.isLoadForm);
            }
        }
    }

    formUpdate(isLoadForm) {
        this.props.apiRequest('/api/cabinet/employee/' + this.state.objectId, null, () => {
            this.props.formLoad(isLoadForm);
        }, null);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    setStateAndInput(name, value) {

        let input = document.getElementById(name);
        input.value = value;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        if (this.state.employeeFio && this.state.employeeEmail) {

            let formData = new FormData();

            formData.append('employee_email', this.state.employeeEmail);
            formData.append('employee_fio', this.state.employeeFio);

            formData.append('employee_telephone', this.state.employeeTelephone);
            formData.append('employee_position', this.state.employeePosition);

            this.setState({
                isHandleSubmit: true
            });

            this.props.apiRequest('api/cabinet/employee/' + this.state.objectId + '/edit', formData, () => {
                    // this.setStateAndInput('', null);
                    this.setState({
                        isHandleSubmit: false,
                        formResponseError: null
                    });
                    // this.formUpdate(this.state.isLoadForm);
                    this.props.notificationMessage(this.props.responseMessage, 'green');
                    this.props.formUpdate();
                    this.props.pageUpdate();
                }, () => {
                    this.setState({
                        isHandleSubmit: false,
                        formResponseError: this.props.responseError
                    });
                }
            );
        }

        else {
            this.setState({
                formResponseError: 'Внимательно заполните все обязательные поля ( * ).'
            });
        }
    }

    modalClose() {
        this.props.modalClose();
    }

    alertOpen(alertForm, alertData) {
        this.props.alertOpen(alertForm, alertData);
    }

    formMenu(formMenu) {
        this.setState({
            formMenu: formMenu
        });
    }

    clipboard(clipboard) {
        navigator.clipboard.writeText(clipboard)
            .then(() => {
                this.props.notificationMessage('Текст скопирован.', 'green');
            })
            .catch(err => {
                console.log('Something went wrong', err);
            });
    }

    render() {
        const {
            isMountForm,
            isOpenForm,
            formMenu,
            formData,
            isHandleSubmit,
            formResponseError } = this.state;

        let alertData = (objectId, objectData) => {
            return {
                objectId: objectId,
                objectData: objectData
            };
        }

        let groupName = (groupName, initialYear) => {
            let course;

            let date = new Date();
            course = (date.getFullYear() - Number(initialYear) + 1);

            return groupName.replace(/\$/i, course);
        }

        let telephone = (telephone) => {

            telephone.split('');

            return '+7 (' +
                telephone[1] +
                telephone[2] +
                telephone[3] +
                ') ' +
                telephone[4] +
                telephone[5] +
                telephone[6] +
                '-' +
                telephone[7] +
                telephone[8] +
                telephone[9] +
                telephone[10];
        }

        if (isMountForm) {

            if (!isOpenForm || !formData) {

                return (
                    <div className="Form__section" style={{width: 460}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="460" height="509" viewBox="0 0 460 509">
                            <g id="Group_1666" data-name="Group 1666" transform="translate(-3632 -1229)">
                                <g id="account_circle-black-18dp" transform="translate(3632 1229)">
                                    <path id="Path_1" data-name="Path 1" d="M0,0H100V100H0Z" fill="none"/>
                                    <path id="Path_2" data-name="Path 2" d="M50,2A48,48,0,1,0,98,50,48.018,48.018,0,0,0,50,2Zm0,14.4A14.4,14.4,0,1,1,35.6,30.8,14.381,14.381,0,0,1,50,16.4Zm0,68.16A34.563,34.563,0,0,1,21.2,69.1C21.344,59.552,40.4,54.32,50,54.32c9.552,0,28.656,5.232,28.8,14.784A34.563,34.563,0,0,1,50,84.56Z" fill="#e9ebed"/>
                                </g>
                                <rect id="Rectangle_2054" data-name="Rectangle 2054" width="159" height="24" rx="12" transform="translate(3632 1356)" fill="#2a3e4c" opacity="0.1"/>
                                <rect id="Rectangle_2055" data-name="Rectangle 2055" width="80" height="24" rx="12" transform="translate(3632 1394)" fill="#2a3e4c" opacity="0.1"/>
                                <rect id="Rectangle_2056" data-name="Rectangle 2056" width="147" height="24" rx="12" transform="translate(3720 1394)" fill="#2a3e4c" opacity="0.1"/>
                                <rect id="Rectangle_2057" data-name="Rectangle 2057" width="184" height="14" rx="7" transform="translate(3632 1442)" fill="#2a3e4c" opacity="0.1"/>
                                <rect id="Rectangle_2058" data-name="Rectangle 2058" width="76" height="16" rx="8" transform="translate(3632 1504)" fill="#959fa6" opacity="0.1"/>
                                <rect id="Rectangle_2059" data-name="Rectangle 2059" width="92" height="16" rx="8" transform="translate(3744 1504)" fill="#959fa6" opacity="0.1"/>
                                <rect id="Rectangle_2060" data-name="Rectangle 2060" width="100" height="16" rx="8" transform="translate(3872 1504)" fill="#959fa6" opacity="0.1"/>
                                <rect id="Rectangle_2061" data-name="Rectangle 2061" width="460" height="2" transform="translate(3632 1536)" fill="#f7f7f7"/>
                                <rect id="Rectangle_2062" data-name="Rectangle 2062" width="152" height="16" rx="8" transform="translate(3632 1566)" fill="#2a3e4c" opacity="0.1"/>
                                <rect id="Rectangle_2063" data-name="Rectangle 2063" width="118" height="14" rx="7" transform="translate(3632 1646)" fill="#2a3e4c" opacity="0.1"/>
                                <rect id="Rectangle_2064" data-name="Rectangle 2064" width="118" height="16" rx="8" transform="translate(3632 1612)" fill="#959fa6" opacity="0.1"/>
                                <rect id="Rectangle_2065" data-name="Rectangle 2065" width="184" height="14" rx="7" transform="translate(3632 1724)" fill="#2a3e4c" opacity="0.1"/>
                                <rect id="Rectangle_2066" data-name="Rectangle 2066" width="144" height="16" rx="8" transform="translate(3632 1690)" fill="#959fa6" opacity="0.1"/>
                            </g>
                        </svg>
                    </div>
                );
            }

            else {

                return (
                    <div className="Form__section" style={{width: 460}}>
                        <div className="Section__header">
                            <div className="Header__photo">
                                {formData.employee_avatar ? (
                                    <label>
                                        <div className="Box__avatar Avatar__image"
                                             style={{
                                                 height: 100,
                                                 width: 100,
                                                 borderRadius: '50%',
                                                 backgroundColor: '#e6eaec',
                                                 marginRight: 32,
                                                 display: 'inline-block'
                                             }}>
                                            <img className={'Avatar__image'}
                                                 src={baseURL() + 'api/images/avatar/' + formData.employee_avatar}
                                                 width="100"
                                                 height="100"/>
                                        </div>
                                    </label>
                                ) : (
                                    <label>
                                        <div className="Box__avatar Avatar__image" style={{cursor: "pointer"}}>
                                            <svg id="account_circle-black-18dp" xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">
                                                <path id="Path_1" data-name="Path 1" d="M0,0H100V100H0Z" fill="none"/>
                                                <path id="Path_2" data-name="Path 2" d="M50,2A48,48,0,1,0,98,50,48.018,48.018,0,0,0,50,2Zm0,14.4A14.4,14.4,0,1,1,35.6,30.8,14.381,14.381,0,0,1,50,16.4Zm0,68.16A34.563,34.563,0,0,1,21.2,69.1C21.344,59.552,40.4,54.32,50,54.32c9.552,0,28.656,5.232,28.8,14.784A34.563,34.563,0,0,1,50,84.56Z" fill="#e9ebed"/>
                                            </svg>
                                        </div>
                                    </label>
                                )}
                            </div>
                            <div className="Header__title">
                                <div className="Title__text">
                                    {formData.employee_fio.split(' ')[0]}<br/>{formData.employee_fio.split(' ')[1] + ' ' + formData.employee_fio.split(' ')[2]}
                                    <div style={{
                                        display: "inline-block",
                                        height: 32,
                                        width: 32,
                                        marginLeft: 8,
                                        cursor: "pointer"
                                    }}
                                         onClick={this.clipboard.bind(this, formData.employee_fio)}>
                                        <svg style={{
                                            verticalAlign:"top",
                                            padding: 8
                                        }}
                                             xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                            <g id="Group_1676" data-name="Group 1676" transform="translate(-1073 -187)">
                                                <g id="Rectangle_2087" data-name="Rectangle 2087" transform="translate(1077 187)" fill="none" stroke="#aeb5ba" strokeLinejoin="round" strokeWidth="3">
                                                    <rect width="12" height="12" rx="4" stroke="none"/>
                                                    <rect x="1.5" y="1.5" width="9" height="9" rx="2.5" fill="none"/>
                                                </g>
                                                <g id="Rectangle_2088" data-name="Rectangle 2088" transform="translate(1073 191)" fill="#fff" stroke="#2a3e4c" strokeLinejoin="round" strokeWidth="3">
                                                    <rect width="12" height="12" rx="4" stroke="none"/>
                                                    <rect x="1.5" y="1.5" width="9" height="9" rx="2.5" fill="none"/>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="Profile__content">
                            <div className="Profile__body">

                                <div className="Box">
                                    <div className="Box__text">
                                        Преподаватель
                                        {formData.employee_position ? ' / ' + formData.employee_position : null}</div>
                                </div>

                                <div className="Box">
                                    <div className="Box__menu">
                                        <div className={formMenu === 'data' ? 'Menu__button active' : 'Menu__button'}
                                             onClick={this.formMenu.bind(this, 'data')}>
                                            Профиль
                                            <div className="Button__border"/>
                                        </div>
                                        {/*<div className={formMenu === 'settings' ? 'Menu__button active' : 'Menu__button'}*/}
                                        {/*     onClick={this.formMenu.bind(this, 'settings')}>*/}
                                        {/*    Настройки*/}
                                        {/*    <div className="Button__border"/>*/}
                                        {/*</div>*/}
                                        {/*<div className={formMenu === 'activity' ? 'Menu__button Menu__button_right active' : 'Menu__button Menu__button_right'}*/}
                                        {/*     onClick={this.formMenu.bind(this, 'activity')}>*/}
                                        {/*    Активность*/}
                                        {/*    <div className="Button__border"/>*/}
                                        {/*</div>*/}
                                        <div className={formMenu === 'courses' ? 'Menu__button active' : 'Menu__button'}
                                             onClick={this.formMenu.bind(this, 'courses')}>
                                            Курсы
                                            <div className="Button__border"/>
                                        </div>
                                    </div>
                                    <div className="Menu__hr"/>
                                </div>



                                {formMenu === 'data' ? (
                                    <React.Fragment>

                                        {/*<div className="Content__hr"/>*/}

                                        <div className="Box">
                                            <div className="Box__title">Личные данные</div>
                                        </div>


                                        <div className="Box">
                                            <div className="Box__label">Телефон</div>
                                            <div
                                                className={!formData.employee_telephone ? 'Box__text Box__text_disabled' : 'Box__text'}>
                                                {!formData.employee_telephone ? 'Нет данных' : telephone(formData.employee_telephone)}
                                                {!formData.employee_telephone ? null : (
                                                    <div style={{
                                                        display: "inline-block",
                                                        height: 16,
                                                        width: 16,
                                                        marginLeft: 16,
                                                        cursor: "pointer"
                                                    }}
                                                         onClick={this.clipboard.bind(this, formData.employee_telephone)}>
                                                        <svg style={{
                                                            verticalAlign:"top"
                                                        }}
                                                             xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                                            <g id="Group_1676" data-name="Group 1676" transform="translate(-1073 -187)">
                                                                <g id="Rectangle_2087" data-name="Rectangle 2087" transform="translate(1077 187)" fill="none" stroke="#aeb5ba" strokeLinejoin="round" strokeWidth="3">
                                                                    <rect width="12" height="12" rx="4" stroke="none"/>
                                                                    <rect x="1.5" y="1.5" width="9" height="9" rx="2.5" fill="none"/>
                                                                </g>
                                                                <g id="Rectangle_2088" data-name="Rectangle 2088" transform="translate(1073 191)" fill="#fff" stroke="#2a3e4c" strokeLinejoin="round" strokeWidth="3">
                                                                    <rect width="12" height="12" rx="4" stroke="none"/>
                                                                    <rect x="1.5" y="1.5" width="9" height="9" rx="2.5" fill="none"/>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="Box Box_end">
                                            <div className="Box__label">Электронная почта</div>
                                            <div className="Box__text">
                                                {formData.employee_email}
                                                <div style={{
                                                    display: "inline-block",
                                                    height: 16,
                                                    width: 16,
                                                    marginLeft: 16,
                                                    cursor: "pointer"
                                                }}
                                                     onClick={this.clipboard.bind(this, formData.employee_email)}>
                                                    <svg style={{
                                                        verticalAlign:"top"
                                                    }}
                                                         xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                                        <g id="Group_1676" data-name="Group 1676" transform="translate(-1073 -187)">
                                                            <g id="Rectangle_2087" data-name="Rectangle 2087" transform="translate(1077 187)" fill="none" stroke="#aeb5ba" strokeLinejoin="round" strokeWidth="3">
                                                                <rect width="12" height="12" rx="4" stroke="none"/>
                                                                <rect x="1.5" y="1.5" width="9" height="9" rx="2.5" fill="none"/>
                                                            </g>
                                                            <g id="Rectangle_2088" data-name="Rectangle 2088" transform="translate(1073 191)" fill="#fff" stroke="#2a3e4c" strokeLinejoin="round" strokeWidth="3">
                                                                <rect width="12" height="12" rx="4" stroke="none"/>
                                                                <rect x="1.5" y="1.5" width="9" height="9" rx="2.5" fill="none"/>
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>

                                        {/*<div className="Content__hr"/>*/}

                                        <div className="Form__link">
                                            <div className="Link__delete" onClick={this.alertOpen.bind(this, 'alertTeacherDelete', alertData(this.state.objectId, this.state.objectData))}>Удалить преподавателя</div>
                                        </div>

                                    </React.Fragment>
                                ) : null}


                                {formMenu === 'settings' ? (
                                    <React.Fragment>
                                        <div className="Form">

                                            {/*<div className="Form__link">*/}
                                            {/*    <div className="Link__delete" onClick={this.alertOpen.bind(this, 'alertTeacherDelete', alertData(this.state.objectId, this.state.objectData))}>Удалить преподавателя</div>*/}
                                            {/*</div>*/}

                                            {/*<form onSubmit={this.handleSubmit.bind(this)}>*/}
                                            {/*    <div className="Form__content">*/}

                                            {/*        <div className="Box">*/}
                                            {/*            <div className="Box__title"*/}
                                            {/*                 style={{marginBottom: 16}}>Личные данные</div>*/}
                                            {/*        </div>*/}

                                            {/*        <div className="Content__box">*/}
                                            {/*            <div className="Form__field">*/}
                                            {/*                <div className="Field__label">Фамилия Имя Отчество *</div>*/}
                                            {/*                <input className="Field__input"*/}
                                            {/*                       name="employeeFio"*/}
                                            {/*                       type="text"*/}
                                            {/*                       placeholder="Иванов Иван Иванович"*/}
                                            {/*                       defaultValue={formData.employee_fio}*/}
                                            {/*                       value={this.state.value}*/}
                                            {/*                       onChange={this.handleInputChange.bind(this)}/>*/}
                                            {/*            </div>*/}
                                            {/*        </div>*/}

                                            {/*        <div className="Content__box">*/}
                                            {/*            <div className="Form__field">*/}
                                            {/*                <div className="Field__label">Телефон</div>*/}
                                            {/*                <input className="Field__input"*/}
                                            {/*                       name="employeeTelephone"*/}
                                            {/*                       type="number"*/}
                                            {/*                       placeholder="89991234567"*/}
                                            {/*                       defaultValue={formData.employee_telephone}*/}
                                            {/*                       value={this.state.value}*/}
                                            {/*                       onChange={this.handleInputChange.bind(this)}/>*/}
                                            {/*            </div>*/}
                                            {/*        </div>*/}

                                            {/*        <div className="Content__box">*/}
                                            {/*            <div className="Form__field">*/}
                                            {/*                <div className="Field__label">Электронная почта *</div>*/}
                                            {/*                <input className="Field__input"*/}
                                            {/*                       name="employeeEmail"*/}
                                            {/*                       type="email"*/}
                                            {/*                       placeholder="teacher@email.com"*/}
                                            {/*                       defaultValue={formData.employee_email}*/}
                                            {/*                       value={this.state.value}*/}
                                            {/*                       onChange={this.handleInputChange.bind(this)}/>*/}
                                            {/*            </div>*/}
                                            {/*        </div>*/}

                                            {/*        <div className="Content__hr Content__hr_bottom-32"/>*/}

                                            {/*        <div className="Box">*/}
                                            {/*            <div className="Box__title"*/}
                                            {/*                 style={{marginBottom: 16}}>Образование</div>*/}
                                            {/*        </div>*/}

                                            {/*        <div className="Content__box">*/}
                                            {/*            <span className="Box__info">Сведения о квалификации</span>*/}
                                            {/*        </div>*/}

                                            {/*        <div className="Content__box">*/}
                                            {/*            <div className="Form__field">*/}
                                            {/*                <div className="Field__label">Должность, ученая степень</div>*/}
                                            {/*                <input className="Field__input"*/}
                                            {/*                       name="employeePosition"*/}
                                            {/*                       type="text"*/}
                                            {/*                       defaultValue={formData.employee_position}*/}
                                            {/*                       value={this.state.value}*/}
                                            {/*                       onChange={this.handleInputChange.bind(this)} />*/}
                                            {/*            </div>*/}
                                            {/*        </div>*/}

                                            {/*        <div className="Content__error">*/}
                                            {/*            <span className="Error__text">{formResponseError}</span>*/}
                                            {/*        </div>*/}
                                            {/*    </div>*/}
                                            {/*    <div className="Form__footer">*/}
                                            {/*        <button className={isHandleSubmit ? 'Form__button Form__button_blue Form__button_left Form__button_disabled' : 'Form__button Form__button_blue Form__button_left'}*/}
                                            {/*                type="submit"*/}
                                            {/*                style={{*/}
                                            {/*                    position: "relative"*/}
                                            {/*                }}*/}
                                            {/*                disabled={isHandleSubmit ? 'disabled' : null}>*/}
                                            {/*            Сохранить*/}
                                            {/*            <div style={{position: "absolute", right: 6, top: 6}}>{isHandleSubmit ? <Loading white={true} /> : null}</div>*/}
                                            {/*        </button>*/}
                                            {/*    </div>*/}
                                            {/*</form>*/}
                                        </div>
                                    </React.Fragment>
                                ) : null}

                                {formMenu === 'activity' ? (
                                    <React.Fragment>
                                        {/*<div className="Form">*/}

                                        {/*    <div className="Form__content">*/}

                                        {/*        <div className="Box">*/}
                                        {/*            <div className="Box__title"*/}
                                        {/*                 style={{marginBottom: 16}}>За месяц</div>*/}
                                        {/*        </div>*/}

                                        {/*        <div className="Content__hr Content__hr_bottom-32"/>*/}

                                        {/*        <div className="Box">*/}
                                        {/*            <div className="Box__title"*/}
                                        {/*                 style={{marginBottom: 16}}>Посещения за день</div>*/}
                                        {/*        </div>*/}

                                        {/*        <div className="Content__hr Content__hr_bottom-32"/>*/}

                                        {/*        <div className="Box">*/}
                                        {/*            <div className="Box__title"*/}
                                        {/*                 style={{marginBottom: 16}}>Действия за день</div>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </React.Fragment>
                                ) : null}


                                {formMenu === 'courses' ? (
                                    <ul className="Body__list">

                                        {formData.training_courses.length > 0 ? (
                                        formData.training_courses.map((training_course, index) => (

                                            <li className={this.props.profileData.profile_type === 'administrator' ? "List__item List__item_active" : "List__item"}

                                                key={training_course.id} style={{
                                                backgroundColor: "#f7f7f7",
                                                padding: "16px",
                                                borderRadius: 7,
                                                marginTop: 12,
                                                position: "relative",
                                                cursor: (this.props.profileData.profile_type === 'administrator' ? "pointer" : "default")
                                            }}
                                                onClick={
                                                this.props.profileData.profile_type === 'administrator' ? (
                                                    this.alertOpen.bind(this, 'alertTeacherTrainingCourseAnnul', alertData(training_course.id, null))
                                                ) : null
                                            }>

                                                <div style={{
                                                    display: "inline-block",
                                                    position: "absolute",
                                                    top: -8,
                                                    right: 16
                                                }}>
                                                        <div className="Item__index"
                                                             style={{
                                                                 fontFamily: "Roboto, sans-serif",
                                                                 fontSize: 15,
                                                                 fontWeight: 700,
                                                                 backgroundColor: "#0061FF",
                                                                 color: "white",
                                                                 padding: "4px 6px 3px",
                                                                 borderRadius: 4,
                                                                 display: "inline-block",
                                                                 textTransform: "uppercase",
                                                                 marginRight: 16
                                                             }}>
                                                            {groupName(training_course.group_name, training_course.initial_year)}
                                                        </div>
                                                </div>

                                                <div className="Item__block Item__block_inline" style={{
                                                    paddingTop: 2,
                                                    // textOverflow: "ellipsis",
                                                    // overflow: "hidden",
                                                    // maxWidth: 1240,
                                                    // whiteSpace: "nowrap",
                                                    // wordWrap: "break-word"
                                                    fontSize: 16,
                                                    fontWeight: 400,
                                                    fontFamily: "Roboto, sans-serif",
                                                    color: "#2a3e4c"
                                                }}>
                                                    {training_course.discipline_name}
                                                </div>

                                            </li>


                                        ))
                                        ) : (

                                        <li>
                                            <div style={{
                                                textAlign: "center",
                                                fontSize: 32,
                                                fontWeight: 900,
                                                fontFamily: "Roboto, sans-serif",
                                                color: "#2a3e4c",
                                                padding: "18px 0 0",
                                                opacity: 0.15
                                            }}>

                                                Нет активных курсов
                                            </div>


                                        </li>
                                        )}

                                    </ul>

                                ) : null}

                            </div>
                        </div>
                    </div>
                );
            }
        }

        else return null;
    }
}

function mapStateToProps(state) {
    return {
        isLoadForm: state.form.isLoadForm,
        isUpdateForm: state.form.isUpdateForm,
        modalData: state.modal.modalData,
        isRequest: state.api.isRequest,
        responseMessage: state.api.responseMessage,
        responseData: state.api.responseData,
        responseError: state.api.responseError,
        profileData: state.profile.profileData
    }
}

function mapDispatchToProps(dispatch) {
    return {
        formLoad: (isLoadForm) => dispatch(formLoad(isLoadForm)),
        pageUpdate: () => dispatch(pageUpdate()),
        formUpdate: () => dispatch(formUpdate()),
        notificationMessage: (message, colorMessage) => dispatch(notificationMessage(message, colorMessage)),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        alertOpen: (alertForm, alertData) => dispatch(alertOpen(alertForm, alertData)),
        modalClose: () => dispatch(modalClose())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalEmployee);
