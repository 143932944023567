import React from "react";
import {connect} from "react-redux";
import {modalClose} from "../../store/actions/modalAction";
import "./Modal.scss";
import "../form/Form.scss";
import ModalEmployeeAdd from "../page/employees/modal/modalEmployeeAdd";
import ModalTrainingGroupAdd from "../page/students/modal/modalTrainingGroupAdd";
import ModalStudentAdd from "../page/students/modal/modalStudentAdd";
import ModalStudent from "../page/students/modal/modalStudent";
import ModalTrainingGroupEdit from "../page/students/modal/modalTrainingGroupEdit";
import ModalEmployee from "../page/employees/modal/modalEmployee";
import ModalTrainingPlanAdd from "../page/training/plans/modal/modalTrainingPlanAdd";
import ModalTrainingScheduleAdd from "../page/training/schedules/modal/modalTrainingScheduleAdd";
import ModalTrainingPlanDisciplineAdd from "../page/training/plan/modal/modalTrainingPlanDisciplineAdd";
import ModalTrainingPlanEdit from "../page/training/plan/modal/modalTrainingPlanEdit";
import ModalTrainingPlanDisciplineEdit from "../page/training/plan/modal/modalTrainingPlanDisciplineEdit";
import ModalTrainingScheduleEdit from "../page/training/schedule/modal/modalTrainingScheduleEdit";
import ModalTrainingSchedulePeriodAdd from "../page/training/schedule/modal/modalTrainingSchedulePeriodAdd";
import ModalTrainingSchedulePeriodEdit from "../page/training/schedule/modal/modalTrainingSchedulePeriodEdit";
import ModalTeacherTrainingCourseAdd from "../page/teacher/training/courses/modal/modalTeacherTrainingCourseAdd";
import ModalTeacherTrainingCourseLessonAdd
    from "../page/teacher/training/course/modal/modalTeacherTrainingCourseLessonAdd";
import ModalTeacherTrainingCourseLessonEdit
    from "../page/teacher/training/course/modal/modalTeacherTrainingCourseLessonEdit";
import ModalStudentGradeBook from "../page/student/gradebook/modal/modalStudentGradeBook";
import ModalStudentChatAdd from "../page/student/messages/modal/modalStudentChatAdd";
import ModalTeacherChatAdd from "../page/teacher/messages/modal/modalTeacherChatAdd";
import ModalFeedbackAdd from "../page/feedbacks/modal/modalFeedbackAdd";
import ModalTeacherGradebookResit from "../page/teacher/resit/modal/modalTeacherGradebookResit";
import ModalTeacherCourseFinish from "../page/teacher/training/course/modal/modalTeacherCourseFinish";
import ModalTeacherTrainingCourseStudents
    from "../page/teacher/training/course/modal/modalTeacherTrainingCourseStudents";
import ModalTrainingGroupDisciplinePrint from "../page/students/modal/modalTrainingGroupDisciplinePrint";
import ModalTeacherTrainingCourseExam from "../page/teacher/training/course/modal/modalTeacherTrainingCourseExam";
import ModalTeacherTrainingCourseExamEdit
    from "../page/teacher/training/course/modal/modalTeacherTrainingCourseExamEdit";

class Modal extends React.Component {

    modalClose() {
        this.props.modalClose();
    }

    switchForm(modalForm) {
        switch(modalForm) {
            case 'modalEmployee': return (<ModalEmployee/>);
            case 'modalEmployeeAdd': return (<ModalEmployeeAdd/>);
            case 'modalTrainingGroupAdd': return (<ModalTrainingGroupAdd/>);
            case 'modalTrainingGroupEdit': return (<ModalTrainingGroupEdit/>);
            case 'modalStudent': return (<ModalStudent/>);
            case 'modalStudentAdd': return (<ModalStudentAdd/>);
            case 'modalTrainingPlanAdd': return (<ModalTrainingPlanAdd/>);
            case 'modalTrainingPlanEdit': return (<ModalTrainingPlanEdit/>);
            case 'modalTrainingPlanDisciplineAdd': return (<ModalTrainingPlanDisciplineAdd/>);
            case 'modalTrainingPlanDisciplineEdit': return (<ModalTrainingPlanDisciplineEdit/>);
            case 'modalTrainingScheduleAdd': return (<ModalTrainingScheduleAdd/>);
            case 'modalTrainingScheduleEdit': return (<ModalTrainingScheduleEdit/>);
            case 'modalTrainingSchedulePeriodAdd': return (<ModalTrainingSchedulePeriodAdd/>);
            case 'modalTrainingSchedulePeriodEdit': return (<ModalTrainingSchedulePeriodEdit/>);
            case 'modalTeacherTrainingCourseAdd': return (<ModalTeacherTrainingCourseAdd/>);
            case 'modalTeacherTrainingCourseLessonAdd': return (<ModalTeacherTrainingCourseLessonAdd/>);
            case 'modalTeacherTrainingCourseLessonEdit': return (<ModalTeacherTrainingCourseLessonEdit/>);
            case 'modalStudentGradeBook': return (<ModalStudentGradeBook/>);
            case 'modalStudentChatAdd': return (<ModalStudentChatAdd/>);
            case 'modalTeacherChatAdd': return (<ModalTeacherChatAdd/>);
            case 'modalFeedbackAdd': return (<ModalFeedbackAdd/>);
            case 'modalTeacherGradebookResit': return (<ModalTeacherGradebookResit/>);
            case 'modalTeacherCourseFinish': return (<ModalTeacherCourseFinish/>);
            case 'modalTeacherTrainingCourseStudents': return (<ModalTeacherTrainingCourseStudents/>);
            case 'modalTrainingGroupDisciplinePrint': return (<ModalTrainingGroupDisciplinePrint/>);
            case 'modalTeacherTrainingCourseExam': return (<ModalTeacherTrainingCourseExam/>);
            case 'modalTeacherTrainingCourseExamEdit': return (<ModalTeacherTrainingCourseExamEdit/>);
            default: return (<div className="Form Form_null"/>);
        }
    }

    render() {
        const { isModal, modalForm } = this.props;

        let switchForm = this.switchForm(modalForm);

        if (!isModal) { return null; }

        else {
            return (
                <div className="Modal">
                    <i/>
                    <div className="Modal__dialog">
                        <div className="Modal__content">
                            <div className="Modal__body">
                                {switchForm}
                            </div>
                            <div className="Modal__close">
                                <div className="Button__close icon-close" onClick={this.modalClose.bind(this, false, null)}/>
                            </div>
                        </div>
                    </div>
                    <i/>
                </div>
            );
        }
    }
}

function mapStateToProps(state) {
    return {
        isModal: state.modal.isModal,
        modalForm: state.modal.modalForm,
        interimData: state.modal.interimData
    }
}

function mapDispatchToProps(dispatch) {
    return {
        modalClose: () => dispatch(modalClose())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
