import React from "react";
import {connect} from "react-redux";
import {pageLoad, pageUpdate, pageMount} from "../../../store/actions/pageAction";
import {apiRequest, apiUnmount} from "../../../store/actions/apiAction";
import {modalOpen} from "../../../store/actions/modalAction";
import "./Students.scss";
import Loading from "../../loading/Loading";
import baseURL from "../../../library/baseURL";
import {alertOpen} from "../../../store/actions/alertAction";

class Students extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isMount: true,
            isOpen: false,
            isLoad: false,
            isUpdate: false,
            studentsData: null,
            unrollArray: []
        };
    }

    componentDidMount() {
        this.props.pageMount();
        this.pageUpdate(this.state.isLoad);
        this.intervalUpdate = setInterval(this.props.pageUpdate, 180000);
    }

    componentWillUnmount() {
        // console.log('Employees Unmounted');

        clearInterval(this.intervalUpdate);

        this.setState({
            isMount: false
        }, () => {
            this.props.apiUnmount();
        });
    }

    componentDidUpdate() {
        if (this.state.isMount) {
            const responseData = this.props.responseData;
            // console.log(1 + ' componentDidUpdate');
            if (this.props.isLoad !== this.state.isLoad) {
                // console.log(2 + ' isLoad');
                if (responseData !== this.state.studentsData) {
                    // console.log(3 + ' responseData');

                    this.setState({
                        isOpen: true,
                        isLoad: this.props.isLoad,
                        isUpdate: this.props.isUpdate,
                        studentsData: responseData
                    });
                }
            }

            else if (this.props.isUpdate !== this.state.isUpdate) {
                // console.log(4 + ' isUpdate');
                this.pageUpdate(this.state.isLoad);

                this.setState({
                    isUpdate: this.props.isUpdate
                });
            }
        }
    }

    pageUpdate(isLoad) {
        this.props.apiRequest('api/cabinet/students', null, () => {
            this.props.pageLoad(isLoad);
        }, null);
    }

    modalOpen(modalForm, modalData) {
        this.props.modalOpen(modalForm, modalData);
    }

    alertOpen(alertForm, alertData) {
        this.props.alertOpen(alertForm, alertData);
    }

    unrollArray(id) {
        let unrollArray = this.state.unrollArray;
        let repeat = false;

        if (unrollArray.length > 0) {

            unrollArray.map((object, index) => {
                if (Number(object) === Number(id)) {
                    repeat = true;
                    unrollArray.splice(index, 1);
                }
            });
        }

        if (!repeat) {

            unrollArray.push(id);
        }

        this.setState({
            unrollArray: unrollArray
        });
    }

    render() {
        const { isMount, isOpen, studentsData, unrollArray } = this.state;

        let modalData = (objectId, objectData) => {
            return {
                objectId: objectId,
                objectData: objectData
            };
        }

        let alertData = (objectId, objectData) => {
            return {
                objectId: objectId,
                objectData: objectData
            };
        }

        let telephone = (telephone) => {

            telephone.split('');

            return '+7 (' +
                telephone[1] +
                telephone[2] +
                telephone[3] +
                ') ' +
                telephone[4] +
                telephone[5] +
                telephone[6] +
                '-' +
                telephone[7] +
                telephone[8] +
                telephone[9] +
                telephone[10];
        }

        let lastVisit = (time) => {

            return (
                <div className={((parseInt(String(new Date().getTime()/1000)) - time) < 300) ? 'Student__online Student__online_green' : 'Student__online'}/>
            );
        }

        let lastVisitDataTime = (time) => {

            if (Number(time) > 0) {

                if ((parseInt(String(new Date().getTime()/1000)) - time) < 300) {

                    return 'сейчас';
                }

                else {
                    let date = new Date(time * 1000);
                    let day = "0" + date.getDate();
                    let month = "0" + (date.getMonth() + 1);
                    let year = String(date.getFullYear());
                    let hours = "0" + date.getHours();
                    let minutes = "0" + date.getMinutes();
                    let seconds = "0" + date.getSeconds();
                    let formattedData = day.substr(-2) + '.' + month.substr(-2) + '.' + year.substr(-2);
                    // let formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
                    let formattedTime = hours.substr(-2) + ':' + minutes.substr(-2);

                    return formattedTime + ' ' + formattedData;
                }
            }

            else {

                return 'никогда';
            }
        }

        let groupName = (groupName, initialYear) => {
            let course;

            let date = new Date();
            course = (date.getFullYear() - Number(initialYear) + 1);

            if (groupName) {
                return groupName.replace(/\$/i, course);
            }
        }

        let isUnroll = (id) => {
            let repeat = false;

            if (unrollArray.length > 0) {

                unrollArray.map(object => {
                    if (Number(object) === Number(id)) {
                        repeat = true;
                    }
                });
            }

            return repeat;
        }

        if (isMount) {

            if (!isOpen || !studentsData) {
                return (<div className="Page__loader"><i/><Loading/><i/></div>);
            }

            else {

                return (
                    <div className="Page__section">

                        <div className="Section__header">
                            <div className="Header__title">
                                <div className="Title__text">Студенты</div>
                            </div>
                        </div>

                        <div className="Section__content">

                            <div className="Students">
                                <div className="Students__body">
                                    {studentsData.map((year, index) => (
                                        <React.Fragment key={index}>
                                            <div className="Body__title">{year.key.toUpperCase()}</div>
                                            <ul className="Body__list">
                                                {year.array.map((trainingGroup, index) => (
                                                    <React.Fragment key={index}>
                                                        <li className={isUnroll(trainingGroup.id) ? 'List__item active' : 'List__item'}
                                                            onClick={this.unrollArray.bind(this, trainingGroup.id)}
                                                            style={{
                                                                padding: (trainingGroup.direction_preparation ? "12px 24px 7px 24px" : "17px 24px 16px 24px")
                                                            }}>
                                                            {/*<div className="Table__td">{groupName(trainingGroup.group_name, trainingGroup.initial_year)}</div>*/}
                                                            {/*<div className="Table__td" style={{display: "inline-block", verticalAlign: "top", paddingTop: 4}}>{groupName(trainingGroup.group_name, trainingGroup.initial_year)}</div>*/}

                                                            {/*{trainingGroup.direction_preparation ? (*/}
                                                            {/*    <React.Fragment>*/}
                                                            {/*        <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                                            {/*        <div className="Table__td" style={{display: "inline-block", verticalAlign: "top", paddingTop: 4}}>{trainingGroup.direction_preparation}</div>*/}
                                                            {/*    </React.Fragment>*/}
                                                            {/*) : null}*/}

                                                            {/*{trainingGroup.employee_fio ? (*/}
                                                            {/*    <React.Fragment>*/}
                                                            {/*        <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                                            {/*        <div className="Table__td" style={{display: "inline-block", verticalAlign: "top", paddingTop: 4}}>{trainingGroup.employee_fio}</div>*/}
                                                            {/*    </React.Fragment>*/}
                                                            {/*    ) : null}*/}

                                                            {trainingGroup.direction_preparation ? (
                                                                <React.Fragment>
                                                                    <div className="Table__td" style={{display: "inline-block", verticalAlign: "top", paddingTop: 4}}>{groupName(trainingGroup.group_name, trainingGroup.initial_year)}</div>
                                                                    <div className="Item__hr Item__hr_vertical Item__hr_inline"/>
                                                                    <div className="Table__td" style={{display: "inline-block", verticalAlign: "top", paddingTop: 4}}>{trainingGroup.direction_preparation}</div>
                                                                </React.Fragment>
                                                            ) : (
                                                                <div className="Table__td">{groupName(trainingGroup.group_name, trainingGroup.initial_year)}</div>
                                                            )}
                                                        </li>
                                                        {isUnroll(trainingGroup.id) ? (
                                                            <li>
                                                                {trainingGroup.students ? (
                                                                    <table className="Body__table">
                                                                        <tbody>
                                                                            {trainingGroup.students.map((student, index) => (
                                                                                <tr className="Table__tr" key={index} onClick={this.modalOpen.bind(this, 'modalStudent', modalData(student.id, student))}>
                                                                                    <td className="Table__td">
                                                                                        {student.student_avatar ? (
                                                                                                <div style={{
                                                                                                    position: "relative"
                                                                                                }}>
                                                                                                    <div className="Student__photo"/>
                                                                                                    <img style={{
                                                                                                        position: "absolute",
                                                                                                        top: 2,
                                                                                                        left: 2,
                                                                                                        width: 32,
                                                                                                        height: 32,
                                                                                                        borderRadius: "50%",
                                                                                                        opacity: 1
                                                                                                    }}
                                                                                                         src={baseURL() + 'api/images/avatar/' + student.student_avatar}
                                                                                                         width="100"
                                                                                                         height="100"/>
                                                                                                </div>
                                                                                            ) : (
                                                                                            <div style={{
                                                                                                position: "relative"
                                                                                            }}>
                                                                                                <div className="Student__photo"/>
                                                                                            </div>
                                                                                        )}
                                                                                    </td>
                                                                                    <td className="Table__td">{student.student_fio}</td>
                                                                                    <td className="Table__td">{student.student_email}</td>
                                                                                    <td className="Table__td">{student.student_telephone ? telephone(student.student_telephone) : null}</td>
                                                                                    <td className="Table__td" style={{textAlign: "right", paddingRight: 0}}>{lastVisitDataTime(student.last_visit)}</td>
                                                                                    <td className="Table__td">{lastVisit(student.last_visit)}</td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                ) : null}

                                                                <div className="Button__add"
                                                                     onClick={this.modalOpen.bind(this, 'modalStudentAdd', modalData(trainingGroup.id, null))}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">
                                                                        <g id="Group_1662" data-name="Group 1662" transform="translate(-1826 -274)">
                                                                            <circle id="Ellipse_61" data-name="Ellipse 61" cx="21" cy="21" r="21" transform="translate(1826 274)" fill="#0061ff"/>
                                                                            <rect id="Rectangle_2052" data-name="Rectangle 2052" width="4" height="14" rx="1" transform="translate(1845 288)" fill="#fff"/>
                                                                            <rect id="Rectangle_2053" data-name="Rectangle 2053" width="4" height="14" rx="1" transform="translate(1854 293) rotate(90)" fill="#fff"/>
                                                                        </g>
                                                                    </svg>

                                                                    <div className="Add__text">Студент</div>
                                                                </div>

                                                                <div className="Button__edit"
                                                                     onClick={this.modalOpen.bind(this, 'modalTrainingGroupEdit', modalData(trainingGroup.id, trainingGroup))}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">
                                                                        <g id="Group_2240" data-name="Group 2240" transform="translate(-471 -775)">
                                                                            <rect id="Rectangle_2757" data-name="Rectangle 2757" width="42" height="42" transform="translate(471 775)" fill="#f7f7f7" opacity="0"/>
                                                                            <g id="Group_2239" data-name="Group 2239" transform="translate(50 28)">
                                                                                <path id="Path_965" data-name="Path 965" d="M2.657,17.495a2.447,2.447,0,0,1,.707-1.509L13.983,5.367l1.73,1.73L6.2,16.612l1.73,1.73,9.515-9.515,1.73,1.73L9.658,20.073l1.73,1.73L20.9,12.287l1.73,1.73L12.014,24.636a2.447,2.447,0,0,1-1.509.707l-7.171.652a1.223,1.223,0,0,1-1.329-1.329Zm6.478,5.516-3.656.332A1.4,1.4,0,0,0,5.1,22.9c-.31-.272-.454-.272-.454-.272l.342-3.762Z" transform="translate(428 754)" fill="#2a3e4c" fillRule="evenodd"/>
                                                                                <path id="Path_966" data-name="Path 966" d="M13.054,4.447l1.731-1.731a2.447,2.447,0,0,1,3.461,0l5.192,5.192a2.447,2.447,0,0,1,0,3.461L21.707,13.1Z" transform="translate(429.852 754)" fill="#2a3e4c"/>
                                                                            </g>
                                                                        </g>
                                                                    </svg>

                                                                </div>

                                                                <div className="Button__send"
                                                                     onClick={this.alertOpen.bind(this, 'alertTrainingGroupSendSuggestions', alertData(trainingGroup.id, trainingGroup))}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">
                                                                        <g id="Group_2236" data-name="Group 2236" transform="translate(-480 -760)">
                                                                            <rect id="Rectangle_2757" data-name="Rectangle 2757" width="42" height="42" transform="translate(480 760)" fill="#f7f7f7" opacity="0"/>
                                                                            <g id="Group_2234" data-name="Group 2234" transform="translate(-98 -4)">
                                                                                <path id="bell-solid" d="M10.5,24a3,3,0,0,0,3-3h-6A3,3,0,0,0,10.5,24Zm10.1-7.018C19.69,16.009,18,14.545,18,9.75a7.4,7.4,0,0,0-6-7.273V1.5a1.5,1.5,0,1,0-3,0v.977A7.4,7.4,0,0,0,3,9.75c0,4.8-1.695,6.259-2.6,7.232A1.465,1.465,0,0,0,0,18a1.5,1.5,0,0,0,1.5,1.5H19.495A1.5,1.5,0,0,0,21,18,1.464,1.464,0,0,0,20.6,16.982Z" transform="translate(600.408 771.358) rotate(30)" fill="#2a3e4c"/>
                                                                                <path id="at-solid" d="M14,8a6,6,0,1,0,3.276,11.027.581.581,0,0,0,.134-.856l-.246-.3a.579.579,0,0,0-.759-.124A4.452,4.452,0,1,1,14,9.548c2.423,0,4.452,1.394,4.452,3.871a1.889,1.889,0,0,1-1.407,2.025c-.42-.011-.409-.311-.326-.726l.567-2.93a.581.581,0,0,0-.57-.691H15.627a.327.327,0,0,0-.325.29v0a1.86,1.86,0,0,0-1.451-.527,3.451,3.451,0,0,0-3.335,3.664,2.273,2.273,0,0,0,2.323,2.561,2.448,2.448,0,0,0,1.814-.927c.23.825.983.825,1.711.825A3.319,3.319,0,0,0,20,13.419C20,10.121,17.339,8,14,8Zm-.525,7.365a.867.867,0,0,1-.873-.986,1.616,1.616,0,0,1,1.418-1.76c.539,0,.861.369.861.986a1.67,1.67,0,0,1-1.407,1.76Z" transform="translate(575 763)" fill="#2a3e4c"/>
                                                                            </g>
                                                                        </g>
                                                                    </svg>

                                                                </div>

                                                                <div className="Button__edit"
                                                                     onClick={this.modalOpen.bind(this, 'modalTrainingGroupDisciplinePrint', modalData(trainingGroup.id, trainingGroup))}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">
                                                                        <g id="Сгруппировать_1679" data-name="Сгруппировать 1679" transform="translate(-1765 -274)">
                                                                            <circle id="Эллипс_62" data-name="Эллипс 62" cx="21" cy="21" r="21" transform="translate(1765 274)" fill="#2a3e4c" opacity="0"/>
                                                                            <path id="print-solid" d="M21,9V3.621a1.5,1.5,0,0,0-.439-1.061L18.439.439A1.5,1.5,0,0,0,17.378,0H4.5A1.5,1.5,0,0,0,3,1.5V9a3,3,0,0,0-3,3v5.25A.75.75,0,0,0,.75,18H3v4.5A1.5,1.5,0,0,0,4.5,24h15A1.5,1.5,0,0,0,21,22.5V18h2.25a.75.75,0,0,0,.75-.75V12A3,3,0,0,0,21,9ZM18,21H6V16.5H18Zm0-10.5H6V3h9V5.25a.75.75,0,0,0,.75.75H18Zm2.25,3.375a1.125,1.125,0,1,1,1.125-1.125A1.125,1.125,0,0,1,20.25,13.875Z" transform="translate(1774 283)" fill="#2a3e4c"/>
                                                                        </g>
                                                                    </svg>

                                                                </div>
                                                            </li>
                                                        ) : null}
                                                    </React.Fragment>
                                                ))}
                                            </ul>
                                        </React.Fragment>
                                    ))}
                                </div>
                            </div>

                        </div>

                        <div className="Section__button"
                             onClick={this.modalOpen.bind(this, 'modalTrainingGroupAdd', modalData(null, null))}>
                            <div className="button"/>
                        </div>
                    </div>
                );
            }
        }

        else return null;
    }
}

function mapStateToProps(state) {
    return {
        isLoad: state.page.isLoad,
        isUpdate: state.page.isUpdate,
        responseData: state.api.responseData
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageMount: () => dispatch(pageMount()),
        pageLoad: (isLoad) => dispatch(pageLoad(isLoad)),
        pageUpdate: () => dispatch(pageUpdate()),
        apiUnmount: () => dispatch(apiUnmount()),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        alertOpen: (alertForm, alertData) => dispatch(alertOpen(alertForm, alertData)),
        modalOpen: (modalForm, modalData) => dispatch(modalOpen(modalForm, modalData))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (Students);
