import React from "react";
import {connect} from "react-redux";
import {profileOpen, profileUpdate} from "../../../store/actions/profileAction";
import baseURL from "../../../library/baseURL";
import Loading from "../../loading/Loading";
import {notificationMessage} from "../../../store/actions/notificationAction";
import {alertOpen} from "../../../store/actions/alertAction";
import {apiRequest} from "../../../store/actions/apiAction";

class ProfileData extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            profileData: this.props.profileData,
            isUpload: false
        };

        this.avatarInput = React.createRef();
    }

    componentDidUpdate() {
        if (this.props.profileData !== this.state.profileData) {
            // console.log('profileData');

            this.setState({
                profileData: this.props.profileData
            });
        }
    }

    profileOpen(profileOpenForm) {
        this.props.profileOpen(profileOpenForm);
    }

    handleAvatarChange() {
        let files = this.avatarInput.current.files;
        this.uploadFiles(files);
    }

    uploadFiles(files) {
        let formData = new FormData();

        let replay = 0;
        let size = 0;
        let type = 0;

        if (files.length > 0) {

            for (let i = 0, file; i < files.length; i++) {
                file = files.item(i);
                formData.append('avatar', file);
                replay++;

                if (file.size > 8388608) {
                    size++;
                }

                if (file.type === 'image/png' // PNG
                    || file.type === 'image/jpeg' // JPG
                ) {
                    type++;
                }
            }

            if (type === files.length) {

                if (replay < 2) {

                    if (size > 0) {
                        this.props.notificationMessage('Максимальный размер фотографии не более 8 Мб.', 'red');
                    }

                    else {

                        this.setState({
                            isUpload: true
                        });

                        this.props.apiRequest('api/cabinet/profile/avatar/upload', formData, () => {
                                this.setState({
                                    isUpload: false
                                }, () => {
                                    this.props.notificationMessage(this.props.responseMessage, 'green');
                                });
                                this.props.profileUpdate();
                            }, () => {
                                this.setState({
                                    isUpload: false
                                });
                                this.props.notificationMessage(this.props.responseError, 'red');
                            }
                        );
                    }
                }

                else {
                    this.props.notificationMessage('За раз можно загрузить не более 1 фотографии.', 'red');
                }
            }

            else {
                this.props.notificationMessage('Возможна загрузка фотографии формата PNG и JPG(JPEG).', 'red');
            }
        }
    }

    alertOpen(alertForm, alertData) {
        this.props.alertOpen(alertForm, alertData);
    }

    render() {
        const { profileData, isUpload } = this.state;

        let alertData = (objectId, objectData) => {
            return {
                objectId: objectId,
                objectData: objectData
            };
        }

        let telephone = (telephone) => {

            telephone.split('');

            return '+7 (' +
                telephone[1] +
                telephone[2] +
                telephone[3] +
                ') ' +
                telephone[4] +
                telephone[5] +
                telephone[6] +
                '-' +
                telephone[7] +
                telephone[8] +
                telephone[9] +
                telephone[10];
        }

        let groupName = (groupName, initialYear) => {
            let course;

            let date = new Date();
            course = (date.getFullYear() - Number(initialYear) + 1);

            return groupName.replace(/\$/i, course);
        }

        let periodOfStudy = (periodOfStudy) => {

            return periodOfStudy.split('-')[0] + ' - ' + periodOfStudy.split('-')[1];
        }

        return (
            <div className="Form__section">
                <div className="Section__header">
                    <div className="Header__photo">
                        {profileData.profile_avatar ? (
                            <label>
                                <div className="Box__avatar Avatar__image"
                                     style={{
                                         height: 100,
                                         width: 100,
                                         borderRadius: '50%',
                                         backgroundColor: '#e6eaec',
                                         marginRight: 32,
                                         display: 'inline-block'
                                     }}>
                                    <img className={'Avatar__image'}
                                         src={baseURL() + 'api/images/avatar/' + profileData.profile_avatar}
                                         width="100"
                                         height="100"/>
                                    {profileData.profile_type !== 'student' ? (
                                        <div className="Box__clear icon-clear"
                                             onClick={this.alertOpen.bind(this, 'alertProfileAvatarDelete', alertData(null, null))}/>
                                    ) : null}
                                </div>
                            </label>
                        ) : (
                            <label>
                                <div className="Box__avatar Avatar__image" style={{cursor: "pointer"}}>
                                    <svg id="account_circle-black-18dp" xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">
                                        <path id="Path_1" data-name="Path 1" d="M0,0H100V100H0Z" fill="none"/>
                                        <path id="Path_2" data-name="Path 2" d="M50,2A48,48,0,1,0,98,50,48.018,48.018,0,0,0,50,2Zm0,14.4A14.4,14.4,0,1,1,35.6,30.8,14.381,14.381,0,0,1,50,16.4Zm0,68.16A34.563,34.563,0,0,1,21.2,69.1C21.344,59.552,40.4,54.32,50,54.32c9.552,0,28.656,5.232,28.8,14.784A34.563,34.563,0,0,1,50,84.56Z" fill="#e9ebed"/>
                                    </svg>

                                    {profileData.profile_type !== 'student' ? (
                                        <React.Fragment>
                                            <div className="Box__add icon-add"/>
                                        <div style={{
                                            position: "absolute",
                                            bottom: 4,
                                            right: -18
                                        }}>
                                            {isUpload ? <Loading /> : null}
                                        </div>
                                        <input type="file"
                                        accept=".jpeg, .jpg, .png"
                                        ref={this.avatarInput}
                                        onChange={this.handleAvatarChange.bind(this)}
                                        hidden />
                                        </React.Fragment>
                                    ) : null}
                                </div>
                            </label>
                        )}
                    </div>
                    <div className="Header__title">
                        <div className="Title__text">{profileData.profile_fio.split(' ')[0]}<br/>{profileData.profile_fio.split(' ')[1] + ' ' + profileData.profile_fio.split(' ')[2]}</div>
                    </div>
                </div>
                <div className="Profile__content">
                    <div className="Profile__body">

                        <div className="Box">
                            {profileData.profile_type === 'student' ? (<div className="Box__text">Студент / Группа {groupName(profileData.training_group.group_name, profileData.training_group.initial_year)}</div>) : null}
                            {profileData.profile_type === 'teacher' ? (<div className="Box__text">Преподаватель{profileData.profile_position ? ' / ' + profileData.profile_position : null}</div>) : null}
                            {profileData.profile_type === 'administrator' ? (<div className="Box__text">Администратор{profileData.profile_position ? ' / ' + profileData.profile_position : null}</div>) : null}
                        </div>

                        <div className="Content__hr"/>

                        <div className="Box">
                            <div className="Box__title">Личные данные</div>
                        </div>

                        {profileData.profile_type === 'student' ? (
                            <div className="Box">
                                <div className="Box__label">Дата рождения</div>
                                <div className="Box__text">{profileData.profile_birthday}</div>
                            </div>
                        ) : null}

                        <div className="Box">
                            <div className="Box__label">Электронная почта</div>
                            <div className="Box__text">{profileData.profile_email}</div>
                        </div>
                        <div className="Box">
                            <div className="Box__label">Телефон</div>
                            <div
                                className={!profileData.profile_telephone ? 'Box__text Box__text_disabled' : 'Box__text'}>
                                {!profileData.profile_telephone ? 'Нет данных' : telephone(profileData.profile_telephone)}
                            </div>
                        </div>

                        <div className={(profileData.profile_type === 'student') ? 'Box' : 'Box Box_end'}>
                            <div className="Box__link"
                                 onClick={this.profileOpen.bind(this, 'formProfileSettings')}>Редактировать информацию
                            </div>
                        </div>

                        {profileData.profile_type === 'student' ? (
                            <React.Fragment>
                                <div className="Content__hr"/>

                                <div className="Box">
                                    <div className="Box__title">Образование</div>
                                </div>
                                <div className="Box">
                                    <div className="Box__label">Период обучения</div>
                                    <div
                                        className={!profileData.period_of_study ? 'Box__text Box__text_disabled' : 'Box__text'}>
                                        {!profileData.period_of_study ? 'Нет данных' : periodOfStudy(profileData.period_of_study)}
                                    </div>
                                </div>
                                <div className="Box">
                                    <div className="Box__label">Учебное заведение</div>
                                    <div
                                        className={!profileData.educational_institution ? 'Box__text Box__text_disabled' : 'Box__text'}>
                                        {!profileData.educational_institution ? 'Нет данных' : profileData.educational_institution}
                                    </div>
                                </div>

                                {profileData.received_specialty ? (
                                    <div className="Box">
                                        <div className="Box__label">Полученная специальность (если есть)</div>
                                        <div className="Box__text">{profileData.received_specialty}</div>
                                    </div>
                                ) : null}
                                <div className="Box">
                                    <div className="Box__link"
                                         onClick={this.profileOpen.bind(this, 'formProfileSettings')}>Редактировать информацию
                                    </div>
                                </div>
                            </React.Fragment>
                        ) : null}

                        {profileData.profile_type === 'student' ? (
                            <React.Fragment>
                                <div className="Content__hr"/>

                                <div className="Box">
                                    <div className="Box__title">Данные авторизации</div>
                                </div>
                                <div className="Box">
                                    <div className="Box__label">Логин</div>
                                    <div className="Box__text">{profileData.profile_login}</div>
                                </div>
                                <div className="Box Box_end">
                                    <div className="Box__link"
                                         onClick={this.profileOpen.bind(this, 'formProfilePassword')}>Изменить пароль</div>
                                </div>
                            </React.Fragment>
                        ) : null}

                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        profileData: state.profile.profileData,
        responseError: state.api.responseError,
        responseMessage: state.api.responseMessage
    }
}

function mapDispatchToProps(dispatch) {
    return {
        profileOpen: (profileOpenForm) => dispatch(profileOpen(profileOpenForm)),
        profileUpdate: () => dispatch(profileUpdate()),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        notificationMessage: (message, colorMessage) => dispatch(notificationMessage(message, colorMessage)),
        alertOpen: (alertForm, alertData) => dispatch(alertOpen(alertForm, alertData))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileData);
