import {MODAL_CLOSE, MODAL_OPEN} from "../actions/actionTypes";

const initialState = {
    isModal: false,
    modalForm: null,
    modalData: null
}

export default function modalReducer(state = initialState, action) {
    switch (action.type) {
        case MODAL_OPEN:
            return {
                ...state, isModal: true, modalForm: action.modalForm, modalData: action.modalData
            }
        case MODAL_CLOSE:
            return {
                ...state, isModal: false, modalForm: null, modalData: null
            }
        default:
            return state;
    }
}
