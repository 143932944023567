import {ALERT_CLOSE, ALERT_OPEN} from "../actions/actionTypes";

const initialState = {
    isAlert: false,
    alertForm: null,
    alertData: null
}

export default function alertReducer(state = initialState, action) {
    switch (action.type) {
        case ALERT_OPEN:
            return {
                ...state, isAlert: true, alertForm: action.alertForm, alertData: action.alertData
            }
        case ALERT_CLOSE:
            return {
                ...state, isAlert: false, alertForm: null, alertData: null
            }
        default:
            return state;
    }
}
