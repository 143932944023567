import React from "react";
import {connect} from "react-redux";
import {pageLoad, pageUpdate, pageMount} from "../../../../store/actions/pageAction";
import {apiRequest} from "../../../../store/actions/apiAction";
import {modalOpen} from "../../../../store/actions/modalAction";
import "./GradeBook.scss";
import Loading from "../../../loading/Loading";
import {Link} from "react-router-dom";
import {alertOpen} from "../../../../store/actions/alertAction";

class GradeBook extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isMount: true,
            isOpen: false,
            isLoad: false,
            isUpdate: false,
            pageError: null,
            trainingPlanData: null
        };
    }

    componentDidMount() {
        this.props.pageMount();
        this.pageUpdate(this.state.isLoad);
        // this.intervalUpdate = setInterval(this.props.pageUpdate, 180000);
    }

    componentWillUnmount() {
        // console.log('Employees Unmounted');
        this.setState({
            isMount: false
        });
    }

    componentDidUpdate() {
        if (this.state.isMount) {
            const responseData = this.props.responseData;
            // console.log(1 + ' componentDidUpdate');
            if (this.props.isLoad !== this.state.isLoad) {
                // console.log(2 + ' isLoad');
                if (responseData !== this.state.trainingPlanData) {
                    // console.log(3 + ' responseData');

                    this.setState({
                        isOpen: true,
                        isLoad: this.props.isLoad,
                        isUpdate: this.props.isUpdate,
                        trainingPlanData: responseData
                    });
                }
            }

            else if (this.props.isUpdate !== this.state.isUpdate) {
                // console.log(4 + ' isUpdate');
                this.pageUpdate(this.state.isLoad);

                this.setState({
                    isUpdate: this.props.isUpdate
                });
            }
        }
    }

    pageUpdate(isLoad) {
        this.props.apiRequest('api/cabinet/profile/gradebook', null, () => {
            this.props.pageLoad(isLoad);
        });
    }

    modalOpen(modalForm, modalData) {
        this.props.modalOpen(modalForm, modalData);
    }

    alertOpen(alertForm, alertData) {
        this.props.alertOpen(alertForm, alertData);
    }


    render() {
        const { isMount, isOpen, pageError, trainingPlanData } = this.state;

        let modalData = (objectId, objectData) => {
            return {
                objectId: objectId,
                objectData: objectData
            };
        }

        let alertData = (objectId, objectData) => {
            return {
                objectId: objectId,
                objectData: objectData
            };
        }

        let groupName = (groupName, initialYear) => {
            let course;

            let date = new Date();
            course = (date.getFullYear() - Number(initialYear) + 1);

            if (groupName) {
                return groupName.replace(/\$/i, course);
            }
        }

        if (isMount) {

            if (pageError) {

                if (!isOpen) {

                    return (
                        <div className="Page__loader">
                            <i/>
                            <div className="Body_nodata">
                                Страница не найдена
                                <div style={{textAlign: 'center', marginTop: 48, fontSize: 32}}>
                                    ERROR 404
                                </div>
                            </div>
                            <i/>
                        </div>
                    );
                }

                else {

                    return (
                        <div className="Page__loader">
                            <i/>
                            <div>
                                <div className="Body_nodata">План удалён</div>
                                <div className="Header__path" style={{textAlign: 'center', marginTop: 48}}>
                                    <Link to={"/cabinet/training/plans"}>
                                        <div className="Path__link Path__link_text Path__link_blue">Учебные планы</div>
                                    </Link>
                                </div>
                            </div>
                            <i/>
                        </div>
                    );
                }
            }

            else {

                if (!isOpen || !trainingPlanData) {
                    return (<div className="Page__loader"><i/><Loading/><i/></div>);
                }

                else {

                    return (
                        <div className="Page__section">

                            <div className="Section__header Section__header_margin-bottom-16">
                                <div className="Header__title">
                                    <div className="Title__text" style={{marginBottom: 16}}>Зачетная книжка</div>
                                </div>
                            </div>

                            <div className="Section__content">

                                <div className="GradeBook">
                                    <div className="GradeBook__body">

                                        <div className="Block__Item" style={{marginBottom: 24}}>
                                            <div className="Item__wrapper">
                                                <div className="Item__block Item__block_inline Item__block_margin-right Item__block_padding">
                                                    <div className="Item__Text" style={{fontSize: 32, fontWeight: 900, display: "inline-block"}}>{groupName(trainingPlanData.group_name, trainingPlanData.initial_year)}</div>
                                                    <div className="Item__hr Item__hr_vertical Item__hr_inline"/>
                                                    <div className="Item__Text" style={{fontSize: 32, fontWeight: 900, display: "inline-block"}}>{trainingPlanData.training_program.direction_preparation}</div>
                                                    {/*<div className="Item__label Item__label_inline">Направление</div>*/}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="Block__Item">
                                            <div className="Item__wrapper">
                                                <div className="Item__block Item__block_inline Item__block_margin-right Item__block_padding">
                                                    <div className="Item__Text" style={{fontSize: 24, fontWeight: 900}}>{trainingPlanData.training_program.training_qualifications}</div>
                                                    <div className="Item__hr Item__hr_vertical Item__hr_inline"/>
                                                    <div className="Item__Text" style={{fontSize: 24, fontWeight: 900}}>{trainingPlanData.training_program.form_training}</div>
                                                    <div className="Item__hr Item__hr_vertical Item__hr_inline"/>
                                                    <div className="Item__Text" style={{fontSize: 24, fontWeight: 900}}>{trainingPlanData.training_program.period_training} года</div>
                                                </div>

                                            </div>
                                        </div>

                                        {/*<div className="Block__Item">*/}
                                        {/*    <div className="Item__wrapper">*/}
                                        {/*        <div className="Item__block Item__block_inline Item__block_margin-right Item__block_padding">*/}
                                        {/*            <div className="Item__Text" style={{fontSize: 24, fontWeight: 900}}>{trainingPlanData.training_program.training_qualifications}</div>*/}
                                        {/*            <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                        {/*            <div className="Item__label Item__label_inline">Квалификация</div>*/}
                                        {/*        </div>*/}
                                        {/*        <div className="Item__block Item__block_inline Item__block_margin-right Item__block_padding">*/}
                                        {/*            <div className="Item__Text" style={{fontSize: 24, fontWeight: 900}}>{trainingPlanData.training_program.form_training}</div>*/}
                                        {/*            <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                        {/*            <div className="Item__label Item__label_inline">Форма обучения</div>*/}
                                        {/*        </div>*/}

                                        {/*        <div className="Item__block Item__block_inline Item__block_margin-right Item__block_padding">*/}
                                        {/*            <div className="Item__Text" style={{fontSize: 24, fontWeight: 900}}>{trainingPlanData.training_program.period_training} года</div>*/}
                                        {/*            <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                        {/*            <div className="Item__label Item__label_inline">Срок обучения</div>*/}
                                        {/*        </div>*/}

                                        {/*    </div>*/}
                                        {/*</div>*/}

                                        <div className="Body__hr"/>

                                        {trainingPlanData.training_program.disciplines_plan ? (
                                            trainingPlanData.training_program.disciplines_plan.map((disciplines_plan, index) => (
                                                <React.Fragment key={index}>
                                                    <div className="Body__label">{disciplines_plan.year_program} курс, {disciplines_plan.period_program} семестр</div>
                                                    <ul className="Body__list">
                                                        {disciplines_plan.disciplines.map(discipline => (
                                                            <li className="List__item" key={discipline.id} onClick={this.modalOpen.bind(this, 'modalStudentGradeBook', modalData(disciplines_plan.disciplines, discipline))}>
                                                                {/*<li className="List__item" key={discipline.id} onClick={this.modalOpen.bind(this, 'modalTrainingPlanDisciplineEdit', modalData(trainingPlanData, discipline))}>*/}
                                                                {/*{discipline.discipline_index ? (<div className="Item__index">{discipline.discipline_index}</div>) : null}*/}







                                                                <div style={{
                                                                    display: "inline-block",
                                                                    position: "absolute",
                                                                    top: -12
                                                                }}>
                                                                    {discipline.discipline_index ? (
                                                                        <div
                                                                             style={{
                                                                                 fontFamily: "Roboto, sans-serif",
                                                                                 fontSize: 15,
                                                                                 fontWeight: 700,
                                                                                 backgroundColor: "#2A3E4C",
                                                                                 color: "white",
                                                                                 padding: "4px 6px 3px",
                                                                                 borderRadius: 4,
                                                                                 display: "inline-block",
                                                                                 textTransform: "uppercase",
                                                                                 marginRight: 16
                                                                             }}>
                                                                            {discipline.discipline_index}
                                                                        </div>
                                                                    ) : null}

                                                                    {discipline.academic_performance.length > 0 ? (





                                                                        discipline.academic_performance.map((academic_performance, index) => (
                                                                            <div key={index} style={{
                                                                                fontFamily: "Roboto, sans-serif",
                                                                                fontSize: 15,
                                                                                fontWeight: 700,
                                                                                color: "white",
                                                                                padding: "4px 6px 3px",
                                                                                borderRadius: 4,
                                                                                display: "inline-block",
                                                                                textTransform: "uppercase",
                                                                                backgroundColor: (Number(academic_performance.knowledge_assessment) === 0 ? "#0066ff" :
                                                                                    (Number(academic_performance.knowledge_assessment) === 1 ? "#e53935" :
                                                                                        (Number(academic_performance.knowledge_assessment) === 2 ? "#e53935" :
                                                                                            (Number(academic_performance.knowledge_assessment) > 2 ? "#43a047" : "#43a047")))),
                                                                                marginRight: 8
                                                                            }}>

                                                                                {academic_performance.control_type === 'exam' ? 'Экзамен' : null}
                                                                                {academic_performance.control_type === 'diff' ? 'Диф. зачет' : null}
                                                                                {academic_performance.control_type === 'test' ? 'Зачет' : null}
                                                                                {academic_performance.control_type === 'project' ? 'Курс. проект' : null}
                                                                                {academic_performance.control_type === 'coursework' ? 'Курс. работа' : null}

                                                                            </div>
                                                                        ))

                                                                    ) : null}
                                                                </div>


                                                                {Number(discipline.anchor_of_choice) > 0 ? (
                                                                    discipline.discipline_choice ? (
                                                                        <div
                                                                             style={{
                                                                                 fontFamily: "Roboto, sans-serif",
                                                                                 fontSize: 15,
                                                                                 fontWeight: 700,
                                                                                 backgroundColor: "#0061ff",
                                                                                 color: "white",
                                                                                 padding: "4px 6px 3px",
                                                                                 borderRadius: 4,
                                                                                 display: "inline-block",
                                                                                 position: "absolute",
                                                                                 top: -8,
                                                                                 textTransform: "uppercase",
                                                                                 right: 16 + 13 + 16
                                                                             }}>
                                                                            Выбран
                                                                        </div>
                                                                    ) : null
                                                                ) : null}


                                                                {Number(discipline.anchor_of_choice) > 0 ? (
                                                                    <div
                                                                         style={{
                                                                             display: "inline-block",
                                                                             position: "absolute",
                                                                             top: -9,
                                                                             right: 16
                                                                         }}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="12.599" height="26.857" viewBox="0 0 12.599 26.857">
                                                                            <g id="Group_1635" data-name="Group 1635" transform="translate(-89.692 -1130.28) rotate(30)">
                                                                                <g id="Group_1633" data-name="Group 1633" transform="translate(809.485 527.485) rotate(90)">
                                                                                    <rect id="Rectangle_1389" data-name="Rectangle 1389" width="13" height="4" rx="2" transform="translate(404 156)" fill="#0061ff"/>
                                                                                    <g id="Group_1341" data-name="Group 1341" transform="translate(-9.314 308.614) rotate(-45)">
                                                                                        <rect id="Rectangle_1390" data-name="Rectangle 1390" width="10" height="4" rx="2" transform="translate(397 184)" fill="#0061ff"/>
                                                                                        <rect id="Rectangle_1391" data-name="Rectangle 1391" width="4" height="10" rx="2" transform="translate(397 184)" fill="#0061ff"/>
                                                                                    </g>
                                                                                </g>
                                                                                <g id="Group_1634" data-name="Group 1634" transform="translate(500.485 1357.485) rotate(-90)">
                                                                                    <rect id="Rectangle_1389-2" data-name="Rectangle 1389" width="13" height="4" rx="2" transform="translate(404 156)" fill="#0061ff"/>
                                                                                    <g id="Group_1341-2" data-name="Group 1341" transform="translate(-9.314 308.614) rotate(-45)">
                                                                                        <rect id="Rectangle_1390-2" data-name="Rectangle 1390" width="10" height="4" rx="2" transform="translate(397 184)" fill="#0061ff"/>
                                                                                        <rect id="Rectangle_1391-2" data-name="Rectangle 1391" width="4" height="10" rx="2" transform="translate(397 184)" fill="#0061ff"/>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </svg>
                                                                    </div>
                                                                ) : null}


                                                                <div className="Item__block Item__block_inline" style={{
                                                                    // paddingTop: 7,
                                                                    // textOverflow: "ellipsis",
                                                                    // overflow: "hidden",
                                                                    // maxWidth: 1240,
                                                                    // whiteSpace: "nowrap",
                                                                    // wordWrap: "break-word"
                                                                }}>
                                                                    {discipline.discipline_name}
                                                                </div>

                                                                {/*<div className="Item__wrapper">*/}
                                                                {/*    <div className="Item__left">*/}
                                                                {/*        <div className="Item__block Item__block_inline" style={{*/}
                                                                {/*            paddingTop: 7,*/}
                                                                {/*            textOverflow: "ellipsis",*/}
                                                                {/*            overflow: "hidden",*/}
                                                                {/*            maxWidth: 1240,*/}
                                                                {/*            whiteSpace: "nowrap",*/}
                                                                {/*            wordWrap: "break-word"*/}
                                                                {/*        }}>*/}
                                                                {/*            {discipline.discipline_name}*/}
                                                                {/*        </div>*/}
                                                                        {/*{Number(discipline.anchor_of_choice) > 0 ? (*/}
                                                                        {/*    <div style={{*/}
                                                                        {/*        paddingTop: 5*/}
                                                                        {/*    }}*/}
                                                                        {/*         className="Item__block Item__block_inline Item__block_margin-left">*/}
                                                                        {/*        <svg xmlns="http://www.w3.org/2000/svg" width="12.599" height="26.857" viewBox="0 0 12.599 26.857">*/}
                                                                        {/*            <g id="Group_1635" data-name="Group 1635" transform="translate(-89.692 -1130.28) rotate(30)">*/}
                                                                        {/*                <g id="Group_1633" data-name="Group 1633" transform="translate(809.485 527.485) rotate(90)">*/}
                                                                        {/*                    <rect id="Rectangle_1389" data-name="Rectangle 1389" width="13" height="4" rx="2" transform="translate(404 156)" fill="#0061ff"/>*/}
                                                                        {/*                    <g id="Group_1341" data-name="Group 1341" transform="translate(-9.314 308.614) rotate(-45)">*/}
                                                                        {/*                        <rect id="Rectangle_1390" data-name="Rectangle 1390" width="10" height="4" rx="2" transform="translate(397 184)" fill="#0061ff"/>*/}
                                                                        {/*                        <rect id="Rectangle_1391" data-name="Rectangle 1391" width="4" height="10" rx="2" transform="translate(397 184)" fill="#0061ff"/>*/}
                                                                        {/*                    </g>*/}
                                                                        {/*                </g>*/}
                                                                        {/*                <g id="Group_1634" data-name="Group 1634" transform="translate(500.485 1357.485) rotate(-90)">*/}
                                                                        {/*                    <rect id="Rectangle_1389-2" data-name="Rectangle 1389" width="13" height="4" rx="2" transform="translate(404 156)" fill="#0061ff"/>*/}
                                                                        {/*                    <g id="Group_1341-2" data-name="Group 1341" transform="translate(-9.314 308.614) rotate(-45)">*/}
                                                                        {/*                        <rect id="Rectangle_1390-2" data-name="Rectangle 1390" width="10" height="4" rx="2" transform="translate(397 184)" fill="#0061ff"/>*/}
                                                                        {/*                        <rect id="Rectangle_1391-2" data-name="Rectangle 1391" width="4" height="10" rx="2" transform="translate(397 184)" fill="#0061ff"/>*/}
                                                                        {/*                    </g>*/}
                                                                        {/*                </g>*/}
                                                                        {/*            </g>*/}
                                                                        {/*        </svg>*/}
                                                                        {/*    </div>*/}
                                                                        {/*) : null}*/}
                                                                    {/*</div>*/}
                                                                    {/*<div className="Item__right">*/}
                                                                    {/*    <div className="Item__block Item__block_inline Item__block_margin-left ">*/}


                                                                            {/*{Number(discipline.control_exam) > 0 ? (*/}
                                                                            {/*    <React.Fragment>*/}
                                                                            {/*        <div className="Item__hr Item__hr_vertical Item__hr_inline Item__hr_margin-32"/>*/}
                                                                            {/*        <div className="Item__Text">Экзамен</div>*/}
                                                                            {/*    </React.Fragment>*/}
                                                                            {/*) : null}*/}
                                                                            {/*{Number(discipline.control_diff) > 0 ? (*/}
                                                                            {/*    <React.Fragment>*/}
                                                                            {/*        <div className="Item__hr Item__hr_vertical Item__hr_inline Item__hr_margin-32"/>*/}
                                                                            {/*        <div className="Item__Text">Диф. зачет</div>*/}
                                                                            {/*    </React.Fragment>*/}
                                                                            {/*) : null}*/}
                                                                            {/*{Number(discipline.control_test) > 0 ? (*/}
                                                                            {/*    <React.Fragment>*/}
                                                                            {/*        <div className="Item__hr Item__hr_vertical Item__hr_inline Item__hr_margin-32"/>*/}
                                                                            {/*        <div className="Item__Text">Зачет</div>*/}
                                                                            {/*    </React.Fragment>*/}
                                                                            {/*) : null}*/}
                                                                            {/*{Number(discipline.control_project) > 0 ? (*/}
                                                                            {/*    <React.Fragment>*/}
                                                                            {/*        <div className="Item__hr Item__hr_vertical Item__hr_inline Item__hr_margin-32"/>*/}
                                                                            {/*        <div className="Item__Text">Курсовой проект</div>*/}
                                                                            {/*    </React.Fragment>*/}
                                                                            {/*) : null}*/}
                                                                            {/*{Number(discipline.control_coursework) > 0 ? (*/}
                                                                            {/*    <React.Fragment>*/}
                                                                            {/*        <div className="Item__hr Item__hr_vertical Item__hr_inline Item__hr_margin-32"/>*/}
                                                                            {/*        <div className="Item__Text">Курсовая работа</div>*/}
                                                                            {/*    </React.Fragment>*/}
                                                                            {/*) : null}*/}
                                                                            {/*<div className="Item__hr Item__hr_vertical Item__hr_inline Item__hr_margin-32"/>*/}
                                                                            {/*<div className="Item__Text">{Number(discipline.lecture_hours) + Number(discipline.laboratory_hours) + Number(discipline.practical_hours) + Number(discipline.ciw_hours) + Number(discipline.iws_hours)}</div>*/}










                                                                            {/*{Number(discipline.control_project) > 0 ? (*/}
                                                                            {/*    <React.Fragment>*/}
                                                                            {/*        <div className="Item__Text" style={{*/}
                                                                            {/*            color: "#2A3E4C",*/}
                                                                            {/*            fontFamily: "Roboto, sans-serif",*/}
                                                                            {/*            fontWeight: 500,*/}
                                                                            {/*            opacity: .25*/}
                                                                            {/*        }}>Курсовой проект</div>*/}
                                                                            {/*        <div className="Item__hr Item__hr_vertical Item__hr_inline Item__hr_margin-32"/>*/}
                                                                            {/*        <div className="Item__Text">-</div>*/}
                                                                            {/*        <div className="Item__hr Item__hr_vertical Item__hr_inline Item__hr_margin-32"/>*/}
                                                                            {/*    </React.Fragment>*/}
                                                                            {/*) : null}*/}
                                                                            {/*{Number(discipline.control_coursework) > 0 ? (*/}
                                                                            {/*    <React.Fragment>*/}
                                                                            {/*        <div className="Item__Text" style={{*/}
                                                                            {/*            color: "#2A3E4C",*/}
                                                                            {/*            fontFamily: "Roboto, sans-serif",*/}
                                                                            {/*            fontWeight: 500,*/}
                                                                            {/*            opacity: .25*/}
                                                                            {/*        }}>Курсовая работа</div>*/}
                                                                            {/*        <div className="Item__hr Item__hr_vertical Item__hr_inline Item__hr_margin-32"/>*/}
                                                                            {/*        <div className="Item__Text">-</div>*/}
                                                                            {/*        <div className="Item__hr Item__hr_vertical Item__hr_inline Item__hr_margin-32"/>*/}
                                                                            {/*    </React.Fragment>*/}
                                                                            {/*) : null}*/}

                                                                            {/*{Number(discipline.control_exam) > 0 ? (*/}
                                                                            {/*    <React.Fragment>*/}
                                                                            {/*        /!*<div className="Item__hr Item__hr_vertical Item__hr_inline Item__hr_margin-32"/>*!/*/}
                                                                            {/*        <div className="Item__Text" style={{*/}
                                                                            {/*            color: "#2A3E4C",*/}
                                                                            {/*            fontFamily: "Roboto, sans-serif",*/}
                                                                            {/*            fontWeight: 500,*/}
                                                                            {/*            opacity: .25*/}
                                                                            {/*        }}>Экзамен</div>*/}
                                                                            {/*    </React.Fragment>*/}
                                                                            {/*) : null}*/}
                                                                            {/*{Number(discipline.control_diff) > 0 ? (*/}
                                                                            {/*    <React.Fragment>*/}
                                                                            {/*        /!*<div className="Item__hr Item__hr_vertical Item__hr_inline Item__hr_margin-32"/>*!/*/}
                                                                            {/*        <div className="Item__Text" style={{*/}
                                                                            {/*            color: "#2A3E4C",*/}
                                                                            {/*            fontFamily: "Roboto, sans-serif",*/}
                                                                            {/*            fontWeight: 500,*/}
                                                                            {/*            opacity: .25*/}
                                                                            {/*        }}>Диф. зачет</div>*/}
                                                                            {/*    </React.Fragment>*/}
                                                                            {/*) : null}*/}
                                                                            {/*{Number(discipline.control_test) > 0 ? (*/}
                                                                            {/*    <React.Fragment>*/}
                                                                            {/*        /!*<div className="Item__hr Item__hr_vertical Item__hr_inline Item__hr_margin-32"/>*!/*/}
                                                                            {/*        <div className="Item__Text" style={{*/}
                                                                            {/*            color: "#2A3E4C",*/}
                                                                            {/*            fontFamily: "Roboto, sans-serif",*/}
                                                                            {/*            fontWeight: 500,*/}
                                                                            {/*            opacity: .25*/}
                                                                            {/*        }}>Зачет</div>*/}
                                                                            {/*    </React.Fragment>*/}
                                                                            {/*) : null}*/}


                                                                            {/*<div className="Item__hr Item__hr_vertical Item__hr_inline Item__hr_margin-32"/>*/}
                                                                            {/*<div className="Item__Text">-</div>*/}
                                                                        {/*</div>*/}
                                                                    {/*</div>*/}
                                                                {/*</div>*/}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </React.Fragment>
                                            ))
                                        ) : null}
                                    </div>
                                </div>
                            </div>

                            {/*<div className="Section__button"*/}
                            {/*     onClick={this.modalOpen.bind(this, 'modalTrainingPlanDisciplineAdd', modalData(trainingPlanData.id, trainingPlanData))}>*/}
                            {/*    <div className="button"/>*/}
                            {/*</div>*/}
                        </div>
                    );
                }
            }
        }

        else return null;
    }
}

function mapStateToProps(state) {
    return {
        isLoad: state.page.isLoad,
        isUpdate: state.page.isUpdate,
        responseData: state.api.responseData,
        responseError:  state.api.responseError
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageMount: () => dispatch(pageMount()),
        pageLoad: (isLoad) => dispatch(pageLoad(isLoad)),
        pageUpdate: () => dispatch(pageUpdate()),
        alertOpen: (alertForm, alertData) => dispatch(alertOpen(alertForm, alertData)),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        modalOpen: (modalForm, modalData) => dispatch(modalOpen(modalForm, modalData))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (GradeBook);
