import React from "react";
import {connect} from "react-redux";
import {profileOpen, profileUpdate} from "../../../store/actions/profileAction";
import Loading from "../../loading/Loading";
import {formLoad} from "../../../store/actions/formAction";
import {pageUpdate} from "../../../store/actions/pageAction";
import {apiRequest} from "../../../store/actions/apiAction";
import {alertOpen} from "../../../store/actions/alertAction";
import {modalClose} from "../../../store/actions/modalAction";
import {notificationMessage} from "../../../store/actions/notificationAction";

class ProfileNotifications extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isMountForm: true,
            isOpenForm: false,
            isLoadForm: false,
            isUpdateForm: false,
            formData: null,
            isHandleSubmit: false,
            formResponseError: null,

            passwordOld: null,
            passwordNew: null,
            passwordRepeat: null,
            profileNotificationData: this.props.profileData
        };
    }

    componentDidMount() {
        // this.formUpdate(this.state.isLoadForm);
        // this.setState({
        //     profileNotificationData: this.props.profileData
        // });
    }

    componentWillUnmount() {
        // console.log('From Unmounted');
        this.setState({
            isMountForm: false
        });
    }

    componentDidUpdate() {
        if (this.state.isMountForm) {
            const responseData = this.props.profileData;
            // console.log(1 + ' componentDidUpdate');
            // if (this.props.isLoadForm !== this.state.isLoadForm) {
                // console.log(2 + ' isLoad');
                if (responseData !== this.state.profileNotificationData) {
                    // console.log(3 + ' responseData');

                    // this.formUpdate();

                    this.setState({
                        isOpenForm: true,
                        isLoadForm: this.props.isLoadForm,
                        isUpdateForm: this.props.isUpdateForm,
                        profileNotificationData: responseData
                    });
                }
            // }
        }
    }

    // formUpdate(isLoadForm) {
    //     this.props.apiRequest('/company/' + this.state.objectId + '/supports_date', null, () => {
    //         this.props.formLoad(isLoadForm);
    //     }, null);
    // }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    setStateAndInput(name, value) {

        let input = document.getElementById(name);
        input.value = value;

        this.setState({
            [name]: value
        });
    }

    // handleSubmit(event) {
    //     event.preventDefault();
    //
    //     if (!this.state.passwordOld && !this.state.passwordRepeat && !this.state.passwordRepeat) {
    //         this.setState({
    //             formResponseError: 'Пожалуйста, внимательно заполните все поля.'
    //         });
    //     }
    //
    //     else {
    //         if (this.state.passwordNew === this.state.passwordRepeat) {
    //             let formData = new FormData();
    //
    //             formData.append('profile_old_password', this.state.passwordOld);
    //             formData.append('profile_new_password', this.state.passwordNew);
    //
    //             this.setState({
    //                 isHandleSubmit: true
    //             });
    //
    //             this.props.apiRequest('/profile/password', formData, () => {
    //                     this.setState({
    //                         isHandleSubmit: false,
    //                         formResponseError: null
    //                     });
    //                     // this.formUpdate(this.state.isLoadForm);
    //                     this.props.profileUpdate();
    //                 }, () => {
    //                     this.setState({
    //                         isHandleSubmit: false,
    //                         formResponseError: this.props.responseError
    //                     })
    //                 }
    //             );
    //         }
    //
    //         else {
    //             this.setState({
    //                 formResponseError: 'Повторный пароль введен неверно.'
    //             });
    //         }
    //     }
    // }

    notification(notification) { console.log(1);
        let profileNotificationData = this.state.profileNotificationData;
        let notificationName = 'notification_' + notification;
        let isNotification = 0;

        if (profileNotificationData[notificationName]) {

            if (Number(profileNotificationData[notificationName]) === 0) {
                isNotification = 1;
            }

            profileNotificationData[notificationName] = isNotification;

                this.setState({
                    profileNotificationData: profileNotificationData
                });

                let formData = new FormData();

                formData.append('notification_name', notification);

                this.props.apiRequest('api/cabinet/profile/notification', formData, () => {
                        // this.props.notificationMessage(this.props.responseMessage, 'green');
                        this.props.profileUpdate();
                    }, () => {
                        this.props.notificationMessage(this.props.responseError, 'red');
                    }
                );

            }
        }

    modalClose() {
        this.props.modalClose();
    }

    alertOpen(alertForm, alertData) {
        this.props.alertOpen(alertForm, alertData);
    }

    render() {
        const { profileData } = this.props;
        const { isOpenForm, isHandleSubmit, formResponseError, countNumber, countDate, formData, supportType, profileNotificationData } = this.state;

        let alertData = (objectId, objectType, objectData) => {
            return {
                objectId: objectId,
                objectType: objectType,
                objectData: objectData
            };
        }

        return (
            <div className="Profile__form">
                <form>
                    <div className="Form__header">
                        <div className="Header__title">Уведомления</div>
                    </div>

                    <div className="Form__content">

                        <div className="Content__box">
                            <span className="Box__info">Активируйте настройки, что бы получать уведомления о следующих событиях, на электронную почту</span>
                        </div>

                        <div className="Content__hr Content__hr_bottom-32"/>



                        {profileData.profile_type === 'student' ? (


                            <React.Fragment>
                                <div className="Box">
                                    <div className="Box__label" style={{color: "#2A3E4C"}}>Изменения информации в аккаунте:</div><br/>
                                    <div className="Box__switch"
                                         onClick={this.notification.bind(this, 'edit')}>
                                        <label className={Number(profileNotificationData.notification_edit) > 0 ? "switch" : "switch switch_active"}>
                                            <span className="switch__lever"/>
                                            <span className="switch__index"/>
                                        </label>
                                    </div>
                                </div>

                                <div className="Box">
                                    <div className="Box__label" style={{color: "#2A3E4C"}}>Напоминание о начале события за 1 день:</div><br/>
                                    <div className="Box__switch"
                                         onClick={this.notification.bind(this, 'day')}>
                                        <label className={Number(profileNotificationData.notification_day) > 0 ? "switch" : "switch switch_active"}>
                                            <span className="switch__lever"/>
                                            <span className="switch__index"/>
                                        </label>
                                    </div>
                                </div>

                                <div className="Box">
                                    <div className="Box__label" style={{color: "#2A3E4C"}}>Напоминание о начале события за 1 час:</div><br/>
                                    <div className="Box__switch"
                                         onClick={this.notification.bind(this, 'hour')}>
                                        <label className={Number(profileNotificationData.notification_hour) > 0 ? "switch" : "switch switch_active"}>
                                            <span className="switch__lever"/>
                                            <span className="switch__index"/>
                                        </label>
                                    </div>
                                </div>

                                <div className="Box">
                                    <div className="Box__label" style={{color: "#2A3E4C"}}>Изменения в зачетной книжке:</div><br/>
                                    <div className="Box__switch"
                                         onClick={this.notification.bind(this, 'gradebook')}>
                                        <label className={Number(profileNotificationData.notification_gradebook) > 0 ? "switch" : "switch switch_active"}>
                                            <span className="switch__lever"/>
                                            <span className="switch__index"/>
                                        </label>
                                    </div>
                                </div>
                            </React.Fragment>
                        ) : null}

                        {profileData.profile_type === 'teacher' ? (
                            <React.Fragment>

                                <div className="Box">
                                    <div className="Box__label" style={{color: "#2A3E4C"}}>Напоминание о начале события за 1 день:</div><br/>
                                    <div className="Box__switch"
                                         onClick={this.notification.bind(this, 'day')}>
                                        <label className={Number(profileNotificationData.notification_day) > 0 ? "switch" : "switch switch_active"}>
                                            <span className="switch__lever"/>
                                            <span className="switch__index"/>
                                        </label>
                                    </div>
                                </div>

                                <div className="Box">
                                    <div className="Box__label" style={{color: "#2A3E4C"}}>Напоминание о начале события за 1 час:</div><br/>
                                    <div className="Box__switch"
                                         onClick={this.notification.bind(this, 'hour')}>
                                        <label className={Number(profileNotificationData.notification_hour) > 0 ? "switch" : "switch switch_active"}>
                                            <span className="switch__lever"/>
                                            <span className="switch__index"/>
                                        </label>
                                    </div>
                                </div>
                            </React.Fragment>
                        ) : null}

                            <div className="Box">
                                <div className="Box__label" style={{color: "#2A3E4C"}}>Новые сообщения:</div><br/>
                                <div className="Box__switch"
                                     style={{
                                         // marginBottom: ((profileData.profile_type === 'student') || (profileData.profile_type === 'teacher') ? 24 : 0)
                                         marginBottom: 0
                                     }}
                                onClick={this.notification.bind(this, 'messages')}>
                                    <label className={Number(profileNotificationData.notification_messages) > 0 ? "switch" : "switch switch_active"}>
                                        <span className="switch__lever"/>
                                        <span className="switch__index"/>
                                    </label>
                                </div>
                            </div>

                    </div>

                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        isLoadForm: state.form.isLoadForm,
        isUpdateForm: state.form.isUpdateForm,
        interimData: state.modal.interimData,
        isRequest: state.api.isRequest,
        responseMessage: state.api.responseMessage,
        responseData: state.api.responseData,
        responseError: state.api.responseError,
        profileData: state.profile.profileData
    }
}

function mapDispatchToProps(dispatch) {
    return {
        formLoad: (isLoadForm) => dispatch(formLoad(isLoadForm)),
        pageUpdate: () => dispatch(pageUpdate()),
        profileUpdate: () => dispatch(profileUpdate()),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        alertOpen: (alertForm, alertData) => dispatch(alertOpen(alertForm, alertData)),
        notificationMessage: (message, colorMessage) => dispatch(notificationMessage(message, colorMessage)),
        modalClose: () => dispatch(modalClose())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileNotifications);
