import React from "react";
import {connect} from "react-redux";
import {apiRequest} from "../../../store/actions/apiAction";
import {formLoad} from "../../../store/actions/formAction";
import {pageUpdate} from "../../../store/actions/pageAction";
import {modalClose} from "../../../store/actions/modalAction";
import {alertOpen} from "../../../store/actions/alertAction";
import Loading from "../../loading/Loading";
import {profileClose, profileUpdate} from "../../../store/actions/profileAction";

class ProfilePassword extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isMountForm: true,
            isOpenForm: false,
            isLoadForm: false,
            isUpdateForm: false,
            formData: null,
            isHandleSubmit: false,
            formResponseError: null,

            passwordOld: null,
            passwordNew: null,
            passwordRepeat: null
        };
    }

    componentDidMount() {
        // this.formUpdate(this.state.isLoadForm);
    }

    componentWillUnmount() {
        // console.log('From Unmounted');
        this.setState({
            isMountForm: false
        });
    }

    // componentDidUpdate() {
    //     if (this.state.isMountForm) {
    //         const responseData = this.props.responseData;
    //         // console.log(1 + ' componentDidUpdate');
    //         if (this.props.isLoadForm !== this.state.isLoadForm) {
    //             // console.log(2 + ' isLoad');
    //             if (responseData !== this.state.formData) {
    //                 // console.log(3 + ' responseData');
    //
    //                 // this.formUpdate();
    //
    //                 this.setState({
    //                     isOpenForm: true,
    //                     isLoadForm: this.props.isLoadForm,
    //                     isUpdateForm: this.props.isUpdateForm,
    //                     formData: responseData
    //                 });
    //             }
    //         }
    //
    //         else if (this.props.isUpdateForm !== this.state.isUpdateForm) {
    //             // console.log(4 + ' isUpdate');
    //             this.setState({
    //                 isUpdateForm: this.props.isUpdateForm
    //             });
    //             this.formUpdate(this.state.isLoadForm);
    //         }
    //     }
    // }

    // formUpdate(isLoadForm) {
    //     this.props.apiRequest('/company/' + this.state.objectId + '/supports_date', null, () => {
    //         this.props.formLoad(isLoadForm);
    //     }, null);
    // }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    // setStateAndInput(name, value) {
    //
    //     let input = document.getElementById(name);
    //     input.value = value;
    //
    //     this.setState({
    //         [name]: value
    //     });
    // }

    handleSubmit(event) {
        event.preventDefault();

        if (!this.state.passwordOld || !this.state.passwordNew || !this.state.passwordRepeat) {
            this.setState({
                formResponseError: 'Пожалуйста, внимательно заполните все поля.'
            });
        }

        else {
            if (this.state.passwordNew === this.state.passwordRepeat) {
                let formData = new FormData();

                formData.append('profile_old_password', this.state.passwordOld);
                formData.append('profile_new_password', this.state.passwordNew);

                this.setState({
                    isHandleSubmit: true
                });

                this.props.apiRequest('api/cabinet/profile/password', formData, () => {
                        this.setState({
                            isHandleSubmit: false,
                            formResponseError: null
                        });
                        this.props.profileClose();
                        this.props.profileUpdate();
                    }, () => {
                        this.setState({
                            isHandleSubmit: false,
                            formResponseError: this.props.responseError
                        })
                    }
                );
            }

            else {
                this.setState({
                    formResponseError: 'Повторный пароль введен неверно.'
                });
            }
        }
    }

    modalClose() {
        this.props.modalClose();
    }

    alertOpen(alertForm, alertData) {
        this.props.alertOpen(alertForm, alertData);
    }

    render() {
        const { isOpenForm, isHandleSubmit, formResponseError, countNumber, countDate, formData, supportType } = this.state;

        let alertData = (objectId, objectType, objectData) => {
            return {
                objectId: objectId,
                objectType: objectType,
                objectData: objectData
            };
        }

        return (
            <div className="Profile__form">
                <form onSubmit={this.handleSubmit.bind(this)}>
                    <div className="Form__header">
                        <div className="Header__title">Смена пароля</div>
                    </div>

                    <div className="Form__content">
                        <div className="Content__hr Content__hr_bottom-32"/>

                        <div className="Content__box">
                            <span className="Box__info">Изменить действующий пароль для входа в аккаунт возможно после заполнения следующих полей:</span>
                        </div>
                        <div className="Content__box">
                            <div className="Form__field">
                                <div className="Field__label">Активный пароль</div>
                                <input className="Field__input Field__input_password"
                                       name="passwordOld"
                                       type="password"
                                       value={this.state.value}
                                       onChange={this.handleInputChange.bind(this)} />
                            </div>
                        </div>

                        <div className="Content__box">
                            <div className="Form__field">
                                <div className="Field__label">Новый пароль</div>
                                <input className="Field__input Field__input_password"
                                       name="passwordNew"
                                       type="password"
                                       value={this.state.value}
                                       onChange={this.handleInputChange.bind(this)} />
                            </div>
                        </div>

                        <div className="Content__box">
                            <div className="Form__field">
                                <div className="Field__label">Повторно введите новый пароль</div>
                                <input className="Field__input Field__input_password"
                                       name="passwordRepeat"
                                       type="password"
                                       value={this.state.value}
                                       onChange={this.handleInputChange.bind(this)} />
                            </div>
                        </div>

                        <div className="Content__box">
                            <span className="Box__info">В случае успешного изменения пароля, откроется страница авторизации.</span>
                        </div>

                        <div className="Content__error">
                            <span className="Error__text">{formResponseError}</span>
                        </div>
                    </div>
                    <div className="Form__footer">

                        <button className={isHandleSubmit ? 'Form__button Form__button_blue Form__button_left Form__button_disabled' : 'Form__button Form__button_blue Form__button_left'}
                                type="submit"
                                style={{
                                    position: "relative"
                                }}
                                disabled={isHandleSubmit ? 'disabled' : null}>
                            Сохранить
                            <div style={{position: "absolute", right: 6, top: 6}}>{isHandleSubmit ? <Loading white={true} /> : null}</div>
                        </button>

                        {/*<div className="Field__link"*/}
                        {/*     style={{*/}
                        {/*         display: 'inline-block'*/}
                        {/*     }}*/}
                        {/*onClick={this.alertOpen.bind(this, 'alertProfileRecovery', null)}>*/}
                        {/*    Забыли пароль?*/}
                        {/*</div>*/}
                    </div>
                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        isLoadForm: state.form.isLoadForm,
        isUpdateForm: state.form.isUpdateForm,
        interimData: state.modal.interimData,
        isRequest: state.api.isRequest,
        responseMessage: state.api.responseMessage,
        responseData: state.api.responseData,
        responseError: state.api.responseError
    }
}

function mapDispatchToProps(dispatch) {
    return {
        formLoad: (isLoadForm) => dispatch(formLoad(isLoadForm)),
        pageUpdate: () => dispatch(pageUpdate()),
        profileUpdate: () => dispatch(profileUpdate()),
        profileClose: () => dispatch(profileClose()),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        alertOpen: (alertForm, alertData) => dispatch(alertOpen(alertForm, alertData)),
        modalClose: () => dispatch(modalClose())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePassword);
