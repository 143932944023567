import React from "react";
import {connect} from "react-redux";
import {pageLoad, pageUpdate, pageMount} from "../../../../../store/actions/pageAction";
import {apiRequest} from "../../../../../store/actions/apiAction";
import {modalOpen} from "../../../../../store/actions/modalAction";
import "./TeacherTrainingCourses.scss";
import Loading from "../../../../loading/Loading";
import {Link} from "react-router-dom";

class TeacherTrainingCourses extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isMount: true,
            isOpen: false,
            isLoad: false,
            isUpdate: false,
            teacherTrainingCoursesData: null
        };
    }

    componentDidMount() {
        this.props.pageMount();
        this.pageUpdate(this.state.isLoad);
        // this.intervalUpdate = setInterval(this.props.pageUpdate, 180000);
    }

    componentWillUnmount() {
        // console.log('Employees Unmounted');
        this.setState({
            isMount: false
        });
    }

    componentDidUpdate() {
        if (this.state.isMount) {
            const responseData = this.props.responseData;
            // console.log(1 + ' componentDidUpdate');
            if (this.props.isLoad !== this.state.isLoad) {
                // console.log(2 + ' isLoad');
                if (responseData !== this.state.teacherTrainingCoursesData) {
                    // console.log(3 + ' responseData');

                    this.setState({
                        isOpen: true,
                        isLoad: this.props.isLoad,
                        isUpdate: this.props.isUpdate,
                        teacherTrainingCoursesData: responseData
                    });
                }
            }

            else if (this.props.isUpdate !== this.state.isUpdate) {
                // console.log(4 + ' isUpdate');
                this.pageUpdate(this.state.isLoad);

                this.setState({
                    isUpdate: this.props.isUpdate
                });
            }
        }
    }

    pageUpdate(isLoad) {
        this.props.apiRequest('api/cabinet/teacher/training/courses', null, () => {
            this.props.pageLoad(isLoad);
        }, null);
    }

    modalOpen(modalForm, modalData) {
        this.props.modalOpen(modalForm, modalData);
    }

    render() {
        const { isMount, isOpen, teacherTrainingCoursesData } = this.state;

        let modalData = (objectId, objectData) => {
            return {
                objectId: objectId,
                objectData: objectData
            };
        }

        let groupName = (groupName, initialYear) => {
            let course;

            let date = new Date();
            course = (date.getFullYear() - Number(initialYear) + 1);

            if (groupName) {
                return groupName.replace(/\$/i, course);
            }
        }

        if (isMount) {

            if (!isOpen || !teacherTrainingCoursesData) {
                return (<div className="Page__loader"><i/><Loading/><i/></div>);
            }

            else {

                return (
                    <div className="Page__section">
                        {teacherTrainingCoursesData.length > 0 ? (
                        <div className="Section__header">
                            <div className="Header__title">
                                <div className="Title__text">Учебные курсы</div>

                                {/*<div className="Block__Item" style={{display: "inline-block", marginBottom: 0, verticalAlign: "top"}}>*/}
                                {/*    <div className="Item__block Item__block_inline Item__block_margin-right Item__block_padding"*/}
                                {/*         style={{display: "inline-block", marginBottom: 0, verticalAlign: "top", paddingTop: 4, marginRight: 0}}>*/}

                                {/*        <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                {/*    </div>*/}
                                {/*</div>*/}

                                {/*<div className="Title__text" style={{opacity: 0.15}}>Архив</div>*/}
                            </div>
                        </div>
                            ) : null}

                        <div className="Section__content">

                            <div className="TeacherTrainingCourses">
                                <div className="TeacherTrainingCourses__body">

                                    {teacherTrainingCoursesData.length > 0 ? (
                                        <React.Fragment>
                                    {/*<div className="Body__hr"/>*/}

                                    <ul className="Body__list" style={{width: 376 + 376 + 376 + 42 + 42 + 42}}>

                                        {teacherTrainingCoursesData.map((trainingCourse, index) => (
                                            <Link to={"/cabinet/teacher/training/course/" + trainingCourse.id} key={index}>
                                                <li className="List__box" style={{width: 376, display: "inline-block", marginRight: 42, verticalAlign: "top"}}>
                                                        <div className="Box__header">
                                                            <div className="Header__title" style={{fontSize: 22}}>

                                                                {trainingCourse.discipline_name}

                                                                <div style={{fontSize: 16, fontWeight: 400, marginTop: 8}}>
                                                                    {trainingCourse.year_program} курс, {trainingCourse.period_program} семестр
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="Box__footer">
                                                            <div className="Block__Item" style={{display: "inline-block", marginBottom: 0, verticalAlign: "top"}}>
                                                                <div className="Item__block Item__block_inline Item__block_margin-right Item__block_padding">
                                                                    <div className="Item__Text"
                                                                         style={{fontSize: 24, fontWeight: 900}}>
                                                                        {groupName(trainingCourse.group_name, trainingCourse.initial_year)}
                                                                    </div>
                                                                    <div className="Item__hr Item__hr_vertical Item__hr_inline"/>
                                                                    <div className="Item__label Item__label_inline">Группа</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                </li>
                                            </Link>
                                        ))}

                                    </ul>
                                        </React.Fragment>
                                    ) : (
                                    <React.Fragment>
                                        <div className="Page__loader">
                                            <i/>
                                            <div className="Body_nodata" style={{marginTop: 96 + 48, opacity: .15}}>
                                                Пока нет курсов
                                                {/*<div style={{textAlign: 'center', marginTop: 48, fontSize: 32}}>*/}
                                                {/*    ERROR 404*/}
                                                {/*</div>*/}
                                            </div>
                                            <i/>
                                        </div>
                                    </React.Fragment>
                                    )}

                                </div>
                            </div>

                        </div>

                        <div className="Section__button"
                             onClick={this.modalOpen.bind(this, 'modalTeacherTrainingCourseAdd', modalData(null, null))}>
                            <div className="button"/>
                        </div>
                    </div>
                );
            }
        }

        else return null;
    }
}

function mapStateToProps(state) {
    return {
        isLoad: state.page.isLoad,
        isUpdate: state.page.isUpdate,
        responseData: state.api.responseData
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageMount: () => dispatch(pageMount()),
        pageLoad: (isLoad) => dispatch(pageLoad(isLoad)),
        pageUpdate: () => dispatch(pageUpdate()),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        modalOpen: (modalForm, modalData) => dispatch(modalOpen(modalForm, modalData))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (TeacherTrainingCourses);
