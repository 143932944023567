import React from "react";
import {connect} from "react-redux";
import {alertClose} from "../../../../../store/actions/alertAction";
import {apiRequest} from "../../../../../store/actions/apiAction";
import Loading from "../../../../loading/Loading";
import {formUpdate} from "../../../../../store/actions/formAction";
import {notificationMessage} from "../../../../../store/actions/notificationAction";
import {pageUpdate} from "../../../../../store/actions/pageAction";

class AlertTeacherChatDelete extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            objectId: this.props.alertData.objectId,
            objectType: null,
            objectData: null,
            isHandleSubmit: false,
            alertResponseError: null
        };
    }

    componentDidMount() {
        // this.setState({
        //     objectData: this.props.alertData.objectData,
        //     objectId: this.props.alertData.objectId,
        //     objectType: this.props.alertData.objectType
        // });
    }

    handleSubmit(event) {
        event.preventDefault();

        this.setState({
            isHandleSubmit: true
        });

        this.props.apiRequest('api/cabinet/teacher/personal/chat/' + this.state.objectId + '/delete', null, () => {
                this.setState({
                    isHandleSubmit: false,
                    alertResponseError: null
                });
                this.props.alertClose();
                this.props.notificationMessage(this.props.responseMessage, 'green');
                this.props.pageUpdate();
                // this.props.formUpdate();
            }, () => {
                this.setState({
                    isHandleSubmit: false,
                    alertResponseError: this.props.responseError
                });
            }
        );
    }

    alertClose() {
        this.props.alertClose();
    }

    render() {
        const { isHandleSubmit, alertResponseError } = this.state;

        return (
            <div className="Form">
                <form onSubmit={this.handleSubmit.bind(this)}>
                    <div className="Form__header">
                        <div className="Header__title">Удалить диалог</div>
                    </div>
                    <div className="Form__content">
                        <div className="Content__box">
                            <div className="Box__text" style={{fontSize: 16}}>
                                Все сообщения в данном диалоге будут удалены. Продолжить?</div>
                        </div>

                        <div className="Content__error">
                            <span className="Error__text">{alertResponseError}</span>
                        </div>
                    </div>
                    <div className="Form__footer">
                        <button className={isHandleSubmit ? 'Form__button Form__button_red Form__button_left Form__button_disabled' : 'Form__button Form__button_red Form__button_left'}
                                type="submit"
                                style={{
                                    position: "relative"
                                }}
                                disabled={isHandleSubmit ? 'disabled' : null}>
                            Удалить
                            <div style={{position: "absolute", right: 6, top: 6}}>{isHandleSubmit ? <Loading white={true} /> : null}</div>
                        </button>
                    </div>
                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        alertData: state.alert.alertData,
        responseError: state.api.responseError,
        responseMessage: state.api.responseMessage
    }
}

function mapDispatchToProps(dispatch) {
    return {
        formUpdate: () => dispatch(formUpdate()),
        pageUpdate: () => dispatch(pageUpdate()),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        // modalClose: () => dispatch(modalClose()),
        notificationMessage: (message, colorMessage) => dispatch(notificationMessage(message, colorMessage)),
        alertClose: () => dispatch(alertClose())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertTeacherChatDelete);
