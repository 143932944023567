import React from "react";
import {Redirect, Route, Switch} from 'react-router-dom';
import {connect} from "react-redux";
import "./page/Page.scss";
import Logout from "./auth/Logout";
import Notification from "./notification/Notification";
import Modal from "./modal/Modal";
import Alert from "./alert/Alert";
import Navigate from "./navigate/Navigate";
import Students from "./page/students/Students";
import Profile from "./profile/Profile";
import Employees from "./page/employees/Employees";
import TrainingPlans from "./page/training/plans/TrainingPlans";
import TrainingPlan from "./page/training/plan/TrainingPlan";
import TrainingSchedules from "./page/training/schedules/TrainingSchedules";
import TrainingSchedule from "./page/training/schedule/TrainingSchedule";
import TeacherTrainingCourses from "./page/teacher/training/courses/TeacherTrainingCourses";
import TeacherTrainingCourse from "./page/teacher/training/course/TeacherTrainingCourse";
import Curator from "./page/teacher/curator/Curator";
import StudentTrainingCourses from "./page/student/training/courses/StudentTrainingCourses";
import GradeBook from "./page/student/gradebook/GradeBook";
import StudentTrainingSchedule from "./page/student/training/schedule/StudentTrainingSchedule";
import StudentTrainingCourse from "./page/student/training/course/StudentTrainingCourse";
import StudentMessages from "./page/student/messages/StudentMessages";
import TeacherMessages from "./page/teacher/messages/TeacherMessages";
import StudentFeedback from "./page/student/feedback/StudentFeedback";
import Feedbacks from "./page/feedbacks/Feedbacks";
import TeacherResit from "./page/teacher/resit/TeacherResit";

class Cabinet extends React.Component {

    render() {
        const { profileData } = this.props;


        if (profileData) {

            if (profileData.profile_type === 'administrator') {
                return (
                    <div className="Cabinet">
                        <Navigate/>
                        <div className="Page">
                            <Switch>
                                <Route exact path="/cabinet/logout" component={Logout}/>
                                <Route exact path="/cabinet/students" component={Students}/>
                                <Route exact path="/cabinet/employees" component={Employees}/>
                                <Route exact path="/cabinet/training/plans" component={TrainingPlans}/>
                                <Route exact path="/cabinet/training/plan/:trainingPlanId" component={TrainingPlan}/>

                                <Route exact path="/cabinet/training/schedules" component={TrainingSchedules}/>
                                <Route exact path="/cabinet/training/schedule/:trainingScheduleId" component={TrainingSchedule}/>

                                <Route exact path="/cabinet/feedbacks" component={Feedbacks}/>

                                <Route path="/">
                                    <Redirect push to="/cabinet/students"/>
                                </Route>
                            </Switch>
                        </div>
                        <Profile/>
                        <Modal/>
                        <Alert/>
                        <Notification/>
                    </div>
                );
            }

            else if (profileData.profile_type === 'teacher') {
                return (
                    <div className="Cabinet">
                        <Navigate/>
                        <div className="Page">
                            <Switch>
                                <Route exact path="/cabinet/logout" component={Logout}/>
                                <Route exact path="/cabinet/teacher/training/courses" component={TeacherTrainingCourses}/>
                                <Route exact path="/cabinet/teacher/training/course/:trainingCourseId" component={TeacherTrainingCourse}/>
                                <Route exact path="/cabinet/teacher/curator" component={Curator}/>
                                <Route exact path="/cabinet/teacher/messages" component={TeacherMessages}/>
                                <Route exact path="/cabinet/teacher/feedback" component={StudentFeedback}/>
                                <Route exact path="/cabinet/teacher/resit" component={TeacherResit}/>

                                <Route path="/">
                                    <Redirect push to="/cabinet/teacher/training/courses"/>
                                </Route>
                            </Switch>
                        </div>
                        <Profile/>
                        <Modal/>
                        <Alert/>
                        <Notification/>
                    </div>
                );
            }

            else if (profileData.profile_type === 'student') {
                return (
                    <div className="Cabinet">
                        <Navigate/>
                        <div className="Page">
                            <Switch>
                                <Route exact path="/cabinet/logout" component={Logout}/>
                                <Route exact path="/cabinet/student/training/schedule" component={StudentTrainingSchedule}/>
                                <Route exact path="/cabinet/student/training/courses" component={StudentTrainingCourses}/>
                                <Route exact path="/cabinet/student/training/course/:trainingCourseId" component={StudentTrainingCourse}/>
                                <Route exact path="/cabinet/student/gradebook" component={GradeBook}/>
                                <Route exact path="/cabinet/student/messages" component={StudentMessages}/>
                                <Route exact path="/cabinet/student/feedback" component={StudentFeedback}/>

                                <Route path="/">
                                    <Redirect push to="/cabinet/student/training/schedule"/>
                                </Route>
                            </Switch>
                        </div>
                        <Profile/>
                        <Modal/>
                        <Alert/>
                        <Notification/>
                    </div>
                );
            }
        }

        else {

            return (<Profile />);
        }
    }
}

function mapStateToProps(state) {
    return {
        profileData: state.profile.profileData
    }
}

export default connect(mapStateToProps, null)(Cabinet);
