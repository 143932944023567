import React from "react";
import {connect} from "react-redux";
import {apiRequest} from "../../../store/actions/apiAction";
import {formLoad} from "../../../store/actions/formAction";
import {modalClose} from "../../../store/actions/modalAction";
import {alertOpen} from "../../../store/actions/alertAction";
import Loading from "../../loading/Loading";
import {profileUpdate} from "../../../store/actions/profileAction";
import {notificationMessage} from "../../../store/actions/notificationAction";

class ProfileSettings extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isMountForm: true,
            isOpenForm: false,
            isLoadForm: false,
            isUpdateForm: false,
            formData: null,
            isHandleSubmit: false,
            formResponseError: null,

            profileFio: null,
            profileTelephone: null,
            profileEmail: null,
            profilePosition: null,
            periodOfStudy: null,
            educationalInstitution: null,
            receivedSpecialty: null
        };
    }

    componentDidMount() {
        // this.formUpdate(this.state.isLoadForm);
        this.setState({
            profileFio: this.props.profileData.profile_fio,
            profileTelephone: (this.props.profileData.profile_telephone ? this.props.profileData.profile_telephone : ''),
            profileEmail: this.props.profileData.profile_email,
            profilePosition: (this.props.profileData.profile_position ? this.props.profileData.profile_position : ''),
            periodOfStudy: (this.props.profileData.period_of_study ? this.props.profileData.period_of_study : ''),
            educationalInstitution: (this.props.profileData.educational_institution ? this.props.profileData.educational_institution : ''),
            receivedSpecialty: (this.props.profileData.received_specialty ? this.props.profileData.received_specialty : '')
        });
    }

    componentWillUnmount() {
        // console.log('From Unmounted');
        this.setState({
            isMountForm: false
        });
    }

    componentDidUpdate() {
        if (this.state.isMountForm) {
            const responseData = this.props.responseData;
            // console.log(1 + ' componentDidUpdate');
            if (this.props.isLoadForm !== this.state.isLoadForm) {
                // console.log(2 + ' isLoad');
                if (responseData !== this.state.formData) {
                    // console.log(3 + ' responseData');

                    // this.formUpdate();

                    this.setState({
                        isOpenForm: true,
                        isLoadForm: this.props.isLoadForm,
                        isUpdateForm: this.props.isUpdateForm,
                        formData: responseData
                    });
                }
            }

            else if (this.props.isUpdateForm !== this.state.isUpdateForm) {
                // console.log(4 + ' isUpdate');
                this.setState({
                    isUpdateForm: this.props.isUpdateForm
                });
                this.formUpdate(this.state.isLoadForm);
            }
        }
    }

    // formUpdate(isLoadForm) {
    //     this.props.apiRequest('/company/' + this.state.objectId + '/supports_date', null, () => {
    //         this.props.formLoad(isLoadForm);
    //     }, null);
    // }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    // setStateAndInput(name, value) {
    //
    //     let input = document.getElementById(name);
    //     input.value = value;
    //
    //     this.setState({
    //         [name]: value
    //     });
    // }

    handleSubmit(event) {
        event.preventDefault();

        if (this.props.profileData.profile_type === 'student') {

            let formData = new FormData();

            formData.append('profile_telephone', this.state.profileTelephone);
            formData.append('period_of_study', this.state.periodOfStudy);
            formData.append('educational_institution', this.state.educationalInstitution);
            formData.append('received_specialty', this.state.receivedSpecialty);

            this.setState({
                isHandleSubmit: true
            });

            this.props.apiRequest('api/cabinet/profile/edit', formData, () => {
                    // this.setStateAndInput('', null);
                    this.setState({
                        isHandleSubmit: false,
                        formResponseError: null
                    });
                    // this.formUpdate(this.state.isLoadForm);
                    this.props.notificationMessage(this.props.responseMessage, 'green');
                    this.props.profileUpdate();
                }, () => {
                    this.setState({
                        isHandleSubmit: false,
                        formResponseError: this.props.responseError
                    });
                }
            );
        }

        else {

            if (this.state.profileFio && this.state.profileEmail) {

                let formData = new FormData();

                formData.append('profile_email', this.state.profileEmail);
                formData.append('profile_fio', this.state.profileFio);
                formData.append('profile_telephone', this.state.profileTelephone);
                formData.append('profile_position', this.state.profilePosition);

                this.setState({
                    isHandleSubmit: true
                });

                this.props.apiRequest('api/cabinet/profile/edit', formData, () => {
                        // this.setStateAndInput('', null);
                        this.setState({
                            isHandleSubmit: false,
                            formResponseError: null
                        });
                        // this.formUpdate(this.state.isLoadForm);
                        this.props.notificationMessage(this.props.responseMessage, 'green');
                        this.props.profileUpdate();
                    }, () => {
                        this.setState({
                            isHandleSubmit: false,
                            formResponseError: this.props.responseError
                        });
                    }
                );
            }

            else {
                this.setState({
                    formResponseError: 'Внимательно заполните все обязательные поля ( * ).'
                });
            }
        }
    }

    modalClose() {
        this.props.modalClose();
    }

    alertOpen(alertForm, alertData) {
        this.props.alertOpen(alertForm, alertData);
    }

    render() {
        const { profileData } = this.props;
        const { isOpenForm, isHandleSubmit, formResponseError, countNumber, countDate, formData, supportType } = this.state;

        let alertData = (objectId, objectType, objectData) => {
            return {
                objectId: objectId,
                objectType: objectType,
                objectData: objectData
            };
        }

        return (
            <div className="Profile__form">
                <form onSubmit={this.handleSubmit.bind(this)}>
                    <div className="Form__header">
                        <div className="Header__title">Настройки</div>
                    </div>

                    <div className="Form__content">
                        <div className="Content__hr Content__hr_bottom-32"/>

                        {profileData.profile_type === 'student' ? (
                                <React.Fragment>
                        <div className="Box">
                            <div className="Box__title"
                                 style={{marginBottom: 16}}>Личные данные</div>
                        </div>
                        <div className="Content__box">
                            <span className="Box__info">Контактная информация</span>
                        </div>

                                    <div className="Content__box">
                                        <div className="Form__field">
                                            <div className="Field__label">Телефон</div>
                                            <input className="Field__input"
                                                   name="profileTelephone"
                                                   type="number"
                                                   placeholder="89991234567"
                                                   defaultValue={profileData.profile_telephone}
                                                   value={this.state.value}
                                                   onChange={this.handleInputChange.bind(this)}/>
                                        </div>
                                    </div>
                                </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <div className="Box">
                                    <div className="Box__title"
                                         style={{marginBottom: 24}}>Личные данные</div>
                                </div>

                                <div className="Content__box">
                                    <div className="Form__field">
                                        <div className="Field__label">Фамилия Имя Отчество *</div>
                                        <input className="Field__input"
                                               name="profileFio"
                                               type="text"
                                               placeholder="Иванов Иван Иванович"
                                               defaultValue={profileData.profile_fio}
                                               value={this.state.value}
                                               onChange={this.handleInputChange.bind(this)}/>
                                    </div>
                                </div>

                                <div className="Content__box">
                                    <div className="Form__field">
                                        <div className="Field__label">Телефон</div>
                                        <input className="Field__input"
                                               name="profileTelephone"
                                               type="number"
                                               placeholder="89991234567"
                                               defaultValue={profileData.profile_telephone}
                                               value={this.state.value}
                                               onChange={this.handleInputChange.bind(this)}/>
                                    </div>
                                </div>

                                <div className="Content__box">
                                    <div className="Form__field">
                                        <div className="Field__label">Электронная почта *</div>
                                        <input className="Field__input"
                                               name="profileEmail"
                                               type="email"
                                               placeholder="teacher@email.com"
                                               defaultValue={profileData.profile_email}
                                               value={this.state.value}
                                               onChange={this.handleInputChange.bind(this)}/>
                                    </div>
                                </div>

                            </React.Fragment>
                        )}

                        {profileData.profile_type === 'student' ? (
                            <React.Fragment>
                        <div className="Content__hr Content__hr_bottom-32"/>

                        <div className="Box">
                            <div className="Box__title"
                                 style={{marginBottom: 16}}>Образование</div>
                        </div>

                        <div className="Content__box">
                            <span className="Box__info">Сведения о полученном образовании</span>
                        </div>

                                <div className="Content__box">
                                    <div className="Form__field">
                                        <div className="Field__label">Период обучения</div>
                                        <input className="Field__input"
                                               name="periodOfStudy"
                                               type="text"
                                               placeholder="мм.гггг-мм.гггг"
                                               defaultValue={profileData.period_of_study}
                                               value={this.state.value}
                                               onChange={this.handleInputChange.bind(this)} />
                                    </div>
                                </div>

                                <div className="Content__box">
                                    <div className="Form__field">
                                        <div className="Field__label">Учебное заведение</div>
                                        <input className="Field__input"
                                               name="educationalInstitution"
                                               type="text"
                                               defaultValue={profileData.educational_institution}
                                               value={this.state.value}
                                               onChange={this.handleInputChange.bind(this)} />
                                    </div>
                                </div>

                                <div className="Content__box">
                                    <div className="Form__field">
                                        <div className="Field__label">Полученная специальность (если есть)</div>
                                        <input className="Field__input"
                                               name="receivedSpecialty"
                                               type="text"
                                               defaultValue={profileData.received_specialty}
                                               value={this.state.value}
                                               onChange={this.handleInputChange.bind(this)} />
                                    </div>
                                </div>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <div className="Content__hr Content__hr_bottom-32"/>

                                <div className="Content__box"
                                     style={{marginBottom: 16}}>
                                    <div className="Box__title">Образование</div>
                                </div>

                                <div className="Content__box">
                                    <span className="Box__info">Сведения о квалификации</span>
                                </div>

                                <div className="Content__box">
                                    <div className="Form__field">
                                        <div className="Field__label">Должность, ученая степень</div>
                                        <input className="Field__input"
                                               name="profilePosition"
                                               type="text"
                                               defaultValue={profileData.profile_position}
                                               value={this.state.value}
                                               onChange={this.handleInputChange.bind(this)} />
                                    </div>
                                </div>

                            </React.Fragment>
                        )}

                        <div className="Content__error">
                            <span className="Error__text">{formResponseError}</span>
                        </div>
                    </div>
                    <div className="Form__footer">
                        <button className={isHandleSubmit ? 'Form__button Form__button_blue Form__button_left Form__button_disabled' : 'Form__button Form__button_blue Form__button_left'}
                                type="submit"
                                style={{
                                    position: "relative"
                                }}
                                disabled={isHandleSubmit ? 'disabled' : null}>
                            Сохранить
                            <div style={{position: "absolute", right: 6, top: 6}}>{isHandleSubmit ? <Loading white={true} /> : null}</div>
                        </button>
                    </div>
                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        isLoadForm: state.form.isLoadForm,
        isUpdateForm: state.form.isUpdateForm,
        responseMessage: state.api.responseMessage,
        responseError: state.api.responseError,
        profileData: state.profile.profileData
    }
}

function mapDispatchToProps(dispatch) {
    return {
        formLoad: (isLoadForm) => dispatch(formLoad(isLoadForm)),
        profileUpdate: () => dispatch(profileUpdate()),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        alertOpen: (alertForm, alertData) => dispatch(alertOpen(alertForm, alertData)),
        notificationMessage: (message, colorMessage) => dispatch(notificationMessage(message, colorMessage)),
        modalClose: () => dispatch(modalClose())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSettings);
