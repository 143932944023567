import axios from 'axios';
import {PROFILE_CLOSE, PROFILE_DATA, PROFILE_LOAD, PROFILE_OPEN, PROFILE_UPDATE, PROFILE_REQUEST, PROFILE_REQUEST_ERROR, PROFILE_RESPONSE, PROFILE_RESPONSE_ERROR} from "./actionTypes";
import {authLogout} from "./authAction";
import baseURL from "../../library/baseURL";

export function profileOpen(profileOpenForm) {

    document.body.classList.add('overflow_hidden');

    return {
        type: PROFILE_OPEN,
        profileOpenForm: profileOpenForm
    }
}

export function profileClose() {

    document.body.classList.remove('overflow_hidden');

    return {
        type: PROFILE_CLOSE
    }
}

export function profileLoad(isLoadProfile) {

    isLoadProfile = !isLoadProfile;

    return {
        type: PROFILE_LOAD,
        isLoadProfile: isLoadProfile
    }
}

export function profileUpdate() {

    return {
        type: PROFILE_UPDATE
    }
}

export function profileObject(profileData) {

    return {
        type: PROFILE_DATA,
        profileData: profileData
    }
}

export function profileRequest(requestURL, formData, callbackSuccess, callbackError) {
    return async dispatch => {
        dispatch(profileRequestStart());

        let headers;

        if (formData) {
            headers = {
                Authorization: 'Bearer ' + localStorage.getItem('tokenSession'),
                'Content-Type': 'multipart/form-data'
            }
        }
        else {
            headers = {
                Authorization: 'Bearer ' + localStorage.getItem('tokenSession')
            }
        }

        const config = {
            baseURL: baseURL(),
            headers: headers
        }

        axios.defaults.withCredentials = false;

        try {
            let response;

            if (formData) {
                response = await axios.post(requestURL, formData, config);
            }
            else {
                response = await axios.get(requestURL, config);
            }

            if (response.data.auth.error) dispatch(authLogout());
            else {
                if (response.data.response.message && response.data.response.data && !response.data.response.error) {
                    // console.log('Response API message and data.');
                    dispatch(profileResponse(response.data.response.message, response.data.response.data, null));
                    callbackSuccess();
                }
                else if (response.data.response.message && !response.data.response.data && !response.data.response.error) {
                    // console.log('Response API message.');
                    dispatch(profileResponse(response.data.response.message,null, null));
                    callbackSuccess();
                }
                else if (!response.data.response.message && response.data.response.data && !response.data.response.error) {
                    // console.log('Response API data.');
                    dispatch(profileResponse(null, response.data.response.data, null));
                    callbackSuccess();
                }
                else if (!response.data.response.message && !response.data.response.data && response.data.response.error) {
                    // console.log('Error API response.');
                    dispatch(profileResponse(null, null, response.data.response.error));
                    callbackError();
                }
                else {
                    dispatch(profileResponseError('API response error.'));
                }
            }
        }
        catch (error) {
            dispatch(profileRequestError('API request error.'));
        }
    }
}

export function profileRequestStart() {
    // console.log('API request start.');

    return {
        type: PROFILE_REQUEST
    }
}

export function profileResponse(responseMessage, responseData, responseError) {
    return {
        type: PROFILE_RESPONSE,
        responseMessage,
        responseData,
        responseError
    }
}

export function profileResponseError(apiResponseError) {
    // console.log('API response error.');

    return {
        type: PROFILE_RESPONSE_ERROR,
        apiResponseError
    }
}

export function profileRequestError(apiRequestError) {
    // console.log('API request error.');

    return {
        type: PROFILE_REQUEST_ERROR,
        apiRequestError
    }
}
