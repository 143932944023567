import {NOTIFICATION_CLEAR, NOTIFICATION_MESSAGE} from "../actions/actionTypes";

const initialState = {
    message: null,
    colorMessage: null
}

export default function notificationReducer(state = initialState, action) {
    switch (action.type) {
        case NOTIFICATION_MESSAGE:
            return {
                ...state, message: action.message, colorMessage: action.colorMessage
            }
        case NOTIFICATION_CLEAR:
            return {
                ...state, message: null, colorMessage: null
            }
        default:
            return state;
    }
}
