import axios from 'axios';
import {authLogout} from "./authAction";
import {API_REQUEST, API_REQUEST_ERROR, API_RESPONSE, API_RESPONSE_ERROR, API_UNMOUNT} from "./actionTypes";
import baseURL from "../../library/baseURL";

export function apiRequest(requestURL, formData, callbackSuccess, callbackError) {
    return async dispatch => {
        dispatch(apiRequestStart());

        let headers;

        if (formData) {
            headers = {
                Authorization: 'Bearer ' + localStorage.getItem('tokenSession'),
                'Content-Type': 'multipart/form-data'
            }
        }
        else {
            headers = {
                Authorization: 'Bearer ' + localStorage.getItem('tokenSession')
            }
        }

        const config = {
            baseURL: baseURL(),
            headers: headers
        }

        axios.defaults.withCredentials = false;

        try {
            let response;

            if (formData) {
                response = await axios.post(requestURL, formData, config);
            }
            else {
                response = await axios.get(requestURL, config);
            }

            if (response.data.auth.error) dispatch(authLogout());
            else {
                if (response.data.response.message && response.data.response.data && !response.data.response.error) {
                    // console.log('Response API message and data.');
                    dispatch(apiResponse(response.data.response.message, response.data.response.data, null));
                    callbackSuccess();
                }
                else if (response.data.response.message && !response.data.response.data && !response.data.response.error) {
                    // console.log('Response API message.');
                    dispatch(apiResponse(response.data.response.message,null, null));
                    callbackSuccess();
                }
                else if (!response.data.response.message && response.data.response.data && !response.data.response.error) {
                    // console.log('Response API data.');
                    dispatch(apiResponse(null, response.data.response.data, null));
                    callbackSuccess();
                }
                else if (!response.data.response.message && !response.data.response.data && response.data.response.error) {
                    // console.log('Error API response.');
                    dispatch(apiResponse(null, null, response.data.response.error));
                    callbackError();
                }
                else {
                    dispatch(apiResponseError('API response error.'));
                }
            }
        }
        catch (error) {
            dispatch(apiRequestError('API request error.'));
        }
    }
}

export function apiRequestStart() {
    // console.log('API request start.');

    return {
        type: API_REQUEST
    }
}

export function apiResponse(responseMessage, responseData, responseError) {
    return {
        type: API_RESPONSE,
        responseMessage,
        responseData,
        responseError
    }
}

export function apiResponseError(apiResponseError) {
    // console.log('API response error.');

    return {
        type: API_RESPONSE_ERROR,
        apiResponseError
    }
}

export function apiRequestError(apiRequestError) {
    // console.log('API request error.');

    return {
        type: API_REQUEST_ERROR,
        apiRequestError
    }
}

export function apiUnmount() {

    return {
        type: API_UNMOUNT
    }
}
