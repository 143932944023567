import {CABINET_EDIT, CABINET_OPEN} from "../actions/actionTypes";

const initialState = {}

export default function cabinetReducer(state = initialState, action) {
    switch (action.type) {
        default:
            return state;
    }
}
