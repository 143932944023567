import React from "react";
import {connect} from "react-redux";
import {pageLoad, pageUpdate, pageMount} from "../../../store/actions/pageAction";
import {apiRequest} from "../../../store/actions/apiAction";
import {modalOpen} from "../../../store/actions/modalAction";
import "./Employees.scss";
import Loading from "../../loading/Loading";
import baseURL from "../../../library/baseURL";

class Employees extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isMount: true,
            isOpen: false,
            isLoad: false,
            isUpdate: false,
            employeesData: null
        };
    }

    componentDidMount() {
        this.props.pageMount();
        this.pageUpdate(this.state.isLoad);
        this.intervalUpdate = setInterval(this.props.pageUpdate, 180000);
    }

    componentWillUnmount() {
        // console.log('Employees Unmounted');

        clearInterval(this.intervalUpdate);

        this.setState({
            isMount: false
        });
    }

    componentDidUpdate() {
        if (this.state.isMount) {
            const responseData = this.props.responseData;
            // console.log(1 + ' componentDidUpdate');
            if (this.props.isLoad !== this.state.isLoad) {
                // console.log(2 + ' isLoad');
                if (responseData !== this.state.employeesData) {
                    // console.log(3 + ' responseData');

                    this.setState({
                        isOpen: true,
                        isLoad: this.props.isLoad,
                        isUpdate: this.props.isUpdate,
                        employeesData: responseData
                    });
                }
            }

            else if (this.props.isUpdate !== this.state.isUpdate) {
                // console.log(4 + ' isUpdate');
                this.pageUpdate(this.state.isLoad);

                this.setState({
                    isUpdate: this.props.isUpdate
                });
            }
        }
    }

    pageUpdate(isLoad) {
        this.props.apiRequest('api/cabinet/employees', null, () => {
            this.props.pageLoad(isLoad);
        }, null);
    }

    modalOpen(modalForm, modalData) {
        this.props.modalOpen(modalForm, modalData);
    }

    render() {
        const { isMount, isOpen, employeesData } = this.state;

        let modalData = (objectId, objectData) => {
            return {
                objectId: objectId,
                objectData: objectData
            };
        }

        let photoEmployee = (id) => {

            return (
                <div className="Employee__photo"/>
            );
        }

        let telephone = (telephone) => {

            telephone.split('');

            return '+7 (' +
                telephone[1] +
                telephone[2] +
                telephone[3] +
                ') ' +
                telephone[4] +
                telephone[5] +
                telephone[6] +
                '-' +
                telephone[7] +
                telephone[8] +
                telephone[9] +
                telephone[10];
        }

        let lastVisit = (time) => {

            return (
                <div className={((parseInt(String(new Date().getTime()/1000)) - time) < 300) ? 'Employee__online Employee__online_green' : 'Employee__online'}/>
            );
        }

        let lastVisitDataTime = (time) => {

            if (Number(time) > 0) {

                if ((parseInt(String(new Date().getTime()/1000)) - time) < 300) {

                    return 'сейчас';
                }

                else {
                    let date = new Date(time * 1000);
                    let day = "0" + date.getDate();
                    let month = "0" + (date.getMonth() + 1);
                    let year = String(date.getFullYear());
                    let hours = "0" + date.getHours();
                    let minutes = "0" + date.getMinutes();
                    let seconds = "0" + date.getSeconds();
                    let formattedData = day.substr(-2) + '.' + month.substr(-2) + '.' + year.substr(-2);
                    // let formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
                    let formattedTime = hours.substr(-2) + ':' + minutes.substr(-2);

                    return formattedTime + ' ' + formattedData;
                }
            }

            else {

                return 'никогда';
            }
        }

        if (isMount) {

            if (!isOpen || !employeesData) {
                return (<div className="Page__loader"><i/><Loading/><i/></div>);
            }

            else {

                return (
                    <div className="Page__section">

                        <div className="Section__header">
                            <div className="Header__title">
                                <div className="Title__text">Преподаватели</div>
                            </div>
                        </div>

                        <div className="Section__content">

                            <div className="Employees">
                                <div className="Employees__body">
                                    {employeesData.map((employees, index) => (
                                        <React.Fragment key={index}>
                                            <div className="Body__title">{employees.key.toUpperCase()}</div>
                                            <table className="Body__table">
                                                <tbody>
                                                {employees.array.map(employee => (
                                                    <tr className="Table__tr" key={employee.id}
                                                        onClick={this.modalOpen.bind(this, 'modalEmployee', modalData(employee.id, employee))}>
                                                        <td className="Table__td">
                                                            {employee.employee_avatar ? (
                                                                <div style={{
                                                                    position: "relative"
                                                                }}>
                                                                    <div className="Student__photo"/>
                                                                    <img style={{
                                                                        position: "absolute",
                                                                        top: 2,
                                                                        left: 2,
                                                                        width: 32,
                                                                        height: 32,
                                                                        borderRadius: "50%",
                                                                        opacity: 1
                                                                    }}
                                                                         src={baseURL() + 'api/images/avatar/' + employee.employee_avatar}
                                                                         width="100"
                                                                         height="100"/>
                                                                </div>
                                                            ) : (
                                                                <div style={{
                                                                    position: "relative"
                                                                }}>
                                                                    <div className="Student__photo"/>
                                                                </div>
                                                            )}
                                                        </td>
                                                        <td className="Table__td">{employee.employee_fio}</td>
                                                        <td className="Table__td">{employee.employee_email}</td>
                                                        <td className="Table__td">{employee.employee_telephone ? telephone(employee.employee_telephone) : null}</td>
                                                        <td className="Table__td" style={{textAlign: "right", paddingRight: 0}}>{lastVisitDataTime(employee.last_visit)}</td>
                                                        <td className="Table__td">{lastVisit(employee.last_visit)}</td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </React.Fragment>
                                    ))}
                                </div>
                            </div>

                        </div>

                        <div className="Section__button"
                             onClick={this.modalOpen.bind(this, 'modalEmployeeAdd', modalData(null, null))}>
                            <div className="button"/>
                        </div>
                    </div>
                );
            }
        }

        else return null;
    }
}

function mapStateToProps(state) {
    return {
        isLoad: state.page.isLoad,
        isUpdate: state.page.isUpdate,
        responseData: state.api.responseData
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageMount: () => dispatch(pageMount()),
        pageLoad: (isLoad) => dispatch(pageLoad(isLoad)),
        pageUpdate: () => dispatch(pageUpdate()),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        modalOpen: (modalForm, modalData) => dispatch(modalOpen(modalForm, modalData))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (Employees);
