import React from "react";
import {connect} from "react-redux";
import {alertClose} from "../../../../store/actions/alertAction";
import {apiRequest} from "../../../../store/actions/apiAction";
import {modalClose} from "../../../../store/actions/modalAction";
import Loading from "../../../loading/Loading";
import {profileClose, profileUpdate} from "../../../../store/actions/profileAction";
import {formUpdate} from "../../../../store/actions/formAction";
import {notificationMessage} from "../../../../store/actions/notificationAction";
import {pageUpdate} from "../../../../store/actions/pageAction";

class AlertStudentTransfer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            objectId: this.props.alertData.objectId,
            objectType: null,
            objectData: this.props.alertData.objectData,
            isHandleSubmit: false,
            alertResponseError: null,

            trainingCoursesCurrentData: null,
            trainingGroupId: this.props.alertData.objectData.training_group_id,
            dateEnrollment: null
        };
    }

    componentDidMount() {
        // this.formUpdate(this.state.isLoadForm);

        this.trainingCoursesCurrentData();
    }

    handleSubmit(event) {
        event.preventDefault();

        if (this.state.trainingGroupId && (Number(this.state.trainingGroupId) > 0) && this.state.dateEnrollment) {

            let formData = new FormData();
            formData.append('training_group_id', this.state.trainingGroupId);
            formData.append('date_enrollment', this.state.dateEnrollment);


            this.setState({
                isHandleSubmit: true
            });

            this.props.apiRequest('api/cabinet/student/' + this.state.objectId + '/transfer', formData, () => {
                    this.setState({
                        isHandleSubmit: false,
                        alertResponseError: null
                    });
                    this.props.alertClose();
                    this.props.notificationMessage(this.props.responseMessage, 'green');
                // this.props.formUpdate();
                this.props.pageUpdate();
                    this.props.modalClose();
                }, () => {
                    this.setState({
                        isHandleSubmit: false,
                        alertResponseError: this.props.responseError
                    });
                }
            );

        }

        else {
            this.setState({
                alertResponseError: 'Пожалуйста, внимательно заполните все поля.'
            });
        }
    }

    alertClose() {
        this.props.alertClose();
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    trainingCoursesCurrentData() {
        this.props.apiRequest('api/cabinet/students', null, () => {
            this.setState({
                trainingCoursesCurrentData: this.props.responseData
            }, () => {
                // this.trainingGroupData();
                // this.trainingProgramData();
                // this.trainingDisciplineData();
            });
        }, null);
    }

    render() {
        const { isHandleSubmit, alertResponseError, trainingCoursesCurrentData, trainingGroupId} = this.state;

        let groupName = (groupName, initialYear) => {
            let course;

            let date = new Date();
            course = (date.getFullYear() - Number(initialYear) + 1);

            if (groupName.search('\$') > 0) {
                return groupName.replace(/\$/i, course);
            }

            else {
                return groupName;
            }
        }

        return (
            <div className="Form">
                <form onSubmit={this.handleSubmit.bind(this)}>
                    <div className="Form__header">
                        <div className="Header__title">Перевод студента</div>
                    </div>
                    <div className="Form__content">
                        {/*<div className="Content__box">*/}
                        {/*    <div className="Box__text" style={{fontSize: 16}}>Если этот пользователь совершал действия на портале, то он будет архивирован, иначе удален. Вы хотите продолжить?</div>*/}
                        {/*</div>*/}

                        <div className="Content__hr Content__hr_bottom-32"/>

                        <div className="Content__box">
                            <div className="Form__field">
                                <div className="Field__label">Группа</div>
                                <select className="Field__select"
                                        id="trainingGroupId"
                                        name="trainingGroupId"
                                        value={trainingGroupId}
                                        onChange={this.handleInputChange.bind(this)}>
                                    <option value="0">Не выбран</option>
                                    <option  className="option__hr" disabled />
                                    {!trainingCoursesCurrentData ? null : (
                                        trainingCoursesCurrentData.map(groups => (
                                            groups.array.map((group, index) => (
                                                <React.Fragment key={index}>
                                                    <option value={group.id}>{groupName(group.group_name, group.initial_year)}</option>
                                                    <option  className="option__hr" disabled />
                                                </React.Fragment>
                                            ))
                                        ))
                                    )}
                                </select>
                            </div>
                        </div>

                        <div className="Content__box">
                            <div className="Form__field">
                                <div className="Field__label">Дата зачисления / перевода</div>
                                <input className="Field__input"
                                       name="dateEnrollment"
                                       type="text"
                                       placeholder="дд.мм.гггг"
                                       value={this.state.value}
                                       onChange={this.handleInputChange.bind(this)}/>
                            </div>
                        </div>

                        <div className="Content__error">
                            <span className="Error__text">{alertResponseError}</span>
                        </div>
                    </div>
                    <div className="Form__footer">
                        <button className={isHandleSubmit ? 'Form__button Form__button_blue Form__button_left Form__button_disabled' : 'Form__button Form__button_blue Form__button_left'}
                                type="submit"
                                style={{
                                    position: "relative"
                                }}
                                disabled={isHandleSubmit ? 'disabled' : null}>
                            Перевести
                            <div style={{position: "absolute", right: 6, top: 6}}>{isHandleSubmit ? <Loading white={true} /> : null}</div>
                        </button>
                    </div>
                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        alertData: state.alert.alertData,
        isRequest: state.api.isRequest,
        responseMessage: state.api.responseMessage,
        responseData: state.api.responseData,
        responseError: state.api.responseError
    }
}

function mapDispatchToProps(dispatch) {
    return {
        formUpdate: () => dispatch(formUpdate()),
        pageUpdate: () => dispatch(pageUpdate()),
        modalClose: () => dispatch(modalClose()),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        notificationMessage: (message, colorMessage) => dispatch(notificationMessage(message, colorMessage)),
        alertClose: () => dispatch(alertClose())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertStudentTransfer);
