import {NOTIFICATION_CLEAR, NOTIFICATION_MESSAGE} from "./actionTypes";

export function notificationClear() {
    return async dispatch => {
        dispatch(notificationClearMessages());
    }
}

export function notificationMessage(message, colorMessage) {
    return async dispatch => {
        dispatch(notificationClearMessages());
        dispatch(notificationAddMessage(message, colorMessage));
        dispatch(notificationAutoClear());
    }
}

export function notificationAddMessage(message, colorMessage) {

    return {
        type: NOTIFICATION_MESSAGE,
        message,
        colorMessage
    }
}

export function notificationClearMessages() {

    return {
        type: NOTIFICATION_CLEAR
    }
}

export function notificationAutoClear() {
    return dispatch => {setTimeout(() => {dispatch(notificationClearMessages())}, 4000)}
}
