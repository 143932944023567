import React from "react";
import {connect} from "react-redux";
import {modalClose} from "../../../../store/actions/modalAction";
import {notificationMessage} from "../../../../store/actions/notificationAction";
import {apiRequest} from "../../../../store/actions/apiAction";
import {pageUpdate} from "../../../../store/actions/pageAction";
import Loading from "../../../loading/Loading";
import {formLoad} from "../../../../store/actions/formAction";
import {alertOpen} from "../../../../store/actions/alertAction";

class ModalTrainingGroupAdd extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isMountForm: true,
            isOpenForm: false,
            isLoadForm: false,
            isUpdateForm: false,
            formData: null,
            isHandleSubmit: false,
            objectId: null,
            objectData: null,
            formResponseError: null,

            groupName: null,
            initialYear: null,
            trainingProgramId: 0,
            trainingScheduleId: 0,
            employeeId: 0,
            employeesData: null,
            trainingProgramsData: null,
            trainingSchedulesData: null
        };
    }

    componentDidMount() {
        // this.formUpdate(this.state.isLoadForm);
        this.employeesData();
        this.trainingProgramsData();
        this.trainingSchedulesData();
    }

    componentWillUnmount() {
        // console.log('From Unmounted');
        this.setState({
            isMountForm: false
        });
    }

    // componentDidUpdate() {
    //     if (this.state.isMountForm) {
    //         const responseData = this.props.responseData;
    //         // console.log(1 + ' componentDidUpdate');
    //         if (this.props.isLoadForm !== this.state.isLoadForm) {
    //             // console.log(2 + ' isLoad');
    //             if (responseData !== this.state.formData) {
    //                 // console.log(3 + ' responseData');
    //
    //                 this.formUpdate();
    //
    //                 this.setState({
    //                     isOpenForm: true,
    //                     isLoadForm: this.props.isLoadForm,
    //                     isUpdateForm: this.props.isUpdateForm,
    //                     formData: responseData
    //                 });
    //             }
    //         }
    //
    //         else if (this.props.isUpdateForm !== this.state.isUpdateForm) {
    //             // console.log(4 + ' isUpdate');
    //             this.setState({
    //                 isUpdateForm: this.props.isUpdateForm
    //             });
    //             this.formUpdate(this.state.isLoadForm);
    //         }
    //     }
    // }
    //
    // formUpdate(isLoadForm) {
    //     this.props.apiRequest('/company/' + this.state.objectId + '/supports_date', null, () => {
    //         this.props.formLoad(isLoadForm);
    //     }, null);
    // }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    // setStateAndInput(name, value) {
    //
    //     let input = document.getElementById(name);
    //     input.value = value;
    //
    //     this.setState({
    //         [name]: value
    //     });
    // }

    handleSubmit(event) {
        event.preventDefault();

        if (this.state.groupName && this.state.initialYear) {

            let formData = new FormData();

            formData.append('group_name', this.state.groupName);
            formData.append('initial_year', this.state.initialYear);
            formData.append('training_program_id', this.state.trainingProgramId);
            formData.append('training_schedule_id', this.state.trainingScheduleId);
            formData.append('employee_id', this.state.employeeId);

            this.setState({
                isHandleSubmit: true
            });

            this.props.apiRequest('api/cabinet/training/group/add', formData, () => {
                    // this.setStateAndInput('', null);
                    this.setState({
                        isHandleSubmit: false,
                        formResponseError: null
                    });
                    // this.formUpdate(this.state.isLoadForm);
                    this.props.modalClose();
                    this.props.notificationMessage(this.props.responseMessage, 'green');
                    this.props.pageUpdate();
                }, () => {
                    this.setState({
                        isHandleSubmit: false,
                        formResponseError: this.props.responseError
                    });
                }
            );

        }

        else {
            this.setState({
                formResponseError: 'Внимательно заполните все обязательные поля ( * ).'
            });
        }
    }

    modalClose() {
        this.props.modalClose();
    }

    // alertOpen(alertForm, alertData) {
    //     this.props.alertOpen(alertForm, alertData);
    // }

    employeesData() {
        this.props.apiRequest('api/cabinet/employees', null, () => {
            this.setState({
                employeesData: this.props.responseData
            });
        }, null);
    }

    trainingProgramsData() {
        this.props.apiRequest('api/cabinet/training/plans', null, () => {
            this.setState({
                trainingProgramsData: this.props.responseData
            });
        }, null);
    }

    trainingSchedulesData() {
        this.props.apiRequest('api/cabinet/training/schedules', null, () => {
            this.setState({
                trainingSchedulesData: this.props.responseData
            });
        }, null);
    }

    render() {
        const {
            isMountForm,
            // isOpenForm,
            // formData,
            isHandleSubmit,
            formResponseError,

            trainingProgramId,
            trainingScheduleId,
            employeeId,
            employeesData,
            trainingProgramsData,
            trainingSchedulesData
        } = this.state;

        // let alertData = (objectId, objectData) => {
        //     return {
        //         objectId: objectId,
        //         objectData: objectData
        //     };
        // }

        if (isMountForm) {

            // {!isOpenForm ? (<Loading/>) : (
            //     formData ? () : null)}

            return (
                <div className="Form">
                    <form onSubmit={this.handleSubmit.bind(this)}>
                        <div className="Form__header">
                            <div className="Header__title">Новая группа *</div>
                        </div>
                        <div className="Form__content">

                            <div className="Content__hr Content__hr_bottom-32"/>

                            <div className="Content__box">
                                <div className="Form__field">
                                    <div className="Field__label">Группа *</div>
                                    <input className="Field__input"
                                           name="groupName"
                                           type="text"
                                           placeholder="Номер-$"
                                           value={this.state.value}
                                           onChange={this.handleInputChange.bind(this)}/>
                                </div>
                            </div>

                            <div className="Content__box">
                                <div className="Form__field">
                                    <div className="Field__label">Начало обучения *</div>
                                    <input className="Field__input"
                                           name="initialYear"
                                           type="number"
                                           placeholder="2020"
                                           value={this.state.value}
                                           onChange={this.handleInputChange.bind(this)}/>
                                </div>
                            </div>

                            <div className="Content__hr Content__hr_bottom-32"/>

                            <div className="Content__box">
                                <div className="Form__field">
                                    <div className="Field__label">Учебный план</div>
                                    <select className="Field__select"
                                            id="trainingProgramId"
                                            name="trainingProgramId"
                                            value={trainingProgramId}
                                            onChange={this.handleInputChange.bind(this)}>
                                        <option value="0">Не выбран</option>
                                        <option  className="option__hr" disabled />
                                        {!trainingProgramsData ? null : (
                                            trainingProgramsData.map((trainingProgram, index) => (
                                                <React.Fragment key={index}>
                                                    <option value={trainingProgram.id}>{trainingProgram.direction_preparation}</option>
                                                    <option className="option__info" disabled>{trainingProgram.training_qualifications} / {trainingProgram.form_training} / {trainingProgram.period_training}</option>
                                                    <option  className="option__hr" disabled />
                                                </React.Fragment>
                                            )))}
                                    </select>
                                </div>
                            </div>

                            <div className="Content__box">
                                <div className="Form__field">
                                    <div className="Field__label">Учебный график</div>
                                    <select className="Field__select"
                                            id="trainingScheduleId"
                                            name="trainingScheduleId"
                                            value={trainingScheduleId}
                                            onChange={this.handleInputChange.bind(this)}>
                                        <option value="0">Не выбран</option>
                                        <option  className="option__hr" disabled />
                                        {!trainingSchedulesData ? null : (
                                            trainingSchedulesData.map((trainingSchedule, index) => (
                                                <React.Fragment key={index}>
                                                    <option value={trainingSchedule.id}>{trainingSchedule.destination_schedule}</option>
                                                    <option className="option__info" disabled>{trainingSchedule.training_qualifications} / {trainingSchedule.form_training} / {trainingSchedule.period_training}</option>
                                                    <option  className="option__hr" disabled />
                                                </React.Fragment>
                                            )))}
                                    </select>
                                </div>
                            </div>

                            <div className="Content__box">
                                <div className="Form__field">
                                    <div className="Field__label">Куратор группы</div>
                                    <select className="Field__select"
                                            id="employeeId"
                                            name="employeeId"
                                            value={employeeId}
                                            onChange={this.handleInputChange.bind(this)}>
                                        <option value="0">Не выбран</option>
                                        <option  className="option__hr" disabled />
                                        {!employeesData ? null : (
                                            employeesData.map((employees) => (
                                                employees.array.map((employee, index) => (
                                                    <React.Fragment key={index}>
                                                        <option value={employee.id}>{employee.employee_fio}</option>
                                                        <option className="option__info" disabled>{employee.employee_email}</option>
                                                        <option  className="option__hr" disabled />
                                                    </React.Fragment>
                                                ))
                                            )))}
                                    </select>
                                </div>
                            </div>

                            <div className="Content__error">
                                <span className="Error__text">{formResponseError}</span>
                            </div>
                        </div>
                        <div className="Form__footer">
                            <button className={isHandleSubmit ? 'Form__button Form__button_blue Form__button_left Form__button_disabled' : 'Form__button Form__button_blue Form__button_left'}
                                    type="submit"
                                    style={{
                                        position: "relative"
                                    }}
                                    disabled={isHandleSubmit ? 'disabled' : null}>
                                Добавить
                                <div style={{position: "absolute", right: 6, top: 6}}>{isHandleSubmit ? <Loading white={true} /> : null}</div>
                            </button>
                        </div>
                    </form>
                </div>
            );
        }

        else return null;
    }
}

function mapStateToProps(state) {
    return {
        isLoadForm: state.form.isLoadForm,
        isUpdateForm: state.form.isUpdateForm,
        modalData: state.modal.modalData,
        isRequest: state.api.isRequest,
        responseMessage: state.api.responseMessage,
        responseData: state.api.responseData,
        responseError: state.api.responseError
    }
}

function mapDispatchToProps(dispatch) {
    return {
        formLoad: (isLoadForm) => dispatch(formLoad(isLoadForm)),
        pageUpdate: () => dispatch(pageUpdate()),
        notificationMessage: (message, colorMessage) => dispatch(notificationMessage(message, colorMessage)),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        alertOpen: (alertForm, alertData) => dispatch(alertOpen(alertForm, alertData)),
        modalClose: () => dispatch(modalClose())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalTrainingGroupAdd);
