import {PAGE_LOAD, PAGE_UNMOUNT, PAGE_UPDATE} from "./actionTypes";
import {modalClose} from "./modalAction";
import {alertClose} from "./alertAction";
import {profileClose} from "./profileAction";

export function pageLoad(isLoad) {

    isLoad = !isLoad;

    return {
        type: PAGE_LOAD,
        isLoad
    }
}

export function pageUpdate() {

    return {
        type: PAGE_UPDATE
    }
}

export function pageMount() {
    return dispatch => {
        dispatch(profileClose());
        dispatch(modalClose());
        dispatch(alertClose());
    }
}

export function pageUnmount() {

    return {
        type: PAGE_UNMOUNT
    }
}
