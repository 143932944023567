import React from "react";
import {connect} from "react-redux";
import {modalClose} from "../../../../../../store/actions/modalAction";
import {notificationMessage} from "../../../../../../store/actions/notificationAction";
import {apiRequest} from "../../../../../../store/actions/apiAction";
import {pageUpdate} from "../../../../../../store/actions/pageAction";
import {formLoad} from "../../../../../../store/actions/formAction";
import {alertOpen} from "../../../../../../store/actions/alertAction";
import Loading from "../../../../../loading/Loading";
import baseURL from "../../../../../../library/baseURL";

class ModalTeacherTrainingCourseStudents extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isMountForm: true,
            isOpenForm: false,
            isLoadForm: false,
            isUpdateForm: false,
            formData: null,
            isHandleSubmit: false,
            objectId: this.props.modalData.objectId,
            objectData: this.props.modalData.objectData,
            formResponseError: null,

            examinationReport: [],
            dataAssessment: null
        };
    }

    componentDidMount() {
        // this.formUpdate(this.state.isLoadForm);
        this.examinationReport();
    }

    componentWillUnmount() {
        // console.log('From Unmounted');
        this.setState({
            isMountForm: false
        });
    }

    // componentDidUpdate() {
    //     if (this.state.isMountForm) {
    //         const responseData = this.props.responseData;
    //         // console.log(1 + ' componentDidUpdate');
    //         if (this.props.isLoadForm !== this.state.isLoadForm) {
    //             // console.log(2 + ' isLoad');
    //             if (responseData !== this.state.formData) {
    //                 // console.log(3 + ' responseData');
    //
    //                 this.formUpdate();
    //
    //                 this.setState({
    //                     isOpenForm: true,
    //                     isLoadForm: this.props.isLoadForm,
    //                     isUpdateForm: this.props.isUpdateForm,
    //                     formData: responseData
    //                 });
    //             }
    //         }
    //
    //         else if (this.props.isUpdateForm !== this.state.isUpdateForm) {
    //             // console.log(4 + ' isUpdate');
    //             this.setState({
    //                 isUpdateForm: this.props.isUpdateForm
    //             });
    //             this.formUpdate(this.state.isLoadForm);
    //         }
    //     }
    // }

    // formUpdate(isLoadForm) {
    //     this.props.apiRequest('/company/' + this.state.objectId + '/supports_date', null, () => {
    //         this.props.formLoad(isLoadForm);
    //     }, null);
    // }



    setStateAndInput(name, value) {

        let input = document.getElementById(name);
        input.value = value;

        this.setState({
            [name]: value
        });
    }

    examinationReport() {
        let examinationReport = [];
        let objectData = this.state.objectData;

        objectData.students.map(student => {

            let object = {
                student_id: Number(student.id),
                student_fio: student.student_fio,
                control: {
                    exam: {
                        knowledge_assessment: 1
                    },
                    diff: {
                        knowledge_assessment: 1
                    },
                    test: {
                        knowledge_assessment: 1
                    },
                    project: {
                        project_title: '',
                        knowledge_assessment: 1
                    },
                    coursework: {
                        project_title: '',
                        knowledge_assessment: 1
                    }
                }
            }

            examinationReport.push(object);
        });


        this.setState({
            examinationReport: examinationReport
        });
    }

    handleSubmit(event) {
        event.preventDefault();
    //
    //     const dateRegular = /^\d{4}-\d{2}-\d{2}$/;
    //
    //     if (this.state.dataAssessment) {
    //
    //         if (dateRegular.test(this.state.dataAssessment)) {
    //
    //             const dataAssessment = new Date(this.state.dataAssessment).getTime() / 1000;
    //
    //             if (dataAssessment) {
    //
    //                 let formData = new FormData();
    //
    //                 let employeeId = this.state.objectData.employee.id;
    //                 let examinationReport = JSON.stringify(this.state.examinationReport);
    //
    //                 formData.append('employee_id', employeeId);
    //                 formData.append('examination_report', examinationReport);
    //                 formData.append('data_assessment', this.formattedData(dataAssessment));
    //
    //                 // console.log(employeeId);
    //                 // console.log(this.state.examinationReport);
    //                 // console.log(this.formattedData(dataAssessment));
    //
    //                 this.setState({
    //                     isHandleSubmit: true
    //                 });
    //
    //                 this.props.apiRequest('api/cabinet/teacher/training/course/' + this.state.objectId + '/finish', formData, () => {
    //                         // this.setStateAndInput('', null);
    //                         this.setState({
    //                             isHandleSubmit: false,
    //                             formResponseError: null
    //                         });
    //                         // this.formUpdate(this.state.isLoadForm);
    //                         this.props.modalClose();
    //                         this.props.notificationMessage(this.props.responseMessage, 'green');
    //                         this.props.pageUpdate();
    //                     }, () => {
    //                         this.setState({
    //                             isHandleSubmit: false,
    //                             formResponseError: this.props.responseError
    //                         });
    //                     }
    //                 );
    //             }
    //
    //             else {
    //                 this.setState({
    //                     formResponseError: 'Пожалуйста, укажите корректно месяц и день даты сдачи.'
    //                 });
    //             }
    //         }
    //
    //         else {
    //             this.setState({
    //                 formResponseError: 'Пожалуйста, заполните в правильном формате дату сдачи (2020-12-31).'
    //             });
    //         }
    //     }
    //
    //     else {
    //         this.setState({
    //             formResponseError: 'Пожалуйста, укажите дату сдачи.'
    //         });
    //     }
    }

    modalClose() {
        this.props.modalClose();
    }

    alertOpen(alertForm, alertData) {
        this.props.alertOpen(alertForm, alertData);
    }


    formattedData(time) {

        if (Number(time) > 0) {

            let date = new Date(time * 1000);
            let day = "0" + date.getDate();
            let month = "0" + (date.getMonth() + 1);
            let year = String(date.getFullYear());
            let hours = "0" + date.getHours();
            let minutes = "0" + date.getMinutes();
            let seconds = "0" + date.getSeconds();
            let formattedData = day.substr(-2) + '.' + month.substr(-2) + '.' + year;
            // let formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
            let formattedTime = hours.substr(-2) + ':' + minutes.substr(-2);

            return formattedData;
        }

        else {

            return null;
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleInputChangeExaminationReport(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let examinationReport = this.state.examinationReport;

        let studentId = Number(name.split('_')[0]);
        let controlType = String(name.split('_')[1]);
        let inputName = String(name.split('_')[2]);

        examinationReport.map(examinationReport => {
            if (examinationReport.student_id === studentId) {
                if (controlType === 'exam') {
                    if (inputName === 'knowledgeAssessment') {
                        examinationReport.control.exam.knowledge_assessment = Number(value);
                    }
                }
                else if (controlType === 'diff') {
                    if (inputName === 'knowledgeAssessment') {
                        examinationReport.control.diff.knowledge_assessment = Number(value);
                    }
                }
                else if (controlType === 'test') {
                    if (inputName === 'knowledgeAssessment') {
                        examinationReport.control.test.knowledge_assessment = Number(value);
                    }
                }
                else if (controlType === 'project') {
                    if (inputName === 'projectTitle') {
                        examinationReport.control.project.project_title = String(value);
                    }
                    else if (inputName === 'knowledgeAssessment') {
                        examinationReport.control.project.knowledge_assessment = Number(value);
                    }
                }
                else if (controlType === 'coursework') {
                    if (inputName === 'projectTitle') {
                        examinationReport.control.coursework.project_title = String(value);
                    }
                    else if (inputName === 'knowledgeAssessment') {
                        examinationReport.control.coursework.knowledge_assessment = Number(value);
                    }
                }
            }
        });

        this.setState({
            examinationReport: examinationReport
        });
    }

    render() {
        const { isOpenForm, isHandleSubmit, formResponseError, countNumber, countDate, formData, supportType, objectId, objectData, examinationReport } = this.state;

        let alertData = (objectId, objectData) => {
            return {
                objectId: objectId,
                objectData: objectData
            };
        }        // Переменная для отображения поступлений если есть.
        let supportArraySelected = false;

        let groupName = (groupName, initialYear) => {
            let course;

            let date = new Date();
            course = (date.getFullYear() - Number(initialYear) + 1);

            if (groupName.search('\$') > 0) {
                return groupName.replace(/\$/i, course);
            }

            else {
                return groupName;
            }
        }

        let telephone = (telephone) => {

            telephone.split('');

            return '+7 (' +
                telephone[1] +
                telephone[2] +
                telephone[3] +
                ') ' +
                telephone[4] +
                telephone[5] +
                telephone[6] +
                '-' +
                telephone[7] +
                telephone[8] +
                telephone[9] +
                telephone[10];
        }

        return (
            <div className="Form">
                <form onSubmit={this.handleSubmit.bind(this)}>
                    <div className="Form__header">
                        <div className="Header__title">{groupName(this.state.objectData.group_name, this.state.objectData.initial_year)}</div>
                    </div>
                    <div className="Form__content">

                        <div className="Content__hr Content__hr_bottom-32"/>


                        {this.state.objectData.students.map((student, index) => (
                            <React.Fragment key={index}>

                                {/*<div className="Box">*/}
                                {/*    <div className="Box__title" style={{fontSize: 24, marginBottom: 24}}>*/}
                                {/*        {student.student_fio}*/}
                                {/*    </div>*/}
                                {/*</div>*/}

                                <div style={{marginBottom: 24}}>
                                    {student.student_avatar ? (
                                        <div style={{
                                            marginRight: 16,
                                            verticalAlign: "top",
                                            position: "relative",
                                            display: "inline-block"
                                        }}>
                                            <div className="Student__photo1">
                                                {/*<svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">*/}
                                                {/*    <image id="baseline_account_circle_black_36dp" width="42" height="42" opacity="0.1" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAABtUlEQVR4Ae3WAWQCURjA8SGEEIZDGIZDCMMQxjAMIUAIAwwQBghhOBxggBCGw2GAACGEMAwhHA4hhBC+/RHort537y5Bf36Azuu9d+/dzZm61oSHEFMs9yYIMEADZ+0WPlYQpQh9VFBYJfSxhliK8Y7cOZhCCvKTZ7ZcRJCC/cKxmZkYorBAgBCRflD6mSpjBjHYoJuy33rYqZZPmQdRaONYPYiZeaPXsIUYTE1vpnL5YtPSDSEKPkyFEIU+UqtgC1HwYOpbf3im14IojWFqAVFqINEIksETjtWFZDBAopnFdZA2qDY2kAwCJIogFsbw4Oe4ZiZIJBe0vOCA9G9aDLmQKRLNM675J1qo426vgTb8jM8LkSiAnLCDj3toa2Boe9B2DK/4A2x7MXwBNJGoeuJHr8jbFyTFyuZCrCNvzzYXtXtklv7gwDYHS8iBNW5B2T9B5qgha3Us9J8eyaonHrDGG0owVcYHtifOnhJUuYYLcokBHlE5+DNP8A0HbWSzBZqGQdmK4cIqFwtIQWZwkKsqhthBLG3hoYzCchFil3EgQ9RwtqroIMAc8cFmnWGEFiootGv/gzxqH8WJhToAAAAASUVORK5CYII="/>*/}
                                                {/*</svg>*/}

                                                <svg xmlns="http://www.w3.org/2000/svg" id="account_circle-black-18dp" width="42" height="42" viewBox="0 0 100 100">
                                                    <path id="Path_1" data-name="Path 1" d="M0,0H100V100H0Z" fill="none"/>
                                                    <path opacity="0.1" id="Path_2" data-name="Path 2" d="M50,2A48,48,0,1,0,98,50,48.018,48.018,0,0,0,50,2Zm0,14.4A14.4,14.4,0,1,1,35.6,30.8,14.381,14.381,0,0,1,50,16.4Zm0,68.16A34.563,34.563,0,0,1,21.2,69.1C21.344,59.552,40.4,54.32,50,54.32c9.552,0,28.656,5.232,28.8,14.784A34.563,34.563,0,0,1,50,84.56Z" fill="#000"/>
                                                </svg>
                                            </div>
                                            <img style={{
                                                position: "absolute",
                                                top: 0,
                                                left: 0,
                                                width: 42,
                                                height: 42,
                                                borderRadius: "50%",
                                                opacity: 1
                                            }}
                                                 src={baseURL() + 'api/images/avatar/' + student.student_avatar}
                                                 width="100"
                                                 height="100"/>
                                        </div>
                                    ) : (
                                        <div style={{
                                            marginRight: 16,
                                            verticalAlign: "top",
                                            position: "relative",
                                            display: "inline-block"
                                        }}>
                                            <div className="Student__photo1">
                                                {/*<svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">*/}
                                                {/*    <image id="baseline_account_circle_black_36dp" width="42" height="42" opacity="0.1" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAABtUlEQVR4Ae3WAWQCURjA8SGEEIZDGIZDCMMQxjAMIUAIAwwQBghhOBxggBCGw2GAACGEMAwhHA4hhBC+/RHort537y5Bf36Azuu9d+/dzZm61oSHEFMs9yYIMEADZ+0WPlYQpQh9VFBYJfSxhliK8Y7cOZhCCvKTZ7ZcRJCC/cKxmZkYorBAgBCRflD6mSpjBjHYoJuy33rYqZZPmQdRaONYPYiZeaPXsIUYTE1vpnL5YtPSDSEKPkyFEIU+UqtgC1HwYOpbf3im14IojWFqAVFqINEIksETjtWFZDBAopnFdZA2qDY2kAwCJIogFsbw4Oe4ZiZIJBe0vOCA9G9aDLmQKRLNM675J1qo426vgTb8jM8LkSiAnLCDj3toa2Boe9B2DK/4A2x7MXwBNJGoeuJHr8jbFyTFyuZCrCNvzzYXtXtklv7gwDYHS8iBNW5B2T9B5qgha3Us9J8eyaonHrDGG0owVcYHtifOnhJUuYYLcokBHlE5+DNP8A0HbWSzBZqGQdmK4cIqFwtIQWZwkKsqhthBLG3hoYzCchFil3EgQ9RwtqroIMAc8cFmnWGEFiootGv/gzxqH8WJhToAAAAASUVORK5CYII="/>*/}
                                                {/*</svg>*/}

                                                <svg xmlns="http://www.w3.org/2000/svg" id="account_circle-black-18dp" width="42" height="42" viewBox="0 0 100 100">
                                                    <path id="Path_1" data-name="Path 1" d="M0,0H100V100H0Z" fill="none"/>
                                                    <path opacity="0.1" id="Path_2" data-name="Path 2" d="M50,2A48,48,0,1,0,98,50,48.018,48.018,0,0,0,50,2Zm0,14.4A14.4,14.4,0,1,1,35.6,30.8,14.381,14.381,0,0,1,50,16.4Zm0,68.16A34.563,34.563,0,0,1,21.2,69.1C21.344,59.552,40.4,54.32,50,54.32c9.552,0,28.656,5.232,28.8,14.784A34.563,34.563,0,0,1,50,84.56Z" fill="#000"/>
                                                </svg>
                                            </div>
                                        </div>
                                    )}

                                    <div style={{
                                        display: "inline-block",
                                        verticalAlign: "top"
                                    }}>
                                        <div className="Info__title" style={{
                                            display: "inline-block",
                                            // opacity: 0.75,
                                            fontSize: 15, fontWeight: 700,
                                            marginBottom: 6,
                                            marginRight: 16,
                                            color: "#2A3E4C"}}>
                                            {student.student_fio}
                                        </div>
                                        <br/>
                                        <div className="Title__date" style={{
                                            display: "inline-block",
                                            opacity: 0.75,
                                            marginBottom: 0,
                                            fontWeight: 400,
                                            fontSize: 13,
                                            color: "#2A3E4C"
                                        }}>
                                            {student.student_telephone ? telephone(student.student_telephone) + ' / ' : null}{student.student_email}
                                        </div>
                                    </div>

                                </div>


                                {/*<div className="Content__hr Content__hr_bottom-32"/>*/}
                            </React.Fragment>
                        ))}



                    </div>
                    <div className="Form__footer">
                        <div onClick={this.modalClose.bind(this)} className={isHandleSubmit ? 'Form__button Form__button_gray Form__button_left Form__button_disabled' : 'Form__button Form__button_gray Form__button_left'}
                                type="submit"
                                style={{
                                    position: "relative",
                                    backgroundColor: "#919ba2",
                                    color: "white"
                                }}
                                disabled={isHandleSubmit ? 'disabled' : null}>
                            Закрыть
                            <div style={{position: "absolute", right: 6, top: 6}}>{isHandleSubmit ? <Loading white={true} /> : null}</div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        isLoadForm: state.form.isLoadForm,
        isUpdateForm: state.form.isUpdateForm,
        modalData: state.modal.modalData,
        isRequest: state.api.isRequest,
        responseMessage: state.api.responseMessage,
        responseData: state.api.responseData,
        responseError: state.api.responseError
    }
}

function mapDispatchToProps(dispatch) {
    return {
        formLoad: (isLoadForm) => dispatch(formLoad(isLoadForm)),
        pageUpdate: () => dispatch(pageUpdate()),
        notificationMessage: (message, colorMessage) => dispatch(notificationMessage(message, colorMessage)),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        alertOpen: (alertForm, alertData) => dispatch(alertOpen(alertForm, alertData)),
        modalClose: () => dispatch(modalClose())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalTeacherTrainingCourseStudents);
