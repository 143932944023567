import React from "react";
import {connect} from "react-redux";
import {alertClose} from "../../store/actions/alertAction";
import "./Alert.scss";
import "../form/Form.scss";
import AlertStudentAvatarDelete from "../page/students/alert/alertStudentAvatarDelete";
import AlertProfileAvatarDelete from "../profile/alert/alertProfileAvatarDelete";
import AlertTrainingGroupSendSuggestions from "../page/students/alert/alertTrainingGroupSendSuggestions";
import AlertTeacherTrainingCourseDelete from "../page/teacher/training/course/alert/alertTeacherTrainingCourseDelete";
import AlertTeacherTrainingCourseLessonDelete
    from "../page/teacher/training/course/alert/alertTeacherTrainingCourseLessonDelete";
import AlertStudentChatDelete from "../page/student/messages/alert/alertStudentChatDelete";
import AlertTeacherChatDelete from "../page/teacher/messages/alert/alertTeacherChatDelete";
import AlertStudentDelete from "../page/students/alert/alertStudentDelete";
import AlertTeacherDelete from "../page/employees/alert/alertTeacherDelete";
import AlertStudentTransfer from "../page/students/alert/alertStudentTransfer";
import AlertStudentFeedbackDelete from "../page/student/feedback/alert/alertStudentFeedbackDelete";
import AlertFeedbackDelete from "../page/feedbacks/alert/alertFeedbackDelete";
import AlertStudentGradebook from "../page/students/alert/alertStudentGradebook";
import AlertTeacherTrainingCourseAnnul from "../page/employees/alert/alertTeacherTrainingCourseAnnul";
import AlertTeacherTrainingCourseExamDelete
    from "../page/teacher/training/course/alert/alertTeacherTrainingCourseExamDelete";

class Alert extends React.Component {

    alertClose() {
        this.props.alertClose();
    }

    switchForm(alertForm) {
        switch(alertForm) {
            case 'alertProfileAvatarDelete': return (<AlertProfileAvatarDelete/>);
            case 'alertStudentAvatarDelete': return (<AlertStudentAvatarDelete/>);
            case 'alertTrainingGroupSendSuggestions': return (<AlertTrainingGroupSendSuggestions/>);
            case 'alertTeacherTrainingCourseDelete': return (<AlertTeacherTrainingCourseDelete/>);
            case 'alertTeacherTrainingCourseLessonDelete': return (<AlertTeacherTrainingCourseLessonDelete/>);
            case 'alertStudentChatDelete': return (<AlertStudentChatDelete/>)
            case 'alertTeacherChatDelete': return (<AlertTeacherChatDelete/>);
            case 'alertStudentDelete': return (<AlertStudentDelete/>);
            case 'alertTeacherDelete': return (<AlertTeacherDelete/>);
            case 'alertStudentTransfer': return (<AlertStudentTransfer/>);
            case 'alertStudentFeedbackDelete': return (<AlertStudentFeedbackDelete/>);
            case 'alertFeedbackDelete': return (<AlertFeedbackDelete/>);
            case 'alertStudentGradeBook': return (<AlertStudentGradebook/>);
            case 'alertTeacherTrainingCourseAnnul': return (<AlertTeacherTrainingCourseAnnul/>);
            case 'alertTeacherTrainingCourseExamDelete': return (<AlertTeacherTrainingCourseExamDelete/>);
            default: return (<div className="Form Form_null" />);
        }
    }

    render() {
        const { isAlert, alertForm } = this.props;

        let switchForm = this.switchForm(alertForm);

        if (!isAlert) { return null; }

        else {
            return (
                <div className="Alert">
                    <i/>
                    <div className="Alert__dialog">
                        <div className="Alert__content">
                            <div className="Alert__body">
                                {switchForm}
                            </div>
                            <div className="Alert__close">
                                <div className="Button__close icon-close" onClick={this.alertClose.bind(this, false, null)}/>
                            </div>
                        </div>
                    </div>
                    <i/>
                </div>
            );
        }
    }
}

function mapStateToProps(state) {
    return {
        isAlert: state.alert.isAlert,
        alertForm: state.alert.alertForm,
        alertData: state.alert.alertData
    }
}

function mapDispatchToProps(dispatch) {
    return {
        alertClose: () => dispatch(alertClose())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Alert);
