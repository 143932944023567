import axios from "axios";
import {RECOVERY_ERROR, RECOVERY_REQUEST} from "./actionTypes";
import baseURL from "../../library/baseURL";

export function authRecovery(formData, callbackSuccess, callbackError) {
    return async dispatch => {
        dispatch(recoveryRequest());

        try {
            const response = await axios.post('api/recovery', formData, {baseURL: baseURL(), headers: {'Content-Type': 'multipart/form-data'}});

            const data = response.data;

            callbackSuccess();
        }

        catch (error) {
            dispatch(recoveryError('Неверный логин / адрес эл. почты'));

            callbackError();
        }
    }
}

export function recoveryRequest() {
    // console.log('Recovery request start.');

    return {
        type: RECOVERY_REQUEST
    }
}

export function recoveryError(recoveryError) {
    // console.log('Recovery request error.');

    return {
        type: RECOVERY_ERROR,
        recoveryError
    }
}
