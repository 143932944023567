import {API_REQUEST_ERROR, API_REQUEST, API_RESPONSE, API_RESPONSE_ERROR, API_UNMOUNT} from "../actions/actionTypes";

const initialState = {
    isRequest: true,
    responseMessage: null,
    responseData: null,
    responseError: null,
    apiResponseError: null,
    apiRequestError: null
}

export default function apiReducer(state = initialState, action) {
    switch (action.type) {
        case API_REQUEST:
            return {
                ...state, isRequest: true, responseMessage: null, responseData: null, responseError: null, apiResponseError: null
            }
        case API_RESPONSE:
            return {
                ...state, isRequest: false, responseMessage: action.responseMessage, responseData: action.responseData, responseError: action.responseError
            }
        case API_RESPONSE_ERROR:
            return {
                ...state, isRequest: false, apiResponseError: action.apiResponseError
            }
        case API_REQUEST_ERROR:
            return {
                ...state, isRequest: false, apiRequestError: action.apiRequestError
            }
        case API_UNMOUNT:
            return {
                ...state, isRequest: false, responseMessage: null, responseData: null, responseError: null, apiResponseError: null
            }
        default:
            return state;
    }
}
