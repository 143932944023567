import React from "react";
import {connect} from "react-redux";
import {alertClose} from "../../../../store/actions/alertAction";
import {apiRequest} from "../../../../store/actions/apiAction";
import {modalClose} from "../../../../store/actions/modalAction";
import Loading from "../../../loading/Loading";
import {profileClose, profileUpdate} from "../../../../store/actions/profileAction";
import {formUpdate} from "../../../../store/actions/formAction";
import {notificationMessage} from "../../../../store/actions/notificationAction";
import {pageUpdate} from "../../../../store/actions/pageAction";

class AlertStudentGradebook extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isMountForm: true,
            objectId: this.props.alertData.objectId,
            objectType: null,
            objectData: this.props.alertData.objectData,
            isHandleSubmit: false,
            alertResponseError: null,

            employeesData: null,
            employeeId: 0,

            knowledgeAssessment: 0,
            dataAssessment: null,
            projectTitle: null,

        };
    }

    componentDidMount() {
        // this.formUpdate(this.state.isLoadForm);

        this.employeesData();
    }

    handleSubmit(event) {
        event.preventDefault();

        // if (this.state.trainingGroupId && (Number(this.state.trainingGroupId) > 0) && this.state.dateEnrollment) {
        //
        //     let formData = new FormData();
        //     formData.append('training_group_id', this.state.trainingGroupId);
        //     formData.append('date_enrollment', this.state.dateEnrollment);
        //
        //
        //     this.setState({
        //         isHandleSubmit: true
        //     });
        //
        //     this.props.apiRequest('api/cabinet/student/' + this.state.objectId + '/transfer', formData, () => {
        //             this.setState({
        //                 isHandleSubmit: false,
        //                 alertResponseError: null
        //             });
        //             this.props.alertClose();
        //             this.props.notificationMessage(this.props.responseMessage, 'green');
        //             // this.props.formUpdate();
        //             this.props.pageUpdate();
        //             this.props.modalClose();
        //         }, () => {
        //             this.setState({
        //                 isHandleSubmit: false,
        //                 alertResponseError: this.props.responseError
        //             });
        //         }
        //     );
        //
        // }
        //
        // else {
        //     this.setState({
        //         alertResponseError: 'Пожалуйста, внимательно заполните все поля.'
        //     });
        // }

        this.props.alertClose();
    }

    alertClose() {
        this.props.alertClose();
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    employeesData() {
        this.props.apiRequest('api/cabinet/employees', null, () => {
            this.setState({
                employeesData: this.props.responseData
            });
        }, null);
    }


    controlTestAdd() {
        if (Number(this.state.knowledgeAssessment) === 0) {

            if (Number(this.state.employeeId) === 0) {
                this.props.notificationMessage('Укажите преподавателя для бегунка', 'red');
                return;
            }
        }

        this.setState({
            isHandleSubmit: true
        });

        let formData = new FormData();
        formData.append('control_type', 'test');
        formData.append('project_title', null);
        formData.append('knowledge_assessment', this.state.knowledgeAssessment);
        formData.append('data_assessment', this.state.dataAssessment);
        formData.append('employee_id', this.state.employeeId);

        this.props.apiRequest('api/cabinet/student/' + this.state.objectId + '/discipline/' + this.state.objectData.id + '/control/add', formData, () => {
                this.setState({
                    isHandleSubmit: false,
                    alertResponseError: null
                });
                this.props.alertClose();
                this.props.notificationMessage(this.props.responseMessage, 'green');
                this.props.formUpdate();
                this.props.pageUpdate();
                // this.props.modalClose();
            }, () => {
                this.setState({
                    isHandleSubmit: false,
                    alertResponseError: this.props.responseError
                });
            }
        );
    }

    controlDiffAdd() {
        if (Number(this.state.knowledgeAssessment) === 0) {

            if (Number(this.state.employeeId) === 0) {
                this.props.notificationMessage('Укажите преподавателя для бегунка', 'red');
                return;
            }
        }

        this.setState({
            isHandleSubmit: true
        });

        let formData = new FormData();
        formData.append('control_type', 'diff');
        formData.append('project_title', null);
        formData.append('knowledge_assessment', this.state.knowledgeAssessment);
        formData.append('data_assessment', this.state.dataAssessment);
        formData.append('employee_id', this.state.employeeId);

        this.props.apiRequest('api/cabinet/student/' + this.state.objectId + '/discipline/' + this.state.objectData.id + '/control/add', formData, () => {
                this.setState({
                    isHandleSubmit: false,
                    alertResponseError: null
                });
                this.props.alertClose();
                this.props.notificationMessage(this.props.responseMessage, 'green');
                this.props.formUpdate();
                this.props.pageUpdate();
                // this.props.modalClose();
            }, () => {
                this.setState({
                    isHandleSubmit: false,
                    alertResponseError: this.props.responseError
                });
            }
        );
    }

    controlExamAdd() {
        if (Number(this.state.knowledgeAssessment) === 0) {

            if (Number(this.state.employeeId) === 0) {
                this.props.notificationMessage('Укажите преподавателя для бегунка', 'red');
                return;
            }
        }

        this.setState({
            isHandleSubmit: true
        });

        let formData = new FormData();
        formData.append('control_type', 'exam');
        formData.append('project_title', null);
        formData.append('knowledge_assessment', this.state.knowledgeAssessment);
        formData.append('data_assessment', this.state.dataAssessment);
        formData.append('employee_id', this.state.employeeId);

        this.props.apiRequest('api/cabinet/student/' + this.state.objectId + '/discipline/' + this.state.objectData.id + '/control/add', formData, () => {
                this.setState({
                    isHandleSubmit: false,
                    alertResponseError: null
                });
                this.props.alertClose();
                this.props.notificationMessage(this.props.responseMessage, 'green');
                this.props.formUpdate();
                this.props.pageUpdate();
                // this.props.modalClose();
            }, () => {
                this.setState({
                    isHandleSubmit: false,
                    alertResponseError: this.props.responseError
                });
            }
        );
    }

    controlProjectAdd() {
        if (Number(this.state.knowledgeAssessment) === 0) {

            if (Number(this.state.employeeId) === 0) {
                this.props.notificationMessage('Укажите преподавателя для бегунка', 'red');
                return;
            }
        }

        this.setState({
            isHandleSubmit: true
        });

        let formData = new FormData();
        formData.append('control_type', 'project');
        formData.append('project_title', this.state.projectTitle);
        formData.append('knowledge_assessment', this.state.knowledgeAssessment);
        formData.append('data_assessment', this.state.dataAssessment);
        formData.append('employee_id', this.state.employeeId);

        this.props.apiRequest('api/cabinet/student/' + this.state.objectId + '/discipline/' + this.state.objectData.id + '/control/add', formData, () => {
                this.setState({
                    isHandleSubmit: false,
                    alertResponseError: null
                });
                this.props.alertClose();
                this.props.notificationMessage(this.props.responseMessage, 'green');
                this.props.formUpdate();
                this.props.pageUpdate();
                // this.props.modalClose();
            }, () => {
                this.setState({
                    isHandleSubmit: false,
                    alertResponseError: this.props.responseError
                });
            }
        );
    }

    controlCourseworkAdd() {
        if (Number(this.state.knowledgeAssessment) === 0) {

            if (Number(this.state.employeeId) === 0) {
                this.props.notificationMessage('Укажите преподавателя для бегунка', 'red');
                return;
            }
        }

        this.setState({
            isHandleSubmit: true
        });

        let formData = new FormData();
        formData.append('control_type', 'coursework');
        formData.append('project_title', this.state.projectTitle);
        formData.append('knowledge_assessment', this.state.knowledgeAssessment);
        formData.append('data_assessment', this.state.dataAssessment);
        formData.append('employee_id', this.state.employeeId);

        this.props.apiRequest('api/cabinet/student/' + this.state.objectId + '/discipline/' + this.state.objectData.id + '/control/add', formData, () => {
                this.setState({
                    isHandleSubmit: false,
                    alertResponseError: null
                });
                this.props.alertClose();
                this.props.notificationMessage(this.props.responseMessage, 'green');
                this.props.formUpdate();
                this.props.pageUpdate();
                // this.props.modalClose();
            }, () => {
                this.setState({
                    isHandleSubmit: false,
                    alertResponseError: this.props.responseError
                });
            }
        );
    }

    controlDelete(id) {
        this.setState({
            isHandleSubmit: true
        });

        // let formData = new FormData();
        // formData.append('control_type', 'test');
        // formData.append('project_title', null);
        // formData.append('knowledge_assessment', this.state.knowledgeAssessment);
        // formData.append('data_assessment', this.state.dataAssessment);
        // formData.append('employee_id', this.state.employeeId);

        this.props.apiRequest('api/cabinet/student/' + this.state.objectId + '/discipline/' + this.state.objectData.id + '/control/' + id + '/delete', null, () => {
                this.setState({
                    isHandleSubmit: false,
                    alertResponseError: null
                });
                this.props.alertClose();
                this.props.notificationMessage(this.props.responseMessage, 'green');
                this.props.formUpdate();
                this.props.pageUpdate();
                // this.props.modalClose();
            }, () => {
                this.setState({
                    isHandleSubmit: false,
                    alertResponseError: this.props.responseError
                });
            }
        );
    }

    disciplineChoiceAdd() {
        this.props.apiRequest('api/cabinet/student/' + this.state.objectId + '/discipline/' + this.state.objectData.id + '/choice/add', null, () => {
                this.setState({
                    isHandleSubmit: false,
                    alertResponseError: null
                });
                this.props.alertClose();
                this.props.notificationMessage(this.props.responseMessage, 'green');
                this.props.formUpdate();
                this.props.pageUpdate();
                // this.props.modalClose();
            }, () => {
                this.setState({
                    isHandleSubmit: false,
                    alertResponseError: this.props.responseError
                });
            }
        );
    }

    disciplineChoiceDelete() {
        this.props.apiRequest('api/cabinet/student/' + this.state.objectId + '/discipline/' + this.state.objectData.id + '/choice/delete', null, () => {
                this.setState({
                    isHandleSubmit: false,
                    alertResponseError: null
                });
                this.props.alertClose();
                this.props.notificationMessage(this.props.responseMessage, 'green');
                this.props.formUpdate();
                this.props.pageUpdate();
                // this.props.modalClose();
            }, () => {
                this.setState({
                    isHandleSubmit: false,
                    alertResponseError: this.props.responseError
                });
            }
        );
    }

    render() {
        const { isMountForm, isHandleSubmit, alertResponseError, trainingCoursesCurrentData, trainingGroupId, objectData,

            isTrue,

            employeeId,
            employeesData,

            knowledgeAssessment
        } = this.state;


        let controlTest = false;
        let controlTestTeacher = false;

        let controlDiff = false;
        let controlDiffTeacher = false;

        let controlExam = false;
        let controlExamTeacher = false;

        let controlProject = false;
        let controlProjectTeacher = false;


        let controlCoursework = false;
        let controlCourseworkTeacher = false;


        if (isMountForm) {

            // {!isOpenForm ? (<Loading/>) : (
            //     formData ? () : null)}

            return (
                <div className="Form" style={{width: 340}}>
                    <div onSubmit={this.handleSubmit.bind(this)}>
                        <div className="Form__header">
                            <div className="Header__title" style={{fontSize: 24, fontWeight: 900}}>{objectData.discipline_name}</div>

                            {/*{isTrue ? (*/}
                            {/*    <div className="Item__index" style={{*/}
                            {/*        marginTop: 12,*/}
                            {/*        display: "inline-block",*/}
                            {/*        backgroundColor: "#43a047",*/}
                            {/*        // backgroundColor: "#e53935",*/}
                            {/*        textTransform: "uppercase",*/}
                            {/*        // isPassed Не сдан*/}
                            {/*        fontFamily: "Roboto, sans-serif",*/}
                            {/*        fontSize: 15,*/}
                            {/*        fontWeight: 700,*/}
                            {/*        backgroundСolor: "#2A3E4C",*/}
                            {/*        color: "white",*/}
                            {/*        padding: "4px 6px 3px",*/}
                            {/*        borderRadius: 4*/}
                            {/*    }}>Сдан </div>*/}
                            {/*) : null}*/}
                        </div>











                            <div className="Form__content" style={{
                                // marginBottom: 42
                            }}>

                                <div className="Content__hr Content__hr_bottom-32"/>



                                <div className="Box" style={{marginBottom: 24}}>
                                    <div className="Box__title" style={{
                                        fontFamily: "Roboto, sans-serif",
                                        fontWeight: 700,
                                        fontSize: 20,
                                        color: "#2A3E4C"
                                    }}>Обучение</div>
                                </div>


                                <div className="Box" style={{marginBottom: 24}}>
                                    <div className="Box__label" style={{
                                        fontFamily: "Roboto, sans-serif",
                                        fontWeight: 700,
                                        fontSize: 16,
                                        color: "#949ea5",
                                        marginBottom: 16
                                    }}>Часы</div>
                                    <div className="Box__text" style={{
                                        fontFamily: "Roboto, sans-serif",
                                        fontWeight: 400,
                                        fontSize: 16,
                                        color: "#2A3E4C"
                                    }}>{Number(objectData.lecture_hours) + Number(objectData.laboratory_hours) + Number(objectData.practical_hours) + Number(objectData.ciw_hours) + Number(objectData.iws_hours)}</div>
                                </div>

                                <div className="Box" style={{marginBottom: 24}}>
                                    <div className="Box__label" style={{
                                        fontFamily: "Roboto, sans-serif",
                                        fontWeight: 700,
                                        fontSize: 16,
                                        color: "#949ea5",
                                        marginBottom: 16
                                    }}>Зач. ед.</div>
                                    <div className="Box__text" style={{
                                        fontFamily: "Roboto, sans-serif",
                                        fontWeight: 400,
                                        fontSize: 16,
                                        color: "#2A3E4C"
                                    }}>{objectData.credit_unit}</div>
                                </div>

                                <div className="Content__hr Content__hr_bottom-32"/>



                                {Number(objectData.control_exam) > 0 ? (

                                    objectData.academic_performance.map(academic_performance => (
                                        academic_performance.control_type === 'exam' ? (controlExam = true) : null
                                    )),

                                        controlExam ? (

                                            objectData.academic_performance.map((academic_performance, index) => (
                                                academic_performance.control_type === 'exam' ? (


                                                    <React.Fragment key={index}>

                                                        <div className="Box" style={{marginBottom: 24}}>
                                                            <div className="Box__title" style={{
                                                                fontFamily: "Roboto, sans-serif",
                                                                fontWeight: 700,
                                                                fontSize: 20,
                                                                color: "#2A3E4C"
                                                            }}>Экзамен</div>
                                                        </div>

                                                        <div className="Box" style={{marginBottom: 24}}>
                                                            <div className="Box__label" style={{
                                                                fontFamily: "Roboto, sans-serif",
                                                                fontWeight: 700,
                                                                fontSize: 16,
                                                                color: "#949ea5",
                                                                marginBottom: 16
                                                            }}>Оценка</div>
                                                            <div className="Box__text" style={{
                                                                fontFamily: "Roboto, sans-serif",
                                                                fontWeight: 400,
                                                                fontSize: 16,
                                                                color: "#2A3E4C"
                                                            }}>

                                                                {Number(academic_performance.knowledge_assessment) === 0 ? 'Ведомость' : null}
                                                                {Number(academic_performance.knowledge_assessment) === 1 ? 'Не явка' : null}
                                                                {Number(academic_performance.knowledge_assessment) > 1 ? academic_performance.knowledge_assessment : null}

                                                            </div>
                                                        </div>

                                                        <div className="Box" style={{marginBottom: 24}}>
                                                            <div className="Box__label" style={{
                                                                fontFamily: "Roboto, sans-serif",
                                                                fontWeight: 700,
                                                                fontSize: 16,
                                                                color: "#949ea5",
                                                                marginBottom: 16
                                                            }}>Дата сдачи</div>
                                                            <div className="Box__text" style={{
                                                                fontFamily: "Roboto, sans-serif",
                                                                fontWeight: 400,
                                                                fontSize: 16,
                                                                color: "#2A3E4C"
                                                            }}>
                                                                {!academic_performance.data_assessment ? '-' : (
                                                                    academic_performance.data_assessment === "null" ? '-' : (
                                                                        academic_performance.data_assessment === "" ? '-' : academic_performance.data_assessment
                                                                    )
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="Box" style={{
                                                            marginBottom: 24
                                                        }}>
                                                            <div className="Box__label" style={{
                                                                fontFamily: "Roboto, sans-serif",
                                                                fontWeight: 700,
                                                                fontSize: 16,
                                                                color: "#949ea5",
                                                                marginBottom: 16
                                                            }}>Преподаватель</div>
                                                            <div className="Box__text" style={{
                                                                fontFamily: "Roboto, sans-serif",
                                                                fontWeight: 400,
                                                                fontSize: 16,
                                                                color: "#2A3E4C"
                                                            }}>

                                                                {!employeesData ? null : (
                                                                    employeesData.map((employees) => (
                                                                        employees.array.map((employee, index) => (
                                                                            Number(academic_performance.employee_id) === Number(employee.id) ? controlExamTeacher = true : null,
                                                                                Number(academic_performance.employee_id) === Number(employee.id) ? employee.employee_fio : null
                                                                        ))
                                                                    )))}

                                                                {controlExamTeacher ? null : '-'}

                                                            </div>
                                                        </div>

                                                        <div className="Form__footer">
                                                            <button className={isHandleSubmit ? 'Form__button Form__button_red Form__button_left Form__button_disabled' : 'Form__button Form__button_red Form__button_left'}
                                                                    type="submit"
                                                                    disabled={isHandleSubmit ? 'disabled' : null}
                                                                    onClick={this.controlDelete.bind(this, academic_performance.id)}
                                                                    style={{position: "relative"}}>
                                                                Аннулировать
                                                                <div style={{position: "absolute", right: 6, top: 6}}>{isHandleSubmit ? <Loading white={true} /> : null}</div>
                                                            </button>
                                                        </div>
                                                    </React.Fragment>


                                                ) : null
                                            ))


                                        ) : (

                                            <React.Fragment>

                                                <div className="Box" style={{marginBottom: 24}}>
                                                    <div className="Box__title" style={{
                                                        fontFamily: "Roboto, sans-serif",
                                                        fontWeight: 700,
                                                        fontSize: 20,
                                                        color: "#2A3E4C"
                                                    }}>Экзамен</div>
                                                </div>



                                                <div className="Content__box">
                                                    <div className="Form__field">
                                                        <div className="Field__label">Оценка</div>

                                                        <input className="Field__input"
                                                               name="knowledgeAssessment"
                                                               type="number"
                                                               style={{
                                                                   width: 304
                                                               }}
                                                               max={5}
                                                               min={0}
                                                               step={1}
                                                               defaultValue={0}
                                                               value={this.state.value}
                                                               onChange={this.handleInputChange.bind(this)}/>
                                                    </div>
                                                </div>

                                                <div className="Content__box">
                                                    <div className="Form__field">
                                                        <div className="Field__label">Дата сдачи</div>
                                                        <input className="Field__input"
                                                               name="dataAssessment"
                                                               type="text"
                                                               style={{
                                                                   width: 304
                                                               }}
                                                               placeholder="дд.мм.гггг"
                                                               value={this.state.value}
                                                               onChange={this.handleInputChange.bind(this)}/>
                                                    </div>
                                                </div>

                                                <div className="Content__box">
                                                    <div className="Form__field">
                                                        <div className="Field__label">Преподаватель</div>
                                                        <select className="Field__select"
                                                                id="employeeId"
                                                                name="employeeId"
                                                                value={employeeId}
                                                                style={{
                                                                    width: 340
                                                                }}
                                                                onChange={this.handleInputChange.bind(this)}>
                                                            <option value="0">Не выбран</option>
                                                            <option  className="option__hr" disabled />
                                                            {!employeesData ? null : (
                                                                employeesData.map((employees) => (
                                                                    employees.array.map((employee, index) => (
                                                                        <React.Fragment key={index}>
                                                                            <option value={employee.id}>{employee.employee_fio}</option>
                                                                            <option className="option__info" disabled>{employee.employee_email}</option>
                                                                            <option  className="option__hr" disabled />
                                                                        </React.Fragment>
                                                                    ))
                                                                )))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="Form__footer">
                                                    <button className={isHandleSubmit ? 'Form__button Form__button_blue Form__button_left Form__button_disabled' : 'Form__button Form__button_blue Form__button_left'}
                                                            type="submit"
                                                            disabled={isHandleSubmit ? 'disabled' : null}
                                                            onClick={this.controlExamAdd.bind(this)}
                                                            style={{position: "relative"}}>
                                                        Поставить
                                                        <div style={{position: "absolute", right: 6, top: 6}}>{isHandleSubmit ? <Loading white={true} /> : null}</div>
                                                    </button>
                                                </div>
                                            </React.Fragment>

                                        )

                                ) : null}














                                {Number(objectData.control_diff) > 0 ? (

                                    objectData.academic_performance.map(academic_performance => (
                                        academic_performance.control_type === 'diff' ? (controlDiff = true) : null
                                    )),

                                        controlDiff ? (

                                            objectData.academic_performance.map((academic_performance, index) => (
                                                academic_performance.control_type === 'diff' ? (


                                                    <React.Fragment key={index}>

                                                        <div className="Box" style={{marginBottom: 24}}>
                                                            <div className="Box__title" style={{
                                                                fontFamily: "Roboto, sans-serif",
                                                                fontWeight: 700,
                                                                fontSize: 20,
                                                                color: "#2A3E4C"
                                                            }}>Диф. зачет</div>
                                                        </div>

                                                        <div className="Box" style={{marginBottom: 24}}>
                                                            <div className="Box__label" style={{
                                                                fontFamily: "Roboto, sans-serif",
                                                                fontWeight: 700,
                                                                fontSize: 16,
                                                                color: "#949ea5",
                                                                marginBottom: 16
                                                            }}>Оценка</div>
                                                            <div className="Box__text" style={{
                                                                fontFamily: "Roboto, sans-serif",
                                                                fontWeight: 400,
                                                                fontSize: 16,
                                                                color: "#2A3E4C"
                                                            }}>

                                                                {Number(academic_performance.knowledge_assessment) === 0 ? 'Ведомость' : null}
                                                                {Number(academic_performance.knowledge_assessment) === 1 ? 'Не явка' : null}
                                                                {Number(academic_performance.knowledge_assessment) > 1 ? academic_performance.knowledge_assessment : null}

                                                            </div>
                                                        </div>

                                                        <div className="Box" style={{marginBottom: 24}}>
                                                            <div className="Box__label" style={{
                                                                fontFamily: "Roboto, sans-serif",
                                                                fontWeight: 700,
                                                                fontSize: 16,
                                                                color: "#949ea5",
                                                                marginBottom: 16
                                                            }}>Дата сдачи</div>
                                                            <div className="Box__text" style={{
                                                                fontFamily: "Roboto, sans-serif",
                                                                fontWeight: 400,
                                                                fontSize: 16,
                                                                color: "#2A3E4C"
                                                            }}>
                                                                {!academic_performance.data_assessment ? '-' : (
                                                                    academic_performance.data_assessment === "null" ? '-' : (
                                                                        academic_performance.data_assessment === "" ? '-' : academic_performance.data_assessment
                                                                    )
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="Box" style={{
                                                            marginBottom: 24
                                                        }}>
                                                            <div className="Box__label" style={{
                                                                fontFamily: "Roboto, sans-serif",
                                                                fontWeight: 700,
                                                                fontSize: 16,
                                                                color: "#949ea5",
                                                                marginBottom: 16
                                                            }}>Преподаватель</div>
                                                            <div className="Box__text" style={{
                                                                fontFamily: "Roboto, sans-serif",
                                                                fontWeight: 400,
                                                                fontSize: 16,
                                                                color: "#2A3E4C"
                                                            }}>

                                                                {!employeesData ? null : (
                                                                    employeesData.map((employees) => (
                                                                        employees.array.map((employee, index) => (
                                                                            Number(academic_performance.employee_id) === Number(employee.id) ? controlDiffTeacher = true : null,
                                                                                Number(academic_performance.employee_id) === Number(employee.id) ? employee.employee_fio : null
                                                                        ))
                                                                    )))}

                                                                {controlDiffTeacher ? null : '-'}

                                                            </div>
                                                        </div>

                                                        <div className="Form__footer">
                                                            <button className={isHandleSubmit ? 'Form__button Form__button_red Form__button_left Form__button_disabled' : 'Form__button Form__button_red Form__button_left'}
                                                                    type="submit"
                                                                    disabled={isHandleSubmit ? 'disabled' : null}
                                                                    onClick={this.controlDelete.bind(this, academic_performance.id)}
                                                                    style={{position: "relative"}}>
                                                                Аннулировать
                                                                <div style={{position: "absolute", right: 6, top: 6}}>{isHandleSubmit ? <Loading white={true} /> : null}</div>
                                                            </button>
                                                        </div>
                                                    </React.Fragment>


                                                ) : null
                                            ))


                                        ) : (

                                            <React.Fragment>

                                                <div className="Box" style={{marginBottom: 24}}>
                                                    <div className="Box__title" style={{
                                                        fontFamily: "Roboto, sans-serif",
                                                        fontWeight: 700,
                                                        fontSize: 20,
                                                        color: "#2A3E4C"
                                                    }}>Диф. зачет</div>
                                                </div>



                                                <div className="Content__box">
                                                    <div className="Form__field">
                                                        <div className="Field__label">Оценка</div>

                                                        <input className="Field__input"
                                                               name="knowledgeAssessment"
                                                               type="number"
                                                               style={{
                                                                   width: 304
                                                               }}
                                                               max={5}
                                                               min={0}
                                                               step={1}
                                                               defaultValue={0}
                                                               value={this.state.value}
                                                               onChange={this.handleInputChange.bind(this)}/>
                                                    </div>
                                                </div>

                                                <div className="Content__box">
                                                    <div className="Form__field">
                                                        <div className="Field__label">Дата сдачи</div>
                                                        <input className="Field__input"
                                                               name="dataAssessment"
                                                               type="text"
                                                               style={{
                                                                   width: 304
                                                               }}
                                                               placeholder="дд.мм.гггг"
                                                               value={this.state.value}
                                                               onChange={this.handleInputChange.bind(this)}/>
                                                    </div>
                                                </div>

                                                <div className="Content__box">
                                                    <div className="Form__field">
                                                        <div className="Field__label">Преподаватель</div>
                                                        <select className="Field__select"
                                                                id="employeeId"
                                                                name="employeeId"
                                                                value={employeeId}
                                                                style={{
                                                                    width: 340
                                                                }}
                                                                onChange={this.handleInputChange.bind(this)}>
                                                            <option value="0">Не выбран</option>
                                                            <option  className="option__hr" disabled />
                                                            {!employeesData ? null : (
                                                                employeesData.map((employees) => (
                                                                    employees.array.map((employee, index) => (
                                                                        <React.Fragment key={index}>
                                                                            <option value={employee.id}>{employee.employee_fio}</option>
                                                                            <option className="option__info" disabled>{employee.employee_email}</option>
                                                                            <option  className="option__hr" disabled />
                                                                        </React.Fragment>
                                                                    ))
                                                                )))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="Form__footer">
                                                    <button className={isHandleSubmit ? 'Form__button Form__button_blue Form__button_left Form__button_disabled' : 'Form__button Form__button_blue Form__button_left'}
                                                            type="submit"
                                                            disabled={isHandleSubmit ? 'disabled' : null}
                                                            onClick={this.controlDiffAdd.bind(this)}
                                                            style={{position: "relative"}}>
                                                        Поставить
                                                        <div style={{position: "absolute", right: 6, top: 6}}>{isHandleSubmit ? <Loading white={true} /> : null}</div>
                                                    </button>
                                                </div>
                                            </React.Fragment>

                                        )

                                ) : null}















                                {Number(objectData.control_test) > 0 ? (

                                    objectData.academic_performance.map(academic_performance => (
                                        academic_performance.control_type === 'test' ? (controlTest = true) : null
                                    )),

                                    controlTest ? (

                                        objectData.academic_performance.map((academic_performance, index) => (
                                            academic_performance.control_type === 'test' ? (


                                                <React.Fragment key={index}>

                                                    <div className="Box" style={{marginBottom: 24}}>
                                                        <div className="Box__title" style={{
                                                            fontFamily: "Roboto, sans-serif",
                                                            fontWeight: 700,
                                                            fontSize: 20,
                                                            color: "#2A3E4C"
                                                        }}>Зачет</div>
                                                    </div>

                                                    <div className="Box" style={{marginBottom: 24}}>
                                                        <div className="Box__label" style={{
                                                            fontFamily: "Roboto, sans-serif",
                                                            fontWeight: 700,
                                                            fontSize: 16,
                                                            color: "#949ea5",
                                                            marginBottom: 16
                                                        }}>Оценка</div>
                                                        <div className="Box__text" style={{
                                                            fontFamily: "Roboto, sans-serif",
                                                            fontWeight: 400,
                                                            fontSize: 16,
                                                            color: "#2A3E4C"
                                                        }}>

                                                            {Number(academic_performance.knowledge_assessment) === 0 ? 'Ведомость' : null}
                                                            {Number(academic_performance.knowledge_assessment) === 1 ? 'Не явка' : null}
                                                            {Number(academic_performance.knowledge_assessment) === 2 ? 'Не зачет' : null}
                                                            {Number(academic_performance.knowledge_assessment) === 5 ? 'Зачет' : null}

                                                        </div>
                                                    </div>

                                                    <div className="Box" style={{marginBottom: 24}}>
                                                        <div className="Box__label" style={{
                                                            fontFamily: "Roboto, sans-serif",
                                                            fontWeight: 700,
                                                            fontSize: 16,
                                                            color: "#949ea5",
                                                            marginBottom: 16
                                                        }}>Дата сдачи</div>
                                                        <div className="Box__text" style={{
                                                            fontFamily: "Roboto, sans-serif",
                                                            fontWeight: 400,
                                                            fontSize: 16,
                                                            color: "#2A3E4C"
                                                        }}>
                                                            {!academic_performance.data_assessment ? '-' : (
                                                                academic_performance.data_assessment === "null" ? '-' : (
                                                                    academic_performance.data_assessment === "" ? '-' : academic_performance.data_assessment
                                                                )
                                                            )}
                                                        </div>
                                                    </div>

                                                    <div className="Box" style={{
                                                        marginBottom: 24
                                                    }}>
                                                        <div className="Box__label" style={{
                                                            fontFamily: "Roboto, sans-serif",
                                                            fontWeight: 700,
                                                            fontSize: 16,
                                                            color: "#949ea5",
                                                            marginBottom: 16
                                                        }}>Преподаватель</div>
                                                        <div className="Box__text" style={{
                                                            fontFamily: "Roboto, sans-serif",
                                                            fontWeight: 400,
                                                            fontSize: 16,
                                                            color: "#2A3E4C"
                                                        }}>

                                                            {!employeesData ? null : (
                                                                employeesData.map((employees) => (
                                                                    employees.array.map((employee, index) => (
                                                                        Number(academic_performance.employee_id) === Number(employee.id) ? controlTestTeacher = true : null,
                                                                        Number(academic_performance.employee_id) === Number(employee.id) ? employee.employee_fio : null
                                                                    ))
                                                                )))}

                                                            {controlTestTeacher ? null : '-'}

                                                        </div>
                                                    </div>

                                                    <div className="Form__footer">
                                                        <button className={isHandleSubmit ? 'Form__button Form__button_red Form__button_left Form__button_disabled' : 'Form__button Form__button_red Form__button_left'}
                                                                type="submit"
                                                                disabled={isHandleSubmit ? 'disabled' : null}
                                                                onClick={this.controlDelete.bind(this, academic_performance.id)}
                                                                style={{position: "relative"}}>
                                                            Аннулировать
                                                            <div style={{position: "absolute", right: 6, top: 6}}>{isHandleSubmit ? <Loading white={true} /> : null}</div>
                                                        </button>
                                                    </div>
                                                </React.Fragment>


                                            ) : null
                                        ))


                                    ) : (

                                        <React.Fragment>

                                            <div className="Box" style={{marginBottom: 24}}>
                                                <div className="Box__title" style={{
                                                    fontFamily: "Roboto, sans-serif",
                                                    fontWeight: 700,
                                                    fontSize: 20,
                                                    color: "#2A3E4C"
                                                }}>Зачет</div>
                                            </div>



                                            <div className="Content__box">
                                                <div className="Form__field">
                                                    <div className="Field__label">Оценка</div>
                                                    <select className="Field__select"
                                                            id="knowledgeAssessment"
                                                            name="knowledgeAssessment"
                                                            value={knowledgeAssessment}
                                                            style={{
                                                                width: 340
                                                            }}
                                                            onChange={this.handleInputChange.bind(this)}>
                                                        <option value="0">Ведомость</option>
                                                        <option  className="option__hr" disabled />
                                                        <option value="1">Не явка</option>
                                                        <option  className="option__hr" disabled />
                                                        <option value="2">Не зачет</option>
                                                        <option  className="option__hr" disabled />
                                                        <option value="5">Зачет</option>
                                                        <option  className="option__hr" disabled />
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="Content__box">
                                                <div className="Form__field">
                                                    <div className="Field__label">Дата сдачи</div>
                                                    <input className="Field__input"
                                                           name="dataAssessment"
                                                           type="text"
                                                           style={{
                                                               width: 304
                                                           }}
                                                           placeholder="дд.мм.гггг"
                                                           value={this.state.value}
                                                           onChange={this.handleInputChange.bind(this)}/>
                                                </div>
                                            </div>

                                            <div className="Content__box">
                                                <div className="Form__field">
                                                    <div className="Field__label">Преподаватель</div>
                                                    <select className="Field__select"
                                                            id="employeeId"
                                                            name="employeeId"
                                                            value={employeeId}
                                                            style={{
                                                                width: 340
                                                            }}
                                                            onChange={this.handleInputChange.bind(this)}>
                                                        <option value="0">Не выбран</option>
                                                        <option  className="option__hr" disabled />
                                                        {!employeesData ? null : (
                                                            employeesData.map((employees) => (
                                                                employees.array.map((employee, index) => (
                                                                    <React.Fragment key={index}>
                                                                        <option value={employee.id}>{employee.employee_fio}</option>
                                                                        <option className="option__info" disabled>{employee.employee_email}</option>
                                                                        <option  className="option__hr" disabled />
                                                                    </React.Fragment>
                                                                ))
                                                            )))}
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="Form__footer">
                                                <button className={isHandleSubmit ? 'Form__button Form__button_blue Form__button_left Form__button_disabled' : 'Form__button Form__button_blue Form__button_left'}
                                                        type="submit"
                                                        disabled={isHandleSubmit ? 'disabled' : null}
                                                onClick={this.controlTestAdd.bind(this)}
                                                style={{position: "relative"}}>
                                                    Поставить
                                                    <div style={{position: "absolute", right: 6, top: 6}}>{isHandleSubmit ? <Loading white={true} /> : null}</div>
                                                </button>
                                            </div>
                                        </React.Fragment>

                                    )

                                ) : null}





































                                {Number(objectData.control_project) > 0 ? (

                                    objectData.academic_performance.map(academic_performance => (
                                        academic_performance.control_type === 'project' ? (controlProject = true) : null
                                    )),

                                        controlProject ? (

                                            objectData.academic_performance.map((academic_performance, index) => (
                                                academic_performance.control_type === 'project' ? (


                                                    <React.Fragment key={index}>
                                                        <div className="Content__hr Content__hr_bottom-32" style={{marginTop: 32}}/>

                                                        <div className="Box" style={{marginBottom: 24}}>
                                                            <div className="Box__title" style={{
                                                                fontFamily: "Roboto, sans-serif",
                                                                fontWeight: 700,
                                                                fontSize: 20,
                                                                color: "#2A3E4C"
                                                            }}>Курсовой проект</div>
                                                        </div>

                                                        <div className="Box" style={{marginBottom: 24}}>
                                                            <div className="Box__label" style={{
                                                                fontFamily: "Roboto, sans-serif",
                                                                fontWeight: 700,
                                                                fontSize: 16,
                                                                color: "#949ea5",
                                                                marginBottom: 16
                                                            }}>Название проекта</div>
                                                            <div className="Box__text" style={{
                                                                fontFamily: "Roboto, sans-serif",
                                                                fontWeight: 400,
                                                                fontSize: 16,
                                                                color: "#2A3E4C"
                                                            }}>
                                                                {!academic_performance.project_title ? '-' : (
                                                                    academic_performance.project_title === "null" ? '-' : (
                                                                        academic_performance.project_title === "" ? '-' : academic_performance.project_title
                                                                    )
                                                                )}

                                                            </div>
                                                        </div>

                                                        <div className="Box" style={{marginBottom: 24}}>
                                                            <div className="Box__label" style={{
                                                                fontFamily: "Roboto, sans-serif",
                                                                fontWeight: 700,
                                                                fontSize: 16,
                                                                color: "#949ea5",
                                                                marginBottom: 16
                                                            }}>Оценка</div>
                                                            <div className="Box__text" style={{
                                                                fontFamily: "Roboto, sans-serif",
                                                                fontWeight: 400,
                                                                fontSize: 16,
                                                                color: "#2A3E4C"
                                                            }}>

                                                                {Number(academic_performance.knowledge_assessment) === 0 ? 'Ведомость' : null}
                                                                {Number(academic_performance.knowledge_assessment) === 1 ? 'Не явка' : null}
                                                                {Number(academic_performance.knowledge_assessment) > 1 ? academic_performance.knowledge_assessment : null}

                                                            </div>
                                                        </div>

                                                        <div className="Box" style={{marginBottom: 24}}>
                                                            <div className="Box__label" style={{
                                                                fontFamily: "Roboto, sans-serif",
                                                                fontWeight: 700,
                                                                fontSize: 16,
                                                                color: "#949ea5",
                                                                marginBottom: 16
                                                            }}>Дата сдачи</div>
                                                            <div className="Box__text" style={{
                                                                fontFamily: "Roboto, sans-serif",
                                                                fontWeight: 400,
                                                                fontSize: 16,
                                                                color: "#2A3E4C"
                                                            }}>
                                                                {!academic_performance.data_assessment ? '-' : (
                                                                    academic_performance.data_assessment === "null" ? '-' : (
                                                                        academic_performance.data_assessment === "" ? '-' : academic_performance.data_assessment
                                                                    )
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="Box" style={{
                                                            marginBottom: 24
                                                        }}>
                                                            <div className="Box__label" style={{
                                                                fontFamily: "Roboto, sans-serif",
                                                                fontWeight: 700,
                                                                fontSize: 16,
                                                                color: "#949ea5",
                                                                marginBottom: 16
                                                            }}>Преподаватель</div>
                                                            <div className="Box__text" style={{
                                                                fontFamily: "Roboto, sans-serif",
                                                                fontWeight: 400,
                                                                fontSize: 16,
                                                                color: "#2A3E4C"
                                                            }}>

                                                                {!employeesData ? null : (
                                                                    employeesData.map((employees) => (
                                                                        employees.array.map((employee, index) => (
                                                                            Number(academic_performance.employee_id) === Number(employee.id) ? controlProjectTeacher = true : null,
                                                                                Number(academic_performance.employee_id) === Number(employee.id) ? employee.employee_fio : null
                                                                        ))
                                                                    )))}

                                                                {controlProjectTeacher ? null : '-'}

                                                            </div>
                                                        </div>

                                                        <div className="Form__footer">
                                                            <button className={isHandleSubmit ? 'Form__button Form__button_red Form__button_left Form__button_disabled' : 'Form__button Form__button_red Form__button_left'}
                                                                    type="submit"
                                                                    disabled={isHandleSubmit ? 'disabled' : null}
                                                                    onClick={this.controlDelete.bind(this, academic_performance.id)}
                                                                    style={{position: "relative"}}>
                                                                Аннулировать
                                                                <div style={{position: "absolute", right: 6, top: 6}}>{isHandleSubmit ? <Loading white={true} /> : null}</div>
                                                            </button>
                                                        </div>
                                                    </React.Fragment>


                                                ) : null
                                            ))


                                        ) : (

                                            <React.Fragment>
                                                <div className="Content__hr Content__hr_bottom-32" style={{marginTop: 32}}/>

                                                <div className="Box" style={{marginBottom: 24}}>
                                                    <div className="Box__title" style={{
                                                        fontFamily: "Roboto, sans-serif",
                                                        fontWeight: 700,
                                                        fontSize: 20,
                                                        color: "#2A3E4C"
                                                    }}>Курсовой проект</div>
                                                </div>

                                                <div className="Content__box">
                                                    <div className="Form__field">
                                                        <div className="Field__label">Название проекта</div>
                                                        <input className="Field__input"
                                                               name="projectTitle"
                                                               type="text"
                                                               style={{
                                                                   width: 304
                                                               }}
                                                               value={this.state.value}
                                                               onChange={this.handleInputChange.bind(this)}/>
                                                    </div>
                                                </div>



                                                <div className="Content__box">
                                                    <div className="Form__field">
                                                        <div className="Field__label">Оценка</div>

                                                        <input className="Field__input"
                                                               name="knowledgeAssessment"
                                                               type="number"
                                                               style={{
                                                                   width: 304
                                                               }}
                                                               max={5}
                                                               min={0}
                                                               step={1}
                                                               defaultValue={0}
                                                               value={this.state.value}
                                                               onChange={this.handleInputChange.bind(this)}/>
                                                    </div>
                                                </div>

                                                <div className="Content__box">
                                                    <div className="Form__field">
                                                        <div className="Field__label">Дата сдачи</div>
                                                        <input className="Field__input"
                                                               name="dataAssessment"
                                                               type="text"
                                                               style={{
                                                                   width: 304
                                                               }}
                                                               placeholder="дд.мм.гггг"
                                                               value={this.state.value}
                                                               onChange={this.handleInputChange.bind(this)}/>
                                                    </div>
                                                </div>

                                                <div className="Content__box">
                                                    <div className="Form__field">
                                                        <div className="Field__label">Преподаватель</div>
                                                        <select className="Field__select"
                                                                id="employeeId"
                                                                name="employeeId"
                                                                value={employeeId}
                                                                style={{
                                                                    width: 340
                                                                }}
                                                                onChange={this.handleInputChange.bind(this)}>
                                                            <option value="0">Не выбран</option>
                                                            <option  className="option__hr" disabled />
                                                            {!employeesData ? null : (
                                                                employeesData.map((employees) => (
                                                                    employees.array.map((employee, index) => (
                                                                        <React.Fragment key={index}>
                                                                            <option value={employee.id}>{employee.employee_fio}</option>
                                                                            <option className="option__info" disabled>{employee.employee_email}</option>
                                                                            <option  className="option__hr" disabled />
                                                                        </React.Fragment>
                                                                    ))
                                                                )))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="Form__footer">
                                                    <button className={isHandleSubmit ? 'Form__button Form__button_blue Form__button_left Form__button_disabled' : 'Form__button Form__button_blue Form__button_left'}
                                                            type="submit"
                                                            disabled={isHandleSubmit ? 'disabled' : null}
                                                            onClick={this.controlProjectAdd.bind(this)}
                                                            style={{position: "relative"}}>
                                                        Поставить
                                                        <div style={{position: "absolute", right: 6, top: 6}}>{isHandleSubmit ? <Loading white={true} /> : null}</div>
                                                    </button>
                                                </div>
                                            </React.Fragment>

                                        )

                                ) : null}












                                {Number(objectData.control_coursework) > 0 ? (

                                    objectData.academic_performance.map(academic_performance => (
                                        academic_performance.control_type === 'coursework' ? (controlCoursework = true) : null
                                    )),

                                        controlCoursework ? (

                                            objectData.academic_performance.map((academic_performance, index) => (
                                                academic_performance.control_type === 'coursework' ? (


                                                    <React.Fragment key={index}>
                                                        <div className="Content__hr Content__hr_bottom-32" style={{marginTop: 32}}/>

                                                        <div className="Box" style={{marginBottom: 24}}>
                                                            <div className="Box__title" style={{
                                                                fontFamily: "Roboto, sans-serif",
                                                                fontWeight: 700,
                                                                fontSize: 20,
                                                                color: "#2A3E4C"
                                                            }}>Курсовая работа</div>
                                                        </div>

                                                        <div className="Box" style={{marginBottom: 24}}>
                                                            <div className="Box__label" style={{
                                                                fontFamily: "Roboto, sans-serif",
                                                                fontWeight: 700,
                                                                fontSize: 16,
                                                                color: "#949ea5",
                                                                marginBottom: 16
                                                            }}>Название проекта</div>
                                                            <div className="Box__text" style={{
                                                                fontFamily: "Roboto, sans-serif",
                                                                fontWeight: 400,
                                                                fontSize: 16,
                                                                color: "#2A3E4C"
                                                            }}>
                                                                {!academic_performance.project_title ? '-' : (
                                                                    academic_performance.project_title === "null" ? '-' : (
                                                                        academic_performance.project_title === "" ? '-' : academic_performance.project_title
                                                                    )
                                                                )}

                                                            </div>
                                                        </div>

                                                        <div className="Box" style={{marginBottom: 24}}>
                                                            <div className="Box__label" style={{
                                                                fontFamily: "Roboto, sans-serif",
                                                                fontWeight: 700,
                                                                fontSize: 16,
                                                                color: "#949ea5",
                                                                marginBottom: 16
                                                            }}>Оценка</div>
                                                            <div className="Box__text" style={{
                                                                fontFamily: "Roboto, sans-serif",
                                                                fontWeight: 400,
                                                                fontSize: 16,
                                                                color: "#2A3E4C"
                                                            }}>

                                                                {Number(academic_performance.knowledge_assessment) === 0 ? 'Ведомость' : null}
                                                                {Number(academic_performance.knowledge_assessment) === 1 ? 'Не явка' : null}
                                                                {Number(academic_performance.knowledge_assessment) > 1 ? academic_performance.knowledge_assessment : null}

                                                            </div>
                                                        </div>

                                                        <div className="Box" style={{marginBottom: 24}}>
                                                            <div className="Box__label" style={{
                                                                fontFamily: "Roboto, sans-serif",
                                                                fontWeight: 700,
                                                                fontSize: 16,
                                                                color: "#949ea5",
                                                                marginBottom: 16
                                                            }}>Дата сдачи</div>
                                                            <div className="Box__text" style={{
                                                                fontFamily: "Roboto, sans-serif",
                                                                fontWeight: 400,
                                                                fontSize: 16,
                                                                color: "#2A3E4C"
                                                            }}>
                                                                {!academic_performance.data_assessment ? '-' : (
                                                                    academic_performance.data_assessment === "null" ? '-' : (
                                                                        academic_performance.data_assessment === "" ? '-' : academic_performance.data_assessment
                                                                    )
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="Box" style={{
                                                            marginBottom: 24
                                                        }}>
                                                            <div className="Box__label" style={{
                                                                fontFamily: "Roboto, sans-serif",
                                                                fontWeight: 700,
                                                                fontSize: 16,
                                                                color: "#949ea5",
                                                                marginBottom: 16
                                                            }}>Преподаватель</div>
                                                            <div className="Box__text" style={{
                                                                fontFamily: "Roboto, sans-serif",
                                                                fontWeight: 400,
                                                                fontSize: 16,
                                                                color: "#2A3E4C"
                                                            }}>

                                                                {!employeesData ? null : (
                                                                    employeesData.map((employees) => (
                                                                        employees.array.map((employee, index) => (
                                                                            Number(academic_performance.employee_id) === Number(employee.id) ? controlCourseworkTeacher = true : null,
                                                                                Number(academic_performance.employee_id) === Number(employee.id) ? employee.employee_fio : null
                                                                        ))
                                                                    )))}

                                                                {controlCourseworkTeacher ? null : '-'}

                                                            </div>
                                                        </div>

                                                        <div className="Form__footer">
                                                            <button className={isHandleSubmit ? 'Form__button Form__button_red Form__button_left Form__button_disabled' : 'Form__button Form__button_red Form__button_left'}
                                                                    type="submit"
                                                                    disabled={isHandleSubmit ? 'disabled' : null}
                                                                    onClick={this.controlDelete.bind(this, academic_performance.id)}
                                                                    style={{position: "relative"}}>
                                                                Аннулировать
                                                                <div style={{position: "absolute", right: 6, top: 6}}>{isHandleSubmit ? <Loading white={true} /> : null}</div>
                                                            </button>
                                                        </div>
                                                    </React.Fragment>


                                                ) : null
                                            ))


                                        ) : (

                                            <React.Fragment>
                                                <div className="Content__hr Content__hr_bottom-32" style={{marginTop: 32}}/>

                                                <div className="Box" style={{marginBottom: 24}}>
                                                    <div className="Box__title" style={{
                                                        fontFamily: "Roboto, sans-serif",
                                                        fontWeight: 700,
                                                        fontSize: 20,
                                                        color: "#2A3E4C"
                                                    }}>Курсовая работа</div>
                                                </div>

                                                <div className="Content__box">
                                                    <div className="Form__field">
                                                        <div className="Field__label">Название проекта</div>
                                                        <input className="Field__input"
                                                               name="projectTitle"
                                                               type="text"
                                                               style={{
                                                                   width: 304
                                                               }}
                                                               value={this.state.value}
                                                               onChange={this.handleInputChange.bind(this)}/>
                                                    </div>
                                                </div>



                                                <div className="Content__box">
                                                    <div className="Form__field">
                                                        <div className="Field__label">Оценка</div>

                                                        <input className="Field__input"
                                                               name="knowledgeAssessment"
                                                               type="number"
                                                               style={{
                                                                   width: 304
                                                               }}
                                                               max={5}
                                                               min={0}
                                                               step={1}
                                                               defaultValue={0}
                                                               value={this.state.value}
                                                               onChange={this.handleInputChange.bind(this)}/>
                                                    </div>
                                                </div>

                                                <div className="Content__box">
                                                    <div className="Form__field">
                                                        <div className="Field__label">Дата сдачи</div>
                                                        <input className="Field__input"
                                                               name="dataAssessment"
                                                               type="text"
                                                               style={{
                                                                   width: 304
                                                               }}
                                                               placeholder="дд.мм.гггг"
                                                               value={this.state.value}
                                                               onChange={this.handleInputChange.bind(this)}/>
                                                    </div>
                                                </div>

                                                <div className="Content__box">
                                                    <div className="Form__field">
                                                        <div className="Field__label">Преподаватель</div>
                                                        <select className="Field__select"
                                                                id="employeeId"
                                                                name="employeeId"
                                                                value={employeeId}
                                                                style={{
                                                                    width: 340
                                                                }}
                                                                onChange={this.handleInputChange.bind(this)}>
                                                            <option value="0">Не выбран</option>
                                                            <option  className="option__hr" disabled />
                                                            {!employeesData ? null : (
                                                                employeesData.map((employees) => (
                                                                    employees.array.map((employee, index) => (
                                                                        <React.Fragment key={index}>
                                                                            <option value={employee.id}>{employee.employee_fio}</option>
                                                                            <option className="option__info" disabled>{employee.employee_email}</option>
                                                                            <option  className="option__hr" disabled />
                                                                        </React.Fragment>
                                                                    ))
                                                                )))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className="Form__footer">
                                                    <button className={isHandleSubmit ? 'Form__button Form__button_blue Form__button_left Form__button_disabled' : 'Form__button Form__button_blue Form__button_left'}
                                                            type="submit"
                                                            disabled={isHandleSubmit ? 'disabled' : null}
                                                            onClick={this.controlCourseworkAdd.bind(this)}
                                                            style={{position: "relative"}}>
                                                        Поставить
                                                        <div style={{position: "absolute", right: 6, top: 6}}>{isHandleSubmit ? <Loading white={true} /> : null}</div>
                                                    </button>
                                                </div>
                                            </React.Fragment>

                                        )

                                ) : null}

















                            </div>

































                        {this.props.profileData.profile_type === 'administrator' ? (

                            Number(objectData.anchor_of_choice) > 0 ? (

                                objectData.discipline_choice ? (
                                    <React.Fragment>
                                        <div className="Form__link">
                                            <div className="Link__delete" onClick={this.disciplineChoiceDelete.bind(this)}>Убрать выбор</div>
                                        </div>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>

                                        <div className="Form__link">
                                            <div className="Link__delete" style={{color: "#0061ff"}} onClick={this.disciplineChoiceAdd.bind(this)}>Выбрать предмет</div>
                                        </div>
                                    </React.Fragment>
                                )
                            ) : null
                        ) : null}
                    </div>
                </div>
            );
        }

        else return null;
    }
}

function mapStateToProps(state) {
    return {
        alertData: state.alert.alertData,
        isRequest: state.api.isRequest,
        responseMessage: state.api.responseMessage,
        responseData: state.api.responseData,
        responseError: state.api.responseError,
        profileData: state.profile.profileData
    }
}

function mapDispatchToProps(dispatch) {
    return {
        formUpdate: () => dispatch(formUpdate()),
        pageUpdate: () => dispatch(pageUpdate()),
        modalClose: () => dispatch(modalClose()),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        notificationMessage: (message, colorMessage) => dispatch(notificationMessage(message, colorMessage)),
        alertClose: () => dispatch(alertClose())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertStudentGradebook);
