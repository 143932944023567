import React from "react";
import {connect} from "react-redux";
import {pageLoad, pageMount, pageUpdate} from "../../../../store/actions/pageAction";
import {apiRequest} from "../../../../store/actions/apiAction";
import {modalOpen} from "../../../../store/actions/modalAction";
import "./TrainingSchedule.scss";
import Loading from "../../../loading/Loading";
import {Link} from "react-router-dom";
import "./schedule.scss";
import {notificationMessage} from "../../../../store/actions/notificationAction";

class TrainingSchedule extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isMount: true,
            isOpen: false,
            isLoad: false,
            isUpdate: false,
            pageError: null,
            trainingScheduleData: null,

            isEditTypeWeeks: false,
            typeWeekNumber: 1,
            typeWeeksData: [],
            isSaveTypeWeeks: false,

            isEditTimetables: false,
            timetablesData: [],
            isSaveTimetables: false,
        };
    }

    componentDidMount() {
        this.props.pageMount();
        this.pageUpdate(this.state.isLoad);
        // this.intervalUpdate = setInterval(this.props.pageUpdate, 180000);
    }

    componentWillUnmount() {
        // console.log('Employees Unmounted');
        this.setState({
            isMount: false
        });
    }

    componentDidUpdate() {
        if (this.state.isMount) {
            const responseData = this.props.responseData;
            // console.log(1 + ' componentDidUpdate');
            if (this.props.isLoad !== this.state.isLoad) {
                // console.log(2 + ' isLoad');
                if (responseData !== this.state.trainingScheduleData) {
                    // console.log(3 + ' responseData');

                    this.setState({
                        isOpen: true,
                        isLoad: this.props.isLoad,
                        isUpdate: this.props.isUpdate,
                        trainingScheduleData: responseData
                    });
                }
            }

            else if (this.props.isUpdate !== this.state.isUpdate) {
                // console.log(4 + ' isUpdate');
                this.pageUpdate(this.state.isLoad);

                this.setState({
                    isUpdate: this.props.isUpdate
                });
            }
        }
    }

    pageUpdate(isLoad) {
        this.props.apiRequest('api/cabinet/training/schedule/' + this.props.match.params.trainingScheduleId, null, () => {
            this.props.pageLoad(isLoad);
        }, () => {
            this.setState({
                pageError: this.props.responseError
            });
        });
    }

    modalOpen(modalForm, modalData) {
        this.props.modalOpen(modalForm, modalData);
    }

    isEditTypeWeeks(isEditTypeWeeks) {

        const typeWeeksData = this.state.trainingScheduleData.schedule_periods;

        this.setState({
                isEditTypeWeeks: !isEditTypeWeeks,
                typeWeeksData: (!isEditTypeWeeks ? typeWeeksData : []),
                typeWeekNumber: 1,
                isSaveTypeWeeks: false,
                isEditTimetables: false,
                timetablesData: [],
                isSaveTimetables: false
            },
            () => {
                this.props.pageUpdate();
            });
    }

    isEditTimetables(isEditTimetables) {

        const timetablesData = this.state.trainingScheduleData.schedule_timetables;

        this.setState({
                isEditTypeWeeks: false,
                typeWeeksData: [],
                typeWeekNumber: 1,
                isSaveTypeWeeks: false,
                isEditTimetables: !isEditTimetables,
                timetablesData: (!isEditTimetables ? timetablesData : []),
                isSaveTimetables: false
            },
            () => {
                this.props.pageUpdate();
            });
    }

    trainingScheduleTimetableEdit() {
        const timetablesData = this.state.timetablesData;

        if (timetablesData.length > 0) {

            let formData = new FormData();

            let jsonTimetablesData = JSON.stringify(timetablesData);

            formData.append('schedule_timetables', jsonTimetablesData);

            if (!this.state.isSaveTimetables) {

                this.setState({
                    isSaveTimetables: true
                });

                this.props.apiRequest('api/cabinet/training/schedule/' + this.props.match.params.trainingScheduleId + '/timetables/edit', formData, () => {
                        this.props.notificationMessage(this.props.responseMessage, 'green');
                        this.props.pageUpdate();
                        this.setState({
                            isEditTimetables: false,
                            timetablesData: [],
                            isSaveTimetables: false
                        });
                    }, () => {
                        this.setState({
                            isSaveTimetables: false
                        });
                        this.props.notificationMessage(this.props.responseError, 'red');
                    }
                );
            }

            else {
                this.props.notificationMessage('Изменения по расписанию уже сохраняются.', 'red');
            }
        }

        else {
            this.props.notificationMessage('Нет данных по расписанию для изменений.', 'red');
        }

    }

    trainingSchedulePeriodEdit () {
        const typeWeeksData = this.state.typeWeeksData;
        let typeWeeksDataEdit = []

        if (typeWeeksData) {
            typeWeeksData.map(schedule_period => {
                schedule_period.periods.map(period => {
                    typeWeeksDataEdit.push(period);
                });
            });
        }

        if (typeWeeksDataEdit.length > 0) {

            let formData = new FormData();

            let jsonTypeWeeksDataEdit = JSON.stringify(typeWeeksDataEdit);

            formData.append('schedule_periods', jsonTypeWeeksDataEdit);

            if (!this.state.isSaveTypeWeeks) {

                this.setState({
                    isSaveTypeWeeks: true
                });

                this.props.apiRequest('api/cabinet/training/schedule/' + this.props.match.params.trainingScheduleId + '/periods/edit', formData, () => {
                    this.props.notificationMessage(this.props.responseMessage, 'green');
                        this.props.pageUpdate();
                        this.setState({
                            isEditTypeWeeks: false,
                            typeWeeksData: [],
                            typeWeekNumber: 0,
                            isSaveTypeWeeks: false
                        });
                    }, () => {
                        this.setState({
                            isSaveTypeWeeks: false
                        });
                        this.props.notificationMessage(this.props.responseError, 'red');
                    }
                );
            }

            else {
                this.props.notificationMessage('Изменения по периодам уже сохраняются.', 'red');
            }

        }

        else {
            this.props.notificationMessage('Нет данных по периодам для изменений.', 'red');
        }
    }

    typeWeek(periodId, weekNumber, typeWeek) {
        const typeWeeksData = this.state.typeWeeksData;

        if (typeWeeksData) {
            typeWeeksData.map(schedule_period => {
                schedule_period.periods.map(period => {
                    if (Number(period.id) === Number(periodId)) {

                        if (Number(period['type_week_' + weekNumber]) === Number(typeWeek)) {
                            period['type_week_' + weekNumber] = 0;
                        }

                        else {

                            period['type_week_' + weekNumber] = typeWeek;
                        }
                    }
                });
            });

            this.setState({
                typeWeeksData: typeWeeksData
            });
        }
    }

    typeWeekNumber(number) {
        this.setState({
            typeWeekNumber: number
        });
    }

    newTimetable(year) {
        let timetablesData = this.state.timetablesData;

        // Если вдруг больше не нужно анализировать удаленные записи, и можно редактировать и перезаписывать.
        // let delTimetable = false;
        // let delTimetableId = 0;
        // let delTimetableIndex = null;
        //
        // timetablesData.map((timetable, index) => {
        //
        //     if (Number(timetable.id) > 0 && Number(timetable.del) > 0) {
        //         delTimetable = true;
        //         delTimetableId = timetable.id;
        //         delTimetableIndex = index;
        //     }
        // });
        //
        // if (delTimetable) {
        //     timetablesData.splice(delTimetableIndex, 1);
        // }

        let object = {
            // id: delTimetableId, // Если вдруг больше не нужно анализировать удаленные записи, и можно редактировать и перезаписывать.
            id: 0,
            year_timetable: year,
            period_timetable: 0,
            week_day_mon: 0,
            week_day_tue: 0,
            week_day_wed: 0,
            week_day_thu: 0,
            week_day_fri: 0,
            week_day_sat: 0,
            week_day_sun: 0,
            day_hour_0: 0,
            day_hour_1: 0,
            day_hour_2: 0,
            day_hour_3: 0,
            day_hour_4: 0,
            day_hour_5: 0,
            day_hour_6: 0,
            day_hour_7: 0,
            day_hour_8: 0,
            day_hour_9: 0,
            day_hour_10: 0,
            day_hour_11: 0,
            day_hour_12: 0,
            day_hour_13: 0,
            day_hour_14: 0,
            day_hour_15: 0,
            day_hour_16: 0,
            day_hour_17: 0,
            day_hour_18: 0,
            day_hour_19: 0,
            day_hour_20: 0,
            day_hour_21: 0,
            day_hour_22: 0,
            day_hour_23: 0,
            max_hours_day: 0,
            double_hours: 0,
            del: 0
        }

        timetablesData.push(object);

        this.setState({
            timetablesData: timetablesData
        });
    }

    timetablePeriod(indexId, period) {
        let timetablesData = this.state.timetablesData;

        timetablesData.map((timetable, index) => {
            if (indexId === index) {
                timetable.period_timetable = period;
            }
        });

        this.setState({
            timetablesData: timetablesData
        });
    }

    timetableWeekDay(indexId, weekDay) {
        let timetablesData = this.state.timetablesData;

        timetablesData.map((timetable, index) => {
            if (indexId === index) {

                switch(Number(weekDay)) {
                    case 1: timetable.week_day_mon = (timetable.week_day_mon > 0 ? 0 : 1); break;
                    case 2: timetable.week_day_tue = (timetable.week_day_tue > 0 ? 0 : 1); break;
                    case 3: timetable.week_day_wed = (timetable.week_day_wed > 0 ? 0 : 1); break;
                    case 4: timetable.week_day_thu = (timetable.week_day_thu > 0 ? 0 : 1); break;
                    case 5: timetable.week_day_fri = (timetable.week_day_fri > 0 ? 0 : 1); break;
                    case 6: timetable.week_day_sat = (timetable.week_day_sat > 0 ? 0 : 1); break;
                    case 7: timetable.week_day_sun = (timetable.week_day_sun > 0 ? 0 : 1); break;
                    default: return null;
                }
            }
        });

        this.setState({
            timetablesData: timetablesData
        });
    }

    timetableDayHour(indexId, dayHour) {
        let timetablesData = this.state.timetablesData;

        timetablesData.map((timetable, index) => {
            if (indexId === index) {

                switch(Number(dayHour)) {
                    case 0: timetable.day_hour_0 = (timetable.day_hour_0 > 0 ? 0 : 1); break;
                    case 1: timetable.day_hour_1 = (timetable.day_hour_1 > 0 ? 0 : 1); break;
                    case 2: timetable.day_hour_2 = (timetable.day_hour_2 > 0 ? 0 : 1); break;
                    case 3: timetable.day_hour_3 = (timetable.day_hour_3 > 0 ? 0 : 1); break;
                    case 4: timetable.day_hour_4 = (timetable.day_hour_4 > 0 ? 0 : 1); break;
                    case 5: timetable.day_hour_5 = (timetable.day_hour_5 > 0 ? 0 : 1); break;
                    case 6: timetable.day_hour_6 = (timetable.day_hour_6 > 0 ? 0 : 1); break;
                    case 7: timetable.day_hour_7 = (timetable.day_hour_7 > 0 ? 0 : 1); break;
                    case 8: timetable.day_hour_8 = (timetable.day_hour_8 > 0 ? 0 : 1); break;
                    case 9: timetable.day_hour_9 = (timetable.day_hour_9 > 0 ? 0 : 1); break;
                    case 10: timetable.day_hour_10 = (timetable.day_hour_10 > 0 ? 0 : 1); break;
                    case 11: timetable.day_hour_11 = (timetable.day_hour_11 > 0 ? 0 : 1); break;
                    case 12: timetable.day_hour_12 = (timetable.day_hour_12 > 0 ? 0 : 1); break;
                    case 13: timetable.day_hour_13 = (timetable.day_hour_13 > 0 ? 0 : 1); break;
                    case 14: timetable.day_hour_14 = (timetable.day_hour_14 > 0 ? 0 : 1); break;
                    case 15: timetable.day_hour_15 = (timetable.day_hour_15 > 0 ? 0 : 1); break;
                    case 16: timetable.day_hour_16 = (timetable.day_hour_16 > 0 ? 0 : 1); break;
                    case 17: timetable.day_hour_17 = (timetable.day_hour_17 > 0 ? 0 : 1); break;
                    case 18: timetable.day_hour_18 = (timetable.day_hour_18 > 0 ? 0 : 1); break;
                    case 19: timetable.day_hour_19 = (timetable.day_hour_19 > 0 ? 0 : 1); break;
                    case 20: timetable.day_hour_20 = (timetable.day_hour_20 > 0 ? 0 : 1); break;
                    case 21: timetable.day_hour_21 = (timetable.day_hour_21 > 0 ? 0 : 1); break;
                    case 22: timetable.day_hour_22 = (timetable.day_hour_22 > 0 ? 0 : 1); break;
                    case 23: timetable.day_hour_23 = (timetable.day_hour_23 > 0 ? 0 : 1); break;
                    default: return null;
                }
            }
        });

        this.setState({
            timetablesData: timetablesData
        });
    }

    doubleHoursTrue(indexId) {
        let timetablesData = this.state.timetablesData;

        timetablesData.map((timetable, index) => {
            if (indexId === index) {

                timetable.double_hours = 1;
            }
        });

        this.setState({
            timetablesData: timetablesData
        });
    }

    doubleHoursFalse(indexId) {
        let timetablesData = this.state.timetablesData;

        timetablesData.map((timetable, index) => {
            if (indexId === index) {

                timetable.double_hours = 0;
            }
        });

        this.setState({
            timetablesData: timetablesData
        });
    }

    maxHours(timetable) {

        return Number(timetable.day_hour_0) +
            Number(timetable.day_hour_1) +
            Number(timetable.day_hour_2) +
            Number(timetable.day_hour_3) +
            Number(timetable.day_hour_4) +
            Number(timetable.day_hour_5) +
            Number(timetable.day_hour_6) +
            Number(timetable.day_hour_7) +
            Number(timetable.day_hour_8) +
            Number(timetable.day_hour_9) +
            Number(timetable.day_hour_10) +
            Number(timetable.day_hour_11) +
            Number(timetable.day_hour_12) +
            Number(timetable.day_hour_13) +
            Number(timetable.day_hour_14) +
            Number(timetable.day_hour_15) +
            Number(timetable.day_hour_16) +
            Number(timetable.day_hour_17) +
            Number(timetable.day_hour_18) +
            Number(timetable.day_hour_19) +
            Number(timetable.day_hour_20) +
            Number(timetable.day_hour_21) +
            Number(timetable.day_hour_22) +
            Number(timetable.day_hour_23);
    }

    maxWeekDays(timetable) {

        return Number(timetable.week_day_mon) +
            Number(timetable.week_day_tue) +
            Number(timetable.week_day_wed) +
            Number(timetable.week_day_thu) +
            Number(timetable.week_day_fri) +
            Number(timetable.week_day_sat) +
            Number(timetable.week_day_sun);
    }


    maxHoursDayPlus(indexId) {
        let timetablesData = this.state.timetablesData;

        timetablesData.map((timetable, index) => {
            if (indexId === index) {

                if (Number(timetable.max_hours_day) < (this.maxHours(timetable))) {

                    timetable.max_hours_day = Number(timetable.max_hours_day) + 1;
                }
            }
        });

        this.setState({
            timetablesData: timetablesData
        });
    }

    maxHoursDayMinus(indexId) {
        let timetablesData = this.state.timetablesData;

        timetablesData.map((timetable, index) => {
            if (indexId === index) {

                if (Number(timetable.max_hours_day) > 0) {

                    timetable.max_hours_day = Number(timetable.max_hours_day) - 1;
                }
            }
        });

        this.setState({
            timetablesData: timetablesData
        });
    }

    delTimetable(indexId) {
        let timetablesData = this.state.timetablesData;

        let delTimetable = false;

        timetablesData.map((timetable, index) => {
            if (indexId === index) {
                if (Number(timetable.id) > 0) {
                    timetable.del = Date.now();
                }

                else {
                    delTimetable = true;
                }
            }
        });

        if (delTimetable) {
            timetablesData.splice(indexId, 1);
        }

        this.setState({
            timetablesData: timetablesData
        });
    }


    render() {
        const { isMount, isOpen, pageError, trainingScheduleData, isEditTypeWeeks, typeWeeksData, typeWeekNumber, isEditTimetables, timetablesData } = this.state;

        let modalData = (objectId, objectData) => {
            return {
                objectId: objectId,
                objectData: objectData
            };
        }

        let typeWeek = (isEditTypeWeeks, typeWeek, onClick) => {

            if (isEditTypeWeeks) {
                switch(Number(typeWeek)) {
                    case 0: return (<td className="Table__td active default pointer" onClick={onClick}/>);
                    case 1: return (<td className="Table__td active pointer" onClick={onClick}>Т</td>);
                    case 2: return (<td className="Table__td active pointer" onClick={onClick}>Э</td>);
                    case 3: return (<td className="Table__td active pointer" onClick={onClick}>У</td>);
                    case 4: return (<td className="Table__td active pointer" onClick={onClick}>П</td>);
                    case 5: return (<td className="Table__td active pointer" onClick={onClick}>Д</td>);
                    case 6: return (<td className="Table__td active pointer" onClick={onClick}>Г</td>);
                    case 7: return (<td className="Table__td active pointer" onClick={onClick}>К</td>);
                    default: return (<td className="Table__td active default pointer" onClick={onClick}/>);
                }
            }

            else {
                switch(Number(typeWeek)) {
                    case 0: return (<td className="Table__td default"/>);
                    case 1: return (<td className="Table__td">Т</td>);
                    case 2: return (<td className="Table__td">Э</td>);
                    case 3: return (<td className="Table__td">У</td>);
                    case 4: return (<td className="Table__td">П</td>);
                    case 5: return (<td className="Table__td">Д</td>);
                    case 6: return (<td className="Table__td">Г</td>);
                    case 7: return (<td className="Table__td">К</td>);
                    default: return (<td className="Table__td default"/>);
                }
            }
        }

        if (isMount) {

            if (pageError) {

                if (!isOpen) {

                    return (
                        <div className="Page__loader">
                            <i/>
                            <div className="Body_nodata">
                                Страница не найдена
                                <div style={{textAlign: 'center', marginTop: 48, fontSize: 32}}>
                                    ERROR 404
                                </div>
                            </div>
                            <i/>
                        </div>
                    );
                }

                else {

                    return (
                        <div className="Page__loader">
                            <i/>
                            <div>
                                <div className="Body_nodata">График удалён</div>
                                <div className="Header__path" style={{textAlign: 'center', marginTop: 48}}>
                                    <Link to={"/cabinet/training/schedules"}>
                                        <div className="Path__link Path__link_text Path__link_blue">Учебные графики</div>
                                    </Link>
                                </div>
                            </div>
                            <i/>
                        </div>
                    );
                }
            }

            else {

                if (!isOpen || !trainingScheduleData) {
                    return (<div className="Page__loader"><i/><Loading/><i/></div>);
                }

                else {

                    return (
                        <div className="Page__section">

                            <div className="Section__header Section__header_margin-bottom-16">
                                <div className="Header__title">
                                    <div className="Title__text">{trainingScheduleData.destination_schedule}</div>

                                    <div className="Button__edit"
                                         onClick={this.modalOpen.bind(this, 'modalTrainingScheduleEdit', modalData(trainingScheduleData.id, trainingScheduleData))}>
                                        {/*<svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">*/}
                                        {/*    <g id="Group_1663" data-name="Group 1663" transform="translate(-1765 -274)">*/}
                                        {/*        <circle id="Ellipse_62" data-name="Ellipse 62" cx="21" cy="21" r="21" transform="translate(1765 274)" fill="#2a3e4c" opacity="0"/>*/}
                                        {/*        <g id="edit.0a0e73c359245f3e48551d2cb2a488db" transform="translate(1774 283)">*/}
                                        {/*            <path id="Path_11" data-name="Path 11" d="M2.53,15.144a1.973,1.973,0,0,1,.57-1.216l8.56-8.56,1.395,1.395L5.384,14.432l1.395,1.395,7.671-7.671,1.395,1.395L8.173,17.222l1.395,1.395,7.671-7.671,1.395,1.395-8.56,8.56a1.973,1.973,0,0,1-1.216.57L3.076,22A.986.986,0,0,1,2,20.924ZM7.752,19.59l-2.947.268A1.131,1.131,0,0,0,4.5,19.5c-.25-.219-.366-.219-.366-.219l.276-3.033Z" fill="#373b50" fillRule="evenodd"/>*/}
                                        {/*            <path id="Path_12" data-name="Path 12" d="M13.054,3.972l1.395-1.395a1.972,1.972,0,0,1,2.789,0l4.184,4.184a1.972,1.972,0,0,1,0,2.789l-1.395,1.395Z" fill="#373b50"/>*/}
                                        {/*        </g>*/}
                                        {/*    </g>*/}
                                        {/*</svg>*/}

                                        <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">
                                            <g id="Group_2240" data-name="Group 2240" transform="translate(-471 -775)">
                                                <rect id="Rectangle_2757" data-name="Rectangle 2757" width="42" height="42" transform="translate(471 775)" fill="#f7f7f7" opacity="0"/>
                                                <g id="Group_2239" data-name="Group 2239" transform="translate(50 28)">
                                                    <path id="Path_965" data-name="Path 965" d="M2.657,17.495a2.447,2.447,0,0,1,.707-1.509L13.983,5.367l1.73,1.73L6.2,16.612l1.73,1.73,9.515-9.515,1.73,1.73L9.658,20.073l1.73,1.73L20.9,12.287l1.73,1.73L12.014,24.636a2.447,2.447,0,0,1-1.509.707l-7.171.652a1.223,1.223,0,0,1-1.329-1.329Zm6.478,5.516-3.656.332A1.4,1.4,0,0,0,5.1,22.9c-.31-.272-.454-.272-.454-.272l.342-3.762Z" transform="translate(428 754)" fill="#2a3e4c" fillRule="evenodd"/>
                                                    <path id="Path_966" data-name="Path 966" d="M13.054,4.447l1.731-1.731a2.447,2.447,0,0,1,3.461,0l5.192,5.192a2.447,2.447,0,0,1,0,3.461L21.707,13.1Z" transform="translate(429.852 754)" fill="#2a3e4c"/>
                                                </g>
                                            </g>
                                        </svg>

                                    </div>
                                </div>
                            </div>

                            <div className="Section__content">

                                <div className="TrainingSchedule">
                                    <div className="TrainingSchedule__body">

                                        <div className="Block__Item">
                                            <div className="Item__wrapper">
                                                <div className="Item__block Item__block_inline Item__block_margin-right Item__block_padding">
                                                    <div className="Item__label Item__label_inline">Срок</div>
                                                    <div className="Item__hr Item__hr_vertical Item__hr_inline"/>
                                                    <div className="Item__Text" style={{fontSize: 24, fontWeight: 900}}>{trainingScheduleData.period_training}</div>
                                                </div>

                                                <div className="Item__block Item__block_inline Item__block_margin-right Item__block_padding">
                                                    <div className="Item__label Item__label_inline">Форма</div>
                                                    <div className="Item__hr Item__hr_vertical Item__hr_inline"/>
                                                    <div className="Item__Text" style={{fontSize: 24, fontWeight: 900}}>{trainingScheduleData.form_training}</div>
                                                </div>

                                                <div className="Item__block Item__block_inline Item__block_margin-right Item__block_padding">
                                                    <div className="Item__label Item__label_inline">Квалификация</div>
                                                    <div className="Item__hr Item__hr_vertical Item__hr_inline"/>
                                                    <div className="Item__Text" style={{fontSize: 24, fontWeight: 900}}>{trainingScheduleData.training_qualifications}</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="Body__hr"/>

                                        {/*<div className="Body__label">Календарный учебный график</div>*/}
                                        <div className="Body__label" style={{fontSize: 32}}>Календарь</div>

                                            <div className="Schedule">



                                                    <table className="Schedule_table">

                                                        <tbody>
                                                            <tr>
                                                                <td rowSpan="2" className="Table__th Table__th_width-40"><div className="k"/></td>
                                                                <td rowSpan="2" className="Table__th Table__th_width-40"><div className="s"/></td>
                                                                <td colSpan="5" className="Table__th">Сентябрь</td>
                                                                <td colSpan="4" className="Table__th">Октябрь</td>
                                                                <td colSpan="4" className="Table__th">Ноябрь</td>
                                                                <td colSpan="5" className="Table__th">Декабрь</td>
                                                                <td colSpan="4" className="Table__th">Январь</td>
                                                                <td colSpan="4" className="Table__th">Февраль</td>
                                                                <td colSpan="5" className="Table__th">Март</td>
                                                                <td colSpan="4" className="Table__th">Апрель</td>
                                                                <td colSpan="4" className="Table__th">Май</td>
                                                                <td colSpan="5" className="Table__th">Июнь</td>
                                                                <td colSpan="4" className="Table__th">Июль</td>
                                                                <td colSpan="4" className="Table__th">Август</td>
                                                            </tr>
                                                            <tr>

                                                                <td className="Table__td">1</td>
                                                                <td className="Table__td">2</td>
                                                                <td className="Table__td">3</td>
                                                                <td className="Table__td">4</td>
                                                                <td className="Table__td">5</td>


                                                                <td  className="Table__td">6</td>
                                                                <td className="Table__td">7</td>
                                                                <td className="Table__td">8</td>
                                                                <td className="Table__td">9</td>


                                                                <td className="Table__td">10</td>
                                                                <td className="Table__td">11</td>
                                                                <td className="Table__td">12</td>
                                                                <td className="Table__td">13</td>

                                                                <td className="Table__td">14</td>

                                                                <td className="Table__td">15</td>
                                                                <td className="Table__td">16</td>
                                                                <td className="Table__td">17</td>
                                                                <td className="Table__td">18</td>


                                                                <td className="Table__td">19</td>
                                                                <td className="Table__td">20</td>
                                                                <td className="Table__td">21</td>
                                                                <td className="Table__td">22</td>

                                                                <td className="Table__td">23</td>
                                                                <td className="Table__td">24</td>
                                                                <td className="Table__td">25</td>
                                                                <td className="Table__td">26</td>

                                                                <td className="Table__td">27</td>
                                                                <td className="Table__td">28</td>
                                                                <td className="Table__td">29</td>
                                                                <td className="Table__td">30</td>
                                                                <td className="Table__td">31</td>


                                                                <td className="Table__td">32</td>
                                                                <td className="Table__td">33</td>
                                                                <td className="Table__td">34</td>
                                                                <td className="Table__td">35</td>


                                                                <td className="Table__td">36</td>
                                                                <td className="Table__td">37</td>
                                                                <td className="Table__td">38</td>
                                                                <td className="Table__td">39</td>


                                                                <td className="Table__td">40</td>
                                                                <td className="Table__td">41</td>
                                                                <td className="Table__td">42</td>
                                                                <td className="Table__td">43</td>
                                                                <td className="Table__td">44</td>


                                                                <td className="Table__td">45</td>
                                                                <td className="Table__td">46</td>
                                                                <td className="Table__td">47</td>
                                                                <td className="Table__td">48</td>


                                                                <td className="Table__td">49</td>
                                                                <td className="Table__td">50</td>
                                                                <td className="Table__td">51</td>
                                                                <td className="Table__td">52</td>

                                                            </tr>

                                                            {isEditTypeWeeks ? (
                                                                <React.Fragment>


                                                                    {typeWeeksData ? (
                                                                        typeWeeksData.map((schedule_period) => (

                                                                    schedule_period.periods.map((period, index) => (
                                                                        <tr key={index}>
                                                                            {index > 0 ? null : (
                                                                                <td rowSpan={schedule_period.periods.length} className="Table__td Table__td_big">{schedule_period.year}</td>
                                                                            )}
                                                                            <td className="Table__td Table__td_big">{period.period_schedule}</td>

                                                                            {typeWeek(isEditTypeWeeks, period.type_week_1, this.typeWeek.bind(this, period.id, '1', typeWeekNumber))}
                                                                            {typeWeek(isEditTypeWeeks, period.type_week_2, this.typeWeek.bind(this, period.id, '2', typeWeekNumber))}
                                                                            {typeWeek(isEditTypeWeeks, period.type_week_3, this.typeWeek.bind(this, period.id, '3', typeWeekNumber))}
                                                                            {typeWeek(isEditTypeWeeks, period.type_week_4, this.typeWeek.bind(this, period.id, '4', typeWeekNumber))}
                                                                            {typeWeek(isEditTypeWeeks, period.type_week_5, this.typeWeek.bind(this, period.id, '5', typeWeekNumber))}
                                                                            {typeWeek(isEditTypeWeeks, period.type_week_6, this.typeWeek.bind(this, period.id, '6', typeWeekNumber))}
                                                                            {typeWeek(isEditTypeWeeks, period.type_week_7, this.typeWeek.bind(this, period.id, '7', typeWeekNumber))}
                                                                            {typeWeek(isEditTypeWeeks, period.type_week_8, this.typeWeek.bind(this, period.id, '8', typeWeekNumber))}
                                                                            {typeWeek(isEditTypeWeeks, period.type_week_9, this.typeWeek.bind(this, period.id, '9', typeWeekNumber))}
                                                                            {typeWeek(isEditTypeWeeks, period.type_week_10, this.typeWeek.bind(this, period.id, '10', typeWeekNumber))}
                                                                            {typeWeek(isEditTypeWeeks, period.type_week_11, this.typeWeek.bind(this, period.id, '11', typeWeekNumber))}
                                                                            {typeWeek(isEditTypeWeeks, period.type_week_12, this.typeWeek.bind(this, period.id, '12', typeWeekNumber))}
                                                                            {typeWeek(isEditTypeWeeks, period.type_week_13, this.typeWeek.bind(this, period.id, '13', typeWeekNumber))}
                                                                            {typeWeek(isEditTypeWeeks, period.type_week_14, this.typeWeek.bind(this, period.id, '14', typeWeekNumber))}
                                                                            {typeWeek(isEditTypeWeeks, period.type_week_15, this.typeWeek.bind(this, period.id, '15', typeWeekNumber))}
                                                                            {typeWeek(isEditTypeWeeks, period.type_week_16, this.typeWeek.bind(this, period.id, '16', typeWeekNumber))}
                                                                            {typeWeek(isEditTypeWeeks, period.type_week_17, this.typeWeek.bind(this, period.id, '17', typeWeekNumber))}
                                                                            {typeWeek(isEditTypeWeeks, period.type_week_18, this.typeWeek.bind(this, period.id, '18', typeWeekNumber))}
                                                                            {typeWeek(isEditTypeWeeks, period.type_week_19, this.typeWeek.bind(this, period.id, '19', typeWeekNumber))}
                                                                            {typeWeek(isEditTypeWeeks, period.type_week_20, this.typeWeek.bind(this, period.id, '20', typeWeekNumber))}
                                                                            {typeWeek(isEditTypeWeeks, period.type_week_21, this.typeWeek.bind(this, period.id, '21', typeWeekNumber))}
                                                                            {typeWeek(isEditTypeWeeks, period.type_week_22, this.typeWeek.bind(this, period.id, '22', typeWeekNumber))}
                                                                            {typeWeek(isEditTypeWeeks, period.type_week_23, this.typeWeek.bind(this, period.id, '23', typeWeekNumber))}
                                                                            {typeWeek(isEditTypeWeeks, period.type_week_24, this.typeWeek.bind(this, period.id, '24', typeWeekNumber))}
                                                                            {typeWeek(isEditTypeWeeks, period.type_week_25, this.typeWeek.bind(this, period.id, '25', typeWeekNumber))}
                                                                            {typeWeek(isEditTypeWeeks, period.type_week_26, this.typeWeek.bind(this, period.id, '26', typeWeekNumber))}
                                                                            {typeWeek(isEditTypeWeeks, period.type_week_27, this.typeWeek.bind(this, period.id, '27', typeWeekNumber))}
                                                                            {typeWeek(isEditTypeWeeks, period.type_week_28, this.typeWeek.bind(this, period.id, '28', typeWeekNumber))}
                                                                            {typeWeek(isEditTypeWeeks, period.type_week_29, this.typeWeek.bind(this, period.id, '29', typeWeekNumber))}
                                                                            {typeWeek(isEditTypeWeeks, period.type_week_30, this.typeWeek.bind(this, period.id, '30', typeWeekNumber))}
                                                                            {typeWeek(isEditTypeWeeks, period.type_week_31, this.typeWeek.bind(this, period.id, '31', typeWeekNumber))}
                                                                            {typeWeek(isEditTypeWeeks, period.type_week_32, this.typeWeek.bind(this, period.id, '32', typeWeekNumber))}
                                                                            {typeWeek(isEditTypeWeeks, period.type_week_33, this.typeWeek.bind(this, period.id, '33', typeWeekNumber))}
                                                                            {typeWeek(isEditTypeWeeks, period.type_week_34, this.typeWeek.bind(this, period.id, '34', typeWeekNumber))}
                                                                            {typeWeek(isEditTypeWeeks, period.type_week_35, this.typeWeek.bind(this, period.id, '35', typeWeekNumber))}
                                                                            {typeWeek(isEditTypeWeeks, period.type_week_36, this.typeWeek.bind(this, period.id, '36', typeWeekNumber))}
                                                                            {typeWeek(isEditTypeWeeks, period.type_week_37, this.typeWeek.bind(this, period.id, '37', typeWeekNumber))}
                                                                            {typeWeek(isEditTypeWeeks, period.type_week_38, this.typeWeek.bind(this, period.id, '38', typeWeekNumber))}
                                                                            {typeWeek(isEditTypeWeeks, period.type_week_39, this.typeWeek.bind(this, period.id, '39', typeWeekNumber))}
                                                                            {typeWeek(isEditTypeWeeks, period.type_week_40, this.typeWeek.bind(this, period.id, '40', typeWeekNumber))}
                                                                            {typeWeek(isEditTypeWeeks, period.type_week_41, this.typeWeek.bind(this, period.id, '41', typeWeekNumber))}
                                                                            {typeWeek(isEditTypeWeeks, period.type_week_42, this.typeWeek.bind(this, period.id, '42', typeWeekNumber))}
                                                                            {typeWeek(isEditTypeWeeks, period.type_week_43, this.typeWeek.bind(this, period.id, '43', typeWeekNumber))}
                                                                            {typeWeek(isEditTypeWeeks, period.type_week_44, this.typeWeek.bind(this, period.id, '44', typeWeekNumber))}
                                                                            {typeWeek(isEditTypeWeeks, period.type_week_45, this.typeWeek.bind(this, period.id, '45', typeWeekNumber))}
                                                                            {typeWeek(isEditTypeWeeks, period.type_week_46, this.typeWeek.bind(this, period.id, '46', typeWeekNumber))}
                                                                            {typeWeek(isEditTypeWeeks, period.type_week_47, this.typeWeek.bind(this, period.id, '47', typeWeekNumber))}
                                                                            {typeWeek(isEditTypeWeeks, period.type_week_48, this.typeWeek.bind(this, period.id, '48', typeWeekNumber))}
                                                                            {typeWeek(isEditTypeWeeks, period.type_week_49, this.typeWeek.bind(this, period.id, '49', typeWeekNumber))}
                                                                            {typeWeek(isEditTypeWeeks, period.type_week_50, this.typeWeek.bind(this, period.id, '50', typeWeekNumber))}
                                                                            {typeWeek(isEditTypeWeeks, period.type_week_51, this.typeWeek.bind(this, period.id, '51', typeWeekNumber))}
                                                                            {typeWeek(isEditTypeWeeks, period.type_week_52, this.typeWeek.bind(this, period.id, '52', typeWeekNumber))}
                                                                        </tr>
                                                                    ))
                                                                ))) : null}
                                                                </React.Fragment>
                                                            ) : (

                                                                trainingScheduleData.schedule_periods ? (
                                                                trainingScheduleData.schedule_periods.map((schedule_period) => (

                                                                    schedule_period.periods.map((period, index) => (
                                                                        <tr key={index}>
                                                                            {index > 0 ? null : (
                                                                                <td rowSpan={schedule_period.periods.length} className="Table__td Table__td_big">{schedule_period.year}</td>
                                                                            )}
                                                                            <td className="Table__td Table__td_big pointer active" onClick={this.modalOpen.bind(this, 'modalTrainingSchedulePeriodEdit', modalData(trainingScheduleData.id, period))}>{period.period_schedule}</td>


                                                                            {typeWeek(isEditTypeWeeks,period.type_week_1)}
                                                                            {typeWeek(isEditTypeWeeks,period.type_week_2)}
                                                                            {typeWeek(isEditTypeWeeks,period.type_week_3)}
                                                                            {typeWeek(isEditTypeWeeks,period.type_week_4)}
                                                                            {typeWeek(isEditTypeWeeks,period.type_week_5)}
                                                                            {typeWeek(isEditTypeWeeks,period.type_week_6)}
                                                                            {typeWeek(isEditTypeWeeks,period.type_week_7)}
                                                                            {typeWeek(isEditTypeWeeks,period.type_week_8)}
                                                                            {typeWeek(isEditTypeWeeks,period.type_week_9)}
                                                                            {typeWeek(isEditTypeWeeks,period.type_week_10)}
                                                                            {typeWeek(isEditTypeWeeks,period.type_week_11)}
                                                                            {typeWeek(isEditTypeWeeks,period.type_week_12)}
                                                                            {typeWeek(isEditTypeWeeks,period.type_week_13)}
                                                                            {typeWeek(isEditTypeWeeks,period.type_week_14)}
                                                                            {typeWeek(isEditTypeWeeks,period.type_week_15)}
                                                                            {typeWeek(isEditTypeWeeks,period.type_week_16)}
                                                                            {typeWeek(isEditTypeWeeks,period.type_week_17)}
                                                                            {typeWeek(isEditTypeWeeks,period.type_week_18)}
                                                                            {typeWeek(isEditTypeWeeks,period.type_week_19)}
                                                                            {typeWeek(isEditTypeWeeks,period.type_week_20)}
                                                                            {typeWeek(isEditTypeWeeks,period.type_week_21)}
                                                                            {typeWeek(isEditTypeWeeks,period.type_week_22)}
                                                                            {typeWeek(isEditTypeWeeks,period.type_week_23)}
                                                                            {typeWeek(isEditTypeWeeks,period.type_week_24)}
                                                                            {typeWeek(isEditTypeWeeks,period.type_week_25)}
                                                                            {typeWeek(isEditTypeWeeks,period.type_week_26)}
                                                                            {typeWeek(isEditTypeWeeks,period.type_week_27)}
                                                                            {typeWeek(isEditTypeWeeks,period.type_week_28)}
                                                                            {typeWeek(isEditTypeWeeks,period.type_week_29)}
                                                                            {typeWeek(isEditTypeWeeks,period.type_week_30)}
                                                                            {typeWeek(isEditTypeWeeks,period.type_week_31)}
                                                                            {typeWeek(isEditTypeWeeks,period.type_week_32)}
                                                                            {typeWeek(isEditTypeWeeks,period.type_week_33)}
                                                                            {typeWeek(isEditTypeWeeks,period.type_week_34)}
                                                                            {typeWeek(isEditTypeWeeks,period.type_week_35)}
                                                                            {typeWeek(isEditTypeWeeks,period.type_week_36)}
                                                                            {typeWeek(isEditTypeWeeks,period.type_week_37)}
                                                                            {typeWeek(isEditTypeWeeks,period.type_week_38)}
                                                                            {typeWeek(isEditTypeWeeks,period.type_week_39)}
                                                                            {typeWeek(isEditTypeWeeks,period.type_week_40)}
                                                                            {typeWeek(isEditTypeWeeks,period.type_week_41)}
                                                                            {typeWeek(isEditTypeWeeks,period.type_week_42)}
                                                                            {typeWeek(isEditTypeWeeks,period.type_week_43)}
                                                                            {typeWeek(isEditTypeWeeks,period.type_week_44)}
                                                                            {typeWeek(isEditTypeWeeks,period.type_week_45)}
                                                                            {typeWeek(isEditTypeWeeks,period.type_week_46)}
                                                                            {typeWeek(isEditTypeWeeks,period.type_week_47)}
                                                                            {typeWeek(isEditTypeWeeks,period.type_week_48)}
                                                                            {typeWeek(isEditTypeWeeks,period.type_week_49)}
                                                                            {typeWeek(isEditTypeWeeks,period.type_week_50)}
                                                                            {typeWeek(isEditTypeWeeks,period.type_week_51)}
                                                                            {typeWeek(isEditTypeWeeks,period.type_week_52)}
                                                                        </tr>
                                                                    ))
                                                                ))) : null
                                                            )}
                                                        </tbody>
                                                    </table>

                                                {isEditTypeWeeks ? (
                                                    <div className="Schedule__add Schedule__add_disabled">
                                                        <div style={{display: "inline-block", opacity: 0.25}}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">
                                                                <g id="Group_1662" data-name="Group 1662" transform="translate(-1826 -274)">
                                                                    <circle id="Ellipse_61" data-name="Ellipse 61" cx="21" cy="21" r="21" transform="translate(1826 274)" fill="#2A3E4C"/>
                                                                    <rect id="Rectangle_2052" data-name="Rectangle 2052" width="4" height="14" rx="1" transform="translate(1845 288)" fill="#fff"/>
                                                                    <rect id="Rectangle_2053" data-name="Rectangle 2053" width="4" height="14" rx="1" transform="translate(1854 293) rotate(90)" fill="#fff"/>
                                                                </g>
                                                            </svg>
                                                        </div>
                                                        {/*<div className="Add__text">Курс / Семестр</div>*/}
                                                        <div className="Add__text" style={{color: "#2A3E4C", opacity: 0.25}}>Семестр</div>
                                                    </div>
                                                ) : (
                                                    <div className="Schedule__add"
                                                         onClick={this.modalOpen.bind(this, 'modalTrainingSchedulePeriodAdd', modalData(trainingScheduleData.id, null))}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">
                                                            <g id="Group_1662" data-name="Group 1662" transform="translate(-1826 -274)">
                                                                <circle id="Ellipse_61" data-name="Ellipse 61" cx="21" cy="21" r="21" transform="translate(1826 274)" fill="#0061ff"/>
                                                                <rect id="Rectangle_2052" data-name="Rectangle 2052" width="4" height="14" rx="1" transform="translate(1845 288)" fill="#fff"/>
                                                                <rect id="Rectangle_2053" data-name="Rectangle 2053" width="4" height="14" rx="1" transform="translate(1854 293) rotate(90)" fill="#fff"/>
                                                            </g>
                                                        </svg>
                                                        {/*<div className="Add__text">Курс / Семестр</div>*/}
                                                        <div className="Add__text">Семестр</div>
                                                    </div>
                                                )}

                                                <div className="Schedule__switch" style={{marginLeft: 42, paddingTop: 3, verticalAlign: "top", cursor: "pointer"}} onClick={this.isEditTypeWeeks.bind(this, isEditTypeWeeks)}>
                                                    <div className="Switch__wrapper" style={{marginRight: 12}}>
                                                        <label className={isEditTypeWeeks ? 'switch switch_active' : 'switch'}>
                                                            <span className="switch__lever" />
                                                            {/*<span className="switch__index" />*/}
                                                        </label>
                                                    </div>
                                                    {/*<div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                                    <div className="Switch__text">Редактировать</div>
                                                </div>

                                                {isEditTypeWeeks ? (
                                                <table className="Schedule__info">
                                                    <tbody>
                                                        <tr className="Info__tr">

                                                            <td className="Info__td">
                                                                <div className={typeWeekNumber === 1 ? 'Info__box pointer active' : 'Info__box pointer'} onClick={this.typeWeekNumber.bind(this, 1)}>
                                                                    <div className="Box__logo">Т</div>
                                                                    <div className="Box__text Box__text_padding-7">Теоретическое <br/>обучение</div>
                                                                </div>
                                                            </td>
                                                            <td className="Info__td">
                                                                <div className={typeWeekNumber === 2 ? 'Info__box pointer active' : 'Info__box pointer'} onClick={this.typeWeekNumber.bind(this, 2)}>
                                                                    <div className="Box__logo">Э</div>
                                                                    <div className="Box__text Box__text_padding-7">Экзаменационная <br/>сессия</div>
                                                                </div>
                                                            </td>
                                                            <td className="Info__td">
                                                                <div className={typeWeekNumber === 3 ? 'Info__box pointer active' : 'Info__box pointer'} onClick={this.typeWeekNumber.bind(this, 3)}>
                                                                    <div className="Box__logo">У</div>
                                                                    <div className="Box__text Box__text_padding-7">Учебная <br/>практика</div>
                                                                </div>
                                                            </td>
                                                            <td className="Info__td">
                                                                <div className={typeWeekNumber === 4 ? 'Info__box pointer active' : 'Info__box pointer'} onClick={this.typeWeekNumber.bind(this, 4)}>
                                                                    <div className="Box__logo">П</div>
                                                                    <div className="Box__text Box__text_padding-7">Производственная <br/>практика</div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr className="Info__tr">

                                                            <td className="Info__td">
                                                                <div className={typeWeekNumber === 5 ? 'Info__box pointer active' : 'Info__box pointer'} onClick={this.typeWeekNumber.bind(this, 5)}>
                                                                    <div className="Box__logo">Д</div>
                                                                    <div className="Box__text Box__text_padding-7">Подготовка к защите выпускной<br/>
                                                                        квалификационной работы (ВКР)</div>
                                                                </div>
                                                            </td>
                                                            <td className="Info__td">
                                                                <div className={typeWeekNumber === 6 ? 'Info__box pointer active' : 'Info__box pointer'} onClick={this.typeWeekNumber.bind(this, 6)}>
                                                                    <div className="Box__logo">Г</div>
                                                                    <div className="Box__text Box__text_padding-7">Государственный экзамен<br/>
                                                                        и процедура защиты ВКР</div>
                                                                </div>
                                                            </td>
                                                            <td className="Info__td">
                                                                <div className={typeWeekNumber === 7 ? 'Info__box pointer active' : 'Info__box pointer'} onClick={this.typeWeekNumber.bind(this, 7)}>
                                                                    <div className="Box__logo">К</div>
                                                                    <div className="Box__text">Каникулы</div>
                                                                </div>
                                                            </td>

                                                            <td>

                                                                {/*<div className="Schedule__switch">*/}
                                                                {/*    <div className="Switch__wrapper">*/}
                                                                {/*        <label className="switch switch_active">*/}
                                                                {/*            <span className="switch__lever" />*/}
                                                                {/*            /!*<span className="switch__index" />*!/*/}
                                                                {/*        </label>*/}
                                                                {/*    </div>*/}
                                                                {/*    <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                                                {/*    <div className="Switch__text">Редактировать календарь</div>*/}
                                                                {/*</div>*/}

                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                    ) : (
                                                    <table className="Schedule__info">
                                                        <tbody>
                                                        <tr className="Info__tr">

                                                            <td className="Info__td">
                                                                <div className="Info__box">
                                                                    <div className="Box__logo">Т</div>
                                                                    <div className="Box__text Box__text_padding-7">Теоретическое <br/>обучение</div>
                                                                </div>
                                                            </td>
                                                            <td className="Info__td">
                                                                <div className="Info__box">
                                                                    <div className="Box__logo">Э</div>
                                                                    <div className="Box__text Box__text_padding-7">Экзаменационная <br/>сессия</div>
                                                                </div>
                                                            </td>
                                                            <td className="Info__td">
                                                                <div className="Info__box">
                                                                    <div className="Box__logo">У</div>
                                                                    <div className="Box__text Box__text_padding-7">Учебная <br/>практика</div>
                                                                </div>
                                                            </td>
                                                            <td className="Info__td">
                                                                <div className="Info__box">
                                                                    <div className="Box__logo">П</div>
                                                                    <div className="Box__text Box__text_padding-7">Производственная <br/>практика</div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr className="Info__tr">

                                                            <td className="Info__td">
                                                                <div className="Info__box">
                                                                    <div className="Box__logo">Д</div>
                                                                    <div className="Box__text Box__text_padding-7">Подготовка к защите выпускной<br/>
                                                                        квалификационной работы (ВКР)</div>
                                                                </div>
                                                            </td>
                                                            <td className="Info__td">
                                                                <div className="Info__box">
                                                                    <div className="Box__logo">Г</div>
                                                                    <div className="Box__text Box__text_padding-7">Государственный экзамен<br/>
                                                                        и процедура защиты ВКР</div>
                                                                </div>
                                                            </td>
                                                            <td className="Info__td">
                                                                <div className="Info__box">
                                                                    <div className="Box__logo">К</div>
                                                                    <div className="Box__text">Каникулы</div>
                                                                </div>
                                                            </td>

                                                            <td>

                                                                {/*<div className="Schedule__switch">*/}
                                                                {/*    <div className="Switch__wrapper">*/}
                                                                {/*        <label className="switch switch_active">*/}
                                                                {/*            <span className="switch__lever" />*/}
                                                                {/*            /!*<span className="switch__index" />*!/*/}
                                                                {/*        </label>*/}
                                                                {/*    </div>*/}
                                                                {/*    <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                                                {/*    <div className="Switch__text">Редактировать календарь</div>*/}
                                                                {/*</div>*/}

                                                            </td>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                )}

                                        {/*<div className="Body__hr"/>*/}

                                        {/*        <div className="Schedule__switch" style={{marginBottom: 42}} onClick={this.isEditTypeWeeks.bind(this, isEditTypeWeeks)}>*/}
                                        {/*            <div className="Switch__wrapper">*/}
                                        {/*                <label className={isEditTypeWeeks ? 'switch switch_active' : 'switch'}>*/}
                                        {/*                    <span className="switch__lever" />*/}
                                        {/*                    /!*<span className="switch__index" />*!/*/}
                                        {/*                </label>*/}
                                        {/*            </div>*/}
                                        {/*            <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                        {/*            <div className="Switch__text">Редактировать календарь</div>*/}
                                        {/*        </div>*/}


                                        <div className="Body__hr"/>

                                        <div className="Body__label" style={{fontSize: 32}}>Расписание</div>

                                        {/*<div className="Schedule__period">*/}
                                        {/*    <div className="Info__tr Info__tr_inline-block">*/}
                                        {/*        <div className="Info__td">*/}
                                        {/*            <div className="Info__box">*/}
                                        {/*                <div className="Box__logo">1</div>*/}
                                        {/*                <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                        {/*                <div className="Box__text">Курс</div>*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*    <div className="Info__tr Info__tr_inline-block">*/}
                                        {/*            <div className="Info__td">*/}
                                        {/*            <div className="Info__box">*/}
                                        {/*                <div className="Box__logo">*/}
                                        {/*                    /!*<svg xmlns="http://www.w3.org/2000/svg" width="32" height="16" viewBox="0 0 32 16">*!/*/}
                                        {/*                    /!*    <path id="infinity-solid" d="M23.555,96c-3.305,0-5.89,2.065-7.555,3.93C14.335,98.065,11.75,96,8.445,96a8.012,8.012,0,1,0,0,16c3.305,0,5.89-2.065,7.555-3.93,1.665,1.865,4.25,3.93,7.555,3.93a8.012,8.012,0,1,0,0-16ZM8.445,107.2a3.227,3.227,0,1,1,0-6.4c1.91,0,3.67,1.8,4.7,3.2C12.125,105.38,10.35,107.2,8.445,107.2Zm15.11,0c-1.91,0-3.67-1.805-4.7-3.2,1.02-1.38,2.8-3.2,4.7-3.2a3.227,3.227,0,1,1,0,6.4Z" transform="translate(0 -96)" fill="#2a3e4c"/>*!/*/}
                                        {/*                    /!*</svg>*!/*/}

                                        {/*                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 16">*/}
                                        {/*                        <g id="Group_1675" data-name="Group 1675" transform="translate(-815 -806)">*/}
                                        {/*                            <path id="infinity-solid" d="M17.659,96a7.837,7.837,0,0,0-5.664,2.947A7.837,7.837,0,0,0,6.33,96a6.007,6.007,0,1,0,0,12,7.837,7.837,0,0,0,5.664-2.947,7.837,7.837,0,0,0,5.664,2.947,6.007,6.007,0,1,0,0-12Zm-11.329,8.4a2.419,2.419,0,1,1,0-4.8c1.432,0,2.752,1.35,3.524,2.4C9.089,103.03,7.759,104.394,6.33,104.394Zm11.329,0c-1.432,0-2.752-1.353-3.524-2.4.765-1.035,2.1-2.4,3.524-2.4a2.419,2.419,0,1,1,0,4.8Z" transform="translate(815.005 712.012)" fill="#2a3e4c"/>*/}
                                        {/*                            <rect id="Rectangle_2081" data-name="Rectangle 2081" width="24" height="16" transform="translate(815 806)" fill="#f7f7f7" opacity="0"/>*/}
                                        {/*                        </g>*/}
                                        {/*                    </svg>*/}

                                        {/*                </div>*/}
                                        {/*                <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                        {/*                <div className="Box__text">Все семестры</div>*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}

                                        {/*        <div className="Body__label">Недели</div>*/}

                                        {/*        <div className="Schedule__weeks">*/}
                                        {/*            <div className="Info__tr">*/}
                                        {/*                <div className="Info__td">*/}
                                        {/*                    <div className="Info__box">*/}
                                        {/*                        <div className="Box__logo">Т</div>*/}
                                        {/*                        <div className="Box__text Box__text_padding-7">Теоретическое <br/>обучение</div>*/}
                                        {/*                    </div>*/}
                                        {/*                </div>*/}
                                        {/*                <div className="Info__td">*/}
                                        {/*                    <div className="Info__box">*/}
                                        {/*                        <div className="Box__logo">Э</div>*/}
                                        {/*                        <div className="Box__text Box__text_padding-7">Экзаменационная <br/>сессия</div>*/}
                                        {/*                    </div>*/}
                                        {/*                </div>*/}
                                        {/*                <div className="Info__td">*/}
                                        {/*                    <div className="Info__box">*/}
                                        {/*                        <div className="Box__logo">У</div>*/}
                                        {/*                        <div className="Box__text Box__text_padding-7">Учебная <br/>практика</div>*/}
                                        {/*                    </div>*/}
                                        {/*                </div>*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}


                                        {/*        <div className="Body__label">Дни в неделе</div>*/}

                                        {/*        <div className="Schedule__week">*/}
                                        {/*            <div className="Info__tr Info__tr_inline-block">*/}
                                        {/*                <div className="Info__td">*/}
                                        {/*                    <div className="Info__box">*/}
                                        {/*                        <div className="Box__logo">Пн</div>*/}
                                        {/*                    </div>*/}
                                        {/*                </div>*/}
                                        {/*                <div className="Info__td">*/}
                                        {/*                    <div className="Info__box">*/}
                                        {/*                        <div className="Box__logo">Вт</div>*/}
                                        {/*                    </div>*/}
                                        {/*                </div>*/}
                                        {/*                <div className="Info__td">*/}
                                        {/*                    <div className="Info__box">*/}
                                        {/*                        <div className="Box__logo">Ср</div>*/}
                                        {/*                    </div>*/}
                                        {/*                </div>*/}
                                        {/*                <div className="Info__td">*/}
                                        {/*                    <div className="Info__box">*/}
                                        {/*                        <div className="Box__logo">Чт</div>*/}
                                        {/*                    </div>*/}
                                        {/*                </div>*/}
                                        {/*                <div className="Info__td">*/}
                                        {/*                    <div className="Info__box">*/}
                                        {/*                        <div className="Box__logo">Пт</div>*/}
                                        {/*                    </div>*/}
                                        {/*                </div>*/}
                                        {/*                <div className="Info__td">*/}
                                        {/*                    <div className="Info__box">*/}
                                        {/*                        <div className="Box__logo">Сб</div>*/}
                                        {/*                    </div>*/}
                                        {/*                </div>*/}
                                        {/*                <div className="Info__td">*/}
                                        {/*                    <div className="Info__box">*/}
                                        {/*                        <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                        {/*                        <div className="Box__text">Учебные дни</div>*/}
                                        {/*                    </div>*/}
                                        {/*                </div>*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}

                                        {/*        <div className="Body__label" style={{marginBottom: 16}}>Часы в день</div>*/}








                                        {/*        <div className="Schedule__hours">*/}
                                        {/*            <div className="Info__tr Info__tr_inline-block">*/}
                                        {/*                /!*<div className="Info__td">*!/*/}
                                        {/*                /!*    <div className="Info__box Info__box_default">*!/*/}
                                        {/*                /!*        <div className="Box__logo">0</div>*!/*/}
                                        {/*                /!*    </div>*!/*/}
                                        {/*                /!*</div>*!/*/}
                                        {/*                /!*<div className="Info__td">*!/*/}
                                        {/*                /!*    <div className="Info__box">*!/*/}
                                        {/*                /!*        <div className="Box__logo">1</div>*!/*/}
                                        {/*                /!*    </div>*!/*/}
                                        {/*                /!*</div>*!/*/}
                                        {/*                /!*<div className="Info__td">*!/*/}
                                        {/*                /!*    <div className="Info__box">*!/*/}
                                        {/*                /!*        <div className="Box__logo">2</div>*!/*/}
                                        {/*                /!*    </div>*!/*/}
                                        {/*                /!*</div>*!/*/}
                                        {/*                /!*<div className="Info__td">*!/*/}
                                        {/*                /!*    <div className="Info__box">*!/*/}
                                        {/*                /!*        <div className="Box__logo">3</div>*!/*/}
                                        {/*                /!*    </div>*!/*/}
                                        {/*                /!*</div>*!/*/}
                                        {/*                /!*<div className="Info__td">*!/*/}
                                        {/*                /!*    <div className="Info__box">*!/*/}
                                        {/*                /!*        <div className="Box__logo">4</div>*!/*/}
                                        {/*                /!*    </div>*!/*/}
                                        {/*                /!*</div>*!/*/}
                                        {/*                /!*<div className="Info__td">*!/*/}
                                        {/*                /!*    <div className="Info__box">*!/*/}
                                        {/*                /!*        <div className="Box__logo">5</div>*!/*/}
                                        {/*                /!*    </div>*!/*/}
                                        {/*                /!*</div>*!/*/}
                                        {/*                /!*<div className="Info__td">*!/*/}
                                        {/*                /!*    <div className="Info__box">*!/*/}
                                        {/*                /!*        <div className="Box__logo">6</div>*!/*/}
                                        {/*                /!*    </div>*!/*/}
                                        {/*                /!*</div>*!/*/}
                                        {/*                /!*<div className="Info__td">*!/*/}
                                        {/*                /!*    <div className="Info__box">*!/*/}
                                        {/*                /!*        <div className="Box__logo">7</div>*!/*/}
                                        {/*                /!*    </div>*!/*/}
                                        {/*                /!*</div>*!/*/}
                                        {/*                /!*<div className="Info__td">*!/*/}
                                        {/*                /!*    <div className="Info__box">*!/*/}
                                        {/*                /!*        <div className="Box__logo">8</div>*!/*/}
                                        {/*                /!*    </div>*!/*/}
                                        {/*                /!*</div>*!/*/}
                                        {/*                <div className="Info__td Info__box_default">*/}
                                        {/*                    <div className="Info__box">*/}
                                        {/*                        <div className="Box__logo">9</div>*/}
                                        {/*                    </div>*/}
                                        {/*                </div>*/}
                                        {/*                <div className="Info__td">*/}
                                        {/*                    <div className="Info__box active">*/}
                                        {/*                        <div className="Box__logo">10</div>*/}
                                        {/*                    </div>*/}
                                        {/*                </div>*/}
                                        {/*                <div className="Info__td">*/}
                                        {/*                    <div className="Info__box pointer">*/}
                                        {/*                        <div className="Box__logo">11</div>*/}
                                        {/*                    </div>*/}
                                        {/*                </div>*/}
                                        {/*                <div className="Info__td">*/}
                                        {/*                    <div className="Info__box">*/}
                                        {/*                        <div className="Box__logo">12</div>*/}
                                        {/*                    </div>*/}
                                        {/*                </div>*/}
                                        {/*                <div className="Info__td">*/}
                                        {/*                    <div className="Info__box">*/}
                                        {/*                        <div className="Box__logo">13</div>*/}
                                        {/*                    </div>*/}
                                        {/*                </div>*/}
                                        {/*                <div className="Info__td">*/}
                                        {/*                    <div className="Info__box">*/}
                                        {/*                        <div className="Box__logo">14</div>*/}
                                        {/*                    </div>*/}
                                        {/*                </div>*/}
                                        {/*                <div className="Info__td">*/}
                                        {/*                    <div className="Info__box">*/}
                                        {/*                        <div className="Box__logo">15</div>*/}
                                        {/*                    </div>*/}
                                        {/*                </div>*/}
                                        {/*                <div className="Info__td">*/}
                                        {/*                    <div className="Info__box">*/}
                                        {/*                        <div className="Box__logo">16</div>*/}
                                        {/*                    </div>*/}
                                        {/*                </div>*/}
                                        {/*                <div className="Info__td">*/}
                                        {/*                    <div className="Info__box">*/}
                                        {/*                        <div className="Box__logo">17</div>*/}
                                        {/*                    </div>*/}
                                        {/*                </div>*/}
                                        {/*                <div className="Info__td">*/}
                                        {/*                    <div className="Info__box">*/}
                                        {/*                        <div className="Box__logo">18</div>*/}
                                        {/*                    </div>*/}
                                        {/*                </div>*/}
                                        {/*                /!*<div className="Info__td">*!/*/}
                                        {/*                /!*    <div className="Info__box">*!/*/}
                                        {/*                /!*        <div className="Box__logo">19</div>*!/*/}
                                        {/*                /!*    </div>*!/*/}
                                        {/*                /!*</div>*!/*/}
                                        {/*                /!*<div className="Info__td">*!/*/}
                                        {/*                /!*    <div className="Info__box">*!/*/}
                                        {/*                /!*        <div className="Box__logo">20</div>*!/*/}
                                        {/*                /!*    </div>*!/*/}
                                        {/*                /!*</div>*!/*/}
                                        {/*                /!*<div className="Info__td">*!/*/}
                                        {/*                /!*    <div className="Info__box">*!/*/}
                                        {/*                /!*        <div className="Box__logo">21</div>*!/*/}
                                        {/*                /!*    </div>*!/*/}
                                        {/*                /!*</div>*!/*/}
                                        {/*                /!*<div className="Info__td">*!/*/}
                                        {/*                /!*    <div className="Info__box">*!/*/}
                                        {/*                /!*        <div className="Box__logo">22</div>*!/*/}
                                        {/*                /!*    </div>*!/*/}
                                        {/*                /!*</div>*!/*/}
                                        {/*                /!*<div className="Info__td">*!/*/}
                                        {/*                /!*    <div className="Info__box">*!/*/}
                                        {/*                /!*        <div className="Box__logo">23</div>*!/*/}
                                        {/*                /!*    </div>*!/*/}
                                        {/*                /!*</div>*!/*/}

                                        {/*                <div className="Info__td">*/}
                                        {/*                    <div className="Info__box">*/}
                                        {/*                        <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                        {/*                        <div className="Box__text">Учебные часы</div>*/}
                                        {/*                    </div>*/}
                                        {/*                </div>*/}
                                        {/*            </div>*/}
                                        {/*        </div>*/}


                                        {/*        <div className="Block__Item" style={{marginBottom: 42}}>*/}
                                        {/*            <div className="Item__wrapper">*/}

                                        {/*                <div className="Item__block Item__block_inline Item__block_margin-right Item__block_padding">*/}
                                        {/*                    <div className="Item__label Item__label_inline">Сдвоенные часы</div>*/}
                                        {/*                    <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                        {/*                    <div style={{display: "inline-block", marginRight: 12, cursor: "pointer"}}>*/}
                                        {/*                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">*/}
                                        {/*                            <g id="Group_1680" data-name="Group 1680" transform="translate(-1392 -522)">*/}
                                        {/*                                <rect id="Rectangle_2080" data-name="Rectangle 2080" width="32" height="32" rx="16" transform="translate(1392 522)" fill="#cacfd3"/>*/}
                                        {/*                                <g id="Group_1677" data-name="Group 1677" transform="translate(1948 -930) rotate(90)">*/}
                                        {/*                                    <g id="Group_920" data-name="Group 920" transform="translate(420.563 1105.602) rotate(-45)">*/}
                                        {/*                                        <rect id="Rectangle_1180" data-name="Rectangle 1180" width="10" height="4" rx="1" transform="translate(1136.293 341.001)" fill="#fff"/>*/}
                                        {/*                                        <rect id="Rectangle_1181" data-name="Rectangle 1181" width="4" height="10" rx="1" transform="translate(1136.293 335)" fill="#fff"/>*/}
                                        {/*                                    </g>*/}
                                        {/*                                </g>*/}
                                        {/*                            </g>*/}
                                        {/*                        </svg>*/}

                                        {/*                    </div>*/}
                                        {/*                    <div className="Item__Text" style={{fontSize: 24, fontWeight: 900}}>Да</div>*/}
                                        {/*                    <div style={{display: "inline-block", marginLeft: 12, cursor: "default", opacity: 0.25}}>*/}
                                        {/*                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">*/}
                                        {/*                            <g id="Group_1679" data-name="Group 1679" transform="translate(-1488 -524)">*/}
                                        {/*                                <rect id="Rectangle_2081" data-name="Rectangle 2081" width="32" height="32" rx="16" transform="translate(1488 524)" fill="#cacfd3"/>*/}
                                        {/*                                <g id="Group_1678" data-name="Group 1678" transform="translate(964 2008) rotate(-90)">*/}
                                        {/*                                    <g id="Group_920" data-name="Group 920" transform="translate(420.563 1105.602) rotate(-45)">*/}
                                        {/*                                        <rect id="Rectangle_1180" data-name="Rectangle 1180" width="10" height="4" rx="1" transform="translate(1136.293 341.001)" fill="#fff"/>*/}
                                        {/*                                        <rect id="Rectangle_1181" data-name="Rectangle 1181" width="4" height="10" rx="1" transform="translate(1136.293 335)" fill="#fff"/>*/}
                                        {/*                                    </g>*/}
                                        {/*                                </g>*/}
                                        {/*                            </g>*/}
                                        {/*                        </svg>*/}

                                        {/*                    </div>*/}
                                        {/*                </div>*/}

                                        {/*                <div className="Item__block Item__block_inline Item__block_margin-right Item__block_padding">*/}
                                        {/*                    <div className="Item__label Item__label_inline">Макс. кол-во часов</div>*/}
                                        {/*                    <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}


                                        {/*                    <div style={{display: "inline-block", marginRight: 12, cursor: "pointer"}}>*/}
                                        {/*                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">*/}
                                        {/*                            <g id="Group_1680" data-name="Group 1680" transform="translate(-1392 -522)">*/}
                                        {/*                                <rect id="Rectangle_2080" data-name="Rectangle 2080" width="32" height="32" rx="16" transform="translate(1392 522)" fill="#cacfd3"/>*/}
                                        {/*                                <g id="Group_1677" data-name="Group 1677" transform="translate(1948 -930) rotate(90)">*/}
                                        {/*                                    <g id="Group_920" data-name="Group 920" transform="translate(420.563 1105.602) rotate(-45)">*/}
                                        {/*                                        <rect id="Rectangle_1180" data-name="Rectangle 1180" width="10" height="4" rx="1" transform="translate(1136.293 341.001)" fill="#fff"/>*/}
                                        {/*                                        <rect id="Rectangle_1181" data-name="Rectangle 1181" width="4" height="10" rx="1" transform="translate(1136.293 335)" fill="#fff"/>*/}
                                        {/*                                    </g>*/}
                                        {/*                                </g>*/}
                                        {/*                            </g>*/}
                                        {/*                        </svg>*/}

                                        {/*                    </div>*/}
                                        {/*                    <div className="Item__Text" style={{fontSize: 24, fontWeight: 900}}>6</div>*/}
                                        {/*                    <div style={{display: "inline-block", marginLeft: 12, cursor: "pointer"}}>*/}
                                        {/*                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">*/}
                                        {/*                            <g id="Group_1679" data-name="Group 1679" transform="translate(-1488 -524)">*/}
                                        {/*                                <rect id="Rectangle_2081" data-name="Rectangle 2081" width="32" height="32" rx="16" transform="translate(1488 524)" fill="#cacfd3"/>*/}
                                        {/*                                <g id="Group_1678" data-name="Group 1678" transform="translate(964 2008) rotate(-90)">*/}
                                        {/*                                    <g id="Group_920" data-name="Group 920" transform="translate(420.563 1105.602) rotate(-45)">*/}
                                        {/*                                        <rect id="Rectangle_1180" data-name="Rectangle 1180" width="10" height="4" rx="1" transform="translate(1136.293 341.001)" fill="#fff"/>*/}
                                        {/*                                        <rect id="Rectangle_1181" data-name="Rectangle 1181" width="4" height="10" rx="1" transform="translate(1136.293 335)" fill="#fff"/>*/}
                                        {/*                                    </g>*/}
                                        {/*                                </g>*/}
                                        {/*                            </g>*/}
                                        {/*                        </svg>*/}

                                        {/*                    </div>*/}
                                        {/*                </div>*/}


                                        {/*            </div>*/}
                                        {/*        </div>*/}

                                        {/*        <div className="Body__hr" style={{width: "50%"}}/>*/}

                                                {isEditTimetables ? (
                                                    <React.Fragment>

                                                        {timetablesData.map((timetable, index) => (
                                                            Number(timetable.del) === 0 ? (
                                                            <React.Fragment key={index}>

                                                                {/*<div className="Body__label">Период обучения</div>*/}

                                                                <div className="Schedule__period">
                                                                    <div className="Info__tr Info__tr_inline-block">
                                                                        <div className="Info__td">
                                                                            <div className="Info__box">

                                                                                {timetable.year_timetable > 0 ? (
                                                                                    <React.Fragment>
                                                                                        <div className="Box__logo active pointer" style={{cursor: "pointer"}} onClick={this.delTimetable.bind(this, index)}>
                                                                                            {timetable.year_timetable}
                                                                                        </div>
                                                                                        <div className="Item__hr Item__hr_vertical Item__hr_inline"/>
                                                                                        <div className="Box__text">Курс</div>
                                                                                    </React.Fragment>
                                                                                ) : (
                                                                                    <React.Fragment>
                                                                                        <div className="Box__logo active pointer" style={{cursor: "pointer"}} onClick={this.delTimetable.bind(this, index)}>
                                                                                            {/*<svg xmlns="http://www.w3.org/2000/svg" width="32" height="16" viewBox="0 0 32 16">*/}
                                                                                            {/*    <path id="infinity-solid" d="M23.555,96c-3.305,0-5.89,2.065-7.555,3.93C14.335,98.065,11.75,96,8.445,96a8.012,8.012,0,1,0,0,16c3.305,0,5.89-2.065,7.555-3.93,1.665,1.865,4.25,3.93,7.555,3.93a8.012,8.012,0,1,0,0-16ZM8.445,107.2a3.227,3.227,0,1,1,0-6.4c1.91,0,3.67,1.8,4.7,3.2C12.125,105.38,10.35,107.2,8.445,107.2Zm15.11,0c-1.91,0-3.67-1.805-4.7-3.2,1.02-1.38,2.8-3.2,4.7-3.2a3.227,3.227,0,1,1,0,6.4Z" transform="translate(0 -96)" fill="#2a3e4c"/>*/}
                                                                                            {/*</svg>*/}

                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 16">
                                                                                                <g id="Group_1675" data-name="Group 1675" transform="translate(-815 -806)">
                                                                                                    <path id="infinity-solid" d="M17.659,96a7.837,7.837,0,0,0-5.664,2.947A7.837,7.837,0,0,0,6.33,96a6.007,6.007,0,1,0,0,12,7.837,7.837,0,0,0,5.664-2.947,7.837,7.837,0,0,0,5.664,2.947,6.007,6.007,0,1,0,0-12Zm-11.329,8.4a2.419,2.419,0,1,1,0-4.8c1.432,0,2.752,1.35,3.524,2.4C9.089,103.03,7.759,104.394,6.33,104.394Zm11.329,0c-1.432,0-2.752-1.353-3.524-2.4.765-1.035,2.1-2.4,3.524-2.4a2.419,2.419,0,1,1,0,4.8Z" transform="translate(815.005 712.012)" fill="#f7f7f7"/>
                                                                                                    <rect id="Rectangle_2081" data-name="Rectangle 2081" width="24" height="16" transform="translate(815 806)" fill="#2a3e4c" opacity="0"/>
                                                                                                </g>
                                                                                            </svg>

                                                                                        </div>
                                                                                            <div className="Item__hr Item__hr_vertical Item__hr_inline"/>
                                                                                            <div className="Box__text">Все курсы</div>
                                                                                    </React.Fragment>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="Info__tr Info__tr_inline-block">
                                                                        <div className="Info__td">
                                                                            <div className="Info__box">

                                                                                {timetable.year_timetable > 0 ? (
                                                                                    <React.Fragment>
                                                                                        {Number(timetable.period_timetable) === 0 ? (
                                                                                            <div className="Box__logo active pointer">
                                                                                                {/*<svg xmlns="http://www.w3.org/2000/svg" width="32" height="16" viewBox="0 0 32 16">*/}
                                                                                                {/*    <path id="infinity-solid" d="M23.555,96c-3.305,0-5.89,2.065-7.555,3.93C14.335,98.065,11.75,96,8.445,96a8.012,8.012,0,1,0,0,16c3.305,0,5.89-2.065,7.555-3.93,1.665,1.865,4.25,3.93,7.555,3.93a8.012,8.012,0,1,0,0-16ZM8.445,107.2a3.227,3.227,0,1,1,0-6.4c1.91,0,3.67,1.8,4.7,3.2C12.125,105.38,10.35,107.2,8.445,107.2Zm15.11,0c-1.91,0-3.67-1.805-4.7-3.2,1.02-1.38,2.8-3.2,4.7-3.2a3.227,3.227,0,1,1,0,6.4Z" transform="translate(0 -96)" fill="#2a3e4c"/>*/}
                                                                                                {/*</svg>*/}

                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 16">
                                                                                                    <g id="Group_1675" data-name="Group 1675" transform="translate(-815 -806)">
                                                                                                        <path id="infinity-solid" d="M17.659,96a7.837,7.837,0,0,0-5.664,2.947A7.837,7.837,0,0,0,6.33,96a6.007,6.007,0,1,0,0,12,7.837,7.837,0,0,0,5.664-2.947,7.837,7.837,0,0,0,5.664,2.947,6.007,6.007,0,1,0,0-12Zm-11.329,8.4a2.419,2.419,0,1,1,0-4.8c1.432,0,2.752,1.35,3.524,2.4C9.089,103.03,7.759,104.394,6.33,104.394Zm11.329,0c-1.432,0-2.752-1.353-3.524-2.4.765-1.035,2.1-2.4,3.524-2.4a2.419,2.419,0,1,1,0,4.8Z" transform="translate(815.005 712.012)" fill="#f7f7f7"/>
                                                                                                        <rect id="Rectangle_2081" data-name="Rectangle 2081" width="24" height="16" transform="translate(815 806)" fill="#2a3e4c" opacity="0"/>
                                                                                                    </g>
                                                                                                </svg>

                                                                                            </div>
                                                                                        ) : (
                                                                                            <div className="Box__logo pointer"
                                                                                                 onClick={this.timetablePeriod.bind(this, index, 0)}>
                                                                                                {/*<svg xmlns="http://www.w3.org/2000/svg" width="32" height="16" viewBox="0 0 32 16">*/}
                                                                                                {/*    <path id="infinity-solid" d="M23.555,96c-3.305,0-5.89,2.065-7.555,3.93C14.335,98.065,11.75,96,8.445,96a8.012,8.012,0,1,0,0,16c3.305,0,5.89-2.065,7.555-3.93,1.665,1.865,4.25,3.93,7.555,3.93a8.012,8.012,0,1,0,0-16ZM8.445,107.2a3.227,3.227,0,1,1,0-6.4c1.91,0,3.67,1.8,4.7,3.2C12.125,105.38,10.35,107.2,8.445,107.2Zm15.11,0c-1.91,0-3.67-1.805-4.7-3.2,1.02-1.38,2.8-3.2,4.7-3.2a3.227,3.227,0,1,1,0,6.4Z" transform="translate(0 -96)" fill="#2a3e4c"/>*/}
                                                                                                {/*</svg>*/}

                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 16">
                                                                                                    <g id="Group_1675" data-name="Group 1675" transform="translate(-815 -806)">
                                                                                                        <path id="infinity-solid" d="M17.659,96a7.837,7.837,0,0,0-5.664,2.947A7.837,7.837,0,0,0,6.33,96a6.007,6.007,0,1,0,0,12,7.837,7.837,0,0,0,5.664-2.947,7.837,7.837,0,0,0,5.664,2.947,6.007,6.007,0,1,0,0-12Zm-11.329,8.4a2.419,2.419,0,1,1,0-4.8c1.432,0,2.752,1.35,3.524,2.4C9.089,103.03,7.759,104.394,6.33,104.394Zm11.329,0c-1.432,0-2.752-1.353-3.524-2.4.765-1.035,2.1-2.4,3.524-2.4a2.419,2.419,0,1,1,0,4.8Z" transform="translate(815.005 712.012)" fill="#2a3e4c"/>
                                                                                                        <rect id="Rectangle_2081" data-name="Rectangle 2081" width="24" height="16" transform="translate(815 806)" fill="#f7f7f7" opacity="0"/>
                                                                                                    </g>
                                                                                                </svg>

                                                                                            </div>
                                                                                        )}

                                                                                        {trainingScheduleData.schedule_periods ? (trainingScheduleData.schedule_periods.map(schedule_period => (
                                                                                            schedule_period.periods.map(period => (
                                                                                                Number(period.year_schedule) === Number(timetable.year_timetable) ? (
                                                                                                    <div className={
                                                                                                        Number(timetable.period_timetable) === Number(period.period_schedule) ? 'Box__logo active pointer' : 'Box__logo pointer'
                                                                                                    }
                                                                                                         key={period.id}
                                                                                                         onClick={this.timetablePeriod.bind(this, index, Number(period.period_schedule))}>{period.period_schedule}</div>
                                                                                                ) : null
                                                                                            ))))) : null}
                                                                                        <div className="Item__hr Item__hr_vertical Item__hr_inline"/>
                                                                                        <div className="Box__text">{Number(timetable.period_timetable) === 0 ? 'Все семестры' : 'Семестр'}</div>
                                                                                    </React.Fragment>
                                                                                ) : (
                                                                                    <React.Fragment>
                                                                                        {Number(timetable.period_timetable) === 0 ? (
                                                                                            <div className="Box__logo active pointer">
                                                                                                {/*<svg xmlns="http://www.w3.org/2000/svg" width="32" height="16" viewBox="0 0 32 16">*/}
                                                                                                {/*    <path id="infinity-solid" d="M23.555,96c-3.305,0-5.89,2.065-7.555,3.93C14.335,98.065,11.75,96,8.445,96a8.012,8.012,0,1,0,0,16c3.305,0,5.89-2.065,7.555-3.93,1.665,1.865,4.25,3.93,7.555,3.93a8.012,8.012,0,1,0,0-16ZM8.445,107.2a3.227,3.227,0,1,1,0-6.4c1.91,0,3.67,1.8,4.7,3.2C12.125,105.38,10.35,107.2,8.445,107.2Zm15.11,0c-1.91,0-3.67-1.805-4.7-3.2,1.02-1.38,2.8-3.2,4.7-3.2a3.227,3.227,0,1,1,0,6.4Z" transform="translate(0 -96)" fill="#2a3e4c"/>*/}
                                                                                                {/*</svg>*/}

                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 16">
                                                                                                    <g id="Group_1675" data-name="Group 1675" transform="translate(-815 -806)">
                                                                                                        <path id="infinity-solid" d="M17.659,96a7.837,7.837,0,0,0-5.664,2.947A7.837,7.837,0,0,0,6.33,96a6.007,6.007,0,1,0,0,12,7.837,7.837,0,0,0,5.664-2.947,7.837,7.837,0,0,0,5.664,2.947,6.007,6.007,0,1,0,0-12Zm-11.329,8.4a2.419,2.419,0,1,1,0-4.8c1.432,0,2.752,1.35,3.524,2.4C9.089,103.03,7.759,104.394,6.33,104.394Zm11.329,0c-1.432,0-2.752-1.353-3.524-2.4.765-1.035,2.1-2.4,3.524-2.4a2.419,2.419,0,1,1,0,4.8Z" transform="translate(815.005 712.012)" fill="#f7f7f7"/>
                                                                                                        <rect id="Rectangle_2081" data-name="Rectangle 2081" width="24" height="16" transform="translate(815 806)" fill="#2a3e4c" opacity="0"/>
                                                                                                    </g>
                                                                                                </svg>

                                                                                            </div>
                                                                                        ) : (
                                                                                            <div className="Box__logo pointer"
                                                                                                 onClick={this.timetablePeriod.bind(this, index, 0)}>
                                                                                                {/*<svg xmlns="http://www.w3.org/2000/svg" width="32" height="16" viewBox="0 0 32 16">*/}
                                                                                                {/*    <path id="infinity-solid" d="M23.555,96c-3.305,0-5.89,2.065-7.555,3.93C14.335,98.065,11.75,96,8.445,96a8.012,8.012,0,1,0,0,16c3.305,0,5.89-2.065,7.555-3.93,1.665,1.865,4.25,3.93,7.555,3.93a8.012,8.012,0,1,0,0-16ZM8.445,107.2a3.227,3.227,0,1,1,0-6.4c1.91,0,3.67,1.8,4.7,3.2C12.125,105.38,10.35,107.2,8.445,107.2Zm15.11,0c-1.91,0-3.67-1.805-4.7-3.2,1.02-1.38,2.8-3.2,4.7-3.2a3.227,3.227,0,1,1,0,6.4Z" transform="translate(0 -96)" fill="#2a3e4c"/>*/}
                                                                                                {/*</svg>*/}

                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 16">
                                                                                                    <g id="Group_1675" data-name="Group 1675" transform="translate(-815 -806)">
                                                                                                        <path id="infinity-solid" d="M17.659,96a7.837,7.837,0,0,0-5.664,2.947A7.837,7.837,0,0,0,6.33,96a6.007,6.007,0,1,0,0,12,7.837,7.837,0,0,0,5.664-2.947,7.837,7.837,0,0,0,5.664,2.947,6.007,6.007,0,1,0,0-12Zm-11.329,8.4a2.419,2.419,0,1,1,0-4.8c1.432,0,2.752,1.35,3.524,2.4C9.089,103.03,7.759,104.394,6.33,104.394Zm11.329,0c-1.432,0-2.752-1.353-3.524-2.4.765-1.035,2.1-2.4,3.524-2.4a2.419,2.419,0,1,1,0,4.8Z" transform="translate(815.005 712.012)" fill="#2a3e4c"/>
                                                                                                        <rect id="Rectangle_2081" data-name="Rectangle 2081" width="24" height="16" transform="translate(815 806)" fill="#f7f7f7" opacity="0"/>
                                                                                                    </g>
                                                                                                </svg>

                                                                                            </div>
                                                                                        )}
                                                                                        <div className="Item__hr Item__hr_vertical Item__hr_inline"/>
                                                                                        <div className="Box__text">Все семестры</div>
                                                                                    </React.Fragment>
                                                                                )}

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                  <div className="Body__label">Дни недели</div>

                                                                <div className="Schedule__week">
                                                                    <div className="Info__tr Info__tr_inline-block">
                                                                        <div className="Info__td">
                                                                            <div className={Number(timetable.week_day_mon) > 0 ? 'Info__box active pointer' : 'Info__box pointer'}
                                                                                 onClick={this.timetableWeekDay.bind(this, index, 1)}>
                                                                                <div className="Box__logo">Пн</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="Info__td">
                                                                            <div className={Number(timetable.week_day_tue) > 0 ? 'Info__box active pointer' : 'Info__box pointer'}
                                                                                 onClick={this.timetableWeekDay.bind(this, index, 2)}>
                                                                                <div className="Box__logo">Вт</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="Info__td">
                                                                            <div className={Number(timetable.week_day_wed) > 0 ? 'Info__box active pointer' : 'Info__box pointer'}
                                                                                 onClick={this.timetableWeekDay.bind(this, index, 3)}>
                                                                                <div className="Box__logo">Ср</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="Info__td">
                                                                            <div className={Number(timetable.week_day_thu) > 0 ? 'Info__box active pointer' : 'Info__box pointer'}
                                                                                 onClick={this.timetableWeekDay.bind(this, index, 4)}>
                                                                                <div className="Box__logo">Чт</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="Info__td">
                                                                            <div className={Number(timetable.week_day_fri) > 0 ? 'Info__box active pointer' : 'Info__box pointer'}
                                                                                 onClick={this.timetableWeekDay.bind(this, index, 5)}>
                                                                                <div className="Box__logo">Пт</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="Info__td">
                                                                            <div className={Number(timetable.week_day_sat) > 0 ? 'Info__box active pointer' : 'Info__box pointer'}
                                                                                 onClick={this.timetableWeekDay.bind(this, index, 6)}>
                                                                                <div className="Box__logo">Сб</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="Info__td">
                                                                            <div className={Number(timetable.week_day_sun) > 0 ? 'Info__box active pointer' : 'Info__box pointer'}
                                                                                 onClick={this.timetableWeekDay.bind(this, index, 7)}>
                                                                                <div className="Box__logo">Вс</div>
                                                                            </div>
                                                                        </div>
                                                                        {/*<div className="Info__td">*/}
                                                                        {/*    <div className="Info__box ">*/}
                                                                        {/*        <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                                                        {/*        <div className="Box__text">Учебные дни</div>*/}
                                                                        {/*    </div>*/}
                                                                        {/*</div>*/}
                                                                    </div>
                                                                </div>

                                                                {this.maxWeekDays(timetable) > 0 ? (
                                                                        <React.Fragment>
                                                                <div className="Body__label" style={{marginBottom: 24}}>Учебные часы</div>

                                                                <div className="Schedule__hours">
                                                                    <div className="Info__tr Info__tr_inline-block">
                                                                        <div className="Info__td">
                                                                            <div className={Number(timetable.day_hour_0) > 0 ? 'Info__box active pointer' : 'Info__box pointer'}
                                                                                 onClick={this.timetableDayHour.bind(this, index, 0)}>
                                                                                <div className="Box__logo">0</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="Info__td">
                                                                            <div className={Number(timetable.day_hour_1) > 0 ? 'Info__box active pointer' : 'Info__box pointer'}
                                                                                 onClick={this.timetableDayHour.bind(this, index, 1)}>
                                                                                <div className="Box__logo">1</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="Info__td">
                                                                            <div className={Number(timetable.day_hour_2) > 0 ? 'Info__box active pointer' : 'Info__box pointer'}
                                                                                 onClick={this.timetableDayHour.bind(this, index, 2)}>
                                                                                <div className="Box__logo">2</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="Info__td">
                                                                            <div className={Number(timetable.day_hour_3) > 0 ? 'Info__box active pointer' : 'Info__box pointer'}
                                                                                 onClick={this.timetableDayHour.bind(this, index, 3)}>
                                                                                <div className="Box__logo">3</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="Info__td">
                                                                            <div className={Number(timetable.day_hour_4) > 0 ? 'Info__box active pointer' : 'Info__box pointer'}
                                                                                 onClick={this.timetableDayHour.bind(this, index, 4)}>
                                                                                <div className="Box__logo">4</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="Info__td">
                                                                            <div className={Number(timetable.day_hour_5) > 0 ? 'Info__box active pointer' : 'Info__box pointer'}
                                                                                 onClick={this.timetableDayHour.bind(this, index, 5)}>
                                                                                <div className="Box__logo">5</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="Info__td">
                                                                            <div className={Number(timetable.day_hour_6) > 0 ? 'Info__box active pointer' : 'Info__box pointer'}
                                                                                 onClick={this.timetableDayHour.bind(this, index, 6)}>
                                                                                <div className="Box__logo">6</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="Info__td">
                                                                            <div className={Number(timetable.day_hour_7) > 0 ? 'Info__box active pointer' : 'Info__box pointer'}
                                                                                 onClick={this.timetableDayHour.bind(this, index, 7)}>
                                                                                <div className="Box__logo">7</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="Info__td">
                                                                            <div className={Number(timetable.day_hour_8) > 0 ? 'Info__box active pointer' : 'Info__box pointer'}
                                                                                 onClick={this.timetableDayHour.bind(this, index, 8)}>
                                                                                <div className="Box__logo">8</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="Info__td">
                                                                            <div className={Number(timetable.day_hour_9) > 0 ? 'Info__box active pointer' : 'Info__box pointer'}
                                                                                 onClick={this.timetableDayHour.bind(this, index, 9)}>
                                                                                <div className="Box__logo">9</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="Info__td">
                                                                            <div className={Number(timetable.day_hour_10) > 0 ? 'Info__box active pointer' : 'Info__box pointer'}
                                                                                 onClick={this.timetableDayHour.bind(this, index, 10)}>
                                                                                <div className="Box__logo">10</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="Info__td">
                                                                            <div className={Number(timetable.day_hour_11) > 0 ? 'Info__box active pointer' : 'Info__box pointer'}
                                                                                 onClick={this.timetableDayHour.bind(this, index, 11)}>
                                                                                <div className="Box__logo">11</div>
                                                                            </div>
                                                                        </div>
                                                                        <br/>
                                                                        <div className="Info__td">
                                                                            <div className={Number(timetable.day_hour_12) > 0 ? 'Info__box active pointer' : 'Info__box pointer'}
                                                                                 onClick={this.timetableDayHour.bind(this, index, 12)}>
                                                                                <div className="Box__logo">12</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="Info__td">
                                                                            <div className={Number(timetable.day_hour_13) > 0 ? 'Info__box active pointer' : 'Info__box pointer'}
                                                                                 onClick={this.timetableDayHour.bind(this, index, 13)}>
                                                                                <div className="Box__logo">13</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="Info__td">
                                                                            <div className={Number(timetable.day_hour_14) > 0 ? 'Info__box active pointer' : 'Info__box pointer'}
                                                                                 onClick={this.timetableDayHour.bind(this, index, 14)}>
                                                                                <div className="Box__logo">14</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="Info__td">
                                                                            <div className={Number(timetable.day_hour_15) > 0 ? 'Info__box active pointer' : 'Info__box pointer'}
                                                                                 onClick={this.timetableDayHour.bind(this, index, 15)}>
                                                                                <div className="Box__logo">15</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="Info__td">
                                                                            <div className={Number(timetable.day_hour_16) > 0 ? 'Info__box active pointer' : 'Info__box pointer'}
                                                                                 onClick={this.timetableDayHour.bind(this, index, 16)}>
                                                                                <div className="Box__logo">16</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="Info__td">
                                                                            <div className={Number(timetable.day_hour_17) > 0 ? 'Info__box active pointer' : 'Info__box pointer'}
                                                                                 onClick={this.timetableDayHour.bind(this, index, 17)}>
                                                                                <div className="Box__logo">17</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="Info__td">
                                                                            <div className={Number(timetable.day_hour_18) > 0 ? 'Info__box active pointer' : 'Info__box pointer'}
                                                                                 onClick={this.timetableDayHour.bind(this, index, 18)}>
                                                                                <div className="Box__logo">18</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="Info__td">
                                                                            <div className={Number(timetable.day_hour_19) > 0 ? 'Info__box active pointer' : 'Info__box pointer'}
                                                                                 onClick={this.timetableDayHour.bind(this, index, 19)}>
                                                                                <div className="Box__logo">19</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="Info__td">
                                                                            <div className={Number(timetable.day_hour_20) > 0 ? 'Info__box active pointer' : 'Info__box pointer'}
                                                                                 onClick={this.timetableDayHour.bind(this, index, 20)}>
                                                                                <div className="Box__logo">20</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="Info__td">
                                                                            <div className={Number(timetable.day_hour_21) > 0 ? 'Info__box active pointer' : 'Info__box pointer'}
                                                                                 onClick={this.timetableDayHour.bind(this, index, 21)}>
                                                                                <div className="Box__logo">21</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="Info__td">
                                                                            <div className={Number(timetable.day_hour_22) > 0 ? 'Info__box active pointer' : 'Info__box pointer'}
                                                                                 onClick={this.timetableDayHour.bind(this, index, 22)}>
                                                                                <div className="Box__logo">22</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="Info__td">
                                                                            <div className={Number(timetable.day_hour_23) > 0 ? 'Info__box active pointer' : 'Info__box pointer'}
                                                                                 onClick={this.timetableDayHour.bind(this, index, 23)}>
                                                                                <div className="Box__logo">23</div>
                                                                            </div>
                                                                        </div>

                                                                        {/*<div className="Info__td">*/}
                                                                        {/*    <div className="Info__box">*/}
                                                                        {/*        <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                                                        {/*        <div className="Box__text">Учебные часы</div>*/}
                                                                        {/*    </div>*/}
                                                                        {/*</div>*/}
                                                                    </div>
                                                                </div>

                                                                <div className="Block__Item" style={{marginBottom: 42}}>
                                                                    <div className="Item__wrapper">

                                                                        <div className="Item__block Item__block_inline Item__block_margin-right Item__block_padding">
                                                                            <div className="Item__label Item__label_inline">Сдвоенные часы</div>
                                                                            <div className="Item__hr Item__hr_vertical Item__hr_inline"/>
                                                                            <div style={{display: "inline-block", marginRight: 12,
                                                                                cursor: (Number(timetable.double_hours) === 0 ? "default" : "pointer"),
                                                                                opacity: (Number(timetable.double_hours) === 0 ? 0.25 : 1)
                                                                            }} onClick={this.doubleHoursFalse.bind(this, index)}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                                                                                    <g id="Group_1680" data-name="Group 1680" transform="translate(-1392 -522)">
                                                                                        <rect id="Rectangle_2080" data-name="Rectangle 2080" width="32" height="32" rx="16" transform="translate(1392 522)" fill="#cacfd3"/>
                                                                                        <g id="Group_1677" data-name="Group 1677" transform="translate(1948 -930) rotate(90)">
                                                                                            <g id="Group_920" data-name="Group 920" transform="translate(420.563 1105.602) rotate(-45)">
                                                                                                <rect id="Rectangle_1180" data-name="Rectangle 1180" width="10" height="4" rx="1" transform="translate(1136.293 341.001)" fill="#fff"/>
                                                                                                <rect id="Rectangle_1181" data-name="Rectangle 1181" width="4" height="10" rx="1" transform="translate(1136.293 335)" fill="#fff"/>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </svg>

                                                                            </div>
                                                                            <div className="Item__Text" style={{fontSize: 24, fontWeight: 900}}>{Number(timetable.double_hours) === 1 ? 'Да' : 'Нет'}</div>
                                                                            <div style={{display: "inline-block", marginLeft: 12,
                                                                                cursor: (Number(timetable.double_hours) === 1 ? "default" : "pointer"),
                                                                                opacity: (Number(timetable.double_hours) === 1 ? 0.25 : 1)
                                                                            }} onClick={this.doubleHoursTrue.bind(this, index)}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                                                                                    <g id="Group_1679" data-name="Group 1679" transform="translate(-1488 -524)">
                                                                                        <rect id="Rectangle_2081" data-name="Rectangle 2081" width="32" height="32" rx="16" transform="translate(1488 524)" fill="#cacfd3"/>
                                                                                        <g id="Group_1678" data-name="Group 1678" transform="translate(964 2008) rotate(-90)">
                                                                                            <g id="Group_920" data-name="Group 920" transform="translate(420.563 1105.602) rotate(-45)">
                                                                                                <rect id="Rectangle_1180" data-name="Rectangle 1180" width="10" height="4" rx="1" transform="translate(1136.293 341.001)" fill="#fff"/>
                                                                                                <rect id="Rectangle_1181" data-name="Rectangle 1181" width="4" height="10" rx="1" transform="translate(1136.293 335)" fill="#fff"/>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </svg>

                                                                            </div>
                                                                        </div>

                                                                        <div className="Item__block Item__block_inline Item__block_margin-right Item__block_padding">
                                                                            <div className="Item__label Item__label_inline">Макс. кол-во часов</div>
                                                                            <div className="Item__hr Item__hr_vertical Item__hr_inline"/>


                                                                            <div style={{display: "inline-block", marginRight: 12,
                                                                                cursor: (Number(timetable.max_hours_day) > 0 ? "pointer" : "default"),
                                                                                opacity: (Number(timetable.max_hours_day) > 0 ? 1 : 0.25)
                                                                            }}
                                                                                 onClick={this.maxHoursDayMinus.bind(this, index)}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                                                                                    <g id="Group_1680" data-name="Group 1680" transform="translate(-1392 -522)">
                                                                                        <rect id="Rectangle_2080" data-name="Rectangle 2080" width="32" height="32" rx="16" transform="translate(1392 522)" fill="#cacfd3"/>
                                                                                        <g id="Group_1677" data-name="Group 1677" transform="translate(1948 -930) rotate(90)">
                                                                                            <g id="Group_920" data-name="Group 920" transform="translate(420.563 1105.602) rotate(-45)">
                                                                                                <rect id="Rectangle_1180" data-name="Rectangle 1180" width="10" height="4" rx="1" transform="translate(1136.293 341.001)" fill="#fff"/>
                                                                                                <rect id="Rectangle_1181" data-name="Rectangle 1181" width="4" height="10" rx="1" transform="translate(1136.293 335)" fill="#fff"/>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </svg>

                                                                            </div>
                                                                            <div className="Item__Text" style={{fontSize: 24, fontWeight: 900}}>{timetable.max_hours_day}</div>
                                                                            <div style={{display: "inline-block",
                                                                                marginLeft: 12,
                                                                                cursor: (Number(timetable.max_hours_day) < (this.maxHours(timetable)) ? "pointer" : "default"),
                                                                                opacity: (Number(timetable.max_hours_day) < (this.maxHours(timetable)) ? 1 : 0.25)
                                                                            }}
                                                                                      onClick={this.maxHoursDayPlus.bind(this, index)}>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                                                                                    <g id="Group_1679" data-name="Group 1679" transform="translate(-1488 -524)">
                                                                                        <rect id="Rectangle_2081" data-name="Rectangle 2081" width="32" height="32" rx="16" transform="translate(1488 524)" fill="#cacfd3"/>
                                                                                        <g id="Group_1678" data-name="Group 1678" transform="translate(964 2008) rotate(-90)">
                                                                                            <g id="Group_920" data-name="Group 920" transform="translate(420.563 1105.602) rotate(-45)">
                                                                                                <rect id="Rectangle_1180" data-name="Rectangle 1180" width="10" height="4" rx="1" transform="translate(1136.293 341.001)" fill="#fff"/>
                                                                                                <rect id="Rectangle_1181" data-name="Rectangle 1181" width="4" height="10" rx="1" transform="translate(1136.293 335)" fill="#fff"/>
                                                                                            </g>
                                                                                        </g>
                                                                                    </g>
                                                                                </svg>

                                                                            </div>
                                                                        </div>


                                                                    </div>
                                                                </div>
                                                                        </React.Fragment>
                                                                    ) : null}


                                                                <div className="Body__hr" style={{width: "50%"}}/>
                                                            </React.Fragment>
                                                        ) : null
                                                        ))}
































                                                        <div className="Schedule__period">
                                                            <div className="Info__tr Info__tr_inline-block">
                                                                <div className="Info__td">
                                                                    <div className="Info__box">
                                                                        <div className="Box__logo pointer" onClick={this.newTimetable.bind(this, 0)}>
                                                                            {/*<svg xmlns="http://www.w3.org/2000/svg" width="32" height="16" viewBox="0 0 32 16">*/}
                                                                            {/*    <path id="infinity-solid" d="M23.555,96c-3.305,0-5.89,2.065-7.555,3.93C14.335,98.065,11.75,96,8.445,96a8.012,8.012,0,1,0,0,16c3.305,0,5.89-2.065,7.555-3.93,1.665,1.865,4.25,3.93,7.555,3.93a8.012,8.012,0,1,0,0-16ZM8.445,107.2a3.227,3.227,0,1,1,0-6.4c1.91,0,3.67,1.8,4.7,3.2C12.125,105.38,10.35,107.2,8.445,107.2Zm15.11,0c-1.91,0-3.67-1.805-4.7-3.2,1.02-1.38,2.8-3.2,4.7-3.2a3.227,3.227,0,1,1,0,6.4Z" transform="translate(0 -96)" fill="#2a3e4c"/>*/}
                                                                            {/*</svg>*/}

                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 16">
                                                                                <g id="Group_1675" data-name="Group 1675" transform="translate(-815 -806)">
                                                                                    <path id="infinity-solid" d="M17.659,96a7.837,7.837,0,0,0-5.664,2.947A7.837,7.837,0,0,0,6.33,96a6.007,6.007,0,1,0,0,12,7.837,7.837,0,0,0,5.664-2.947,7.837,7.837,0,0,0,5.664,2.947,6.007,6.007,0,1,0,0-12Zm-11.329,8.4a2.419,2.419,0,1,1,0-4.8c1.432,0,2.752,1.35,3.524,2.4C9.089,103.03,7.759,104.394,6.33,104.394Zm11.329,0c-1.432,0-2.752-1.353-3.524-2.4.765-1.035,2.1-2.4,3.524-2.4a2.419,2.419,0,1,1,0,4.8Z" transform="translate(815.005 712.012)" fill="#2a3e4c"/>
                                                                                    <rect id="Rectangle_2081" data-name="Rectangle 2081" width="24" height="16" transform="translate(815 806)" fill="#f7f7f7" opacity="0"/>
                                                                                </g>
                                                                            </svg>

                                                                        </div>
                                                                        {trainingScheduleData.schedule_periods ? (trainingScheduleData.schedule_periods.map((schedule_period, index) => (
                                                                            <div className="Box__logo pointer" key={index}  onClick={this.newTimetable.bind(this, schedule_period.year)}>{schedule_period.year}</div>
                                                                        ))) : null}
                                                                        <div className="Item__hr Item__hr_vertical Item__hr_inline"/>
                                                                        <div className="Box__text">Курсы</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        {/*<div className="Body__label">Недели</div>*/}

                                                        {/*<div className="Schedule__weeks">*/}
                                                        {/*    <div className="Info__tr">*/}
                                                        {/*        <div className="Info__td">*/}
                                                        {/*            <div className="Info__box">*/}
                                                        {/*                <div className="Box__logo">Т</div>*/}
                                                        {/*                <div className="Box__text Box__text_padding-7">Теоретическое <br/>обучение</div>*/}
                                                        {/*            </div>*/}
                                                        {/*        </div>*/}
                                                        {/*        <div className="Info__td">*/}
                                                        {/*            <div className="Info__box">*/}
                                                        {/*                <div className="Box__logo">Э</div>*/}
                                                        {/*                <div className="Box__text Box__text_padding-7">Экзаменационная <br/>сессия</div>*/}
                                                        {/*            </div>*/}
                                                        {/*        </div>*/}
                                                        {/*        <div className="Info__td">*/}
                                                        {/*            <div className="Info__box">*/}
                                                        {/*                <div className="Box__logo">У</div>*/}
                                                        {/*                <div className="Box__text Box__text_padding-7">Учебная <br/>практика</div>*/}
                                                        {/*            </div>*/}
                                                        {/*        </div>*/}
                                                        {/*    </div>*/}
                                                        {/*</div>*/}


                                                        {/*<div className="Body__label">Дни в неделе</div>*/}

                                                        {/*<div className="Schedule__week">*/}
                                                        {/*    <div className="Info__tr Info__tr_inline-block">*/}
                                                        {/*        <div className="Info__td">*/}
                                                        {/*            <div className="Info__box pointer">*/}
                                                        {/*                <div className="Box__logo">Пн</div>*/}
                                                        {/*            </div>*/}
                                                        {/*        </div>*/}
                                                        {/*        <div className="Info__td">*/}
                                                        {/*            <div className="Info__box pointer">*/}
                                                        {/*                <div className="Box__logo">Вт</div>*/}
                                                        {/*            </div>*/}
                                                        {/*        </div>*/}
                                                        {/*        <div className="Info__td">*/}
                                                        {/*            <div className="Info__box pointer">*/}
                                                        {/*                <div className="Box__logo">Ср</div>*/}
                                                        {/*            </div>*/}
                                                        {/*        </div>*/}
                                                        {/*        <div className="Info__td">*/}
                                                        {/*            <div className="Info__box pointer">*/}
                                                        {/*                <div className="Box__logo">Чт</div>*/}
                                                        {/*            </div>*/}
                                                        {/*        </div>*/}
                                                        {/*        <div className="Info__td">*/}
                                                        {/*            <div className="Info__box pointer">*/}
                                                        {/*                <div className="Box__logo">Пт</div>*/}
                                                        {/*            </div>*/}
                                                        {/*        </div>*/}
                                                        {/*        <div className="Info__td">*/}
                                                        {/*            <div className="Info__box pointer">*/}
                                                        {/*                <div className="Box__logo">Сб</div>*/}
                                                        {/*            </div>*/}
                                                        {/*        </div>*/}
                                                        {/*        <div className="Info__td">*/}
                                                        {/*            <div className="Info__box pointer">*/}
                                                        {/*                <div className="Box__logo">Вс</div>*/}
                                                        {/*            </div>*/}
                                                        {/*        </div>*/}
                                                        {/*        <div className="Info__td">*/}
                                                        {/*            <div className="Info__box ">*/}
                                                        {/*                <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                                        {/*                <div className="Box__text">Учебные дни</div>*/}
                                                        {/*            </div>*/}
                                                        {/*        </div>*/}
                                                        {/*    </div>*/}
                                                        {/*</div>*/}

                                                        {/*<div className="Body__label" style={{marginBottom: 24}}>Часы в день</div>*/}

                                                        {/*<div className="Schedule__hours">*/}
                                                        {/*    <div className="Info__tr Info__tr_inline-block">*/}
                                                        {/*        <div className="Info__td">*/}
                                                        {/*            <div className="Info__box pointer">*/}
                                                        {/*                <div className="Box__logo">0</div>*/}
                                                        {/*            </div>*/}
                                                        {/*        </div>*/}
                                                        {/*        <div className="Info__td">*/}
                                                        {/*            <div className="Info__box pointer">*/}
                                                        {/*                <div className="Box__logo">1</div>*/}
                                                        {/*            </div>*/}
                                                        {/*        </div>*/}
                                                        {/*        <div className="Info__td">*/}
                                                        {/*            <div className="Info__box pointer">*/}
                                                        {/*                <div className="Box__logo">2</div>*/}
                                                        {/*            </div>*/}
                                                        {/*        </div>*/}
                                                        {/*        <div className="Info__td">*/}
                                                        {/*            <div className="Info__box pointer">*/}
                                                        {/*                <div className="Box__logo">3</div>*/}
                                                        {/*            </div>*/}
                                                        {/*        </div>*/}
                                                        {/*        <div className="Info__td">*/}
                                                        {/*            <div className="Info__box pointer">*/}
                                                        {/*                <div className="Box__logo">4</div>*/}
                                                        {/*            </div>*/}
                                                        {/*        </div>*/}
                                                        {/*        <div className="Info__td">*/}
                                                        {/*            <div className="Info__box pointer">*/}
                                                        {/*                <div className="Box__logo">5</div>*/}
                                                        {/*            </div>*/}
                                                        {/*        </div>*/}
                                                        {/*        <div className="Info__td">*/}
                                                        {/*            <div className="Info__box pointer">*/}
                                                        {/*                <div className="Box__logo">6</div>*/}
                                                        {/*            </div>*/}
                                                        {/*        </div>*/}
                                                        {/*        <div className="Info__td">*/}
                                                        {/*            <div className="Info__box pointer">*/}
                                                        {/*                <div className="Box__logo">7</div>*/}
                                                        {/*            </div>*/}
                                                        {/*        </div>*/}
                                                        {/*        <div className="Info__td">*/}
                                                        {/*            <div className="Info__box pointer">*/}
                                                        {/*                <div className="Box__logo">8</div>*/}
                                                        {/*            </div>*/}
                                                        {/*        </div>*/}
                                                        {/*        <div className="Info__td">*/}
                                                        {/*            <div className="Info__box pointer">*/}
                                                        {/*                <div className="Box__logo">9</div>*/}
                                                        {/*            </div>*/}
                                                        {/*        </div>*/}
                                                        {/*        <div className="Info__td">*/}
                                                        {/*            <div className="Info__box pointer">*/}
                                                        {/*                <div className="Box__logo">10</div>*/}
                                                        {/*            </div>*/}
                                                        {/*        </div>*/}
                                                        {/*        <div className="Info__td">*/}
                                                        {/*            <div className="Info__box pointer">*/}
                                                        {/*                <div className="Box__logo">11</div>*/}
                                                        {/*            </div>*/}
                                                        {/*        </div>*/}
                                                        {/*        <br/>*/}
                                                        {/*        <div className="Info__td">*/}
                                                        {/*            <div className="Info__box pointer">*/}
                                                        {/*                <div className="Box__logo">12</div>*/}
                                                        {/*            </div>*/}
                                                        {/*        </div>*/}
                                                        {/*        <div className="Info__td">*/}
                                                        {/*            <div className="Info__box pointer">*/}
                                                        {/*                <div className="Box__logo">13</div>*/}
                                                        {/*            </div>*/}
                                                        {/*        </div>*/}
                                                        {/*        <div className="Info__td">*/}
                                                        {/*            <div className="Info__box pointer">*/}
                                                        {/*                <div className="Box__logo">14</div>*/}
                                                        {/*            </div>*/}
                                                        {/*        </div>*/}
                                                        {/*        <div className="Info__td">*/}
                                                        {/*            <div className="Info__box pointer">*/}
                                                        {/*                <div className="Box__logo">15</div>*/}
                                                        {/*            </div>*/}
                                                        {/*        </div>*/}
                                                        {/*        <div className="Info__td">*/}
                                                        {/*            <div className="Info__box pointer">*/}
                                                        {/*                <div className="Box__logo">16</div>*/}
                                                        {/*            </div>*/}
                                                        {/*        </div>*/}
                                                        {/*        <div className="Info__td">*/}
                                                        {/*            <div className="Info__box pointer">*/}
                                                        {/*                <div className="Box__logo">17</div>*/}
                                                        {/*            </div>*/}
                                                        {/*        </div>*/}
                                                        {/*        <div className="Info__td">*/}
                                                        {/*            <div className="Info__box pointer">*/}
                                                        {/*                <div className="Box__logo">18</div>*/}
                                                        {/*            </div>*/}
                                                        {/*        </div>*/}
                                                        {/*        <div className="Info__td">*/}
                                                        {/*            <div className="Info__box pointer">*/}
                                                        {/*                <div className="Box__logo">19</div>*/}
                                                        {/*            </div>*/}
                                                        {/*        </div>*/}
                                                        {/*        <div className="Info__td">*/}
                                                        {/*            <div className="Info__box pointer">*/}
                                                        {/*                <div className="Box__logo">20</div>*/}
                                                        {/*            </div>*/}
                                                        {/*        </div>*/}
                                                        {/*        <div className="Info__td">*/}
                                                        {/*            <div className="Info__box pointer">*/}
                                                        {/*                <div className="Box__logo">21</div>*/}
                                                        {/*            </div>*/}
                                                        {/*        </div>*/}
                                                        {/*        <div className="Info__td">*/}
                                                        {/*            <div className="Info__box pointer">*/}
                                                        {/*                <div className="Box__logo">22</div>*/}
                                                        {/*            </div>*/}
                                                        {/*        </div>*/}
                                                        {/*        <div className="Info__td">*/}
                                                        {/*            <div className="Info__box pointer">*/}
                                                        {/*                <div className="Box__logo">23</div>*/}
                                                        {/*            </div>*/}
                                                        {/*        </div>*/}

                                                        {/*        <div className="Info__td">*/}
                                                        {/*            <div className="Info__box">*/}
                                                        {/*                <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                                        {/*                <div className="Box__text">Учебные часы</div>*/}
                                                        {/*            </div>*/}
                                                        {/*        </div>*/}
                                                        {/*    </div>*/}
                                                        {/*</div>*/}


                                                        {/*<div className="Block__Item" style={{marginBottom: 42}}>*/}
                                                        {/*    <div className="Item__wrapper">*/}

                                                        {/*        <div className="Item__block Item__block_inline Item__block_margin-right Item__block_padding">*/}
                                                        {/*            <div className="Item__label Item__label_inline">Сдвоенные часы</div>*/}
                                                        {/*            <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                                        {/*            <div style={{display: "inline-block", marginRight: 12, cursor: "pointer"}}>*/}
                                                        {/*                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">*/}
                                                        {/*                    <g id="Group_1680" data-name="Group 1680" transform="translate(-1392 -522)">*/}
                                                        {/*                        <rect id="Rectangle_2080" data-name="Rectangle 2080" width="32" height="32" rx="16" transform="translate(1392 522)" fill="#cacfd3"/>*/}
                                                        {/*                        <g id="Group_1677" data-name="Group 1677" transform="translate(1948 -930) rotate(90)">*/}
                                                        {/*                            <g id="Group_920" data-name="Group 920" transform="translate(420.563 1105.602) rotate(-45)">*/}
                                                        {/*                                <rect id="Rectangle_1180" data-name="Rectangle 1180" width="10" height="4" rx="1" transform="translate(1136.293 341.001)" fill="#fff"/>*/}
                                                        {/*                                <rect id="Rectangle_1181" data-name="Rectangle 1181" width="4" height="10" rx="1" transform="translate(1136.293 335)" fill="#fff"/>*/}
                                                        {/*                            </g>*/}
                                                        {/*                        </g>*/}
                                                        {/*                    </g>*/}
                                                        {/*                </svg>*/}

                                                        {/*            </div>*/}
                                                        {/*            <div className="Item__Text" style={{fontSize: 24, fontWeight: 900}}>Да</div>*/}
                                                        {/*            <div style={{display: "inline-block", marginLeft: 12, cursor: "default", opacity: 0.25}}>*/}
                                                        {/*                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">*/}
                                                        {/*                    <g id="Group_1679" data-name="Group 1679" transform="translate(-1488 -524)">*/}
                                                        {/*                        <rect id="Rectangle_2081" data-name="Rectangle 2081" width="32" height="32" rx="16" transform="translate(1488 524)" fill="#cacfd3"/>*/}
                                                        {/*                        <g id="Group_1678" data-name="Group 1678" transform="translate(964 2008) rotate(-90)">*/}
                                                        {/*                            <g id="Group_920" data-name="Group 920" transform="translate(420.563 1105.602) rotate(-45)">*/}
                                                        {/*                                <rect id="Rectangle_1180" data-name="Rectangle 1180" width="10" height="4" rx="1" transform="translate(1136.293 341.001)" fill="#fff"/>*/}
                                                        {/*                                <rect id="Rectangle_1181" data-name="Rectangle 1181" width="4" height="10" rx="1" transform="translate(1136.293 335)" fill="#fff"/>*/}
                                                        {/*                            </g>*/}
                                                        {/*                        </g>*/}
                                                        {/*                    </g>*/}
                                                        {/*                </svg>*/}

                                                        {/*            </div>*/}
                                                        {/*        </div>*/}

                                                        {/*        <div className="Item__block Item__block_inline Item__block_margin-right Item__block_padding">*/}
                                                        {/*            <div className="Item__label Item__label_inline">Макс. кол-во часов</div>*/}
                                                        {/*            <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}


                                                        {/*            <div style={{display: "inline-block", marginRight: 12, cursor: "pointer"}}>*/}
                                                        {/*                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">*/}
                                                        {/*                    <g id="Group_1680" data-name="Group 1680" transform="translate(-1392 -522)">*/}
                                                        {/*                        <rect id="Rectangle_2080" data-name="Rectangle 2080" width="32" height="32" rx="16" transform="translate(1392 522)" fill="#cacfd3"/>*/}
                                                        {/*                        <g id="Group_1677" data-name="Group 1677" transform="translate(1948 -930) rotate(90)">*/}
                                                        {/*                            <g id="Group_920" data-name="Group 920" transform="translate(420.563 1105.602) rotate(-45)">*/}
                                                        {/*                                <rect id="Rectangle_1180" data-name="Rectangle 1180" width="10" height="4" rx="1" transform="translate(1136.293 341.001)" fill="#fff"/>*/}
                                                        {/*                                <rect id="Rectangle_1181" data-name="Rectangle 1181" width="4" height="10" rx="1" transform="translate(1136.293 335)" fill="#fff"/>*/}
                                                        {/*                            </g>*/}
                                                        {/*                        </g>*/}
                                                        {/*                    </g>*/}
                                                        {/*                </svg>*/}

                                                        {/*            </div>*/}
                                                        {/*            <div className="Item__Text" style={{fontSize: 24, fontWeight: 900}}>6</div>*/}
                                                        {/*            <div style={{display: "inline-block", marginLeft: 12, cursor: "pointer"}}>*/}
                                                        {/*                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">*/}
                                                        {/*                    <g id="Group_1679" data-name="Group 1679" transform="translate(-1488 -524)">*/}
                                                        {/*                        <rect id="Rectangle_2081" data-name="Rectangle 2081" width="32" height="32" rx="16" transform="translate(1488 524)" fill="#cacfd3"/>*/}
                                                        {/*                        <g id="Group_1678" data-name="Group 1678" transform="translate(964 2008) rotate(-90)">*/}
                                                        {/*                            <g id="Group_920" data-name="Group 920" transform="translate(420.563 1105.602) rotate(-45)">*/}
                                                        {/*                                <rect id="Rectangle_1180" data-name="Rectangle 1180" width="10" height="4" rx="1" transform="translate(1136.293 341.001)" fill="#fff"/>*/}
                                                        {/*                                <rect id="Rectangle_1181" data-name="Rectangle 1181" width="4" height="10" rx="1" transform="translate(1136.293 335)" fill="#fff"/>*/}
                                                        {/*                            </g>*/}
                                                        {/*                        </g>*/}
                                                        {/*                    </g>*/}
                                                        {/*                </svg>*/}

                                                        {/*            </div>*/}
                                                        {/*        </div>*/}


                                                        {/*    </div>*/}
                                                        {/*</div>*/}


                                                    </React.Fragment>
                                                ) : (












                                                    <React.Fragment>

                                                        {trainingScheduleData.schedule_timetable.map((timetable, index) => (

                                                                <React.Fragment key={index}>

                                                                    {/*<div className="Body__label">Период обучения</div>*/}

                                                                    <div className="Schedule__period">
                                                                        <div className="Info__tr Info__tr_inline-block">
                                                                            <div className="Info__td">
                                                                                <div className="Info__box">

                                                                                    {timetable.year > 0 ? (
                                                                                        <React.Fragment>
                                                                                            <div className="Box__logo">{timetable.year}</div>
                                                                                            <div className="Item__hr Item__hr_vertical Item__hr_inline"/>
                                                                                            <div className="Box__text">Курс</div>
                                                                                        </React.Fragment>
                                                                                    ) : (
                                                                                        <React.Fragment>
                                                                                            <div className="Box__logo">
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 16">
                                                                                                    <g id="Group_1675" data-name="Group 1675" transform="translate(-815 -806)">
                                                                                                        <path id="infinity-solid" d="M17.659,96a7.837,7.837,0,0,0-5.664,2.947A7.837,7.837,0,0,0,6.33,96a6.007,6.007,0,1,0,0,12,7.837,7.837,0,0,0,5.664-2.947,7.837,7.837,0,0,0,5.664,2.947,6.007,6.007,0,1,0,0-12Zm-11.329,8.4a2.419,2.419,0,1,1,0-4.8c1.432,0,2.752,1.35,3.524,2.4C9.089,103.03,7.759,104.394,6.33,104.394Zm11.329,0c-1.432,0-2.752-1.353-3.524-2.4.765-1.035,2.1-2.4,3.524-2.4a2.419,2.419,0,1,1,0,4.8Z" transform="translate(815.005 712.012)" fill="#2a3e4c"/>
                                                                                                        <rect id="Rectangle_2081" data-name="Rectangle 2081" width="24" height="16" transform="translate(815 806)" fill="#f7f7f7" opacity="0"/>
                                                                                                    </g>
                                                                                                </svg>

                                                                                            </div>
                                                                                            <div className="Item__hr Item__hr_vertical Item__hr_inline"/>
                                                                                            <div className="Box__text">Все курсы</div>
                                                                                        </React.Fragment>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="Info__tr Info__tr_inline-block">
                                                                            <div className="Info__td">
                                                                                <div className="Info__box">

                                                                                    {timetable.period > 0 ? (
                                                                                        <React.Fragment>
                                                                                            <div className="Box__logo">{timetable.period}</div>
                                                                                            <div className="Item__hr Item__hr_vertical Item__hr_inline"/>
                                                                                            <div className="Box__text">Семестр</div>
                                                                                        </React.Fragment>
                                                                                    ) : (
                                                                                        <React.Fragment>
                                                                                            <div className="Box__logo">
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="16" viewBox="0 0 24 16">
                                                                                                    <g id="Group_1675" data-name="Group 1675" transform="translate(-815 -806)">
                                                                                                        <path id="infinity-solid" d="M17.659,96a7.837,7.837,0,0,0-5.664,2.947A7.837,7.837,0,0,0,6.33,96a6.007,6.007,0,1,0,0,12,7.837,7.837,0,0,0,5.664-2.947,7.837,7.837,0,0,0,5.664,2.947,6.007,6.007,0,1,0,0-12Zm-11.329,8.4a2.419,2.419,0,1,1,0-4.8c1.432,0,2.752,1.35,3.524,2.4C9.089,103.03,7.759,104.394,6.33,104.394Zm11.329,0c-1.432,0-2.752-1.353-3.524-2.4.765-1.035,2.1-2.4,3.524-2.4a2.419,2.419,0,1,1,0,4.8Z" transform="translate(815.005 712.012)" fill="#2a3e4c"/>
                                                                                                        <rect id="Rectangle_2081" data-name="Rectangle 2081" width="24" height="16" transform="translate(815 806)" fill="#f7f7f7" opacity="0"/>
                                                                                                    </g>
                                                                                                </svg>

                                                                                            </div>
                                                                                            <div className="Item__hr Item__hr_vertical Item__hr_inline"/>
                                                                                            <div className="Box__text">Все семестры</div>
                                                                                        </React.Fragment>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {timetable.timetables.map((timetableObject, index) => (

                                                                        <React.Fragment key={timetableObject.id}>
                                                                            <div className="Body__hr" style={{width: "25%"}}/>

                                                                            <div className="Body__label">Дни недели</div>

                                                                            <div className="Schedule__week">
                                                                                <div className="Info__tr Info__tr_inline-block">

                                                                                    {Number(timetableObject.week_day_mon) > 0 ? (
                                                                                        <div className="Info__td">
                                                                                            <div className="Info__box">
                                                                                                <div className="Box__logo">Пн</div>
                                                                                            </div>
                                                                                        </div>
                                                                                    ) : null}

                                                                                    {Number(timetableObject.week_day_tue) > 0 ? (
                                                                                        <div className="Info__td">
                                                                                            <div className="Info__box">
                                                                                                <div className="Box__logo">Вт</div>
                                                                                            </div>
                                                                                        </div>
                                                                                    ) : null}

                                                                                    {Number(timetableObject.week_day_wed) > 0 ? (
                                                                                        <div className="Info__td">
                                                                                            <div className="Info__box">
                                                                                                <div className="Box__logo">Ср</div>
                                                                                            </div>
                                                                                        </div>
                                                                                    ) : null}

                                                                                    {Number(timetableObject.week_day_thu) > 0 ? (
                                                                                        <div className="Info__td">
                                                                                            <div className="Info__box">
                                                                                                <div className="Box__logo">Чт</div>
                                                                                            </div>
                                                                                        </div>
                                                                                    ) : null}

                                                                                    {Number(timetableObject.week_day_fri) > 0 ? (
                                                                                        <div className="Info__td">
                                                                                            <div className="Info__box">
                                                                                                <div className="Box__logo">Пт</div>
                                                                                            </div>
                                                                                        </div>
                                                                                    ) : null}

                                                                                    {Number(timetableObject.week_day_sat) > 0 ? (
                                                                                        <div className="Info__td">
                                                                                            <div className="Info__box">
                                                                                                <div className="Box__logo">Сб</div>
                                                                                            </div>
                                                                                        </div>
                                                                                    ) : null}

                                                                                    {Number(timetableObject.week_day_sun) > 0 ? (
                                                                                        <div className="Info__td">
                                                                                            <div className="Info__box">
                                                                                                <div className="Box__logo">Вс</div>
                                                                                            </div>
                                                                                        </div>
                                                                                    ) : null}
                                                                                </div>
                                                                            </div>

                                                                            {this.maxWeekDays(timetableObject) > 0 ? (
                                                                                <React.Fragment>
                                                                                    <div className="Body__label" style={{marginBottom: 24}}>Учебные часы</div>

                                                                                    <div className="Schedule__hours">
                                                                                        <div className="Info__tr Info__tr_inline-block">
                                                                                            {Number(timetableObject.day_hour_0) > 0 ? (
                                                                                                <div className="Info__td">
                                                                                                    <div className="Info__box">
                                                                                                        <div className="Box__logo">0</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            ) : null}

                                                                                            {Number(timetableObject.day_hour_1) > 0 ? (
                                                                                                <div className="Info__td">
                                                                                                    <div className="Info__box">
                                                                                                        <div className="Box__logo">1</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            ) : null}

                                                                                            {Number(timetableObject.day_hour_2) > 0 ? (
                                                                                                <div className="Info__td">
                                                                                                    <div className="Info__box">
                                                                                                        <div className="Box__logo">2</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            ) : null}

                                                                                            {Number(timetableObject.day_hour_3) > 0 ? (
                                                                                                <div className="Info__td">
                                                                                                    <div className="Info__box">
                                                                                                        <div className="Box__logo">3</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            ) : null}

                                                                                            {Number(timetableObject.day_hour_4) > 0 ? (
                                                                                                <div className="Info__td">
                                                                                                    <div className="Info__box">
                                                                                                        <div className="Box__logo">4</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            ) : null}

                                                                                            {Number(timetableObject.day_hour_5) > 0 ? (
                                                                                                <div className="Info__td">
                                                                                                    <div className="Info__box">
                                                                                                        <div className="Box__logo">5</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            ) : null}

                                                                                            {Number(timetableObject.day_hour_6) > 0 ? (
                                                                                                <div className="Info__td">
                                                                                                    <div className="Info__box">
                                                                                                        <div className="Box__logo">6</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            ) : null}

                                                                                            {Number(timetableObject.day_hour_7) > 0 ? (
                                                                                                <div className="Info__td">
                                                                                                    <div className="Info__box">
                                                                                                        <div className="Box__logo">7</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            ) : null}

                                                                                            {Number(timetableObject.day_hour_8) > 0 ? (
                                                                                                <div className="Info__td">
                                                                                                    <div className="Info__box">
                                                                                                        <div className="Box__logo">8</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            ) : null}

                                                                                            {Number(timetableObject.day_hour_9) > 0 ? (
                                                                                                <div className="Info__td">
                                                                                                    <div className="Info__box">
                                                                                                        <div className="Box__logo">9</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            ) : null}

                                                                                            {Number(timetableObject.day_hour_10) > 0 ? (
                                                                                                <div className="Info__td">
                                                                                                    <div className="Info__box">
                                                                                                        <div className="Box__logo">10</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            ) : null}

                                                                                            {Number(timetableObject.day_hour_11) > 0 ? (
                                                                                                <div className="Info__td">
                                                                                                    <div className="Info__box">
                                                                                                        <div className="Box__logo">11</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            ) : null}

                                                                                            {Number(timetableObject.day_hour_12) > 0 ? (
                                                                                                <div className="Info__td">
                                                                                                    <div className="Info__box">
                                                                                                        <div className="Box__logo">12</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            ) : null}

                                                                                            {Number(timetableObject.day_hour_13) > 0 ? (
                                                                                                <div className="Info__td">
                                                                                                    <div className="Info__box">
                                                                                                        <div className="Box__logo">13</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            ) : null}

                                                                                            {Number(timetableObject.day_hour_14) > 0 ? (
                                                                                                <div className="Info__td">
                                                                                                    <div className="Info__box">
                                                                                                        <div className="Box__logo">14</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            ) : null}

                                                                                            {Number(timetableObject.day_hour_15) > 0 ? (
                                                                                                <div className="Info__td">
                                                                                                    <div className="Info__box">
                                                                                                        <div className="Box__logo">15</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            ) : null}

                                                                                            {Number(timetableObject.day_hour_16) > 0 ? (
                                                                                                <div className="Info__td">
                                                                                                    <div className="Info__box">
                                                                                                        <div className="Box__logo">16</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            ) : null}

                                                                                            {Number(timetableObject.day_hour_17) > 0 ? (
                                                                                                <div className="Info__td">
                                                                                                    <div className="Info__box">
                                                                                                        <div className="Box__logo">17</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            ) : null}

                                                                                            {Number(timetableObject.day_hour_18) > 0 ? (
                                                                                                <div className="Info__td">
                                                                                                    <div className="Info__box">
                                                                                                        <div className="Box__logo">18</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            ) : null}

                                                                                            {Number(timetableObject.day_hour_19) > 0 ? (
                                                                                                <div className="Info__td">
                                                                                                    <div className="Info__box">
                                                                                                        <div className="Box__logo">19</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            ) : null}

                                                                                            {Number(timetableObject.day_hour_20) > 0 ? (
                                                                                                <div className="Info__td">
                                                                                                    <div className="Info__box">
                                                                                                        <div className="Box__logo">20</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            ) : null}

                                                                                            {Number(timetableObject.day_hour_21) > 0 ? (
                                                                                                <div className="Info__td">
                                                                                                    <div className="Info__box">
                                                                                                        <div className="Box__logo">21</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            ) : null}

                                                                                            {Number(timetableObject.day_hour_22) > 0 ? (
                                                                                                <div className="Info__td">
                                                                                                    <div className="Info__box">
                                                                                                        <div className="Box__logo">22</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            ) : null}

                                                                                            {Number(timetableObject.day_hour_23) > 0 ? (
                                                                                                <div className="Info__td">
                                                                                                    <div className="Info__box">
                                                                                                        <div className="Box__logo">23</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            ) : null}

                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="Block__Item" style={{marginBottom: 42}}>
                                                                                        <div className="Item__wrapper">

                                                                                            <div className="Item__block Item__block_inline Item__block_margin-right Item__block_padding">
                                                                                                <div className="Item__label Item__label_inline">Сдвоенные часы</div>
                                                                                                <div className="Item__hr Item__hr_vertical Item__hr_inline"/>
                                                                                                <div className="Item__Text" style={{fontSize: 24, fontWeight: 900}}>{Number(timetableObject.double_hours) === 1 ? 'Да' : 'Нет'}</div>
                                                                                            </div>

                                                                                            <div className="Item__block Item__block_inline Item__block_margin-right Item__block_padding">
                                                                                                <div className="Item__label Item__label_inline">Макс. кол-во часов</div>
                                                                                                <div className="Item__hr Item__hr_vertical Item__hr_inline"/>
                                                                                                <div className="Item__Text" style={{fontSize: 24, fontWeight: 900}}>{timetableObject.max_hours_day}</div>
                                                                                            </div>


                                                                                        </div>
                                                                                    </div>
                                                                                </React.Fragment>
                                                                            ) : null}



                                                                        </React.Fragment>

                                                                    ))}

                                                                    <div className="Body__hr" style={{width: "50%"}}/>

                                                                </React.Fragment>
                                                        ))}

                                                    </React.Fragment>















                                                )}


                                                {/*<div className="Body__hr"/>*/}

                                                <div className="Schedule__switch" style={{marginBottom: 42, cursor: "pointer"}} onClick={this.isEditTimetables.bind(this, isEditTimetables)}>
                                                    <div className="Switch__wrapper" style={{marginRight: 12}}>
                                                        <label className={isEditTimetables ? 'switch switch_active' : 'switch'}>
                                                            <span className="switch__lever" />
                                                            {/*<span className="switch__index" />*/}
                                                        </label>
                                                    </div>
                                                    {/*<div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                                    <div className="Switch__text">Редактировать</div>
                                                </div>


                                            </div>
                                    </div>
                                </div>

                            </div>

                            {isEditTypeWeeks ? (
                                <div className="Section__button"
                                     onClick={this.trainingSchedulePeriodEdit.bind(this)}>
                                    <div className="button button_save"/>
                                </div>
                            ) : null}

                            {isEditTimetables ? (
                                <div className="Section__button"
                                     onClick={this.trainingScheduleTimetableEdit.bind(this)}>
                                    <div className="button button_save"/>
                                </div>
                            ) : null}

                        </div>
                    );
                }
            }
        }

        else return null;
    }
}

function mapStateToProps(state) {
    return {
        isLoad: state.page.isLoad,
        isUpdate: state.page.isUpdate,
        responseData: state.api.responseData,
        responseMessage: state.api.responseMessage,
        responseError:  state.api.responseError
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageMount: () => dispatch(pageMount()),
        pageLoad: (isLoad) => dispatch(pageLoad(isLoad)),
        pageUpdate: () => dispatch(pageUpdate()),
        notificationMessage: (message, colorMessage) => dispatch(notificationMessage(message, colorMessage)),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        modalOpen: (modalForm, modalData) => dispatch(modalOpen(modalForm, modalData))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (TrainingSchedule);
