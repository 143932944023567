import {
    PROFILE_LOAD,
    PROFILE_UPDATE,
    PROFILE_CLOSE,
    PROFILE_OPEN,
    PROFILE_DATA,
    PROFILE_REQUEST,
    PROFILE_RESPONSE,
    PROFILE_RESPONSE_ERROR, PROFILE_REQUEST_ERROR
} from "../actions/actionTypes";

const initialState = {
    isProfileOpen: false,
    isLoadProfile: false,
    isUpdateProfile: false,
    profileOpenForm: null,
    profileData: null,
    isRequest: true,
    responseMessage: null,
    responseData: null,
    responseError: null,
    apiResponseError: null,
    apiRequestError: null
}

export default function profileReducer(state = initialState, action) {
    switch (action.type) {
        case PROFILE_OPEN:
            return {
                ...state, isProfileOpen: true, profileOpenForm: action.profileOpenForm
            }
        case PROFILE_CLOSE:
            return {
                ...state, isProfileOpen: false, profileOpenForm: null
            }
        case PROFILE_LOAD:
            return {
                ...state, isLoadProfile: action.isLoadProfile, isUpdateProfile: false
            }
        case PROFILE_UPDATE:
            return {
                ...state, isUpdateProfile: true
            }
        case PROFILE_DATA:
            return {
                ...state, profileData: action.profileData
            }
        case PROFILE_REQUEST:
            return {
                ...state, isRequest: true, responseMessage: null, responseData: null, responseError: null, apiResponseError: null
            }
        case PROFILE_RESPONSE:
            return {
                ...state, isRequest: false, responseMessage: action.responseMessage, responseData: action.responseData, responseError: action.responseError
            }
        case PROFILE_RESPONSE_ERROR:
            return {
                ...state, isRequest: false, apiResponseError: action.apiResponseError
            }
        case PROFILE_REQUEST_ERROR:
            return {
                ...state, isRequest: false, apiRequestError: action.apiRequestError
            }
        default:
            return state;
    }
}
