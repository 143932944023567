import React from "react";
import {connect} from "react-redux";
import {pageLoad, pageUpdate, pageMount} from "../../../../../store/actions/pageAction";
import {apiRequest} from "../../../../../store/actions/apiAction";
import {modalOpen} from "../../../../../store/actions/modalAction";
import "./StudentTrainingCourses.scss";
import Loading from "../../../../loading/Loading";
import {Link} from "react-router-dom";

class StudentTrainingCourses extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isMount: true,
            isOpen: false,
            isLoad: false,
            isUpdate: false,
            studentTrainingCoursesData: null,



            calendarMonth: new Date().getMonth(), // месяцы в JS идут от 0 до 11, а не от 1 до 12
            calendarYear: new Date().getFullYear(),
            // calendarDay: new Date().getDate(),
            selectedDay: new Date().getDate(),
            selectedMonth: new Date().getMonth(),
            selectedYear: new Date().getFullYear(),
        };
    }

    componentDidMount() {
        this.props.pageMount();
        this.pageUpdate(this.state.isLoad);
        this.intervalUpdate = setInterval(this.props.pageUpdate, 180000);
    }

    componentWillUnmount() {
        // console.log('Employees Unmounted');
        clearInterval(this.intervalUpdate);
        this.setState({
            isMount: false
        });
    }

    componentDidUpdate() {
        if (this.state.isMount) {
            const responseData = this.props.responseData;
            // console.log(1 + ' componentDidUpdate');
            if (this.props.isLoad !== this.state.isLoad) {
                // console.log(2 + ' isLoad');
                if (responseData !== this.state.studentTrainingCoursesData) {
                    // console.log(3 + ' responseData');

                    this.setState({
                        isOpen: true,
                        isLoad: this.props.isLoad,
                        isUpdate: this.props.isUpdate,
                        studentTrainingCoursesData: responseData
                    });
                }
            }

            else if (this.props.isUpdate !== this.state.isUpdate) {
                // console.log(4 + ' isUpdate');
                this.pageUpdate(this.state.isLoad);

                this.setState({
                    isUpdate: this.props.isUpdate
                });
            }
        }
    }

    pageUpdate(isLoad) {
        this.props.apiRequest('api/cabinet/student/training/courses', null, () => {
            this.props.pageLoad(isLoad);
        }, null);
    }

    modalOpen(modalForm, modalData) {
        this.props.modalOpen(modalForm, modalData);
    }






















    firstDayOfMonth(date) {
        // Получить номер дня недели, от 0 (пн) до 6 (вс).
        let day = new Date(date).getDay();
        // Сделать воскресенье (0) последним днем.
        if (day === 0) day = 7;
        return day - 1;
    }

    dayIsToday() {
        return new Date().getDate();
    }

    monthIsToday() {
        return new Date().getMonth();
    }

    yearIsToday() {
        return new Date().getFullYear();
    }

    clickDay(month, day, year) {
        this.setState({
            selectedMonth: month,
            selectedDay: day,
            selectedYear: year,

            selectTypeHours: null,
            isTypeHours: false
        });

        window.scrollTo(0, 0);
    }

    monthPlus() {
        // console.log(this.state.calendarMonth);
        if (this.state.calendarMonth < 11) {

            this.setState({
                calendarMonth: this.state.calendarMonth + 1,

                selectTypeHours: null,
                isTypeHours: false
            });
        }

        else {
            this.setState({
                calendarMonth: 0,
                calendarYear: this.state.calendarYear + 1,

                selectTypeHours: null,
                isTypeHours: false
            });
        }
    }

    monthMines() {
        // console.log(this.state.calendarMonth);
        if (this.state.calendarMonth > 0) {

            this.setState({
                calendarMonth: this.state.calendarMonth - 1,

                selectTypeHours: null,
                isTypeHours: false
            });
        }

        else {
            this.setState({
                calendarMonth: 11,
                calendarYear: this.state.calendarYear - 1,

                selectTypeHours: null,
                isTypeHours: false
            });
        }
    }

    // selectedCurrent(selectedCurrent) {
    //     this.setState({
    //         selectedCurrent: !selectedCurrent
    //     })
    // }


    currentWeekYear(month, day, year) {
        // Как идет отсчет недели в учебном графике!
        // Какая неделя сейчас!
        let firstSeptemberSunday = false;
        let firstWeekSeptember = 0;
        let currentWeekYear = 0;
        let lastYearWeek = 52;

        if (Number(month) > 7) {
            if (new Date(Number(year), 8, 1).getDay() === 0) {

                firstSeptemberSunday = true;
            }

            if (firstSeptemberSunday) {

                firstWeekSeptember = new Date(Number(year), 8, 2).getWeek();
            }

            else {

                firstWeekSeptember = new Date(Number(year), 8, 1).getWeek();
            }

            if (new Date(Number(year), Number(month), Number(day)).getWeek() === 1) {
                currentWeekYear = 53 - firstWeekSeptember + 1;
            }

            else {
                currentWeekYear = new Date(Number(year), Number(month), Number(day)).getWeek() - firstWeekSeptember + 1;
            }
        }

        else {

            if (new Date(Number(year) - 1, 8, 1).getDay() === 0) {
                firstSeptemberSunday = true;
            }

            if (firstSeptemberSunday) {

                firstWeekSeptember = new Date(Number(year) - 1, 8, 2).getWeek();
            }

            else {

                firstWeekSeptember = new Date(Number(year) - 1, 8, 1).getWeek();
            }

            if ((new Date(Number(year) - 1, 11, 31).getWeek() === 53) || (new Date(Number(year), 0, 1).getWeek() === 53)) {
                lastYearWeek = 53;
            }

            if (new Date(Number(year), Number(month), Number(day)).getWeek() === 53) {
                if (firstSeptemberSunday) {

                    currentWeekYear = lastYearWeek - firstWeekSeptember + 1;
                }

                else {

                    currentWeekYear = lastYearWeek - firstWeekSeptember + 1;
                }
            }

            else if (new Date(Number(year), Number(month), Number(day)).getWeek() === 52) {
                if (firstSeptemberSunday) {

                    currentWeekYear = lastYearWeek - firstWeekSeptember + 1;
                }

                else {

                    currentWeekYear = lastYearWeek - firstWeekSeptember + 1;
                }
            }

            else if (new Date(Number(year), Number(month), Number(day)).getWeek() === 1) {



                if (firstSeptemberSunday) {

                    currentWeekYear = lastYearWeek - firstWeekSeptember + 2;
                }

                else {

                    currentWeekYear = lastYearWeek - firstWeekSeptember + 2;
                }
            }

            else {

                if (firstSeptemberSunday) {

                    currentWeekYear = lastYearWeek - new Date(Number(year) - 1, 8, 2).getWeek() + new Date(Number(year), Number(month), Number(day)).getWeek() + 1;
                }

                else {

                    currentWeekYear = lastYearWeek - new Date(Number(year) - 1, 8, 1).getWeek() + new Date(Number(year), Number(month), Number(day)).getWeek() + 1;
                }
            }

            if (currentWeekYear === 53) {

                if (new Date(Number(year), 8, 1).getDay() > 0) {
                    currentWeekYear = 1;
                }
            }
        }

        return currentWeekYear;
    }




    linkOpen(link) {
        window.open(link, '_blank');
    }




    render() {
        const { isMount, isOpen, studentTrainingCoursesData,
            selectedMonth, selectedDay, selectedYear
        } = this.state;

        let modalData = (objectId, objectData) => {
            return {
                objectId: objectId,
                objectData: objectData
            };
        }

        let groupName = (groupName, initialYear) => {
            let course;

            let date = new Date();
            course = (date.getFullYear() - Number(initialYear) + 1);

            if (groupName.search('\$') > 0) {
                return groupName.replace(/\$/i, course);
            }

            else {
                return groupName;
            }
        }

        if (isMount) {

            if (!isOpen || !studentTrainingCoursesData) {
                return (<div className="Page__loader"><i/><Loading/><i/></div>);
            }

            else {

                return (
                    <div className="Page__section">
                        {studentTrainingCoursesData.training_course ? (
                        <div className="Section__header">
                            <div className="Header__title">
                                <div className="Title__text">Учебные курсы</div>

                            </div>
                        </div>
                            ) : null}

                        <div className="Section__content">

                            <div className="TeacherTrainingCourses">
                                <div className="TeacherTrainingCourses__body">

                                    <div className="Body__wrapper">

                                    {studentTrainingCoursesData.training_course ? (
                                        <React.Fragment>


                                    <ul className="Body__list">

                                        {studentTrainingCoursesData.training_course.map((trainingCourse, index) => (
                                            <Link to={"/cabinet/student/training/course/" + trainingCourse.id} key={index}>
                                                <li className="List__box" style={{width: 376, display: "inline-block", marginRight: 42, verticalAlign: "top", cursor: "pointer"}} key={index}>
                                                        <div className="Box__header">
                                                            <div className="Header__title">
                                                                {trainingCourse.discipline_name}
                                                            </div>
                                                        </div>
                                                        <div className="Box__footer">
                                                            <div className="Block__Item" style={{display: "inline-block", marginBottom: 0, verticalAlign: "top"}}>
                                                                <div className="Item__block Item__block_inline Item__block_margin-right Item__block_padding">
                                                                    <div className="Item__Text"
                                                                         style={{fontSize: 17, fontWeight: 400}}>
                                                                        {trainingCourse.employee_fio}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                </li>
                                            </Link>
                                        ))}

                                    </ul>




                                        </React.Fragment>
                                    ) : (
                                    <React.Fragment>
                                        <div className="Page__loader">
                                            <i/>
                                            <div className="Body_nodata" style={{marginTop: 96 + 48, opacity: .15}}>
                                                Пока нет курсов
                                            </div>
                                            <i/>
                                        </div>
                                    </React.Fragment>
                                    )}

                                </div>
                                </div>
                            </div>

                        </div>

                    </div>
                );
            }
        }

        else return null;
    }
}

function mapStateToProps(state) {
    return {
        isLoad: state.page.isLoad,
        isUpdate: state.page.isUpdate,
        responseData: state.api.responseData
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageMount: () => dispatch(pageMount()),
        pageLoad: (isLoad) => dispatch(pageLoad(isLoad)),
        pageUpdate: () => dispatch(pageUpdate()),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        modalOpen: (modalForm, modalData) => dispatch(modalOpen(modalForm, modalData))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (StudentTrainingCourses);
