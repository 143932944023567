import React from "react";
import {connect} from "react-redux";
import {pageLoad, pageUpdate, pageMount} from "../../../../store/actions/pageAction";
import {apiRequest, apiUnmount} from "../../../../store/actions/apiAction";
import {modalOpen} from "../../../../store/actions/modalAction";
import "./StudentFeedback.scss";
import Loading from "../../../loading/Loading";
import baseURL from "../../../../library/baseURL";
import {alertOpen} from "../../../../store/actions/alertAction";
import {notificationMessage} from "../../../../store/actions/notificationAction";
import Baron from "../../../baron/Baron";

class StudentFeedback extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isMount: true,
            isOpen: false,
            isLoad: false,
            isUpdate: false,

            personalChatsData: null,
            selectChat: null,



            chatMessageText: null,

            adminEmail: null
        };
    }

    componentDidMount() {
        this.props.pageMount();
        this.pageUpdate(this.state.isLoad);
        this.intervalUpdate = setInterval(this.props.pageUpdate, 7200);
    }

    componentWillUnmount() {
        // console.log('Employees Unmounted');

        clearInterval(this.intervalUpdate);

        this.setState({
            isMount: false
        }, () => {
            this.props.apiUnmount();
        });
    }

    componentDidUpdate() {
        if (this.state.isMount) {
            const responseData = this.props.responseData;
            // console.log(1 + ' componentDidUpdate');
            if (this.props.isLoad !== this.state.isLoad) {
                // console.log(2 + ' isLoad');
                if (responseData !== this.state.personalChatsData) {
                    // console.log(3 + ' responseData');

                    this.adminEmail();

                    this.setState({
                        isOpen: true,
                        isLoad: this.props.isLoad,
                        isUpdate: this.props.isUpdate,
                        personalChatsData: responseData
                    }, () => {
                        let block = document.getElementById("chat");
                        block.scrollTop = block.scrollHeight;
                    });


                }
            }

            else if (this.props.isUpdate !== this.state.isUpdate) {
                // console.log(4 + ' isUpdate');
                this.pageUpdate(this.state.isLoad);

                this.setState({
                    isUpdate: this.props.isUpdate
                });
            }
        }
    }

    pageUpdate(isLoad) {
        this.props.apiRequest('api/cabinet/student/feedback', null, () => {
            this.props.pageLoad(isLoad);
        }, null);
    }

    adminEmail() {
        this.props.apiRequest('api/cabinet/feedback/email', null, () => {
            this.setState({
                adminEmail: this.props.responseData
            });
        }, null);
    }

    modalOpen(modalForm, modalData) {
        this.props.modalOpen(modalForm, modalData);
    }

    alertOpen(alertForm, alertData) {
        this.props.alertOpen(alertForm, alertData);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    selectChatUpdate() {

        let isUpdate = false;

        if (this.state.selectChat && this.state.personalChatsData) {
            this.state.personalChatsData.map(personalChat => {
                if (Number(personalChat.id) === Number(this.state.selectChat.id)) {
                    isUpdate = true;

                    this.setState({
                        selectChat: personalChat
                    }, () => {
                        let block = document.getElementById("chat");
                        block.scrollTop = block.scrollHeight;
                    });
                }
            });
        }

        if (!isUpdate) {
            this.setState({
                selectChat: null
            });
        }
    }


    handleSubmit(event) {
        event.preventDefault();

        if (this.state.chatMessageText) {


            let formData = new FormData();

            formData.append('message', this.state.chatMessageText);



            this.setState({
                isHandleSubmit: true
            });

            // console.log(this.state.chatMessageText);

            this.props.apiRequest('api/cabinet/feedback/user/message/add', formData, () => {

                    this.setState({
                        isHandleSubmit: false
                    });


                    this.props.pageUpdate();
                    this.setStateAndInput('chatMessageText', null);

                }, () => {
                    this.props.notificationMessage(this.props.responseError, 'red');
                    this.setState({
                        isHandleSubmit: false
                    });
                }
            );

        }

        else {

            this.props.notificationMessage('Пожалуйста, внимательно заполните поле отправки сообщения.', 'red');
        }
    }

    setStateAndInput(name, value) {

        let input = document.getElementById(name);
        input.value = value;

        this.setState({
            [name]: value
        });
    }

    selectChat(selectChat) {
        this.setState({
            selectChat: selectChat
        }, () => {

            let block = document.getElementById("chat");
            block.scrollTop = block.scrollHeight;
        });
    }

    render() {
        const { isMount, isOpen, personalChatsData, selectChat, chatMessageText, adminEmail } = this.state;



        let modalData = (objectId, objectData) => {
            return {
                objectId: objectId,
                objectData: objectData
            };
        }

        let alertData = (objectId, objectData) => {
            return {
                objectId: objectId,
                objectData: objectData
            };
        }

        let telephone = (telephone) => {

            telephone.split('');

            return '+7 (' +
                telephone[1] +
                telephone[2] +
                telephone[3] +
                ') ' +
                telephone[4] +
                telephone[5] +
                telephone[6] +
                '-' +
                telephone[7] +
                telephone[8] +
                telephone[9] +
                telephone[10];
        }

        let lastVisit = (time) => {

            return (
                <div style={{
                    position: "absolute",
                    bottom: -2,
                    right: -2,
                    height: 8,
                    width: 8
                }}
                     className={((parseInt(String(new Date().getTime()/1000)) - time) < 300) ? 'Student__online Student__online_green' : 'Student__online'}/>
            );
        }

        let lastVisitDataTime = (time) => {

            if (Number(time) > 0) {

                if ((parseInt(String(new Date().getTime()/1000)) - time) < 300) {

                    return 'сейчас';
                }

                else {
                    let date = new Date(time * 1000);
                    let day = "0" + date.getDate();
                    let month = "0" + (date.getMonth() + 1);
                    let year = String(date.getFullYear());
                    let hours = "0" + date.getHours();
                    let minutes = "0" + date.getMinutes();
                    let seconds = "0" + date.getSeconds();
                    let formattedData = day.substr(-2) + '.' + month.substr(-2) + '.' + year.substr(-2);
                    // let formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
                    let formattedTime = hours.substr(-2) + ':' + minutes.substr(-2);

                    return formattedTime + ' ' + formattedData;
                }
            }

            else {

                return 'никогда';
            }
        }

        let lastDataTime = (time) => {

            if (Number(time) > 0) {

                let date = new Date(time * 1000);
                let day = "0" + date.getDate();
                let month = "0" + (date.getMonth() + 1);
                let year = String(date.getFullYear());
                let hours = "0" + date.getHours();
                let minutes = "0" + date.getMinutes();
                let seconds = "0" + date.getSeconds();
                let formattedData = day.substr(-2) + '.' + month.substr(-2) + '.' + year.substr(-2);
                // let formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
                let formattedTime = hours.substr(-2) + ':' + minutes.substr(-2);

                return formattedTime + ' ' + formattedData;
            }

            else {

                return null;
            }
        }

        let groupName = (groupName, initialYear) => {
            let course;

            let date = new Date();
            course = (date.getFullYear() - Number(initialYear) + 1);

            if (groupName) {
                return groupName.replace(/\$/i, course);
            }
        }

        if (isMount) {

            if (!isOpen || !personalChatsData) {
                return (<div className="Page__loader"><i/><Loading/><i/></div>);
            }

            else {

                return (
                    <div className="Page__section">
                        <div className="Section__header">
                            <div className="Header__title">
                                <div className="Title__text" style={{marginBottom: 8}}>Обратная связь</div><br/>
                                <div className="Title__text" style={{
                                    fontSize: 16,
                                    fontWeight: 400,
                                    opacity: .75
                                }}>Дополнительно можно обратиться по адресу: {adminEmail}</div>

                            </div>
                        </div>
                        <div className="Section__content" style={{height: "calc(100% - 38px - 42px - 8px - 19px)"}}>

                            <div className="StudentFeedback">
                                <div className="StudentFeedback__body">

                                    {/*<div className="Body__hr"/>*/}




                                    {/*<div className="Body__wrapper" style={{width: 460 + 42 + 2 + 638 + 42}}>*/}
                                    {/*<div className="Body__wrapper" style={{width: 360 + 638 + 42}}>*/}











                                        {/*<div className="Chat__messages" style={{*/}
                                        {/*    display: "inline-block",*/}
                                        {/*    width: 360*/}
                                        {/*}}>*/}

                                        {/*    <div className="Course__header Course__header_margin-bottom-38">*/}
                                        {/*        <div className="Header__title">*/}
                                        {/*            <div className="Title__text">Обратная свзяь</div>*/}

                                                    {/*<div className="Button__add" style={{*/}
                                                    {/*    display: "inline-block",*/}
                                                    {/*    float: "right",*/}
                                                    {/*    cursor: "pointer"*/}
                                                    {/*}}*/}
                                                    {/*     onClick={this.modalOpen.bind(this, 'modalStudentChatAdd', modalData(null, null))}>*/}



                                                        {/*<svg xmlns="http://www.w3.org/2000/svg" id="Group_1521" data-name="Group 1521" width="32" height="32" viewBox="0 0 32 32">*/}
                                                        {/*    <g id="Group_1301" data-name="Group 1301" transform="translate(4 4)">*/}
                                                        {/*        <rect id="Rectangle_1358" data-name="Rectangle 1358" width="24" height="24" rx="12" fill="#2a3e4c"/>*/}
                                                        {/*        <rect id="Rectangle_1359" data-name="Rectangle 1359" width="12" height="12" rx="6" transform="translate(6 6)" fill="none"/>*/}
                                                        {/*    </g>*/}
                                                        {/*    <rect id="Rectangle_1371" data-name="Rectangle 1371" width="6" height="6" rx="3" transform="translate(13)" fill="#f7f7f7"/>*/}
                                                        {/*    <rect id="Rectangle_1373" data-name="Rectangle 1373" width="6" height="6" rx="3" transform="translate(26 13)" fill="#f7f7f7"/>*/}
                                                        {/*    <rect id="Rectangle_1374" data-name="Rectangle 1374" width="6" height="6" rx="3" transform="translate(13 26)" fill="#f7f7f7"/>*/}
                                                        {/*    <rect id="Rectangle_1375" data-name="Rectangle 1375" width="6" height="6" rx="3" transform="translate(0 13)" fill="#f7f7f7"/>*/}
                                                        {/*    <rect id="Rectangle_1376" data-name="Rectangle 1376" width="6" height="6" rx="3" transform="translate(25.192 2.565) rotate(45)" fill="#f7f7f7"/>*/}
                                                        {/*    <rect id="Rectangle_1377" data-name="Rectangle 1377" width="10" height="10" rx="5" transform="translate(11 11)" fill="#f7f7f7"/>*/}
                                                        {/*    <rect id="Rectangle_1378" data-name="Rectangle 1378" width="6" height="6" rx="3" transform="translate(25.192 20.95) rotate(45)" fill="#f7f7f7"/>*/}
                                                        {/*    <rect id="Rectangle_1379" data-name="Rectangle 1379" width="6" height="6" rx="3" transform="translate(6.808 20.95) rotate(45)" fill="#f7f7f7"/>*/}
                                                        {/*    <rect id="Rectangle_1380" data-name="Rectangle 1380" width="6" height="6" rx="3" transform="translate(6.808 2.565) rotate(45)" fill="#f7f7f7"/>*/}
                                                        {/*</svg>*/}

                                                {/*        <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">*/}
                                                {/*            <g id="Group_1662" data-name="Group 1662" transform="translate(-1826 -274)">*/}
                                                {/*                <circle id="Ellipse_61" data-name="Ellipse 61" cx="21" cy="21" r="21" transform="translate(1826 274)" fill="#0061ff"/>*/}
                                                {/*                <rect id="Rectangle_2052" data-name="Rectangle 2052" width="4" height="14" rx="1" transform="translate(1845 288)" fill="#fff"/>*/}
                                                {/*                <rect id="Rectangle_2053" data-name="Rectangle 2053" width="4" height="14" rx="1" transform="translate(1854 293) rotate(90)" fill="#fff"/>*/}
                                                {/*            </g>*/}
                                                {/*        </svg>*/}

                                                {/*    </div>*/}
                                                {/*</div>*/}

                                                {/*<div className="Body__hr" style={{marginTop: 0, marginBottom: 42, height: 0}}/>*/}


                                                {/*<div className="Course__wrapper">*/}

                                                {/*    <Baron baronId={'chatRoom'}>*/}


                                                {/*        {personalChatsData.length > 0 ? (*/}
                                                {/*            personalChatsData.map(personalChat => (*/}
                                                {/*                // <div className={selectChat ? ((Number(selectChat.id) === Number(personalChat.id)) ? "Course__info active" : "Course__info") : "Course__info"}*/}
                                                {/*                //      key={personalChat.id} onClick={this.selectChat.bind(this, personalChat)}>*/}
                                                {/*                //*/}
                                                {/*                //*/}
                                                {/*                //*/}
                                                {/*                //     <div className="Title__date" style={{opacity: 0.75, marginBottom: 6, fontWeight: 700, fontSize: 17, color: "#2A3E4C"}}>*/}
                                                {/*                //         <div style={{display: "inline-block", marginRight: 12}}>{personalChat.employee_fio}</div>*/}
                                                {/*                //*/}
                                                {/*                //     </div>*/}
                                                {/*                //     <div className="Title__date" style={{opacity: 0.75, marginBottom: 0, fontWeight: 400, fontSize: 17, color: "#2A3E4C"}}>*/}
                                                {/*                //         {personalChat.chat_messages[personalChat.chat_messages.length - 1].user_type === 'student' ? 'Вы: ' : null}*/}
                                                {/*                //         {personalChat.chat_messages[personalChat.chat_messages.length - 1].message}*/}
                                                {/*                //         <br/>*/}
                                                {/*                //         {personalChat.chat_messages[personalChat.chat_messages.length - 1].time}*/}
                                                {/*                //     </div>*/}


                                                {/*                <div key={personalChat.id} style={{*/}
                                                {/*                    marginBottom: 24*/}
                                                {/*                }}>*/}




                                                {/*                    <div className={selectChat ? ((Number(selectChat.id) === Number(personalChat.id)) ? "Course__info active" : "Course__info") : "Course__info"}*/}
                                                {/*                         onClick={this.selectChat.bind(this, personalChat)}*/}


                                                {/*                         style={{*/}
                                                {/*                             verticalAlign: "top",*/}
                                                {/*                             // display: "inline-block",*/}
                                                {/*                             padding: 16,*/}
                                                {/*                             maxWidth: 440,*/}
                                                {/*                             borderRadius: 14*/}
                                                {/*                         }}>*/}
                                                {/*                        <div style={{*/}
                                                {/*                            marginBottom: 2*/}
                                                {/*                        }}>*/}

                                                {/*                            {personalChat.employee_avatar ? (*/}
                                                {/*                                <div style={{*/}
                                                {/*                                    marginRight: 16,*/}
                                                {/*                                    verticalAlign: "top",*/}
                                                {/*                                    position: "relative",*/}
                                                {/*                                    display: "inline-block",*/}
                                                {/*                                    width: 42,*/}
                                                {/*                                    height: 42*/}
                                                {/*                                }}>*/}
                                                {/*                                    <div className="Student__photo1">*/}
                                                {/*                                        /!*<svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">*!/*/}
                                                {/*                                        /!*    <image id="baseline_account_circle_black_36dp" width="42" height="42" opacity="0.1" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAABtUlEQVR4Ae3WAWQCURjA8SGEEIZDGIZDCMMQxjAMIUAIAwwQBghhOBxggBCGw2GAACGEMAwhHA4hhBC+/RHort537y5Bf36Azuu9d+/dzZm61oSHEFMs9yYIMEADZ+0WPlYQpQh9VFBYJfSxhliK8Y7cOZhCCvKTZ7ZcRJCC/cKxmZkYorBAgBCRflD6mSpjBjHYoJuy33rYqZZPmQdRaONYPYiZeaPXsIUYTE1vpnL5YtPSDSEKPkyFEIU+UqtgC1HwYOpbf3im14IojWFqAVFqINEIksETjtWFZDBAopnFdZA2qDY2kAwCJIogFsbw4Oe4ZiZIJBe0vOCA9G9aDLmQKRLNM675J1qo426vgTb8jM8LkSiAnLCDj3toa2Boe9B2DK/4A2x7MXwBNJGoeuJHr8jbFyTFyuZCrCNvzzYXtXtklv7gwDYHS8iBNW5B2T9B5qgha3Us9J8eyaonHrDGG0owVcYHtifOnhJUuYYLcokBHlE5+DNP8A0HbWSzBZqGQdmK4cIqFwtIQWZwkKsqhthBLG3hoYzCchFil3EgQ9RwtqroIMAc8cFmnWGEFiootGv/gzxqH8WJhToAAAAASUVORK5CYII="/>*!/*/}
                                                {/*                                        /!*</svg>*!/*/}

                                                {/*                                        <svg xmlns="http://www.w3.org/2000/svg" id="account_circle-black-18dp" width="42" height="42" viewBox="0 0 100 100">*/}
                                                {/*                                            <path id="Path_1" data-name="Path 1" d="M0,0H100V100H0Z" fill="none"/>*/}
                                                {/*                                            <path opacity="0.1" id="Path_2" data-name="Path 2" d="M50,2A48,48,0,1,0,98,50,48.018,48.018,0,0,0,50,2Zm0,14.4A14.4,14.4,0,1,1,35.6,30.8,14.381,14.381,0,0,1,50,16.4Zm0,68.16A34.563,34.563,0,0,1,21.2,69.1C21.344,59.552,40.4,54.32,50,54.32c9.552,0,28.656,5.232,28.8,14.784A34.563,34.563,0,0,1,50,84.56Z" fill="#000"/>*/}
                                                {/*                                        </svg>*/}
                                                {/*                                    </div>*/}
                                                {/*                                    <img style={{*/}
                                                {/*                                        position: "absolute",*/}
                                                {/*                                        top: 0,*/}
                                                {/*                                        left: 0,*/}
                                                {/*                                        width: 42,*/}
                                                {/*                                        height: 42,*/}
                                                {/*                                        borderRadius: "50%",*/}
                                                {/*                                        opacity: 1*/}
                                                {/*                                    }}*/}
                                                {/*                                         src={baseURL() + 'api/images/avatar/' + personalChat.employee_avatar}*/}
                                                {/*                                         width="100"*/}
                                                {/*                                         height="100"/>*/}
                                                {/*                                    {lastVisit(personalChat.last_visit)}*/}
                                                {/*                                </div>*/}
                                                {/*                            ) : (*/}
                                                {/*                                <div style={{*/}
                                                {/*                                    marginRight: 16,*/}
                                                {/*                                    verticalAlign: "top",*/}
                                                {/*                                    position: "relative",*/}
                                                {/*                                    display: "inline-block",*/}
                                                {/*                                    width: 42,*/}
                                                {/*                                    height: 42*/}
                                                {/*                                }}>*/}
                                                {/*                                    <div className="Student__photo1">*/}
                                                {/*                                        /!*<svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">*!/*/}
                                                {/*                                        /!*    <image id="baseline_account_circle_black_36dp" width="42" height="42" opacity="0.1" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAABtUlEQVR4Ae3WAWQCURjA8SGEEIZDGIZDCMMQxjAMIUAIAwwQBghhOBxggBCGw2GAACGEMAwhHA4hhBC+/RHort537y5Bf36Azuu9d+/dzZm61oSHEFMs9yYIMEADZ+0WPlYQpQh9VFBYJfSxhliK8Y7cOZhCCvKTZ7ZcRJCC/cKxmZkYorBAgBCRflD6mSpjBjHYoJuy33rYqZZPmQdRaONYPYiZeaPXsIUYTE1vpnL5YtPSDSEKPkyFEIU+UqtgC1HwYOpbf3im14IojWFqAVFqINEIksETjtWFZDBAopnFdZA2qDY2kAwCJIogFsbw4Oe4ZiZIJBe0vOCA9G9aDLmQKRLNM675J1qo426vgTb8jM8LkSiAnLCDj3toa2Boe9B2DK/4A2x7MXwBNJGoeuJHr8jbFyTFyuZCrCNvzzYXtXtklv7gwDYHS8iBNW5B2T9B5qgha3Us9J8eyaonHrDGG0owVcYHtifOnhJUuYYLcokBHlE5+DNP8A0HbWSzBZqGQdmK4cIqFwtIQWZwkKsqhthBLG3hoYzCchFil3EgQ9RwtqroIMAc8cFmnWGEFiootGv/gzxqH8WJhToAAAAASUVORK5CYII="/>*!/*/}
                                                {/*                                        /!*</svg>*!/*/}

                                                {/*                                        <svg xmlns="http://www.w3.org/2000/svg" id="account_circle-black-18dp" width="42" height="42" viewBox="0 0 100 100">*/}
                                                {/*                                            <path id="Path_1" data-name="Path 1" d="M0,0H100V100H0Z" fill="none"/>*/}
                                                {/*                                            <path opacity="0.1" id="Path_2" data-name="Path 2" d="M50,2A48,48,0,1,0,98,50,48.018,48.018,0,0,0,50,2Zm0,14.4A14.4,14.4,0,1,1,35.6,30.8,14.381,14.381,0,0,1,50,16.4Zm0,68.16A34.563,34.563,0,0,1,21.2,69.1C21.344,59.552,40.4,54.32,50,54.32c9.552,0,28.656,5.232,28.8,14.784A34.563,34.563,0,0,1,50,84.56Z" fill="#000"/>*/}
                                                {/*                                        </svg>*/}
                                                {/*                                    </div>*/}
                                                {/*                                    {lastVisit(personalChat.last_visit)}*/}
                                                {/*                                </div>*/}
                                                {/*                            )}*/}

                                                {/*                            <div style={{*/}
                                                {/*                                display: "inline-block",*/}
                                                {/*                                verticalAlign: "top"*/}
                                                {/*                            }}>*/}
                                                {/*                                <div className="Info__title" style={{*/}
                                                {/*                                    display: "inline-block",*/}
                                                {/*                                    // opacity: 0.75,*/}
                                                {/*                                    fontSize: 15, fontWeight: 700,*/}
                                                {/*                                    marginBottom: 0,*/}
                                                {/*                                    paddingTop: 2,*/}
                                                {/*                                    width: 328 - 42 - 16,*/}
                                                {/*                                    textOverflow: "ellipsis",*/}
                                                {/*                                    overflow: "hidden",*/}
                                                {/*                                    whiteSpace: "nowrap",*/}
                                                {/*                                    wordWrap: "break-word",*/}
                                                {/*                                    color: "#2A3E4C"}}>*/}
                                                {/*                                    {personalChat.employee_fio}*/}
                                                {/*                                </div>*/}
                                                {/*                                <br/>*/}
                                                {/*                                {personalChat.chat_messages.length > 0 ? (*/}
                                                {/*                                    <div className="Title__date" style={{*/}
                                                {/*                                        display: "inline-block",*/}
                                                {/*                                        opacity: 0.75,*/}
                                                {/*                                        marginBottom: 0,*/}
                                                {/*                                        fontWeight: 400,*/}
                                                {/*                                        fontSize: 13,*/}
                                                {/*                                        color: "#2A3E4C"*/}
                                                {/*                                    }}>*/}
                                                {/*                                        {lastDataTime(personalChat.chat_messages[personalChat.chat_messages.length - 1].time)}*/}
                                                {/*                                    </div>*/}
                                                {/*                                ) : null}*/}
                                                {/*                            </div>*/}
                                                {/*                        </div>*/}
                                                {/*                        {personalChat.chat_messages.length > 0 ? (*/}
                                                {/*                            <div className="Info__text" style={{*/}
                                                {/*                                marginBottom: 0,*/}
                                                {/*                                opacity: 1,*/}
                                                {/*                                fontFamily: "Roboto, sans-serif",*/}
                                                {/*                                fontSize: 15,*/}
                                                {/*                                width: 328 - 42 - 16,*/}
                                                {/*                                textOverflow: "ellipsis",*/}
                                                {/*                                overflow: "hidden",*/}
                                                {/*                                whiteSpace: "nowrap",*/}
                                                {/*                                wordWrap: "break-word",*/}
                                                {/*                                color: "#2A3E4C",*/}
                                                {/*                                paddingLeft: 42 + 16*/}
                                                {/*                            }}>*/}
                                                {/*                                {personalChat.chat_messages[personalChat.chat_messages.length - 1].user_type === 'student' ? 'Вы: ' : null}*/}
                                                {/*                                {personalChat.chat_messages[personalChat.chat_messages.length - 1].message}*/}
                                                {/*                            </div>*/}
                                                {/*                        ) : null}*/}
                                                {/*                    </div>*/}
                                                {/*                </div>*/}

                                                {/*                //*/}
                                                {/*                //*/}
                                                {/*                //*/}
                                                {/*                //     /!*<div className="Info__edit"*!/*/}
                                                {/*                //     /!*     onClick={this.modalOpen.bind(this, 'modalTeacherTrainingCourseLessonEdit', modalData(null, null))}>*!/*/}
                                                {/*                //*/}
                                                {/*                //     /!*    <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">*!/*/}
                                                {/*                //     /!*        <g id="Group_2240" data-name="Group 2240" transform="translate(-471 -775)">*!/*/}
                                                {/*                //     /!*            <rect id="Rectangle_2757" data-name="Rectangle 2757" width="42" height="42" transform="translate(471 775)" fill="#f7f7f7" opacity="0"/>*!/*/}
                                                {/*                //     /!*            <g id="Group_2239" data-name="Group 2239" transform="translate(50 28)">*!/*/}
                                                {/*                //     /!*                <path id="Path_965" data-name="Path 965" d="M2.657,17.495a2.447,2.447,0,0,1,.707-1.509L13.983,5.367l1.73,1.73L6.2,16.612l1.73,1.73,9.515-9.515,1.73,1.73L9.658,20.073l1.73,1.73L20.9,12.287l1.73,1.73L12.014,24.636a2.447,2.447,0,0,1-1.509.707l-7.171.652a1.223,1.223,0,0,1-1.329-1.329Zm6.478,5.516-3.656.332A1.4,1.4,0,0,0,5.1,22.9c-.31-.272-.454-.272-.454-.272l.342-3.762Z" transform="translate(428 754)" fill="#2a3e4c" fillRule="evenodd"/>*!/*/}
                                                {/*                //     /!*                <path id="Path_966" data-name="Path 966" d="M13.054,4.447l1.731-1.731a2.447,2.447,0,0,1,3.461,0l5.192,5.192a2.447,2.447,0,0,1,0,3.461L21.707,13.1Z" transform="translate(429.852 754)" fill="#2a3e4c"/>*!/*/}
                                                {/*                //     /!*            </g>*!/*/}
                                                {/*                //     /!*        </g>*!/*/}
                                                {/*                //     /!*    </svg>*!/*/}
                                                {/*                //*/}
                                                {/*                //*/}
                                                {/*                //     /!*</div>*!/*/}
                                                {/*                // /!*</div>*!/*/}
                                                {/*            ))*/}
                                                {/*        ) : null}*/}

                                                {/*    </Baron>*/}

                                                {/*</div>*/}

                                            {/*</div>*/}

















                                        {/*</div>*/}


                                        <div className="Chat Chat_feedback" style={{display: "inline-block", width: 552 + 42}}>
                                            {/*{selectChat ? (*/}
                                                <React.Fragment>

                                                    <div className="Chat__room _after Chat__room_feedback">
                                                        <Baron baronId={'chat'}>

                                                            <div style={{
                                                                paddingTop: 56
                                                            }}/>

                                                            {personalChatsData.map((chat_message, index) => (
                                                                <div key={index} style={{
                                                                    marginTop: 24
                                                                }}>




                                                                    <div className="Course__info" style={{
                                                                        verticalAlign: "top",
                                                                        display: "inline-block",
                                                                        padding: 16,
                                                                        maxWidth: 440,
                                                                        backgroundColor: (chat_message.user_type === 'student' ? "white" : (chat_message.user_type === 'teacher' ? "white" : "#eee")),
                                                                        borderRadius: 7
                                                                    }}>
                                                                        <div style={{
                                                                            marginBottom: 2
                                                                        }}>
                                                                            {/*{chat_message.user_type === 'student' ? (*/}
                                                                            {/*    selectChat.student_avatar ? (*/}
                                                                            {/*        <div style={{*/}
                                                                            {/*            marginRight: 16,*/}
                                                                            {/*            verticalAlign: "top",*/}
                                                                            {/*            position: "relative",*/}
                                                                            {/*            display: "inline-block"*/}
                                                                            {/*        }}>*/}
                                                                            {/*            <div className="Student__photo1">*/}
                                                                            {/*                /!*<svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">*!/*/}
                                                                            {/*                /!*    <image id="baseline_account_circle_black_36dp" width="42" height="42" opacity="0.1" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAABtUlEQVR4Ae3WAWQCURjA8SGEEIZDGIZDCMMQxjAMIUAIAwwQBghhOBxggBCGw2GAACGEMAwhHA4hhBC+/RHort537y5Bf36Azuu9d+/dzZm61oSHEFMs9yYIMEADZ+0WPlYQpQh9VFBYJfSxhliK8Y7cOZhCCvKTZ7ZcRJCC/cKxmZkYorBAgBCRflD6mSpjBjHYoJuy33rYqZZPmQdRaONYPYiZeaPXsIUYTE1vpnL5YtPSDSEKPkyFEIU+UqtgC1HwYOpbf3im14IojWFqAVFqINEIksETjtWFZDBAopnFdZA2qDY2kAwCJIogFsbw4Oe4ZiZIJBe0vOCA9G9aDLmQKRLNM675J1qo426vgTb8jM8LkSiAnLCDj3toa2Boe9B2DK/4A2x7MXwBNJGoeuJHr8jbFyTFyuZCrCNvzzYXtXtklv7gwDYHS8iBNW5B2T9B5qgha3Us9J8eyaonHrDGG0owVcYHtifOnhJUuYYLcokBHlE5+DNP8A0HbWSzBZqGQdmK4cIqFwtIQWZwkKsqhthBLG3hoYzCchFil3EgQ9RwtqroIMAc8cFmnWGEFiootGv/gzxqH8WJhToAAAAASUVORK5CYII="/>*!/*/}
                                                                            {/*                /!*</svg>*!/*/}

                                                                            {/*                <svg xmlns="http://www.w3.org/2000/svg" id="account_circle-black-18dp" width="42" height="42" viewBox="0 0 100 100">*/}
                                                                            {/*                    <path id="Path_1" data-name="Path 1" d="M0,0H100V100H0Z" fill="none"/>*/}
                                                                            {/*                    <path opacity="0.1" id="Path_2" data-name="Path 2" d="M50,2A48,48,0,1,0,98,50,48.018,48.018,0,0,0,50,2Zm0,14.4A14.4,14.4,0,1,1,35.6,30.8,14.381,14.381,0,0,1,50,16.4Zm0,68.16A34.563,34.563,0,0,1,21.2,69.1C21.344,59.552,40.4,54.32,50,54.32c9.552,0,28.656,5.232,28.8,14.784A34.563,34.563,0,0,1,50,84.56Z" fill="#000"/>*/}
                                                                            {/*                </svg>*/}
                                                                            {/*            </div>*/}
                                                                            {/*            <img style={{*/}
                                                                            {/*                position: "absolute",*/}
                                                                            {/*                top: 0,*/}
                                                                            {/*                left: 0,*/}
                                                                            {/*                width: 42,*/}
                                                                            {/*                height: 42,*/}
                                                                            {/*                borderRadius: "50%",*/}
                                                                            {/*                opacity: 1*/}
                                                                            {/*            }}*/}
                                                                            {/*                 src={baseURL() + 'api/images/avatar/' + selectChat.student_avatar}*/}
                                                                            {/*                 width="100"*/}
                                                                            {/*                 height="100"/>*/}
                                                                            {/*        </div>*/}
                                                                            {/*    ) : (*/}
                                                                            {/*        <div style={{*/}
                                                                            {/*            marginRight: 16,*/}
                                                                            {/*            verticalAlign: "top",*/}
                                                                            {/*            position: "relative",*/}
                                                                            {/*            display: "inline-block"*/}
                                                                            {/*        }}>*/}
                                                                            {/*            <div className="Student__photo1">*/}
                                                                            {/*                /!*<svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">*!/*/}
                                                                            {/*                /!*    <image id="baseline_account_circle_black_36dp" width="42" height="42" opacity="0.1" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAABtUlEQVR4Ae3WAWQCURjA8SGEEIZDGIZDCMMQxjAMIUAIAwwQBghhOBxggBCGw2GAACGEMAwhHA4hhBC+/RHort537y5Bf36Azuu9d+/dzZm61oSHEFMs9yYIMEADZ+0WPlYQpQh9VFBYJfSxhliK8Y7cOZhCCvKTZ7ZcRJCC/cKxmZkYorBAgBCRflD6mSpjBjHYoJuy33rYqZZPmQdRaONYPYiZeaPXsIUYTE1vpnL5YtPSDSEKPkyFEIU+UqtgC1HwYOpbf3im14IojWFqAVFqINEIksETjtWFZDBAopnFdZA2qDY2kAwCJIogFsbw4Oe4ZiZIJBe0vOCA9G9aDLmQKRLNM675J1qo426vgTb8jM8LkSiAnLCDj3toa2Boe9B2DK/4A2x7MXwBNJGoeuJHr8jbFyTFyuZCrCNvzzYXtXtklv7gwDYHS8iBNW5B2T9B5qgha3Us9J8eyaonHrDGG0owVcYHtifOnhJUuYYLcokBHlE5+DNP8A0HbWSzBZqGQdmK4cIqFwtIQWZwkKsqhthBLG3hoYzCchFil3EgQ9RwtqroIMAc8cFmnWGEFiootGv/gzxqH8WJhToAAAAASUVORK5CYII="/>*!/*/}
                                                                            {/*                /!*</svg>*!/*/}

                                                                            {/*                <svg xmlns="http://www.w3.org/2000/svg" id="account_circle-black-18dp" width="42" height="42" viewBox="0 0 100 100">*/}
                                                                            {/*                    <path id="Path_1" data-name="Path 1" d="M0,0H100V100H0Z" fill="none"/>*/}
                                                                            {/*                    <path opacity="0.1" id="Path_2" data-name="Path 2" d="M50,2A48,48,0,1,0,98,50,48.018,48.018,0,0,0,50,2Zm0,14.4A14.4,14.4,0,1,1,35.6,30.8,14.381,14.381,0,0,1,50,16.4Zm0,68.16A34.563,34.563,0,0,1,21.2,69.1C21.344,59.552,40.4,54.32,50,54.32c9.552,0,28.656,5.232,28.8,14.784A34.563,34.563,0,0,1,50,84.56Z" fill="#000"/>*/}
                                                                            {/*                </svg>*/}
                                                                            {/*            </div>*/}
                                                                            {/*        </div>*/}
                                                                            {/*    )*/}
                                                                            {/*) : null}*/}

                                                                            {/*{chat_message.user_type === 'employee' ? (*/}
                                                                            {/*    selectChat.employee_avatar ? (*/}
                                                                            {/*        <div style={{*/}
                                                                            {/*            marginRight: 16,*/}
                                                                            {/*            verticalAlign: "top",*/}
                                                                            {/*            position: "relative",*/}
                                                                            {/*            display: "inline-block"*/}
                                                                            {/*        }}>*/}
                                                                            {/*            <div className="Student__photo1">*/}
                                                                            {/*                /!*<svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">*!/*/}
                                                                            {/*                /!*    <image id="baseline_account_circle_black_36dp" width="42" height="42" opacity="0.1" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAABtUlEQVR4Ae3WAWQCURjA8SGEEIZDGIZDCMMQxjAMIUAIAwwQBghhOBxggBCGw2GAACGEMAwhHA4hhBC+/RHort537y5Bf36Azuu9d+/dzZm61oSHEFMs9yYIMEADZ+0WPlYQpQh9VFBYJfSxhliK8Y7cOZhCCvKTZ7ZcRJCC/cKxmZkYorBAgBCRflD6mSpjBjHYoJuy33rYqZZPmQdRaONYPYiZeaPXsIUYTE1vpnL5YtPSDSEKPkyFEIU+UqtgC1HwYOpbf3im14IojWFqAVFqINEIksETjtWFZDBAopnFdZA2qDY2kAwCJIogFsbw4Oe4ZiZIJBe0vOCA9G9aDLmQKRLNM675J1qo426vgTb8jM8LkSiAnLCDj3toa2Boe9B2DK/4A2x7MXwBNJGoeuJHr8jbFyTFyuZCrCNvzzYXtXtklv7gwDYHS8iBNW5B2T9B5qgha3Us9J8eyaonHrDGG0owVcYHtifOnhJUuYYLcokBHlE5+DNP8A0HbWSzBZqGQdmK4cIqFwtIQWZwkKsqhthBLG3hoYzCchFil3EgQ9RwtqroIMAc8cFmnWGEFiootGv/gzxqH8WJhToAAAAASUVORK5CYII="/>*!/*/}
                                                                            {/*                /!*</svg>*!/*/}

                                                                            {/*                <svg xmlns="http://www.w3.org/2000/svg" id="account_circle-black-18dp" width="42" height="42" viewBox="0 0 100 100">*/}
                                                                            {/*                    <path id="Path_1" data-name="Path 1" d="M0,0H100V100H0Z" fill="none"/>*/}
                                                                            {/*                    <path opacity="0.1" id="Path_2" data-name="Path 2" d="M50,2A48,48,0,1,0,98,50,48.018,48.018,0,0,0,50,2Zm0,14.4A14.4,14.4,0,1,1,35.6,30.8,14.381,14.381,0,0,1,50,16.4Zm0,68.16A34.563,34.563,0,0,1,21.2,69.1C21.344,59.552,40.4,54.32,50,54.32c9.552,0,28.656,5.232,28.8,14.784A34.563,34.563,0,0,1,50,84.56Z" fill="#000"/>*/}
                                                                            {/*                </svg>*/}
                                                                            {/*            </div>*/}
                                                                            {/*            <img style={{*/}
                                                                            {/*                position: "absolute",*/}
                                                                            {/*                top: 0,*/}
                                                                            {/*                left: 0,*/}
                                                                            {/*                width: 42,*/}
                                                                            {/*                height: 42,*/}
                                                                            {/*                borderRadius: "50%",*/}
                                                                            {/*                opacity: 1*/}
                                                                            {/*            }}*/}
                                                                            {/*                 src={baseURL() + 'api/images/avatar/' + selectChat.employee_avatar}*/}
                                                                            {/*                 width="100"*/}
                                                                            {/*                 height="100"/>*/}
                                                                            {/*        </div>*/}
                                                                            {/*    ) : (*/}
                                                                            {/*        <div style={{*/}
                                                                            {/*            marginRight: 16,*/}
                                                                            {/*            verticalAlign: "top",*/}
                                                                            {/*            position: "relative",*/}
                                                                            {/*            display: "inline-block"*/}
                                                                            {/*        }}>*/}
                                                                            {/*            <div className="Student__photo1">*/}
                                                                            {/*                /!*<svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">*!/*/}
                                                                            {/*                /!*    <image id="baseline_account_circle_black_36dp" width="42" height="42" opacity="0.1" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAABtUlEQVR4Ae3WAWQCURjA8SGEEIZDGIZDCMMQxjAMIUAIAwwQBghhOBxggBCGw2GAACGEMAwhHA4hhBC+/RHort537y5Bf36Azuu9d+/dzZm61oSHEFMs9yYIMEADZ+0WPlYQpQh9VFBYJfSxhliK8Y7cOZhCCvKTZ7ZcRJCC/cKxmZkYorBAgBCRflD6mSpjBjHYoJuy33rYqZZPmQdRaONYPYiZeaPXsIUYTE1vpnL5YtPSDSEKPkyFEIU+UqtgC1HwYOpbf3im14IojWFqAVFqINEIksETjtWFZDBAopnFdZA2qDY2kAwCJIogFsbw4Oe4ZiZIJBe0vOCA9G9aDLmQKRLNM675J1qo426vgTb8jM8LkSiAnLCDj3toa2Boe9B2DK/4A2x7MXwBNJGoeuJHr8jbFyTFyuZCrCNvzzYXtXtklv7gwDYHS8iBNW5B2T9B5qgha3Us9J8eyaonHrDGG0owVcYHtifOnhJUuYYLcokBHlE5+DNP8A0HbWSzBZqGQdmK4cIqFwtIQWZwkKsqhthBLG3hoYzCchFil3EgQ9RwtqroIMAc8cFmnWGEFiootGv/gzxqH8WJhToAAAAASUVORK5CYII="/>*!/*/}
                                                                            {/*                /!*</svg>*!/*/}

                                                                            {/*                <svg xmlns="http://www.w3.org/2000/svg" id="account_circle-black-18dp" width="42" height="42" viewBox="0 0 100 100">*/}
                                                                            {/*                    <path id="Path_1" data-name="Path 1" d="M0,0H100V100H0Z" fill="none"/>*/}
                                                                            {/*                    <path opacity="0.1" id="Path_2" data-name="Path 2" d="M50,2A48,48,0,1,0,98,50,48.018,48.018,0,0,0,50,2Zm0,14.4A14.4,14.4,0,1,1,35.6,30.8,14.381,14.381,0,0,1,50,16.4Zm0,68.16A34.563,34.563,0,0,1,21.2,69.1C21.344,59.552,40.4,54.32,50,54.32c9.552,0,28.656,5.232,28.8,14.784A34.563,34.563,0,0,1,50,84.56Z" fill="#000"/>*/}
                                                                            {/*                </svg>*/}
                                                                            {/*            </div>*/}
                                                                            {/*        </div>*/}
                                                                            {/*    )*/}
                                                                            {/*) : null}*/}

                                                                            <div style={{
                                                                                display: "inline-block",
                                                                                verticalAlign: "top"
                                                                            }}>
                                                                                <div className="Info__title" style={{
                                                                                    display: "inline-block",
                                                                                    // opacity: 0.75,
                                                                                    fontSize: 15, fontWeight: 700,
                                                                                    marginRight: 16,
                                                                                    color: "#2A3E4C"}}>
                                                                                    {/*{chat_message.user_type === 'student' ? selectChat.student_fio.split(' ')[0] + ' ' + selectChat.student_fio.split(' ')[1] : null}*/}
                                                                                    {/*{chat_message.user_type === 'student' ? selectChat.student_fio : null}*/}
                                                                                    {/*{chat_message.user_type === 'employee' ? selectChat.employee_fio : null}*/}
                                                                                    {chat_message.user_type === 'student' ? 'Вы' : null}
                                                                                    {chat_message.user_type === 'teacher' ? 'Вы' : null}
                                                                                    {chat_message.user_type === 'administrator' ? 'Администратор' : null}
                                                                                </div>
                                                                                <br/>
                                                                                <div className="Title__date" style={{
                                                                                    display: "inline-block",
                                                                                    opacity: 0.75,
                                                                                    fontWeight: 400,
                                                                                    marginBottom: 6,
                                                                                    fontSize: 13,
                                                                                    color: "#2A3E4C"
                                                                                }}>
                                                                                    {lastDataTime(chat_message.time)}
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="Info__text" style={{
                                                                            marginBottom: 0,
                                                                            opacity: 1,
                                                                            fontFamily: "Roboto, sans-serif",
                                                                            fontSize: 15,
                                                                            color: "#2a3e4c"
                                                                        }}>
                                                                            {chat_message.message}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}

                                                        </Baron>
                                                    </div>



                                                    <div className="Chat__send">

                                                        <form onSubmit={this.handleSubmit.bind(this)}>
                                                            <textarea className="Field__input Input__chat"
                                                                   style={{
                                                                       display: "inline-block",
                                                                       backgroundColor: "#f7f7f7",
                                                                       border: "2px solid #dadada",
                                                                       width: 340 + 16,
                                                                       padding: "16px 16px",
                                                                       fontSize: 17,
                                                                       color: "#2A3E4C",
                                                                       fontFamily: "Roboto, sans-serif",
                                                                       fontWeight: 400,
                                                                       verticalAlign: "top",
                                                                       borderRadius: 7,
                                                                       marginRight: 24

                                                                   }}
                                                                   id="chatMessageText"
                                                                   name="chatMessageText"
                                                                   // type="text"
                                                                   placeholder="Текст сообщения..."
                                                                   value={this.state.value}
                                                                   onChange={this.handleInputChange.bind(this)}/>

                                                            <button style={{
                                                                display: "inline-block",
                                                                backgroundColor: "#f7f7f7",
                                                                border: "none",
                                                                // height: 24,
                                                                // width: 24,
                                                                // cursor: "pointer",
                                                                padding: 0,
                                                                marginBottom: 0,
                                                                verticalAlign: "top",
                                                                marginRight: 24
                                                            }}>
                                                                <div style={{
                                                                    display: "inline-block",
                                                                    // backgroundColor: "#f7f7f7",
                                                                    // border: "none",
                                                                    height: 24,
                                                                    width: 24,
                                                                    cursor: "pointer",
                                                                    padding: 16,
                                                                    verticalAlign: "top"
                                                                }}>
                                                                    {/*<svg xmlns="http://www.w3.org/2000/svg" width="23.993" height="24" viewBox="0 0 23.993 24">*/}
                                                                    {/*    <path id="paper-plane-solid" d="M22.318.121.6,12.653a1.126,1.126,0,0,0,.1,2.025l4.982,2.09L19.145,4.9a.281.281,0,0,1,.4.389L8.258,19.045v3.773a1.124,1.124,0,0,0,1.992.74l2.976-3.623,5.839,2.446a1.128,1.128,0,0,0,1.547-.853L23.986,1.283A1.125,1.125,0,0,0,22.318.121Z" transform="translate(-0.01 0.031)" fill="#0061ff"/>*/}
                                                                    {/*</svg>*/}

                                                                    <svg aria-hidden="true" focusable="false"
                                                                         data-prefix="fas" data-icon="paper-plane"
                                                                         className="svg-inline--fa fa-paper-plane fa-w-16"
                                                                         role="img" xmlns="http://www.w3.org/2000/svg"
                                                                         viewBox="0 0 512 512">
                                                                        <path fill="#0061ff"
                                                                              d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z"/>
                                                                    </svg>
                                                                </div>
                                                            </button>

                                                            <div style={{
                                                                display: "inline-block",
                                                                // backgroundColor: "#f7f7f7",
                                                                // border: "none",
                                                                height: 24,
                                                                width: 24,
                                                                cursor: "pointer",
                                                                padding: "14px 16px 18px",
                                                                verticalAlign: "top"
                                                            }}


                                                                 onClick={this.alertOpen.bind(this, 'alertStudentFeedbackDelete', alertData(null, null))}
                                                            >
                                                                <svg aria-hidden="true" focusable="false" data-prefix="fas"
                                                                     data-icon="trash"
                                                                     className="svg-inline--fa fa-trash fa-w-14" role="img"
                                                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                                    <path fill="#e53935"
                                                                          d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"/>
                                                                </svg>
                                                            </div>
                                                        </form>
                                                    </div>

                                                </React.Fragment>
                                            {/*) : null}*/}

                                        </div>



                                    </div>

                                </div>
                            {/*</div>*/}
                        </div>
                    </div>
                );
            }
        }

        else return null;
    }
}

function mapStateToProps(state) {
    return {
        isLoad: state.page.isLoad,
        isUpdate: state.page.isUpdate,
        responseData: state.api.responseData
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageMount: () => dispatch(pageMount()),
        pageLoad: (isLoad) => dispatch(pageLoad(isLoad)),
        pageUpdate: () => dispatch(pageUpdate()),
        apiUnmount: () => dispatch(apiUnmount()),
        notificationMessage: (message, colorMessage) => dispatch(notificationMessage(message, colorMessage)),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        alertOpen: (alertForm, alertData) => dispatch(alertOpen(alertForm, alertData)),
        modalOpen: (modalForm, modalData) => dispatch(modalOpen(modalForm, modalData))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (StudentFeedback);
