import {RECOVERY_REQUEST, RECOVERY_ERROR} from "../actions/actionTypes";

const initialState = {
    recoveryRequest: false,
    recoveryError: null
}

export default function recoveryReducer(state = initialState, action) {
    switch (action.type) {
        case RECOVERY_REQUEST:
            return {
                ...state, recoveryRequest: true, recoveryError: null
            }
        case RECOVERY_ERROR:
            return {
                ...state, recoveryRequest: false, recoveryError: action.recoveryError
            }
        default:
            return state;
    }
}
