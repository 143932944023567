import React from "react";
import {connect} from "react-redux";
import {pageLoad, pageUpdate, pageMount} from "../../../../../store/actions/pageAction";
import {apiRequest} from "../../../../../store/actions/apiAction";
import {modalOpen} from "../../../../../store/actions/modalAction";
import "./TeacherTrainingCourse.scss";
import Loading from "../../../../loading/Loading";
import {Link} from "react-router-dom";
import {alertOpen} from "../../../../../store/actions/alertAction";
import {notificationMessage} from "../../../../../store/actions/notificationAction";
import Baron from "../../../../baron/Baron";
import baseURL from "../../../../../library/baseURL";

class TeacherTrainingCourse extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isMount: true,
            isOpen: false,
            isLoad: false,
            isUpdate: false,
            pageError: null,
            trainingCourseData: null,

            typeWeekNumber: 0,

            calendarData: null,

            serverMonth: new Date().getMonth(), // Текущий период с сервера для контроля.
            serverYear: new Date().getFullYear(), // Текущий период с сервера для контроля.
            // serverDay: new Date().getDate(),


            calendarMonth: new Date().getMonth(), // месяцы в JS идут от 0 до 11, а не от 1 до 12
            calendarYear: new Date().getFullYear(),
            // calendarDay: new Date().getDate(),
            selectedDay: new Date().getDate(),
            selectedMonth: new Date().getMonth(),
            selectedYear: new Date().getFullYear(),
            // selectedCurrent: false,

            selectTypeHours: null,
            isTypeHours: false,


            isEditTimetables: false,
            selectChat: null,
            chatMessageText: null
        };
    }

    componentDidMount() {
        this.props.pageMount();
        this.pageUpdate(this.state.isLoad);
        this.intervalUpdate = setInterval(this.props.pageUpdate, 7200);
    }

    componentWillUnmount() {
        // console.log('Employees Unmounted');
        clearInterval(this.intervalUpdate);
        this.setState({
            isMount: false
        });
    }

    componentDidUpdate() {
        if (this.state.isMount) {
            const responseData = this.props.responseData;
            // console.log(1 + ' componentDidUpdate');
            if (this.props.isLoad !== this.state.isLoad) {
                // console.log(2 + ' isLoad');
                if (responseData !== this.state.trainingCourseData) {
                    // console.log(3 + ' responseData');

                    this.setState({
                        isOpen: true,
                        isLoad: this.props.isLoad,
                        isUpdate: this.props.isUpdate,
                        trainingCourseData: responseData,

                        selectTypeHours: null,
                        isTypeHours: false
                    }, () => {
                        if (this.state.selectChat) {
                            if (responseData.course_messages !== this.state.selectChat) {
                                this.setState({
                                    selectChat: responseData.course_messages
                                }, () => {
                                    let block = document.getElementById("chat");
                                    block.scrollTop = block.scrollHeight;
                                });
                            }
                        }
                    });
                }
            }

            else if (this.props.isUpdate !== this.state.isUpdate) {
                // console.log(4 + ' isUpdate');
                this.pageUpdate(this.state.isLoad);

                this.setState({
                    isUpdate: this.props.isUpdate
                });
            }
        }
    }

    pageUpdate(isLoad) {
        this.props.apiRequest('api/cabinet/teacher/training/course/' + this.props.match.params.trainingCourseId, null, () => {
            this.props.pageLoad(isLoad);
        }, () => {
            this.setState({
                pageError: this.props.responseError
            });
        });
    }

    modalOpen(modalForm, modalData) {
        this.props.modalOpen(modalForm, modalData);
    }



    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        if (this.state.chatMessageText && this.state.selectChat) {


            let formData = new FormData();

            formData.append('message', this.state.chatMessageText);



            this.setState({
                isHandleSubmit: true
            });

            this.props.apiRequest('api/cabinet/training/course/' + this.props.match.params.trainingCourseId + '/message/add', formData, () => {

                    this.setState({
                        isHandleSubmit: false
                    });


                    this.props.pageUpdate();
                    this.setStateAndInput('chatMessageText', null);

                }, () => {
                    this.props.notificationMessage(this.props.responseError, 'red');
                    this.setState({
                        isHandleSubmit: false
                    });
                }
            );

        }

        else {

            this.props.notificationMessage('Пожалуйста, внимательно заполните поле отправки сообщения.', 'red');
        }
    }

    setStateAndInput(name, value) {

        let input = document.getElementById(name);
        input.value = value;

        this.setState({
            [name]: value
        });
    }


    firstDayOfMonth(date) {
        // Получить номер дня недели, от 0 (пн) до 6 (вс).
        let day = new Date(date).getDay();
        // Сделать воскресенье (0) последним днем.
        if (day === 0) day = 7;
        return day - 1;
    }

    dayIsToday() {
        return new Date().getDate();
    }

    monthIsToday() {
        return new Date().getMonth();
    }

    yearIsToday() {
        return new Date().getFullYear();
    }

    clickDay(month, day, year) {
        this.setState({
            selectedMonth: month,
            selectedDay: day,
            selectedYear: year,

            selectTypeHours: null,
            isTypeHours: false
        });
    }

    monthPlus() {
        // console.log(this.state.calendarMonth);
        if (this.state.calendarMonth < 11) {

            this.setState({
                calendarMonth: this.state.calendarMonth + 1,

                selectTypeHours: null,
                isTypeHours: false
            });
        }

        else {
            this.setState({
                calendarMonth: 0,
                calendarYear: this.state.calendarYear + 1,

                selectTypeHours: null,
                isTypeHours: false
            });
        }
    }

    monthMines() {
        // console.log(this.state.calendarMonth);
        if (this.state.calendarMonth > 0) {

            this.setState({
                calendarMonth: this.state.calendarMonth - 1,

                selectTypeHours: null,
                isTypeHours: false
            });
        }

        else {
            this.setState({
                calendarMonth: 11,
                calendarYear: this.state.calendarYear - 1,

                selectTypeHours: null,
                isTypeHours: false
            });
        }
    }

    // selectedCurrent(selectedCurrent) {
    //     this.setState({
    //         selectedCurrent: !selectedCurrent
    //     })
    // }


    currentWeekYear(month, day, year) {
        // Как идет отсчет недели в учебном графике!
        // Какая неделя сейчас!
        let firstSeptemberSunday = false;
        let firstWeekSeptember = 0;
        let currentWeekYear = 0;
        let lastYearWeek = 52;

        if (Number(month) > 7) {
            if (new Date(Number(year), 8, 1).getDay() === 0) {

                firstSeptemberSunday = true;
            }

            if (firstSeptemberSunday) {

                firstWeekSeptember = new Date(Number(year), 8, 2).getWeek();
            }

            else {

                firstWeekSeptember = new Date(Number(year), 8, 1).getWeek();
            }

            if (new Date(Number(year), Number(month), Number(day)).getWeek() === 1) {
                currentWeekYear = 53 - firstWeekSeptember + 1;
            }

            else {
                currentWeekYear = new Date(Number(year), Number(month), Number(day)).getWeek() - firstWeekSeptember + 1;
            }
        }

        else {

            if (new Date(Number(year) - 1, 8, 1).getDay() === 0) {
                firstSeptemberSunday = true;
            }

            if (firstSeptemberSunday) {

                firstWeekSeptember = new Date(Number(year) - 1, 8, 2).getWeek();
            }

            else {

                firstWeekSeptember = new Date(Number(year) - 1, 8, 1).getWeek();
            }

            if ((new Date(Number(year) - 1, 11, 31).getWeek() === 53) || (new Date(Number(year), 0, 1).getWeek() === 53)) {
                lastYearWeek = 53;
            }

            if (new Date(Number(year), Number(month), Number(day)).getWeek() === 53) {
                if (firstSeptemberSunday) {

                    currentWeekYear = lastYearWeek - firstWeekSeptember + 1;
                }

                else {

                    currentWeekYear = lastYearWeek - firstWeekSeptember + 1;
                }
            }

            else if (new Date(Number(year), Number(month), Number(day)).getWeek() === 52) {
                if (firstSeptemberSunday) {

                    currentWeekYear = lastYearWeek - firstWeekSeptember + 1;
                }

                else {

                    currentWeekYear = lastYearWeek - firstWeekSeptember + 1;
                }
            }

            else if (new Date(Number(year), Number(month), Number(day)).getWeek() === 1) {



                if (firstSeptemberSunday) {

                    currentWeekYear = lastYearWeek - firstWeekSeptember + 2;
                }

                else {

                    currentWeekYear = lastYearWeek - firstWeekSeptember + 2;
                }
            }

            else {

                if (firstSeptemberSunday) {

                    currentWeekYear = lastYearWeek - new Date(Number(year) - 1, 8, 2).getWeek() + new Date(Number(year), Number(month), Number(day)).getWeek() + 1;
                }

                else {

                    currentWeekYear = lastYearWeek - new Date(Number(year) - 1, 8, 1).getWeek() + new Date(Number(year), Number(month), Number(day)).getWeek() + 1;
                }
            }

            if (currentWeekYear === 53) {

                if (new Date(Number(year), 8, 1).getDay() > 0) {
                    currentWeekYear = 1;
                }
            }
        }

        return currentWeekYear;
    }

    isEditTimetables(isEditTimetables) {

        // const timetablesData = this.state.trainingScheduleData.schedule_timetables;

        this.setState({
                // isEditTypeWeeks: false,
                // typeWeeksData: [],
                // typeWeekNumber: 1,
                // isSaveTypeWeeks: false,
                isEditTimetables: !isEditTimetables,
                // timetablesData: (!isEditTimetables ? timetablesData : []),
                isSaveTimetables: false
            },
            () => {
                this.props.pageUpdate();
            });
    }

    trainingScheduleTimetableEdit() {
        const timetablesData = this.state.timetablesData;

        if (timetablesData.length > 0) {

            let formData = new FormData();

            let jsonTimetablesData = JSON.stringify(timetablesData);

            formData.append('schedule_timetables', jsonTimetablesData);

            if (!this.state.isSaveTimetables) {

                this.setState({
                    isSaveTimetables: true
                });

                this.props.apiRequest('api/cabinet/training/schedule/' + this.props.match.params.trainingScheduleId + '/timetables/edit', formData, () => {
                        this.props.notificationMessage(this.props.responseMessage, 'green');
                        this.props.pageUpdate();
                        this.setState({
                            isEditTimetables: false,
                            timetablesData: [],
                            isSaveTimetables: false
                        });
                    }, () => {
                        this.setState({
                            isSaveTimetables: false
                        });
                        this.props.notificationMessage(this.props.responseError, 'red');
                    }
                );
            }

            else {
                this.props.notificationMessage('Изменения по расписанию уже сохраняются.', 'red');
            }
        }

        else {
            this.props.notificationMessage('Нет данных по расписанию для изменений.', 'red');
        }

    }

    typeWeekNumber(number) {
        this.setState({
            typeWeekNumber: number
        });
    }

    linkOpen(link) {
        window.open(link, '_blank');
    }


    isTypeHours(isTypeHours) {


        this.setState({
                isTypeHours: !isTypeHours,
                selectTypeHours: null
            },
            () => {
                this.props.pageUpdate();
            });
    }

    selectTypeHours(selectTypeHours) {
        let stateSelectTypeHours = this.state.selectTypeHours;
        let trainingCourseData = this.state.trainingCourseData;

        let selectedDay = this.state.selectedDay;
        let selectedMonth = this.state.selectedMonth;
        let selectedYear = this.state.selectedYear;

        let maxLecture = 2;
        let maxLaboratory = 4;
        let maxPractical = 4;
        let maxDayHours = 0;

        let hoursLecture = 0;
        let hoursLaboratory = 0;
        let hoursPractical = 0;
        let dayHours = 0;

        if (Number(trainingCourseData.training_schedule.schedule_period["type_week_" + this.currentWeekYear(this.state.selectedMonth, this.state.selectedDay, this.state.selectedYear)]) > 0) {


            trainingCourseData.training_schedule.schedule_timetable.map(timetable => {
                if (Number(timetable[this.weekDayNameNumber(this.state.selectedMonth, this.state.selectedDay, this.state.selectedYear)]) > 0) {
                    maxDayHours = Number(timetable.max_hours_day);
                }
            });
        }


        trainingCourseData.course_schedule.map(course_schedule => {
            if ((Number(selectedDay) === Number(course_schedule.day_lesson)) &&
                ((Number(selectedMonth) + 1) === Number(course_schedule.month_lesson)) &&
                (Number(selectedYear) === Number(course_schedule.year_lesson))
            ) {
                if (course_schedule.lesson_type === 'lecture') {
                    if (Number(course_schedule.double_hours) === 1) {
                        hoursLecture = hoursLecture + 2;
                        dayHours = dayHours + 2;
                    } else {
                        hoursLecture = hoursLecture + 1;
                        dayHours = dayHours + 1;
                    }
                } else if (course_schedule.lesson_type === 'laboratory') {
                    if (Number(course_schedule.double_hours) === 1) {
                        hoursLaboratory = hoursLaboratory + 2;
                        dayHours = dayHours + 2;
                    } else {
                        hoursLaboratory = hoursLaboratory + 1;
                        dayHours = dayHours + 1;
                    }
                } else if (course_schedule.lesson_type === 'practical') {
                    if (Number(course_schedule.double_hours) === 1) {
                        hoursPractical = hoursPractical + 2;
                        dayHours = dayHours + 2;
                    } else {
                        hoursPractical = hoursPractical + 1;
                        dayHours = dayHours + 1;
                    }
                }
            }
        });

        trainingCourseData.group_schedule_other.map(course_schedule => {
            if ((Number(selectedDay) === Number(course_schedule.day_lesson)) &&
                ((Number(selectedMonth) + 1) === Number(course_schedule.month_lesson)) &&
                (Number(selectedYear) === Number(course_schedule.year_lesson))
            ) {
                if (Number(course_schedule.double_hours) === 1) {
                    dayHours = dayHours + 2;
                } else {
                    dayHours = dayHours + 1;
                }
            }
        });

        trainingCourseData.teacher_schedule.map(course_schedule => {
            if ((Number(selectedDay) === Number(course_schedule.day_lesson)) &&
                ((Number(selectedMonth) + 1) === Number(course_schedule.month_lesson)) &&
                (Number(selectedYear) === Number(course_schedule.year_lesson))
            ) {
                if (Number(course_schedule.double_hours) === 1) {
                    dayHours = dayHours + 2;
                } else {
                    dayHours = dayHours + 1;
                }
            }
        });








     if (dayHours >= maxDayHours) {
            this.props.notificationMessage('В день не более ' + maxDayHours + ' часов обучения.', 'red');
            this.setState({
                selectTypeHours: null,
                isTypeHours: false
            });
        }

        else if ((selectTypeHours === 'lecture') && (hoursLecture >= maxLecture)) {
                this.props.notificationMessage('В день не более 2 часов теоретических занятий по дисциплине.', 'red');
                this.setState({
                    selectTypeHours: null,
                    isTypeHours: false
                });
            }

            else if ((selectTypeHours === 'laboratory') && (hoursLaboratory >= maxLaboratory)) {
                this.props.notificationMessage('В день не более 4 часов лабораторных занятий по дисциплине.', 'red');
                this.setState({
                    selectTypeHours: null,
                    isTypeHours: false
                });
            }

            else if ((selectTypeHours === 'practical') && (hoursPractical >= maxPractical)) {
                this.props.notificationMessage('В день не более 4 часов практических занятий по дисциплине.', 'red');
                this.setState({
                    selectTypeHours: null,
                    isTypeHours: false
                });
            }



            else {

                if (stateSelectTypeHours === selectTypeHours) {
                    this.setState({
                        selectTypeHours: null,
                        isTypeHours: false
                    });
                }

                else {
                    this.setState({
                        selectTypeHours: selectTypeHours,
                        isTypeHours: true
                    });
                }
            }

    }


    selectTypeHoursExam() {
        let stateSelectTypeHours = this.state.selectTypeHours;
        let trainingCourseData = this.state.trainingCourseData;

        let selectedDay = this.state.selectedDay;
        let selectedMonth = this.state.selectedMonth;
        let selectedYear = this.state.selectedYear;

        let maxLecture = 2;
        let maxLaboratory = 4;
        let maxPractical = 4;
        let maxDayHours = 0;

        let hoursLecture = 0;
        let hoursLaboratory = 0;
        let hoursPractical = 0;
        let dayHours = 0;

        this.setState({
            selectTypeHours: 'exam',
            isTypeHours: true
        });
    }

   weekDayNameNumber(month, day, year) {

        let weekNumber = new Date(Number(year), Number(month), Number(day)).getDay();

        switch (weekNumber) {
            case 1: return "week_day_mon";
            case 2: return "week_day_tue";
            case 3: return "week_day_wed";
            case 4: return "week_day_thu";
            case 5: return "week_day_fri";
            case 6: return "week_day_sat";
            case 0: return "week_day_sun";
            default: return null;
        }
    }

    alertOpen(alertForm, alertData) {
        this.props.alertOpen(alertForm, alertData);
    }

    selectChat() {
        let courseMessages = this.state.trainingCourseData.course_messages;

        this.setState({
            selectChat: courseMessages
        }, () => {
            let block = document.getElementById("chat");
            block.scrollTop = block.scrollHeight;
        });
    }

    selectChatNull() {

        this.setState({
            selectChat: null
        });
    }


    trainingCourseYes() {
        this.modalOpen('asdasd', {
            objectId: this.props.match.params.trainingCourseId,
            objectData: null
        })
    }

    trainingCourseNot() {
        this.props.notificationMessage('Закройте все учебные часы.', 'red');
    }

    render() {
        const { isMount, isOpen, pageError, trainingCourseData,

            calendarData, selectedCurrent, isEditTimetables, typeWeekNumber, isTypeHours, selectTypeHours,
            selectedMonth, selectedDay, selectedYear, selectChat

        } = this.state;

        let modalData = (objectId, objectData) => {
            return {
                objectId: objectId,
                objectData: objectData
            };
        }

        let alertData = (objectId, objectData) => {
            return {
                objectId: objectId,
                objectData: objectData
            };
        }

        let lastDataTime = (time) => {

            if (Number(time) > 0) {

                let date = new Date(time * 1000);
                let day = "0" + date.getDate();
                let month = "0" + (date.getMonth() + 1);
                let year = String(date.getFullYear());
                let hours = "0" + date.getHours();
                let minutes = "0" + date.getMinutes();
                let seconds = "0" + date.getSeconds();
                let formattedData = day.substr(-2) + '.' + month.substr(-2) + '.' + year.substr(-2);
                // let formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
                let formattedTime = hours.substr(-2) + ':' + minutes.substr(-2);

                return formattedTime + ' ' + formattedData;
            }

            else {

                return null;
            }
        }

        if (isMount) {

            if (pageError) {

                if (!isOpen) {

                    return (
                        <div className="Page__loader">
                            <i/>
                            <div className="Body_nodata">
                                Страница не найдена
                                <div style={{textAlign: 'center', marginTop: 48, fontSize: 32}}>
                                    ERROR 404
                                </div>
                            </div>
                            <i/>
                        </div>
                    );
                }

                else {

                    return (
                        <div className="Page__loader">
                            <i/>
                            <div>
                                <div className="Body_nodata">Курс архивирован / удален</div>
                                <div className="Header__path" style={{textAlign: 'center', marginTop: 48}}>
                                    <Link to={"/cabinet/teacher/training/courses"}>
                                        <div className="Path__link Path__link_text Path__link_blue">Учебные курсы</div>
                                    </Link>
                                </div>
                            </div>
                            <i/>
                        </div>
                    );
                }
            }

            else {

                if (!isOpen || !trainingCourseData) {
                    return (<div className="Page__loader"><i/><Loading/><i/></div>);
                }

                else {





        // let projectsData = calendarData.project;
        // let eventsData = calendarData.event;
        // let problemsData = calendarData.problem;

        let projectsData = [];
        let eventsData = [];
        let problemsData = [];

        let timeYear = (time) => {
            const dateMonthYear = new Date(time * 1000);

            let yyyy = dateMonthYear.getFullYear();
            if (yyyy < 10) yyyy = '0' + yyyy;

            return yyyy;
        }

        let timeDay = (time) => {
            const dateMonthYear = new Date(time * 1000);

            let dd = dateMonthYear.getDate();
            if (dd < 10) dd = '0' + dd;

            return dd;
        }

        let timeMonth = (time) => {
            const dateMonthYear = new Date(time * 1000);

            return dateMonthYear.toLocaleString('ru', {month: 'long'});
        }

        let weekDay = (time) => {
            const dateMonthYear = new Date(time * 1000);

            // let weekDays = ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'];
            let weekDays = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
            let weekDay = dateMonthYear.getDay();

            return weekDays[weekDay];
        }

        // Переменные выбранного дня на календаре.
        let dateTimeEnd = Number(new Date(this.state.selectedYear + '-' + (this.state.selectedMonth + 1) + '-' + this.state.selectedDay).setHours(0, 0, 0, 0) / 1000 + 86399);
        let dateTimeStart = Number(dateTimeEnd) - 86399;

        // Переменная даты построения календаря.
        let date = new Date(this.state.calendarYear, this.state.calendarMonth);

        // Пустая область в начале календаря.
        let blanksFirst = [];
        for (let i = 0; i < this.firstDayOfMonth(date); i++) {
            blanksFirst.push(<td className="Calendar__td" key={'td' + i}>
                <div className="Td__text Td__text_default"/>
            </td>);
        }

        let weekDayNameNumber = (month, day, year) => {

            let weekNumber = new Date(Number(year), Number(month), Number(day)).getDay()

            switch (weekNumber) {
                case 1: return "week_day_mon";
                case 2: return "week_day_tue";
                case 3: return "week_day_wed";
                case 4: return "week_day_thu";
                case 5: return "week_day_fri";
                case 6: return "week_day_sat";
                case 0: return "week_day_sun";
                default: return null;
            }
        }

        let weekDayDefault = true;

        // Переменные маркеров.
        let markerRed = false;
        let markerYellow = false;
        let markerGray = false;

                    let markerLecture = false;
                    let markerLaboratory = false;
                    let markerPractical = false;
                    let markerExam = false;

        // Календарные дни.
        let daysInMonth = [];
        while (date.getMonth() === this.state.calendarMonth) {
            let today = (date.getDate() === this.dayIsToday() && date.getMonth() === this.monthIsToday() && date.getFullYear() === this.yearIsToday() ? "today" : null);
            let selected = (date.getDate() === this.state.selectedDay && date.getMonth() === this.state.selectedMonth && date.getFullYear() === this.state.selectedYear ? "selected" : null);

            daysInMonth.push(
                <td className={'Calendar__td ' + selected} key={date.getDate()}>

                    {trainingCourseData.training_schedule.schedule_timetable.map(timetable => (
                        Number(timetable[weekDayNameNumber(date.getMonth(), date.getDate(), date.getFullYear())]) > 0 ? weekDayDefault = false : null
                    ))}

                        <div className={
                            Number(trainingCourseData.training_schedule.schedule_period["type_week_" + this.currentWeekYear(date.getMonth(), date.getDate(), date.getFullYear())]) > 0 ?
                                !weekDayDefault ? 'Td__text ' + today : 'Td__text Td__text_default Td__text_pointer ' + today
                                : 'Td__text Td__text_default Td__text_pointer ' + today
                        }
                             onClick={
                                 // Number(trainingCourseData.training_schedule.schedule_period["type_week_" + this.currentWeekYear(date.getMonth(), date.getDate(), date.getFullYear())]) > 0 ?
                                 //     !weekDayDefault ? this.clickDay.bind(this, date.getMonth(), date.getDate(), date.getFullYear()) : null
                                 //     : null

                                 this.clickDay.bind(this, date.getMonth(), date.getDate(), date.getFullYear())
                             }>
                            <div className="Text__day">
                                {date.getDate()}
                            </div>
                            {this.currentWeekYear(date.getMonth(), date.getDate(), date.getFullYear()) !== 0 ? (
                                this.currentWeekYear(date.getMonth(), date.getDate(), date.getFullYear()) !== 53 ? (
                                    <div className="Text__week">
                                        {this.currentWeekYear(date.getMonth(), date.getDate(), date.getFullYear())}
                                    </div>) : null
                            ) : null}


                            {/*<div className="Text__week" style={{bottom: 0}}>*/}
                            {/*    {date.getWeek()}*/}
                            {/*</div>*/}



                            {markerLecture = false}
                            {markerLaboratory = false}
                            {markerPractical = false}
                            {markerExam = false}

                            {trainingCourseData.course_schedule.map(course_schedule => (
                                ((date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear() + '/lecture') === (course_schedule.day_lesson + '.' + course_schedule.month_lesson + '.' + course_schedule.year_lesson + '/' + course_schedule.lesson_type)) ? (
                                    markerLecture = true
                                ) : null
                            ))}

                            {trainingCourseData.course_schedule.map(course_schedule => (
                                ((date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear() + '/laboratory') === (course_schedule.day_lesson + '.' + course_schedule.month_lesson + '.' + course_schedule.year_lesson + '/' + course_schedule.lesson_type)) ? (
                                    markerLaboratory = true
                                ) : null
                            ))}

                            {trainingCourseData.course_schedule.map(course_schedule => (
                                ((date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear() + '/practical') === (course_schedule.day_lesson + '.' + course_schedule.month_lesson + '.' + course_schedule.year_lesson + '/' + course_schedule.lesson_type)) ? (
                                    markerPractical = true
                                ) : null
                            ))}

                            {trainingCourseData.course_schedule.map(course_schedule => (
                                ((date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear() + '/exam') === (course_schedule.day_lesson + '.' + course_schedule.month_lesson + '.' + course_schedule.year_lesson + '/' + course_schedule.lesson_type)) ? (
                                    markerExam = true
                                ) : null
                            ))}

                            <div className="Box__markers">
                                {markerLecture ? (<div className={'Box__marker Box__marker_yellow'}/>) : null}
                                {markerLaboratory ? (<div className={'Box__marker Box__marker_green'}/>) : null}
                                {markerPractical ? (<div className={'Box__marker Box__marker_purple'}/>) : null}
                                {markerExam ? (<div className={'Box__marker Box__marker_exam'}/>) : null}
                            </div>


                        </div>
                    {!weekDayDefault ?  weekDayDefault = true : null}

                    {/*{calendarData.length > 0 ? (*/}
                    {/*    calendarData.map((event, index) => (*/}
                    {/*         Number(new Date(this.state.calendarYear + '-' + (this.state.calendarMonth + 1) + '-' + date.getDate()).setHours(0, 0, 0, 0) / 1000) <= event.events_date_start ? (*/}
                    {/*            event.events_date_start <= Number(new Date(this.state.calendarYear + '-' + (this.state.calendarMonth + 1) + '-' + date.getDate()).setHours(0, 0, 0, 0) / 1000 + 86399) ? (*/}
                    {/*                <div key={index + 'key'} className={'Box__marker Box__marker_red'}/>*/}
                    {/*            ) : null*/}
                    {/*        ) : null*/}
                    {/*    ))) : null*/}
                    {/*}*/}

                    {markerRed = false}
                    {markerYellow = false}
                    {markerGray = false}

                    {projectsData.map(project => (
                        Number(new Date(this.state.calendarYear + '-' + (this.state.calendarMonth + 1) + '-' + date.getDate()).setHours(0, 0, 0, 0) / 1000) <= project.project_date_start ? (
                            project.project_date_start <= Number(new Date(this.state.calendarYear + '-' + (this.state.calendarMonth + 1) + '-' + date.getDate()).setHours(0, 0, 0, 0) / 1000 + 86399) ? (
                                markerRed = true
                            ) : null
                        ) : null
                    ))}

                    {eventsData.map(event => (
                        Number(new Date(this.state.calendarYear + '-' + (this.state.calendarMonth + 1) + '-' + date.getDate()).setHours(0, 0, 0, 0) / 1000) <= event.events_date_start ? (
                            event.events_date_start <= Number(new Date(this.state.calendarYear + '-' + (this.state.calendarMonth + 1) + '-' + date.getDate()).setHours(0, 0, 0, 0) / 1000 + 86399) ? (
                                markerRed = true
                            ) : null
                        ) : null
                    ))}

                    {problemsData.map(problem => (
                        Number(new Date(this.state.calendarYear + '-' + (this.state.calendarMonth + 1) + '-' + date.getDate()).setHours(0, 0, 0, 0) / 1000) <= problem.problem_date ? (
                            problem.problem_date <= Number(new Date(this.state.calendarYear + '-' + (this.state.calendarMonth + 1) + '-' + date.getDate()).setHours(0, 0, 0, 0) / 1000 + 86399) ? (
                                markerYellow = true
                            ) : null
                        ) : null
                    ))}

                    {/*{selectedCurrent ? (*/}
                    {/*    projectsData.map(project => (*/}
                    {/*        Number(new Date(this.state.calendarYear + '-' + (this.state.calendarMonth + 1) + '-' + date.getDate()).setHours(0, 0, 0, 0) / 1000) > project.project_date_start ? (*/}
                    {/*            project.project_date_end >= Number(new Date(this.state.calendarYear + '-' + (this.state.calendarMonth + 1) + '-' + date.getDate()).setHours(0, 0, 0, 0) / 1000) ? (*/}
                    {/*                markerGray = true*/}
                    {/*            ) : null*/}
                    {/*        ) : null*/}
                    {/*    )),*/}
                    {/*        eventsData.map(event => (*/}
                    {/*            Number(new Date(this.state.calendarYear + '-' + (this.state.calendarMonth + 1) + '-' + date.getDate()).setHours(0, 0, 0, 0) / 1000) > event.events_date_start ? (*/}
                    {/*                event.events_date_end >= Number(new Date(this.state.calendarYear + '-' + (this.state.calendarMonth + 1) + '-' + date.getDate()).setHours(0, 0, 0, 0) / 1000) ? (*/}
                    {/*                    markerGray = true*/}
                    {/*                ) : null*/}
                    {/*            ) : null*/}
                    {/*        ))*/}
                    {/*) : null}*/}

                    {markerRed ? (<div className={'Box__marker Box__marker_red'}/>) : null}
                    {markerRed ? null : (markerYellow ? <div className={'Box__marker Box__marker_yellow'}/> : null)}
                    {markerRed ? null : (markerYellow ? null : (markerGray ? <div className={'Box__marker Box__marker_gray'}/> : null))}




                </td>
            );
            date.setDate(date.getDate() + 1);
        }

        // Пустая область в конце календаря.
        let blankSecond = [];
        if (this.firstDayOfMonth(date) !== 0) {
            for (let i = this.firstDayOfMonth(date); i < 7; i++) {
                blankSecond.push(<td className="Calendar__td" key={i + 'td'}>
                    <div className="Td__text Td__text_default"/>
                </td>);
            }
        }

        // Формирование массива календарных дней.
        var totalSlots = [...blanksFirst, ...daysInMonth, ...blankSecond];
        let rows = [];
        let cells = [];

        // Построение календаря.
        totalSlots.forEach((row, i) => {
            if (i % 7 !== 0) {
                cells.push(row); // if index not equal 7 that means not go to next week
            } else {
                rows.push(cells); // when reach next week we contain all td in last week to rows
                cells = []; // empty container
                cells.push(row); // in current loop we still push current row to new container
            }

            if (i === totalSlots.length - 1) { // when end loop we add remain date
                rows.push(cells);
            }
        });

        let daysInMonthTable = rows.map((day, i) => {
            return (<tr className="Calendar__tr" key={'tr' + i}>{day}</tr>);
        });

        // Переменная для отображения мероприятий если есть.
        let daysArraySelected = false;
        let eventsDaysArraySelected = false;
        let projectsDaysArraySelected = false;
        let problemsDaysArraySelected = false;






                   let scheduleTimetable = null;




                    let timetableData = (month, day, year, type, hour, double) => {
                        return {
                            month: month,
                            day: day,
                            year: year,
                            type: type,
                            hour: hour,
                            double: double
                        };
                    }

                    let groupName = (groupName, initialYear) => {
                        let course;

                        let date = new Date();
                        course = (date.getFullYear() - Number(initialYear) + 1);

                        if (groupName.search('\$') > 0) {
                            return groupName.replace(/\$/i, course);
                        }

                        else {
                            return groupName;
                        }
                    }


                    let lectureHours = 0;
                    let laboratoryHours = 0;
                    let practicalHours = 0;
                    let examHours = 0;

                    trainingCourseData.course_schedule.map((course_schedule, index) => {

                            if (course_schedule.lesson_type === 'lecture') {
                                if (Number(course_schedule.double_hours) === 1) {
                                    lectureHours = lectureHours + 2
                                }

                                else {
                                    lectureHours = lectureHours + 1
                                }
                            }

                            else if (course_schedule.lesson_type === 'laboratory') {
                                if (Number(course_schedule.double_hours) === 1) {
                                    laboratoryHours = laboratoryHours + 2
                                }

                                else {
                                    laboratoryHours = laboratoryHours + 1
                                }
                            }

                            else if (course_schedule.lesson_type === 'practical') {
                                if (Number(course_schedule.double_hours) === 1) {
                                    practicalHours = practicalHours + 2
                                }

                                else {
                                    practicalHours = practicalHours + 1
                                }
                            }

                            else if (course_schedule.lesson_type === 'exam') {
                                if (Number(course_schedule.double_hours) === 1) {
                                    examHours = examHours + 2
                                }

                                else {
                                    examHours = examHours + 1
                                }
                            }

                    });





                    let hoursSelectDay = 0;
                    let hoursMaxSelectDay = 0;

                    let timeButton = (timeButton, isEdit, typeHour, onClick) => {

                        let isLecture = false;
                        let isLaboratory = false;
                        let isPractical = false;
                        let isExam = false;
                        let isExamSelect = false;
                        let isGroupSelect = false;
                        let isTeacherSelect = false;

                        trainingCourseData.course_schedule.map(course_schedule => {
                            if ((timeButton.hour === Number(course_schedule.starting_hour)) &&
                                (timeButton.day === Number(course_schedule.day_lesson)) &&
                                ((timeButton.month + 1) === Number(course_schedule.month_lesson)) &&
                                (timeButton.year === Number(course_schedule.year_lesson))
                            ) {
                                if (course_schedule.lesson_type === 'lecture') {
                                    isLecture = true;
                                }

                                else if (course_schedule.lesson_type === 'laboratory') {
                                    isLaboratory = true;
                                }

                                else if (course_schedule.lesson_type === 'practical') {
                                    isPractical = true;
                                }

                                else if (course_schedule.lesson_type === 'exam') {
                                    isExam = true;
                                }
                            }
                        });

                        trainingCourseData.course_schedule.map(course_schedule => {
                            if ((timeButton.day === Number(course_schedule.day_lesson)) &&
                                ((timeButton.month + 1) === Number(course_schedule.month_lesson)) &&
                                (timeButton.year === Number(course_schedule.year_lesson))
                            ) {
                                if (course_schedule.lesson_type === 'exam') {
                                    isExamSelect = true;
                                }
                            }
                        });

                        trainingCourseData.group_schedule_other.map(course_schedule => {
                            if ((timeButton.hour === Number(course_schedule.starting_hour)) &&
                                (timeButton.day === Number(course_schedule.day_lesson)) &&
                                ((timeButton.month + 1) === Number(course_schedule.month_lesson)) &&
                                (timeButton.year === Number(course_schedule.year_lesson))
                            ) {
                                isGroupSelect = true;
                            }

                            if ((timeButton.day === Number(course_schedule.day_lesson)) &&
                                ((timeButton.month + 1) === Number(course_schedule.month_lesson)) &&
                                (timeButton.year === Number(course_schedule.year_lesson))
                            ) {
                                if (course_schedule.lesson_type === 'exam') {
                                    isGroupSelect = true;
                                }
                            }
                        });

                        trainingCourseData.teacher_schedule.map(course_schedule => {
                            if ((timeButton.hour === Number(course_schedule.starting_hour)) &&
                                (timeButton.day === Number(course_schedule.day_lesson)) &&
                                ((timeButton.month + 1) === Number(course_schedule.month_lesson)) &&
                                (timeButton.year === Number(course_schedule.year_lesson))
                            ) {
                                isTeacherSelect = true;
                            }

                            if ((timeButton.day === Number(course_schedule.day_lesson)) &&
                                ((timeButton.month + 1) === Number(course_schedule.month_lesson)) &&
                                (timeButton.year === Number(course_schedule.year_lesson))
                            ) {
                                if (course_schedule.lesson_type === 'exam') {
                                    isTeacherSelect = true;
                                }
                            }
                        });

                        trainingCourseData.teacher_schedule_other.map(course_schedule => {
                            if ((timeButton.hour === Number(course_schedule.starting_hour)) &&
                                (timeButton.day === Number(course_schedule.day_lesson)) &&
                                ((timeButton.month + 1) === Number(course_schedule.month_lesson)) &&
                                (timeButton.year === Number(course_schedule.year_lesson))
                            ) {
                                isTeacherSelect = true;
                            }

                            if ((timeButton.day === Number(course_schedule.day_lesson)) &&
                                ((timeButton.month + 1) === Number(course_schedule.month_lesson)) &&
                                (timeButton.year === Number(course_schedule.year_lesson))
                            ) {
                                if (course_schedule.lesson_type === 'exam') {
                                    isTeacherSelect = true;
                                }
                            }
                        });


                        if (isTeacherSelect) {
                            if (Number(timeButton.double) === 1) {
                                return (
                                    <li style={{marginBottom: 36}}>
                                        <div className="Box__timetable">
                                            <div className="Timetable__text" style={{
                                                backgroundColor: "#0061ff",
                                                // opacity: .5
                                            }}>
                                                <div
                                                    className="Text__time" style={{color: "white"}}>{timeButton.hour < 10 ? '0' + timeButton.hour : timeButton.hour}:00
                                                </div>
                                                <div className="Item__hr Item__hr_vertical Item__hr_white Item__hr_inline"/>
                                                <div
                                                    className="Text__time" style={{color: "white"}}>{timeButton.hour < 8 ? ('0' + (timeButton.hour + 1)) : (timeButton.hour + 1)}:00
                                                </div>
                                            </div>
                                        </div>
                                        <div className="Box__timetable">
                                            <div className="Timetable__text" style={{
                                                backgroundColor: "#0061ff",
                                                // opacity: .5
                                            }}>
                                                <div
                                                    className="Text__time" style={{color: "white"}}>{timeButton.hour < 9 ? '0' + (timeButton.hour + 1) : (timeButton.hour + 1)}:00
                                                </div>
                                                <div className="Item__hr Item__hr_vertical Item__hr_white Item__hr_inline"/>
                                                <div
                                                    className="Text__time" style={{color: "white"}}>{timeButton.hour < 8 ? ('0' + (timeButton.hour + 2)) : (timeButton.hour + 2)}:00
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                );
                            }

                            else {
                                return (
                                    <li>
                                        <div className="Box__timetable">
                                            <div className="Timetable__text" style={{
                                                backgroundColor: "#0061ff",
                                                // opacity: .5
                                            }}>
                                                <div
                                                    className="Text__time" style={{color: "white"}}>{timeButton.hour < 10 ? '0' + timeButton.hour : timeButton.hour}:00
                                                </div>
                                                <div className="Item__hr Item__hr_vertical Item__hr_white Item__hr_inline"/>
                                                <div
                                                    className="Text__time" style={{color: "white"}}>{timeButton.hour < 8 ? ('0' + (timeButton.hour + 1)) : (timeButton.hour + 1)}:00
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                );
                            }
                        }

                        else if (isGroupSelect) {
                            if (Number(timeButton.double) === 1) {
                                return (
                                    <li style={{marginBottom: 36}}>
                                        <div className="Box__timetable">
                                            <div className="Timetable__text" style={{backgroundColor: "#2a3e4c",
                                                // opacity: .5
                                            }}>
                                                <div
                                                    className="Text__time" style={{color: "white"}}>{timeButton.hour < 10 ? '0' + timeButton.hour : timeButton.hour}:00
                                                </div>
                                                <div className="Item__hr Item__hr_vertical Item__hr_white Item__hr_inline"/>
                                                <div
                                                    className="Text__time" style={{color: "white"}}>{timeButton.hour < 8 ? ('0' + (timeButton.hour + 1)) : (timeButton.hour + 1)}:00
                                                </div>
                                            </div>
                                        </div>
                                        <div className="Box__timetable">
                                            <div className="Timetable__text" style={{backgroundColor: "#2a3e4c",
                                                // opacity: .5
                                            }}>
                                                <div
                                                    className="Text__time" style={{color: "white"}}>{timeButton.hour < 9 ? '0' + (timeButton.hour + 1) : (timeButton.hour + 1)}:00
                                                </div>
                                                <div className="Item__hr Item__hr_vertical Item__hr_white Item__hr_inline"/>
                                                <div
                                                    className="Text__time" style={{color: "white"}}>{timeButton.hour < 8 ? ('0' + (timeButton.hour + 2)) : (timeButton.hour + 2)}:00
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                );
                            }

                            else {
                                return (
                                    <li>
                                        <div className="Box__timetable">
                                            <div className="Timetable__text" style={{backgroundColor: "#2a3e4c",
                                                // opacity: .5
                                            }}>
                                                <div
                                                    className="Text__time" style={{color: "white"}}>{timeButton.hour < 10 ? '0' + timeButton.hour : timeButton.hour}:00
                                                </div>
                                                <div className="Item__hr Item__hr_vertical Item__hr_white Item__hr_inline"/>
                                                <div
                                                    className="Text__time" style={{color: "white"}}>{timeButton.hour < 8 ? ('0' + (timeButton.hour + 1)) : (timeButton.hour + 1)}:00
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                );
                            }
                        }

                        else if (isLecture) {
                            if (Number(timeButton.double) === 1) {
                                return (
                                    <li style={{marginBottom: 36}}>
                                        <div className="Box__timetable">
                                            <div className="Timetable__text" style={{backgroundColor: "#fdd835"}}>
                                                <div
                                                    className="Text__time">{timeButton.hour < 10 ? '0' + timeButton.hour : timeButton.hour}:00
                                                </div>
                                                <div className="Item__hr Item__hr_vertical Item__hr_inline"/>
                                                <div
                                                    className="Text__time">{timeButton.hour < 8 ? ('0' + (timeButton.hour + 1)) : (timeButton.hour + 1)}:00
                                                </div>
                                            </div>
                                        </div>
                                        <div className="Box__timetable">
                                            <div className="Timetable__text" style={{backgroundColor: "#fdd835"}}>
                                                <div
                                                    className="Text__time">{timeButton.hour < 9 ? '0' + (timeButton.hour + 1) : (timeButton.hour + 1)}:00
                                                </div>
                                                <div className="Item__hr Item__hr_vertical Item__hr_inline"/>
                                                <div
                                                    className="Text__time">{timeButton.hour < 8 ? ('0' + (timeButton.hour + 2)) : (timeButton.hour + 2)}:00
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                );
                            }

                            else {
                                return (
                                    <li>
                                        <div className="Box__timetable">
                                            <div className="Timetable__text" style={{backgroundColor: "#fdd835"}}>
                                                <div
                                                    className="Text__time">{timeButton.hour < 10 ? '0' + timeButton.hour : timeButton.hour}:00
                                                </div>
                                                <div className="Item__hr Item__hr_vertical Item__hr_inline"/>
                                                <div
                                                    className="Text__time">{timeButton.hour < 8 ? ('0' + (timeButton.hour + 1)) : (timeButton.hour + 1)}:00
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                );
                            }
                        }

                        else if (isLaboratory) {
                            if (Number(timeButton.double) === 1) {
                                return (
                                    <li style={{marginBottom: 36}}>
                                        <div className="Box__timetable">
                                            <div className="Timetable__text" style={{backgroundColor: "#66bb6a"}}>
                                                <div
                                                    className="Text__time">{timeButton.hour < 10 ? '0' + timeButton.hour : timeButton.hour}:00
                                                </div>
                                                <div className="Item__hr Item__hr_vertical Item__hr_inline"/>
                                                <div
                                                    className="Text__time">{timeButton.hour < 8 ? ('0' + (timeButton.hour + 1)) : (timeButton.hour + 1)}:00
                                                </div>
                                            </div>
                                        </div>
                                        <div className="Box__timetable">
                                            <div className="Timetable__text" style={{backgroundColor: "#66bb6a"}}>
                                                <div
                                                    className="Text__time">{timeButton.hour < 9 ? '0' + (timeButton.hour + 1) : (timeButton.hour + 1)}:00
                                                </div>
                                                <div className="Item__hr Item__hr_vertical Item__hr_inline"/>
                                                <div
                                                    className="Text__time">{timeButton.hour < 8 ? ('0' + (timeButton.hour + 2)) : (timeButton.hour + 2)}:00
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                );
                            }

                            else {
                                return (
                                    <li>
                                        <div className="Box__timetable">
                                            <div className="Timetable__text" style={{backgroundColor: "#66bb6a"}}>
                                                <div
                                                    className="Text__time">{timeButton.hour < 10 ? '0' + timeButton.hour : timeButton.hour}:00
                                                </div>
                                                <div className="Item__hr Item__hr_vertical Item__hr_inline"/>
                                                <div
                                                    className="Text__time">{timeButton.hour < 8 ? ('0' + (timeButton.hour + 1)) : (timeButton.hour + 1)}:00
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                );
                            }
                        }

                        else if (isPractical) {
                            if (Number(timeButton.double) === 1) {
                                return (
                                    <li style={{marginBottom: 36}}>
                                        <div className="Box__timetable">
                                            <div className="Timetable__text" style={{backgroundColor: "#00acc1"}}>
                                                <div
                                                    className="Text__time">{timeButton.hour < 10 ? '0' + timeButton.hour : timeButton.hour}:00
                                                </div>
                                                <div className="Item__hr Item__hr_vertical Item__hr_inline"/>
                                                <div
                                                    className="Text__time">{timeButton.hour < 8 ? ('0' + (timeButton.hour + 1)) : (timeButton.hour + 1)}:00
                                                </div>
                                            </div>
                                        </div>
                                        <div className="Box__timetable">
                                            <div className="Timetable__text" style={{backgroundColor: "#00acc1"}}>
                                                <div
                                                    className="Text__time">{timeButton.hour < 9 ? '0' + (timeButton.hour + 1) : (timeButton.hour + 1)}:00
                                                </div>
                                                <div className="Item__hr Item__hr_vertical Item__hr_inline"/>
                                                <div
                                                    className="Text__time">{timeButton.hour < 8 ? ('0' + (timeButton.hour + 2)) : (timeButton.hour + 2)}:00
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                );
                            }

                            else {
                                return (
                                    <li>
                                        <div className="Box__timetable">
                                            <div className="Timetable__text" style={{backgroundColor: "#00acc1"}}>
                                                <div
                                                    className="Text__time">{timeButton.hour < 10 ? '0' + timeButton.hour : timeButton.hour}:00
                                                </div>
                                                <div className="Item__hr Item__hr_vertical Item__hr_inline"/>
                                                <div
                                                    className="Text__time">{timeButton.hour < 8 ? ('0' + (timeButton.hour + 1)) : (timeButton.hour + 1)}:00
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                );
                            }
                        }

                        else if (isExamSelect) {
                            if (Number(timeButton.double) === 1) {
                                return (
                                    <li style={{marginBottom: 36}}>
                                        <div className="Box__timetable">
                                            <div className="Timetable__text" style={{backgroundColor: "#7986cb", opacity: (isExam ? 1 : .75)}}>
                                                <div
                                                    className="Text__time">{timeButton.hour < 10 ? '0' + timeButton.hour : timeButton.hour}:00
                                                </div>
                                                <div className="Item__hr Item__hr_vertical Item__hr_inline"/>
                                                <div
                                                    className="Text__time">{timeButton.hour < 8 ? ('0' + (timeButton.hour + 1)) : (timeButton.hour + 1)}:00
                                                </div>
                                            </div>
                                        </div>
                                        <div className="Box__timetable">
                                            <div className="Timetable__text" style={{backgroundColor: "#7986cb", opacity: (isExam ? 1 : .75)}}>
                                                <div
                                                    className="Text__time">{timeButton.hour < 9 ? '0' + (timeButton.hour + 1) : (timeButton.hour + 1)}:00
                                                </div>
                                                <div className="Item__hr Item__hr_vertical Item__hr_inline"/>
                                                <div
                                                    className="Text__time">{timeButton.hour < 8 ? ('0' + (timeButton.hour + 2)) : (timeButton.hour + 2)}:00
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                );
                            }

                            else {
                                return (
                                    <li>
                                        <div className="Box__timetable">
                                            <div className="Timetable__text" style={{backgroundColor: "#7986cb", opacity: (isExam ? 1 : .75)}}>
                                                <div
                                                    className="Text__time">{timeButton.hour < 10 ? '0' + timeButton.hour : timeButton.hour}:00
                                                </div>
                                                <div className="Item__hr Item__hr_vertical Item__hr_inline"/>
                                                <div
                                                    className="Text__time">{timeButton.hour < 8 ? ('0' + (timeButton.hour + 1)) : (timeButton.hour + 1)}:00
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                );
                            }
                        }

                        else {

                            if (isEdit && typeHour) {

                                if (Number(timeButton.double) === 1) {
                                    return (
                                        <li style={{marginBottom: 36}}>
                                            <div className="Box__timetable" onClick={onClick} style={{cursor: "pointer"}}>
                                                <div className="Timetable__text">
                                                    <div
                                                        className="Text__time">{timeButton.hour < 10 ? '0' + timeButton.hour : timeButton.hour}:00
                                                    </div>
                                                    <div className="Item__hr Item__hr_vertical Item__hr_inline"/>
                                                    <div
                                                        className="Text__time">{timeButton.hour < 8 ? ('0' + (timeButton.hour + 1)) : (timeButton.hour + 1)}:00
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="Box__timetable" onClick={onClick} style={{cursor: "pointer"}}>
                                                <div className="Timetable__text">
                                                    <div
                                                        className="Text__time">{timeButton.hour < 9 ? '0' + (timeButton.hour + 1) : (timeButton.hour + 1)}:00
                                                    </div>
                                                    <div className="Item__hr Item__hr_vertical Item__hr_inline"/>
                                                    <div
                                                        className="Text__time">{timeButton.hour < 8 ? ('0' + (timeButton.hour + 2)) : (timeButton.hour + 2)}:00
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    );
                                }

                                else {
                                    return (
                                        <li>
                                            <div className="Box__timetable" onClick={onClick} style={{cursor: "pointer"}}>
                                                <div className="Timetable__text">
                                                    <div
                                                        className="Text__time">{timeButton.hour < 10 ? '0' + timeButton.hour : timeButton.hour}:00
                                                    </div>
                                                    <div className="Item__hr Item__hr_vertical Item__hr_inline"/>
                                                    <div
                                                        className="Text__time">{timeButton.hour < 8 ? ('0' + (timeButton.hour + 1)) : (timeButton.hour + 1)}:00
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    );
                                }
                            }

                            else {
                                if (Number(timeButton.double) === 1) {
                                    return (
                                        <li style={{marginBottom: 36, opacity: .5}}>
                                            <div className="Box__timetable">
                                                <div className="Timetable__text">
                                                    <div
                                                        className="Text__time">{timeButton.hour < 10 ? '0' + timeButton.hour : timeButton.hour}:00
                                                    </div>
                                                    <div className="Item__hr Item__hr_vertical Item__hr_inline"/>
                                                    <div
                                                        className="Text__time">{timeButton.hour < 8 ? ('0' + (timeButton.hour + 1)) : (timeButton.hour + 1)}:00
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="Box__timetable">
                                                <div className="Timetable__text">
                                                    <div
                                                        className="Text__time">{timeButton.hour < 9 ? '0' + (timeButton.hour + 1) : (timeButton.hour + 1)}:00
                                                    </div>
                                                    <div className="Item__hr Item__hr_vertical Item__hr_inline"/>
                                                    <div
                                                        className="Text__time">{timeButton.hour < 8 ? ('0' + (timeButton.hour + 2)) : (timeButton.hour + 2)}:00
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    );
                                }

                                else {
                                    return (
                                        <li style={{opacity: .5}}>
                                            <div className="Box__timetable">
                                                <div className="Timetable__text">
                                                    <div
                                                        className="Text__time">{timeButton.hour < 10 ? '0' + timeButton.hour : timeButton.hour}:00
                                                    </div>
                                                    <div className="Item__hr Item__hr_vertical Item__hr_inline"/>
                                                    <div
                                                        className="Text__time">{timeButton.hour < 8 ? ('0' + (timeButton.hour + 1)) : (timeButton.hour + 1)}:00
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    );
                                }
                            }
                        }
                    }


                    let timeButtons = [];
                    let isDayCourses = false;



                    let hoursCompleted = false;

                    return (
                        <div className="Page__section">

                            {/*<div className="Section__header Section__header_margin-bottom-38">*/}
                            {/*    <div className="Header__title">*/}
                            {/*        <div className="Title__text">{trainingCourseData.discipline_name}</div>*/}

                            {/*        <div className="Button__edit"*/}
                            {/*             onClick={this.modalOpen.bind(this, 'modalTrainingPlanEdit', modalData(trainingCourseData.id, trainingCourseData))}>*/}
                            {/*            /!*<svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">*!/*/}
                            {/*            /!*    <g id="Group_1663" data-name="Group 1663" transform="translate(-1765 -274)">*!/*/}
                            {/*            /!*        <circle id="Ellipse_62" data-name="Ellipse 62" cx="21" cy="21" r="21" transform="translate(1765 274)" fill="#2a3e4c" opacity="0"/>*!/*/}
                            {/*            /!*        <g id="edit.0a0e73c359245f3e48551d2cb2a488db" transform="translate(1774 283)">*!/*/}
                            {/*            /!*            <path id="Path_11" data-name="Path 11" d="M2.53,15.144a1.973,1.973,0,0,1,.57-1.216l8.56-8.56,1.395,1.395L5.384,14.432l1.395,1.395,7.671-7.671,1.395,1.395L8.173,17.222l1.395,1.395,7.671-7.671,1.395,1.395-8.56,8.56a1.973,1.973,0,0,1-1.216.57L3.076,22A.986.986,0,0,1,2,20.924ZM7.752,19.59l-2.947.268A1.131,1.131,0,0,0,4.5,19.5c-.25-.219-.366-.219-.366-.219l.276-3.033Z" fill="#373b50" fillRule="evenodd"/>*!/*/}
                            {/*            /!*            <path id="Path_12" data-name="Path 12" d="M13.054,3.972l1.395-1.395a1.972,1.972,0,0,1,2.789,0l4.184,4.184a1.972,1.972,0,0,1,0,2.789l-1.395,1.395Z" fill="#373b50"/>*!/*/}
                            {/*            /!*        </g>*!/*/}
                            {/*            /!*    </g>*!/*/}
                            {/*            /!*</svg>*!/*/}

                            {/*            <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">*/}
                            {/*                <g id="Group_2240" data-name="Group 2240" transform="translate(-471 -775)">*/}
                            {/*                    <rect id="Rectangle_2757" data-name="Rectangle 2757" width="42" height="42" transform="translate(471 775)" fill="#f7f7f7" opacity="0"/>*/}
                            {/*                    <g id="Group_2239" data-name="Group 2239" transform="translate(50 28)">*/}
                            {/*                        <path id="Path_965" data-name="Path 965" d="M2.657,17.495a2.447,2.447,0,0,1,.707-1.509L13.983,5.367l1.73,1.73L6.2,16.612l1.73,1.73,9.515-9.515,1.73,1.73L9.658,20.073l1.73,1.73L20.9,12.287l1.73,1.73L12.014,24.636a2.447,2.447,0,0,1-1.509.707l-7.171.652a1.223,1.223,0,0,1-1.329-1.329Zm6.478,5.516-3.656.332A1.4,1.4,0,0,0,5.1,22.9c-.31-.272-.454-.272-.454-.272l.342-3.762Z" transform="translate(428 754)" fill="#2a3e4c" fillRule="evenodd"/>*/}
                            {/*                        <path id="Path_966" data-name="Path 966" d="M13.054,4.447l1.731-1.731a2.447,2.447,0,0,1,3.461,0l5.192,5.192a2.447,2.447,0,0,1,0,3.461L21.707,13.1Z" transform="translate(429.852 754)" fill="#2a3e4c"/>*/}
                            {/*                    </g>*/}
                            {/*                </g>*/}
                            {/*            </svg>*/}


                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            <div className="Section__content">

                                <div className="TeacherTrainingCourse">
                                    <div className="TeacherTrainingCourse__body">

                                        {/*<div className="Body__hr"/>*/}




                                    <div className="Body__wrapper">











                                        <div className="Course" style={{display: "inline-block"}}>

                                            <div className="Course__header Course__header_margin-bottom-38">
                                                <div className="Header__title">
                                                    <div className="Title__text" style={{fontSize: 24}}>{trainingCourseData.discipline_name}</div>

                                                    {/*{trainingCourseData.course_schedule.length > 0 ? null : (*/}
                                                    <div className="Button__edit"
                                                         onClick={this.alertOpen.bind(this, 'alertTeacherTrainingCourseDelete', alertData(trainingCourseData.id, trainingCourseData))}>

                                                        {/*<svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">*/}
                                                        {/*    <g id="Group_2240" data-name="Group 2240" transform="translate(-471 -775)">*/}
                                                        {/*        <rect id="Rectangle_2757" data-name="Rectangle 2757" width="42" height="42" transform="translate(471 775)" fill="#f7f7f7" opacity="0"/>*/}
                                                        {/*        <g id="Group_2239" data-name="Group 2239" transform="translate(50 28)">*/}
                                                        {/*            <path id="Path_965" data-name="Path 965" d="M2.657,17.495a2.447,2.447,0,0,1,.707-1.509L13.983,5.367l1.73,1.73L6.2,16.612l1.73,1.73,9.515-9.515,1.73,1.73L9.658,20.073l1.73,1.73L20.9,12.287l1.73,1.73L12.014,24.636a2.447,2.447,0,0,1-1.509.707l-7.171.652a1.223,1.223,0,0,1-1.329-1.329Zm6.478,5.516-3.656.332A1.4,1.4,0,0,0,5.1,22.9c-.31-.272-.454-.272-.454-.272l.342-3.762Z" transform="translate(428 754)" fill="#2a3e4c" fillRule="evenodd"/>*/}
                                                        {/*            <path id="Path_966" data-name="Path 966" d="M13.054,4.447l1.731-1.731a2.447,2.447,0,0,1,3.461,0l5.192,5.192a2.447,2.447,0,0,1,0,3.461L21.707,13.1Z" transform="translate(429.852 754)" fill="#2a3e4c"/>*/}
                                                        {/*        </g>*/}
                                                        {/*    </g>*/}
                                                        {/*</svg>*/}


                                                        <svg xmlns="http://www.w3.org/2000/svg" id="Group_1521" data-name="Group 1521" width="32" height="32" viewBox="0 0 32 32">
                                                            <g id="Group_1301" data-name="Group 1301" transform="translate(4 4)">
                                                                <rect id="Rectangle_1358" data-name="Rectangle 1358" width="24" height="24" rx="12" fill="#2a3e4c"/>
                                                                <rect id="Rectangle_1359" data-name="Rectangle 1359" width="12" height="12" rx="6" transform="translate(6 6)" fill="none"/>
                                                            </g>
                                                            <rect id="Rectangle_1371" data-name="Rectangle 1371" width="6" height="6" rx="3" transform="translate(13)" fill="#f7f7f7"/>
                                                            <rect id="Rectangle_1373" data-name="Rectangle 1373" width="6" height="6" rx="3" transform="translate(26 13)" fill="#f7f7f7"/>
                                                            <rect id="Rectangle_1374" data-name="Rectangle 1374" width="6" height="6" rx="3" transform="translate(13 26)" fill="#f7f7f7"/>
                                                            <rect id="Rectangle_1375" data-name="Rectangle 1375" width="6" height="6" rx="3" transform="translate(0 13)" fill="#f7f7f7"/>
                                                            <rect id="Rectangle_1376" data-name="Rectangle 1376" width="6" height="6" rx="3" transform="translate(25.192 2.565) rotate(45)" fill="#f7f7f7"/>
                                                            <rect id="Rectangle_1377" data-name="Rectangle 1377" width="10" height="10" rx="5" transform="translate(11 11)" fill="#f7f7f7"/>
                                                            <rect id="Rectangle_1378" data-name="Rectangle 1378" width="6" height="6" rx="3" transform="translate(25.192 20.95) rotate(45)" fill="#f7f7f7"/>
                                                            <rect id="Rectangle_1379" data-name="Rectangle 1379" width="6" height="6" rx="3" transform="translate(6.808 20.95) rotate(45)" fill="#f7f7f7"/>
                                                            <rect id="Rectangle_1380" data-name="Rectangle 1380" width="6" height="6" rx="3" transform="translate(6.808 2.565) rotate(45)" fill="#f7f7f7"/>
                                                        </svg>


                                                    </div>
                                                    {/*)}*/}

                                                    <div className="Block__Item" style={{display: "inline-block", marginTop: 8, marginBottom: 0, verticalAlign: "top"}}>
                                                        <div className="Item__block Item__block_inline Item__block_margin-right Item__block_padding">
                                                            <div className="Item__Text"
                                                                 style={{fontSize: 24, fontWeight: 900,
                                                                     cursor: "pointer",
                                                                     color: "rgb(0, 102, 255)",
                                                                     opacity: 0.75
                                                                 }}
                                                                 onClick={this.modalOpen.bind(this, 'modalTeacherTrainingCourseStudents', modalData(this.props.match.params.trainingCourseId, trainingCourseData))}>
                                                                {groupName(trainingCourseData.group_name, trainingCourseData.initial_year)}
                                                            </div>
                                                            <div className="Item__hr Item__hr_vertical Item__hr_inline"/>
                                                            <div className="Item__label Item__label_inline">Группа</div>
                                                        </div>
                                                    </div>

                                                    <div className="Block__Item" style={{display: "inline-block", paddingTop: 23, marginBottom: 0, verticalAlign: "top", float: "right", marginRight: 0}}>
                                                        {selectChat ? (
                                                                <div style={{
                                                                    cursor: "pointer",
                                                                    color: "#0066ff",
                                                                    fontSize: 19,
                                                                    verticalAlign: "super",
                                                                    display: "inline-block",
                                                                        fontFamily: "Roboto, sans-serif",
                                                                        fontWeight: 500,
                                                                        opacity: .75
                                                                }}
                                                                     onClick={this.selectChatNull.bind(this)}>
                                                                    Закрыть чат
                                                                </div>

                                                        ) : (
                                                            <div style={{
                                                                cursor: "pointer",
                                                                color: "#0066ff",
                                                                fontSize: 19,
                                                                verticalAlign: "super",
                                                                display: "inline-block",
                                                                fontFamily: "Roboto, sans-serif",
                                                                fontWeight: 500,
                                                                opacity: .75
                                                            }}
                                                                 onClick={this.selectChat.bind(this)}>
                                                                Открыть чат
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>

                                                <div className="Body__hr" style={{marginTop: 20, marginBottom: 42}}/>

                                                {/*<div className="Course__info">*/}
                                                {/*    <div className="Info__title">*/}
                                                {/*        Тема занятия*/}
                                                {/*    </div>*/}
                                                {/*    <div className="Info__text">*/}
                                                {/*        Введение в основы теории управления*/}
                                                {/*    </div>*/}
                                                {/*    <div className="Info__link">*/}
                                                {/*        <div className="Link__text">*/}
                                                {/*            Учебный материал*/}
                                                {/*        </div>*/}
                                                {/*        <div className="Link__text">*/}
                                                {/*            Конференция*/}
                                                {/*        </div>*/}
                                                {/*    </div>*/}

                                                {/*    <div className="Info__edit"*/}
                                                {/*         onClick={this.modalOpen.bind(this, 'modalTrainingPlanEdit', modalData(trainingCourseData.id, trainingCourseData))}>*/}

                                                {/*        <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">*/}
                                                {/*            <g id="Group_2240" data-name="Group 2240" transform="translate(-471 -775)">*/}
                                                {/*                <rect id="Rectangle_2757" data-name="Rectangle 2757" width="42" height="42" transform="translate(471 775)" fill="#f7f7f7" opacity="0"/>*/}
                                                {/*                <g id="Group_2239" data-name="Group 2239" transform="translate(50 28)">*/}
                                                {/*                    <path id="Path_965" data-name="Path 965" d="M2.657,17.495a2.447,2.447,0,0,1,.707-1.509L13.983,5.367l1.73,1.73L6.2,16.612l1.73,1.73,9.515-9.515,1.73,1.73L9.658,20.073l1.73,1.73L20.9,12.287l1.73,1.73L12.014,24.636a2.447,2.447,0,0,1-1.509.707l-7.171.652a1.223,1.223,0,0,1-1.329-1.329Zm6.478,5.516-3.656.332A1.4,1.4,0,0,0,5.1,22.9c-.31-.272-.454-.272-.454-.272l.342-3.762Z" transform="translate(428 754)" fill="#2a3e4c" fillRule="evenodd"/>*/}
                                                {/*                    <path id="Path_966" data-name="Path 966" d="M13.054,4.447l1.731-1.731a2.447,2.447,0,0,1,3.461,0l5.192,5.192a2.447,2.447,0,0,1,0,3.461L21.707,13.1Z" transform="translate(429.852 754)" fill="#2a3e4c"/>*/}
                                                {/*                </g>*/}
                                                {/*            </g>*/}
                                                {/*        </svg>*/}


                                                {/*    </div>*/}
                                                {/*</div>*/}



                                                {trainingCourseData.course_schedule.map((course_schedule, index) => (

                                                    ((Number(course_schedule.day_lesson) === Number(this.state.selectedDay)) &&
                                                        (Number(course_schedule.month_lesson) === (Number(this.state.selectedMonth) + 1)) &&
                                                        (Number(course_schedule.year_lesson) === Number(this.state.selectedYear))
                                                    ) ? (
                                                        isDayCourses = true,
                                                    <div className="Course__info" key={index}>

                                                        <div className="Info__title" style={{opacity: 0.75, fontSize: 20, fontWeight: 700, marginBottom: 6, color: "#2A3E4C"}}>
                                                            {(course_schedule.lesson_type === 'lecture') ? 'Теоретическое занятие' : null}
                                                            {(course_schedule.lesson_type === 'laboratory') ? 'Лабораторное занятие' : null}
                                                            {(course_schedule.lesson_type === 'practical') ? 'Практическое занятие' : null}
                                                            {(course_schedule.lesson_type === 'exam') ? 'Экзаменационное занятие' : null}
                                                        </div>
                                                        {(course_schedule.lesson_type === 'exam') ? (
                                                            <div className="Title__date" style={{opacity: 0.75, marginBottom: 0, fontWeight: 400, fontSize: 17, color: "#2A3E4C"}}>
                                                                {timeDay(dateTimeEnd)} {timeMonth(dateTimeEnd)} {timeYear(dateTimeEnd)} c {course_schedule.starting_hour}:00
                                                            </div>
                                                        ) : (
                                                            <div className="Title__date" style={{opacity: 0.75, marginBottom: 24, fontWeight: 400, fontSize: 17, color: "#2A3E4C"}}>
                                                                {timeDay(dateTimeEnd)} {timeMonth(dateTimeEnd)} {timeYear(dateTimeEnd)} c {course_schedule.starting_hour}:00 до {Number(course_schedule.double_hours) > 0 ? Number(course_schedule.starting_hour) + 2 : Number(course_schedule.starting_hour) + 1}:00
                                                            </div>
                                                        )}

                                                        {(course_schedule.lesson_theme && (course_schedule.lesson_theme !== 'null')) ? (
                                                            <div className="Info__title" style={{marginBottom: 0}}>
                                                                {course_schedule.lesson_theme}
                                                            </div>
                                                        ) : null}


                                                        {(course_schedule.lesson_description && (course_schedule.lesson_description !== 'null')) ? (
                                                            <div className="Info__text" style={{marginTop: 12, marginBottom: 0, opacity: 1, fontSize: 17}}>
                                                                {course_schedule.lesson_description}
                                                            </div>
                                                        ) : null}

                                                        {(course_schedule.link_lecture && (course_schedule.link_lecture !== 'null')) ? (
                                                            <div className="Info__link"
                                                                 style={{marginTop: 12, display: 'inline-block', cursor: 'pointer'}}
                                                                 onClick={this.linkOpen.bind(this, course_schedule.link_lecture)}>
                                                                <div className="Link__text">
                                                                    Учебный материал
                                                                </div>
                                                            </div>
                                                        ) : null}

                                                        {(course_schedule.link_task && (course_schedule.link_task !== 'null')) ? (
                                                            <div className="Info__link"
                                                                 style={{marginTop: 12, display: 'inline-block', cursor: 'pointer'}}
                                                                 onClick={this.linkOpen.bind(this, course_schedule.link_task)}>
                                                                <div className="Link__text">
                                                                    Задание
                                                                </div>
                                                            </div>
                                                        ) : null}

                                                        {(course_schedule.link_conference && (course_schedule.link_conference !== 'null')) ? (
                                                                <div className="Info__link"
                                                                     style={{marginTop: 12, display: 'inline-block', cursor: 'pointer'}}
                                                                     onClick={this.linkOpen.bind(this, course_schedule.link_conference)}>
                                                                    <div className="Link__text">
                                                                        Вебинар
                                                                    </div>
                                                                </div>
                                                        ) : null}



                                                        {(course_schedule.lesson_type === 'exam') ? (
                                                        <div className="Info__edit"
                                                             onClick={this.modalOpen.bind(this, 'modalTeacherTrainingCourseExamEdit', modalData(trainingCourseData.id, course_schedule))}>

                                                            <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">
                                                                <g id="Group_2240" data-name="Group 2240" transform="translate(-471 -775)">
                                                                    <rect id="Rectangle_2757" data-name="Rectangle 2757" width="42" height="42" transform="translate(471 775)" fill="#f7f7f7" opacity="0"/>
                                                                    <g id="Group_2239" data-name="Group 2239" transform="translate(50 28)">
                                                                        <path id="Path_965" data-name="Path 965" d="M2.657,17.495a2.447,2.447,0,0,1,.707-1.509L13.983,5.367l1.73,1.73L6.2,16.612l1.73,1.73,9.515-9.515,1.73,1.73L9.658,20.073l1.73,1.73L20.9,12.287l1.73,1.73L12.014,24.636a2.447,2.447,0,0,1-1.509.707l-7.171.652a1.223,1.223,0,0,1-1.329-1.329Zm6.478,5.516-3.656.332A1.4,1.4,0,0,0,5.1,22.9c-.31-.272-.454-.272-.454-.272l.342-3.762Z" transform="translate(428 754)" fill="#2a3e4c" fillRule="evenodd"/>
                                                                        <path id="Path_966" data-name="Path 966" d="M13.054,4.447l1.731-1.731a2.447,2.447,0,0,1,3.461,0l5.192,5.192a2.447,2.447,0,0,1,0,3.461L21.707,13.1Z" transform="translate(429.852 754)" fill="#2a3e4c"/>
                                                                    </g>
                                                                </g>
                                                            </svg>


                                                        </div>

                                                        ) : (
                                                            <div className="Info__edit"
                                                                 onClick={this.modalOpen.bind(this, 'modalTeacherTrainingCourseLessonEdit', modalData(trainingCourseData.id, course_schedule))}>

                                                                <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">
                                                                    <g id="Group_2240" data-name="Group 2240" transform="translate(-471 -775)">
                                                                        <rect id="Rectangle_2757" data-name="Rectangle 2757" width="42" height="42" transform="translate(471 775)" fill="#f7f7f7" opacity="0"/>
                                                                        <g id="Group_2239" data-name="Group 2239" transform="translate(50 28)">
                                                                            <path id="Path_965" data-name="Path 965" d="M2.657,17.495a2.447,2.447,0,0,1,.707-1.509L13.983,5.367l1.73,1.73L6.2,16.612l1.73,1.73,9.515-9.515,1.73,1.73L9.658,20.073l1.73,1.73L20.9,12.287l1.73,1.73L12.014,24.636a2.447,2.447,0,0,1-1.509.707l-7.171.652a1.223,1.223,0,0,1-1.329-1.329Zm6.478,5.516-3.656.332A1.4,1.4,0,0,0,5.1,22.9c-.31-.272-.454-.272-.454-.272l.342-3.762Z" transform="translate(428 754)" fill="#2a3e4c" fillRule="evenodd"/>
                                                                            <path id="Path_966" data-name="Path 966" d="M13.054,4.447l1.731-1.731a2.447,2.447,0,0,1,3.461,0l5.192,5.192a2.447,2.447,0,0,1,0,3.461L21.707,13.1Z" transform="translate(429.852 754)" fill="#2a3e4c"/>
                                                                        </g>
                                                                    </g>
                                                                </svg>


                                                            </div>
                                                        )}
                                                    </div>

                                                    ) : null
                                                ))}

                                                {!isDayCourses ? (
                                                    <React.Fragment>
                                                        <div className="Page__loader">
                                                            <i/>
                                                            <div className="Body_nodata" style={{
                                                                marginTop: 48 + 24,
                                                                opacity: .15,
                                                                fontSize: 56
                                                            }}>
                                                                Нет занятий
                                                            </div>
                                                            <i/>
                                                        </div>
                                                    </React.Fragment>
                                                ) : null}

                                            </div>

















                                        </div>













                                        {selectChat ? null : (



                                        <React.Fragment>





                                        <div className="Day" style={{display: "inline-block"}}>
                                            <div className="Box__day">
                                                {timeDay(dateTimeEnd)}
                                                <div className={'Box__today'}>
                                                    <div className={'Today__weekday'}>{weekDay(dateTimeEnd)}</div>
                                                    <div className={'Today__month'}>{timeMonth(dateTimeEnd)}</div>
                                                </div>
                                            </div>


                                            {Number(trainingCourseData.training_schedule.schedule_period["type_week_" + this.currentWeekYear(this.state.selectedMonth, this.state.selectedDay, this.state.selectedYear)]) > 0 ? (

                                            <ul>

                                                {trainingCourseData.training_schedule.schedule_timetable.map((timetable, index) => (
                                                    Number(timetable[weekDayNameNumber(this.state.selectedMonth, this.state.selectedDay, this.state.selectedYear)]) > 0 ? (
                                                        <React.Fragment key={index}>

                                                            {/*{timeButtons(timetable)}*/}

                                                            {(function() {





                                                                let firstHour;
                                                                let secondHour;

                                                                let i = 0;
                                                                let isFirstHour = false;

                                                                let doubleHours = Number(timetable.double_hours);

                                                                while (!isFirstHour) {

                                                                    if (Number(timetable['day_hour_' + i]) > 0) {
                                                                        isFirstHour = true;
                                                                        firstHour = i
                                                                    }

                                                                    else {
                                                                        if (i < 23) {

                                                                            i++;
                                                                        }

                                                                        else {
                                                                            isFirstHour = true;
                                                                        }
                                                                    }
                                                                }

                                                                let j = 23;
                                                                let isSecondHour = false;

                                                                while (!isSecondHour) {

                                                                    if (Number(timetable['day_hour_' + j]) > 0) {
                                                                        isSecondHour = true;
                                                                        secondHour = j;
                                                                    }

                                                                    else {
                                                                        if (j > 0) {

                                                                            j--;
                                                                        }

                                                                        else {
                                                                            isSecondHour = true;
                                                                        }
                                                                    }
                                                                }


                                                                let multiple = false;

                                                                if (doubleHours === 1) {

                                                                    let a = secondHour - firstHour +1;
                                                                    if (a & 1) {
                                                                        multiple = false;
                                                                        secondHour = secondHour - 1;
                                                                    }

                                                                    else {
                                                                        multiple = true;
                                                                    }
                                                                }

                                                                if ((firstHour != null) && (secondHour != null)) {
                                                                    for (let k = firstHour; k < (secondHour + 1); (doubleHours === 1 ? k = k + 2 : k++)) {

                                                                        let object = {
                                                                            day: Number(selectedDay),
                                                                            month: Number(selectedMonth),
                                                                            year: Number(selectedYear),
                                                                            hour: k,
                                                                            double: doubleHours
                                                                        }


                                                                        timeButtons.push(object);
                                                                    }
                                                                }









                                                                })()}

                                                            {timeButtons.map((timeButtons, index) => (
                                                                <React.Fragment key={index}>
                                                                {timeButton(timeButtons, isTypeHours, selectTypeHours, this.modalOpen.bind(this,
                                                                    (selectTypeHours === 'exam' ? 'modalTeacherTrainingCourseExam' : 'modalTeacherTrainingCourseLessonAdd'),
                                                                    modalData(trainingCourseData.id,
                                                                        timetableData(this.state.selectedMonth, this.state.selectedDay, this.state.selectedYear, selectTypeHours, timeButtons.hour, (timeButtons.double === 1))
                                                                    )))}
                                                                    {/*<div className="Body__hr" style={{*/}
                                                                    {/*    marginLeft: 8,*/}
                                                                    {/*    width: 206,*/}
                                                                    {/*    marginBottom: 12,*/}
                                                                    {/*    opacity: .5,*/}
                                                                    {/*    marginTop: -24}}/>*/}
                                                                </React.Fragment>
                                                            ))}












                                                            {/*{Number(timetable.day_hour_0) > 0 ? (*/}
                                                            {/*    <li>*/}
                                                            {/*        <div className="Box__timetable">*/}
                                                            {/*            <div className="Timetable__text">*/}
                                                            {/*                <div className="Text__time">00:00</div>*/}
                                                            {/*                <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                                            {/*                <div className="Text__time">01:00</div>*/}
                                                            {/*            </div>*/}
                                                            {/*        </div>*/}
                                                            {/*    </li>*/}
                                                            {/*) : null}*/}

                                                            {/*{Number(timetable.day_hour_1) > 0 ? (*/}
                                                            {/*    <li>*/}
                                                            {/*        <div className="Box__timetable">*/}
                                                            {/*            <div className="Timetable__text">*/}
                                                            {/*                <div className="Text__time">01:00</div>*/}
                                                            {/*                <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                                            {/*                <div className="Text__time">02:00</div>*/}
                                                            {/*            </div>*/}
                                                            {/*        </div>*/}
                                                            {/*    </li>*/}
                                                            {/*) : null}*/}

                                                            {/*{Number(timetable.day_hour_2) > 0 ? (*/}
                                                            {/*    <li>*/}
                                                            {/*        <div className="Box__timetable">*/}
                                                            {/*            <div className="Timetable__text">*/}
                                                            {/*                <div className="Text__time">02:00</div>*/}
                                                            {/*                <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                                            {/*                <div className="Text__time">03:00</div>*/}
                                                            {/*            </div>*/}
                                                            {/*        </div>*/}
                                                            {/*    </li>*/}
                                                            {/*) : null}*/}

                                                            {/*{Number(timetable.day_hour_3) > 0 ? (*/}
                                                            {/*    <li>*/}
                                                            {/*        <div className="Box__timetable">*/}
                                                            {/*            <div className="Timetable__text">*/}
                                                            {/*                <div className="Text__time">03:00</div>*/}
                                                            {/*                <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                                            {/*                <div className="Text__time">04:00</div>*/}
                                                            {/*            </div>*/}
                                                            {/*        </div>*/}
                                                            {/*    </li>*/}
                                                            {/*) : null}*/}

                                                            {/*{Number(timetable.day_hour_4) > 0 ? (*/}
                                                            {/*    <li>*/}
                                                            {/*        <div className="Box__timetable">*/}
                                                            {/*            <div className="Timetable__text">*/}
                                                            {/*                <div className="Text__time">04:00</div>*/}
                                                            {/*                <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                                            {/*                <div className="Text__time">05:00</div>*/}
                                                            {/*            </div>*/}
                                                            {/*        </div>*/}
                                                            {/*    </li>*/}
                                                            {/*) : null}*/}

                                                            {/*{Number(timetable.day_hour_5) > 0 ? (*/}
                                                            {/*    <li>*/}
                                                            {/*        <div className="Box__timetable">*/}
                                                            {/*            <div className="Timetable__text">*/}
                                                            {/*                <div className="Text__time">05:00</div>*/}
                                                            {/*                <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                                            {/*                <div className="Text__time">06:00</div>*/}
                                                            {/*            </div>*/}
                                                            {/*        </div>*/}
                                                            {/*    </li>*/}
                                                            {/*) : null}*/}

                                                            {/*{Number(timetable.day_hour_6) > 0 ? (*/}
                                                            {/*    <li>*/}
                                                            {/*        <div className="Box__timetable">*/}
                                                            {/*            <div className="Timetable__text">*/}
                                                            {/*                <div className="Text__time">06:00</div>*/}
                                                            {/*                <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                                            {/*                <div className="Text__time">07:00</div>*/}
                                                            {/*            </div>*/}
                                                            {/*        </div>*/}
                                                            {/*    </li>*/}
                                                            {/*) : null}*/}

                                                            {/*{Number(timetable.day_hour_7) > 0 ? (*/}
                                                            {/*    <li>*/}
                                                            {/*        <div className="Box__timetable">*/}
                                                            {/*            <div className="Timetable__text">*/}
                                                            {/*                <div className="Text__time">07:00</div>*/}
                                                            {/*                <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                                            {/*                <div className="Text__time">08:00</div>*/}
                                                            {/*            </div>*/}
                                                            {/*        </div>*/}
                                                            {/*    </li>*/}
                                                            {/*) : null}*/}

                                                            {/*{Number(timetable.day_hour_8) > 0 ? (*/}
                                                            {/*    <li>*/}
                                                            {/*        <div className="Box__timetable">*/}
                                                            {/*            <div className="Timetable__text">*/}
                                                            {/*                <div className="Text__time">08:00</div>*/}
                                                            {/*                <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                                            {/*                <div className="Text__time">09:00</div>*/}
                                                            {/*            </div>*/}
                                                            {/*        </div>*/}
                                                            {/*    </li>*/}
                                                            {/*) : null}*/}

                                                            {/*{Number(timetable.day_hour_9) > 0 ? (*/}
                                                            {/*    <li>*/}
                                                            {/*        <div className="Box__timetable">*/}
                                                            {/*            <div className="Timetable__text">*/}
                                                            {/*                <div className="Text__time">09:00</div>*/}
                                                            {/*                <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                                            {/*                <div className="Text__time">10:00</div>*/}
                                                            {/*            </div>*/}
                                                            {/*        </div>*/}
                                                            {/*    </li>*/}
                                                            {/*) : null}*/}

                                                            {/*{Number(timetable.day_hour_10) > 0 ? (*/}
                                                            {/*    <li>*/}
                                                            {/*        <div className="Box__timetable" style={{marginBottom: 24}}>*/}
                                                            {/*            <div className="Timetable__text">*/}
                                                            {/*                <div className="Text__time">10:00</div>*/}
                                                            {/*                <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                                            {/*                <div className="Text__time">11:00</div>*/}
                                                            {/*            </div>*/}
                                                            {/*        </div>*/}
                                                            {/*    </li>*/}
                                                            {/*) : null}*/}

                                                            {/*{Number(timetable.day_hour_11) > 0 ? (*/}
                                                            {/*    <li>*/}
                                                            {/*        <div className="Box__timetable Box__timetable_pointer">*/}
                                                            {/*            <div className="Timetable__text"*/}
                                                            {/*                 onClick={this.modalOpen.bind(this,*/}
                                                            {/*                     'modalTeacherTrainingCourseLessonAdd',*/}
                                                            {/*                     modalData(trainingCourseData.id,*/}
                                                            {/*                         timetableData(this.state.selectedMonth, this.state.selectedDay, this.state.selectedYear, selectTypeHours,11, true)*/}
                                                            {/*                     ))}>*/}
                                                            {/*                <div className="Text__time">11:00</div>*/}
                                                            {/*                <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                                            {/*                <div className="Text__time">12:00</div>*/}
                                                            {/*            </div>*/}
                                                            {/*        </div>*/}
                                                            {/*    </li>*/}
                                                            {/*) : null}*/}

                                                            {/*{Number(timetable.day_hour_12) > 0 ? (*/}
                                                            {/*    <li>*/}
                                                            {/*        <div className="Box__timetable" style={{marginBottom: 24}}>*/}
                                                            {/*            <div className="Timetable__text">*/}
                                                            {/*                <div className="Text__time">12:00</div>*/}
                                                            {/*                <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                                            {/*                <div className="Text__time">13:00</div>*/}
                                                            {/*            </div>*/}
                                                            {/*        </div>*/}
                                                            {/*    </li>*/}
                                                            {/*) : null}*/}

                                                            {/*{Number(timetable.day_hour_13) > 0 ? (*/}
                                                            {/*    <li>*/}
                                                            {/*        <div className="Box__timetable">*/}
                                                            {/*            <div className="Timetable__text">*/}
                                                            {/*                <div className="Text__time">13:00</div>*/}
                                                            {/*                <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                                            {/*                <div className="Text__time">14:00</div>*/}
                                                            {/*            </div>*/}
                                                            {/*        </div>*/}
                                                            {/*    </li>*/}
                                                            {/*) : null}*/}

                                                            {/*{Number(timetable.day_hour_14) > 0 ? (*/}
                                                            {/*    <li>*/}
                                                            {/*        <div className="Box__timetable" style={{marginBottom: 24}}>*/}
                                                            {/*            <div className="Timetable__text">*/}
                                                            {/*                <div className="Text__time">14:00</div>*/}
                                                            {/*                <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                                            {/*                <div className="Text__time">15:00</div>*/}
                                                            {/*            </div>*/}
                                                            {/*        </div>*/}
                                                            {/*    </li>*/}
                                                            {/*) : null}*/}

                                                            {/*{Number(timetable.day_hour_15) > 0 ? (*/}
                                                            {/*    <li>*/}
                                                            {/*        <div className="Box__timetable">*/}
                                                            {/*            <div className="Timetable__text">*/}
                                                            {/*                <div className="Text__time">15:00</div>*/}
                                                            {/*                <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                                            {/*                <div className="Text__time">16:00</div>*/}
                                                            {/*            </div>*/}
                                                            {/*        </div>*/}
                                                            {/*    </li>*/}
                                                            {/*) : null}*/}

                                                            {/*{Number(timetable.day_hour_16) > 0 ? (*/}
                                                            {/*    <li>*/}
                                                            {/*        <div className="Box__timetable">*/}
                                                            {/*            <div className="Timetable__text">*/}
                                                            {/*                <div className="Text__time">16:00</div>*/}
                                                            {/*                <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                                            {/*                <div className="Text__time">17:00</div>*/}
                                                            {/*            </div>*/}
                                                            {/*        </div>*/}
                                                            {/*    </li>*/}
                                                            {/*) : null}*/}

                                                            {/*{Number(timetable.day_hour_17) > 0 ? (*/}
                                                            {/*    <li>*/}
                                                            {/*        <div className="Box__timetable">*/}
                                                            {/*            <div className="Timetable__text">*/}
                                                            {/*                <div className="Text__time">17:00</div>*/}
                                                            {/*                <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                                            {/*                <div className="Text__time">18:00</div>*/}
                                                            {/*            </div>*/}
                                                            {/*        </div>*/}
                                                            {/*    </li>*/}
                                                            {/*) : null}*/}

                                                            {/*{Number(timetable.day_hour_18) > 0 ? (*/}
                                                            {/*    <li>*/}
                                                            {/*        <div className="Box__timetable">*/}
                                                            {/*            <div className="Timetable__text">*/}
                                                            {/*                <div className="Text__time">18:00</div>*/}
                                                            {/*                <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                                            {/*                <div className="Text__time">19:00</div>*/}
                                                            {/*            </div>*/}
                                                            {/*        </div>*/}
                                                            {/*    </li>*/}
                                                            {/*) : null}*/}

                                                            {/*{Number(timetable.day_hour_19) > 0 ? (*/}
                                                            {/*    <li>*/}
                                                            {/*        <div className="Box__timetable">*/}
                                                            {/*            <div className="Timetable__text">*/}
                                                            {/*                <div className="Text__time">19:00</div>*/}
                                                            {/*                <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                                            {/*                <div className="Text__time">20:00</div>*/}
                                                            {/*            </div>*/}
                                                            {/*        </div>*/}
                                                            {/*    </li>*/}
                                                            {/*) : null}*/}

                                                            {/*{Number(timetable.day_hour_20) > 0 ? (*/}
                                                            {/*    <li>*/}
                                                            {/*        <div className="Box__timetable">*/}
                                                            {/*            <div className="Timetable__text">*/}
                                                            {/*                <div className="Text__time">20:00</div>*/}
                                                            {/*                <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                                            {/*                <div className="Text__time">21:00</div>*/}
                                                            {/*            </div>*/}
                                                            {/*        </div>*/}
                                                            {/*    </li>*/}
                                                            {/*) : null}*/}

                                                            {/*{Number(timetable.day_hour_21) > 0 ? (*/}
                                                            {/*    <li>*/}
                                                            {/*        <div className="Box__timetable">*/}
                                                            {/*            <div className="Timetable__text">*/}
                                                            {/*                <div className="Text__time">21:00</div>*/}
                                                            {/*                <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                                            {/*                <div className="Text__time">22:00</div>*/}
                                                            {/*            </div>*/}
                                                            {/*        </div>*/}
                                                            {/*    </li>*/}
                                                            {/*) : null}*/}

                                                            {/*{Number(timetable.day_hour_22) > 0 ? (*/}
                                                            {/*    <li>*/}
                                                            {/*        <div className="Box__timetable">*/}
                                                            {/*            <div className="Timetable__text">*/}
                                                            {/*                <div className="Text__time">22:00</div>*/}
                                                            {/*                <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                                            {/*                <div className="Text__time">23:00</div>*/}
                                                            {/*            </div>*/}
                                                            {/*        </div>*/}
                                                            {/*    </li>*/}
                                                            {/*) : null}*/}

                                                            {/*{Number(timetable.day_hour_23) > 0 ? (*/}
                                                            {/*    <li>*/}
                                                            {/*        <div className="Box__timetable">*/}
                                                            {/*            <div className="Timetable__text">*/}
                                                            {/*                <div className="Text__time">23:00</div>*/}
                                                            {/*                <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                                            {/*                <div className="Text__time">24:00</div>*/}
                                                            {/*            </div>*/}
                                                            {/*        </div>*/}
                                                            {/*    </li>*/}
                                                            {/*) : null}*/}


                                                        </React.Fragment>
                                                    ) : null
                                                ))}

                                            </ul>
                                                ) : null}

                                        </div>




                                        <div className="Calendar" style={{display: "inline-block"}}>
                                            <div className="Calendar__day">
                                                <div className={'Calendar__today'}>
                                                    <div
                                                        className={'Today__weekday'}>
                                                        {timeYear(new Date(this.state.calendarYear, this.state.calendarMonth).getTime() / 1000)}
                                                    </div>
                                                    <div
                                                        className={'Today__month'}>
                                                        {timeMonth(new Date(this.state.calendarYear, this.state.calendarMonth).getTime() / 1000)[0].toUpperCase() + timeMonth(new Date(this.state.calendarYear, this.state.calendarMonth).getTime() / 1000).slice(1)}
                                                    </div>
                                                </div>
                                                <div className="Header__buttons">

                                                    <div className="Header__button "
                                                         onClick={this.monthMines.bind(this)}>

                                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                                                            <g id="Group_1680" data-name="Group 1680" transform="translate(-1392 -522)">
                                                                <rect id="Rectangle_2080" data-name="Rectangle 2080" width="32" height="32" rx="16" transform="translate(1392 522)" fill="#cacfd3"/>
                                                                <g id="Group_1677" data-name="Group 1677" transform="translate(1948 -930) rotate(90)">
                                                                    <g id="Group_920" data-name="Group 920" transform="translate(420.563 1105.602) rotate(-45)">
                                                                        <rect id="Rectangle_1180" data-name="Rectangle 1180" width="10" height="4" rx="1" transform="translate(1136.293 341.001)" fill="#fff"/>
                                                                        <rect id="Rectangle_1181" data-name="Rectangle 1181" width="4" height="10" rx="1" transform="translate(1136.293 335)" fill="#fff"/>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </div>

                                                    <div className="Header__button "
                                                         onClick={this.monthPlus.bind(this)}>

                                                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                                                            <g id="Group_1679" data-name="Group 1679" transform="translate(-1488 -524)">
                                                                <rect id="Rectangle_2081" data-name="Rectangle 2081" width="32" height="32" rx="16" transform="translate(1488 524)" fill="#cacfd3"/>
                                                                <g id="Group_1678" data-name="Group 1678" transform="translate(964 2008) rotate(-90)">
                                                                    <g id="Group_920" data-name="Group 920" transform="translate(420.563 1105.602) rotate(-45)">
                                                                        <rect id="Rectangle_1180" data-name="Rectangle 1180" width="10" height="4" rx="1" transform="translate(1136.293 341.001)" fill="#fff"/>
                                                                        <rect id="Rectangle_1181" data-name="Rectangle 1181" width="4" height="10" rx="1" transform="translate(1136.293 335)" fill="#fff"/>
                                                                    </g>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                            <table className="Calendar__table">
                                                <thead>
                                                <tr className="Calendar__tr">
                                                    <th className="Calendar__th">
                                                        <div className="Th__title Th__title_left">Пн</div>
                                                    </th>
                                                    <th className="Calendar__th">
                                                        <div className="Th__title Th__title_left">Вт</div>
                                                    </th>
                                                    <th className="Calendar__th">
                                                        <div className="Th__title Th__title_left">Ср</div>
                                                    </th>
                                                    <th className="Calendar__th">
                                                        <div className="Th__title Th__title_left">Чт</div>
                                                    </th>
                                                    <th className="Calendar__th">
                                                        <div className="Th__title Th__title_left">Пт</div>
                                                    </th>
                                                    <th className="Calendar__th">
                                                        <div className="Th__title Th__title_left">Сб</div>
                                                    </th>
                                                    <th className="Calendar__th">
                                                        <div className="Th__title Th__title_left">Вс</div>
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>{daysInMonthTable}</tbody>
                                            </table>






                                            {/*{isTypeHours ? (*/}
                                                <table className="Schedule__info">
                                                    <tbody>

                                                    {Number(trainingCourseData.lecture_hours) > 0 ? (
                                                        <tr className="Info__tr">

                                                            <td className="Info__td">
                                                                {(Number(lectureHours) < Number(trainingCourseData.lecture_hours)) ? (
                                                                <div className={selectTypeHours === 'lecture' ? 'Info__box pointer active' : 'Info__box pointer'} style={{cursor: "pointer"}} onClick={this.selectTypeHours.bind(this, 'lecture')}>
                                                                    <div className="Box__logo yellow">Т</div>
                                                                    {/*<div className="Box__logo yellow" style={{*/}
                                                                    {/*    paddingTop: 10,*/}
                                                                    {/*    paddingBottom: 4*/}
                                                                    {/*}}>*/}
                                                                    {/*    <div style={{*/}

                                                                    {/*        marginLeft: 2,*/}
                                                                    {/*        height: 24,*/}
                                                                    {/*        width: 24,*/}
                                                                    {/*        backgroundColor: "#e9c33e",*/}
                                                                    {/*        borderColor: "#e9c33e",*/}
                                                                    {/*        borderRadius: 26,*/}
                                                                    {/*        display: "inline-block"*/}
                                                                    {/*    }}/>*/}
                                                                    {/*</div>*/}
                                                                    <div className="Box__text Box__text_padding-7" style={{width: 256}}>Теоретические <br/>занятия</div>
                                                                    <div className="Item__hr Item__hr_vertical Item__hr_inline"/>
                                                                    <div className="Item__Text" style={{fontSize: 32, fontWeight: 900, opacity: 0.5}}>{lectureHours}</div>
                                                                    <div className="Item__Text" style={{fontSize: 24, fontWeight: 900, opacity: 0.25}}>&nbsp;из&nbsp;</div>
                                                                    <div className="Item__Text" style={{fontSize: 32, fontWeight: 900, opacity: 0.5}}>{trainingCourseData.lecture_hours}</div>
                                                                </div>
                                                                ) : (
                                                                <div className='Info__box'>
                                                                    <div className="Box__logo yellow">Т</div>
                                                                    {/*<div className="Box__logo yellow" style={{*/}
                                                                    {/*    paddingTop: 10,*/}
                                                                    {/*    paddingBottom: 4*/}
                                                                    {/*}}>*/}
                                                                    {/*    <div style={{*/}

                                                                    {/*        marginLeft: 2,*/}
                                                                    {/*        height: 24,*/}
                                                                    {/*        width: 24,*/}
                                                                    {/*        backgroundColor: "#e9c33e",*/}
                                                                    {/*        borderColor: "#e9c33e",*/}
                                                                    {/*        borderRadius: 26,*/}
                                                                    {/*        display: "inline-block"*/}
                                                                    {/*    }}/>*/}
                                                                    {/*</div>*/}
                                                                    <div className="Box__text Box__text_padding-7" style={{width: 256}}>Теоретические <br/>занятия</div>
                                                                    <div className="Item__hr Item__hr_vertical Item__hr_inline"/>
                                                                    <div className="Item__Text" style={{fontSize: 32, fontWeight: 900, opacity: 0.5}}>{lectureHours}</div>
                                                                    <div className="Item__Text" style={{fontSize: 24, fontWeight: 900, opacity: 0.25}}>&nbsp;из&nbsp;</div>
                                                                    <div className="Item__Text" style={{fontSize: 32, fontWeight: 900, opacity: 0.5}}>{trainingCourseData.lecture_hours}</div>
                                                                </div>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    ) : null}
                                                    {Number(trainingCourseData.laboratory_hours) > 0 ? (
                                                        <tr className="Info__tr">

                                                            <td className="Info__td">
                                                                {(Number(laboratoryHours) < Number(trainingCourseData.laboratory_hours)) ? (
                                                                <div className={selectTypeHours === 'laboratory' ? 'Info__box pointer active' : 'Info__box pointer'} style={{cursor: "pointer"}} onClick={this.selectTypeHours.bind(this, 'laboratory')}>
                                                                    <div className="Box__logo green">Л</div>
                                                                    {/*<div className="Box__logo green" style={{*/}
                                                                    {/*    paddingTop: 10,*/}
                                                                    {/*    paddingBottom: 4*/}
                                                                    {/*}}>*/}
                                                                    {/*    <div style={{*/}
                                                                    {/*        marginLeft: 2,*/}
                                                                    {/*    height: 24,*/}
                                                                    {/*    width: 24,*/}
                                                                    {/*    backgroundColor: "#87B789",*/}
                                                                    {/*    borderColor: "#87B789",*/}
                                                                    {/*    borderRadius: 26,*/}
                                                                    {/*    display: "inline-block"*/}
                                                                    {/*}}/>*/}
                                                                    {/*</div>*/}

                                                                    <div className="Box__text Box__text_padding-7" style={{width: 256}}>Лабораторные <br/>занятия</div>
                                                                    <div className="Item__hr Item__hr_vertical Item__hr_inline"/>
                                                                    <div className="Item__Text" style={{fontSize: 32, fontWeight: 900, opacity: 0.5}}>{laboratoryHours}</div>
                                                                    <div className="Item__Text" style={{fontSize: 24, fontWeight: 900, opacity: 0.25}}>&nbsp;из&nbsp;</div>
                                                                    <div className="Item__Text" style={{fontSize: 32, fontWeight: 900, opacity: 0.5}}>{trainingCourseData.laboratory_hours}</div>
                                                                </div>
                                                                ) : (
                                                                    <div className='Info__box'>
                                                                        <div className="Box__logo green">Л</div>
                                                                        {/*<div className="Box__logo green" style={{*/}
                                                                        {/*    paddingTop: 10,*/}
                                                                        {/*    paddingBottom: 4*/}
                                                                        {/*}}>*/}
                                                                        {/*    <div style={{*/}
                                                                        {/*        marginLeft: 2,*/}
                                                                        {/*    height: 24,*/}
                                                                        {/*    width: 24,*/}
                                                                        {/*    backgroundColor: "#87B789",*/}
                                                                        {/*    borderColor: "#87B789",*/}
                                                                        {/*    borderRadius: 26,*/}
                                                                        {/*    display: "inline-block"*/}
                                                                        {/*}}/>*/}
                                                                        {/*</div>*/}

                                                                        <div className="Box__text Box__text_padding-7" style={{width: 256}}>Лабораторные <br/>занятия</div>
                                                                        <div className="Item__hr Item__hr_vertical Item__hr_inline"/>
                                                                        <div className="Item__Text" style={{fontSize: 32, fontWeight: 900, opacity: 0.5}}>{laboratoryHours}</div>
                                                                        <div className="Item__Text" style={{fontSize: 24, fontWeight: 900, opacity: 0.25}}>&nbsp;из&nbsp;</div>
                                                                        <div className="Item__Text" style={{fontSize: 32, fontWeight: 900, opacity: 0.5}}>{trainingCourseData.laboratory_hours}</div>
                                                                    </div>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    ) : null}









                                                    {Number(trainingCourseData.practical_hours) > 0 ? (
                                                        <tr className="Info__tr">

                                                            <td className="Info__td">
                                                                {(Number(practicalHours) < Number(trainingCourseData.practical_hours)) ? (
                                                                    <div className={selectTypeHours === 'practical' ? 'Info__box pointer active' : 'Info__box pointer'} style={{cursor: "pointer"}} onClick={this.selectTypeHours.bind(this, 'practical')}>
                                                                        <div className="Box__logo purple">П</div>
                                                                        {/*<div className="Box__logo green" style={{*/}
                                                                        {/*    paddingTop: 10,*/}
                                                                        {/*    paddingBottom: 4*/}
                                                                        {/*}}>*/}
                                                                        {/*    <div style={{*/}
                                                                        {/*        marginLeft: 2,*/}
                                                                        {/*    height: 24,*/}
                                                                        {/*    width: 24,*/}
                                                                        {/*    backgroundColor: "#87B789",*/}
                                                                        {/*    borderColor: "#87B789",*/}
                                                                        {/*    borderRadius: 26,*/}
                                                                        {/*    display: "inline-block"*/}
                                                                        {/*}}/>*/}
                                                                        {/*</div>*/}

                                                                        <div className="Box__text Box__text_padding-7" style={{width: 256}}>Практические <br/>занятия</div>
                                                                        <div className="Item__hr Item__hr_vertical Item__hr_inline"/>
                                                                        <div className="Item__Text" style={{fontSize: 32, fontWeight: 900, opacity: 0.5}}>{practicalHours}</div>
                                                                        <div className="Item__Text" style={{fontSize: 24, fontWeight: 900, opacity: 0.25}}>&nbsp;из&nbsp;</div>
                                                                        <div className="Item__Text" style={{fontSize: 32, fontWeight: 900, opacity: 0.5}}>{trainingCourseData.practical_hours}</div>
                                                                    </div>
                                                                ) : (
                                                                    <div className='Info__box'>
                                                                        <div className="Box__logo purple">П</div>
                                                                        {/*<div className="Box__logo green" style={{*/}
                                                                        {/*    paddingTop: 10,*/}
                                                                        {/*    paddingBottom: 4*/}
                                                                        {/*}}>*/}
                                                                        {/*    <div style={{*/}
                                                                        {/*        marginLeft: 2,*/}
                                                                        {/*    height: 24,*/}
                                                                        {/*    width: 24,*/}
                                                                        {/*    backgroundColor: "#87B789",*/}
                                                                        {/*    borderColor: "#87B789",*/}
                                                                        {/*    borderRadius: 26,*/}
                                                                        {/*    display: "inline-block"*/}
                                                                        {/*}}/>*/}
                                                                        {/*</div>*/}

                                                                        <div className="Box__text Box__text_padding-7" style={{width: 256}}>Практические <br/>занятия</div>
                                                                        <div className="Item__hr Item__hr_vertical Item__hr_inline"/>
                                                                        <div className="Item__Text" style={{fontSize: 32, fontWeight: 900, opacity: 0.5}}>{practicalHours}</div>
                                                                        <div className="Item__Text" style={{fontSize: 24, fontWeight: 900, opacity: 0.25}}>&nbsp;из&nbsp;</div>
                                                                        <div className="Item__Text" style={{fontSize: 32, fontWeight: 900, opacity: 0.5}}>{trainingCourseData.practical_hours}</div>
                                                                    </div>
                                                                )}
                                                            </td>
                                                        </tr>
                                                    ) : null}


                                                        <tr className="Info__tr">

                                                            <td className="Info__td">
                                                                {Number(examHours) === 0 ? (
                                                                    <div className={selectTypeHours === 'exam' ? 'Info__box pointer active' : 'Info__box pointer'} style={{cursor: "pointer"}} onClick={this.selectTypeHoursExam.bind(this)}>
                                                                        <div className="Box__logo blue">Э</div>
                                                                        <div className="Box__text Box__text_padding-7" style={{width: 256}}>Экзаменационное <br/>занятие</div>
                                                                    </div>
                                                                ) : (
                                                                    <div className='Info__box'>
                                                                            <div className="Box__logo blue">Э</div>
                                                                        <div className="Box__text Box__text_padding-7" style={{width: 256}}>Экзаменационное <br/>занятие</div>
                                                                    </div>
                                                                )}
                                                            </td>
                                                        </tr>

                                                    {/*<tr className="Info__tr">*/}
                                                    {/*    <td className="Info__td">*/}
                                                    {/*        <div className='Info__box pointer' style={{cursor: "pointer"}} onClick={this.modalOpen.bind(this,*/}
                                                    {/*            'modalTeacherTrainingCourseExam',*/}
                                                    {/*            modalData(trainingCourseData.id, timetableData(this.state.selectedMonth, this.state.selectedDay, this.state.selectedYear, selectTypeHours, timeButtons.hour, (timeButtons.double === 1))))}>*/}
                                                    {/*            <div className="Box__logo blue">Э</div>*/}

                                                    {/*            <div className="Box__text Box__text_padding-7" style={{width: 256}}>Экзаменационное <br/>занятие</div>*/}
                                                    {/*        </div>*/}
                                                    {/*    </td>*/}
                                                    {/*</tr>*/}


                                                    {/*<tr className="Info__tr">*/}

                                                    {/*    <td className="Info__td">*/}
                                                    {/*        <div className={typeWeekNumber === 7 ? 'Info__box pointer active' : 'Info__box pointer'} onClick={this.typeWeekNumber.bind(this, 7)}>*/}
                                                    {/*            <div className="Box__logo">П</div>*/}
                                                    {/*            <div className="Box__text Box__text_padding-7">Практические <br/>часы</div>*/}
                                                    {/*        </div>*/}
                                                    {/*    </td>*/}

                                                    {/*    <td className="Info__td" style={{textAlign: "right"}}>*/}
                                                    {/*        <div className="Info__box">*/}

                                                    {/*            <div className="Item__Text" style={{fontSize: 32, fontWeight: 900}}>12</div>*/}
                                                    {/*            <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                                    {/*            <div className="Item__Text" style={{fontSize: 32, fontWeight: 900}}>40</div>*/}
                                                    {/*        </div>*/}
                                                    {/*    </td>*/}
                                                    {/*</tr>*/}

                                                    <tr className="Info__tr">
                                                        <td className="Info__td">
                                                            <div className='Info__box' style={{marginTop: 8}}>
                                                                <div className="Item__Text" style={{fontSize: 24, fontWeight: 900, opacity: 0.25}}>Время расписания, Пермское (GMT+5)</div>
                                                            </div>
                                                        </td>
                                                    </tr>


                                                    </tbody>
                                                </table>
                                            {/*) : (*/}



                                            {/*<table className="Schedule__info">*/}
                                            {/*    <tbody>*/}
                                            {/*    <tr className="Info__tr">*/}

                                            {/*        <td className="Info__td">*/}
                                            {/*            <div className="Info__box  ">*/}
                                            {/*                <div className="Box__logo yellow">Т</div>*/}
                                            {/*                <div className="Box__text Box__text_padding-7">Часы <br/>теории</div>*/}
                                            {/*                <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                            {/*                <div className="Item__Text" style={{fontSize: 32, fontWeight: 900, opacity: 0.5}}>{lectureHours}</div>*/}
                                            {/*                <div className="Item__Text" style={{fontSize: 24, fontWeight: 900, opacity: 0.25}}>&nbsp;из&nbsp;</div>*/}
                                            {/*                <div className="Item__Text" style={{fontSize: 32, fontWeight: 900, opacity: 0.5}}>{trainingCourseData.lecture_hours}</div>*/}
                                            {/*            </div>*/}
                                            {/*        </td>*/}
                                            {/*    </tr>*/}
                                            {/*    <tr className="Info__tr">*/}

                                            {/*        <td className="Info__td">*/}
                                            {/*            <div className="Info__box  ">*/}
                                            {/*                <div className="Box__logo green">Л</div>*/}
                                            {/*                <div className="Box__text Box__text_padding-7">Лабораторные <br/>часы</div>*/}
                                            {/*                <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                            {/*                <div className="Item__Text" style={{fontSize: 32, fontWeight: 900, opacity: 0.5}}>{laboratoryHours}</div>*/}
                                            {/*                <div className="Item__Text" style={{fontSize: 24, fontWeight: 900, opacity: 0.25}}>&nbsp;из&nbsp;</div>*/}
                                            {/*                <div className="Item__Text" style={{fontSize: 32, fontWeight: 900, opacity: 0.5}}>{trainingCourseData.laboratory_hours}</div>*/}
                                            {/*            </div>*/}
                                            {/*        </td>*/}
                                            {/*    </tr>*/}
                                                {/*<tr className="Info__tr">*/}

                                                {/*    <td className="Info__td">*/}
                                                {/*        <div className={typeWeekNumber === 7 ? 'Info__box pointer active' : 'Info__box pointer'} onClick={this.typeWeekNumber.bind(this, 7)}>*/}
                                                {/*            <div className="Box__logo">П</div>*/}
                                                {/*            <div className="Box__text Box__text_padding-7">Практические <br/>часы</div>*/}
                                                {/*        </div>*/}
                                                {/*    </td>*/}

                                                {/*    <td className="Info__td" style={{textAlign: "right"}}>*/}
                                                {/*        <div className="Info__box">*/}

                                                {/*            <div className="Item__Text" style={{fontSize: 32, fontWeight: 900}}>12</div>*/}
                                                {/*            <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                                {/*            <div className="Item__Text" style={{fontSize: 32, fontWeight: 900}}>40</div>*/}
                                                {/*        </div>*/}
                                                {/*    </td>*/}
                                                {/*</tr>*/}
                                            {/*    </tbody>*/}
                                            {/*</table>*/}
                                            {/*)}*/}



                                            {/*<div className="Body__hr" style={{marginLeft: 8, width: 550 - 16, marginBottom: 18}}/>*/}

                                            {/*<div className="Schedule__switch" style={{cursor: "pointer"}} onClick={this.isTypeHours.bind(this, isTypeHours)}>*/}
                                            {/*    <div className="Switch__wrapper" style={{marginRight: 12}}>*/}
                                            {/*        <label className={isTypeHours ? 'switch switch_active' : 'switch'}>*/}
                                            {/*            <span className="switch__lever" />*/}
                                            {/*            /!*<span className="switch__index" />*!/*/}
                                            {/*        </label>*/}
                                            {/*    </div>*/}
                                            {/*    /!*<div className="Item__hr Item__hr_vertical Item__hr_inline"/>*!/*/}
                                            {/*    <div className="Switch__text">Редактировать</div>*/}
                                            {/*</div>*/}


                                            {/*<table className="Schedule__info">*/}
                                            {/*    <tbody>*/}
                                            {/*    <tr className="Info__tr">*/}

                                            {/*        <td className="Info__td">*/}
                                            {/*            <div className='Info__box'>*/}
                                            {/*                <div style={{*/}
                                            {/*                    marginLeft: 14,*/}
                                            {/*                    marginRight: 16,*/}
                                            {/*                    height: 24,*/}
                                            {/*                    width: 24,*/}
                                            {/*                    backgroundColor: "#e9653e",*/}
                                            {/*                    borderColor: "#e9653e",*/}
                                            {/*                    borderRadius: 26,*/}
                                            {/*                    display: "inline-block"*/}
                                            {/*                }}/>*/}
                                            {/*                <div className="Box__text" style={{verticalAlign: "top", paddingTop: 3}}>Занятия группы</div>*/}
                                            {/*            </div>*/}
                                            {/*        </td>*/}
                                            {/*    </tr>*/}
                                            {/*        <tr className="Info__tr">*/}

                                            {/*            <td className="Info__td">*/}
                                            {/*                <div className='Info__box'>*/}
                                            {/*                    <div style={{*/}
                                            {/*                        marginLeft: 14,*/}
                                            {/*                        marginRight: 16,*/}
                                            {/*                        height: 24,*/}
                                            {/*                        width: 24,*/}
                                            {/*                        backgroundColor: "#3ea2e9",*/}
                                            {/*                        borderColor: "#3ea2e9",*/}
                                            {/*                        borderRadius: 26,*/}
                                            {/*                        display: "inline-block"*/}
                                            {/*                    }}/>*/}
                                            {/*                    <div className="Box__text" style={{verticalAlign: "top", paddingTop: 3}}>Занятия преподавателя</div>*/}
                                            {/*                </div>*/}
                                            {/*            </td>*/}
                                            {/*        </tr>*/}
                                            {/*    </tbody>*/}
                                            {/*</table>*/}


                                        </div>



                                        </React.Fragment>
                                        )}



                                        {selectChat ? (

                                            <React.Fragment>





                                                <div className="Day" style={{display: "inline-block"}}>




                                                    <div className="Chat" style={{display: "inline-block", width: 552 + 42}}>
                                                        {selectChat ? (
                                                            <React.Fragment>

                                                                <div className="Chat__room _after">
                                                                    <Baron baronId={'chat'}>

                                                                        <div style={{
                                                                            paddingTop: 56
                                                                        }}/>

                                                                        {selectChat.map((chat_message, index) => (
                                                                            <div key={index} style={{
                                                                                marginTop: 24
                                                                            }}>




                                                                                <div className="Course__info" style={{
                                                                                    verticalAlign: "top",
                                                                                    display: "inline-block",
                                                                                    padding: 16,
                                                                                    maxWidth: 440,
                                                                                    backgroundColor: (chat_message.user_type === 'student' ? "white" : "#eee"),
                                                                                    borderRadius: 7
                                                                                }}>
                                                                                    <div style={{
                                                                                        marginBottom: 2
                                                                                    }}>
                                                                                        {chat_message.user_type === 'student' ? (

                                                                                            trainingCourseData.students.map((student) => (
                                                                                                (Number(student.id) === Number(chat_message.user_id)) ? (
                                                                                                    <React.Fragment  key={student.id}>
                                                                                                        {student.student_avatar ? (
                                                                                                            <div style={{
                                                                                                                marginRight: 16,
                                                                                                                verticalAlign: "top",
                                                                                                                position: "relative",
                                                                                                                display: "inline-block"
                                                                                                            }}>
                                                                                                                <div className="Student__photo1">
                                                                                                                    {/*<svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">*/}
                                                                                                                    {/*    <image id="baseline_account_circle_black_36dp" width="42" height="42" opacity="0.1" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAABtUlEQVR4Ae3WAWQCURjA8SGEEIZDGIZDCMMQxjAMIUAIAwwQBghhOBxggBCGw2GAACGEMAwhHA4hhBC+/RHort537y5Bf36Azuu9d+/dzZm61oSHEFMs9yYIMEADZ+0WPlYQpQh9VFBYJfSxhliK8Y7cOZhCCvKTZ7ZcRJCC/cKxmZkYorBAgBCRflD6mSpjBjHYoJuy33rYqZZPmQdRaONYPYiZeaPXsIUYTE1vpnL5YtPSDSEKPkyFEIU+UqtgC1HwYOpbf3im14IojWFqAVFqINEIksETjtWFZDBAopnFdZA2qDY2kAwCJIogFsbw4Oe4ZiZIJBe0vOCA9G9aDLmQKRLNM675J1qo426vgTb8jM8LkSiAnLCDj3toa2Boe9B2DK/4A2x7MXwBNJGoeuJHr8jbFyTFyuZCrCNvzzYXtXtklv7gwDYHS8iBNW5B2T9B5qgha3Us9J8eyaonHrDGG0owVcYHtifOnhJUuYYLcokBHlE5+DNP8A0HbWSzBZqGQdmK4cIqFwtIQWZwkKsqhthBLG3hoYzCchFil3EgQ9RwtqroIMAc8cFmnWGEFiootGv/gzxqH8WJhToAAAAASUVORK5CYII="/>*/}
                                                                                                                    {/*</svg>*/}

                                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" id="account_circle-black-18dp" width="42" height="42" viewBox="0 0 100 100">
                                                                                                                        <path id="Path_1" data-name="Path 1" d="M0,0H100V100H0Z" fill="none"/>
                                                                                                                        <path opacity="0.1" id="Path_2" data-name="Path 2" d="M50,2A48,48,0,1,0,98,50,48.018,48.018,0,0,0,50,2Zm0,14.4A14.4,14.4,0,1,1,35.6,30.8,14.381,14.381,0,0,1,50,16.4Zm0,68.16A34.563,34.563,0,0,1,21.2,69.1C21.344,59.552,40.4,54.32,50,54.32c9.552,0,28.656,5.232,28.8,14.784A34.563,34.563,0,0,1,50,84.56Z" fill="#000"/>
                                                                                                                    </svg>
                                                                                                                </div>
                                                                                                                <img style={{
                                                                                                                    position: "absolute",
                                                                                                                    top: 0,
                                                                                                                    left: 0,
                                                                                                                    width: 42,
                                                                                                                    height: 42,
                                                                                                                    borderRadius: "50%",
                                                                                                                    opacity: 1
                                                                                                                }}
                                                                                                                     src={baseURL() + 'api/images/avatar/' + student.student_avatar}
                                                                                                                     width="100"
                                                                                                                     height="100"/>
                                                                                                            </div>
                                                                                                        ) : (
                                                                                                            <div style={{
                                                                                                                marginRight: 16,
                                                                                                                verticalAlign: "top",
                                                                                                                position: "relative",
                                                                                                                display: "inline-block"
                                                                                                            }}>
                                                                                                                <div className="Student__photo1">
                                                                                                                    {/*<svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">*/}
                                                                                                                    {/*    <image id="baseline_account_circle_black_36dp" width="42" height="42" opacity="0.1" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAABtUlEQVR4Ae3WAWQCURjA8SGEEIZDGIZDCMMQxjAMIUAIAwwQBghhOBxggBCGw2GAACGEMAwhHA4hhBC+/RHort537y5Bf36Azuu9d+/dzZm61oSHEFMs9yYIMEADZ+0WPlYQpQh9VFBYJfSxhliK8Y7cOZhCCvKTZ7ZcRJCC/cKxmZkYorBAgBCRflD6mSpjBjHYoJuy33rYqZZPmQdRaONYPYiZeaPXsIUYTE1vpnL5YtPSDSEKPkyFEIU+UqtgC1HwYOpbf3im14IojWFqAVFqINEIksETjtWFZDBAopnFdZA2qDY2kAwCJIogFsbw4Oe4ZiZIJBe0vOCA9G9aDLmQKRLNM675J1qo426vgTb8jM8LkSiAnLCDj3toa2Boe9B2DK/4A2x7MXwBNJGoeuJHr8jbFyTFyuZCrCNvzzYXtXtklv7gwDYHS8iBNW5B2T9B5qgha3Us9J8eyaonHrDGG0owVcYHtifOnhJUuYYLcokBHlE5+DNP8A0HbWSzBZqGQdmK4cIqFwtIQWZwkKsqhthBLG3hoYzCchFil3EgQ9RwtqroIMAc8cFmnWGEFiootGv/gzxqH8WJhToAAAAASUVORK5CYII="/>*/}
                                                                                                                    {/*</svg>*/}

                                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" id="account_circle-black-18dp" width="42" height="42" viewBox="0 0 100 100">
                                                                                                                        <path id="Path_1" data-name="Path 1" d="M0,0H100V100H0Z" fill="none"/>
                                                                                                                        <path opacity="0.1" id="Path_2" data-name="Path 2" d="M50,2A48,48,0,1,0,98,50,48.018,48.018,0,0,0,50,2Zm0,14.4A14.4,14.4,0,1,1,35.6,30.8,14.381,14.381,0,0,1,50,16.4Zm0,68.16A34.563,34.563,0,0,1,21.2,69.1C21.344,59.552,40.4,54.32,50,54.32c9.552,0,28.656,5.232,28.8,14.784A34.563,34.563,0,0,1,50,84.56Z" fill="#000"/>
                                                                                                                    </svg>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        )}

                                                                                                        <div style={{
                                                                                                            display: "inline-block",
                                                                                                            verticalAlign: "top"
                                                                                                        }}>
                                                                                                            <div className="Info__title" style={{
                                                                                                                display: "inline-block",
                                                                                                                // opacity: 0.75,
                                                                                                                fontSize: 15, fontWeight: 700,
                                                                                                                marginBottom: 6,
                                                                                                                marginRight: 16,
                                                                                                                color: "#2A3E4C"}}>
                                                                                                                {/*{chat_message.user_type === 'student' ? selectChat.student_fio.split(' ')[0] + ' ' + selectChat.student_fio.split(' ')[1] : null}*/}
                                                                                                                {chat_message.user_type === 'student' ? student.student_fio : null}
                                                                                                                {/*{chat_message.user_type === 'teacher' ? student.employee_fio : null}*/}
                                                                                                            </div>
                                                                                                            <br/>
                                                                                                            <div className="Title__date" style={{
                                                                                                                display: "inline-block",
                                                                                                                opacity: 0.75,
                                                                                                                marginBottom: 0,
                                                                                                                fontWeight: 400,
                                                                                                                fontSize: 13,
                                                                                                                color: "#2A3E4C"
                                                                                                            }}>
                                                                                                                {lastDataTime(chat_message.time)}
                                                                                                            </div>
                                                                                                        </div>

                                                                                                    </React.Fragment>
                                                                                                ) : null
                                                                                            ))
                                                                                        ) : null}

                                                                                        {chat_message.user_type === 'teacher' ? (
                                                                                            <React.Fragment>
                                                                                                {trainingCourseData.employee.employee_avatar ? (
                                                                                                    <div style={{
                                                                                                        marginRight: 16,
                                                                                                        verticalAlign: "top",
                                                                                                        position: "relative",
                                                                                                        display: "inline-block"
                                                                                                    }}>
                                                                                                        <div className="Student__photo1">
                                                                                                            {/*<svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">*/}
                                                                                                            {/*    <image id="baseline_account_circle_black_36dp" width="42" height="42" opacity="0.1" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAABtUlEQVR4Ae3WAWQCURjA8SGEEIZDGIZDCMMQxjAMIUAIAwwQBghhOBxggBCGw2GAACGEMAwhHA4hhBC+/RHort537y5Bf36Azuu9d+/dzZm61oSHEFMs9yYIMEADZ+0WPlYQpQh9VFBYJfSxhliK8Y7cOZhCCvKTZ7ZcRJCC/cKxmZkYorBAgBCRflD6mSpjBjHYoJuy33rYqZZPmQdRaONYPYiZeaPXsIUYTE1vpnL5YtPSDSEKPkyFEIU+UqtgC1HwYOpbf3im14IojWFqAVFqINEIksETjtWFZDBAopnFdZA2qDY2kAwCJIogFsbw4Oe4ZiZIJBe0vOCA9G9aDLmQKRLNM675J1qo426vgTb8jM8LkSiAnLCDj3toa2Boe9B2DK/4A2x7MXwBNJGoeuJHr8jbFyTFyuZCrCNvzzYXtXtklv7gwDYHS8iBNW5B2T9B5qgha3Us9J8eyaonHrDGG0owVcYHtifOnhJUuYYLcokBHlE5+DNP8A0HbWSzBZqGQdmK4cIqFwtIQWZwkKsqhthBLG3hoYzCchFil3EgQ9RwtqroIMAc8cFmnWGEFiootGv/gzxqH8WJhToAAAAASUVORK5CYII="/>*/}
                                                                                                            {/*</svg>*/}

                                                                                                            <svg xmlns="http://www.w3.org/2000/svg" id="account_circle-black-18dp" width="42" height="42" viewBox="0 0 100 100">
                                                                                                                <path id="Path_1" data-name="Path 1" d="M0,0H100V100H0Z" fill="none"/>
                                                                                                                <path opacity="0.1" id="Path_2" data-name="Path 2" d="M50,2A48,48,0,1,0,98,50,48.018,48.018,0,0,0,50,2Zm0,14.4A14.4,14.4,0,1,1,35.6,30.8,14.381,14.381,0,0,1,50,16.4Zm0,68.16A34.563,34.563,0,0,1,21.2,69.1C21.344,59.552,40.4,54.32,50,54.32c9.552,0,28.656,5.232,28.8,14.784A34.563,34.563,0,0,1,50,84.56Z" fill="#000"/>
                                                                                                            </svg>
                                                                                                        </div>
                                                                                                        <img style={{
                                                                                                            position: "absolute",
                                                                                                            top: 0,
                                                                                                            left: 0,
                                                                                                            width: 42,
                                                                                                            height: 42,
                                                                                                            borderRadius: "50%",
                                                                                                            opacity: 1
                                                                                                        }}
                                                                                                             src={baseURL() + 'api/images/avatar/' + trainingCourseData.employee.employee_avatar}
                                                                                                             width="100"
                                                                                                             height="100"/>
                                                                                                    </div>
                                                                                                ) : (
                                                                                                    <div style={{
                                                                                                        marginRight: 16,
                                                                                                        verticalAlign: "top",
                                                                                                        position: "relative",
                                                                                                        display: "inline-block"
                                                                                                    }}>
                                                                                                        <div className="Student__photo1">
                                                                                                            {/*<svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">*/}
                                                                                                            {/*    <image id="baseline_account_circle_black_36dp" width="42" height="42" opacity="0.1" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAABtUlEQVR4Ae3WAWQCURjA8SGEEIZDGIZDCMMQxjAMIUAIAwwQBghhOBxggBCGw2GAACGEMAwhHA4hhBC+/RHort537y5Bf36Azuu9d+/dzZm61oSHEFMs9yYIMEADZ+0WPlYQpQh9VFBYJfSxhliK8Y7cOZhCCvKTZ7ZcRJCC/cKxmZkYorBAgBCRflD6mSpjBjHYoJuy33rYqZZPmQdRaONYPYiZeaPXsIUYTE1vpnL5YtPSDSEKPkyFEIU+UqtgC1HwYOpbf3im14IojWFqAVFqINEIksETjtWFZDBAopnFdZA2qDY2kAwCJIogFsbw4Oe4ZiZIJBe0vOCA9G9aDLmQKRLNM675J1qo426vgTb8jM8LkSiAnLCDj3toa2Boe9B2DK/4A2x7MXwBNJGoeuJHr8jbFyTFyuZCrCNvzzYXtXtklv7gwDYHS8iBNW5B2T9B5qgha3Us9J8eyaonHrDGG0owVcYHtifOnhJUuYYLcokBHlE5+DNP8A0HbWSzBZqGQdmK4cIqFwtIQWZwkKsqhthBLG3hoYzCchFil3EgQ9RwtqroIMAc8cFmnWGEFiootGv/gzxqH8WJhToAAAAASUVORK5CYII="/>*/}
                                                                                                            {/*</svg>*/}

                                                                                                            <svg xmlns="http://www.w3.org/2000/svg" id="account_circle-black-18dp" width="42" height="42" viewBox="0 0 100 100">
                                                                                                                <path id="Path_1" data-name="Path 1" d="M0,0H100V100H0Z" fill="none"/>
                                                                                                                <path opacity="0.1" id="Path_2" data-name="Path 2" d="M50,2A48,48,0,1,0,98,50,48.018,48.018,0,0,0,50,2Zm0,14.4A14.4,14.4,0,1,1,35.6,30.8,14.381,14.381,0,0,1,50,16.4Zm0,68.16A34.563,34.563,0,0,1,21.2,69.1C21.344,59.552,40.4,54.32,50,54.32c9.552,0,28.656,5.232,28.8,14.784A34.563,34.563,0,0,1,50,84.56Z" fill="#000"/>
                                                                                                            </svg>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )}

                                                                                                <div style={{
                                                                                                    display: "inline-block",
                                                                                                    verticalAlign: "top"
                                                                                                }}>
                                                                                                    <div className="Info__title" style={{
                                                                                                        display: "inline-block",
                                                                                                        // opacity: 0.75,
                                                                                                        fontSize: 15, fontWeight: 700,
                                                                                                        marginBottom: 6,
                                                                                                        marginRight: 16,
                                                                                                        color: "#2A3E4C"}}>
                                                                                                        {/*{chat_message.user_type === 'student' ? selectChat.student_fio.split(' ')[0] + ' ' + selectChat.student_fio.split(' ')[1] : null}*/}
                                                                                                        {/*{chat_message.user_type === 'student' ? student.student_fio : null}*/}
                                                                                                        {chat_message.user_type === 'teacher' ? trainingCourseData.employee.employee_fio : null}
                                                                                                    </div>
                                                                                                    <br/>
                                                                                                    <div className="Title__date" style={{
                                                                                                        display: "inline-block",
                                                                                                        opacity: 0.75,
                                                                                                        marginBottom: 0,
                                                                                                        fontWeight: 400,
                                                                                                        fontSize: 13,
                                                                                                        color: "#2A3E4C"
                                                                                                    }}>
                                                                                                        {lastDataTime(chat_message.time)}
                                                                                                    </div>
                                                                                                </div>

                                                                                            </React.Fragment>
                                                                                        ) : null}


                                                                                    </div>

                                                                                    <div className="Info__text" style={{
                                                                                        marginBottom: 0,
                                                                                        opacity: 1,
                                                                                        fontFamily: "Roboto, sans-serif",
                                                                                        fontSize: 15,
                                                                                        color: "#2a3e4c",
                                                                                        paddingLeft: 42 + 16
                                                                                    }}>
                                                                                        {chat_message.message}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))}

                                                                    </Baron>
                                                                </div>



                                                                <div className="Chat__send">

                                                                    <form onSubmit={this.handleSubmit.bind(this)}>
                                                                        <input className="Field__input Input__chat"
                                                                               style={{
                                                                                   display: "inline-block",
                                                                                   backgroundColor: "#f7f7f7",
                                                                                   border: "2px solid #dadada",
                                                                                   width: 340 + 16 + 56,
                                                                                   padding: "16px 16px",
                                                                                   fontSize: 17,
                                                                                   color: "#2A3E4C",
                                                                                   fontFamily: "Roboto, sans-serif",
                                                                                   fontWeight: 400,
                                                                                   verticalAlign: "top",
                                                                                   borderRadius: 7,
                                                                                   marginRight: 24

                                                                               }}
                                                                               id="chatMessageText"
                                                                               name="chatMessageText"
                                                                               type="text"
                                                                               placeholder="Текст сообщения..."
                                                                               value={this.state.value}
                                                                               onChange={this.handleInputChange.bind(this)}/>

                                                                        <button style={{
                                                                            display: "inline-block",
                                                                            backgroundColor: "#f7f7f7",
                                                                            border: "none",
                                                                            // height: 24,
                                                                            // width: 24,
                                                                            // cursor: "pointer",
                                                                            padding: 0,
                                                                            marginBottom: 0,
                                                                            verticalAlign: "top",
                                                                            marginRight: 24
                                                                        }}>
                                                                            <div style={{
                                                                                display: "inline-block",
                                                                                // backgroundColor: "#f7f7f7",
                                                                                // border: "none",
                                                                                height: 24,
                                                                                width: 24,
                                                                                cursor: "pointer",
                                                                                padding: 16,
                                                                                verticalAlign: "top"
                                                                            }}>
                                                                                {/*<svg xmlns="http://www.w3.org/2000/svg" width="23.993" height="24" viewBox="0 0 23.993 24">*/}
                                                                                {/*    <path id="paper-plane-solid" d="M22.318.121.6,12.653a1.126,1.126,0,0,0,.1,2.025l4.982,2.09L19.145,4.9a.281.281,0,0,1,.4.389L8.258,19.045v3.773a1.124,1.124,0,0,0,1.992.74l2.976-3.623,5.839,2.446a1.128,1.128,0,0,0,1.547-.853L23.986,1.283A1.125,1.125,0,0,0,22.318.121Z" transform="translate(-0.01 0.031)" fill="#0061ff"/>*/}
                                                                                {/*</svg>*/}

                                                                                <svg aria-hidden="true" focusable="false"
                                                                                     data-prefix="fas" data-icon="paper-plane"
                                                                                     className="svg-inline--fa fa-paper-plane fa-w-16"
                                                                                     role="img" xmlns="http://www.w3.org/2000/svg"
                                                                                     viewBox="0 0 512 512">
                                                                                    <path fill="#0061ff"
                                                                                          d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z"/>
                                                                                </svg>
                                                                            </div>
                                                                        </button>

                                                                    </form>
                                                                </div>

                                                            </React.Fragment>
                                                        ) : null}

                                                    </div>


                                                </div>










                                            </React.Fragment>

                                        ) : null}

                                    </div>

                                    </div>
                                </div>
                            </div>

                            {!selectChat ? (
                                <React.Fragment>

                                    {/*{Number(trainingCourseData.lecture_hours) > 0 ? (*/}
                                    {/*    hoursCompleted = false,*/}
                                    {/*        (Number(lectureHours) < Number(trainingCourseData.lecture_hours)) ? null : hoursCompleted = true*/}
                                    {/*) : null}*/}

                                    {/*{Number(trainingCourseData.laboratory_hours) > 0 ? (*/}
                                    {/*    hoursCompleted = false,*/}
                                    {/*        (Number(laboratoryHours) < Number(trainingCourseData.laboratory_hours)) ? null : hoursCompleted = true*/}
                                    {/*) : null}*/}

                                    {/*{Number(trainingCourseData.practical_hours) > 0 ? (*/}
                                    {/*    hoursCompleted = false,*/}
                                    {/*        (Number(practicalHours) < Number(trainingCourseData.practical_hours)) ? null : hoursCompleted = true*/}
                                    {/*) : null}*/}


                                    {/*{hoursCompleted ? (*/}
                                    {/*    <div className="Section__button"*/}
                                    {/*         onClick={this.trainingCourseYes.bind(this)}>*/}
                                    {/*        <div className="button button_save"/>*/}
                                    {/*    </div>*/}
                                    {/*) : (*/}
                                    {/*    <div className="Section__button"*/}
                                    {/*         onClick={this.trainingCourseNot.bind(this)}>*/}
                                    {/*        <div className="button button_save"/>*/}
                                    {/*    </div>*/}

                                    {/*)}*/}


                                    <div className="Section__button"
                                         onClick={this.modalOpen.bind(this, 'modalTeacherCourseFinish', modalData(this.props.match.params.trainingCourseId, trainingCourseData))}>
                                        <div className="button button_save"/>
                                    </div>

                                </React.Fragment>










                            ) : null}
                        </div>
                    );
                }
            }
        }

        else return null;
    }
}

Date.prototype.getWeek = function() {
    var date = new Date(this.getTime());
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    // January 4 is always in week 1.
    var week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
        - 3 + (week1.getDay() + 6) % 7) / 7);
}

// Returns the four-digit year corresponding to the ISO week of the date.
Date.prototype.getWeekYear = function() {
    var date = new Date(this.getTime());
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    return date.getFullYear();
}

function mapStateToProps(state) {
    return {
        isLoad: state.page.isLoad,
        isUpdate: state.page.isUpdate,
        responseData: state.api.responseData,
        responseError:  state.api.responseError
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageMount: () => dispatch(pageMount()),
        pageLoad: (isLoad) => dispatch(pageLoad(isLoad)),
        pageUpdate: () => dispatch(pageUpdate()),
        alertOpen: (alertForm, alertData) => dispatch(alertOpen(alertForm, alertData)),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        notificationMessage: (message, colorMessage) => dispatch(notificationMessage(message, colorMessage)),
        modalOpen: (modalForm, modalData) => dispatch(modalOpen(modalForm, modalData))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (TeacherTrainingCourse);
