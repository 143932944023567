import React from "react";
import {connect} from "react-redux";
import {modalClose} from "../../../../../../store/actions/modalAction";
import {notificationMessage} from "../../../../../../store/actions/notificationAction";
import {apiRequest} from "../../../../../../store/actions/apiAction";
import {pageUpdate} from "../../../../../../store/actions/pageAction";
import Loading from "../../../../../loading/Loading";
import {formLoad} from "../../../../../../store/actions/formAction";
import {alertOpen} from "../../../../../../store/actions/alertAction";

class ModalTeacherTrainingCourseExamEdit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isMountForm: true,
            isOpenForm: false,
            isLoadForm: false,
            isUpdateForm: false,
            formData: null,
            isHandleSubmit: false,
            objectId: this.props.modalData.objectId,
            objectData: this.props.modalData.objectData,
            formResponseError: null,




            dayLesson: this.props.modalData.objectData.day_lesson,
            monthLesson: this.props.modalData.objectData.month_lesson,
            yearLesson: this.props.modalData.objectData.year_lesson,

            startingHour: this.props.modalData.objectData.starting_hour,
            doubleHours: (this.props.modalData.objectData.double_hours ? 1 : 0),

            lessonType: this.props.modalData.objectData.lesson_type,

            lessonTheme: (this.props.modalData.objectData.lesson_theme === 'null' ? '' : this.props.modalData.objectData.lesson_theme),
            lessonDescription: (this.props.modalData.objectData.lesson_description === 'null' ? '' : this.props.modalData.objectData.lesson_description),

            linkLecture: (this.props.modalData.objectData.link_lecture === 'null' ? '' : this.props.modalData.objectData.link_lecture),
            linkConference: (this.props.modalData.objectData.link_conference === 'null' ? '' : this.props.modalData.objectData.link_conference),
            linkTask: (this.props.modalData.objectData.link_task === 'null' ? '' : this.props.modalData.objectData.link_task),
        };
    }

    // componentDidMount() {
    //     this.formUpdate(this.state.isLoadForm);
    // }

    componentWillUnmount() {
        // console.log('From Unmounted');
        this.setState({
            isMountForm: false
        });
    }

    // componentDidUpdate() {
    //     if (this.state.isMountForm) {
    //         const responseData = this.props.responseData;
    //         // console.log(1 + ' componentDidUpdate');
    //         if (this.props.isLoadForm !== this.state.isLoadForm) {
    //             // console.log(2 + ' isLoad');
    //             if (responseData !== this.state.formData) {
    //                 // console.log(3 + ' responseData');
    //
    //                 this.formUpdate();
    //
    //                 this.setState({
    //                     isOpenForm: true,
    //                     isLoadForm: this.props.isLoadForm,
    //                     isUpdateForm: this.props.isUpdateForm,
    //                     formData: responseData
    //                 });
    //             }
    //         }
    //
    //         else if (this.props.isUpdateForm !== this.state.isUpdateForm) {
    //             // console.log(4 + ' isUpdate');
    //             this.setState({
    //                 isUpdateForm: this.props.isUpdateForm
    //             });
    //             this.formUpdate(this.state.isLoadForm);
    //         }
    //     }
    // }
    //
    // formUpdate(isLoadForm) {
    //     this.props.apiRequest('/company/' + this.state.objectId + '/supports_date', null, () => {
    //         this.props.formLoad(isLoadForm);
    //     }, null);
    // }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    // setStateAndInput(name, value) {
    //
    //     let input = document.getElementById(name);
    //     input.value = value;
    //
    //     this.setState({
    //         [name]: value
    //     });
    // }

    handleSubmit(event) {
        event.preventDefault();

        // if (this.state.lessonTheme) {

            let formData = new FormData();

            formData.append('day_lesson', this.state.dayLesson);
            formData.append('month_lesson', this.state.monthLesson);
            formData.append('year_lesson', this.state.yearLesson);

            formData.append('starting_hour', this.state.startingHour);
            formData.append('double_hours', this.state.doubleHours);

            if (this.state.lessonType) {
                formData.append('lesson_type', this.state.lessonType);
            }

            formData.append('lesson_theme', this.state.lessonTheme);
            formData.append('lesson_description', this.state.lessonDescription);

            formData.append('link_lecture', this.state.linkLecture);
            formData.append('link_conference', this.state.linkConference);
            formData.append('link_task', this.state.linkTask);


            this.setState({
                isHandleSubmit: true
            });

            this.props.apiRequest('api/cabinet/teacher/training/course/' + this.state.objectId + '/exam/' + this.state.objectData.id + '/edit', formData, () => {
                    // this.setStateAndInput('', null);
                    this.setState({
                        isHandleSubmit: false,
                        formResponseError: null
                    });
                    // this.formUpdate(this.state.isLoadForm);
                    this.props.modalClose();
                    this.props.notificationMessage(this.props.responseMessage, 'green');
                    this.props.pageUpdate();
                }, () => {
                    this.setState({
                        isHandleSubmit: false,
                        formResponseError: this.props.responseError
                    });
                }
            );

        // }
        //
        // else {
        //     this.setState({
        //         formResponseError: 'Внимательно заполните все обязательные поля ( * ).'
        //     });
        // }
    }

    modalClose() {
        this.props.modalClose();
    }

    alertOpen(alertForm, alertData) {
        this.props.alertOpen(alertForm, alertData);
    }

    render() {
        const {
            isMountForm,
            // isOpenForm,
            // formData,
            isHandleSubmit,
            formResponseError,
            anchorsData,
            dayLesson,
            monthLesson,
            yearLesson,

            startingHour,
            doubleHours,

            lessonType,

            lessonTheme,
            lessonDescription,

            linkLecture,
            linkConference,
            linkTask
        } = this.state;

        let alertData = (objectId, objectData) => {
            return {
                objectId: objectId,
                objectData: objectData
            };
        }

        if (isMountForm) {

            // {!isOpenForm ? (<Loading/>) : (
            //     formData ? () : null)}


            let month = (month) => {
                let arr =[
                    'январь',
                    'февраль',
                    'март',
                    'апрель',
                    'май',
                    'июнь',
                    'июль',
                    'август',
                    'сентябрь',
                    'октябрь',
                    'ноябрь',
                    'декабрь'
                ];

                return arr[Number(month)];
            }


            return (
                <div className="Form">
                    <form onSubmit={this.handleSubmit.bind(this)}>
                        <div className="Form__header">
                            <div className="Header__title">Экзаменационное занятие</div>
                        </div>
                        <div className="Form__content">

                            {/*<div className="Content__hr Content__hr_bottom-32"/>*/}

                            {/*<div className="Content__box">*/}
                            {/*    <div className="Form__field">*/}
                            {/*        {this.state.lessonType === 'lecture' ? 'Лекция: ' : (this.state.lessonType === 'laboratory' ? 'Лабораторная: ' : null)}*/}
                            {/*        {this.state.dayLesson} {month(this.state.monthLesson)} {this.state.yearLesson} c {this.state.startingHour}:00 до {Number(this.state.doubleHours) > 0 ? Number(this.state.startingHour) + 2 : Number(this.state.startingHour) + 1}:00*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            <div className="Box">
                                <div className="Box__title" style={{fontSize: 24, marginBottom: 6, opacity: .75}}>
                                    {this.state.lessonType === 'lecture' ? 'Теоретическое занятие' : null}
                                    {this.state.lessonType === 'laboratory' ? 'Лабораторное занятие' : null}
                                    {this.state.lessonType === 'practical' ? 'Практическое занятие' : null}
                                </div>
                            </div>

                            <div className="Content__box">
                                <div className="Box__title" style={{fontSize: 18, opacity: .75, fontWeight: 400}}>
                                    {this.state.dayLesson} {month(this.state.monthLesson)} {this.state.yearLesson} c {this.state.startingHour}:00
                                </div>
                            </div>

                            <div className="Content__hr"/>

                            {/*<div className="Content__box">*/}
                            {/*    <div className="Form__field">*/}
                            {/*        {this.state.lessonType === 'lecture' ? 'Лекция: ' : (this.state.lessonType === 'laboratory' ? 'Лабораторная: ' : null)}*/}
                            {/*        {this.state.dayLesson} {month(this.state.monthLesson)} {this.state.yearLesson} c {this.state.startingHour}:00 до {Number(this.state.doubleHours) > 0 ? Number(this.state.startingHour) + 2 : Number(this.state.startingHour) + 1}:00*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            {/*<div className="Box">*/}
                            {/*    <div className="Box__title" style={{fontSize: 24, marginBottom: 6, opacity: .75}}>*/}
                            {/*        {this.state.lessonType === 'lecture' ? 'Лекция' : (this.state.lessonType === 'laboratory' ? 'Лабораторная' : null)}*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            {/*<div className="Content__box">*/}
                            {/*    <div className="Box__title" style={{fontSize: 18, opacity: .75, fontWeight: 400}}>*/}
                            {/*        {this.state.dayLesson} {month(this.state.monthLesson)} {this.state.yearLesson} c {this.state.startingHour}:00 до {Number(this.state.doubleHours) > 0 ? Number(this.state.startingHour) + 2 : Number(this.state.startingHour) + 1}:00*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            {/*<div className="Content__hr"/>*/}

                            {/*<div className="Content__box">*/}
                            {/*    <div className="Form__field">*/}
                            {/*        <div className="Field__label">Тема урока *</div>*/}
                            {/*        <input className="Field__input"*/}
                            {/*               name="lessonTheme"*/}
                            {/*               defaultValue={lessonTheme}*/}
                            {/*               type="text"*/}
                            {/*               placeholder="Введение. Основы дисциплины."*/}
                            {/*               value={this.state.value}*/}
                            {/*               onChange={this.handleInputChange.bind(this)}/>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            <div className="Content__box">
                                <div className="Form__field">
                                    <div className="Field__label">Краткое описание</div>
                                    <textarea className="Field__input"
                                              name="lessonDescription"
                                              defaultValue={lessonDescription}
                                              rows={4}
                                              value={this.state.value}
                                              onChange={this.handleInputChange.bind(this)}/>
                                </div>
                            </div>

                            <div className="Content__hr Content__hr_bottom-32"/>

                            <div className="Content__box">
                                <div className="Form__field">
                                    <div className="Field__label">Ссылка на материал</div>
                                    <input className="Field__input"
                                           name="linkLecture"
                                           defaultValue={linkLecture}
                                           type="text"
                                           placeholder="http..."
                                           value={this.state.value}
                                           onChange={this.handleInputChange.bind(this)}/>
                                </div>
                            </div>

                            <div className="Content__box">
                                <div className="Form__field">
                                    <div className="Field__label">Ссылка на вебинар</div>
                                    <input className="Field__input"
                                           name="linkConference"
                                           defaultValue={linkConference}
                                           type="text"
                                           placeholder="http..."
                                           value={this.state.value}
                                           onChange={this.handleInputChange.bind(this)}/>
                                </div>
                            </div>

                            <div className="Content__box">
                                <div className="Form__field">
                                    <div className="Field__label">Ссылка на задание</div>
                                    <input className="Field__input"
                                           name="linkTask"
                                           defaultValue={linkTask}
                                           type="text"
                                           placeholder="http..."
                                           value={this.state.value}
                                           onChange={this.handleInputChange.bind(this)}/>
                                </div>
                            </div>



                            <div className="Content__error">
                                <span className="Error__text">{formResponseError}</span>
                            </div>
                        </div>
                        <div className="Form__footer">
                            <button className={isHandleSubmit ? 'Form__button Form__button_blue Form__button_left Form__button_disabled' : 'Form__button Form__button_blue Form__button_left'}
                                    type="submit"
                                    style={{
                                        position: "relative"
                                    }}
                                    disabled={isHandleSubmit ? 'disabled' : null}>
                                Сохранить
                                <div style={{position: "absolute", right: 6, top: 6}}>{isHandleSubmit ? <Loading white={true} /> : null}</div>
                            </button>
                        </div>
                        <div className="Form__link">
                            <div className="Link__delete" onClick={this.alertOpen.bind(this, 'alertTeacherTrainingCourseExamDelete', alertData(this.state.objectId, this.state.objectData))}>Удалить занятие</div>
                        </div>
                    </form>
                </div>
            );
        }

        else return null;
    }
}

function mapStateToProps(state) {
    return {
        isLoadForm: state.form.isLoadForm,
        isUpdateForm: state.form.isUpdateForm,
        modalData: state.modal.modalData,
        isRequest: state.api.isRequest,
        responseMessage: state.api.responseMessage,
        responseData: state.api.responseData,
        responseError: state.api.responseError
    }
}

function mapDispatchToProps(dispatch) {
    return {
        formLoad: (isLoadForm) => dispatch(formLoad(isLoadForm)),
        pageUpdate: () => dispatch(pageUpdate()),
        notificationMessage: (message, colorMessage) => dispatch(notificationMessage(message, colorMessage)),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        alertOpen: (alertForm, alertData) => dispatch(alertOpen(alertForm, alertData)),
        modalClose: () => dispatch(modalClose())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalTeacherTrainingCourseExamEdit);
