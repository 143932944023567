import React from "react";
import {connect} from "react-redux";
import {modalClose} from "../../../../../../store/actions/modalAction";
import {notificationMessage} from "../../../../../../store/actions/notificationAction";
import {apiRequest} from "../../../../../../store/actions/apiAction";
import {pageUpdate} from "../../../../../../store/actions/pageAction";
import {formLoad} from "../../../../../../store/actions/formAction";
import {alertOpen} from "../../../../../../store/actions/alertAction";
import Loading from "../../../../../loading/Loading";

class ModalTeacherCourseFinish extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isMountForm: true,
            isOpenForm: false,
            isLoadForm: false,
            isUpdateForm: false,
            formData: null,
            isHandleSubmit: false,
            objectId: this.props.modalData.objectId,
            objectData: this.props.modalData.objectData,
            formResponseError: null,

            examinationReport: [],
            dataAssessment: null
        };
    }

    componentDidMount() {
        // this.formUpdate(this.state.isLoadForm);
        this.examinationReport();
    }

    componentWillUnmount() {
        // console.log('From Unmounted');
        this.setState({
            isMountForm: false
        });
    }

    // componentDidUpdate() {
    //     if (this.state.isMountForm) {
    //         const responseData = this.props.responseData;
    //         // console.log(1 + ' componentDidUpdate');
    //         if (this.props.isLoadForm !== this.state.isLoadForm) {
    //             // console.log(2 + ' isLoad');
    //             if (responseData !== this.state.formData) {
    //                 // console.log(3 + ' responseData');
    //
    //                 this.formUpdate();
    //
    //                 this.setState({
    //                     isOpenForm: true,
    //                     isLoadForm: this.props.isLoadForm,
    //                     isUpdateForm: this.props.isUpdateForm,
    //                     formData: responseData
    //                 });
    //             }
    //         }
    //
    //         else if (this.props.isUpdateForm !== this.state.isUpdateForm) {
    //             // console.log(4 + ' isUpdate');
    //             this.setState({
    //                 isUpdateForm: this.props.isUpdateForm
    //             });
    //             this.formUpdate(this.state.isLoadForm);
    //         }
    //     }
    // }

    // formUpdate(isLoadForm) {
    //     this.props.apiRequest('/company/' + this.state.objectId + '/supports_date', null, () => {
    //         this.props.formLoad(isLoadForm);
    //     }, null);
    // }



    setStateAndInput(name, value) {

        let input = document.getElementById(name);
        input.value = value;

        this.setState({
            [name]: value
        });
    }

    examinationReport() {
        let examinationReport = [];
        let objectData = this.state.objectData;

        objectData.students.map(student => {

            let object = {
                student_id: Number(student.id),
                student_fio: student.student_fio,
                control: {
                    exam: {
                        knowledge_assessment: 1
                    },
                    diff: {
                        knowledge_assessment: 1
                    },
                    test: {
                        knowledge_assessment: 1
                    },
                    project: {
                        project_title: '',
                        knowledge_assessment: 1
                    },
                    coursework: {
                        project_title: '',
                        knowledge_assessment: 1
                    }
                }
            }

            examinationReport.push(object);
        });


        this.setState({
            examinationReport: examinationReport
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        const dateRegular = /^\d{4}-\d{2}-\d{2}$/;

        if (this.state.dataAssessment) {

            if (dateRegular.test(this.state.dataAssessment)) {

                const dataAssessment = new Date(this.state.dataAssessment).getTime() / 1000;

                if (dataAssessment) {

                    let formData = new FormData();

                    let employeeId = this.state.objectData.employee.id;
                    let examinationReport = JSON.stringify(this.state.examinationReport);

                    formData.append('employee_id', employeeId);
                    formData.append('examination_report', examinationReport);
                    formData.append('data_assessment', this.formattedData(dataAssessment));

                    // console.log(employeeId);
                    // console.log(this.state.examinationReport);
                    // console.log(this.formattedData(dataAssessment));

                        this.setState({
                            isHandleSubmit: true
                        });

                        this.props.apiRequest('api/cabinet/teacher/training/course/' + this.state.objectId + '/finish', formData, () => {
                                // this.setStateAndInput('', null);
                                this.setState({
                                    isHandleSubmit: false,
                                    formResponseError: null
                                });
                                // this.formUpdate(this.state.isLoadForm);
                                this.props.modalClose();
                                this.props.notificationMessage(this.props.responseMessage, 'green');
                                this.props.pageUpdate();
                            }, () => {
                                this.setState({
                                    isHandleSubmit: false,
                                    formResponseError: this.props.responseError
                                });
                            }
                        );
                }

                else {
                    this.setState({
                        formResponseError: 'Пожалуйста, укажите корректно месяц и день даты сдачи.'
                    });
                }
            }

            else {
                this.setState({
                    formResponseError: 'Пожалуйста, заполните в правильном формате дату сдачи (2020-12-31).'
                });
            }
        }

        else {
            this.setState({
                formResponseError: 'Пожалуйста, укажите дату сдачи.'
            });
        }
    }

    modalClose() {
        this.props.modalClose();
    }

    alertOpen(alertForm, alertData) {
        this.props.alertOpen(alertForm, alertData);
    }


    formattedData(time) {

        if (Number(time) > 0) {

            let date = new Date(time * 1000);
            let day = "0" + date.getDate();
            let month = "0" + (date.getMonth() + 1);
            let year = String(date.getFullYear());
            let hours = "0" + date.getHours();
            let minutes = "0" + date.getMinutes();
            let seconds = "0" + date.getSeconds();
            let formattedData = day.substr(-2) + '.' + month.substr(-2) + '.' + year;
            // let formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
            let formattedTime = hours.substr(-2) + ':' + minutes.substr(-2);

            return formattedData;
        }

        else {

            return null;
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleInputChangeExaminationReport(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let examinationReport = this.state.examinationReport;

        let studentId = Number(name.split('_')[0]);
        let controlType = String(name.split('_')[1]);
        let inputName = String(name.split('_')[2]);

        examinationReport.map(examinationReport => {
            if (examinationReport.student_id === studentId) {
                if (controlType === 'exam') {
                    if (inputName === 'knowledgeAssessment') {
                        examinationReport.control.exam.knowledge_assessment = Number(value);
                    }
                }
                else if (controlType === 'diff') {
                    if (inputName === 'knowledgeAssessment') {
                        examinationReport.control.diff.knowledge_assessment = Number(value);
                    }
                }
                else if (controlType === 'test') {
                    if (inputName === 'knowledgeAssessment') {
                        examinationReport.control.test.knowledge_assessment = Number(value);
                    }
                }
                else if (controlType === 'project') {
                    if (inputName === 'projectTitle') {
                        examinationReport.control.project.project_title = String(value);
                    }
                    else if (inputName === 'knowledgeAssessment') {
                        examinationReport.control.project.knowledge_assessment = Number(value);
                    }
                }
                else if (controlType === 'coursework') {
                    if (inputName === 'projectTitle') {
                        examinationReport.control.coursework.project_title = String(value);
                    }
                    else if (inputName === 'knowledgeAssessment') {
                        examinationReport.control.coursework.knowledge_assessment = Number(value);
                    }
                }
            }
        });

        this.setState({
            examinationReport: examinationReport
        });
    }

    render() {
        const { isOpenForm, isHandleSubmit, formResponseError, countNumber, countDate, formData, supportType, objectId, objectData, examinationReport } = this.state;

        let alertData = (objectId, objectData) => {
            return {
                objectId: objectId,
                objectData: objectData
            };
        }

        // Переменная для отображения поступлений если есть.
        let supportArraySelected = false;

        return (
            <div className="Form">
                <form onSubmit={this.handleSubmit.bind(this)}>
                    <div className="Form__header">
                        <div className="Header__title">Экзаменационная <br/>ведомость</div>
                    </div>
                    <div className="Form__content">

                        <div className="Content__hr Content__hr_bottom-32"/>

                        {examinationReport.length > 0 ? (
                            examinationReport.map((student, index) => (
                                <React.Fragment key={index}>


                                    <div className="Box">
                                        <div className="Box__title" style={{fontSize: 24, marginBottom: 24}}>
                                            {student.student_fio}
                                        </div>
                                    </div>


                                    {Number(objectData.control_exam) > 0 ? (
                                        <React.Fragment>

                                            <div className="Box" style={{marginBottom: 24, opacity: .75}}>
                                                <div className="Box__title" style={{
                                                    fontFamily: "Roboto, sans-serif",
                                                    fontWeight: 700,
                                                    fontSize: 20,
                                                    color: "#2A3E4C"
                                                }}>Экзамен</div>
                                            </div>



                                            <div className="Content__box">
                                                <div className="Form__field">
                                                    <div className="Field__label">Оценка (1 - Не явка)</div>

                                                    <input className="Field__input"
                                                           name={student.student_id + "_exam_knowledgeAssessment"}
                                                           type="number"
                                                           max={5}
                                                           min={1}
                                                           step={1}
                                                           defaultValue={student.control.exam.knowledge_assessment}
                                                           value={this.state.value}
                                                           onChange={this.handleInputChangeExaminationReport.bind(this)}/>
                                                </div>
                                            </div>

                                        </React.Fragment>
                                    ) : null}



                                    {Number(objectData.control_diff) > 0 ? (
                                        <React.Fragment>

                                            <div className="Box" style={{marginBottom: 24, opacity: .75}}>
                                                <div className="Box__title" style={{
                                                    fontFamily: "Roboto, sans-serif",
                                                    fontWeight: 700,
                                                    fontSize: 20,
                                                    color: "#2A3E4C"
                                                }}>Диф. зачет</div>
                                            </div>



                                            <div className="Content__box">
                                                <div className="Form__field">
                                                    <div className="Field__label">Оценка (1 - Не явка)</div>

                                                    <input className="Field__input"
                                                           name={student.student_id + "_diff_knowledgeAssessment"}
                                                           type="number"
                                                           max={5}
                                                           min={1}
                                                           step={1}
                                                           defaultValue={student.control.diff.knowledge_assessment}
                                                           value={this.state.value}
                                                           onChange={this.handleInputChangeExaminationReport.bind(this)}/>
                                                </div>
                                            </div>

                                        </React.Fragment>
                                    ) : null}


                                    {Number(objectData.control_test) > 0 ? (
                                        <React.Fragment>

                                            <div className="Box" style={{marginBottom: 24, opacity: .75}}>
                                                <div className="Box__title" style={{
                                                    fontFamily: "Roboto, sans-serif",
                                                    fontWeight: 700,
                                                    fontSize: 20,
                                                    color: "#2A3E4C"
                                                }}>Зачет</div>
                                            </div>

                                            <div className="Content__box">
                                                <div className="Form__field">
                                                    <div className="Field__label">Оценка</div>
                                                    <select className="Field__select"
                                                            name={student.student_id + "_test_knowledgeAssessment"}
                                                            value={student.control.test.knowledge_assessment}
                                                            onChange={this.handleInputChangeExaminationReport.bind(this)}>
                                                        <option value="1">Не явка</option>
                                                        <option  className="option__hr" disabled />
                                                        <option value="2">Не зачет</option>
                                                        <option  className="option__hr" disabled />
                                                        <option value="5">Зачет</option>
                                                        <option  className="option__hr" disabled />
                                                    </select>
                                                </div>
                                            </div>

                                        </React.Fragment>
                                    ) : null}


                                    {Number(objectData.control_project) > 0 ? (
                                        <React.Fragment>

                                            <div className="Box" style={{marginBottom: 24, opacity: .75}}>
                                                <div className="Box__title" style={{
                                                    fontFamily: "Roboto, sans-serif",
                                                    fontWeight: 700,
                                                    fontSize: 20,
                                                    color: "#2A3E4C"
                                                }}>Курсовой проект</div>
                                            </div>

                                            <div className="Content__box">
                                                <div className="Form__field">
                                                    <div className="Field__label">Название проекта</div>

                                                    <input className="Field__input"
                                                           name={student.student_id + "_project_projectTitle"}
                                                           type="text"
                                                           defaultValue={student.control.project.project_title}
                                                           value={this.state.value}
                                                           onChange={this.handleInputChangeExaminationReport.bind(this)}/>
                                                </div>
                                            </div>

                                            <div className="Content__box">
                                                <div className="Form__field">
                                                    <div className="Field__label">Оценка (1 - Не явка)</div>

                                                    <input className="Field__input"
                                                           name={student.student_id + "_project_knowledgeAssessment"}
                                                           type="number"
                                                           max={5}
                                                           min={1}
                                                           step={1}
                                                           defaultValue={student.control.project.knowledge_assessment}
                                                           value={this.state.value}
                                                           onChange={this.handleInputChangeExaminationReport.bind(this)}/>
                                                </div>
                                            </div>

                                        </React.Fragment>
                                    ) : null}



                                    {Number(objectData.control_coursework) > 0 ? (
                                        <React.Fragment>

                                            <div className="Box" style={{marginBottom: 24, opacity: .75}}>
                                                <div className="Box__title" style={{
                                                    fontFamily: "Roboto, sans-serif",
                                                    fontWeight: 700,
                                                    fontSize: 20,
                                                    color: "#2A3E4C"
                                                }}>Курсовая работа</div>
                                            </div>

                                            <div className="Content__box">
                                                <div className="Form__field">
                                                    <div className="Field__label">Название проекта</div>

                                                    <input className="Field__input"
                                                           name={student.student_id + "_coursework_projectTitle"}
                                                           type="text"
                                                           defaultValue={student.control.coursework.project_title}
                                                           value={this.state.value}
                                                           onChange={this.handleInputChangeExaminationReport.bind(this)}/>
                                                </div>
                                            </div>

                                            <div className="Content__box">
                                                <div className="Form__field">
                                                    <div className="Field__label">Оценка (1 - Не явка)</div>

                                                    <input className="Field__input"
                                                           name={student.student_id + "_coursework_knowledgeAssessment"}
                                                           type="number"
                                                           max={5}
                                                           min={1}
                                                           step={1}
                                                           defaultValue={student.control.coursework.knowledge_assessment}
                                                           value={this.state.value}
                                                           onChange={this.handleInputChangeExaminationReport.bind(this)}/>
                                                </div>
                                            </div>

                                        </React.Fragment>
                                    ) : null}



                                    <div className="Content__hr Content__hr_bottom-32"/>
                                </React.Fragment>
                            ))
                            ) : null}

                        {/*<div className="Content__box">*/}
                        {/*    <div className="Box__label">Поддержка</div>*/}
                        {/*    <select className="Box__select" name="supportType" value={supportType} onChange={this.handleInputChange.bind(this)}>*/}
                        {/*        <option value={1}>Адресная поддрежка</option>*/}
                        {/*        <option value={2}>Поддержка занятости</option>*/}
                        {/*        <option value={3}>Системные меры</option>*/}
                        {/*    </select>*/}
                        {/*</div>*/}

                        {/*<div className="Content__box">*/}
                        {/*    <div className="Box__counts">*/}
                        {/*        {!isOpenForm ? (<Loading/>) : (*/}
                        {/*            formData ? (*/}
                        {/*                formData.length > 0 ? (*/}

                        {/*                    <React.Fragment>*/}

                        {/*                        {formData.length > 0 ? (*/}
                        {/*                            formData.map((support) => (*/}
                        {/*                                Number(support.support_name_id) === Number(supportType) ? supportArraySelected = true : false*/}
                        {/*                            ))*/}
                        {/*                        ) : null}*/}

                        {/*                        {supportArraySelected ? (*/}
                        {/*                            <table className="Counts__table">*/}
                        {/*                                <thead>*/}
                        {/*                                <tr className="Table__tr">*/}
                        {/*                                    <th className="Table__th">*/}
                        {/*                                        <div className="Th__title">Дата поступлений</div>*/}
                        {/*                                        <div className="Th__count">Сумма</div>*/}
                        {/*                                    </th>*/}
                        {/*                                </tr>*/}
                        {/*                                </thead>*/}
                        {/*                                <tbody>*/}

                        {/*                                {formData.map(support => (*/}
                        {/*                                    (Number(support.support_name_id) === Number(supportType) ? (*/}
                        {/*                                        <tr className="Table__tr" key={support.support_id}>*/}
                        {/*                                            <td className="Table__td">*/}
                        {/*                                                <div className="Link">*/}
                        {/*                                                    <div className="Program__name">{getDateMonthYear(support.support_date)}</div>*/}
                        {/*                                                    <div className="Program__count">{Number(support.support_quant)}</div>*/}
                        {/*                                                    <div className="Program__delete"*/}
                        {/*                                                         onClick={this.alertOpen.bind(this, 'alertCompanySupportDelete', alertData(this.state.objectId, null, support.support_id))}>Удалить</div>*/}
                        {/*                                                </div>*/}
                        {/*                                            </td>*/}
                        {/*                                        </tr>*/}
                        {/*                                    ) : null)*/}
                        {/*                                ))}*/}

                        {/*                                </tbody>*/}
                        {/*                            </table>*/}
                        {/*                        ) : (<div className='Box__text Box__text_center Box__text_disabled'>Поддержка не содержит записи о поступлениях</div>)}*/}

                        {/*                    </React.Fragment>*/}

                        {/*                ) : (<div className='Box__text Box__text_center Box__text_disabled'>Поддержка не содержит записи о поступлениях</div>)*/}
                        {/*            ) : (<div className='Box__text Box__text_center Box__text_disabled'>Поддержка не содержит записи о поступлениях</div>)*/}
                        {/*        )}*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        {/*<hr className="Content__hr" />*/}

                        {/*<div className="Content__box">*/}
                        {/*    <div className="Box__label">Сумма</div>*/}
                        {/*    <input className="Box__input"*/}
                        {/*           id="countNumber"*/}
                        {/*           name="countNumber"*/}
                        {/*           type="number"*/}
                        {/*           min={0}*/}
                        {/*           max={1000000000}*/}
                        {/*           defaultValue={countNumber}*/}
                        {/*           value={this.state.value}*/}
                        {/*           onChange={this.handleInputChange.bind(this)} />*/}
                        {/*</div>*/}

                        {/*<div className="Content__box">*/}
                        {/*    <div className="Box__label">Дата поступлений</div>*/}
                        {/*    <input className="Box__input"*/}
                        {/*           name="countDate"*/}
                        {/*           type="date"*/}
                        {/*           defaultValue={countDate}*/}
                        {/*           value={this.state.value}*/}
                        {/*           onChange={this.handleInputChange.bind(this)} />*/}
                        {/*</div>*/}




                        <div className="Content__box">
                            <div className="Form__field">
                                <div className="Field__label">Дата сдачи</div>
                                <input className="Field__input"
                                       name="dataAssessment"
                                       type="date"
                                    // placeholder="дд.мм.гггг"
                                       value={this.state.value}
                                       onChange={this.handleInputChange.bind(this)}/>
                            </div>
                        </div>

                        <div className="Content__error">
                            <span className="Error__text">{formResponseError}</span>
                        </div>
                    </div>
                    <div className="Form__footer">
                        <button className={isHandleSubmit ? 'Form__button Form__button_blue Form__button_left Form__button_disabled' : 'Form__button Form__button_blue Form__button_left'}
                                type="submit"
                                style={{
                                    position: "relative"
                                }}
                                disabled={isHandleSubmit ? 'disabled' : null}>
                            Закрыть курс
                            <div style={{position: "absolute", right: 6, top: 6}}>{isHandleSubmit ? <Loading white={true} /> : null}</div>
                        </button>
                    </div>
                </form>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        isLoadForm: state.form.isLoadForm,
        isUpdateForm: state.form.isUpdateForm,
        modalData: state.modal.modalData,
        isRequest: state.api.isRequest,
        responseMessage: state.api.responseMessage,
        responseData: state.api.responseData,
        responseError: state.api.responseError
    }
}

function mapDispatchToProps(dispatch) {
    return {
        formLoad: (isLoadForm) => dispatch(formLoad(isLoadForm)),
        pageUpdate: () => dispatch(pageUpdate()),
        notificationMessage: (message, colorMessage) => dispatch(notificationMessage(message, colorMessage)),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        alertOpen: (alertForm, alertData) => dispatch(alertOpen(alertForm, alertData)),
        modalClose: () => dispatch(modalClose())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalTeacherCourseFinish);
