import React from "react";
import {connect} from "react-redux";
import {modalClose} from "../../../../store/actions/modalAction";
import {notificationMessage} from "../../../../store/actions/notificationAction";
import {apiRequest} from "../../../../store/actions/apiAction";
import {pageUpdate} from "../../../../store/actions/pageAction";
import Loading from "../../../loading/Loading";
import {formLoad, formUpdate} from "../../../../store/actions/formAction";
import {alertOpen} from "../../../../store/actions/alertAction";
import baseURL from "../../../../library/baseURL";

class ModalStudent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isMountForm: true,
            isOpenForm: false,
            isLoadForm: false,
            isUpdateForm: false,
            formData: null,
            isHandleSubmit: false,
            objectId: this.props.modalData.objectId,
            objectData: this.props.modalData.objectData,
            formResponseError: null,
            formMenu: 'data',
            studentFio: null,
            studentBirthday: null,
            studentTelephone: null,
            studentEmail: null,
            periodOfStudy: null,
            educationalInstitution: null,
            receivedSpecialty: null,

            isUpload: false,



            calendarMonth: new Date().getMonth(), // месяцы в JS идут от 0 до 11, а не от 1 до 12
            calendarYear: new Date().getFullYear(),
            // calendarDay: new Date().getDate(),
            selectedDay: new Date().getDate(),
            selectedMonth: new Date().getMonth(),
            selectedYear: new Date().getFullYear(),
        };

        this.avatarInput = React.createRef();
    }

    componentDidMount() {
        this.formUpdate(this.state.isLoadForm);
    }

    componentWillUnmount() {
        // console.log('From Unmounted');
        this.setState({
            isMountForm: false
        });
    }

    componentDidUpdate() {
        if (this.state.isMountForm) {
            const responseData = this.props.responseData;
            // console.log(1 + ' componentDidUpdate');
            if (this.props.isLoadForm !== this.state.isLoadForm) {
                // console.log(2 + ' isLoad');
                if (responseData !== this.state.formData) {
                    // console.log(3 + ' responseData');

                    this.formUpdate();

                    this.setState({
                        isOpenForm: true,
                        isLoadForm: this.props.isLoadForm,
                        isUpdateForm: this.props.isUpdateForm,
                        formData: responseData,
                    }, () => {

                        this.setState({
                            studentFio: this.state.formData.student_fio,
                            studentBirthday: this.state.formData.student_birthday,
                            studentTelephone: (this.state.formData.student_telephone ? this.state.formData.student_telephone : ''),
                            studentEmail: this.state.formData.student_email,
                            periodOfStudy: (this.state.formData.period_of_study ? this.state.formData.period_of_study : ''),
                            educationalInstitution: (this.state.formData.educational_institution ? this.state.formData.educational_institution : ''),
                            receivedSpecialty: (this.state.formData.received_specialty ? this.state.formData.received_specialty : '')
                        });
                    });
                }
            }

            else if (this.props.isUpdateForm !== this.state.isUpdateForm) {
                // console.log(4 + ' isUpdate');
                this.setState({
                    isUpdateForm: this.props.isUpdateForm
                });
                this.formUpdate(this.state.isLoadForm);
            }
        }
    }

    formUpdate(isLoadForm) {
        this.props.apiRequest('/api/cabinet/student/' + this.state.objectId, null, () => {
            this.props.formLoad(isLoadForm);
        }, null);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    setStateAndInput(name, value) {

        let input = document.getElementById(name);
        input.value = value;

        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        if (this.state.studentFio && this.state.studentBirthday && this.state.studentEmail) {

            let formData = new FormData();

            formData.append('student_email', this.state.studentEmail);
            formData.append('student_fio', this.state.studentFio);
            formData.append('student_birthday', this.state.studentBirthday);

            formData.append('student_telephone', this.state.studentTelephone);
            formData.append('period_of_study', this.state.periodOfStudy);
            formData.append('educational_institution', this.state.educationalInstitution);
            formData.append('received_specialty', this.state.receivedSpecialty);

            this.setState({
                isHandleSubmit: true
            });

            this.props.apiRequest('api/cabinet/student/' + this.state.objectId + '/edit', formData, () => {
                    // this.setStateAndInput('', null);
                    this.setState({
                        isHandleSubmit: false,
                        formResponseError: null
                    });
                    // this.formUpdate(this.state.isLoadForm);
                    this.props.notificationMessage(this.props.responseMessage, 'green');
                    this.props.formUpdate();
                    this.props.pageUpdate();
                }, () => {
                    this.setState({
                        isHandleSubmit: false,
                        formResponseError: this.props.responseError
                    });
                }
            );
        }

        else {
            this.setState({
                formResponseError: 'Внимательно заполните все обязательные поля ( * ).'
            });
        }
    }

    handleAvatarChange() {
        let files = this.avatarInput.current.files;
        this.uploadFiles(files);
    }

    uploadFiles(files) {
        let formData = new FormData();

        let replay = 0;
        let size = 0;
        let type = 0;

        if (files.length > 0) {

            for (let i = 0, file; i < files.length; i++) {
                file = files.item(i);
                formData.append('avatar', file);
                replay++;

                if (file.size > 8388608) {
                    size++;
                }

                if (file.type === 'image/png' // PNG
                    || file.type === 'image/jpeg' // JPG
                ) {
                    type++;
                }
            }

            if (type === files.length) {

                if (replay < 2) {

                    if (size > 0) {
                        this.props.notificationMessage('Максимальный размер фотографии не более 8 Мб.', 'red');
                    }

                    else {

                        this.setState({
                            isUpload: true
                        });

                        this.props.apiRequest('api/cabinet/student/' + this.state.objectId + '/avatar/upload', formData, () => {
                                this.setState({
                                    isUpload: false
                                });
                                this.props.notificationMessage(this.props.responseMessage, 'green');
                                this.props.pageUpdate();
                                this.props.formUpdate();
                            }, () => {
                                this.setState({
                                    isUpload: false
                                });
                                this.props.notificationMessage(this.props.responseError, 'red');
                            }
                        );
                    }
                }

                else {
                    this.props.notificationMessage('За раз можно загрузить не более 1 фотографии.', 'red');
                }
            }

            else {
                this.props.notificationMessage('Возможна загрузка фотографии формата PNG и JPG(JPEG).', 'red');
            }
        }
    }

    modalClose() {
        this.props.modalClose();
    }

    alertOpen(alertForm, alertData) {
        this.props.alertOpen(alertForm, alertData);
    }

    formMenu(formMenu) {
        this.setState({
            formMenu: formMenu
        });
    }














    firstDayOfMonth(date) {
        // Получить номер дня недели, от 0 (пн) до 6 (вс).
        let day = new Date(date).getDay();
        // Сделать воскресенье (0) последним днем.
        if (day === 0) day = 7;
        return day - 1;
    }

    dayIsToday() {
        return new Date().getDate();
    }

    monthIsToday() {
        return new Date().getMonth();
    }

    yearIsToday() {
        return new Date().getFullYear();
    }

    clickDay(month, day, year) {
        this.setState({
            selectedMonth: month,
            selectedDay: day,
            selectedYear: year,

            selectTypeHours: null,
            isTypeHours: false
        }, () => {
            this.props.formUpdate();
        });
    }

    monthPlus() {
        // console.log(this.state.calendarMonth);
        if (this.state.calendarMonth < 11) {

            this.setState({
                calendarMonth: this.state.calendarMonth + 1,

                selectTypeHours: null,
                isTypeHours: false
            });
        }

        else {
            this.setState({
                calendarMonth: 0,
                calendarYear: this.state.calendarYear + 1,

                selectTypeHours: null,
                isTypeHours: false
            });
        }
    }

    monthMines() {
        // console.log(this.state.calendarMonth);
        if (this.state.calendarMonth > 0) {

            this.setState({
                calendarMonth: this.state.calendarMonth - 1,

                selectTypeHours: null,
                isTypeHours: false
            });
        }

        else {
            this.setState({
                calendarMonth: 11,
                calendarYear: this.state.calendarYear - 1,

                selectTypeHours: null,
                isTypeHours: false
            });
        }
    }





    clipboard(clipboard) {
        navigator.clipboard.writeText(clipboard)
            .then(() => {
                this.props.notificationMessage('Текст скопирован.', 'green');
            })
            .catch(err => {
                console.log('Something went wrong', err);
            });
    }









    render() {
        const {
            isMountForm,
            isOpenForm,
            formMenu,
            formData,
            isHandleSubmit,
            formResponseError,
            isUpload } = this.state;

        let alertData = (objectId, objectData) => {
            return {
                objectId: objectId,
                objectData: objectData
            };
        }

        let groupName = (groupName, initialYear) => {
            let course;

            let date = new Date();
            course = (date.getFullYear() - Number(initialYear) + 1);

            return groupName.replace(/\$/i, course);
        }

        let telephone = (telephone) => {

            telephone.split('');

            return '+7 (' +
                telephone[1] +
                telephone[2] +
                telephone[3] +
                ') ' +
                telephone[4] +
                telephone[5] +
                telephone[6] +
                '-' +
                telephone[7] +
                telephone[8] +
                telephone[9] +
                telephone[10];
        }

        let periodOfStudy = (periodOfStudy) => {

            return periodOfStudy.split('-')[0] + ' - ' + periodOfStudy.split('-')[1];
        }







        if (isMountForm) {

            if (!isOpenForm || !formData) {

                return (
                    <div className="Form__section" style={{width: 460}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="460" height="739" viewBox="0 0 460 739">
                            <g id="Group_1665" data-name="Group 1665" transform="translate(-3632 -1229)">
                                <g id="account_circle-black-18dp" transform="translate(3632 1229)">
                                    <path id="Path_1" data-name="Path 1" d="M0,0H100V100H0Z" fill="none"/>
                                    <path id="Path_2" data-name="Path 2" d="M50,2A48,48,0,1,0,98,50,48.018,48.018,0,0,0,50,2Zm0,14.4A14.4,14.4,0,1,1,35.6,30.8,14.381,14.381,0,0,1,50,16.4Zm0,68.16A34.563,34.563,0,0,1,21.2,69.1C21.344,59.552,40.4,54.32,50,54.32c9.552,0,28.656,5.232,28.8,14.784A34.563,34.563,0,0,1,50,84.56Z" fill="#e9ebed"/>
                                </g>
                                <rect id="Rectangle_2054" data-name="Rectangle 2054" width="159" height="24" rx="12" transform="translate(3632 1356)" fill="#2a3e4c" opacity="0.1"/>
                                <rect id="Rectangle_2055" data-name="Rectangle 2055" width="80" height="24" rx="12" transform="translate(3632 1394)" fill="#2a3e4c" opacity="0.1"/>
                                <rect id="Rectangle_2056" data-name="Rectangle 2056" width="147" height="24" rx="12" transform="translate(3720 1394)" fill="#2a3e4c" opacity="0.1"/>
                                <rect id="Rectangle_2057" data-name="Rectangle 2057" width="184" height="14" rx="7" transform="translate(3632 1442)" fill="#2a3e4c" opacity="0.1"/>
                                <rect id="Rectangle_2058" data-name="Rectangle 2058" width="76" height="16" rx="8" transform="translate(3632 1504)" fill="#959fa6" opacity="0.1"/>
                                <rect id="Rectangle_2059" data-name="Rectangle 2059" width="92" height="16" rx="8" transform="translate(3744 1504)" fill="#959fa6" opacity="0.1"/>
                                <rect id="Rectangle_2060" data-name="Rectangle 2060" width="100" height="16" rx="8" transform="translate(3872 1504)" fill="#959fa6" opacity="0.1"/>
                                <rect id="Rectangle_2061" data-name="Rectangle 2061" width="460" height="2" transform="translate(3632 1536)" fill="#f7f7f7"/>
                                <rect id="Rectangle_2069" data-name="Rectangle 2069" width="460" height="2" transform="translate(3632 1844)" fill="#f7f7f7"/>
                                <rect id="Rectangle_2062" data-name="Rectangle 2062" width="152" height="16" rx="8" transform="translate(3632 1566)" fill="#2a3e4c" opacity="0.1"/>
                                <rect id="Rectangle_2063" data-name="Rectangle 2063" width="184" height="14" rx="7" transform="translate(3632 1646)" fill="#2a3e4c" opacity="0.1"/>
                                <rect id="Rectangle_2064" data-name="Rectangle 2064" width="118" height="16" rx="8" transform="translate(3632 1612)" fill="#959fa6" opacity="0.1"/>
                                <rect id="Rectangle_2065" data-name="Rectangle 2065" width="118" height="14" rx="7" transform="translate(3632 1724)" fill="#2a3e4c" opacity="0.1"/>
                                <rect id="Rectangle_2066" data-name="Rectangle 2066" width="144" height="16" rx="8" transform="translate(3632 1690)" fill="#959fa6" opacity="0.1"/>
                                <rect id="Rectangle_2067" data-name="Rectangle 2067" width="184" height="14" rx="7" transform="translate(3632 1802)" fill="#2a3e4c" opacity="0.1"/>
                                <rect id="Rectangle_2068" data-name="Rectangle 2068" width="85" height="16" rx="8" transform="translate(3632 1768)" fill="#959fa6" opacity="0.1"/>
                                <rect id="Rectangle_2070" data-name="Rectangle 2070" width="152" height="16" rx="8" transform="translate(3632 1874)" fill="#2a3e4c" opacity="0.1"/>
                                <rect id="Rectangle_2071" data-name="Rectangle 2071" width="184" height="14" rx="7" transform="translate(3632 1954)" fill="#2a3e4c" opacity="0.1"/>
                                <rect id="Rectangle_2072" data-name="Rectangle 2072" width="118" height="16" rx="8" transform="translate(3632 1920)" fill="#959fa6" opacity="0.1"/>
                            </g>
                        </svg>
                    </div>
                );
            }

            else {














        // let projectsData = calendarData.project;
        // let eventsData = calendarData.event;
        // let problemsData = calendarData.problem;

        let projectsData = [];
        let eventsData = [];
        let problemsData = [];

        let timeYear = (time) => {
            const dateMonthYear = new Date(time * 1000);

            let yyyy = dateMonthYear.getFullYear();
            if (yyyy < 10) yyyy = '0' + yyyy;

            return yyyy;
        }

        let timeDay = (time) => {
            const dateMonthYear = new Date(time * 1000);

            let dd = dateMonthYear.getDate();
            if (dd < 10) dd = '0' + dd;

            return dd;
        }

        let timeMonth = (time) => {
            const dateMonthYear = new Date(time * 1000);

            return dateMonthYear.toLocaleString('ru', {month: 'long'});
        }

        let weekDay = (time) => {
            const dateMonthYear = new Date(time * 1000);

            // let weekDays = ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'];
            let weekDays = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
            let weekDay = dateMonthYear.getDay();

            return weekDays[weekDay];
        }

        // Переменные выбранного дня на календаре.
        let dateTimeEnd = Number(new Date(this.state.selectedYear + '-' + (this.state.selectedMonth + 1) + '-' + this.state.selectedDay).setHours(0, 0, 0, 0) / 1000 + 86399);
        let dateTimeStart = Number(dateTimeEnd) - 86399;

        // Переменная даты построения календаря.
        let date = new Date(this.state.calendarYear, this.state.calendarMonth);

        // Пустая область в начале календаря.
        let blanksFirst = [];
        for (let i = 0; i < this.firstDayOfMonth(date); i++) {
            blanksFirst.push(<td className="Calendar__td" key={'td' + i}>
                <div className="Td__text Td__text_default"/>
            </td>);
        }

        let weekDayNameNumber = (month, day, year) => {

            let weekNumber = new Date(Number(year), Number(month), Number(day)).getDay()

            switch (weekNumber) {
                case 1: return "week_day_mon";
                case 2: return "week_day_tue";
                case 3: return "week_day_wed";
                case 4: return "week_day_thu";
                case 5: return "week_day_fri";
                case 6: return "week_day_sat";
                case 0: return "week_day_sun";
                default: return null;
            }
        }

        let weekDayDefault = true;

        // Переменные маркеров.
        let markerRed = false;
        let markerYellow = false;
        let markerGray = false;

        let markerLecture = false;
        let markerLaboratory = false;
        let markerPractical = false;
        let markerActivity = false;

        // Календарные дни.
        let daysInMonth = [];
        while (date.getMonth() === this.state.calendarMonth) {
            let today = (date.getDate() === this.dayIsToday() && date.getMonth() === this.monthIsToday() && date.getFullYear() === this.yearIsToday() ? "today" : null);
            let selected = (date.getDate() === this.state.selectedDay && date.getMonth() === this.state.selectedMonth && date.getFullYear() === this.state.selectedYear ? "selected" : null);

            daysInMonth.push(
                <td className={'Calendar__td ' + selected} key={date.getDate()}>

                    {/*{studentTrainingCoursesData.training_schedule.schedule_timetable.map(timetable => (*/}
                    {/*    Number(timetable[weekDayNameNumber(date.getMonth(), date.getDate(), date.getFullYear())]) > 0 ? weekDayDefault = false : null*/}
                    {/*))}*/}

                    <div className={
                        // Number(studentTrainingCoursesData.training_schedule.schedule_periods[0]["type_week_" + this.currentWeekYear(date.getMonth(), date.getDate(), date.getFullYear())]) > 0 ?
                        // !weekDayDefault ? 'Td__text ' + today : 'Td__text Td__text_default Td__text_pointer ' + today
                        'Td__text ' + today
                            // : 'Td__text Td__text_default Td__text_pointer ' + today
                    }
                         onClick={
                             // Number(trainingCourseData.training_schedule.schedule_period["type_week_" + this.currentWeekYear(date.getMonth(), date.getDate(), date.getFullYear())]) > 0 ?
                             //     !weekDayDefault ? this.clickDay.bind(this, date.getMonth(), date.getDate(), date.getFullYear()) : null
                             //     : null

                             this.clickDay.bind(this, date.getMonth(), date.getDate(), date.getFullYear())
                         }>
                        <div className="Text__day">
                            {date.getDate()}
                        </div>
                        {/*{this.currentWeekYear(date.getMonth(), date.getDate(), date.getFullYear()) !== 0 ? (*/}
                        {/*    this.currentWeekYear(date.getMonth(), date.getDate(), date.getFullYear()) !== 53 ? (*/}
                        {/*        <div className="Text__week">*/}
                        {/*            {this.currentWeekYear(date.getMonth(), date.getDate(), date.getFullYear())}*/}
                        {/*        </div>) : null*/}
                        {/*) : null}*/}


                        {/*<div className="Text__week" style={{bottom: 0}}>*/}
                        {/*    {date.getWeek()}*/}
                        {/*</div>*/}



                        {markerLecture = false}
                        {markerLaboratory = false}
                        {markerPractical = false}
                        {markerActivity = false}

                        {/*{studentTrainingCoursesData.training_course.map(training_course => (*/}
                        {/*    training_course.course_schedule.map(course_schedule => (*/}
                        {/*        ((date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear() + '/lecture') === (course_schedule.day_lesson + '.' + course_schedule.month_lesson + '.' + course_schedule.year_lesson + '/' + course_schedule.lesson_type)) ? (*/}
                        {/*            markerLecture = true*/}
                        {/*        ) : null*/}
                        {/*    ))*/}
                        {/*))}*/}

                        {/*{studentTrainingCoursesData.training_course.map(training_course => (*/}
                        {/*    training_course.course_schedule.map(course_schedule => (*/}
                        {/*        ((date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear() + '/laboratory') === (course_schedule.day_lesson + '.' + course_schedule.month_lesson + '.' + course_schedule.year_lesson + '/' + course_schedule.lesson_type)) ? (*/}
                        {/*            markerLaboratory = true*/}
                        {/*        ) : null*/}
                        {/*    ))*/}
                        {/*))}*/}

                        {/*{studentTrainingCoursesData.training_course.map(training_course => (*/}
                        {/*    training_course.course_schedule.map(course_schedule => (*/}
                        {/*        ((date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear() + '/practical') === (course_schedule.day_lesson + '.' + course_schedule.month_lesson + '.' + course_schedule.year_lesson + '/' + course_schedule.lesson_type)) ? (*/}
                        {/*            markerPractical = true*/}
                        {/*        ) : null*/}
                        {/*    ))*/}
                        {/*))}*/}


                        {formData.activity_student.map(activity => (
                            Number(new Date(this.state.calendarYear + '-' + (this.state.calendarMonth + 1) + '-' + date.getDate()).setHours(0, 0, 0, 0) / 1000) <= activity.time ? (
                                activity.time <= Number(new Date(this.state.calendarYear + '-' + (this.state.calendarMonth + 1) + '-' + date.getDate()).setHours(0, 0, 0, 0) / 1000 + 86399) ? (
                                    markerActivity = true
                                ) : null
                            ) : null
                        ))}

                        {formData.activity_student.map(activity => (
                            Number(new Date(this.state.calendarYear + '-' + (this.state.calendarMonth + 1) + '-' + date.getDate()).setHours(0, 0, 0, 0) / 1000) <= activity.end_time_connection ? (
                                activity.end_time_connection <= Number(new Date(this.state.calendarYear + '-' + (this.state.calendarMonth + 1) + '-' + date.getDate()).setHours(0, 0, 0, 0) / 1000 + 86399) ? (
                                    markerActivity = true
                                ) : null
                            ) : null
                        ))}

                        <div className="Box__markers">
                            {markerActivity ? (<div className={'Box__marker Box__marker_blue'}/>) : null}
                            {markerLecture ? (<div className={'Box__marker Box__marker_yellow'}/>) : null}
                            {markerLaboratory ? (<div className={'Box__marker Box__marker_green'}/>) : null}
                            {markerPractical ? (<div className={'Box__marker Box__marker_purple'}/>) : null}
                        </div>


                    </div>
                    {!weekDayDefault ?  weekDayDefault = true : null}

                    {/*{calendarData.length > 0 ? (*/}
                    {/*    calendarData.map((event, index) => (*/}
                    {/*         Number(new Date(this.state.calendarYear + '-' + (this.state.calendarMonth + 1) + '-' + date.getDate()).setHours(0, 0, 0, 0) / 1000) <= event.events_date_start ? (*/}
                    {/*            event.events_date_start <= Number(new Date(this.state.calendarYear + '-' + (this.state.calendarMonth + 1) + '-' + date.getDate()).setHours(0, 0, 0, 0) / 1000 + 86399) ? (*/}
                    {/*                <div key={index + 'key'} className={'Box__marker Box__marker_red'}/>*/}
                    {/*            ) : null*/}
                    {/*        ) : null*/}
                    {/*    ))) : null*/}
                    {/*}*/}

                    {markerRed = false}
                    {markerYellow = false}
                    {markerGray = false}

                    {projectsData.map(project => (
                        Number(new Date(this.state.calendarYear + '-' + (this.state.calendarMonth + 1) + '-' + date.getDate()).setHours(0, 0, 0, 0) / 1000) <= project.project_date_start ? (
                            project.project_date_start <= Number(new Date(this.state.calendarYear + '-' + (this.state.calendarMonth + 1) + '-' + date.getDate()).setHours(0, 0, 0, 0) / 1000 + 86399) ? (
                                markerRed = true
                            ) : null
                        ) : null
                    ))}

                    {eventsData.map(event => (
                        Number(new Date(this.state.calendarYear + '-' + (this.state.calendarMonth + 1) + '-' + date.getDate()).setHours(0, 0, 0, 0) / 1000) <= event.events_date_start ? (
                            event.events_date_start <= Number(new Date(this.state.calendarYear + '-' + (this.state.calendarMonth + 1) + '-' + date.getDate()).setHours(0, 0, 0, 0) / 1000 + 86399) ? (
                                markerRed = true
                            ) : null
                        ) : null
                    ))}

                    {problemsData.map(problem => (
                        Number(new Date(this.state.calendarYear + '-' + (this.state.calendarMonth + 1) + '-' + date.getDate()).setHours(0, 0, 0, 0) / 1000) <= problem.problem_date ? (
                            problem.problem_date <= Number(new Date(this.state.calendarYear + '-' + (this.state.calendarMonth + 1) + '-' + date.getDate()).setHours(0, 0, 0, 0) / 1000 + 86399) ? (
                                markerYellow = true
                            ) : null
                        ) : null
                    ))}





                    {/*{selectedCurrent ? (*/}
                    {/*    projectsData.map(project => (*/}
                    {/*        Number(new Date(this.state.calendarYear + '-' + (this.state.calendarMonth + 1) + '-' + date.getDate()).setHours(0, 0, 0, 0) / 1000) > project.project_date_start ? (*/}
                    {/*            project.project_date_end >= Number(new Date(this.state.calendarYear + '-' + (this.state.calendarMonth + 1) + '-' + date.getDate()).setHours(0, 0, 0, 0) / 1000) ? (*/}
                    {/*                markerGray = true*/}
                    {/*            ) : null*/}
                    {/*        ) : null*/}
                    {/*    )),*/}
                    {/*        eventsData.map(event => (*/}
                    {/*            Number(new Date(this.state.calendarYear + '-' + (this.state.calendarMonth + 1) + '-' + date.getDate()).setHours(0, 0, 0, 0) / 1000) > event.events_date_start ? (*/}
                    {/*                event.events_date_end >= Number(new Date(this.state.calendarYear + '-' + (this.state.calendarMonth + 1) + '-' + date.getDate()).setHours(0, 0, 0, 0) / 1000) ? (*/}
                    {/*                    markerGray = true*/}
                    {/*                ) : null*/}
                    {/*            ) : null*/}
                    {/*        ))*/}
                    {/*) : null}*/}

                    {markerRed ? (<div className={'Box__marker Box__marker_red'}/>) : null}
                    {/*{markerRed ? null : (markerYellow ? <div className={'Box__marker Box__marker_yellow'}/> : null)}*/}
                    {/*{markerRed ? null : (markerYellow ? null : (markerGray ? <div className={'Box__marker Box__marker_gray'}/> : null))}*/}




                </td>
            );
            date.setDate(date.getDate() + 1);
        }

        // Пустая область в конце календаря.
        let blankSecond = [];
        if (this.firstDayOfMonth(date) !== 0) {
            for (let i = this.firstDayOfMonth(date); i < 7; i++) {
                blankSecond.push(<td className="Calendar__td" key={i + 'td'}>
                    <div className="Td__text Td__text_default"/>
                </td>);
            }
        }

        // Формирование массива календарных дней.
        var totalSlots = [...blanksFirst, ...daysInMonth, ...blankSecond];
        let rows = [];
        let cells = [];

        // Построение календаря.
        totalSlots.forEach((row, i) => {
            if (i % 7 !== 0) {
                cells.push(row); // if index not equal 7 that means not go to next week
            } else {
                rows.push(cells); // when reach next week we contain all td in last week to rows
                cells = []; // empty container
                cells.push(row); // in current loop we still push current row to new container
            }

            if (i === totalSlots.length - 1) { // when end loop we add remain date
                rows.push(cells);
            }
        });

        let daysInMonthTable = rows.map((day, i) => {
            return (<tr className="Calendar__tr" key={'tr' + i}>{day}</tr>);
        });










                let activityTrip = (activity, index) => {

                    const daySecond = 86399;

                    let activityTime = false;
                    let activityEndTime = false;

                    let timeLeft = 0;
                    let endTimeWidth = 0;
                    let minEndTimeWidth = 6;
                    let maxWidth = 460;
                    let height = 24;
                    let borderRadius = 2;

                    let startDay = Number(
                        new Date(this.state.selectedYear +
                            '-' +
                            (this.state.selectedMonth + 1) +
                            '-' +
                            this.state.selectedDay).setHours(0, 0, 0, 0) / 1000);
                    let endDay = Number(
                        new Date(this.state.selectedYear +
                            '-' +
                            (this.state.selectedMonth + 1) +
                            '-' +
                            this.state.selectedDay).setHours(0, 0, 0, 0) / 1000 + daySecond);



                    if (startDay <= activity.time) {
                        if (activity.time <= endDay) {
                            activityTime = true;
                        }
                    }

                    if (startDay <= activity.end_time_connection) {

                        if (activity.end_time_connection <= endDay) {
                            activityEndTime = true;
                        }
                    }

                    if (activityTime && activityEndTime) {
                        // console.log(activity);
                        activityMarker = true;

                        timeLeft = (maxWidth / 100) * ((Number(activity.time) - startDay) / (daySecond / 100));

                        endTimeWidth = (maxWidth / 100) * ((Number(activity.end_time_connection) - Number(activity.time)) / (daySecond / 100));

                        // console.log(1 + ' ' + timeLeft + ' ' + endTimeWidth);

                        if (endTimeWidth < minEndTimeWidth) {endTimeWidth = minEndTimeWidth;}

                        return (<div style={{
                            position: "absolute",
                            left: timeLeft,
                            width: endTimeWidth,
                            height: height,
                            borderRadius: borderRadius,
                            backgroundColor: "#0061ff"
                        }} key={index}/>);
                    }

                    else if (activityTime && !activityEndTime) {
                        // console.log(activity);
                        activityMarker = true;

                        timeLeft = (maxWidth / 100) * ((Number(activity.time) - startDay) / (daySecond / 100));

                        endTimeWidth = (maxWidth / 100) * ((endDay - Number(activity.time)) / (daySecond / 100));

                        // console.log(2 + ' ' + timeLeft + ' ' + endTimeWidth);

                        return (<div style={{
                            position: "absolute",
                            left: timeLeft,
                            width: endTimeWidth,
                            height: height,
                            borderRadius: borderRadius,
                            backgroundColor: "#0061ff"
                        }} key={index}/>);
                    }

                    else if (!activityTime && activityEndTime) {
                        // console.log(activity);
                        activityMarker = true;

                        endTimeWidth = (maxWidth / 100) * ((Number(activity.end_time_connection) - startDay) / (daySecond / 100));

                        // console.log(3 + ' ' + timeLeft + ' ' + endTimeWidth);

                        return (<div style={{
                            position: "absolute",
                            left: timeLeft,
                            width: endTimeWidth,
                            height: height,
                            borderRadius: borderRadius,
                            backgroundColor: "#0061ff"
                        }} key={index}/>);

                    }

                    else {

                        if ((activity.time < startDay) && (endDay < activity.end_time_connection)) {
                            // console.log(activity);
                            activityMarker = true;


                            // console.log(4);

                            return (<div style={{
                                position: "absolute",
                                left: timeLeft,
                                width: maxWidth,
                                height: height,
                                borderRadius: borderRadius,
                                backgroundColor: "#0061ff"
                            }} key={index}/>);
                        }

                        else {

                            // console.log(5);

                            return null;
                        }
                    }
                }

                let lastVisitDataTime = (time) => {

                    if (Number(time) > 0) {

                        if ((parseInt(String(new Date().getTime()/1000)) - time) < 300) {

                            return 'сейчас';
                        }

                        else {
                            let date = new Date(time * 1000);
                            let day = "0" + date.getDate();
                            let month = "0" + (date.getMonth() + 1);
                            let year = String(date.getFullYear());
                            let hours = "0" + date.getHours();
                            let minutes = "0" + date.getMinutes();
                            let seconds = "0" + date.getSeconds();
                            let formattedData = day.substr(-2) + '.' + month.substr(-2) + '.' + year.substr(-2);
                            // let formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
                            let formattedTime = hours.substr(-2) + ':' + minutes.substr(-2);

                            return formattedTime + ' ' + formattedData;
                        }
                    }

                    else {

                        return 'никогда';
                    }
                }


                let activityTripText = (activity, index) => {
                    const daySecond = 86399;

                    let activityTime = false;
                    let activityEndTime = false;
                    let startDay = Number(
                        new Date(this.state.selectedYear +
                            '-' +
                            (this.state.selectedMonth + 1) +
                            '-' +
                            this.state.selectedDay).setHours(0, 0, 0, 0) / 1000);
                    let endDay = Number(
                        new Date(this.state.selectedYear +
                            '-' +
                            (this.state.selectedMonth + 1) +
                            '-' +
                            this.state.selectedDay).setHours(0, 0, 0, 0) / 1000 + daySecond);

                    if (startDay <= activity.time) {
                        if (activity.time <= endDay) {
                            activityTime = true;
                        }
                    }

                    if (startDay <= activity.end_time_connection) {

                        if (activity.end_time_connection <= endDay) {
                            activityEndTime = true;
                        }
                    }

                    if (activityTime || activityEndTime) {

                        if (activity.time === activity.end_time_connection) {

                            return (
                                <div style={{
                                    fontSize: 17,
                                    fontWeight: 400,
                                    fontFamily: "Roboto, sans-serif",
                                    color: "#2a3e4c",
                                    opacity: .75,
                                    marginBottom: 12
                                }} key={index}>
                                    {lastVisitDataTime(activity.time)}
                                </div>
                            );
                        }

                        else {
                            return (
                                <div style={{
                                    fontSize: 17,
                                    fontWeight: 400,
                                    fontFamily: "Roboto, sans-serif",
                                    color: "#2a3e4c",
                                    opacity: .75,
                                    marginBottom: 12
                                }} key={index}>
                                    {lastVisitDataTime(activity.time) + ' - ' + lastVisitDataTime(activity.end_time_connection)}
                                </div>
                            );
                        }
                    }

                    else {

                        if ((activity.time < startDay) && (endDay < activity.end_time_connection)) {
                            return (
                                <div style={{
                                    fontSize: 17,
                                    fontWeight: 400,
                                    fontFamily: "Roboto, sans-serif",
                                    color: "#2a3e4c",
                                    opacity: .75,
                                    marginBottom: 12
                                }} key={index}>
                                    {lastVisitDataTime(activity.time) + ' - ' + lastVisitDataTime(activity.end_time_connection)}
                                </div>
                            );
                        }

                        else {

                            return null;
                        }
                    }
                }





                let activityMarker = false;


                return (
                    <div className="Form__section" style={{width: 460}}>
                        <div className="Section__header">
                            <div className="Header__photo">
                                {formData.student_avatar ? (
                                    <label>
                                        <div className="Box__avatar Avatar__image"
                                             style={{
                                                 height: 100,
                                                 width: 100,
                                                 borderRadius: '50%',
                                                 backgroundColor: '#e6eaec',
                                                 marginRight: 32,
                                                 display: 'inline-block'
                                             }}>
                                            <img className={'Avatar__image'}
                                                 src={baseURL() + 'api/images/avatar/' + formData.student_avatar}
                                                 width="100"
                                                 height="100"/>
                                            <div className="Box__clear icon-clear"
                                                 onClick={this.alertOpen.bind(this, 'alertStudentAvatarDelete', alertData(formData.student_id, null))}/>
                                        </div>
                                    </label>
                                ) : (
                                    <label>
                                        <div className="Box__avatar Avatar__image" style={{cursor: "pointer"}}>
                                        <svg id="account_circle-black-18dp" xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">
                                            <path id="Path_1" data-name="Path 1" d="M0,0H100V100H0Z" fill="none"/>
                                            <path id="Path_2" data-name="Path 2" d="M50,2A48,48,0,1,0,98,50,48.018,48.018,0,0,0,50,2Zm0,14.4A14.4,14.4,0,1,1,35.6,30.8,14.381,14.381,0,0,1,50,16.4Zm0,68.16A34.563,34.563,0,0,1,21.2,69.1C21.344,59.552,40.4,54.32,50,54.32c9.552,0,28.656,5.232,28.8,14.784A34.563,34.563,0,0,1,50,84.56Z" fill="#e9ebed"/>
                                        </svg>
                                            <div className="Box__add icon-add"/>
                                        <div style={{
                                            position: "absolute",
                                            bottom: 4,
                                            right: -18
                                        }}>
                                            {isUpload ? <Loading /> : null}
                                        </div>
                                        <input type="file"
                                               accept=".jpeg, .jpg, .png"
                                               ref={this.avatarInput}
                                               onChange={this.handleAvatarChange.bind(this)}
                                               hidden />
                                        </div>
                                    </label>
                                )}
                            </div>
                            <div className="Header__title">
                                <div className="Title__text">
                                    {formData.student_fio.split(' ')[0]}<br/>{formData.student_fio.split(' ')[1] + ' ' + (formData.student_fio.split(' ')[2] ? formData.student_fio.split(' ')[2] : '')}


                                    <div style={{
                                            display: "inline-block",
                                            height: 32,
                                            width: 32,
                                            marginLeft: 8,
                                            cursor: "pointer"
                                        }}
                                         onClick={this.clipboard.bind(this, formData.student_fio)}>
                                            <svg style={{
                                                verticalAlign:"top",
                                                padding: 8
                                            }}
                                                 xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                                <g id="Group_1676" data-name="Group 1676" transform="translate(-1073 -187)">
                                                    <g id="Rectangle_2087" data-name="Rectangle 2087" transform="translate(1077 187)" fill="none" stroke="#aeb5ba" strokeLinejoin="round" strokeWidth="3">
                                                        <rect width="12" height="12" rx="4" stroke="none"/>
                                                        <rect x="1.5" y="1.5" width="9" height="9" rx="2.5" fill="none"/>
                                                    </g>
                                                    <g id="Rectangle_2088" data-name="Rectangle 2088" transform="translate(1073 191)" fill="#fff" stroke="#2a3e4c" strokeLinejoin="round" strokeWidth="3">
                                                        <rect width="12" height="12" rx="4" stroke="none"/>
                                                        <rect x="1.5" y="1.5" width="9" height="9" rx="2.5" fill="none"/>
                                                    </g>
                                                </g>
                                            </svg>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="Profile__content">
                            <div className="Profile__body">

                                <div className="Box">
                                    <div className="Box__text"
                                    style={{
                                        display: "inline-block",
                                        verticalAlign: "top",
                                        marginRight: 16,
                                        paddingTop: 7
                                    }}>Студент / Группа {groupName(formData.training_group.group_name, formData.training_group.initial_year)}</div>
                                    {this.props.profileData.profile_type === 'administrator' ? (
                                    <div style={{display: "inline-block", cursor: "pointer"}} onClick={this.alertOpen.bind(this, 'alertStudentTransfer', alertData(this.state.objectId, this.state.objectData))}>

                                        <svg xmlns="http://www.w3.org/2000/svg" width="12.599" height="26.857" viewBox="0 0 12.599 26.857">
                                            <g id="Group_1635" data-name="Group 1635" transform="translate(-89.692 -1130.28) rotate(30)">
                                                <g id="Group_1633" data-name="Group 1633" transform="translate(809.485 527.485) rotate(90)">
                                                    <rect id="Rectangle_1389" data-name="Rectangle 1389" width="13" height="4" rx="2" transform="translate(404 156)" fill="#0061ff"/>
                                                    <g id="Group_1341" data-name="Group 1341" transform="translate(-9.314 308.614) rotate(-45)">
                                                        <rect id="Rectangle_1390" data-name="Rectangle 1390" width="10" height="4" rx="2" transform="translate(397 184)" fill="#0061ff"/>
                                                        <rect id="Rectangle_1391" data-name="Rectangle 1391" width="4" height="10" rx="2" transform="translate(397 184)" fill="#0061ff"/>
                                                    </g>
                                                </g>
                                                <g id="Group_1634" data-name="Group 1634" transform="translate(500.485 1357.485) rotate(-90)">
                                                    <rect id="Rectangle_1389-2" data-name="Rectangle 1389" width="13" height="4" rx="2" transform="translate(404 156)" fill="#0061ff"/>
                                                    <g id="Group_1341-2" data-name="Group 1341" transform="translate(-9.314 308.614) rotate(-45)">
                                                        <rect id="Rectangle_1390-2" data-name="Rectangle 1390" width="10" height="4" rx="2" transform="translate(397 184)" fill="#0061ff"/>
                                                        <rect id="Rectangle_1391-2" data-name="Rectangle 1391" width="4" height="10" rx="2" transform="translate(397 184)" fill="#0061ff"/>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                        ) : null}
                                </div>

                                <div className="Box">
                                    <div className="Box__menu">
                                        <div className={formMenu === 'data' ? 'Menu__button active' : 'Menu__button'}
                                             onClick={this.formMenu.bind(this, 'data')}>
                                            Профиль
                                            <div className="Button__border"/>
                                        </div>
                                        <div className={formMenu === 'gradebook' ? 'Menu__button active' : 'Menu__button'}
                                             onClick={this.formMenu.bind(this, 'gradebook')}>
                                            Зачетка
                                            <div className="Button__border"/>
                                        </div>

                                        {this.props.profileData.profile_type === 'administrator' ? (

                                            <div className={formMenu === 'settings' ? 'Menu__button active' : 'Menu__button'}
                                            onClick={this.formMenu.bind(this, 'settings')}>
                                            Настройки
                                            <div className="Button__border"/>
                                            </div>
                                        ) : null}

                                        <div className={formMenu === 'activity' ? 'Menu__button Menu__button_right active' : 'Menu__button Menu__button_right'}
                                             onClick={this.formMenu.bind(this, 'activity')}>
                                            Активность
                                            <div className="Button__border"/>
                                        </div>
                                    </div>
                                    <div className="Menu__hr"/>
                                </div>



                                {formMenu === 'data' ? (
                                    <React.Fragment>

                                        {/*<div className="Content__hr"/>*/}

                                        <div className="Box">
                                            <div className="Box__title">Личные данные</div>
                                        </div>

                                        <div className="Box">
                                            <div className="Box__label">Дата рождения</div>
                                            <div className="Box__text">{formData.student_birthday}</div>
                                        </div>



                                        <div className="Box">
                                            <div className="Box__label">Телефон</div>
                                            <div
                                                className={!formData.student_telephone ? 'Box__text Box__text_disabled' : 'Box__text'}>
                                                {!formData.student_telephone ? 'Нет данных' : telephone(formData.student_telephone)}
                                                {!formData.student_telephone ? null : (
                                                    <div style={{
                                                        display: "inline-block",
                                                        height: 16,
                                                        width: 16,
                                                        marginLeft: 16,
                                                        cursor: "pointer"
                                                    }}
                                                         onClick={this.clipboard.bind(this, formData.student_telephone)}>
                                                        <svg style={{
                                                            verticalAlign:"top"
                                                        }}
                                                             xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                                            <g id="Group_1676" data-name="Group 1676" transform="translate(-1073 -187)">
                                                                <g id="Rectangle_2087" data-name="Rectangle 2087" transform="translate(1077 187)" fill="none" stroke="#aeb5ba" strokeLinejoin="round" strokeWidth="3">
                                                                    <rect width="12" height="12" rx="4" stroke="none"/>
                                                                    <rect x="1.5" y="1.5" width="9" height="9" rx="2.5" fill="none"/>
                                                                </g>
                                                                <g id="Rectangle_2088" data-name="Rectangle 2088" transform="translate(1073 191)" fill="#fff" stroke="#2a3e4c" strokeLinejoin="round" strokeWidth="3">
                                                                    <rect width="12" height="12" rx="4" stroke="none"/>
                                                                    <rect x="1.5" y="1.5" width="9" height="9" rx="2.5" fill="none"/>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="Box">
                                            <div className="Box__label">Электронная почта</div>
                                            <div className="Box__text">
                                                {formData.student_email}
                                                <div style={{
                                                    display: "inline-block",
                                                    height: 16,
                                                    width: 16,
                                                    marginLeft: 16,
                                                    cursor: "pointer"
                                                }}
                                                     onClick={this.clipboard.bind(this, formData.student_email)}>
                                                    <svg style={{
                                                        verticalAlign:"top"
                                                    }}
                                                         xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                                        <g id="Group_1676" data-name="Group 1676" transform="translate(-1073 -187)">
                                                            <g id="Rectangle_2087" data-name="Rectangle 2087" transform="translate(1077 187)" fill="none" stroke="#aeb5ba" strokeLinejoin="round" strokeWidth="3">
                                                                <rect width="12" height="12" rx="4" stroke="none"/>
                                                                <rect x="1.5" y="1.5" width="9" height="9" rx="2.5" fill="none"/>
                                                            </g>
                                                            <g id="Rectangle_2088" data-name="Rectangle 2088" transform="translate(1073 191)" fill="#fff" stroke="#2a3e4c" strokeLinejoin="round" strokeWidth="3">
                                                                <rect width="12" height="12" rx="4" stroke="none"/>
                                                                <rect x="1.5" y="1.5" width="9" height="9" rx="2.5" fill="none"/>
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="Content__hr"/>

                                        <div className="Box">
                                            <div className="Box__title">Образование</div>
                                        </div>
                                        <div className="Box">
                                            <div className="Box__label">Период обучения</div>
                                            <div className={!formData.period_of_study ? 'Box__text Box__text_disabled' : 'Box__text'}>
                                                {!formData.period_of_study ? 'Нет данных' : periodOfStudy(formData.period_of_study)}
                                            </div>
                                        </div>
                                        <div className="Box">
                                            <div className="Box__label">Учебное заведение</div>
                                            <div className={!formData.educational_institution ? 'Box__text Box__text_disabled' : 'Box__text'}>
                                                {!formData.educational_institution ? 'Нет данных' : formData.educational_institution}
                                            </div>
                                        </div>

                                        {formData.received_specialty ? (
                                            <div className="Box">
                                                <div className="Box__label">Полученная специальность</div>
                                                <div className="Box__text">{formData.received_specialty}</div>
                                            </div>
                                        ) : null}

                                        <div className="Content__hr"/>

                                        <div className="Box">
                                            <div className="Box__title">Данные авторизации</div>
                                        </div>
                                        <div className="Box Box_end">
                                            <div className="Box__label">Логин</div>
                                            <div className="Box__text">
                                                {formData.student_login}
                                                <div style={{
                                                    display: "inline-block",
                                                    height: 16,
                                                    width: 16,
                                                    marginLeft: 16,
                                                    cursor: "pointer"
                                                }}
                                                     onClick={this.clipboard.bind(this, formData.student_login)}>
                                                    <svg style={{
                                                        verticalAlign:"top"
                                                    }}
                                                         xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                                        <g id="Group_1676" data-name="Group 1676" transform="translate(-1073 -187)">
                                                            <g id="Rectangle_2087" data-name="Rectangle 2087" transform="translate(1077 187)" fill="none" stroke="#aeb5ba" strokeLinejoin="round" strokeWidth="3">
                                                                <rect width="12" height="12" rx="4" stroke="none"/>
                                                                <rect x="1.5" y="1.5" width="9" height="9" rx="2.5" fill="none"/>
                                                            </g>
                                                            <g id="Rectangle_2088" data-name="Rectangle 2088" transform="translate(1073 191)" fill="#fff" stroke="#2a3e4c" strokeLinejoin="round" strokeWidth="3">
                                                                <rect width="12" height="12" rx="4" stroke="none"/>
                                                                <rect x="1.5" y="1.5" width="9" height="9" rx="2.5" fill="none"/>
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>

                                        {/*<div className="Form__link">*/}
                                        {/*    <div className="Link__delete" onClick={this.alertOpen.bind(this, 'alertStudentDelete', alertData(this.state.objectId, this.state.objectData))}>Удалить студента</div>*/}
                                        {/*</div>*/}

                                        {/*<div className="Form__link">*/}
                                        {/*    <div className="Link__delete" style={{color: "#0061ff"}} onClick={this.alertOpen.bind(this, 'alertStudentTransfer', alertData(this.state.objectId, this.state.objectData))}>Перевести в другую группу</div>*/}
                                        {/*</div>*/}


                                        {this.props.profileData.profile_type === 'administrator' ? (
                                            <div className="Form__link">
                                                <div className="Link__delete" onClick={this.alertOpen.bind(this, 'alertStudentDelete', alertData(this.state.objectId, this.state.objectData))}>Удалить студента</div>
                                            </div>
                                        ) : null}

                                    </React.Fragment>
                                ) : null}


                                {formMenu === 'settings' ? (
                                    <React.Fragment>
                                        <div className="Form">
                                            <form onSubmit={this.handleSubmit.bind(this)}>
                                                <div className="Form__content">

                                                    <div className="Box">
                                                        <div className="Box__title"
                                                             style={{marginBottom: 16}}>Личные данные</div>
                                                    </div>

                                                    <div className="Content__box">
                                                        <div className="Form__field">
                                                            <div className="Field__label">Фамилия Имя Отчество *</div>
                                                            <input className="Field__input"
                                                                   name="studentFio"
                                                                   type="text"
                                                                   placeholder="Иванов Иван Иванович"
                                                                   defaultValue={formData.student_fio}
                                                                   value={this.state.value}
                                                                   onChange={this.handleInputChange.bind(this)}/>
                                                        </div>
                                                    </div>

                                                    <div className="Content__box">
                                                        <div className="Form__field">
                                                            <div className="Field__label">Дата рождения *</div>
                                                            <input className="Field__input"
                                                                   name="studentBirthday"
                                                                   type="text"
                                                                   placeholder="дд.мм.гггг"
                                                                   defaultValue={formData.student_birthday}
                                                                   value={this.state.value}
                                                                   onChange={this.handleInputChange.bind(this)}/>
                                                        </div>
                                                    </div>

                                                    <div className="Content__box">
                                                        <div className="Form__field">
                                                            <div className="Field__label">Телефон</div>
                                                            <input className="Field__input"
                                                                   name="studentTelephone"
                                                                   type="number"
                                                                   placeholder="89991234567"
                                                                   defaultValue={formData.student_telephone}
                                                                   value={this.state.value}
                                                                   onChange={this.handleInputChange.bind(this)}/>
                                                        </div>
                                                    </div>

                                                    <div className="Content__box">
                                                        <div className="Form__field">
                                                            <div className="Field__label">Электронная почта *</div>
                                                            <input className="Field__input"
                                                                   name="studentEmail"
                                                                   type="email"
                                                                   placeholder="user@email.com"
                                                                   defaultValue={formData.student_email}
                                                                   value={this.state.value}
                                                                   onChange={this.handleInputChange.bind(this)}/>
                                                        </div>
                                                    </div>

                                                    <div className="Content__hr Content__hr_bottom-32"/>

                                                    <div className="Box">
                                                        <div className="Box__title"
                                                             style={{marginBottom: 16}}>Образование</div>
                                                    </div>

                                                    <div className="Content__box">
                                                        <span className="Box__info">Сведения о полученном образовании</span>
                                                    </div>

                                                    <div className="Content__box">
                                                        <div className="Form__field">
                                                            <div className="Field__label">Период обучения</div>
                                                            <input className="Field__input"
                                                                   name="periodOfStudy"
                                                                   type="text"
                                                                   placeholder="мм.гггг-мм.гггг"
                                                                   defaultValue={formData.period_of_study}
                                                                   value={this.state.value}
                                                                   onChange={this.handleInputChange.bind(this)} />
                                                        </div>
                                                    </div>

                                                    <div className="Content__box">
                                                        <div className="Form__field">
                                                            <div className="Field__label">Учебное заведение</div>
                                                            <input className="Field__input"
                                                                   name="educationalInstitution"
                                                                   type="text"
                                                                   defaultValue={formData.educational_institution}
                                                                   value={this.state.value}
                                                                   onChange={this.handleInputChange.bind(this)} />
                                                        </div>
                                                    </div>

                                                    <div className="Content__box">
                                                        <div className="Form__field">
                                                            <div className="Field__label">Полученная специальность (если есть)</div>
                                                            <input className="Field__input"
                                                                   name="receivedSpecialty"
                                                                   type="text"
                                                                   defaultValue={formData.received_specialty}
                                                                   value={this.state.value}
                                                                   onChange={this.handleInputChange.bind(this)} />
                                                        </div>
                                                    </div>

                                                    <div className="Content__error">
                                                        <span className="Error__text">{formResponseError}</span>
                                                    </div>
                                                </div>
                                                <div className="Form__footer" style={{marginBottom: 0}}>
                                                    <button className={isHandleSubmit ? 'Form__button Form__button_blue Form__button_left Form__button_disabled' : 'Form__button Form__button_blue Form__button_left'}
                                                            type="submit"
                                                            style={{
                                                                position: "relative"
                                                            }}
                                                            disabled={isHandleSubmit ? 'disabled' : null}>
                                                        Сохранить
                                                        <div style={{position: "absolute", right: 6, top: 6}}>{isHandleSubmit ? <Loading white={true} /> : null}</div>
                                                    </button>
                                                </div>

                                                {/*<div className="Content__hr"/>*/}

                                                {/*<div className="Form__link">*/}
                                                {/*    <div className="Link__delete" style={{color: "#0061ff"}} onClick={this.alertOpen.bind(this, 'alertStudentDelete', alertData(this.state.objectId, this.state.objectData))}>Перевести в другую группу</div>*/}
                                                {/*</div>*/}

                                                {/*<div className="Content__hr"/>*/}

                                                {/*<div className="Form__link">*/}
                                                {/*    <div className="Link__delete" onClick={this.alertOpen.bind(this, 'alertStudentDelete', alertData(this.state.objectId, this.state.objectData))}>Удалить студента</div>*/}
                                                {/*</div>*/}
                                            </form>
                                        </div>
                                    </React.Fragment>
                                ) : null}

                                {formMenu === 'activity' ? (
                                    <React.Fragment>
                                        <div className="Form">

                                            <div className="Form__content">



                                                <div className="Box Box_end">









                                                    <div className="Calendar__active" style={{display: "inline-block"}}>








                                                    <div className="Calendar__day">
                                                        <div className={'Calendar__today'}>
                                                            <div
                                                                className={'Today__weekday'}>
                                                                {timeYear(new Date(this.state.calendarYear, this.state.calendarMonth).getTime() / 1000)}
                                                            </div>
                                                            <div
                                                                className={'Today__month'}>
                                                                {timeMonth(new Date(this.state.calendarYear, this.state.calendarMonth).getTime() / 1000)[0].toUpperCase() + timeMonth(new Date(this.state.calendarYear, this.state.calendarMonth).getTime() / 1000).slice(1)}
                                                            </div>
                                                        </div>
                                                        <div className="Header__buttons">

                                                            <div className="Header__button "
                                                                 onClick={this.monthMines.bind(this)}>

                                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                                                                    <g id="Group_1680" data-name="Group 1680" transform="translate(-1392 -522)">
                                                                        <rect id="Rectangle_2080" data-name="Rectangle 2080" width="32" height="32" rx="16" transform="translate(1392 522)" fill="#cacfd3"/>
                                                                        <g id="Group_1677" data-name="Group 1677" transform="translate(1948 -930) rotate(90)">
                                                                            <g id="Group_920" data-name="Group 920" transform="translate(420.563 1105.602) rotate(-45)">
                                                                                <rect id="Rectangle_1180" data-name="Rectangle 1180" width="10" height="4" rx="1" transform="translate(1136.293 341.001)" fill="#fff"/>
                                                                                <rect id="Rectangle_1181" data-name="Rectangle 1181" width="4" height="10" rx="1" transform="translate(1136.293 335)" fill="#fff"/>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg>
                                                            </div>

                                                            <div className="Header__button "
                                                                 onClick={this.monthPlus.bind(this)}>

                                                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                                                                    <g id="Group_1679" data-name="Group 1679" transform="translate(-1488 -524)">
                                                                        <rect id="Rectangle_2081" data-name="Rectangle 2081" width="32" height="32" rx="16" transform="translate(1488 524)" fill="#cacfd3"/>
                                                                        <g id="Group_1678" data-name="Group 1678" transform="translate(964 2008) rotate(-90)">
                                                                            <g id="Group_920" data-name="Group 920" transform="translate(420.563 1105.602) rotate(-45)">
                                                                                <rect id="Rectangle_1180" data-name="Rectangle 1180" width="10" height="4" rx="1" transform="translate(1136.293 341.001)" fill="#fff"/>
                                                                                <rect id="Rectangle_1181" data-name="Rectangle 1181" width="4" height="10" rx="1" transform="translate(1136.293 335)" fill="#fff"/>
                                                                            </g>
                                                                        </g>
                                                                    </g>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <table className="Calendar__table">
                                                        <thead>
                                                        <tr className="Calendar__tr">
                                                            <th className="Calendar__th">
                                                                <div className="Th__title Th__title_left">Пн</div>
                                                            </th>
                                                            <th className="Calendar__th">
                                                                <div className="Th__title Th__title_left">Вт</div>
                                                            </th>
                                                            <th className="Calendar__th">
                                                                <div className="Th__title Th__title_left">Ср</div>
                                                            </th>
                                                            <th className="Calendar__th">
                                                                <div className="Th__title Th__title_left">Чт</div>
                                                            </th>
                                                            <th className="Calendar__th">
                                                                <div className="Th__title Th__title_left">Пт</div>
                                                            </th>
                                                            <th className="Calendar__th">
                                                                <div className="Th__title Th__title_left">Сб</div>
                                                            </th>
                                                            <th className="Calendar__th">
                                                                <div className="Th__title Th__title_left">Вс</div>
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>{daysInMonthTable}</tbody>
                                                    </table>





                                                    </div>




                                                </div>


                                                    <div className="Box Box_end">

                                                        <div className="Day__active" style={{borderLeft: 0, paddingLeft: 0, marginBottom: 12}}>
                                                            <div className="Box__day">
                                                                {timeDay(dateTimeEnd)}
                                                                <div className={'Box__today'}>
                                                                    <div className={'Today__weekday'}>{weekDay(dateTimeEnd)}</div>
                                                                    <div className={'Today__month'}>{timeMonth(dateTimeEnd)}</div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                            <div style={{
                                                                width: 460,
                                                                height: 24,
                                                                borderRadius: 4,
                                                                backgroundColor: "#f7f7f7",
                                                                position: "relative",
                                                                marginBottom: 16
                                                            }}>


                                                                {formData.activity_student.map((activity, index) => (
                                                                    activityTrip(activity, index)
                                                                ))}

                                                            </div>

                                                        <div style={{
                                                            width: 460
                                                        }}>

                                                        {formData.activity_student.map((activity, index) => (
                                                            activityTripText(activity, index)
                                                        ))}


                                                            {/*{formData.activity_student.map(activity => (*/}
                                                            {/*    Number(new Date(this.state.calendarYear + '-' + (this.state.calendarMonth + 1) + '-' + date.getDate()).setHours(0, 0, 0, 0) / 1000) <= activity.time ? (*/}
                                                            {/*        activity.time <= Number(new Date(this.state.calendarYear + '-' + (this.state.calendarMonth + 1) + '-' + date.getDate()).setHours(0, 0, 0, 0) / 1000 + 86399) ? (*/}
                                                            {/*            markerActivity = true*/}
                                                            {/*        ) : null*/}
                                                            {/*    ) : null*/}
                                                            {/*))}*/}

                                                            {/*{formData.activity_student.map(activity => (*/}
                                                            {/*    Number(new Date(this.state.calendarYear + '-' + (this.state.calendarMonth + 1) + '-' + date.getDate()).setHours(0, 0, 0, 0) / 1000) <= activity.end_time_connection ? (*/}
                                                            {/*        activity.end_time_connection <= Number(new Date(this.state.calendarYear + '-' + (this.state.calendarMonth + 1) + '-' + date.getDate()).setHours(0, 0, 0, 0) / 1000 + 86399) ? (*/}
                                                            {/*            markerActivity = true*/}
                                                            {/*        ) : null*/}
                                                            {/*    ) : null*/}
                                                            {/*))}*/}

                                                            {!activityMarker ? (
                                                                <div style={{
                                                                    textAlign: "center",
                                                                    fontSize: 32,
                                                                    fontWeight: 900,
                                                                    fontFamily: "Roboto, sans-serif",
                                                                    color: "#2a3e4c",
                                                                    padding: "18px 0 0",
                                                                    opacity: 0.15
                                                                }}>

                                                                    Нет активности в этот день
                                                                </div>
                                                                ) : null}

                                                    </div>

                                                    </div>









                                                {/*<div className="Content__hr Content__hr_bottom-32"/>*/}







                                                {/*<div className="Box">*/}
                                                {/*    <div className="Box__title"*/}
                                                {/*         style={{marginBottom: 16}}>За месяц</div>*/}
                                                {/*</div>*/}



                                                {/*<div className="Box">*/}
                                                {/*    <div className="Box__title"*/}
                                                {/*         style={{marginBottom: 16}}>Посещения за день</div>*/}
                                                {/*</div>*/}

                                                {/*<div className="Content__hr Content__hr_bottom-32"/>*/}

                                                {/*<div className="Box">*/}
                                                {/*    <div className="Box__title"*/}
                                                {/*         style={{marginBottom: 16}}>Действия за день</div>*/}
                                                {/*</div>*/}
                                            </div>
                                        </div>
                                    </React.Fragment>
                                ) : null}




                                {formMenu === 'gradebook' ? (
                                <ul>

                                    {formData.gradebook ? (
                                        formData.gradebook.disciplines_plan.map((disciplines_plan, index) => (
                                            <React.Fragment key={index}>
                                                <div className="Body__label"
                                                style={{
                                                    fontSize: 20,
                                                    fontWeight: 700,
                                                    fontFamily: "Roboto, sans-serif",
                                                    color: "#2a3e4c",
                                                    marginBottom: 24,
                                                    marginTop: 24
                                                }}

                                                >{disciplines_plan.year_program} курс, {disciplines_plan.period_program} семестр</div>


                                                <ul className="Body__list">
                                                    {disciplines_plan.disciplines.map(discipline => (
                                                        <li className={this.props.profileData.profile_type === 'administrator' ? "List__item List__item_active" : "List__item"}

                                                            key={discipline.id} style={{
                                                            backgroundColor: "#f7f7f7",
                                                            padding: "16px",
                                                            borderRadius: 7,
                                                            marginTop: 12,
                                                            position: "relative",
                                                            cursor: (this.props.profileData.profile_type === 'administrator' ? "pointer" : "default")
                                                        }}
                                                            onClick={
                                                                this.props.profileData.profile_type === 'administrator' ? (
                                                                this.alertOpen.bind(this, 'alertStudentGradeBook', alertData(this.state.objectId, discipline))
                                                                ) : null
                                                            }>

                                                            <div style={{
                                                                display: "inline-block",
                                                                position: "absolute",
                                                                top: -8
                                                            }}>
                                                            {discipline.discipline_index ? (
                                                                <div className="Item__index"
                                                                     style={{
                                                                         fontFamily: "Roboto, sans-serif",
                                                                         fontSize: 15,
                                                                         fontWeight: 700,
                                                                         backgroundColor: "#2A3E4C",
                                                                         color: "white",
                                                                         padding: "4px 6px 3px",
                                                                         borderRadius: 4,
                                                                         display: "inline-block",
                                                                         textTransform: "uppercase",
                                                                         marginRight: 16
                                                                     }}>
                                                                    {discipline.discipline_index}
                                                                </div>
                                                            ) : null}

                                                            {discipline.academic_performance.length > 0 ? (





                                                                discipline.academic_performance.map((academic_performance, index) => (
                                                                    <div className="Item__index" key={index} style={{
                                                                        fontFamily: "Roboto, sans-serif",
                                                                        fontSize: 15,
                                                                        fontWeight: 700,
                                                                        color: "white",
                                                                        padding: "4px 6px 3px",
                                                                        borderRadius: 4,
                                                                        display: "inline-block",
                                                                        textTransform: "uppercase",
                                                                        backgroundColor: (Number(academic_performance.knowledge_assessment) === 0 ? "#0066ff" :
                                                                            (Number(academic_performance.knowledge_assessment) === 1 ? "#e53935" :
                                                                                (Number(academic_performance.knowledge_assessment) === 2 ? "#e53935" :
                                                                                    (Number(academic_performance.knowledge_assessment) > 2 ? "#43a047" : "#43a047")))),
                                                                        marginRight: 8
                                                                        // backgroundColor: "#e53935",
                                                                        // isPassed Не сдан
                                                                    }}>

                                                                        {academic_performance.control_type === 'exam' ? 'Экзамен' : null}
                                                                        {academic_performance.control_type === 'diff' ? 'Диф. зачет' : null}
                                                                        {academic_performance.control_type === 'test' ? 'Зачет' : null}
                                                                        {academic_performance.control_type === 'project' ? 'Курс. проект' : null}
                                                                        {academic_performance.control_type === 'coursework' ? 'Курс. работа' : null}

                                                                    </div>
                                                                ))

                                                            ) : null}
                                                            </div>

                                                            {Number(discipline.anchor_of_choice) > 0 ? (
                                                                discipline.discipline_choice ? (
                                                                    <div className="Item__index"
                                                                         style={{
                                                                             fontFamily: "Roboto, sans-serif",
                                                                             fontSize: 15,
                                                                             fontWeight: 700,
                                                                             backgroundColor: "#0061ff",
                                                                             color: "white",
                                                                             padding: "4px 6px 3px",
                                                                             borderRadius: 4,
                                                                             display: "inline-block",
                                                                             position: "absolute",
                                                                             top: -8,
                                                                             textTransform: "uppercase",
                                                                             right: 16 + 13 + 16
                                                                         }}>
                                                                        Выбран
                                                                    </div>
                                                                ) : null
                                                            ) : null}


                                                            {Number(discipline.anchor_of_choice) > 0 ? (
                                                                <div className="Item__index"
                                                                     style={{
                                                                         display: "inline-block",
                                                                         position: "absolute",
                                                                         top: -9,
                                                                         right: 16
                                                                     }}>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="12.599" height="26.857" viewBox="0 0 12.599 26.857">
                                                                        <g id="Group_1635" data-name="Group 1635" transform="translate(-89.692 -1130.28) rotate(30)">
                                                                            <g id="Group_1633" data-name="Group 1633" transform="translate(809.485 527.485) rotate(90)">
                                                                                <rect id="Rectangle_1389" data-name="Rectangle 1389" width="13" height="4" rx="2" transform="translate(404 156)" fill="#0061ff"/>
                                                                                <g id="Group_1341" data-name="Group 1341" transform="translate(-9.314 308.614) rotate(-45)">
                                                                                    <rect id="Rectangle_1390" data-name="Rectangle 1390" width="10" height="4" rx="2" transform="translate(397 184)" fill="#0061ff"/>
                                                                                    <rect id="Rectangle_1391" data-name="Rectangle 1391" width="4" height="10" rx="2" transform="translate(397 184)" fill="#0061ff"/>
                                                                                </g>
                                                                            </g>
                                                                            <g id="Group_1634" data-name="Group 1634" transform="translate(500.485 1357.485) rotate(-90)">
                                                                                <rect id="Rectangle_1389-2" data-name="Rectangle 1389" width="13" height="4" rx="2" transform="translate(404 156)" fill="#0061ff"/>
                                                                                <g id="Group_1341-2" data-name="Group 1341" transform="translate(-9.314 308.614) rotate(-45)">
                                                                                    <rect id="Rectangle_1390-2" data-name="Rectangle 1390" width="10" height="4" rx="2" transform="translate(397 184)" fill="#0061ff"/>
                                                                                    <rect id="Rectangle_1391-2" data-name="Rectangle 1391" width="4" height="10" rx="2" transform="translate(397 184)" fill="#0061ff"/>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </svg>
                                                                </div>
                                                            ) : null}




                                                            <div className="Item__block Item__block_inline" style={{
                                                                paddingTop: 2,
                                                                // textOverflow: "ellipsis",
                                                                // overflow: "hidden",
                                                                // maxWidth: 1240,
                                                                // whiteSpace: "nowrap",
                                                                // wordWrap: "break-word"
                                                                fontSize: 16,
                                                                fontWeight: 400,
                                                                fontFamily: "Roboto, sans-serif",
                                                                color: "#2a3e4c"
                                                            }}>
                                                                {discipline.discipline_name}
                                                            </div>

                                                        </li>
                                                    ))}
                                                </ul>
                                            </React.Fragment>
                                        ))
                                    ) : (

                                        <div style={{
                                            textAlign: "center",
                                            fontSize: 32,
                                            fontWeight: 900,
                                            fontFamily: "Roboto, sans-serif",
                                            color: "#2a3e4c",
                                            padding: "18px 0 0",
                                            opacity: 0.15
                                        }}>

                                            Нет учебного плана
                                        </div>
                                    )}

                                </ul>

                                ) : null}


                            </div>
                        </div>
                    </div>
                );
            }
        }

        else return null;
    }
}

function mapStateToProps(state) {
    return {
        isLoadForm: state.form.isLoadForm,
        isUpdateForm: state.form.isUpdateForm,
        modalData: state.modal.modalData,
        isRequest: state.api.isRequest,
        responseMessage: state.api.responseMessage,
        responseData: state.api.responseData,
        responseError: state.api.responseError,
        profileData: state.profile.profileData
    }
}

function mapDispatchToProps(dispatch) {
    return {
        formLoad: (isLoadForm) => dispatch(formLoad(isLoadForm)),
        pageUpdate: () => dispatch(pageUpdate()),
        formUpdate: () => dispatch(formUpdate()),
        notificationMessage: (message, colorMessage) => dispatch(notificationMessage(message, colorMessage)),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        alertOpen: (alertForm, alertData) => dispatch(alertOpen(alertForm, alertData)),
        modalClose: () => dispatch(modalClose())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalStudent);
