export const AUTH_REQUEST = 'auth_request';
export const AUTH_ERROR = 'auth_error';
export const AUTH_SUCCESS = 'auth_success';
export const AUTH_LOGOUT = 'auth_logout';

export const RECOVERY_REQUEST = 'recovery_request';
export const RECOVERY_ERROR = 'recovery_error';

export const CABINET_OPEN = 'cabinet_open';
export const CABINET_EDIT = 'cabinet_edit';

export const PROFILE_OPEN = 'profile_open';
export const PROFILE_CLOSE = 'profile_close';
export const PROFILE_LOAD = 'profile_load';
export const PROFILE_UPDATE = 'profile_update';
export const PROFILE_DATA = 'profile_data';
export const PROFILE_REQUEST = 'profile_request';
export const PROFILE_REQUEST_ERROR = 'profile_request_error';
export const PROFILE_RESPONSE = 'profile_response';
export const PROFILE_RESPONSE_ERROR = 'profile_response_error';

export const MODAL_OPEN = 'modal_open';
export const MODAL_CLOSE = 'modal_close';

export const ALERT_OPEN = 'alert_open';
export const ALERT_CLOSE = 'alert_close';

export const NOTIFICATION_MESSAGE = 'notification_message';
export const NOTIFICATION_CLEAR = 'notification_clear';

export const API_REQUEST = 'api_request';
export const API_RESPONSE = 'api_response';
export const API_RESPONSE_ERROR = 'api_response_error';
export const API_REQUEST_ERROR = 'api_request_error';
export const API_UNMOUNT = 'api_unmount';

export const PAGE_LOAD = 'page_load';
export const PAGE_UPDATE = 'page_update';
export const PAGE_UNMOUNT = 'page_unmount';

export const FORM_LOAD = 'form_load';
export const FORM_UPDATE = 'form_update';
