import {combineReducers} from "redux";
import authReducer from "./authReducer";
import recoveryReducer from "./recoveryReducer";
import apiReducer from "./apiReducer";
import cabinetReducer from "./cabinetReducer";
import pageReducer from "./pageReducer";
import formReducer from "./formReducer";
import notificationReducer from "./notificationReducer";
import profileReducer from "./profileReducer";
import modalReducer from "./modalReducer";
import alertReducer from "./alertReducer";

export default combineReducers({
    auth: authReducer,
    recovery: recoveryReducer,
    api: apiReducer,
    cabinet: cabinetReducer,
    page: pageReducer,
    form: formReducer,
    notification: notificationReducer,
    profile: profileReducer,
    modal: modalReducer,
    alert: alertReducer
});
