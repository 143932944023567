import React from "react";
import {connect} from "react-redux";
import {pageLoad, pageUpdate, pageMount} from "../../../../../store/actions/pageAction";
import {apiRequest} from "../../../../../store/actions/apiAction";
import {modalOpen} from "../../../../../store/actions/modalAction";
import "./StudentTrainingCourse.scss";
import Loading from "../../../../loading/Loading";
import {Link} from "react-router-dom";
import {alertOpen} from "../../../../../store/actions/alertAction";
import {notificationMessage} from "../../../../../store/actions/notificationAction";
import Baron from "../../../../baron/Baron";
import baseURL from "../../../../../library/baseURL";

class StudentTrainingCourse extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isMount: true,
            isOpen: false,
            isLoad: false,
            isUpdate: false,
            pageError: null,
            trainingCourseData: null,

            typeWeekNumber: 0,

            calendarData: null,

            serverMonth: new Date().getMonth(), // Текущий период с сервера для контроля.
            serverYear: new Date().getFullYear(), // Текущий период с сервера для контроля.
            // serverDay: new Date().getDate(),


            calendarMonth: new Date().getMonth(), // месяцы в JS идут от 0 до 11, а не от 1 до 12
            calendarYear: new Date().getFullYear(),
            // calendarDay: new Date().getDate(),
            selectedDay: new Date().getDate(),
            selectedMonth: new Date().getMonth(),
            selectedYear: new Date().getFullYear(),
            // selectedCurrent: false,

            selectTypeHours: null,
            isTypeHours: false,


            isEditTimetables: false,

            selectChat: null,
            chatMessageText: null
        };
    }

    componentDidMount() {
        this.props.pageMount();
        this.pageUpdate(this.state.isLoad);
        this.intervalUpdate = setInterval(this.props.pageUpdate, 7200);
    }

    componentWillUnmount() {
        // console.log('Employees Unmounted');
        clearInterval(this.intervalUpdate);
        this.setState({
            isMount: false
        });
    }

    componentDidUpdate() {
        if (this.state.isMount) {
            const responseData = this.props.responseData;
            // console.log(1 + ' componentDidUpdate');
            if (this.props.isLoad !== this.state.isLoad) {
                // console.log(2 + ' isLoad');
                if (responseData !== this.state.trainingCourseData) {
                    // console.log(3 + ' responseData');

                    this.setState({
                        isOpen: true,
                        isLoad: this.props.isLoad,
                        isUpdate: this.props.isUpdate,
                        trainingCourseData: responseData,

                        selectTypeHours: null,
                        isTypeHours: false,
                        selectChat: responseData.course_messages
                    }, () => {
                        let block = document.getElementById("chat");
                        block.scrollTop = block.scrollHeight;
                    });
                }
            }

            else if (this.props.isUpdate !== this.state.isUpdate) {
                // console.log(4 + ' isUpdate');
                this.pageUpdate(this.state.isLoad);

                this.setState({
                    isUpdate: this.props.isUpdate
                });
            }
        }
    }

    pageUpdate(isLoad) {
        this.props.apiRequest('api/cabinet/student/training/course/' + this.props.match.params.trainingCourseId, null, () => {
            this.props.pageLoad(isLoad);
        }, () => {
            this.setState({
                pageError: this.props.responseError
            });
        });
    }

    modalOpen(modalForm, modalData) {
        this.props.modalOpen(modalForm, modalData);
    }


    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }


    firstDayOfMonth(date) {
        // Получить номер дня недели, от 0 (пн) до 6 (вс).
        let day = new Date(date).getDay();
        // Сделать воскресенье (0) последним днем.
        if (day === 0) day = 7;
        return day - 1;
    }

    dayIsToday() {
        return new Date().getDate();
    }

    monthIsToday() {
        return new Date().getMonth();
    }

    yearIsToday() {
        return new Date().getFullYear();
    }

    clickDay(month, day, year) {
        this.setState({
            selectedMonth: month,
            selectedDay: day,
            selectedYear: year,

            selectTypeHours: null,
            isTypeHours: false
        });
    }

    monthPlus() {
        // console.log(this.state.calendarMonth);
        if (this.state.calendarMonth < 11) {

            this.setState({
                calendarMonth: this.state.calendarMonth + 1,

                selectTypeHours: null,
                isTypeHours: false
            });
        }

        else {
            this.setState({
                calendarMonth: 0,
                calendarYear: this.state.calendarYear + 1,

                selectTypeHours: null,
                isTypeHours: false
            });
        }
    }

    monthMines() {
        // console.log(this.state.calendarMonth);
        if (this.state.calendarMonth > 0) {

            this.setState({
                calendarMonth: this.state.calendarMonth - 1,

                selectTypeHours: null,
                isTypeHours: false
            });
        }

        else {
            this.setState({
                calendarMonth: 11,
                calendarYear: this.state.calendarYear - 1,

                selectTypeHours: null,
                isTypeHours: false
            });
        }
    }

    // selectedCurrent(selectedCurrent) {
    //     this.setState({
    //         selectedCurrent: !selectedCurrent
    //     })
    // }


    currentWeekYear(month, day, year) {
        // Как идет отсчет недели в учебном графике!
        // Какая неделя сейчас!
        let firstSeptemberSunday = false;
        let firstWeekSeptember = 0;
        let currentWeekYear = 0;
        let lastYearWeek = 52;

        if (Number(month) > 7) {
            if (new Date(Number(year), 8, 1).getDay() === 0) {

                firstSeptemberSunday = true;
            }

            if (firstSeptemberSunday) {

                firstWeekSeptember = new Date(Number(year), 8, 2).getWeek();
            }

            else {

                firstWeekSeptember = new Date(Number(year), 8, 1).getWeek();
            }

            if (new Date(Number(year), Number(month), Number(day)).getWeek() === 1) {
                currentWeekYear = 53 - firstWeekSeptember + 1;
            }

            else {
                currentWeekYear = new Date(Number(year), Number(month), Number(day)).getWeek() - firstWeekSeptember + 1;
            }
        }

        else {

            if (new Date(Number(year) - 1, 8, 1).getDay() === 0) {
                firstSeptemberSunday = true;
            }

            if (firstSeptemberSunday) {

                firstWeekSeptember = new Date(Number(year) - 1, 8, 2).getWeek();
            }

            else {

                firstWeekSeptember = new Date(Number(year) - 1, 8, 1).getWeek();
            }

            if ((new Date(Number(year) - 1, 11, 31).getWeek() === 53) || (new Date(Number(year), 0, 1).getWeek() === 53)) {
                lastYearWeek = 53;
            }

            if (new Date(Number(year), Number(month), Number(day)).getWeek() === 53) {
                if (firstSeptemberSunday) {

                    currentWeekYear = lastYearWeek - firstWeekSeptember + 1;
                }

                else {

                    currentWeekYear = lastYearWeek - firstWeekSeptember + 1;
                }
            }

            else if (new Date(Number(year), Number(month), Number(day)).getWeek() === 52) {
                if (firstSeptemberSunday) {

                    currentWeekYear = lastYearWeek - firstWeekSeptember + 1;
                }

                else {

                    currentWeekYear = lastYearWeek - firstWeekSeptember + 1;
                }
            }

            else if (new Date(Number(year), Number(month), Number(day)).getWeek() === 1) {



                if (firstSeptemberSunday) {

                    currentWeekYear = lastYearWeek - firstWeekSeptember + 2;
                }

                else {

                    currentWeekYear = lastYearWeek - firstWeekSeptember + 2;
                }
            }

            else {

                if (firstSeptemberSunday) {

                    currentWeekYear = lastYearWeek - new Date(Number(year) - 1, 8, 2).getWeek() + new Date(Number(year), Number(month), Number(day)).getWeek() + 1;
                }

                else {

                    currentWeekYear = lastYearWeek - new Date(Number(year) - 1, 8, 1).getWeek() + new Date(Number(year), Number(month), Number(day)).getWeek() + 1;
                }
            }

            if (currentWeekYear === 53) {

                if (new Date(Number(year), 8, 1).getDay() > 0) {
                    currentWeekYear = 1;
                }
            }
        }

        return currentWeekYear;
    }

    isEditTimetables(isEditTimetables) {

        // const timetablesData = this.state.trainingScheduleData.schedule_timetables;

        this.setState({
                // isEditTypeWeeks: false,
                // typeWeeksData: [],
                // typeWeekNumber: 1,
                // isSaveTypeWeeks: false,
                isEditTimetables: !isEditTimetables,
                // timetablesData: (!isEditTimetables ? timetablesData : []),
                isSaveTimetables: false
            },
            () => {
                this.props.pageUpdate();
            });
    }

    trainingScheduleTimetableEdit() {
        const timetablesData = this.state.timetablesData;

        if (timetablesData.length > 0) {

            let formData = new FormData();

            let jsonTimetablesData = JSON.stringify(timetablesData);

            formData.append('schedule_timetables', jsonTimetablesData);

            if (!this.state.isSaveTimetables) {

                this.setState({
                    isSaveTimetables: true
                });

                this.props.apiRequest('api/cabinet/training/schedule/' + this.props.match.params.trainingScheduleId + '/timetables/edit', formData, () => {
                        this.props.notificationMessage(this.props.responseMessage, 'green');
                        this.props.pageUpdate();
                        this.setState({
                            isEditTimetables: false,
                            timetablesData: [],
                            isSaveTimetables: false
                        });
                    }, () => {
                        this.setState({
                            isSaveTimetables: false
                        });
                        this.props.notificationMessage(this.props.responseError, 'red');
                    }
                );
            }

            else {
                this.props.notificationMessage('Изменения по расписанию уже сохраняются.', 'red');
            }
        }

        else {
            this.props.notificationMessage('Нет данных по расписанию для изменений.', 'red');
        }

    }

    typeWeekNumber(number) {
        this.setState({
            typeWeekNumber: number
        });
    }

    linkOpen(link) {
        window.open(link, '_blank');
    }


    isTypeHours(isTypeHours) {


        this.setState({
                isTypeHours: !isTypeHours,
                selectTypeHours: null
            },
            () => {
                this.props.pageUpdate();
            });
    }

    selectTypeHours(selectTypeHours) {
        let stateSelectTypeHours = this.state.selectTypeHours;
        let trainingCourseData = this.state.trainingCourseData;

        let selectedDay = this.state.selectedDay;
        let selectedMonth = this.state.selectedMonth;
        let selectedYear = this.state.selectedYear;

        let maxLecture = 2;
        let maxLaboratory = 4;
        let maxPractical = 4;
        let maxDayHours = 0;

        let hoursLecture = 0;
        let hoursLaboratory = 0;
        let hoursPractical = 0;
        let dayHours = 0;

        if (Number(trainingCourseData.training_schedule.schedule_period["type_week_" + this.currentWeekYear(this.state.selectedMonth, this.state.selectedDay, this.state.selectedYear)]) > 0) {


            trainingCourseData.training_schedule.schedule_timetable.map(timetable => {
                if (Number(timetable[this.weekDayNameNumber(this.state.selectedMonth, this.state.selectedDay, this.state.selectedYear)]) > 0) {
                    maxDayHours = Number(timetable.max_hours_day);
                }
            });
        }


        trainingCourseData.course_schedule.map(course_schedule => {
            if ((Number(selectedDay) === Number(course_schedule.day_lesson)) &&
                ((Number(selectedMonth) + 1) === Number(course_schedule.month_lesson)) &&
                (Number(selectedYear) === Number(course_schedule.year_lesson))
            ) {
                if (course_schedule.lesson_type === 'lecture') {
                    if (Number(course_schedule.double_hours) === 1) {
                        hoursLecture = hoursLecture + 2;
                        dayHours = dayHours + 2;
                    } else {
                        hoursLecture = hoursLecture + 1;
                        dayHours = dayHours + 1;
                    }
                } else if (course_schedule.lesson_type === 'laboratory') {
                    if (Number(course_schedule.double_hours) === 1) {
                        hoursLaboratory = hoursLaboratory + 2;
                        dayHours = dayHours + 2;
                    } else {
                        hoursLaboratory = hoursLaboratory + 1;
                        dayHours = dayHours + 1;
                    }
                } else if (course_schedule.lesson_type === 'practical') {
                    if (Number(course_schedule.double_hours) === 1) {
                        hoursPractical = hoursPractical + 2;
                        dayHours = dayHours + 2;
                    } else {
                        hoursPractical = hoursPractical + 1;
                        dayHours = dayHours + 1;
                    }
                }
            }
        });

        trainingCourseData.group_schedule_other.map(course_schedule => {
            if ((Number(selectedDay) === Number(course_schedule.day_lesson)) &&
                ((Number(selectedMonth) + 1) === Number(course_schedule.month_lesson)) &&
                (Number(selectedYear) === Number(course_schedule.year_lesson))
            ) {
                if (Number(course_schedule.double_hours) === 1) {
                    dayHours = dayHours + 2;
                } else {
                    dayHours = dayHours + 1;
                }
            }
        });

        trainingCourseData.teacher_schedule.map(course_schedule => {
            if ((Number(selectedDay) === Number(course_schedule.day_lesson)) &&
                ((Number(selectedMonth) + 1) === Number(course_schedule.month_lesson)) &&
                (Number(selectedYear) === Number(course_schedule.year_lesson))
            ) {
                if (Number(course_schedule.double_hours) === 1) {
                    dayHours = dayHours + 2;
                } else {
                    dayHours = dayHours + 1;
                }
            }
        });








     if (dayHours >= maxDayHours) {
            this.props.notificationMessage('В день не более ' + maxDayHours + ' часов обучения.', 'red');
            this.setState({
                selectTypeHours: null,
                isTypeHours: false
            });
        }

        else if ((selectTypeHours === 'lecture') && (hoursLecture >= maxLecture)) {
                this.props.notificationMessage('В день не более 2 часов теоретических занятий по дисциплине.', 'red');
                this.setState({
                    selectTypeHours: null,
                    isTypeHours: false
                });
            }

            else if ((selectTypeHours === 'laboratory') && (hoursLaboratory >= maxLaboratory)) {
                this.props.notificationMessage('В день не более 4 часов лабораторных занятий по дисциплине.', 'red');
                this.setState({
                    selectTypeHours: null,
                    isTypeHours: false
                });
            }

            else if ((selectTypeHours === 'practical') && (hoursPractical >= maxPractical)) {
                this.props.notificationMessage('В день не более 4 часов практических занятий по дисциплине.', 'red');
                this.setState({
                    selectTypeHours: null,
                    isTypeHours: false
                });
            }



            else {

                if (stateSelectTypeHours === selectTypeHours) {
                    this.setState({
                        selectTypeHours: null,
                        isTypeHours: false
                    });
                }

                else {
                    this.setState({
                        selectTypeHours: selectTypeHours,
                        isTypeHours: true
                    });
                }
            }

    }

   weekDayNameNumber(month, day, year) {

        let weekNumber = new Date(Number(year), Number(month), Number(day)).getDay();

        switch (weekNumber) {
            case 1: return "week_day_mon";
            case 2: return "week_day_tue";
            case 3: return "week_day_wed";
            case 4: return "week_day_thu";
            case 5: return "week_day_fri";
            case 6: return "week_day_sat";
            case 0: return "week_day_sun";
            default: return null;
        }
    }

    alertOpen(alertForm, alertData) {
        this.props.alertOpen(alertForm, alertData);
    }

    handleSubmit(event) {
        event.preventDefault();

        if (this.state.chatMessageText && this.state.selectChat) {


            let formData = new FormData();

            formData.append('message', this.state.chatMessageText);



            this.setState({
                isHandleSubmit: true
            });

            this.props.apiRequest('api/cabinet/training/course/' + this.props.match.params.trainingCourseId + '/message/add', formData, () => {

                    this.setState({
                        isHandleSubmit: false
                    });


                    this.props.pageUpdate();
                    this.setStateAndInput('chatMessageText', null);

                }, () => {
                    this.props.notificationMessage(this.props.responseError, 'red');
                    this.setState({
                        isHandleSubmit: false
                    });
                }
            );

        }

        else {

            this.props.notificationMessage('Пожалуйста, внимательно заполните поле отправки сообщения.', 'red');
        }
    }

    setStateAndInput(name, value) {

        let input = document.getElementById(name);
        input.value = value;

        this.setState({
            [name]: value
        });
    }




    render() {
        const { isMount, isOpen, pageError, trainingCourseData,

            calendarData, selectedCurrent, isEditTimetables, typeWeekNumber, isTypeHours, selectTypeHours,
            selectedMonth, selectedDay, selectedYear,

            selectChat

        } = this.state;





        let modalData = (objectId, objectData) => {
            return {
                objectId: objectId,
                objectData: objectData
            };
        }

        let alertData = (objectId, objectData) => {
            return {
                objectId: objectId,
                objectData: objectData
            };
        }


        let lastDataTime = (time) => {

            if (Number(time) > 0) {

                let date = new Date(time * 1000);
                let day = "0" + date.getDate();
                let month = "0" + (date.getMonth() + 1);
                let year = String(date.getFullYear());
                let hours = "0" + date.getHours();
                let minutes = "0" + date.getMinutes();
                let seconds = "0" + date.getSeconds();
                let formattedData = day.substr(-2) + '.' + month.substr(-2) + '.' + year.substr(-2);
                // let formattedTime = hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
                let formattedTime = hours.substr(-2) + ':' + minutes.substr(-2);

                return formattedTime + ' ' + formattedData;
            }

            else {

                return null;
            }
        }


        if (isMount) {

            if (pageError) {

                if (!isOpen) {

                    return (
                        <div className="Page__loader">
                            <i/>
                            <div className="Body_nodata">
                                Страница не найдена
                                <div style={{textAlign: 'center', marginTop: 48, fontSize: 32}}>
                                    ERROR 404
                                </div>
                            </div>
                            <i/>
                        </div>
                    );
                }

                else {

                    return (
                        <div className="Page__loader">
                            <i/>
                            <div>
                                <div className="Body_nodata">Учебный курс удалён</div>
                                <div className="Header__path" style={{textAlign: 'center', marginTop: 48}}>
                                    <Link to={"/cabinet/teacher/training/courses"}>
                                        <div className="Path__link Path__link_text Path__link_blue">Учебные курсы</div>
                                    </Link>
                                </div>
                            </div>
                            <i/>
                        </div>
                    );
                }
            }

            else {

                if (!isOpen || !trainingCourseData) {
                    return (<div className="Page__loader"><i/><Loading/><i/></div>);
                }

                else {


                    // console.log(trainingCourseData.students);

        let timeYear = (time) => {
            const dateMonthYear = new Date(time * 1000);

            let yyyy = dateMonthYear.getFullYear();
            if (yyyy < 10) yyyy = '0' + yyyy;

            return yyyy;
        }

        let timeDay = (time) => {
            const dateMonthYear = new Date(time * 1000);

            let dd = dateMonthYear.getDate();
            if (dd < 10) dd = '0' + dd;

            return dd;
        }

        let timeMonth = (time) => {
            const dateMonthYear = new Date(time * 1000);

            return dateMonthYear.toLocaleString('ru', {month: 'long'});
        }

                    // Переменные выбранного дня на календаре.
                    let dateTimeEnd = Number(new Date(this.state.selectedYear + '-' + (this.state.selectedMonth + 1) + '-' + this.state.selectedDay).setHours(0, 0, 0, 0) / 1000 + 86399);
                    let dateTimeStart = Number(dateTimeEnd) - 86399;


        let isDayCourses = false;

                    return (
                        <div className="Page__section">

                            <div className="Section__header Section__header_margin-bottom-38">
                                <div className="Header__title">
                                    <div className="Title__text" style={{fontSize: 24}}>{trainingCourseData.discipline_name}</div>

                                </div>
                            </div>

                            <div className="Section__content"  style={{height: 'calc(100% - 38px - 38px - 2px - 42px)'}}>

                                <div className="StudentTrainingCourse">
                                    <div className="StudentTrainingCourse__body">

                                        <div className="Body__hr"/>




                                    <div className="Body__wrapper" style={{width: 460 + 42 + 2 + 42 + 594 + 42}}>











                                        <div className="Course" style={{display: "inline-block", height: "calc(100%)", width: 480}}>

                                            <div className="Course__header Course__header_margin-bottom-38" style={{height: "calc(100%)"}}>
                                                <Baron baronId={'lesson'}>

                                                {trainingCourseData.course_schedule.map((course_schedule, index) => (
                                                    (new Date(course_schedule.year_lesson + '-' + (Number(course_schedule.month_lesson)) + '-' + course_schedule.day_lesson).getTime() >
                                                        (new Date(this.state.selectedYear + '-' + (this.state.selectedMonth + 1) + '-' + this.state.selectedDay).getTime() - 1)
                                                        ) ? (

                                                        isDayCourses = true,





                                                            <React.Fragment key={index}>
                                                                <div style={{
                                                                    marginBottom: 24
                                                                }}>
                                                                    {/*<div className="Box__header" style={{*/}
                                                                    {/*    backgroundColor: "#eaeaea",*/}
                                                                    {/*    // backgroundColor: "#0061ff",*/}
                                                                    {/*    // backgroundColor: "#5d6c77",*/}
                                                                    {/*    // backgroundColor: ((course_schedule.lesson_type === 'lecture') ? "#ffc107" : (course_schedule.lesson_type === 'laboratory') ? "#8bc34a" : (course_schedule.lesson_type === 'practical') ? "#b7879d" : null),*/}
                                                                    {/*    padding: "24px 24px 24px",*/}
                                                                    {/*    borderRadius: "12px"*/}
                                                                    {/*}}>*/}
                                                                    {/*    <div className="Header__title" style={{*/}
                                                                    {/*        color: "#2A3E4C",*/}
                                                                    {/*        // color: "white",*/}
                                                                    {/*        fontSize: 24,*/}
                                                                    {/*        fontFamily: "Roboto, sans-serif",*/}
                                                                    {/*        fontWeight: 700,*/}
                                                                    {/*        marginBottom: 16,*/}
                                                                    {/*        opacity: 0.75*/}
                                                                    {/*    }}>*/}
                                                                    {/*        {course_schedule.discipline_name}*/}
                                                                    {/*    </div>*/}

                                                                    {/*    <div className="Header__title" style={{*/}
                                                                    {/*        color: "#2A3E4C",*/}
                                                                    {/*        // color: "white",*/}
                                                                    {/*        fontSize: 17,*/}
                                                                    {/*        fontFamily: "Roboto, sans-serif",*/}
                                                                    {/*        fontWeight: 400,*/}
                                                                    {/*        opacity: 0.75*/}
                                                                    {/*    }}>*/}
                                                                    {/*        {course_schedule.employee_fio}*/}
                                                                    {/*    </div>*/}
                                                                    {/*</div>*/}
                                                                    <div style={{
                                                                        backgroundColor: "white",
                                                                        borderRadius: "12px",
                                                                        padding: "24px 24px 0"
                                                                    }}>










                                                                        <div className="Course__info" key={index} style={{padding: 0, paddingBottom: 24, marginBottom: 0}}>

                                                                            <div className="Info__title" style={{opacity: 0.75, fontSize: 20, fontWeight: 700, marginBottom: 6, color: "#2A3E4C"}}>
                                                                                {/*{(course_schedule.lesson_type === 'lecture') ? 'Лекция' : null}*/}
                                                                                {/*{(course_schedule.lesson_type === 'laboratory') ? 'Лабораторное занятие' : null}*/}
                                                                                {/*{(course_schedule.lesson_type === 'practical') ? 'Практическое занятие' : null}*/}


                                                                            </div>

                                                                            {(course_schedule.lesson_type === 'lecture') ? (
                                                                                <div className="Item__index" style={{
                                                                                    // backgroundColor: "#fdd835",
                                                                                    // backgroundColor: "#c0ca33",
                                                                                    backgroundColor: "#fdd835",
                                                                                    // textTransform: "uppercase",
                                                                                    // isPassed Не сдан,
                                                                                    color: "#2A3E4C",
                                                                                }}>Лекция</div>
                                                                            ) : null}


                                                                            {(course_schedule.lesson_type === 'laboratory') ? (
                                                                                <div className="Item__index" style={{
                                                                                    backgroundColor: "#66bb6a",
                                                                                    // backgroundColor: "#e53935",
                                                                                    // textTransform: "uppercase"
                                                                                    // isPassed Не сдан
                                                                                    color: "#2A3E4C",
                                                                                }}>Лабораторное занятие</div>
                                                                            ) : null}

                                                                            {(course_schedule.lesson_type === 'practical') ? (
                                                                                <div className="Item__index" style={{
                                                                                    backgroundColor: "#00acc1",
                                                                                    // backgroundColor: "#e53935",
                                                                                    // textTransform: "uppercase"
                                                                                    // isPassed Не сдан
                                                                                    color: "#2A3E4C",
                                                                                }}>Практическое занятие</div>
                                                                            ) : null}

                                                                            {(course_schedule.lesson_type === 'exam') ? (
                                                                                <div className="Item__index" style={{
                                                                                    backgroundColor: "#7986cb",
                                                                                    // backgroundColor: "#e53935",
                                                                                    // textTransform: "uppercase"
                                                                                    // isPassed Не сдан
                                                                                    color: "#2A3E4C",
                                                                                }}>Экзаменационное занятие</div>
                                                                            ) : null}


                                                                            {/*<div className="Title__date" style={{opacity: 0.75, marginBottom: 24, fontWeight: 400, fontSize: 17, color: "#2A3E4C"}}>*/}
                                                                            {/*    {timeDay(new Date(course_schedule.year_lesson + '-' + (Number(course_schedule.month_lesson)) + '-' + course_schedule.day_lesson).setHours(0, 0, 0, 0) / 1000)}*/}
                                                                            {/*    &nbsp; {timeMonth(new Date(course_schedule.year_lesson + '-' + (Number(course_schedule.month_lesson)) + '-' + course_schedule.day_lesson).setHours(0, 0, 0, 0) / 1000)}*/}
                                                                            {/*    &nbsp; {timeYear(new Date(course_schedule.year_lesson + '-' + (Number(course_schedule.month_lesson)) + '-' + course_schedule.day_lesson).setHours(0, 0, 0, 0) / 1000)}*/}
                                                                            {/*    &nbsp; c <b style={{fontWeight: 900, fontSize: 20}}>{course_schedule.starting_hour}:00</b> до <b style={{fontWeight: 900, fontSize: 20}}>{Number(course_schedule.double_hours) > 0 ? Number(course_schedule.starting_hour) + 2 : Number(course_schedule.starting_hour) + 1}:00 </b>*/}
                                                                            {/*</div>*/}

                                                                            {(course_schedule.lesson_type === 'exam') ? (
                                                                                <div className="Title__date" style={{opacity: 0.75, marginBottom: 0, fontWeight: 400, fontSize: 17, color: "#2A3E4C"}}>
                                                                                    {timeDay(new Date(course_schedule.year_lesson + '-' + (Number(course_schedule.month_lesson)) + '-' + course_schedule.day_lesson).setHours(0, 0, 0, 0) / 1000)}
                                                                                    &nbsp; {timeMonth(new Date(course_schedule.year_lesson + '-' + (Number(course_schedule.month_lesson)) + '-' + course_schedule.day_lesson).setHours(0, 0, 0, 0) / 1000)}
                                                                                    &nbsp; {timeYear(new Date(course_schedule.year_lesson + '-' + (Number(course_schedule.month_lesson)) + '-' + course_schedule.day_lesson).setHours(0, 0, 0, 0) / 1000)}
                                                                                    &nbsp; c <b style={{fontWeight: 900, fontSize: 20}}>{course_schedule.starting_hour}:00</b>
                                                                                </div>
                                                                            ) : (
                                                                                <div className="Title__date" style={{opacity: 0.75, marginBottom: 24, fontWeight: 400, fontSize: 17, color: "#2A3E4C"}}>
                                                                                    {timeDay(new Date(course_schedule.year_lesson + '-' + (Number(course_schedule.month_lesson)) + '-' + course_schedule.day_lesson).setHours(0, 0, 0, 0) / 1000)}
                                                                                    &nbsp; {timeMonth(new Date(course_schedule.year_lesson + '-' + (Number(course_schedule.month_lesson)) + '-' + course_schedule.day_lesson).setHours(0, 0, 0, 0) / 1000)}
                                                                                    &nbsp; {timeYear(new Date(course_schedule.year_lesson + '-' + (Number(course_schedule.month_lesson)) + '-' + course_schedule.day_lesson).setHours(0, 0, 0, 0) / 1000)}
                                                                                    &nbsp; c <b style={{fontWeight: 900, fontSize: 20}}>{course_schedule.starting_hour}:00</b> до <b style={{fontWeight: 900, fontSize: 20}}>{Number(course_schedule.double_hours) > 0 ? Number(course_schedule.starting_hour) + 2 : Number(course_schedule.starting_hour) + 1}:00 </b>
                                                                                </div>
                                                                            )}

                                                                            {(course_schedule.lesson_theme && (course_schedule.lesson_theme !== 'null')) ? (
                                                                                <div className="Info__title" style={{marginBottom: 0}}>
                                                                                    {course_schedule.lesson_theme}
                                                                                </div>
                                                                            ) : null}


                                                                            {(course_schedule.lesson_description && (course_schedule.lesson_description !== 'null')) ? (
                                                                                <div className="Info__text" style={{marginTop: 12, marginBottom: 0, opacity: 1, fontSize: 17}}>
                                                                                    {course_schedule.lesson_description}
                                                                                </div>
                                                                            ) : null}

                                                                            {(course_schedule.link_lecture && (course_schedule.link_lecture !== 'null')) ? (
                                                                                <div className="Info__link"
                                                                                     style={{marginTop: 12, display: 'inline-block', cursor: 'pointer'}}
                                                                                     onClick={this.linkOpen.bind(this, course_schedule.link_lecture)}>
                                                                                    <div className="Link__text">
                                                                                        Учебный материал
                                                                                    </div>
                                                                                </div>
                                                                            ) : null}

                                                                            {(course_schedule.link_task && (course_schedule.link_task !== 'null')) ? (
                                                                                <div className="Info__link"
                                                                                     style={{marginTop: 12, display: 'inline-block', cursor: 'pointer'}}
                                                                                     onClick={this.linkOpen.bind(this, course_schedule.link_task)}>
                                                                                    <div className="Link__text">
                                                                                        Задание
                                                                                    </div>
                                                                                </div>
                                                                            ) : null}

                                                                            {(course_schedule.link_conference && (course_schedule.link_conference !== 'null')) ? (
                                                                                <div className="Info__link"
                                                                                     style={{marginTop: 12, display: 'inline-block', cursor: 'pointer'}}
                                                                                     onClick={this.linkOpen.bind(this, course_schedule.link_conference)}>

                                                                                    <div className="Link__text">
                                                                                        Вебинар
                                                                                    </div>
                                                                                </div>
                                                                            ) : null}




                                                                            {/*<div className="Info__edit"*/}
                                                                            {/*     onClick={this.modalOpen.bind(this, 'modalTeacherTrainingCourseLessonEdit', modalData(trainingCourseData.id, course_schedule))}>*/}

                                                                            {/*    <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">*/}
                                                                            {/*        <g id="Group_2240" data-name="Group 2240" transform="translate(-471 -775)">*/}
                                                                            {/*            <rect id="Rectangle_2757" data-name="Rectangle 2757" width="42" height="42" transform="translate(471 775)" fill="#f7f7f7" opacity="0"/>*/}
                                                                            {/*            <g id="Group_2239" data-name="Group 2239" transform="translate(50 28)">*/}
                                                                            {/*                <path id="Path_965" data-name="Path 965" d="M2.657,17.495a2.447,2.447,0,0,1,.707-1.509L13.983,5.367l1.73,1.73L6.2,16.612l1.73,1.73,9.515-9.515,1.73,1.73L9.658,20.073l1.73,1.73L20.9,12.287l1.73,1.73L12.014,24.636a2.447,2.447,0,0,1-1.509.707l-7.171.652a1.223,1.223,0,0,1-1.329-1.329Zm6.478,5.516-3.656.332A1.4,1.4,0,0,0,5.1,22.9c-.31-.272-.454-.272-.454-.272l.342-3.762Z" transform="translate(428 754)" fill="#2a3e4c" fillRule="evenodd"/>*/}
                                                                            {/*                <path id="Path_966" data-name="Path 966" d="M13.054,4.447l1.731-1.731a2.447,2.447,0,0,1,3.461,0l5.192,5.192a2.447,2.447,0,0,1,0,3.461L21.707,13.1Z" transform="translate(429.852 754)" fill="#2a3e4c"/>*/}
                                                                            {/*            </g>*/}
                                                                            {/*        </g>*/}
                                                                            {/*    </svg>*/}


                                                                            {/*</div>*/}
                                                                        </div>
















                                                                    </div>
                                                                </div>
                                                            </React.Fragment>




                                                    ) : null
                                                ))}


                                                {!isDayCourses ? (
                                                    <React.Fragment>
                                                        <div className="Page__loader">
                                                            <i/>
                                                            <div className="Body_nodata" style={{
                                                                marginTop: 0,
                                                                opacity: .15,
                                                                fontSize: 56
                                                            }}>
                                                                Нет занятий
                                                            </div>
                                                            <i/>
                                                        </div>
                                                    </React.Fragment>
                                                ) : null}

                                                </Baron>
                                            </div>


                                        </div>



                                        <div className="Day" style={{display: "inline-block"}}>




                                            <div className="Chat" style={{display: "inline-block", width: 552 + 42}}>
                                                {selectChat ? (
                                                    <React.Fragment>

                                                        <div className="Chat__room _after">
                                                            <Baron baronId={'chat'}>

                                                                <div style={{
                                                                    paddingTop: 56
                                                                }}/>

                                                                {selectChat.map((chat_message, index) => (
                                                                    <div key={index} style={{
                                                                        marginTop: 24
                                                                    }}>




                                                                        <div className="Course__info" style={{
                                                                            verticalAlign: "top",
                                                                            display: "inline-block",
                                                                            padding: 16,
                                                                            maxWidth: 440,
                                                                            backgroundColor: (chat_message.user_type === 'student' ? "white" : "#eee"),
                                                                            borderRadius: 7
                                                                        }}>
                                                                            <div style={{
                                                                                marginBottom: 2
                                                                            }}>
                                                                                {chat_message.user_type === 'student' ? (

                                                                                    trainingCourseData.students.map((student) => (
                                                                                        (Number(student.id) === Number(chat_message.user_id)) ? (
                                                                                            <React.Fragment key={student.id}>
                                                                                                {student.student_avatar ? (
                                                                                                <div style={{
                                                                                                    marginRight: 16,
                                                                                                    verticalAlign: "top",
                                                                                                    position: "relative",
                                                                                                    display: "inline-block"
                                                                                                }}>
                                                                                                    <div className="Student__photo1">
                                                                                                        {/*<svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">*/}
                                                                                                        {/*    <image id="baseline_account_circle_black_36dp" width="42" height="42" opacity="0.1" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAABtUlEQVR4Ae3WAWQCURjA8SGEEIZDGIZDCMMQxjAMIUAIAwwQBghhOBxggBCGw2GAACGEMAwhHA4hhBC+/RHort537y5Bf36Azuu9d+/dzZm61oSHEFMs9yYIMEADZ+0WPlYQpQh9VFBYJfSxhliK8Y7cOZhCCvKTZ7ZcRJCC/cKxmZkYorBAgBCRflD6mSpjBjHYoJuy33rYqZZPmQdRaONYPYiZeaPXsIUYTE1vpnL5YtPSDSEKPkyFEIU+UqtgC1HwYOpbf3im14IojWFqAVFqINEIksETjtWFZDBAopnFdZA2qDY2kAwCJIogFsbw4Oe4ZiZIJBe0vOCA9G9aDLmQKRLNM675J1qo426vgTb8jM8LkSiAnLCDj3toa2Boe9B2DK/4A2x7MXwBNJGoeuJHr8jbFyTFyuZCrCNvzzYXtXtklv7gwDYHS8iBNW5B2T9B5qgha3Us9J8eyaonHrDGG0owVcYHtifOnhJUuYYLcokBHlE5+DNP8A0HbWSzBZqGQdmK4cIqFwtIQWZwkKsqhthBLG3hoYzCchFil3EgQ9RwtqroIMAc8cFmnWGEFiootGv/gzxqH8WJhToAAAAASUVORK5CYII="/>*/}
                                                                                                        {/*</svg>*/}

                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" id="account_circle-black-18dp" width="42" height="42" viewBox="0 0 100 100">
                                                                                                            <path id="Path_1" data-name="Path 1" d="M0,0H100V100H0Z" fill="none"/>
                                                                                                            <path opacity="0.1" id="Path_2" data-name="Path 2" d="M50,2A48,48,0,1,0,98,50,48.018,48.018,0,0,0,50,2Zm0,14.4A14.4,14.4,0,1,1,35.6,30.8,14.381,14.381,0,0,1,50,16.4Zm0,68.16A34.563,34.563,0,0,1,21.2,69.1C21.344,59.552,40.4,54.32,50,54.32c9.552,0,28.656,5.232,28.8,14.784A34.563,34.563,0,0,1,50,84.56Z" fill="#000"/>
                                                                                                        </svg>
                                                                                                    </div>
                                                                                                    <img style={{
                                                                                                        position: "absolute",
                                                                                                        top: 0,
                                                                                                        left: 0,
                                                                                                        width: 42,
                                                                                                        height: 42,
                                                                                                        borderRadius: "50%",
                                                                                                        opacity: 1
                                                                                                    }}
                                                                                                         src={baseURL() + 'api/images/avatar/' + student.student_avatar}
                                                                                                         width="100"
                                                                                                         height="100"/>
                                                                                                </div>
                                                                                            ) : (
                                                                                                <div style={{
                                                                                                    marginRight: 16,
                                                                                                    verticalAlign: "top",
                                                                                                    position: "relative",
                                                                                                    display: "inline-block"
                                                                                                }}>
                                                                                                    <div className="Student__photo1">
                                                                                                        {/*<svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">*/}
                                                                                                        {/*    <image id="baseline_account_circle_black_36dp" width="42" height="42" opacity="0.1" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAABtUlEQVR4Ae3WAWQCURjA8SGEEIZDGIZDCMMQxjAMIUAIAwwQBghhOBxggBCGw2GAACGEMAwhHA4hhBC+/RHort537y5Bf36Azuu9d+/dzZm61oSHEFMs9yYIMEADZ+0WPlYQpQh9VFBYJfSxhliK8Y7cOZhCCvKTZ7ZcRJCC/cKxmZkYorBAgBCRflD6mSpjBjHYoJuy33rYqZZPmQdRaONYPYiZeaPXsIUYTE1vpnL5YtPSDSEKPkyFEIU+UqtgC1HwYOpbf3im14IojWFqAVFqINEIksETjtWFZDBAopnFdZA2qDY2kAwCJIogFsbw4Oe4ZiZIJBe0vOCA9G9aDLmQKRLNM675J1qo426vgTb8jM8LkSiAnLCDj3toa2Boe9B2DK/4A2x7MXwBNJGoeuJHr8jbFyTFyuZCrCNvzzYXtXtklv7gwDYHS8iBNW5B2T9B5qgha3Us9J8eyaonHrDGG0owVcYHtifOnhJUuYYLcokBHlE5+DNP8A0HbWSzBZqGQdmK4cIqFwtIQWZwkKsqhthBLG3hoYzCchFil3EgQ9RwtqroIMAc8cFmnWGEFiootGv/gzxqH8WJhToAAAAASUVORK5CYII="/>*/}
                                                                                                        {/*</svg>*/}

                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" id="account_circle-black-18dp" width="42" height="42" viewBox="0 0 100 100">
                                                                                                            <path id="Path_1" data-name="Path 1" d="M0,0H100V100H0Z" fill="none"/>
                                                                                                            <path opacity="0.1" id="Path_2" data-name="Path 2" d="M50,2A48,48,0,1,0,98,50,48.018,48.018,0,0,0,50,2Zm0,14.4A14.4,14.4,0,1,1,35.6,30.8,14.381,14.381,0,0,1,50,16.4Zm0,68.16A34.563,34.563,0,0,1,21.2,69.1C21.344,59.552,40.4,54.32,50,54.32c9.552,0,28.656,5.232,28.8,14.784A34.563,34.563,0,0,1,50,84.56Z" fill="#000"/>
                                                                                                        </svg>
                                                                                                    </div>
                                                                                                </div>
                                                                                            )}

                                                                                                <div style={{
                                                                                                    display: "inline-block",
                                                                                                    verticalAlign: "top"
                                                                                                }}>
                                                                                                    <div className="Info__title" style={{
                                                                                                        display: "inline-block",
                                                                                                        // opacity: 0.75,
                                                                                                        fontSize: 15, fontWeight: 700,
                                                                                                        marginBottom: 6,
                                                                                                        marginRight: 16,
                                                                                                        color: "#2A3E4C"}}>
                                                                                                        {/*{chat_message.user_type === 'student' ? selectChat.student_fio.split(' ')[0] + ' ' + selectChat.student_fio.split(' ')[1] : null}*/}
                                                                                                        {chat_message.user_type === 'student' ? student.student_fio : null}
                                                                                                        {/*{chat_message.user_type === 'teacher' ? student.employee_fio : null}*/}
                                                                                                    </div>
                                                                                                    <br/>
                                                                                                    <div className="Title__date" style={{
                                                                                                        display: "inline-block",
                                                                                                        opacity: 0.75,
                                                                                                        marginBottom: 0,
                                                                                                        fontWeight: 400,
                                                                                                        fontSize: 13,
                                                                                                        color: "#2A3E4C"
                                                                                                    }}>
                                                                                                        {lastDataTime(chat_message.time)}
                                                                                                    </div>
                                                                                                </div>

                                                                                            </React.Fragment>
                                                                                        ) : null
                                                                                    ))
                                                                                ) : null}

                                                                                {chat_message.user_type === 'teacher' ? (
                                                                                        <React.Fragment>
                                                                                            {trainingCourseData.employee.employee_avatar ? (
                                                                                        <div style={{
                                                                                            marginRight: 16,
                                                                                            verticalAlign: "top",
                                                                                            position: "relative",
                                                                                            display: "inline-block"
                                                                                        }}>
                                                                                            <div className="Student__photo1">
                                                                                                {/*<svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">*/}
                                                                                                {/*    <image id="baseline_account_circle_black_36dp" width="42" height="42" opacity="0.1" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAABtUlEQVR4Ae3WAWQCURjA8SGEEIZDGIZDCMMQxjAMIUAIAwwQBghhOBxggBCGw2GAACGEMAwhHA4hhBC+/RHort537y5Bf36Azuu9d+/dzZm61oSHEFMs9yYIMEADZ+0WPlYQpQh9VFBYJfSxhliK8Y7cOZhCCvKTZ7ZcRJCC/cKxmZkYorBAgBCRflD6mSpjBjHYoJuy33rYqZZPmQdRaONYPYiZeaPXsIUYTE1vpnL5YtPSDSEKPkyFEIU+UqtgC1HwYOpbf3im14IojWFqAVFqINEIksETjtWFZDBAopnFdZA2qDY2kAwCJIogFsbw4Oe4ZiZIJBe0vOCA9G9aDLmQKRLNM675J1qo426vgTb8jM8LkSiAnLCDj3toa2Boe9B2DK/4A2x7MXwBNJGoeuJHr8jbFyTFyuZCrCNvzzYXtXtklv7gwDYHS8iBNW5B2T9B5qgha3Us9J8eyaonHrDGG0owVcYHtifOnhJUuYYLcokBHlE5+DNP8A0HbWSzBZqGQdmK4cIqFwtIQWZwkKsqhthBLG3hoYzCchFil3EgQ9RwtqroIMAc8cFmnWGEFiootGv/gzxqH8WJhToAAAAASUVORK5CYII="/>*/}
                                                                                                {/*</svg>*/}

                                                                                                <svg xmlns="http://www.w3.org/2000/svg" id="account_circle-black-18dp" width="42" height="42" viewBox="0 0 100 100">
                                                                                                    <path id="Path_1" data-name="Path 1" d="M0,0H100V100H0Z" fill="none"/>
                                                                                                    <path opacity="0.1" id="Path_2" data-name="Path 2" d="M50,2A48,48,0,1,0,98,50,48.018,48.018,0,0,0,50,2Zm0,14.4A14.4,14.4,0,1,1,35.6,30.8,14.381,14.381,0,0,1,50,16.4Zm0,68.16A34.563,34.563,0,0,1,21.2,69.1C21.344,59.552,40.4,54.32,50,54.32c9.552,0,28.656,5.232,28.8,14.784A34.563,34.563,0,0,1,50,84.56Z" fill="#000"/>
                                                                                                </svg>
                                                                                            </div>
                                                                                            <img style={{
                                                                                                position: "absolute",
                                                                                                top: 0,
                                                                                                left: 0,
                                                                                                width: 42,
                                                                                                height: 42,
                                                                                                borderRadius: "50%",
                                                                                                opacity: 1
                                                                                            }}
                                                                                                 src={baseURL() + 'api/images/avatar/' + trainingCourseData.employee.employee_avatar}
                                                                                                 width="100"
                                                                                                 height="100"/>
                                                                                        </div>
                                                                                    ) : (
                                                                                        <div style={{
                                                                                            marginRight: 16,
                                                                                            verticalAlign: "top",
                                                                                            position: "relative",
                                                                                            display: "inline-block"
                                                                                        }}>
                                                                                            <div className="Student__photo1">
                                                                                                {/*<svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">*/}
                                                                                                {/*    <image id="baseline_account_circle_black_36dp" width="42" height="42" opacity="0.1" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAABtUlEQVR4Ae3WAWQCURjA8SGEEIZDGIZDCMMQxjAMIUAIAwwQBghhOBxggBCGw2GAACGEMAwhHA4hhBC+/RHort537y5Bf36Azuu9d+/dzZm61oSHEFMs9yYIMEADZ+0WPlYQpQh9VFBYJfSxhliK8Y7cOZhCCvKTZ7ZcRJCC/cKxmZkYorBAgBCRflD6mSpjBjHYoJuy33rYqZZPmQdRaONYPYiZeaPXsIUYTE1vpnL5YtPSDSEKPkyFEIU+UqtgC1HwYOpbf3im14IojWFqAVFqINEIksETjtWFZDBAopnFdZA2qDY2kAwCJIogFsbw4Oe4ZiZIJBe0vOCA9G9aDLmQKRLNM675J1qo426vgTb8jM8LkSiAnLCDj3toa2Boe9B2DK/4A2x7MXwBNJGoeuJHr8jbFyTFyuZCrCNvzzYXtXtklv7gwDYHS8iBNW5B2T9B5qgha3Us9J8eyaonHrDGG0owVcYHtifOnhJUuYYLcokBHlE5+DNP8A0HbWSzBZqGQdmK4cIqFwtIQWZwkKsqhthBLG3hoYzCchFil3EgQ9RwtqroIMAc8cFmnWGEFiootGv/gzxqH8WJhToAAAAASUVORK5CYII="/>*/}
                                                                                                {/*</svg>*/}

                                                                                                <svg xmlns="http://www.w3.org/2000/svg" id="account_circle-black-18dp" width="42" height="42" viewBox="0 0 100 100">
                                                                                                    <path id="Path_1" data-name="Path 1" d="M0,0H100V100H0Z" fill="none"/>
                                                                                                    <path opacity="0.1" id="Path_2" data-name="Path 2" d="M50,2A48,48,0,1,0,98,50,48.018,48.018,0,0,0,50,2Zm0,14.4A14.4,14.4,0,1,1,35.6,30.8,14.381,14.381,0,0,1,50,16.4Zm0,68.16A34.563,34.563,0,0,1,21.2,69.1C21.344,59.552,40.4,54.32,50,54.32c9.552,0,28.656,5.232,28.8,14.784A34.563,34.563,0,0,1,50,84.56Z" fill="#000"/>
                                                                                                </svg>
                                                                                            </div>
                                                                                        </div>
                                                                                    )}

                                                                                            <div style={{
                                                                                                display: "inline-block",
                                                                                                verticalAlign: "top"
                                                                                            }}>
                                                                                                <div className="Info__title" style={{
                                                                                                    display: "inline-block",
                                                                                                    // opacity: 0.75,
                                                                                                    fontSize: 15, fontWeight: 700,
                                                                                                    marginBottom: 6,
                                                                                                    marginRight: 16,
                                                                                                    color: "#2A3E4C"}}>
                                                                                                    {/*{chat_message.user_type === 'student' ? selectChat.student_fio.split(' ')[0] + ' ' + selectChat.student_fio.split(' ')[1] : null}*/}
                                                                                                    {/*{chat_message.user_type === 'student' ? student.student_fio : null}*/}
                                                                                                    {chat_message.user_type === 'teacher' ? trainingCourseData.employee.employee_fio : null}
                                                                                                </div>
                                                                                                <br/>
                                                                                                <div className="Title__date" style={{
                                                                                                    display: "inline-block",
                                                                                                    opacity: 0.75,
                                                                                                    marginBottom: 0,
                                                                                                    fontWeight: 400,
                                                                                                    fontSize: 13,
                                                                                                    color: "#2A3E4C"
                                                                                                }}>
                                                                                                    {lastDataTime(chat_message.time)}
                                                                                                </div>
                                                                                            </div>

                                                                                        </React.Fragment>
                                                                                ) : null}


                                                                            </div>

                                                                            <div className="Info__text" style={{
                                                                                marginBottom: 0,
                                                                                opacity: 1,
                                                                                fontFamily: "Roboto, sans-serif",
                                                                                fontSize: 15,
                                                                                color: "#2a3e4c",
                                                                                paddingLeft: 42 + 16
                                                                            }}>
                                                                                {chat_message.message}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}

                                                            </Baron>
                                                        </div>



                                                        <div className="Chat__send">

                                                            <form onSubmit={this.handleSubmit.bind(this)}>
                                                                <input className="Field__input Input__chat"
                                                                       style={{
                                                                           display: "inline-block",
                                                                           backgroundColor: "#f7f7f7",
                                                                           border: "2px solid #dadada",
                                                                           width: 340 + 16 + 56,
                                                                           padding: "16px 16px",
                                                                           fontSize: 17,
                                                                           color: "#2A3E4C",
                                                                           fontFamily: "Roboto, sans-serif",
                                                                           fontWeight: 400,
                                                                           verticalAlign: "top",
                                                                           borderRadius: 7,
                                                                           marginRight: 24

                                                                       }}
                                                                       id="chatMessageText"
                                                                       name="chatMessageText"
                                                                       type="text"
                                                                       placeholder="Текст сообщения..."
                                                                       value={this.state.value}
                                                                       onChange={this.handleInputChange.bind(this)}/>

                                                                <button style={{
                                                                    display: "inline-block",
                                                                    backgroundColor: "#f7f7f7",
                                                                    border: "none",
                                                                    // height: 24,
                                                                    // width: 24,
                                                                    // cursor: "pointer",
                                                                    padding: 0,
                                                                    marginBottom: 0,
                                                                    verticalAlign: "top",
                                                                    marginRight: 24
                                                                }}>
                                                                    <div style={{
                                                                        display: "inline-block",
                                                                        // backgroundColor: "#f7f7f7",
                                                                        // border: "none",
                                                                        height: 24,
                                                                        width: 24,
                                                                        cursor: "pointer",
                                                                        padding: 16,
                                                                        verticalAlign: "top"
                                                                    }}>
                                                                        {/*<svg xmlns="http://www.w3.org/2000/svg" width="23.993" height="24" viewBox="0 0 23.993 24">*/}
                                                                        {/*    <path id="paper-plane-solid" d="M22.318.121.6,12.653a1.126,1.126,0,0,0,.1,2.025l4.982,2.09L19.145,4.9a.281.281,0,0,1,.4.389L8.258,19.045v3.773a1.124,1.124,0,0,0,1.992.74l2.976-3.623,5.839,2.446a1.128,1.128,0,0,0,1.547-.853L23.986,1.283A1.125,1.125,0,0,0,22.318.121Z" transform="translate(-0.01 0.031)" fill="#0061ff"/>*/}
                                                                        {/*</svg>*/}

                                                                        <svg aria-hidden="true" focusable="false"
                                                                             data-prefix="fas" data-icon="paper-plane"
                                                                             className="svg-inline--fa fa-paper-plane fa-w-16"
                                                                             role="img" xmlns="http://www.w3.org/2000/svg"
                                                                             viewBox="0 0 512 512">
                                                                            <path fill="#0061ff"
                                                                                  d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z"/>
                                                                        </svg>
                                                                    </div>
                                                                </button>

                                                            </form>
                                                        </div>

                                                    </React.Fragment>
                                                ) : null}

                                            </div>


                                        </div>



                                    </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }
            }
        }

        else return null;
    }
}

Date.prototype.getWeek = function() {
    var date = new Date(this.getTime());
    date.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    // January 4 is always in week 1.
    var week1 = new Date(date.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
        - 3 + (week1.getDay() + 6) % 7) / 7);
}

// Returns the four-digit year corresponding to the ISO week of the date.
Date.prototype.getWeekYear = function() {
    var date = new Date(this.getTime());
    date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
    return date.getFullYear();
}

function mapStateToProps(state) {
    return {
        isLoad: state.page.isLoad,
        isUpdate: state.page.isUpdate,
        responseData: state.api.responseData,
        responseError:  state.api.responseError
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageMount: () => dispatch(pageMount()),
        pageLoad: (isLoad) => dispatch(pageLoad(isLoad)),
        pageUpdate: () => dispatch(pageUpdate()),
        alertOpen: (alertForm, alertData) => dispatch(alertOpen(alertForm, alertData)),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        notificationMessage: (message, colorMessage) => dispatch(notificationMessage(message, colorMessage)),
        modalOpen: (modalForm, modalData) => dispatch(modalOpen(modalForm, modalData))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (StudentTrainingCourse);
