import React from "react";
import {connect} from "react-redux";
import {pageLoad, pageUpdate, pageMount} from "../../../../../store/actions/pageAction";
import {apiRequest} from "../../../../../store/actions/apiAction";
import {modalOpen} from "../../../../../store/actions/modalAction";
import "./StudentTrainingSchedule.scss";
import Loading from "../../../../loading/Loading";
import {Link} from "react-router-dom";

class StudentTrainingSchedule extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isMount: true,
            isOpen: false,
            isLoad: false,
            isUpdate: false,
            studentTrainingCoursesData: null,



            calendarMonth: new Date().getMonth(), // месяцы в JS идут от 0 до 11, а не от 1 до 12
            calendarYear: new Date().getFullYear(),
            // calendarDay: new Date().getDate(),
            selectedDay: new Date().getDate(),
            selectedMonth: new Date().getMonth(),
            selectedYear: new Date().getFullYear(),
        };
    }

    componentDidMount() {
        this.props.pageMount();
        this.pageUpdate(this.state.isLoad);
        this.intervalUpdate = setInterval(this.props.pageUpdate, 180000);
    }

    componentWillUnmount() {
        // console.log('Employees Unmounted');
        clearInterval(this.intervalUpdate);
        this.setState({
            isMount: false
        });
    }

    componentDidUpdate() {
        if (this.state.isMount) {
            const responseData = this.props.responseData;
            // console.log(1 + ' componentDidUpdate');
            if (this.props.isLoad !== this.state.isLoad) {
                // console.log(2 + ' isLoad');
                if (responseData !== this.state.studentTrainingCoursesData) {
                    // console.log(3 + ' responseData');

                    this.setState({
                        isOpen: true,
                        isLoad: this.props.isLoad,
                        isUpdate: this.props.isUpdate,
                        studentTrainingCoursesData: responseData
                    });
                }
            }

            else if (this.props.isUpdate !== this.state.isUpdate) {
                // console.log(4 + ' isUpdate');
                this.pageUpdate(this.state.isLoad);

                this.setState({
                    isUpdate: this.props.isUpdate
                });
            }
        }
    }

    pageUpdate(isLoad) {
        this.props.apiRequest('api/cabinet/student/training/courses', null, () => {
            this.props.pageLoad(isLoad);
        }, null);
    }

    modalOpen(modalForm, modalData) {
        this.props.modalOpen(modalForm, modalData);
    }






















    firstDayOfMonth(date) {
        // Получить номер дня недели, от 0 (пн) до 6 (вс).
        let day = new Date(date).getDay();
        // Сделать воскресенье (0) последним днем.
        if (day === 0) day = 7;
        return day - 1;
    }

    dayIsToday() {
        return new Date().getDate();
    }

    monthIsToday() {
        return new Date().getMonth();
    }

    yearIsToday() {
        return new Date().getFullYear();
    }

    clickDay(month, day, year) {
        this.setState({
            selectedMonth: month,
            selectedDay: day,
            selectedYear: year,

            selectTypeHours: null,
            isTypeHours: false
        });

        window.scrollTo(0, 0);
    }

    monthPlus() {
        // console.log(this.state.calendarMonth);
        if (this.state.calendarMonth < 11) {

            this.setState({
                calendarMonth: this.state.calendarMonth + 1,

                selectTypeHours: null,
                isTypeHours: false
            });
        }

        else {
            this.setState({
                calendarMonth: 0,
                calendarYear: this.state.calendarYear + 1,

                selectTypeHours: null,
                isTypeHours: false
            });
        }
    }

    monthMines() {
        // console.log(this.state.calendarMonth);
        if (this.state.calendarMonth > 0) {

            this.setState({
                calendarMonth: this.state.calendarMonth - 1,

                selectTypeHours: null,
                isTypeHours: false
            });
        }

        else {
            this.setState({
                calendarMonth: 11,
                calendarYear: this.state.calendarYear - 1,

                selectTypeHours: null,
                isTypeHours: false
            });
        }
    }

    // selectedCurrent(selectedCurrent) {
    //     this.setState({
    //         selectedCurrent: !selectedCurrent
    //     })
    // }


    currentWeekYear(month, day, year) {
        // Как идет отсчет недели в учебном графике!
        // Какая неделя сейчас!
        let firstSeptemberSunday = false;
        let firstWeekSeptember = 0;
        let currentWeekYear = 0;
        let lastYearWeek = 52;

        if (Number(month) > 7) {
            if (new Date(Number(year), 8, 1).getDay() === 0) {

                firstSeptemberSunday = true;
            }

            if (firstSeptemberSunday) {

                firstWeekSeptember = new Date(Number(year), 8, 2).getWeek();
            }

            else {

                firstWeekSeptember = new Date(Number(year), 8, 1).getWeek();
            }

            if (new Date(Number(year), Number(month), Number(day)).getWeek() === 1) {
                currentWeekYear = 53 - firstWeekSeptember + 1;
            }

            else {
                currentWeekYear = new Date(Number(year), Number(month), Number(day)).getWeek() - firstWeekSeptember + 1;
            }
        }

        else {

            if (new Date(Number(year) - 1, 8, 1).getDay() === 0) {
                firstSeptemberSunday = true;
            }

            if (firstSeptemberSunday) {

                firstWeekSeptember = new Date(Number(year) - 1, 8, 2).getWeek();
            }

            else {

                firstWeekSeptember = new Date(Number(year) - 1, 8, 1).getWeek();
            }

            if ((new Date(Number(year) - 1, 11, 31).getWeek() === 53) || (new Date(Number(year), 0, 1).getWeek() === 53)) {
                lastYearWeek = 53;
            }

            if (new Date(Number(year), Number(month), Number(day)).getWeek() === 53) {
                if (firstSeptemberSunday) {

                    currentWeekYear = lastYearWeek - firstWeekSeptember + 1;
                }

                else {

                    currentWeekYear = lastYearWeek - firstWeekSeptember + 1;
                }
            }

            else if (new Date(Number(year), Number(month), Number(day)).getWeek() === 52) {
                if (firstSeptemberSunday) {

                    currentWeekYear = lastYearWeek - firstWeekSeptember + 1;
                }

                else {

                    currentWeekYear = lastYearWeek - firstWeekSeptember + 1;
                }
            }

            else if (new Date(Number(year), Number(month), Number(day)).getWeek() === 1) {



                if (firstSeptemberSunday) {

                    currentWeekYear = lastYearWeek - firstWeekSeptember + 2;
                }

                else {

                    currentWeekYear = lastYearWeek - firstWeekSeptember + 2;
                }
            }

            else {

                if (firstSeptemberSunday) {

                    currentWeekYear = lastYearWeek - new Date(Number(year) - 1, 8, 2).getWeek() + new Date(Number(year), Number(month), Number(day)).getWeek() + 1;
                }

                else {

                    currentWeekYear = lastYearWeek - new Date(Number(year) - 1, 8, 1).getWeek() + new Date(Number(year), Number(month), Number(day)).getWeek() + 1;
                }
            }

            if (currentWeekYear === 53) {

                if (new Date(Number(year), 8, 1).getDay() > 0) {
                    currentWeekYear = 1;
                }
            }
        }

        return currentWeekYear;
    }




    linkOpen(link) {
        window.open(link, '_blank');
    }
























    render() {
        const { isMount, isOpen, studentTrainingCoursesData,
            selectedMonth, selectedDay, selectedYear
        } = this.state;

        let modalData = (objectId, objectData) => {
            return {
                objectId: objectId,
                objectData: objectData
            };
        }

        let groupName = (groupName, initialYear) => {
            let course;

            let date = new Date();
            course = (date.getFullYear() - Number(initialYear) + 1);

            if (groupName.search('\$') > 0) {
                return groupName.replace(/\$/i, course);
            }

            else {
                return groupName;
            }
        }

        if (isMount) {

            if (!isOpen || !studentTrainingCoursesData || !studentTrainingCoursesData.training_schedule) {
                return (<div className="Page__loader"><i/><Loading/><i/></div>);
            }

            else {



















                // let projectsData = calendarData.project;
                // let eventsData = calendarData.event;
                // let problemsData = calendarData.problem;

                let projectsData = [];
                let eventsData = [];
                let problemsData = [];

                let timeYear = (time) => {
                    const dateMonthYear = new Date(time * 1000);

                    let yyyy = dateMonthYear.getFullYear();
                    if (yyyy < 10) yyyy = '0' + yyyy;

                    return yyyy;
                }

                let timeDay = (time) => {
                    const dateMonthYear = new Date(time * 1000);

                    let dd = dateMonthYear.getDate();
                    if (dd < 10) dd = '0' + dd;

                    return dd;
                }

                let timeMonth = (time) => {
                    const dateMonthYear = new Date(time * 1000);

                    return dateMonthYear.toLocaleString('ru', {month: 'long'});
                }

                let weekDay = (time) => {
                    const dateMonthYear = new Date(time * 1000);

                    // let weekDays = ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'];
                    let weekDays = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
                    let weekDay = dateMonthYear.getDay();

                    return weekDays[weekDay];
                }

                // Переменные выбранного дня на календаре.
                let dateTimeEnd = Number(new Date(this.state.selectedYear + '-' + (this.state.selectedMonth + 1) + '-' + this.state.selectedDay).setHours(0, 0, 0, 0) / 1000 + 86399);
                let dateTimeStart = Number(dateTimeEnd) - 86399;

                // Переменная даты построения календаря.
                let date = new Date(this.state.calendarYear, this.state.calendarMonth);

                // Пустая область в начале календаря.
                let blanksFirst = [];
                for (let i = 0; i < this.firstDayOfMonth(date); i++) {
                    blanksFirst.push(<td className="Calendar__td" key={'td' + i}>
                        <div className="Td__text Td__text_default"/>
                    </td>);
                }

                let weekDayNameNumber = (month, day, year) => {

                    let weekNumber = new Date(Number(year), Number(month), Number(day)).getDay()

                    switch (weekNumber) {
                        case 1: return "week_day_mon";
                        case 2: return "week_day_tue";
                        case 3: return "week_day_wed";
                        case 4: return "week_day_thu";
                        case 5: return "week_day_fri";
                        case 6: return "week_day_sat";
                        case 0: return "week_day_sun";
                        default: return null;
                    }
                }

                let weekDayDefault = true;

                // Переменные маркеров.
                let markerRed = false;
                let markerYellow = false;
                let markerGray = false;

                let markerLecture = false;
                let markerLaboratory = false;
                let markerPractical = false;
                let markerExam = false;

                // Календарные дни.
                let daysInMonth = [];
                while (date.getMonth() === this.state.calendarMonth) {
                    let today = (date.getDate() === this.dayIsToday() && date.getMonth() === this.monthIsToday() && date.getFullYear() === this.yearIsToday() ? "today" : null);
                    let selected = (date.getDate() === this.state.selectedDay && date.getMonth() === this.state.selectedMonth && date.getFullYear() === this.state.selectedYear ? "selected" : null);

                    daysInMonth.push(
                        <td className={'Calendar__td ' + selected} key={date.getDate()}>


                            {studentTrainingCoursesData.training_schedule ? (
                                studentTrainingCoursesData.training_schedule.schedule_timetable ? (
                                    studentTrainingCoursesData.training_schedule.schedule_timetable.map(timetable => (
                                        Number(timetable[weekDayNameNumber(date.getMonth(), date.getDate(), date.getFullYear())]) > 0 ? weekDayDefault = false : null
                                    ))
                                ) : null
                            ) : null}

                            <div className={
                                studentTrainingCoursesData.training_schedule ? (
                                    studentTrainingCoursesData.training_schedule.schedule_periods ? (
                                        Number(studentTrainingCoursesData.training_schedule.schedule_periods[0]["type_week_" + this.currentWeekYear(date.getMonth(), date.getDate(), date.getFullYear())]) > 0 ?
                                            !weekDayDefault ? 'Td__text ' + today : 'Td__text Td__text_default Td__text_pointer ' + today
                                            : 'Td__text Td__text_default Td__text_pointer ' + today
                                    ) : null
                                ) : null
                            }
                                 style={{borderRadius: 7}}
                                 onClick={
                                     // Number(trainingCourseData.training_schedule.schedule_period["type_week_" + this.currentWeekYear(date.getMonth(), date.getDate(), date.getFullYear())]) > 0 ?
                                     //     !weekDayDefault ? this.clickDay.bind(this, date.getMonth(), date.getDate(), date.getFullYear()) : null
                                     //     : null

                                     this.clickDay.bind(this, date.getMonth(), date.getDate(), date.getFullYear())
                                 }>
                                <div className="Text__day">
                                    {date.getDate()}
                                </div>
                                {this.currentWeekYear(date.getMonth(), date.getDate(), date.getFullYear()) !== 0 ? (
                                    this.currentWeekYear(date.getMonth(), date.getDate(), date.getFullYear()) !== 53 ? (
                                        <div className="Text__week">
                                            {this.currentWeekYear(date.getMonth(), date.getDate(), date.getFullYear())}
                                        </div>) : null
                                ) : null}


                                {/*<div className="Text__week" style={{bottom: 0}}>*/}
                                {/*    {date.getWeek()}*/}
                                {/*</div>*/}



                                {markerLecture = false}
                                {markerLaboratory = false}
                                {markerPractical = false}
                                {markerExam = false}

                                {studentTrainingCoursesData.training_course ? (
                                    studentTrainingCoursesData.training_course.map(training_course => (
                                        training_course.course_schedule.map(course_schedule => (
                                            ((date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear() + '/lecture') === (course_schedule.day_lesson + '.' + course_schedule.month_lesson + '.' + course_schedule.year_lesson + '/' + course_schedule.lesson_type)) ? (
                                                markerLecture = true
                                            ) : null
                                        ))
                                    ))
                                ) : null}

                                {studentTrainingCoursesData.training_course ? (
                                    studentTrainingCoursesData.training_course.map(training_course => (
                                    training_course.course_schedule.map(course_schedule => (
                                        ((date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear() + '/laboratory') === (course_schedule.day_lesson + '.' + course_schedule.month_lesson + '.' + course_schedule.year_lesson + '/' + course_schedule.lesson_type)) ? (
                                            markerLaboratory = true
                                        ) : null
                                    ))
                                ))
                                ) : null}

                                {studentTrainingCoursesData.training_course ? (
                                    studentTrainingCoursesData.training_course.map(training_course => (
                                        training_course.course_schedule.map(course_schedule => (
                                            ((date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear() + '/practical') === (course_schedule.day_lesson + '.' + course_schedule.month_lesson + '.' + course_schedule.year_lesson + '/' + course_schedule.lesson_type)) ? (
                                                markerPractical = true
                                            ) : null
                                        ))
                                    ))
                                ) : null}

                                {studentTrainingCoursesData.training_course ? (
                                    studentTrainingCoursesData.training_course.map(training_course => (
                                        training_course.course_schedule.map(course_schedule => (
                                            ((date.getDate() + '.' + (date.getMonth() + 1) + '.' + date.getFullYear() + '/exam') === (course_schedule.day_lesson + '.' + course_schedule.month_lesson + '.' + course_schedule.year_lesson + '/' + course_schedule.lesson_type)) ? (
                                                markerExam = true
                                            ) : null
                                        ))
                                    ))
                                ) : null}

                                <div className="Box__markers" style={{
                                    textAlign: "right"
                                }}>
                                    {/*{markerLecture ? (<div className={'Box__marker Box__marker_yellow'} style={{backgroundColor: "#c0ca33"}}/>) : null}*/}
                                    {/*{markerLecture ? (<div className={'Box__marker Box__marker_yellow'} style={{backgroundColor: "#fdd835"}}/>) : null}*/}
                                    {/*{markerLaboratory ? (<div className={'Box__marker Box__marker_green'} style={{backgroundColor: "#66bb6a"}}/>) : null}*/}
                                    {/*{markerPractical ? (<div className={'Box__marker Box__marker_purple'} style={{backgroundColor: "#00acc1"}}/>) : null}*/}

                                    {markerLecture || markerLaboratory || markerPractical || markerExam ? (<div className={'Box__marker Box__marker_blue'} style={{marginRight: 10}}/>) : null}
                                </div>


                            </div>
                            {!weekDayDefault ?  weekDayDefault = true : null}

                            {/*{calendarData.length > 0 ? (*/}
                            {/*    calendarData.map((event, index) => (*/}
                            {/*         Number(new Date(this.state.calendarYear + '-' + (this.state.calendarMonth + 1) + '-' + date.getDate()).setHours(0, 0, 0, 0) / 1000) <= event.events_date_start ? (*/}
                            {/*            event.events_date_start <= Number(new Date(this.state.calendarYear + '-' + (this.state.calendarMonth + 1) + '-' + date.getDate()).setHours(0, 0, 0, 0) / 1000 + 86399) ? (*/}
                            {/*                <div key={index + 'key'} className={'Box__marker Box__marker_red'}/>*/}
                            {/*            ) : null*/}
                            {/*        ) : null*/}
                            {/*    ))) : null*/}
                            {/*}*/}

                            {markerRed = false}
                            {markerYellow = false}
                            {markerGray = false}

                            {projectsData.map(project => (
                                Number(new Date(this.state.calendarYear + '-' + (this.state.calendarMonth + 1) + '-' + date.getDate()).setHours(0, 0, 0, 0) / 1000) <= project.project_date_start ? (
                                    project.project_date_start <= Number(new Date(this.state.calendarYear + '-' + (this.state.calendarMonth + 1) + '-' + date.getDate()).setHours(0, 0, 0, 0) / 1000 + 86399) ? (
                                        markerRed = true
                                    ) : null
                                ) : null
                            ))}

                            {eventsData.map(event => (
                                Number(new Date(this.state.calendarYear + '-' + (this.state.calendarMonth + 1) + '-' + date.getDate()).setHours(0, 0, 0, 0) / 1000) <= event.events_date_start ? (
                                    event.events_date_start <= Number(new Date(this.state.calendarYear + '-' + (this.state.calendarMonth + 1) + '-' + date.getDate()).setHours(0, 0, 0, 0) / 1000 + 86399) ? (
                                        markerRed = true
                                    ) : null
                                ) : null
                            ))}

                            {problemsData.map(problem => (
                                Number(new Date(this.state.calendarYear + '-' + (this.state.calendarMonth + 1) + '-' + date.getDate()).setHours(0, 0, 0, 0) / 1000) <= problem.problem_date ? (
                                    problem.problem_date <= Number(new Date(this.state.calendarYear + '-' + (this.state.calendarMonth + 1) + '-' + date.getDate()).setHours(0, 0, 0, 0) / 1000 + 86399) ? (
                                        markerYellow = true
                                    ) : null
                                ) : null
                            ))}

                            {/*{selectedCurrent ? (*/}
                            {/*    projectsData.map(project => (*/}
                            {/*        Number(new Date(this.state.calendarYear + '-' + (this.state.calendarMonth + 1) + '-' + date.getDate()).setHours(0, 0, 0, 0) / 1000) > project.project_date_start ? (*/}
                            {/*            project.project_date_end >= Number(new Date(this.state.calendarYear + '-' + (this.state.calendarMonth + 1) + '-' + date.getDate()).setHours(0, 0, 0, 0) / 1000) ? (*/}
                            {/*                markerGray = true*/}
                            {/*            ) : null*/}
                            {/*        ) : null*/}
                            {/*    )),*/}
                            {/*        eventsData.map(event => (*/}
                            {/*            Number(new Date(this.state.calendarYear + '-' + (this.state.calendarMonth + 1) + '-' + date.getDate()).setHours(0, 0, 0, 0) / 1000) > event.events_date_start ? (*/}
                            {/*                event.events_date_end >= Number(new Date(this.state.calendarYear + '-' + (this.state.calendarMonth + 1) + '-' + date.getDate()).setHours(0, 0, 0, 0) / 1000) ? (*/}
                            {/*                    markerGray = true*/}
                            {/*                ) : null*/}
                            {/*            ) : null*/}
                            {/*        ))*/}
                            {/*) : null}*/}

                            {markerRed ? (<div className={'Box__marker Box__marker_red'}/>) : null}
                            {markerRed ? null : (markerYellow ? <div className={'Box__marker Box__marker_yellow'}/> : null)}
                            {markerRed ? null : (markerYellow ? null : (markerGray ? <div className={'Box__marker Box__marker_gray'}/> : null))}




                        </td>
                    );
                    date.setDate(date.getDate() + 1);
                }

                // Пустая область в конце календаря.
                let blankSecond = [];
                if (this.firstDayOfMonth(date) !== 0) {
                    for (let i = this.firstDayOfMonth(date); i < 7; i++) {
                        blankSecond.push(<td className="Calendar__td" key={i + 'td'}>
                            <div className="Td__text Td__text_default"/>
                        </td>);
                    }
                }

                // Формирование массива календарных дней.
                var totalSlots = [...blanksFirst, ...daysInMonth, ...blankSecond];
                let rows = [];
                let cells = [];

                // Построение календаря.
                totalSlots.forEach((row, i) => {
                    if (i % 7 !== 0) {
                        cells.push(row); // if index not equal 7 that means not go to next week
                    } else {
                        rows.push(cells); // when reach next week we contain all td in last week to rows
                        cells = []; // empty container
                        cells.push(row); // in current loop we still push current row to new container
                    }

                    if (i === totalSlots.length - 1) { // when end loop we add remain date
                        rows.push(cells);
                    }
                });

                let daysInMonthTable = rows.map((day, i) => {
                    return (<tr className="Calendar__tr" key={'tr' + i}>{day}</tr>);
                });




















                let isDayCourses = false;




                return (
                    <div className="Page__section">
                        {studentTrainingCoursesData.training_course ? (
                        <div className="Section__header">
                            <div className="Header__title">
                                <div className="Title__text">Расписание</div>

                                {/*<div className="Block__Item" style={{display: "inline-block", marginBottom: 0, verticalAlign: "top"}}>*/}
                                {/*    <div className="Item__block Item__block_inline Item__block_margin-right Item__block_padding"*/}
                                {/*         style={{display: "inline-block", marginBottom: 0, verticalAlign: "top", paddingTop: 4, marginRight: 0}}>*/}

                                {/*        <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                {/*    </div>*/}
                                {/*</div>*/}

                                {/*<div className="Title__text" style={{opacity: 0.15}}>Архив</div>*/}
                            </div>
                        </div>
                            ) : null}

                        <div className="Section__content">

                            <div className="TeacherTrainingCourses">
                                <div className="TeacherTrainingCourses__body">

                                    {/*<div className="Body__hr"/>*/}



                                    {studentTrainingCoursesData.training_course ? (
                                        <React.Fragment>
                                            <div className="Body__wrapper"  style={{width: 540 + 42 + 550}}>

                                    {/*<ul className="Body__list" style={{width: 376 + 376 + 376 + 42 + 42 + 42}}>*/}

                                    {/*    {studentTrainingCoursesData.training_course.map((trainingCourse, index) => (*/}
                                    {/*        // <Link to={"/cabinet/teacher/training/course/" + trainingCourse.id} key={index}>*/}
                                    {/*            <li className="List__box" style={{width: 376, display: "inline-block", marginRight: 42, verticalAlign: "top"}} key={index}>*/}
                                    {/*                    <div className="Box__header">*/}
                                    {/*                        <div className="Header__title">*/}
                                    {/*                            {trainingCourse.discipline_name}*/}
                                    {/*                        </div>*/}
                                    {/*                    </div>*/}
                                    {/*                    <div className="Box__footer">*/}
                                    {/*                        <div className="Block__Item" style={{display: "inline-block", marginBottom: 0, verticalAlign: "top"}}>*/}
                                    {/*                            <div className="Item__block Item__block_inline Item__block_margin-right Item__block_padding">*/}
                                    {/*                                <div className="Item__Text"*/}
                                    {/*                                     style={{fontSize: 24, fontWeight: 900}}>*/}
                                    {/*                                    {groupName(trainingCourse.group_name, trainingCourse.initial_year)}*/}
                                    {/*                                </div>*/}
                                    {/*                                <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                    {/*                                <div className="Item__label Item__label_inline">Группа</div>*/}
                                    {/*                            </div>*/}
                                    {/*                        </div>*/}
                                    {/*                    </div>*/}
                                    {/*            </li>*/}
                                    {/*        // </Link>*/}
                                    {/*    ))}*/}

                                    {/*</ul>*/}






                                            <div className="Course" style={{display: "inline-block", width: 540}}>

                                                <div className="Day" style={{display: "inline-block", borderLeft: 0, paddingLeft: 0}}>
                                                    <div className="Box__day">
                                                        {timeDay(dateTimeEnd)}
                                                        <div className={'Box__today'}>
                                                            <div className={'Today__weekday'}>{weekDay(dateTimeEnd)}</div>
                                                            <div className={'Today__month'}>{timeMonth(dateTimeEnd)}</div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="Course__header Course__header_margin-bottom-38">
                                                    {/*<div className="Header__title">*/}
                                                    {/*<div className="Title__text">{trainingCourseData.discipline_name}</div>*/}

                                                    {/*{trainingCourseData.course_schedule.length > 0 ? null : (*/}
                                                    {/*    <div className="Button__edit"*/}
                                                    {/*         onClick={this.alertOpen.bind(this, 'alertTeacherTrainingCourseDelete', alertData(trainingCourseData.id, trainingCourseData))}>*/}

                                                    {/*        /!*<svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">*!/*/}
                                                    {/*        /!*    <g id="Group_2240" data-name="Group 2240" transform="translate(-471 -775)">*!/*/}
                                                    {/*        /!*        <rect id="Rectangle_2757" data-name="Rectangle 2757" width="42" height="42" transform="translate(471 775)" fill="#f7f7f7" opacity="0"/>*!/*/}
                                                    {/*        /!*        <g id="Group_2239" data-name="Group 2239" transform="translate(50 28)">*!/*/}
                                                    {/*        /!*            <path id="Path_965" data-name="Path 965" d="M2.657,17.495a2.447,2.447,0,0,1,.707-1.509L13.983,5.367l1.73,1.73L6.2,16.612l1.73,1.73,9.515-9.515,1.73,1.73L9.658,20.073l1.73,1.73L20.9,12.287l1.73,1.73L12.014,24.636a2.447,2.447,0,0,1-1.509.707l-7.171.652a1.223,1.223,0,0,1-1.329-1.329Zm6.478,5.516-3.656.332A1.4,1.4,0,0,0,5.1,22.9c-.31-.272-.454-.272-.454-.272l.342-3.762Z" transform="translate(428 754)" fill="#2a3e4c" fillRule="evenodd"/>*!/*/}
                                                    {/*        /!*            <path id="Path_966" data-name="Path 966" d="M13.054,4.447l1.731-1.731a2.447,2.447,0,0,1,3.461,0l5.192,5.192a2.447,2.447,0,0,1,0,3.461L21.707,13.1Z" transform="translate(429.852 754)" fill="#2a3e4c"/>*!/*/}
                                                    {/*        /!*        </g>*!/*/}
                                                    {/*        /!*    </g>*!/*/}
                                                    {/*        /!*</svg>*!/*/}


                                                    {/*        <svg xmlns="http://www.w3.org/2000/svg" id="Group_1521" data-name="Group 1521" width="32" height="32" viewBox="0 0 32 32">*/}
                                                    {/*            <g id="Group_1301" data-name="Group 1301" transform="translate(4 4)">*/}
                                                    {/*                <rect id="Rectangle_1358" data-name="Rectangle 1358" width="24" height="24" rx="12" fill="#2a3e4c"/>*/}
                                                    {/*                <rect id="Rectangle_1359" data-name="Rectangle 1359" width="12" height="12" rx="6" transform="translate(6 6)" fill="none"/>*/}
                                                    {/*            </g>*/}
                                                    {/*            <rect id="Rectangle_1371" data-name="Rectangle 1371" width="6" height="6" rx="3" transform="translate(13)" fill="#f7f7f7"/>*/}
                                                    {/*            <rect id="Rectangle_1373" data-name="Rectangle 1373" width="6" height="6" rx="3" transform="translate(26 13)" fill="#f7f7f7"/>*/}
                                                    {/*            <rect id="Rectangle_1374" data-name="Rectangle 1374" width="6" height="6" rx="3" transform="translate(13 26)" fill="#f7f7f7"/>*/}
                                                    {/*            <rect id="Rectangle_1375" data-name="Rectangle 1375" width="6" height="6" rx="3" transform="translate(0 13)" fill="#f7f7f7"/>*/}
                                                    {/*            <rect id="Rectangle_1376" data-name="Rectangle 1376" width="6" height="6" rx="3" transform="translate(25.192 2.565) rotate(45)" fill="#f7f7f7"/>*/}
                                                    {/*            <rect id="Rectangle_1377" data-name="Rectangle 1377" width="10" height="10" rx="5" transform="translate(11 11)" fill="#f7f7f7"/>*/}
                                                    {/*            <rect id="Rectangle_1378" data-name="Rectangle 1378" width="6" height="6" rx="3" transform="translate(25.192 20.95) rotate(45)" fill="#f7f7f7"/>*/}
                                                    {/*            <rect id="Rectangle_1379" data-name="Rectangle 1379" width="6" height="6" rx="3" transform="translate(6.808 20.95) rotate(45)" fill="#f7f7f7"/>*/}
                                                    {/*            <rect id="Rectangle_1380" data-name="Rectangle 1380" width="6" height="6" rx="3" transform="translate(6.808 2.565) rotate(45)" fill="#f7f7f7"/>*/}
                                                    {/*        </svg>*/}


                                                    {/*    </div>*/}
                                                    {/*)}*/}

                                                    {/*<div className="Block__Item" style={{display: "inline-block", marginTop: 8, marginBottom: 0, verticalAlign: "top"}}>*/}
                                                    {/*    <div className="Item__block Item__block_inline Item__block_margin-right Item__block_padding">*/}
                                                    {/*        <div className="Item__Text"*/}
                                                    {/*             style={{fontSize: 24, fontWeight: 900, opacity: 0.5}}>*/}
                                                    {/*            {groupName(trainingCourseData.group_name, trainingCourseData.initial_year)}*/}
                                                    {/*        </div>*/}
                                                    {/*        <div className="Item__hr Item__hr_vertical Item__hr_inline"/>*/}
                                                    {/*        <div className="Item__label Item__label_inline">Группа</div>*/}
                                                    {/*    </div>*/}
                                                    {/*</div>*/}
                                                    {/*</div>*/}

                                                    {/*<div className="Body__hr" style={{marginBottom: 42}}/>*/}

                                                    {/*<div className="Course__info">*/}
                                                    {/*    <div className="Info__title">*/}
                                                    {/*        Тема занятия*/}
                                                    {/*    </div>*/}
                                                    {/*    <div className="Info__text">*/}
                                                    {/*        Введение в основы теории управления*/}
                                                    {/*    </div>*/}
                                                    {/*    <div className="Info__link">*/}
                                                    {/*        <div className="Link__text">*/}
                                                    {/*            Учебный материал*/}
                                                    {/*        </div>*/}
                                                    {/*        <div className="Link__text">*/}
                                                    {/*            Конференция*/}
                                                    {/*        </div>*/}
                                                    {/*    </div>*/}

                                                    {/*    <div className="Info__edit"*/}
                                                    {/*         onClick={this.modalOpen.bind(this, 'modalTrainingPlanEdit', modalData(trainingCourseData.id, trainingCourseData))}>*/}

                                                    {/*        <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">*/}
                                                    {/*            <g id="Group_2240" data-name="Group 2240" transform="translate(-471 -775)">*/}
                                                    {/*                <rect id="Rectangle_2757" data-name="Rectangle 2757" width="42" height="42" transform="translate(471 775)" fill="#f7f7f7" opacity="0"/>*/}
                                                    {/*                <g id="Group_2239" data-name="Group 2239" transform="translate(50 28)">*/}
                                                    {/*                    <path id="Path_965" data-name="Path 965" d="M2.657,17.495a2.447,2.447,0,0,1,.707-1.509L13.983,5.367l1.73,1.73L6.2,16.612l1.73,1.73,9.515-9.515,1.73,1.73L9.658,20.073l1.73,1.73L20.9,12.287l1.73,1.73L12.014,24.636a2.447,2.447,0,0,1-1.509.707l-7.171.652a1.223,1.223,0,0,1-1.329-1.329Zm6.478,5.516-3.656.332A1.4,1.4,0,0,0,5.1,22.9c-.31-.272-.454-.272-.454-.272l.342-3.762Z" transform="translate(428 754)" fill="#2a3e4c" fillRule="evenodd"/>*/}
                                                    {/*                    <path id="Path_966" data-name="Path 966" d="M13.054,4.447l1.731-1.731a2.447,2.447,0,0,1,3.461,0l5.192,5.192a2.447,2.447,0,0,1,0,3.461L21.707,13.1Z" transform="translate(429.852 754)" fill="#2a3e4c"/>*/}
                                                    {/*                </g>*/}
                                                    {/*            </g>*/}
                                                    {/*        </svg>*/}


                                                    {/*    </div>*/}
                                                    {/*</div>*/}


                                                    {studentTrainingCoursesData.group_schedule.map((course_schedule, index) => (
                                                        ((Number(course_schedule.day_lesson) === Number(this.state.selectedDay)) &&
                                                            (Number(course_schedule.month_lesson) === (Number(this.state.selectedMonth) + 1)) &&
                                                            (Number(course_schedule.year_lesson) === Number(this.state.selectedYear))
                                                        ) ? (

                                                            isDayCourses = true,





                                                            <React.Fragment key={index}>
                                                                <div style={{
                                                                    marginBottom: 42
                                                                }}>
                                                                    <div className="Box__header" style={{
                                                                        backgroundColor: "#eaeaea",
                                                                        // backgroundColor: "#0061ff",
                                                                        // backgroundColor: "#5d6c77",
                                                                        // backgroundColor: ((course_schedule.lesson_type === 'lecture') ? "#ffc107" : (course_schedule.lesson_type === 'laboratory') ? "#8bc34a" : (course_schedule.lesson_type === 'practical') ? "#b7879d" : null),
                                                                        padding: "24px 24px 24px",
                                                                        borderRadius: "12px 12px 0 0"
                                                                    }}>
                                                                        <div className="Header__title" style={{
                                                                            color: "#2A3E4C",
                                                                            // color: "white",
                                                                            fontSize: 24,
                                                                            fontFamily: "Roboto, sans-serif",
                                                                            fontWeight: 700,
                                                                            marginBottom: 16,
                                                                            opacity: 0.75
                                                                        }}>
                                                                            {course_schedule.discipline_name}
                                                                        </div>

                                                                        <div className="Header__title" style={{
                                                                            color: "#2A3E4C",
                                                                            // color: "white",
                                                                            fontSize: 17,
                                                                            fontFamily: "Roboto, sans-serif",
                                                                            fontWeight: 400,
                                                                            opacity: 0.75
                                                                        }}>
                                                                            {course_schedule.employee_fio}
                                                                        </div>
                                                                    </div>
                                                                    <div style={{
                                                                        backgroundColor: "white",
                                                                        borderRadius: "0 0 12px 12px",
                                                                        padding: "24px 24px 0"
                                                                    }}>










                                                            <div className="Course__info" key={index} style={{padding: 0, paddingBottom: 24, marginBottom: 0}}>

                                                                <div className="Info__title" style={{opacity: 0.75, fontSize: 20, fontWeight: 700, marginBottom: 6, color: "#2A3E4C"}}>
                                                                    {/*{(course_schedule.lesson_type === 'lecture') ? 'Лекция' : null}*/}
                                                                    {/*{(course_schedule.lesson_type === 'laboratory') ? 'Лабораторное занятие' : null}*/}
                                                                    {/*{(course_schedule.lesson_type === 'practical') ? 'Практическое занятие' : null}*/}


                                                                </div>

                                                                {(course_schedule.lesson_type === 'lecture') ? (
                                                                    <div className="Item__index" style={{
                                                                        // backgroundColor: "#fdd835",
                                                                        // backgroundColor: "#c0ca33",
                                                                        backgroundColor: "#fdd835",
                                                                        // textTransform: "uppercase",
                                                                        // isPassed Не сдан,
                                                                        color: "#2A3E4C",
                                                                    }}>Лекция</div>
                                                                ) : null}


                                                                {(course_schedule.lesson_type === 'laboratory') ? (
                                                                    <div className="Item__index" style={{
                                                                        backgroundColor: "#66bb6a",
                                                                        // backgroundColor: "#e53935",
                                                                        // textTransform: "uppercase"
                                                                        // isPassed Не сдан
                                                                        color: "#2A3E4C",
                                                                    }}>Лабораторное занятие</div>
                                                                ) : null}

                                                                {(course_schedule.lesson_type === 'practical') ? (
                                                                    <div className="Item__index" style={{
                                                                        backgroundColor: "#00acc1",
                                                                        // backgroundColor: "#e53935",
                                                                        // textTransform: "uppercase"
                                                                        // isPassed Не сдан
                                                                        color: "#2A3E4C",
                                                                    }}>Практическое занятие</div>
                                                                ) : null}

                                                                {(course_schedule.lesson_type === 'exam') ? (
                                                                    <div className="Item__index" style={{
                                                                        backgroundColor: "#7986cb",
                                                                        // backgroundColor: "#e53935",
                                                                        // textTransform: "uppercase"
                                                                        // isPassed Не сдан
                                                                        color: "#2A3E4C",
                                                                    }}>Экзаменационное занятие</div>
                                                                ) : null}



                                                                {/*<div className="Title__date" style={{opacity: 0.75, marginBottom: 24, fontWeight: 400, fontSize: 17, color: "#2A3E4C"}}>*/}
                                                                {/*    {timeDay(dateTimeEnd)} {timeMonth(dateTimeEnd)} {timeYear(dateTimeEnd)} c <b style={{fontWeight: 900, fontSize: 20}}>{course_schedule.starting_hour}:00</b> до <b style={{fontWeight: 900, fontSize: 20}}>{Number(course_schedule.double_hours) > 0 ? Number(course_schedule.starting_hour) + 2 : Number(course_schedule.starting_hour) + 1}:00 </b>*/}
                                                                {/*</div>*/}

                                                                {(course_schedule.lesson_type === 'exam') ? (
                                                                    <div className="Title__date" style={{opacity: 0.75, marginBottom: 0, fontWeight: 400, fontSize: 17, color: "#2A3E4C"}}>
                                                                        {timeDay(dateTimeEnd)} {timeMonth(dateTimeEnd)} {timeYear(dateTimeEnd)} c {course_schedule.starting_hour}:00
                                                                    </div>
                                                                ) : (
                                                                    <div className="Title__date" style={{opacity: 0.75, marginBottom: 24, fontWeight: 400, fontSize: 17, color: "#2A3E4C"}}>
                                                                        {timeDay(dateTimeEnd)} {timeMonth(dateTimeEnd)} {timeYear(dateTimeEnd)} c {course_schedule.starting_hour}:00 до {Number(course_schedule.double_hours) > 0 ? Number(course_schedule.starting_hour) + 2 : Number(course_schedule.starting_hour) + 1}:00
                                                                    </div>
                                                                )}

                                                                {(course_schedule.lesson_theme && (course_schedule.lesson_theme !== 'null')) ? (
                                                                    <div className="Info__title" style={{marginBottom: 0}}>
                                                                        {course_schedule.lesson_theme}
                                                                    </div>
                                                                ) : null}


                                                                {(course_schedule.lesson_description && (course_schedule.lesson_description !== 'null')) ? (
                                                                    <div className="Info__text" style={{marginTop: 12, marginBottom: 0, opacity: 1, fontSize: 17}}>
                                                                        {course_schedule.lesson_description}
                                                                    </div>
                                                                ) : null}

                                                                {(course_schedule.link_lecture && (course_schedule.link_lecture !== 'null')) ? (
                                                                    <div className="Info__link"
                                                                         style={{marginTop: 12, display: 'inline-block', cursor: 'pointer'}}
                                                                         onClick={this.linkOpen.bind(this, course_schedule.link_lecture)}>
                                                                        <div className="Link__text">
                                                                            Учебный материал
                                                                        </div>
                                                                    </div>
                                                                ) : null}

                                                                {(course_schedule.link_task && (course_schedule.link_task !== 'null')) ? (
                                                                    <div className="Info__link"
                                                                         style={{marginTop: 12, display: 'inline-block', cursor: 'pointer'}}
                                                                         onClick={this.linkOpen.bind(this, course_schedule.link_task)}>
                                                                        <div className="Link__text">
                                                                            Задание
                                                                        </div>
                                                                    </div>
                                                                ) : null}

                                                                {(course_schedule.link_conference && (course_schedule.link_conference !== 'null')) ? (
                                                                    <div className="Info__link"
                                                                         style={{marginTop: 12, display: 'inline-block', cursor: 'pointer'}}
                                                                         onClick={this.linkOpen.bind(this, course_schedule.link_conference)}>

                                                                        <div className="Link__text">
                                                                            Вебинар
                                                                        </div>
                                                                    </div>
                                                                ) : null}




                                                                {/*<div className="Info__edit"*/}
                                                                {/*     onClick={this.modalOpen.bind(this, 'modalTeacherTrainingCourseLessonEdit', modalData(trainingCourseData.id, course_schedule))}>*/}

                                                                {/*    <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42">*/}
                                                                {/*        <g id="Group_2240" data-name="Group 2240" transform="translate(-471 -775)">*/}
                                                                {/*            <rect id="Rectangle_2757" data-name="Rectangle 2757" width="42" height="42" transform="translate(471 775)" fill="#f7f7f7" opacity="0"/>*/}
                                                                {/*            <g id="Group_2239" data-name="Group 2239" transform="translate(50 28)">*/}
                                                                {/*                <path id="Path_965" data-name="Path 965" d="M2.657,17.495a2.447,2.447,0,0,1,.707-1.509L13.983,5.367l1.73,1.73L6.2,16.612l1.73,1.73,9.515-9.515,1.73,1.73L9.658,20.073l1.73,1.73L20.9,12.287l1.73,1.73L12.014,24.636a2.447,2.447,0,0,1-1.509.707l-7.171.652a1.223,1.223,0,0,1-1.329-1.329Zm6.478,5.516-3.656.332A1.4,1.4,0,0,0,5.1,22.9c-.31-.272-.454-.272-.454-.272l.342-3.762Z" transform="translate(428 754)" fill="#2a3e4c" fillRule="evenodd"/>*/}
                                                                {/*                <path id="Path_966" data-name="Path 966" d="M13.054,4.447l1.731-1.731a2.447,2.447,0,0,1,3.461,0l5.192,5.192a2.447,2.447,0,0,1,0,3.461L21.707,13.1Z" transform="translate(429.852 754)" fill="#2a3e4c"/>*/}
                                                                {/*            </g>*/}
                                                                {/*        </g>*/}
                                                                {/*    </svg>*/}


                                                                {/*</div>*/}
                                                            </div>
















                                                                    </div>
                                                                </div>
                                                            </React.Fragment>




                                                        ) : null
                                                        ))}


                                            {!isDayCourses ? (
                                                    <React.Fragment>
                                                        <div className="Page__loader">
                                                            <i/>
                                                            <div className="Body_nodata" style={{
                                                                marginTop: 48 + 24,
                                                                opacity: .15,
                                                                fontSize: 56
                                                            }}>
                                                                Нет занятий
                                                            </div>
                                                            <i/>
                                                        </div>
                                                    </React.Fragment>
                                            ) : null}
                                                </div>



                                            </div>












                                            <div className="Calendar" style={{display: "inline-block"}}>
                                                <div className="Calendar__day">
                                                    <div className={'Calendar__today'}>
                                                        <div
                                                            className={'Today__weekday'}>
                                                            {timeYear(new Date(this.state.calendarYear, this.state.calendarMonth).getTime() / 1000)}
                                                        </div>
                                                        <div
                                                            className={'Today__month'}>
                                                            {timeMonth(new Date(this.state.calendarYear, this.state.calendarMonth).getTime() / 1000)[0].toUpperCase() + timeMonth(new Date(this.state.calendarYear, this.state.calendarMonth).getTime() / 1000).slice(1)}
                                                        </div>
                                                    </div>
                                                    <div className="Header__buttons">

                                                        <div className="Header__button "
                                                             onClick={this.monthMines.bind(this)}>

                                                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                                                                <g id="Group_1680" data-name="Group 1680" transform="translate(-1392 -522)">
                                                                    <rect id="Rectangle_2080" data-name="Rectangle 2080" width="32" height="32" rx="16" transform="translate(1392 522)" fill="#cacfd3"/>
                                                                    <g id="Group_1677" data-name="Group 1677" transform="translate(1948 -930) rotate(90)">
                                                                        <g id="Group_920" data-name="Group 920" transform="translate(420.563 1105.602) rotate(-45)">
                                                                            <rect id="Rectangle_1180" data-name="Rectangle 1180" width="10" height="4" rx="1" transform="translate(1136.293 341.001)" fill="#fff"/>
                                                                            <rect id="Rectangle_1181" data-name="Rectangle 1181" width="4" height="10" rx="1" transform="translate(1136.293 335)" fill="#fff"/>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </div>

                                                        <div className="Header__button "
                                                             onClick={this.monthPlus.bind(this)}>

                                                            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                                                                <g id="Group_1679" data-name="Group 1679" transform="translate(-1488 -524)">
                                                                    <rect id="Rectangle_2081" data-name="Rectangle 2081" width="32" height="32" rx="16" transform="translate(1488 524)" fill="#cacfd3"/>
                                                                    <g id="Group_1678" data-name="Group 1678" transform="translate(964 2008) rotate(-90)">
                                                                        <g id="Group_920" data-name="Group 920" transform="translate(420.563 1105.602) rotate(-45)">
                                                                            <rect id="Rectangle_1180" data-name="Rectangle 1180" width="10" height="4" rx="1" transform="translate(1136.293 341.001)" fill="#fff"/>
                                                                            <rect id="Rectangle_1181" data-name="Rectangle 1181" width="4" height="10" rx="1" transform="translate(1136.293 335)" fill="#fff"/>
                                                                        </g>
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                </div>
                                                <table className="Calendar__table">
                                                    <thead>
                                                    <tr className="Calendar__tr">
                                                        <th className="Calendar__th">
                                                            <div className="Th__title Th__title_left">Пн</div>
                                                        </th>
                                                        <th className="Calendar__th">
                                                            <div className="Th__title Th__title_left">Вт</div>
                                                        </th>
                                                        <th className="Calendar__th">
                                                            <div className="Th__title Th__title_left">Ср</div>
                                                        </th>
                                                        <th className="Calendar__th">
                                                            <div className="Th__title Th__title_left">Чт</div>
                                                        </th>
                                                        <th className="Calendar__th">
                                                            <div className="Th__title Th__title_left">Пт</div>
                                                        </th>
                                                        <th className="Calendar__th">
                                                            <div className="Th__title Th__title_left">Сб</div>
                                                        </th>
                                                        <th className="Calendar__th">
                                                            <div className="Th__title Th__title_left">Вс</div>
                                                        </th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>{daysInMonthTable}</tbody>
                                                </table>













                                                <table className="Schedule__info">
                                                    <tbody>

                                                    <tr className="Info__tr">
                                                        <td className="Info__td">
                                                            <div className='Info__box'>
                                                                <div className="Item__Text" style={{fontSize: 24, fontWeight: 900, opacity: 0.25}}>Время расписания, Пермское (GMT+5)</div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>













                                            </div>




































                                            </div>
                                        </React.Fragment>
                                    ) : (
                                    <React.Fragment>
                                        <div className="Page__loader">
                                            <i/>
                                            <div className="Body_nodata" style={{marginTop: 96 + 48, opacity: .15}}>
                                                Пока нет расписания
                                                {/*<div style={{textAlign: 'center', marginTop: 48, fontSize: 32}}>*/}
                                                {/*    ERROR 404*/}
                                                {/*</div>*/}
                                            </div>
                                            <i/>
                                        </div>
                                    </React.Fragment>
                                    )}


                                </div>
                            </div>

                        </div>

                        {/*<div className="Section__button"*/}
                        {/*     onClick={this.modalOpen.bind(this, 'modalTeacherTrainingCourseAdd', modalData(null, null))}>*/}
                        {/*    <div className="button"/>*/}
                        {/*</div>*/}
                    </div>
                );
            }
        }

        else return null;
    }
}

function mapStateToProps(state) {
    return {
        isLoad: state.page.isLoad,
        isUpdate: state.page.isUpdate,
        responseData: state.api.responseData
    }
}

function mapDispatchToProps(dispatch) {
    return {
        pageMount: () => dispatch(pageMount()),
        pageLoad: (isLoad) => dispatch(pageLoad(isLoad)),
        pageUpdate: () => dispatch(pageUpdate()),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        modalOpen: (modalForm, modalData) => dispatch(modalOpen(modalForm, modalData))
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (StudentTrainingSchedule);
