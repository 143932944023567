import React from "react";
import {connect} from "react-redux";
import {modalClose} from "../../../../../../store/actions/modalAction";
import {notificationMessage} from "../../../../../../store/actions/notificationAction";
import {apiRequest} from "../../../../../../store/actions/apiAction";
import {pageUpdate} from "../../../../../../store/actions/pageAction";
import Loading from "../../../../../loading/Loading";
import {formLoad} from "../../../../../../store/actions/formAction";
import {alertOpen} from "../../../../../../store/actions/alertAction";

class ModalTeacherTrainingCourseAdd extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isMountForm: true,
            isOpenForm: false,
            isLoadForm: false,
            isUpdateForm: false,
            formData: null,
            isHandleSubmit: false,
            objectId: null,
            objectData: null,
            formResponseError: null,

            trainingDisciplineId: 0,
            trainingGroupId: 0,
            trainingProgramId: 0,
            trainingCoursesCurrentData: null,
            trainingGroupData: [],
            trainingProgramData: [],
            trainingDisciplineData: []
        };
    }

    componentDidMount() {
        // this.formUpdate(this.state.isLoadForm);

        this.trainingCoursesCurrentData();
    }

    componentWillUnmount() {
        // console.log('From Unmounted');
        this.setState({
            isMountForm: false
        });
    }

    // componentDidUpdate() {
    //     if (this.state.isMountForm) {
    //         const responseData = this.props.responseData;
    //         // console.log(1 + ' componentDidUpdate');
    //         if (this.props.isLoadForm !== this.state.isLoadForm) {
    //             // console.log(2 + ' isLoad');
    //             if (responseData !== this.state.formData) {
    //                 // console.log(3 + ' responseData');
    //
    //                 this.formUpdate();
    //
    //                 this.setState({
    //                     isOpenForm: true,
    //                     isLoadForm: this.props.isLoadForm,
    //                     isUpdateForm: this.props.isUpdateForm,
    //                     formData: responseData
    //                 });
    //             }
    //         }
    //
    //         else if (this.props.isUpdateForm !== this.state.isUpdateForm) {
    //             // console.log(4 + ' isUpdate');
    //             this.setState({
    //                 isUpdateForm: this.props.isUpdateForm
    //             });
    //             this.formUpdate(this.state.isLoadForm);
    //         }
    //     }
    // }
    //
    // formUpdate(isLoadForm) {
    //     this.props.apiRequest('/company/' + this.state.objectId + '/supports_date', null, () => {
    //         this.props.formLoad(isLoadForm);
    //     }, null);
    // }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    handleInputChangeProgram(event) {
        const target = event.target;
        const value = target.value;

        this.setState({
            trainingProgramId: value,
            trainingGroupId: 0,
            trainingDisciplineId: 0
        });
    }

    handleInputChangeGroup(event) {
        const target = event.target;
        const value = target.value;

        let trainingProgramId = 0;

        this.state.trainingGroupData.map((trainingGroup) => {
            if (Number(trainingGroup.id) === Number(value)) {
                trainingProgramId = trainingGroup.training_program_id;
            }
        });

        this.setState({
            trainingGroupId: value,
            trainingProgramId: trainingProgramId
        });
    }

    handleInputChangeDiscipline(event) {
        const target = event.target;
        const value = target.value;

        let trainingProgramId = 0;

        this.state.trainingDisciplineData.map((trainingDiscipline) => {
            if (Number(trainingDiscipline.id) === Number(value)) {
                trainingProgramId = trainingDiscipline.training_program_id;
            }
        });

        this.setState({
            trainingDisciplineId: value,
            trainingProgramId: trainingProgramId
        });
    }

    // setStateAndInput(name, value) {
    //
    //     let input = document.getElementById(name);
    //     input.value = value;
    //
    //     this.setState({
    //         [name]: value
    //     });
    // }

    handleSubmit(event) {
        event.preventDefault();

        if (this.state.trainingProgramId && this.state.trainingGroupId && this.state.trainingDisciplineId) {

            let formData = new FormData();

            formData.append('training_program_id', this.state.trainingProgramId);
            formData.append('training_group_id', this.state.trainingGroupId);
            formData.append('disciplines_plan_id', this.state.trainingDisciplineId);

            this.setState({
                isHandleSubmit: true
            });

            this.props.apiRequest('api/cabinet/training/course/add', formData, () => {
                    // this.setStateAndInput('', null);
                    this.setState({
                        isHandleSubmit: false,
                        formResponseError: null
                    });
                    // this.formUpdate(this.state.isLoadForm);
                    this.props.modalClose();
                    this.props.notificationMessage(this.props.responseMessage, 'green');
                    this.props.pageUpdate();
                }, () => {
                    this.setState({
                        isHandleSubmit: false,
                        formResponseError: this.props.responseError
                    });
                }
            );

        }

        else {
            this.setState({
                formResponseError: 'Пожалуйста, внимательно заполните все поля.'
            });
        }
    }

    modalClose() {
        this.props.modalClose();
    }

    // alertOpen(alertForm, alertData) {
    //     this.props.alertOpen(alertForm, alertData);
    // }

    trainingCoursesCurrentData() {
        this.props.apiRequest('api/cabinet/current/training/courses', null, () => {
            this.setState({
                trainingCoursesCurrentData: this.props.responseData
            }, () => {
                this.trainingGroupData();
                this.trainingProgramData();
                this.trainingDisciplineData();
            });
        }, null);
    }

    trainingGroupData() {
        const trainingCoursesCurrentData = this.state.trainingCoursesCurrentData;
        let trainingGroupData = [];

        trainingCoursesCurrentData.map((trainingCoursesCurrent) => {
            let object = {
                id: trainingCoursesCurrent.id,
                training_program_id: trainingCoursesCurrent.training_program_id,
                group_name: trainingCoursesCurrent.group_name,
                initial_year: trainingCoursesCurrent.initial_year,
                direction_preparation: trainingCoursesCurrent.training_program.direction_preparation
            }

            trainingGroupData.push(object);
        });

        trainingGroupData = this.uniq(trainingGroupData);

        this.setState({
            trainingGroupData: trainingGroupData
        });
    }

    trainingProgramData() {
        const trainingCoursesCurrentData = this.state.trainingCoursesCurrentData;
        let trainingProgramData = [];

        trainingCoursesCurrentData.map((trainingCoursesCurrent) => {
            let object = {
                id: trainingCoursesCurrent.training_program.id,
                direction_preparation: trainingCoursesCurrent.training_program.direction_preparation,
                training_qualifications: trainingCoursesCurrent.training_program.training_qualifications,
                form_training: trainingCoursesCurrent.training_program.form_training,
                period_training: trainingCoursesCurrent.training_program.period_training
            }

            trainingProgramData.push(object);
        });

        trainingProgramData = this.uniq(trainingProgramData);

        this.setState({
            trainingProgramData: trainingProgramData
        });
    }

    trainingDisciplineData() {
        const trainingCoursesCurrentData = this.state.trainingCoursesCurrentData;
        let trainingDisciplineData = [];
        let disciplinesPlan = [];

        trainingCoursesCurrentData.map((trainingCoursesCurrent) => {
            // trainingCoursesCurrent.training_program.disciplines_plan.map((discipline) => {
            //     discipline.direction_preparation = trainingCoursesCurrent.training_program.direction_preparation;
            //     discipline.training_group_id = trainingCoursesCurrent.id;
            //     trainingDisciplineData.push(discipline);
            // });
            disciplinesPlan = trainingCoursesCurrent.training_program.disciplines_plan;

            // Удаляет дубли из массива.
            disciplinesPlan = this.uniq(disciplinesPlan);

            disciplinesPlan.map((discipline) => {
                discipline.direction_preparation = trainingCoursesCurrent.training_program.direction_preparation;
                discipline.training_group_id = trainingCoursesCurrent.id;
                trainingDisciplineData.push(discipline);
            });
        });



        // Удаляет дубли из массива.
        // trainingDisciplineData = this.uniq(trainingDisciplineData);

        this.setState({
            trainingDisciplineData: trainingDisciplineData
        });
    }

    // Удаляет дубли из массива.
    uniq(a) {
        let seen = {};
        return a.filter(function(item) {
            return seen.hasOwnProperty(item.id) ? false : (seen[item.id] = true);
        });
    }

    render() {
        const {
            isMountForm,
            // isOpenForm,
            // formData,
            isHandleSubmit,
            formResponseError,

            trainingDisciplineId,
            trainingGroupId,
            trainingProgramId,
            trainingCoursesCurrentData,
            trainingGroupData,
            trainingProgramData,
            trainingDisciplineData
        } = this.state;

        // let alertData = (objectId, objectData) => {
        //     return {
        //         objectId: objectId,
        //         objectData: objectData
        //     };
        // }

        let groupName = (groupName, initialYear) => {
            let course;

            let date = new Date();
            course = (date.getFullYear() - Number(initialYear) + 1);

            if (groupName.search('\$') > 0) {
                return groupName.replace(/\$/i, course);
            }

            else {
                return groupName;
            }
        }

        if (isMountForm) {

            // {!isOpenForm ? (<Loading/>) : (
            //     formData ? () : null)}

            return (
                <div className="Form">
                    <form onSubmit={this.handleSubmit.bind(this)}>
                        <div className="Form__header">
                            <div className="Header__title">Новый учебный курс</div>
                        </div>
                        <div className="Form__content">

                            <div className="Content__hr Content__hr_bottom-32"/>

                            <div className="Content__box">
                                <div className="Form__field">
                                    <div className="Field__label">Направление подготовки</div>
                                    <select className="Field__select"
                                            id="trainingProgramId"
                                            name="trainingProgramId"
                                            value={trainingProgramId}
                                            onChange={this.handleInputChangeProgram.bind(this)}>
                                        <option value="0">Не выбран</option>
                                        <option  className="option__hr" disabled />
                                        {!trainingProgramData ? null : (
                                            trainingProgramData.map((trainingProgram, index) => (
                                                <React.Fragment key={index}>
                                                    <option value={trainingProgram.id}>{trainingProgram.direction_preparation}</option>
                                                    <option className="option__info" disabled>{trainingProgram.training_qualifications} / {trainingProgram.form_training} / {trainingProgram.period_training}</option>
                                                    <option  className="option__hr" disabled />
                                                </React.Fragment>
                                            ))
                                        )}
                                    </select>
                                </div>
                            </div>

                            <div className="Content__box">
                                <div className="Form__field">
                                    <div className="Field__label">Группа обучения</div>
                                    <select className="Field__select"
                                            id="trainingGroupId"
                                            name="trainingGroupId"
                                            value={trainingGroupId}
                                            onChange={this.handleInputChangeGroup.bind(this)}>
                                        <option value="0">Не выбран</option>
                                        <option  className="option__hr" disabled />
                                        {!trainingGroupData ? null : (
                                            trainingGroupData.map((trainingGroup, index) => (


                                            Number(trainingProgramId) > 0 ? (

                                            ((Number(trainingProgramId) > 0) && (Number(trainingProgramId) === Number(trainingGroup.training_program_id))) ? (
                                                <React.Fragment key={index}>
                                                    <option value={trainingGroup.id}>{groupName(trainingGroup.group_name, trainingGroup.initial_year)}</option>
                                                    <option className="option__info" disabled>{trainingGroup.direction_preparation}</option>
                                                    <option  className="option__hr" disabled />
                                                </React.Fragment>
                                            ) : null

                                            ) : (
                                                <React.Fragment key={index}>
                                                    <option value={trainingGroup.id}>{groupName(trainingGroup.group_name, trainingGroup.initial_year)}</option>
                                                    <option className="option__info" disabled>{trainingGroup.direction_preparation}</option>
                                                    <option  className="option__hr" disabled />
                                                </React.Fragment>
                                            )
                                            ))
                                        )}
                                    </select>
                                </div>
                            </div>

                            <div className="Content__box">
                                <div className="Form__field">
                                    <div className="Field__label">Дисциплина обучения</div>
                                    <select className="Field__select"
                                            id="trainingDisciplineId"
                                            name="trainingDisciplineId"
                                            value={trainingDisciplineId}
                                            onChange={this.handleInputChangeDiscipline.bind(this)}>
                                        <option value="0">Не выбран</option>
                                        <option  className="option__hr" disabled />
                                        {!trainingDisciplineData ? null : (
                                            trainingDisciplineData.map((trainingDiscipline, index) => (
                                                Number(trainingProgramId) > 0 ? (
                                                    ((Number(trainingProgramId) > 0) && (Number(trainingProgramId) === Number(trainingDiscipline.training_program_id))) ? (
                                                        ((Number(trainingGroupId) > 0) && (Number(trainingGroupId) === Number(trainingDiscipline.training_group_id))) ? (
                                                            <React.Fragment key={index}>
                                                                <option className="option__info" disabled>{trainingDiscipline.year_program} курс, {trainingDiscipline.period_program} семестр</option>
                                                                <option value={trainingDiscipline.id}>{trainingDiscipline.discipline_name}</option>
                                                                <option className="option__info" disabled>{trainingDiscipline.direction_preparation}</option>
                                                                <option  className="option__hr" disabled />
                                                            </React.Fragment>
                                                        ) : null
                                                    ) : null
                                                ) : null
                                            ))
                                        )}
                                    </select>
                                </div>
                            </div>

                            <div className="Content__error">
                                <span className="Error__text">{formResponseError}</span>
                            </div>
                        </div>
                        <div className="Form__footer">
                            <button className={isHandleSubmit ? 'Form__button Form__button_blue Form__button_left Form__button_disabled' : 'Form__button Form__button_blue Form__button_left'}
                                    type="submit"
                                    style={{
                                        position: "relative"
                                    }}
                                    disabled={isHandleSubmit ? 'disabled' : null}>
                                Добавить
                                <div style={{position: "absolute", right: 6, top: 6}}>{isHandleSubmit ? <Loading white={true} /> : null}</div>
                            </button>
                        </div>
                    </form>
                </div>
            );
        }

        else return null;
    }
}

function mapStateToProps(state) {
    return {
        isLoadForm: state.form.isLoadForm,
        isUpdateForm: state.form.isUpdateForm,
        modalData: state.modal.modalData,
        isRequest: state.api.isRequest,
        responseMessage: state.api.responseMessage,
        responseData: state.api.responseData,
        responseError: state.api.responseError
    }
}

function mapDispatchToProps(dispatch) {
    return {
        formLoad: (isLoadForm) => dispatch(formLoad(isLoadForm)),
        pageUpdate: () => dispatch(pageUpdate()),
        notificationMessage: (message, colorMessage) => dispatch(notificationMessage(message, colorMessage)),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        alertOpen: (alertForm, alertData) => dispatch(alertOpen(alertForm, alertData)),
        modalClose: () => dispatch(modalClose())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalTeacherTrainingCourseAdd);
