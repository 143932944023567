import React from "react";
import {connect} from "react-redux";
import {modalClose} from "../../../../store/actions/modalAction";
import {notificationMessage} from "../../../../store/actions/notificationAction";
import {apiRequest} from "../../../../store/actions/apiAction";
import {pageUpdate} from "../../../../store/actions/pageAction";
import Loading from "../../../loading/Loading";
import {formLoad} from "../../../../store/actions/formAction";
import {alertOpen} from "../../../../store/actions/alertAction";

class ModalTrainingGroupDisciplinePrint extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isMountForm: true,
            isOpenForm: false,
            isLoadForm: false,
            isUpdateForm: false,
            formData: null,
            isHandleSubmit: false,
            objectId: this.props.modalData.objectId,
            objectData: this.props.modalData.objectData,
            formResponseError: null,
            groupName: this.props.modalData.objectData.group_name,
            initialYear: this.props.modalData.objectData.initial_year,
            trainingProgramId: Number(this.props.modalData.objectData.training_program_id),
            trainingScheduleId: Number(this.props.modalData.objectData.training_schedule_id),
            employeeId: Number(this.props.modalData.objectData.employee_id),


            trainingDisciplineId: 0,
            trainingGroupId: 0,
            trainingCoursesCurrentData: null,
            trainingGroupData: [],
            trainingProgramData: [],
            trainingDisciplineData: []
        };
    }

    componentDidMount() {
        // this.formUpdate(this.state.isLoadForm);

        // this.employeesData();
        // this.trainingProgramsData();
        // this.trainingSchedulesData();

        this.trainingCoursesCurrentData();
    }

    componentWillUnmount() {
        // console.log('From Unmounted');
        this.setState({
            isMountForm: false
        });
    }

    // componentDidUpdate() {
    //     if (this.state.isMountForm) {
    //         const responseData = this.props.responseData;
    //         // console.log(1 + ' componentDidUpdate');
    //         if (this.props.isLoadForm !== this.state.isLoadForm) {
    //             // console.log(2 + ' isLoad');
    //             if (responseData !== this.state.formData) {
    //                 // console.log(3 + ' responseData');
    //
    //                 this.formUpdate();
    //
    //                 this.setState({
    //                     isOpenForm: true,
    //                     isLoadForm: this.props.isLoadForm,
    //                     isUpdateForm: this.props.isUpdateForm,
    //                     formData: responseData
    //                 });
    //             }
    //         }
    //
    //         else if (this.props.isUpdateForm !== this.state.isUpdateForm) {
    //             // console.log(4 + ' isUpdate');
    //             this.setState({
    //                 isUpdateForm: this.props.isUpdateForm
    //             });
    //             this.formUpdate(this.state.isLoadForm);
    //         }
    //     }
    // }
    //
    // formUpdate(isLoadForm) {
    //     this.props.apiRequest('/company/' + this.state.objectId + '/supports_date', null, () => {
    //         this.props.formLoad(isLoadForm);
    //     }, null);
    // }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    // setStateAndInput(name, value) {
    //
    //     let input = document.getElementById(name);
    //     input.value = value;
    //
    //     this.setState({
    //         [name]: value
    //     });
    // }

    handleSubmit(event) {
        event.preventDefault();

        if (this.state.objectId && (Number(this.state.trainingDisciplineId) > 0)) {

            let url = '/api/print/training/group/' + this.state.objectId + '/discipline/' + this.state.trainingDisciplineId;

            const newWindow = window.open(url, '_blank', 'noopener, noreferrer');
            if (newWindow) {
                newWindow.opener = null;
            }


        }

        else {
            this.setState({
                formResponseError: 'Внимательно укажите дисциплину обучения.'
            });
        }
    }

    modalClose() {
        this.props.modalClose();
    }

    // trainingCoursesCurrentData() {
    //     this.props.apiRequest('api/cabinet/all/training/courses/', null, () => {
    //         this.setState({
    //             trainingCoursesCurrentData: this.props.responseData
    //         }, () => {
    //             this.trainingDisciplineData();
    //         });
    //     }, null);
    // }

    trainingCoursesCurrentData() {
        this.props.apiRequest('api/cabinet/training/plan/' + this.props.modalData.objectData.training_program_id, null, () => {
            this.setState({
                trainingCoursesCurrentData: this.props.responseData
            }, () => {
                this.trainingDisciplineData();
            });
        }, null);
    }

    trainingDisciplineData() {
        const trainingCoursesCurrentData = this.state.trainingCoursesCurrentData;
        let trainingDisciplineData = [];

        trainingCoursesCurrentData.disciplines_plan.map((period) => {
            period.disciplines.map((discipline) => {
                trainingDisciplineData.push(discipline);
            });
        });

        this.setState({
            trainingDisciplineData: trainingDisciplineData
        });
    }

    handleInputChangeDiscipline(event) {
        const target = event.target;
        const value = target.value;

        this.setState({
            trainingDisciplineId: value,
            formResponseError: null
        });
    }


    render() {
        const { modalData } = this.props;

        const {
            isMountForm,
            // isOpenForm,
            // formData,
            isHandleSubmit,
            formResponseError,

            trainingProgramId,
            trainingScheduleId,
            employeeId,
            employeesData,
            trainingProgramsData,
            trainingSchedulesData,

            trainingDisciplineId,
            trainingGroupId,
            trainingCoursesCurrentData,
            trainingGroupData,
            trainingProgramData,
            trainingDisciplineData

        } = this.state;

        // let alertData = (objectId, objectData) => {
        //     return {
        //         objectId: objectId,
        //         objectData: objectData
        //     };
        // }

        let groupName = (groupName, initialYear) => {
            let course;

            let date = new Date();
            course = (date.getFullYear() - Number(initialYear) + 1);

            return groupName.replace(/\$/i, course);
        }

        if (isMountForm) {

            // {!isOpenForm ? (<Loading/>) : (
            //     formData ? () : null)}

            return (
                <div className="Form">
                    <form onSubmit={this.handleSubmit.bind(this)}>
                        <div className="Form__header">
                            <div className="Header__title">Группа {groupName(modalData.objectData.group_name, modalData.objectData.initial_year)}</div>
                        </div>
                        <div className="Form__content">

                            <div className="Content__hr Content__hr_bottom-32"/>

                            <div className="Content__box">
                                <div className="Form__field">
                                    <div className="Field__label">Дисциплина обучения</div>
                                    <select className="Field__select"
                                            id="trainingDisciplineId"
                                            name="trainingDisciplineId"
                                            value={trainingDisciplineId}
                                            onChange={this.handleInputChangeDiscipline.bind(this)}>
                                        <option value="0">Не выбрана</option>
                                        <option  className="option__hr" disabled />
                                        {!trainingDisciplineData ? null : (
                                            trainingDisciplineData.map((trainingDiscipline, index) => (
                                                Number(trainingProgramId) > 0 ? (

                                                    ((Number(trainingProgramId) > 0) && (Number(trainingProgramId) === Number(trainingDiscipline.training_program_id))) ? (
                                                        <React.Fragment key={index}>
                                                            <option value={trainingDiscipline.id}>{trainingDiscipline.discipline_name}</option>
                                                            <option className="option__info" disabled>{trainingDiscipline.year_program} курс, {trainingDiscipline.period_program} семестр</option>
                                                            <option  className="option__hr" disabled />
                                                        </React.Fragment>
                                                    ) : null

                                                ) : (
                                                    <React.Fragment key={index}>
                                                        <option value={trainingDiscipline.id}>{trainingDiscipline.discipline_name}</option>
                                                        <option className="option__info" disabled>{trainingDiscipline.year_program} курс, {trainingDiscipline.period_program} семестр</option>
                                                        <option  className="option__hr" disabled />
                                                    </React.Fragment>
                                                )
                                            ))
                                        )}
                                    </select>
                                </div>
                            </div>

                            <div className="Content__error">
                                <span className="Error__text">{formResponseError}</span>
                            </div>
                        </div>
                        <div className="Form__footer">
                            <button className={isHandleSubmit ? 'Form__button Form__button_blue Form__button_left Form__button_disabled' : 'Form__button Form__button_blue Form__button_left'}
                                    type="submit"
                                    style={{
                                        position: "relative"
                                    }}
                                    disabled={isHandleSubmit ? 'disabled' : null}>
                                Скачать ведомость
                                <div style={{position: "absolute", right: 6, top: 6}}>{isHandleSubmit ? <Loading white={true} /> : null}</div>
                            </button>
                        </div>
                    </form>
                </div>
            );
        }

        else return null;
    }
}

function mapStateToProps(state) {
    return {
        isLoadForm: state.form.isLoadForm,
        isUpdateForm: state.form.isUpdateForm,
        modalData: state.modal.modalData,
        isRequest: state.api.isRequest,
        responseMessage: state.api.responseMessage,
        responseData: state.api.responseData,
        responseError: state.api.responseError
    }
}

function mapDispatchToProps(dispatch) {
    return {
        formLoad: (isLoadForm) => dispatch(formLoad(isLoadForm)),
        pageUpdate: () => dispatch(pageUpdate()),
        notificationMessage: (message, colorMessage) => dispatch(notificationMessage(message, colorMessage)),
        apiRequest: (requestURL, formData, callbackSuccess, callbackError) => dispatch(apiRequest(requestURL, formData, callbackSuccess, callbackError)),
        alertOpen: (alertForm, alertData) => dispatch(alertOpen(alertForm, alertData)),
        modalClose: () => dispatch(modalClose())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalTrainingGroupDisciplinePrint);
