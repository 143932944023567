import {FORM_LOAD, FORM_UPDATE} from "../actions/actionTypes";

const initialState = {
    isLoadForm: false,
    isUpdateForm: false
}

export default function formReducer(state = initialState, action) {
    switch (action.type) {
        case FORM_LOAD:
            return {
                ...state, isLoadForm: action.isLoadForm, isUpdateForm: false
            }
        case FORM_UPDATE:
            return {
                ...state, isUpdateForm: true
            }
        default:
            return state;
    }
}
