export default function baseURL() {
    if (document.location.host === 'localhost') {
        return 'http://localhost/'; // http://localhost:8080/
    }

    else if (document.location.host === 'localhost:7000') {
        return 'http://localhost/'; // http://localhost:8080/
    }

    else if (document.location.host === 'lk.at.pstu.ru') {
        return '/'; // http://lk.at.pstu.ru:8080/
    }

    else if (document.location.host === '5.63.157.43') {
        return '/'; //:8080
    }

    else if (document.location.host === '151.248.116.225') {
        return '/'; //:8080
    }

    else {
        console.log('Error, the address connection is not defined.');
        return null;
    }
}
