import React from "react";
import {connect} from 'react-redux';
import {authRecovery} from "../../store/actions/recoveryAction";
import './Auth.scss';
import Loading from "../loading/Loading";
import {NavLink} from "react-router-dom";

class Recovery extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            authEmail: '',
            authError: null
        };
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });

        this.setState({authError: null});
    }

    handleSubmit(event) {
        event.preventDefault();

        if (!this.state.authEmail) {
            this.setState({authError: 'Укажите логин или электронную почту для восстановления'});
        }

        else {
            // const emailRegular = /^[\w\d%$:.-]+@\w+\.\w{2,5}$/;
            // const emailRegularTest = emailRegular.test(this.state.authEmail);
            //
            // if (!emailRegularTest) {
            //     this.setState({authError: 'Некорректный адрес эл. почты'});
            // }
            //
            // else {
            let formData = new FormData();
            formData.append('login', this.state.authEmail);

            this.props.authRecovery(
                formData,
                () => {this.props.history.push('/login');},
                () => {this.setState({authError: this.props.recoveryError});}
            );
            // }
        }
    };

    render() {
        const { recoveryRequest } = this.props;
        const { authError } = this.state;

        return (
            <div className="Auth" style={({
                // height: window.innerHeight + 'px',
                height: "100%"
            })}>
                <i/>
                <div className="Auth__card">
                    <div className="Auth__form">

                        <form onSubmit={this.handleSubmit.bind(this)}>

                            <div className="Form__header">
                                <div className="Header__logotype">
                                    <div className="Logotype_big"/>
                                </div>
                                <div className="Header__motto">Онлайн магистратура ПНИПУ</div>
                                {/*<div className="Header__motto">Образовательный портал <br/> магистратура онлайн <br/> демо-версия</div>*/}
                                <div className="Header__motto"><b>Восстановление доступа</b></div>
                            </div>

                            <div className="Form__content">
                                <div className="Form__field">
                                    <div className="Field__label">Логин / Электронная почта</div>
                                    <input className="Field__input"
                                           name="authEmail"
                                           type="text"
                                           autoComplete="off"
                                        // placeholder="Укажите логин / адрес эл.почты"
                                           value={this.state.value}
                                           onChange={this.handleInputChange.bind(this)} />
                                    <div className="Field__error">
                                        {!authError ? null : <span className="Error__text">{authError}</span>}
                                    </div>
                                </div>
                            </div>

                            <div className="Form__footer">
                                <div className="Form__field">
                                    <button className={!recoveryRequest ? 'Field__button' : 'Field__button Field__button_disabled'}
                                            type="submit"
                                            disabled={!recoveryRequest ? null : 'disabled'}
                                            style={{
                                                display: 'inline-block',
                                                marginRight: 24
                                            }}>Восстановить</button>
                                    <NavLink to={"/login"}>
                                        <div className="Field__link"
                                             style={{
                                                 display: 'inline-block'
                                             }}>
                                            К входу
                                        </div>
                                    </NavLink>
                                </div>
                            </div>

                        </form>

                    </div>

                    {/*<div className="Auth__card_shadow"/>*/}
                    <div className="Auth__loading">
                        {!recoveryRequest ? null : <Loading/>}
                    </div>
                </div>
                <i/>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        recoveryRequest: state.recovery.recoveryRequest,
        recoveryError: state.recovery.recoveryError
    }
}

function mapDispatchToProps(dispatch) {
    return {
        authRecovery: (formData, callbackSuccess, callbackError) => dispatch(authRecovery(formData, callbackSuccess, callbackError))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Recovery);
